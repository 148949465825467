namespace PL.Claims.Controllers {

    export class ClaimIntakeFormController extends Abstract.ClaimIntakeFormBase {

        init(incidentID: number, clientID: number, formTypeID: number, formTypeName: string): void {    
            this.ClaimIntakeForm = new Models.IntakeForm;
            this.ClaimIntakeForm.IncidentID = incidentID;
            this.ClaimIntakeForm.ClientID = clientID;
            this.ClaimIntakeForm.FormTypeID = formTypeID;
            this.ClaimIntakeForm.FormTypeName = formTypeName;
            if (incidentID == null || incidentID == 0) {
                this.setFormStatusAsEnum(Enums.ClaimIntakeForm_Status.NEW);    
                // getClientInformation is called inside "this.ClaimIntakeForm.get()" for one reason or another. - investigate and clean up at some point.
                var client = new PL.CRM.Models.Client();
                client.ClientID = clientID;
                client.getClientInformation().done((clientInfo) => {                    
                    this.ClaimIntakeForm.ClientInformation = clientInfo;
                    this.ClaimIntakeForm.save().done((newIncidentID) => {
                        this.ClaimIntakeForm = new Models.IntakeForm;
                        this.ClaimIntakeForm.IncidentID = newIncidentID;
                        this.ClaimIntakeForm.ClientID = clientID;
                        this.ClaimIntakeForm.FormTypeID = formTypeID;
                        this.ClaimIntakeForm.FormTypeName = formTypeName;
                        this.ClaimIntakeForm.IncidentReport.IncidentDate = '1/1/1900';
                        this.ClaimIntakeForm.get().done(() => {
                            this.setupEvents();
                        });
                    });
                });                
            } else {                
                this.ClaimIntakeForm.get().done(() => {
                    this.setupEvents();
                });
            }
            
        }

        private setupEvents(): void {
            this.setFormStatusAsEnum(this.getFormStatusAsEnum(this.ClaimIntakeForm.Status));
            this.removeUnwantedFields();
            this.prepareCategories();      
            new PL.Claims.Controllers.ClaimIntakeNavigationV2Controller(this.ClaimIntakeForm).init();
        }

        private prepareCategories(): void {
            this.ClaimIntakeForm.Categories.forEach((catO, catI) => {                
                this.processMissingValues(catI);
                this.prcoessCategoryStatus(catI);
                this.processCategoryPhases(catI);  
            });
        }

        private removeUnwantedFields(): void {
        // we need to remove all categories/fields that have the "FormShow" flag set to false.
            var requiredQuestions = 0, categories = this.ClaimIntakeForm.Categories;

            for (var i = 0; i < categories.length; i++) {
                var category = categories[i];

                if (category.Fields.length == 0) {
                    categories.splice(i, 1);
                    i--;
                    continue;
                }

                var fields = this.ClaimIntakeForm.Categories[i].Fields

                for (var x = 0; x < fields.length; x++) {

                    if (fields[x].Required) {
                        requiredQuestions++;
                    }

                    if (!fields[x].FormShow) {
                        fields.splice(x, 1);
                        x--;
                    }

                }
                
                if (category.Fields.length == 0) {
                    categories.splice(i, 1);                    
                }

            }
             
        }   
        
        private processCategoryPhases(categoryIndex: number): void {
            // a phase on a category helps determine wether or not to show specific naviation elements on start, dynamic, finish, and pages in between.
            var categories = this.ClaimIntakeForm.Categories, category = categories[categoryIndex];

            if (this.ClaimIntakeForm.Categories.length != 1) {
                switch (categoryIndex) {
                    case 0:
                        category._Phase = Enums.ClaimIntakeForm_Phase.START_DYNAMIC;
                        break;
                    case categories.length - 1:
                    category._Phase = Enums.ClaimIntakeForm_Phase.DYNAMIC_FINISH;
                        break;
                    default:
                    category._Phase = Enums.ClaimIntakeForm_Phase.DYNAMIC;
                        break;                        
                }
            }
        }    
        
        private prcoessCategoryStatus(categoryIndex: number): void {

            var requiredQuestions = 0, requiredAndAnsweredQuestions = 0, category = this.ClaimIntakeForm.Categories[categoryIndex];            

                requiredQuestions = 0, requiredAndAnsweredQuestions = 0;

                category.Fields.forEach((cfO, cfI) => {
                    if (cfO.Required) {

                        requiredQuestions++;

                        if (typeof cfO.FieldValue == 'string') {
                            if (cfO.FieldValue != null && cfO.FieldValue.trim() != "") {
                                requiredAndAnsweredQuestions++;
                            }
                        } else {
                            if (cfO.FieldValue != null) {
                                requiredAndAnsweredQuestions++;
                            }
                        }
                    }
                });                
            if (this.ClaimIntakeForm.IncidentReport.Status == Enums.ClaimIntakeForm_Status.SUBMITTED) {
                this.setCategoryStatusAsEnum(Enums.ClaimIntakeCategory_Status.COMPLETE, categoryIndex);
            } else {
                if (requiredQuestions == requiredAndAnsweredQuestions) {
                    this.setCategoryStatusAsEnum(Enums.ClaimIntakeCategory_Status.COMPLETE, categoryIndex);
                }

                if (requiredQuestions == 0) {
                    this.setCategoryStatusAsEnum(Enums.ClaimIntakeCategory_Status.REVIEW, categoryIndex);
                }

                if (category._Status == undefined) {
                    this.setCategoryStatusAsEnum(Enums.ClaimIntakeCategory_Status.INCOMPLETE, categoryIndex);
                }
            }
        }
        
        private processMissingValues(categoryIndex: number): void {

            var category = this.ClaimIntakeForm.Categories[categoryIndex], incident = this.ClaimIntakeForm.IncidentReport;

            category.Fields.forEach((cfO) => {

                if (<Object>incident.hasOwnProperty(cfO.FieldName)) {

                    var missingValue = incident[cfO.FieldName];

                    if (missingValue != null && missingValue != undefined) {
                        if (typeof missingValue == 'string') {
                        // unfortunetly, we are returning empty chars, which in an empty char evalutates to "\0" so we need to check for empty chars here
                            if (missingValue.trim() != "" && missingValue != '\0') {
                                cfO.FieldValue = missingValue;                            
                            }
                        } else {
                            cfO.FieldValue = missingValue;
                        }
                    }
                }
            });

        }            
    }

}