var Template_Attachments = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<table style=\"width: 406px;\">\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.Attachments : depth0), {"name":"each","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</table>\r\n";
},"2":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "    <tr>\r\n        <td>\r\n            <a href=\"/chsifilehandler.axd?FileID="
    + escapeExpression(((helper = (helper = helpers.FileID || (depth0 != null ? depth0.FileID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"FileID","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\"minilink\">"
    + escapeExpression(lambda((depth0 != null ? depth0.FileName : depth0), depth0))
    + "</a>\r\n            <input type=\"hidden\" class=\"FileID\" value=\""
    + escapeExpression(((helper = (helper = helpers.FileID || (depth0 != null ? depth0.FileID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"FileID","hash":{},"data":data}) : helper)))
    + "\" />\r\n        </td>\r\n        <td style=\"text-align: right\">[<a href=\"javascript: ConfirmDeleteAttachment("
    + escapeExpression(((helper = (helper = helpers.FileID || (depth0 != null ? depth0.FileID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"FileID","hash":{},"data":data}) : helper)))
    + ");\" class=\"minilink\">delete</a>]</td>\r\n    </tr>\r\n";
},"4":function(depth0,helpers,partials,data) {
  return "            No attachments.\r\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "﻿";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.Attachments : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});