var Template_QuoteChecklist = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper;

  return "<div class=\"QuoteChecklistContainer\">                \r\n    <div class=\"QuoteChecklistHeader\">                     \r\n        <b>"
    + this.escapeExpression(((helper = (helper = helpers.GroupTitle || (depth0 != null ? depth0.GroupTitle : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"GroupTitle","hash":{},"data":data}) : helper)))
    + "</b>\r\n    </div>\r\n    <div class=\"QuoteChecklistBody\">\r\n"
    + ((stack1 = helpers.each.call(depth0,(depths[1] != null ? depths[1].Questions : depths[1]),{"name":"each","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </div>                \r\n</div>\r\n<br />\r\n<br />\r\n";
},"2":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depths[1] != null ? depths[1].GroupID : depths[1]),(depth0 != null ? depth0.GroupID : depth0),{"name":"compare","hash":{"operator":"=="},"fn":this.program(3, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"3":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "            <div class=\"QuoteChecklistItem\">                                                                                      \r\n"
    + ((stack1 = helpers.each.call(depth0,(depths[3] != null ? depths[3].Answers : depths[3]),{"name":"each","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </div>                                        \r\n";
},"4":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depths[1] != null ? depths[1].QuestionID : depths[1]),(depth0 != null ? depth0.QuestionID : depth0),{"name":"compare","hash":{"operator":"=="},"fn":this.program(5, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"5":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=helpers.helperMissing;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depths[2] != null ? depths[2].QuestionMarker : depths[2]),1,{"name":"compare","hash":{"operator":"=="},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depths[2] != null ? depths[2].QuestionMarker : depths[2]),2,{"name":"compare","hash":{"operator":"=="},"fn":this.program(9, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depths[2] != null ? depths[2].QuestionMarker : depths[2]),0,{"name":"compare","hash":{"operator":"=="},"fn":this.program(11, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"6":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "                        <a href=\"#\" onclick=\"ShowChecklistAnswer("
    + alias3(((helper = (helper = helpers.QuoteID || (depth0 != null ? depth0.QuoteID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuoteID","hash":{},"data":data}) : helper)))
    + ", "
    + alias3(((helper = (helper = helpers.QuestionID || (depth0 != null ? depth0.QuestionID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuestionID","hash":{},"data":data}) : helper)))
    + ", "
    + alias3(((helper = (helper = helpers.ClientID || (depth0 != null ? depth0.ClientID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ClientID","hash":{},"data":data}) : helper)))
    + ", '"
    + alias3(((helper = (helper = helpers.QuestionText || (depth0 != null ? depth0.QuestionText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuestionText","hash":{},"data":data}) : helper)))
    + "');\" style=\"width:15px;font-family:'Wingdings 2'; font-size:14pt; color: black;\">X</a><a href=\"#\" style=\"color: blue; text-decoration: underline;\" onclick=\"ShowChecklistAnswer("
    + alias3(((helper = (helper = helpers.QuoteID || (depth0 != null ? depth0.QuoteID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuoteID","hash":{},"data":data}) : helper)))
    + ", "
    + alias3(((helper = (helper = helpers.QuestionID || (depth0 != null ? depth0.QuestionID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuestionID","hash":{},"data":data}) : helper)))
    + ", "
    + alias3(((helper = (helper = helpers.ClientID || (depth0 != null ? depth0.ClientID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ClientID","hash":{},"data":data}) : helper)))
    + ", '"
    + alias3(((helper = (helper = helpers.QuestionText || (depth0 != null ? depth0.QuestionText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuestionText","hash":{},"data":data}) : helper)))
    + "');\">"
    + alias3(((helper = (helper = helpers.QuestionText || (depth0 != null ? depth0.QuestionText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuestionText","hash":{},"data":data}) : helper)))
    + "</a> <span class=\"floatRight\">"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.Response : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</span>\r\n";
},"7":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return " "
    + alias2(((helper = (helper = helpers.Response || (depth0 != null ? depth0.Response : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"Response","hash":{},"data":data}) : helper)))
    + " - "
    + alias2((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias1).call(depth0,(depth0 != null ? depth0.UpdateTmsp : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + " ";
},"9":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "                        <a href=\"#\" onclick=\"ShowChecklistAnswer("
    + alias3(((helper = (helper = helpers.QuoteID || (depth0 != null ? depth0.QuoteID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuoteID","hash":{},"data":data}) : helper)))
    + ", "
    + alias3(((helper = (helper = helpers.QuestionID || (depth0 != null ? depth0.QuestionID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuestionID","hash":{},"data":data}) : helper)))
    + ", "
    + alias3(((helper = (helper = helpers.ClientID || (depth0 != null ? depth0.ClientID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ClientID","hash":{},"data":data}) : helper)))
    + ", '"
    + alias3(((helper = (helper = helpers.QuestionText || (depth0 != null ? depth0.QuestionText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuestionText","hash":{},"data":data}) : helper)))
    + "');\" style=\"width:15px;font-family:'Wingdings 2'; font-size:14pt; color: green;\">P</a><a href=\"#\" style=\"color: blue; text-decoration: underline;\" onclick=\"ShowChecklistAnswer("
    + alias3(((helper = (helper = helpers.QuoteID || (depth0 != null ? depth0.QuoteID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuoteID","hash":{},"data":data}) : helper)))
    + ", "
    + alias3(((helper = (helper = helpers.QuestionID || (depth0 != null ? depth0.QuestionID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuestionID","hash":{},"data":data}) : helper)))
    + ", "
    + alias3(((helper = (helper = helpers.ClientID || (depth0 != null ? depth0.ClientID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ClientID","hash":{},"data":data}) : helper)))
    + ", '"
    + alias3(((helper = (helper = helpers.QuestionText || (depth0 != null ? depth0.QuestionText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuestionText","hash":{},"data":data}) : helper)))
    + "');\">"
    + alias3(((helper = (helper = helpers.QuestionText || (depth0 != null ? depth0.QuestionText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuestionText","hash":{},"data":data}) : helper)))
    + "</a> <span class=\"floatRight\">"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.Response : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</span>\r\n";
},"11":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "                        <a href=\"#\" onclick=\"ShowChecklistAnswer("
    + alias3(((helper = (helper = helpers.QuoteID || (depth0 != null ? depth0.QuoteID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuoteID","hash":{},"data":data}) : helper)))
    + ", "
    + alias3(((helper = (helper = helpers.QuestionID || (depth0 != null ? depth0.QuestionID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuestionID","hash":{},"data":data}) : helper)))
    + ", "
    + alias3(((helper = (helper = helpers.ClientID || (depth0 != null ? depth0.ClientID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ClientID","hash":{},"data":data}) : helper)))
    + ", '"
    + alias3(((helper = (helper = helpers.QuestionText || (depth0 != null ? depth0.QuestionText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuestionText","hash":{},"data":data}) : helper)))
    + "');\" style=\"width:15px;font-family:'Wingdings 2'; font-size:14pt; color: red;\">O</a><a href=\"#\" style=\"color: blue; text-decoration: underline;\" onclick=\"ShowChecklistAnswer("
    + alias3(((helper = (helper = helpers.QuoteID || (depth0 != null ? depth0.QuoteID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuoteID","hash":{},"data":data}) : helper)))
    + ", "
    + alias3(((helper = (helper = helpers.QuestionID || (depth0 != null ? depth0.QuestionID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuestionID","hash":{},"data":data}) : helper)))
    + ", "
    + alias3(((helper = (helper = helpers.ClientID || (depth0 != null ? depth0.ClientID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ClientID","hash":{},"data":data}) : helper)))
    + ", '"
    + alias3(((helper = (helper = helpers.QuestionText || (depth0 != null ? depth0.QuestionText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuestionText","hash":{},"data":data}) : helper)))
    + "');\">"
    + alias3(((helper = (helper = helpers.QuestionText || (depth0 != null ? depth0.QuestionText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuestionText","hash":{},"data":data}) : helper)))
    + "</a> <span class=\"floatRight\">"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.Response : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</span>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "﻿"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Groups : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});