module PL.Que.Models.Design {    

    export class Section {

        SectionID: number;
        FormID: number;
        Title: string;
        Tooltip: string;
        CompletionStatus: number;
        SortOrder: number;
        Status: string;
        CurrentVisibility: number;
        IsRequired: boolean;
        ValidationLevel: number;
        SectionIndex: number;
        // NumOfElements is not related to the C# BOL
        NumOfElements: number = null;        
        Elements: PL.Que.Models.Design.Element[] = new Array<PL.Que.Models.Design.Element>();
        MetaAttributes: PL.Que.Models.MetaAttributes[] = new Array<PL.Que.Models.MetaAttributes>();
        CreateUser: string;
        CreateTmsp: string|Date;
        UpdateUser: string;
        UpdateTmsp: string|Date;

        save(): JQueryDeferred<number> {
            var result = $.Deferred<number>();
            mW.classes.getProperties(this);
            PL.Que.APIs.Section_Repo.saveSection(this).done((sectionID) => {
                result.resolve(sectionID);
            });
            return result;
        }

        getElement(elementID: number): PL.Que.Models.Design.Element {
            var element: PL.Que.Models.Design.Element;
            this.Elements.forEach((eO, eI) => {
                if (eO.ElementID == elementID) {
                    element = eO;
                }
            });
            return element;
        }

    }

}