CHSI.UW.MuniUW_FormulaWorksheet = {
    Init: function (QuoteID) {
        this.Cache.QuoteID = QuoteID;       
        this.Templates.FormulaWorksheet = CHSITemplates.Template_FormulaWorksheet;
        this.Calls.GetQuoteDetails(QuoteID);
        this.Events.Global();
        //this.Binds.BindQTips();
    },
    Templates: {},
    Cache: {
        QuoteObject: {},
        InfoObject: {},
        QuoteID: null,
        Editable: 'Y',
        LastTabID: null,
        TopPosition: null,
        CurrentSelectedElementID: null
    },
    Binds: {
        BindQuote: function () {
            if ($('.blockIndent')[0]) {
                CHSI.UW.MuniUW_FormulaWorksheet.Cache.TopPosition = Math.abs($('#jxFormulaWorksheet .blockIndent:first').offset().top);
            }
            if (QuoteObject) {
                CHSI.UW.MuniQuote.Cache.QuoteObject = QuoteObject;               
                QuoteObject = null;
            }
            if (CHSI.UW.MuniQuote.Cache.QuoteList.length > 1 && CHSI.UW.MuniQuote.Cache.QuoteObject.ParentQuoteID) {
                $('.QuoteLabel').html('Endorsement');
            }
            $('#jxFormulaWorksheet').html(CHSI.UW.MuniUW_FormulaWorksheet.Templates.FormulaWorksheet(CHSI.UW.MuniQuote.Cache.QuoteObject));            
            restart = true;
            sorttable.init();
            if (CHSI.UW.MuniUW_FormulaWorksheet.Cache.CurrentSelectedElementID) {
                $('#' + CHSI.UW.MuniUW_FormulaWorksheet.Cache.CurrentSelectedElementID).focus();
            }
            CHSI.UW.MuniUW_FormulaWorksheet.Methods.RecalculateSize();            
            //CHSI.UW.MuniUW_FormulaWorksheet.Binds.BindQTips();
            CHSI.UW.MuniQuote.DisablePage(CHSI.UW.MuniUW_FormulaWorksheet.Cache.Editable);
            $('.NotificationsBar').hide();
            if (CHSI.UW.MuniUW_FormulaWorksheet.Cache.LastTabID != null) {
                $('#' + CHSI.UW.MuniUW_FormulaWorksheet.Cache.LastTabID).select();
            }
            CHSI.Common.Log('top on bind ' + CHSI.UW.MuniUW_FormulaWorksheet.Cache.TopPosition);
            $('#PageContainer').scrollTop(CHSI.UW.MuniUW_FormulaWorksheet.Cache.TopPosition);
        },
        BindQTips: function (Target) {
            $(Target + ' > .jxQtipInformation').qtip({
                position: {                    
                    my: 'top right',
                    at: 'bottom left',
                    adjust: {
                        method: 'flip'
                    },
                    viewport: jQuery(window)
                },
                content: {                    
                    attr: 'title'
                },
                hide: { fixed: true, delay: 300 },
                style: { padding: '5px 15px', name: '' }
            });
        }
    },
    Events: {
        Global: function () {

            $(window).on('resize', CHSI.UW.MuniUW_FormulaWorksheet.Methods.RecalculateSize());

            $('#jxCheatSheetLink').attr('href', 'FormulaWorksheet.aspx?QuoteID=' + CHSI.UW.MuniUW_FormulaWorksheet.Cache.QuoteID);

            $('.NotificationsBar').hide();

            $('#jxFormulaWorksheet').on('focus', '.VariableTitle', function () {
                var ID = $(this).data('notificationid'),
                    InformationPanelID = '#InformationBar_' + ID,
                    Title = $(InformationPanelID).data('title'),
                    VariableName = $(InformationPanelID).data('variablename'),
                    QuoteID = $(InformationPanelID).data('quoteid'),
                    HistoryID = $(InformationPanelID).data('historyid'),
                    InfoObject = CHSI.UW.MuniUW_FormulaWorksheet.Cache.InfoObject,
                    EditMode = $(InformationPanelID).data('editmode');

                InfoObject.InformationPanelID = InformationPanelID;
                InfoObject.VarID = ID;
                InfoObject.HistoryID = HistoryID;
                InfoObject.Title = Title;
                InfoObject.VariableName = VariableName;
                InfoObject.QuoteID = QuoteID;
                InfoObject.EditMode = EditMode;



                CHSI.UW.MuniUW_FormulaWorksheet.Events.AdditionalInfoPanel('#NotificationID_' + ID, InfoObject);

                
                $('.NotificationsBar').show();

                //CHSI.UW.MuniUW_FormulaWorksheet.Binds.BindQTips();
            });

            $('body').on('scroll', function () {
                $('.NotificationsBar').hide();
            });
            //$('#jxFormulaWorksheet').on('blur', '.VariableTitle', function () {
            //    $('.NotificationsBar').hide();
            //});

            //$('.NotificationsBar').on('mouseenter', function () {
            //    $(this).show();
            //});

            //$('.NotificationsBar').on('mouseleave', function () {
            //    $(this).hide();
            //});

            $('.jxRecalculate').on('click', function () {
                var Response = confirm('Please confirm that you would like to update this value.');

                if (Response) {

                    InfoObject = CHSI.UW.MuniUW_FormulaWorksheet.Cache.InfoObject;
                    CHSI.UW.MuniUW_FormulaWorksheet.Calls.RecalculateVariable(InfoObject.VarID, CHSI.UW.MuniUW_FormulaWorksheet.Cache.QuoteID);


                }
            });

            $('.jxShowHistory').on('click', function () {
                QuoteObject = CHSI.UW.MuniQuote.Cache.QuoteObject;
                InfoObject = CHSI.UW.MuniUW_FormulaWorksheet.Cache.InfoObject;
                CHSI.UW.MuniUW_FormulaWorksheet.Events.ShowHistory(InfoObject.VarID, CHSI.UW.MuniUW_FormulaWorksheet.Cache.QuoteID, InfoObject.EditMode, InfoObject.VariableName);
            });


            $('#jxFormulaWorksheet').on('change', '.Variable', function () {
                QuoteObject = CHSI.UW.MuniQuote.Cache.QuoteObject;
                var Variable = $(this);               
                if (CHSI.UW.MuniQuote.Cache.QuoteObject.QuoteStatus.RequireComments == 'Y') {
                    CHSI.UW.MuniUW_FormulaWorksheet.Events.ShowSaveVariable(Variable.attr("id"), CHSI.UW.MuniUW_FormulaWorksheet.Cache.QuoteID, Variable.val());
                } else {
                    CHSI.UW.MuniUW_FormulaWorksheet.Calls.SaveVariable(Variable.attr("id"), Variable.val(), false);
                }
            });

            $('#jxFormulaWorksheet').on('focus', '.Variable', function () {
                var Variable = $(this);
                Variable.val(Variable.val());
                $(this).select();
            });

            $('#jxFormulaWorksheet').on('focusout', '.Variable', function () {
                var Variable = $(this);
                var FormatedValue = '';
                if (Variable.data('format')) {
                    var Format = Variable.data('format');
                    var OriginalValue = Variable.data('value');
                    FormatedValue = CHSI.Common.String.Format(Format, OriginalValue);
                    Variable.val(FormatedValue);
                }
            });
        },
        ShowGUIAdminTools: function () {
            $('.GUIAdmin').show();
            $('#jxShowGUIAdminTools').hide();
            $('#jxHideGUIAdminTools').show();
        },
        HideGUIAdminTools: function () {
            $('.GUIAdmin').hide();
            $('#jxShowGUIAdminTools').show();
            $('#jxHideGUIAdminTools').hide();
        },
        ShowFormulas: function () {
            $('[data-formula]').each(function (i, j) {
                var currentobject = jQuery(this);
                currentobject.html('<span class="Formula">' + currentobject.attr('data-formula') + '</span>');
            });
        },
        ShowVariableTitles: function () {
            $('[data-title]').each(function (i, j) {
                var currentobject = jQuery(this);
                currentobject.html(currentobject.attr('data-title'));
            });
        },
        ShowVariables: function () {
            $('[data-variable]').each(function (i, j) {
                var currentobject = jQuery(this);
                currentobject.html('<span class="VariableName">' + currentobject.attr('data-variable') + '</span>');
            });
        },
        ShowHistory: function (VarID, QuoteID, EditMode, VariableName) {
            var ShowHistoryURL = '/UW/Modals/VariableHistory.aspx?VarID=' + VarID + '&QuoteID=' + QuoteID + '&EditMode=' + EditMode;
            ShowPopup("History", ShowHistoryURL, "History of " + VariableName + ' - ' + VarID, 600, 290, CHSI.UW.MuniUW_FormulaWorksheet.Binds.BindQuote, false);

        },

        ShowSaveVariable: function (VarID, QuoteID, NewValue) {
            var ShowSaveVariableURL = '/UW/Modals/VariableSave.aspx?VarID=' + VarID + '&QuoteID=' + QuoteID + '&NewValue=' + NewValue;
            ShowPopup("SaveVariable", ShowSaveVariableURL, "Save Variable: " + VarID, 290, 110, CHSI.UW.MuniUW_FormulaWorksheet.Binds.BindQuote, false);

        },
        AdditionalInfoPanel: function (Target, InfoObject) {            
            var jqTarget = $(Target),
                NotificationsBar = '.NotificationsBar',
                Left = 0,
                Top = 0,
                Position = jqTarget.position();

            if (Position) {
                Left = Position.left;
                Top = Position.top + 23;
                $(NotificationsBar).css('left', Left);
                $(NotificationsBar).css('top', Top);

                $('.jxQtipInformation').prop('title', InfoObject.Title);
            }
            
            //CHSI.UW.MuniUW_FormulaWorksheet.Binds.BindQTips(Target);

            // update the button values
        }
    },
    Methods: {
        RecalculateSize: function () {
            var WindowHeight = $(window).height();
            var HeaderContainerHeight = $('#HeaderContainer').height();
            var PageContainer = $('#PageContainer')
            PageContainer.height(WindowHeight - HeaderContainerHeight);
        }
    },
    GetNextEditableFocus: function (currentID) {
        var NextInput = $('#' + currentID).closest('td').next().find('input');

        if (NextInput.length == 0) {
            NextInput = $('#' + currentID).closest('tr').next().children('td').children('input').first();
        }

        if (NextInput.length == 0) {


            return null;
        }
        else {
            if (NextInput.is('[readonly]')) {
                return CHSI.UW.MuniUW_FormulaWorksheet.GetNextEditableFocus(NextInput.attr('id'));
            }
            else {
                return NextInput.attr('id');
            }

        }


    },
    Calls: {
        GetQuoteDetails: function (QuoteID) {
            var Success = function (e) {
                CHSI.UW.MuniQuote.Cache.QuoteList = e;
                CHSI.UW.MuniQuote.Cache.QuoteObject = e[0];
                if (CHSI.UW.MuniQuote.Cache.QuoteObject.QuoteStatus && CHSI.UW.MuniQuote.Cache.QuoteObject.QuoteStatus.Editable == 'N') {
                    CHSI.UW.MuniUW_FormulaWorksheet.Cache.Editable = 'N';
                }
                CHSI.UW.MuniUW_FormulaWorksheet.Binds.BindQuote();
            }

            CHSI.UW.MuniQuote.GetQuoteDetails(QuoteID, Success);

        },

        RecalculateVariable: function (VarID, QuoteID) {
            var Success = function (e) {
                e.Appearance = CHSI.UW.MuniQuote.Cache.QuoteObject.Appearance;
                CHSI.UW.MuniQuote.Cache.QuoteObject = e;               
                CHSI.UW.MuniUW_FormulaWorksheet.Binds.BindQuote();
                $('#' + VarID).css('color', 'orange');
            }

            CHSI.UW.MuniQuote.RecalculateVariable(VarID, QuoteID, Success);
        },

        SaveVariable: function (VarID, NewValue, ForceAutoCalculation) {
            var SourceObject = CHSI.UW.MuniQuote.GetQuoteObjectfromVarID(VarID);

            var ajaxData = {};
            ajaxData.DataObject = {};
            ajaxData.Comments = '';
            ajaxData.DataObject.isLocked = 1;
            ajaxData.DataObject.StringValue = SourceObject.StringValue;
            ajaxData.DataObject.DateValue = SourceObject.DateValue;
            ajaxData.DataObject.IntegerValue = SourceObject.IntegerValue;
            ajaxData.DataObject.DecimalValue = SourceObject.DecimalValue;
            ajaxData.DataObject.BoolValue = SourceObject.BoolValue;
            ajaxData.DataObject.DataType = SourceObject.DataType;
            ajaxData.DataObject.QuoteID = CHSI.UW.MuniQuote.Cache.QuoteObject.QuoteID;
            ajaxData.isAutoCalculated = SourceObject.isAutoCalculated;
            ajaxData.ForceAutoCalculation = ForceAutoCalculation;
            ajaxData.Status = 'active';
            ajaxData.Type = 'Quote';

            if (CHSI.UW.MuniQuote.Cache.QuoteList.length > 1 && CHSI.UW.MuniQuote.Cache.QuoteObject.ParentQuoteID) {
                ajaxData.Type = 'Endorsement';
            }
            CHSI.UW.MuniQuote.SetValue(ajaxData.DataObject, NewValue);
            CHSI.UW.MuniUW_FormulaWorksheet.Cache.CurrentSelectedElementID = CHSI.UW.MuniUW_FormulaWorksheet.GetNextEditableFocus(VarID);
            var Success = function (e) {
                var Appearance = CHSI.UW.MuniQuote.Cache.QuoteObject.Appearance;
                e.Appearance = Appearance
                QuoteObject = e;
                parent.QuoteObject = e;
                CHSI.UW.MuniQuote.Cache.QuoteObject = e;
                CHSI.UW.MuniUW_FormulaWorksheet.Binds.BindQuote();
            }

            CHSI.UW.MuniQuote.SaveVariable(VarID, ajaxData, Success);
        }
    },   

}