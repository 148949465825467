namespace PL.Claims.Abstract {

    export class ClaimIntakeFormBase {

        $container: JQuery = $('#renderedIntakeFormContainer');
        $category_container: JQuery = $('#formCategoryViewContainer');
        $information: JQuery = $('#formInformationContainer');

        ClaimIntakeForm: PL.Claims.Models.IntakeForm;

        Notification: Claims.Controllers.ClaimIntakeNotificationV2Controller;

        constructor(intakeForm?: PL.Claims.Models.IntakeForm) {
            this.ClaimIntakeForm = intakeForm;
        }

        validateFields(): JQueryDeferred<any> {
            var $result = $.Deferred();

            if ($('form').valid()) {
                $result.resolve(true);
            } else {
                $result.reject(false);
                $('.FieldType').each(function (i, v) {
                    var fieldValue = $(this).val();
                    if (fieldValue == null || fieldValue == "" || fieldValue == " ") {
                        $(this).parent().find('.errorDescription').slideDown('fast');
                    } else {
                        $(this).parent().find('.errorDescription').slideUp('fast');
                    }
                });
            }
            return $result;
        }

        validateCategories(): JQueryDeferred<any[]> {
            var $result = $.Deferred(), status: Enums.ClaimIntakeCategory_Status, incompleteCategories: { name: string, remainingCount: number, status: number, index: number }[] = [], _self = this;

            $('.categoryContainer').each(function () {

                var $cat: JQuery = $(this), status = $cat.data('status');

                if (status != Enums.ClaimIntakeCategory_Status.COMPLETE) {

                    incompleteCategories.push({ name: $cat.data('name'), remainingCount: $cat.data('remainingcount'), status: status, index: +$cat.data('index') });
                } else {

                    if ($cat.data('issaved') == false) {
                        _self.saveCategoryFields(+$cat.data('index'));
                        $cat.data('issaved', true);
                    }

                }

            });            

            if (incompleteCategories.length > 0) {
                $result.reject(incompleteCategories);
            } else {
                $result.resolve(true);
            }

            return $result;
        }

        saveCategoryFields(categoryIndex: number): JQueryDeferred<any> {
            var $result = $.Deferred();                                    
            this.ClaimIntakeForm.Categories[categoryIndex].saveFields().done(() => {
                $('.categoryContainer').eq(categoryIndex).data('issaved', true);
                $result.resolve(true);
            });
            return $result
        }

        updateCategoryField(formFieldID: number, value: string, categoryIndex: number, isValid: boolean): void {
            isValid = isValid == undefined ? false : isValid;
            this.ClaimIntakeForm.Categories[categoryIndex].Fields.forEach((cfO, cfI) => {
                if (cfO.FormFieldID == formFieldID) {
                    cfO.FieldValue = value;
                    if (!isValid) {
                        cfO.FieldValue = null;
                    }
                }
            });
            this.updateCategoryProgress(categoryIndex);
        }

        updateCategoryProgress(categoryIndex: number): void {
            var fields = this.ClaimIntakeForm.Categories[categoryIndex].Fields,
                $category = $('.categoryContainer').eq(categoryIndex),
                answered = 0,
                unanswered = 0,
                percentCompleted = 100,
                totalAvalibleQuestions = 0;

            fields.forEach((cfO, cfI) => {

                if (cfO.Required) {

                    totalAvalibleQuestions++;
                    
                    if (typeof cfO.FieldValue == 'string') {
                        if (cfO.FieldValue != null && cfO.FieldValue.trim() != "") {
                            answered++;
                        } else {
                            unanswered++;
                        }
                    } else {
                        if (cfO.FieldValue != null) {
                            answered++;
                        } else {
                            unanswered++;
                        }
                    }
                }

            });

            if (totalAvalibleQuestions > 0) {
                percentCompleted = (answered * (percentCompleted / totalAvalibleQuestions));
            }

            

            if (answered == totalAvalibleQuestions) {
                if (this.ClaimIntakeForm.Categories[categoryIndex]._Status != Enums.ClaimIntakeCategory_Status.REVIEW) {
                    this.setCategoryStatusAsEnum(Enums.ClaimIntakeCategory_Status.COMPLETE, categoryIndex);
                }                
            } else {
                //$category.data('remainingcount', (answered - totalAvalibleQuestions)).find('.remainingCount').text(answered);
                this.setCategoryStatusAsEnum(Enums.ClaimIntakeCategory_Status.INCOMPLETE, categoryIndex);
                $('.categoryContainer').removeClass('progressWarning, progressError, progressSuccess');
                $category.data('remainingcount', (answered - totalAvalibleQuestions)).find('.categoryMessage').text(answered + ' completed out of ' + totalAvalibleQuestions);
            }


            $category.find('.innerCategoryProgress').removeClass('progressSuccess progressWarning progressError');

            var progressType = 'progressSuccess';
            if (this.ClaimIntakeForm.Categories[categoryIndex]._Status == Enums.ClaimIntakeCategory_Status.REVIEW) {
                progressType = 'progressWarning';
            }

            $category.find('.innerCategoryProgress').addClass(progressType);

            $category.find('.innerCategoryProgress').closest('div').css('width', percentCompleted + '%');

            //$('.activeCategory').find('.innerCategoryProgress').closest('div').animate({
            //    width: percentCompleted + '%'
            //}, 200);                                        

            this.updateFormProgress();

        }

        updateFormProgress(): void {
            var categories = this.ClaimIntakeForm.Categories, completed = 0, percentCompleted = 100, numberOfCategories = categories.length, status: Enums.ClaimIntakeCategory_Status = null, $formProgress = $('#jxTotalProgress');

            categories.forEach((catO, catI) => {

                status = <Enums.ClaimIntakeCategory_Status>$('.categoryContainer').eq(catI).data('status');

                if (status == Enums.ClaimIntakeCategory_Status.COMPLETE) {
                    completed++;
                }

            });

            percentCompleted = (completed * (percentCompleted / numberOfCategories));
            
            $formProgress.css('width', percentCompleted + '%');
            $formProgress.find('.categoryMessage').html(completed + ' out of ' + numberOfCategories + ' categories remain unanswered.');

            
            if (completed == numberOfCategories) {

                $formProgress.find('.categoryMessage').html('All categories and questions have been completed.');
                // look into this later
                //this.Notification.getNotification(Enums.ClaimIntakeNotification_Request.FORM_CATEGORIES_COMPLETE);

            }
        }

        getFormStatusAsEnum(status: string): Enums.ClaimIntakeForm_Status {
            var enumStatus: Enums.ClaimIntakeForm_Status;
            switch (status.toLowerCase()) {
                case 'new':
                    enumStatus = Enums.ClaimIntakeForm_Status.NEW;
                    break;
                case 'open':
                    enumStatus = Enums.ClaimIntakeForm_Status.OPEN;
                    break;
                case 'submitted': 
                    enumStatus = Enums.ClaimIntakeForm_Status.SUBMITTED;
                    break;
                case 'first aid':
                    enumStatus = Enums.ClaimIntakeForm_Status.FIRST_AID;
                    break;
                case 'pending':
                    enumStatus = Enums.ClaimIntakeForm_Status.PENDING;
                    break;
                default:
                    enumStatus = Enums.ClaimIntakeForm_Status.NO_CATEGORIES;
                    break;

            }
            return enumStatus;
        }

        setFormStatusAsEnum(status: Enums.ClaimIntakeForm_Status): void {            
            // we might be able to drop our internal form "_Status" on the formtype object at some point.
            switch (status) {
                case 0:
                    this.ClaimIntakeForm._Status = Enums.ClaimIntakeForm_Status.NEW;
                    this.ClaimIntakeForm.IncidentReport.Status = Enums.ClaimIntakeForm_Status.NEW;
                    break;
                case 1:
                    this.ClaimIntakeForm._Status = Enums.ClaimIntakeForm_Status.OPEN;
                    this.ClaimIntakeForm.IncidentReport.Status = Enums.ClaimIntakeForm_Status.OPEN;
                    break;
                case 2:
                    this.ClaimIntakeForm._Status = Enums.ClaimIntakeForm_Status.SUBMITTED;
                    this.ClaimIntakeForm.IncidentReport.Status = Enums.ClaimIntakeForm_Status.SUBMITTED;
                    break;
                case 3:
                    this.ClaimIntakeForm._Status = Enums.ClaimIntakeForm_Status.FIRST_AID;
                    this.ClaimIntakeForm.IncidentReport.Status = Enums.ClaimIntakeForm_Status.FIRST_AID;
                    break;
                case 5:
                    this.ClaimIntakeForm._Status = Enums.ClaimIntakeForm_Status.PENDING;
                    this.ClaimIntakeForm.IncidentReport.Status = Enums.ClaimIntakeForm_Status.PENDING;
                    break;
                default:
                    this.ClaimIntakeForm._Status = Enums.ClaimIntakeForm_Status.NO_CATEGORIES;
                    break;
            }
        }

        getCategoryPhaseAsEnum(phase: string): Enums.ClaimIntakeForm_Phase {
            var enumPhase: Enums.ClaimIntakeForm_Phase;
            switch (phase.toLowerCase()) {
                case 'start':
                    enumPhase = Enums.ClaimIntakeForm_Phase.START;
                    break;
                case 'start_dynamic':
                    enumPhase = Enums.ClaimIntakeForm_Phase.START_DYNAMIC;
                    break;
                case 'dynamic':
                    enumPhase = Enums.ClaimIntakeForm_Phase.DYNAMIC;
                    break;
                case 'dynamic_finish':
                    enumPhase = Enums.ClaimIntakeForm_Phase.DYNAMIC_FINISH;
                    break;
                case 'finish':
                    enumPhase = Enums.ClaimIntakeForm_Phase.FINISH;
                    break;
            }
            return enumPhase;
        }

        setCategoryPhaseAsEnum(phase: Enums.ClaimIntakeForm_Phase, categoryIndex: number): void {
            switch (phase) {
                case 0:
                    this.ClaimIntakeForm.Categories[categoryIndex]._Phase = Enums.ClaimIntakeForm_Phase.START;
                    break;
                case 2:
                    this.ClaimIntakeForm.Categories[categoryIndex]._Phase = Enums.ClaimIntakeForm_Phase.START_DYNAMIC;
                    break;
                case 3:
                    this.ClaimIntakeForm.Categories[categoryIndex]._Phase = Enums.ClaimIntakeForm_Phase.DYNAMIC
                    break;
                case 4:
                    this.ClaimIntakeForm.Categories[categoryIndex]._Phase = Enums.ClaimIntakeForm_Phase.DYNAMIC_FINISH
                    break;
                case 5:
                    this.ClaimIntakeForm.Categories[categoryIndex]._Phase = Enums.ClaimIntakeForm_Phase.FINISH;
                    break;
                default: this.ClaimIntakeForm.Categories[categoryIndex]._Phase = Enums.ClaimIntakeForm_Phase.UNKNOWN;
            }
        }

        getCategoryStatusAsEnum(status: string): Enums.ClaimIntakeCategory_Status {
            var enumStatus: Enums.ClaimIntakeCategory_Status;
            switch (status.toLowerCase()) {
                case 'complete':
                    enumStatus = Enums.ClaimIntakeCategory_Status.COMPLETE;
                    break;
                case 'incomplete':
                    enumStatus = Enums.ClaimIntakeCategory_Status.INCOMPLETE;
                    break;
                case 'review':
                    enumStatus = Enums.ClaimIntakeCategory_Status.REVIEW;
                    break;
                case 'error':
                    enumStatus = Enums.ClaimIntakeCategory_Status.ERROR;
                    break;
            }
            return enumStatus;
        }

        setCategoryStatusAsEnum(status: Enums.ClaimIntakeCategory_Status, categoryIndex: number): void {

            var $category = $('.categoryContainer').eq(categoryIndex);

            switch (status) {
                case 0:
                    this.ClaimIntakeForm.Categories[categoryIndex]._Status = Enums.ClaimIntakeCategory_Status.COMPLETE;
                    break;
                case 1:
                    this.ClaimIntakeForm.Categories[categoryIndex]._Status = Enums.ClaimIntakeCategory_Status.INCOMPLETE;
                    break;
                case 2:
                    this.ClaimIntakeForm.Categories[categoryIndex]._Status = Enums.ClaimIntakeCategory_Status.REVIEW;
                    break;
                case 3:
                    this.ClaimIntakeForm.Categories[categoryIndex]._Status = Enums.ClaimIntakeCategory_Status.ERROR;
                    break;
            }

            $category.data('status', this.ClaimIntakeForm.Categories[categoryIndex]._Status);

            if (this.ClaimIntakeForm.Categories[categoryIndex]._Status != Enums.ClaimIntakeCategory_Status.INCOMPLETE) {
                $category.find('.categoryMessage').html(Enums.ClaimIntakeCategory_Status[this.ClaimIntakeForm.Categories[categoryIndex]._Status].toUpperCase());
            }
            
            //hackish - review
            if (this.ClaimIntakeForm.Categories[categoryIndex]._Status == Enums.ClaimIntakeCategory_Status.COMPLETE) {
                //$category.find('.innerCategoryProgress').removeClass('progressSuccess progressWarning progressError');
                //$category.find('.innerCategoryProgress').addClass('progressSuccess');
                $('.innerCategoryProgress, .categoryContainer').removeClass('progressWarning, progressError');     
                $category.addClass('progressSuccess').find('.innerCategoryProgress').addClass('progressSuccess');
            }

        }

        setEnumNotificationRequest(request: string): Enums.ClaimIntakeNotification_Request {
            var enumRequest: Enums.ClaimIntakeNotification_Request;
            switch (request.toLowerCase()) {

                case 'start':
                    enumRequest = Enums.ClaimIntakeNotification_Request.GENERAL_INFORMATION_ONE;
                    break;
                case 'start_dynamic':
                    enumRequest = Enums.ClaimIntakeNotification_Request.GENERAL_INFORMATION_TWO;
                    break;
                case 'dynamic':
                    enumRequest = Enums.ClaimIntakeNotification_Request.REVIEW;
                    break;
                case 'dynamic_finish':
                    enumRequest = Enums.ClaimIntakeNotification_Request.CATEGORY_PROGRESS_ERROR;
                    break;
                case 'finish':
                    enumRequest = Enums.ClaimIntakeNotification_Request.FIELD_VALIDATION_ERROR;
                    break;

            }
            return enumRequest;
        }
    }
}