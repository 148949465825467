CHSI.UW.QuoteStatus.Controllers.QuoteStatusAdminController = {

    TargetElement: null,
    TargetAddElement: null,

    Cache:{

        GridObject:null,
        GridTable:null
    },
    Init: function () {

        this.GetAllQuoteStatus();
        this.SetupGlobalEvents();
        
    },

    GetAllQuoteStatus:function(){
            CHSI.UW.QuoteStatus.Models.QuoteStatus.GetAllQuoteStatus(function (result) {
            CHSI.UW.QuoteStatus.Controllers.QuoteStatusAdminController.Cache.GridObject=result;
            CHSI.UW.QuoteStatus.Controllers.QuoteStatusAdminController.BindPresentationLayer();
           

        })


    },

    BindPresentationLayer: function () {

        this.TargetElement.html('<table width="100%" class="dtable" id="jxQuoteStatusGrid"><tfoot></tfoot></table>')
        var DataSet = null;
        var Columns;
        if (CHSI.UW.QuoteStatus.Controllers.QuoteStatusAdminController.Cache.GridObject) {
            DataSet = CHSI.UW.QuoteStatus.Controllers.QuoteStatusAdminController.Cache.GridObject.Values;
            Columns = CHSI.UW.QuoteStatus.Controllers.QuoteStatusAdminController.Cache.GridObject.Columns;
        }

        Columns.push({

            title: '',
            orderable: false,
            width: '10%',
            render:function (data, type, row) {
                var html = "<a class='jxViewEdit' data-quotestatus='" + row[0] + "' href='#'>[view/edit]</a>";
                if(row[2]=='Y')
                {

                    html += "<a class='jxQuickComments' data-quotestatus='" + row[0] + "' href='#'>[quick comments]</a>"

                }
                return html;}

        })


        if (DataSet != null) {
            CHSI.UW.QuoteStatus.Controllers.QuoteStatusAdminController.Cache.GridTable = $('#jxQuoteStatusGrid').DataTable({
                data: DataSet,
                columns: Columns,
                lengthMenu: [[25], [25]],

                stateSave: true

            
            })
        } else {
            var HTML = [];
            $('#jxQuoteStatusGrid').html('<tr><td>No results found.</td></tr>')
        }

        $('#jxLoading').hide();


    },

    SetupGlobalEvents: function () {
        
        this.TargetElement.on('click', '.jxViewEdit', function () {
            var Button = $(this);
            var QuoteStatus = Button.data('quotestatus');
            var ShowViewEditQuoteStatusURL = '/utilities/uwquotestatusedit.aspx?QuoteStatus=' + QuoteStatus;
            ShowPopup("ViewEditQuoteStatus", ShowViewEditQuoteStatusURL, "Quote Status", 385, 300, CHSI.UW.QuoteStatus.Controllers.QuoteStatusAdminController.GetAllQuoteStatus, true);
        });

        this.TargetElement.on('click', '.jxQuickComments', function () {
            var Button = $(this);
            var QuoteStatus = Button.data('quotestatus');
            var ShowCommentQuoteStatusURL = '/utilities/uwquotestatusquickcomments.aspx?QuoteStatus=' + QuoteStatus;
            ShowPopup("CommentQuoteStatusURL", ShowCommentQuoteStatusURL, "Quick Comments", 400, 270, CHSI.UW.QuoteStatus.Controllers.QuoteStatusAdminController.GetAllQuoteStatus, true);
        });

        this.TargetAddElement.on('click', function () {

            var ShowAddQuoteStatusURL = '/utilities/uwquotestatusedit.aspx';
            ShowPopup("AddQuoteStatusURL", ShowAddQuoteStatusURL, "New Quote Status", 400, 270, CHSI.UW.QuoteStatus.Controllers.QuoteStatusAdminController.GetAllQuoteStatus, true);
        });                               
    }
}