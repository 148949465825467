CHSI.Common.Attachments = {    
    Init: function (ClientID, AddNew, QuoteID, Code) {
        var df = $.Deferred();


        this.Templates.Attachments = CHSITemplates.Template_AttachmentGrid;
        this.Cache.ClientID = ClientID;                
        this.Cache.QuoteID = QuoteID;

        if (QuoteID) {
            if (Code) {
                this.Cache.Code = Code;
            } else {
                this.Cache.Code = 'UWAtt'
            }          
            this.Calls.GetQuoteInfo(this.Cache.QuoteID).done(function(){
                df.resolve();
            });
        } else {
            CHSI.Common.Attachments.Calls.GetAttachmentSections(function () {
                CHSI.Common.Attachments.Calls.GetAttachmentsByClientID(function () {
                    CHSI.CM.AttachmentTree.Events.GenerateAttachmentTreeview(CHSI.Common.Attachments.Cache.AttachmentGlobal, false, false, false);
                }).done(function () { df.resolve(); });;
            });
        }

        this.Events.Global();
        this.Events.CreateUploader();

        if (AddNew == 1) {
            CHSI.Common.Attachments.Events.ShowFileUploader();
        }

        return df;
    },
    Templates: {
    },
    Cache: {
        AttachmentObject: {
        },
        ClientID: 0,
        FileAttachment: null,
        ArrFileAttachment: [],
        SectionID: 0
        , UWRule: null
        , GroupID: null
        , Code: 'UWAtt'
        , ClientInfo: null
        , QuoteID: null
        , QuoteType: 'CHSI1'
       , AttachmentGlobal: []
        , AllowDocuments: 'N'
        ,Editable:'Y'
    },
    BindFilteredQuery: function (NewAttachment) {
        $('#jxAttachmentOverview').html(this.Templates.Attachments({ Attachments: NewAttachment }));
        $('#jxAllowDocuments').val(CHSI.Common.Attachments.Cache.AllowDocuments);
        restart = true;
        sorttable.init();
        if (SetSize) {
            SetSize();
        }
    },
    BindPresentationLayer: function () {
        $('#jxAttachmentOverview').html(this.Templates.Attachments({ Attachments: CHSI.Common.Attachments.Cache.AttachmentObject }));
        $('#jxAllowDocuments').val(CHSI.Common.Attachments.Cache.AllowDocuments);
        restart = true;
        sorttable.init();
        //CHSI.UW.MuniQuote.DisablePage(CHSI.Common.Attachments.Cache.Editable);
        if (SetSize) {
            SetSize();
        }
    },
    BindAttachmentsBySectionID: function (SectionID) {
        var Attachments = CHSI.Common.Attachments.Cache.AttachmentObject,
            NewAttachments = [];

        for (i = 0; i < Attachments.length; i++) {
            if (Attachments[i].DefaultSectionID == SectionID) {
                NewAttachments.push(Attachments[i]);
            }
        }
        $('#jxAttachmentOverview').html(this.Templates.Attachments({ Attachments: NewAttachments }));
        $('#jxAllowDocuments').val(CHSI.Common.Attachments.Cache.AllowDocuments);
        restart = true;
        sorttable.init();
        if (SetSize)
        {
            SetSize();
        }
    },
    Events: {
        Global: function () {
            $('#jxAttachmentFilter').on('click', function () {
                $(this).val('');
            });

            $('#jxAttachmentFilter').on('keyup', function () {
                var SearchTerm = $(this).val(),
                    NewAttachmentObject = [];

                NewAttachmentObject.push(CHSI.Common.Attachments.Cache.AttachmentSections);
                NewAttachmentObject.push(CHSI.Common.Attachments.Cache.AttachmentObject);                

                if (SearchTerm == '') {
                    CHSI.CM.AttachmentTree.Events.GenerateAttachmentTreeview(NewAttachmentObject, false, false, false);
                    CHSI.Common.Attachments.BindFilteredQuery([]);
                }
                if (SearchTerm.length > 1) {
                    var NewAttachmentResult = CHSI.CM.AttachmentTree.Events.FilterAttachmentResults(SearchTerm, NewAttachmentObject);
                    CHSI.CM.AttachmentTree.Events.GenerateAttachmentTreeview(NewAttachmentResult, false, false, false);
                    CHSI.Common.Attachments.BindFilteredQuery(NewAttachmentResult[1]);
                } else {
                    CHSI.CM.AttachmentTree.Events.GenerateAttachmentTreeview(NewAttachmentObject, false, false, false);
                }
            });

           
            $('#uxShowNewFile').on('click', function () {
                CHSI.Common.Attachments.Events.ShowFileUploader();
            });

            $('#jxFilterResultsContainer').on('click', '.HierarchyNode', function () {
                var SectionID = $(this).data('sectionid');
                CHSI.Common.Attachments.Cache.SectionID = SectionID;
                $('.HierarchyNode').removeClass('SelectedSection');
                $(this).addClass('SelectedSection');

                CHSI.Common.Attachments.Cache.AllowDocuments = $(this).data('allowdocuments');                

                CHSI.Common.Attachments.Calls.GetAttachmentsByClientID();
                CHSI.Common.Attachments.BindAttachmentsBySectionID(SectionID);
            });

            $('#jxSaveNext').on('click', function () {
                CHSI.Common.Navigation.NavigateToNextTab();
            });

            $('#jxCancel').on('click', function () {
                CHSI.Common.Navigation.NavigateToReturnURL
                $(this).addClass('SelectedSection');
            });

        },
        RefreshAttachments:function () {
            CHSI.Common.Attachments.Calls.GetAttachments(CHSI.Common.Attachments.Cache.SectionID);
            $('.HierarchyNode[data-sectionid=' + CHSI.Common.Attachments.Cache.SectionID + ']').addClass('SelectedSection');
        },
        ShowFileUploader: function(AttachmentID, SectionID) {            
            URL = '/CM/Modals/FileUploadForm.aspx?SectionID=' + SectionID + '&AttachmentID=' + AttachmentID + '&clientid=' + CHSI.Common.Attachments.Cache.ClientID;
            ShowPopup('FileUploadForm', URL, 'File Upload', 520, 430, CHSI.Common.Attachments.Events.RefreshAttachments, true);
        },
        DeleteAttachment:function(AttachmentID, FileID, ItemDate, Description, FileName, PolicyPeriod, FileType, CreateUser, CreateTmsp, UpdateUser) {
            var AttachmentObject = {};
            AttachmentObject.AttachmentID = AttachmentID
            AttachmentObject.FileID = FileID;
            AttachmentObject.Status = 'Deleted';
            AttachmentObject.ItemDate = ItemDate;
            AttachmentObject.Description = Description;
            AttachmentObject.FileName = FileName;
            AttachmentObject.PolicyPeriod = PolicyPeriod;
            AttachmentObject.FileType = FileType;
            AttachmentObject.CreateUser = CreateUser;
            AttachmentObject.CreateTmsp = CreateTmsp;
            AttachmentObject.UpdateUser = UpdateUser;
            CHSI.Common.Attachments.Calls.DeleteAttachment(AttachmentObject);
            CHSI.Common.Attachments.BindAttachmentsBySectionID(CHSI.Common.Attachments.Cache.SectionID);
        },
        GetAllowDocuments: function (SectionID) {
            CHSI.Common.Attachments.Cache.AllowDocuments = 'N';
            if (CHSI.Common.Attachments.Cache.AttachmentSections) {
                for (i = 0; i < CHSI.Common.Attachments.Cache.AttachmentSections.length; i++) {
                    if (SectionID == CHSI.Common.Attachments.Cache.AttachmentSections[i].SectionID) {
                        CHSI.Common.Attachments.Cache.AllowDocuments = CHSI.Common.Attachments.Cache.AttachmentSections[i].AllowDocuments;
                    }
                }
            }
        },
        CreateUploader: function () {
            /* Uploader Script */
      
            var Uploader = $('#jxUploadAttachment').fineUploader({
                element: document.getElementById('jxUploadAttachment'),
                request: { endpoint:'/CHSIUploadHandler.axd'},
                debug: true,
                multiple: true,
                disableDefaultDropzone: true,
                callbacks: {
                    onSubmit: function (id, fileName) {
                        //$('.qq-upload-list').empty();                    
                    },
                    onProgress: function (id, fileName, loaded, total) {
                        $('.Loading').show();
                    },
                    onComplete: function (id, fileName, response) {
                        if (response.success == true) {
                            $('.qq-upload-list').hide();
                            $('.Loading').hide();

                            var fileInfo = {
                                FileID: response.FileID,
                                FileName: fileName,
                            };
                            CHSI.Common.Attachments.Cache.FileAttachment = fileInfo;
                       
                            if ($('#jxAllowDocuments').val() == 'Y') {
                                CHSI.Common.Attachments.Cache.ArrFileAttachment.push(fileInfo);
                            }
                        
                        }
                        else {
                            alert(response.ErrorMessage);
                        }
                    },
                    onAllComplete: function (successful, failed) {
                        if (CHSI.Common.Attachments.Cache.ArrFileAttachment.length == 0)
                        {
                            return false;
                        }
                        var FileIDs='';
                        var FileNames = '';
                        var CommaSeparator = '';
                        for (var j = 0; j < CHSI.Common.Attachments.Cache.ArrFileAttachment.length; j++) {
                            FileIDs = FileIDs +CommaSeparator+ CHSI.Common.Attachments.Cache.ArrFileAttachment[j].FileID;
                            FileNames = FileNames + CommaSeparator + CHSI.Common.Attachments.Cache.ArrFileAttachment[j].FileName;
                            CommaSeparator = ',';
                        }

                        if ($('#jxAllowDocuments').val() == 'Y') {
                            var ExtraHeigth = CHSI.Common.Attachments.Cache.ArrFileAttachment.length * 20;
                            URL = '/CM/Modals/FileUploadForm.aspx?SectionID=' + CHSI.Common.Attachments.Cache.SectionID + '&FileID=' + FileIDs + '&clientid=' + CHSI.Common.Attachments.Cache.ClientID + '&FileName=' + encodeURIComponent(FileNames);
                            ShowPopup('FileUploadForm', URL, 'File Upload', 520, 430  , CHSI.Common.Attachments.Events.RefreshAttachments, true);
                            $('.qq-upload-list').empty();
                            CHSI.Common.Attachments.Cache.ArrFileAttachment=[];
                        } else {
                            alert('Attachments cannot be placed in this section at this time.');
                            return false;
                        }
                    }
                }
            });
            Uploader.on('submit', function () {
                if (CHSI.Common.Attachments.Cache.SectionID == 0)
                {
                    alert('Please select a section before uploading files');
                    return false;
                }
                for (var SectionIndex = 0; SectionIndex < CHSI.Common.Attachments.Cache.AttachmentSections.length; SectionIndex++) {
                    if (CHSI.Common.Attachments.Cache.AttachmentSections[SectionIndex].SectionID == CHSI.Common.Attachments.Cache.SectionID)
                    {
                        if (CHSI.Common.Attachments.Cache.AttachmentSections[SectionIndex].AllowDocuments == 'N')
                        {
                            alert('This category does not allow files to be uploaded, please select another');
                            return false;
                        }
                    }
                }
                
            })
            

            function ValidateDropZone() {
                if (!$('.qq-upload-drop-area-active')[0]) {                    
                    $('.qq-upload-drop-area').hide();
                    setTimeout(ValidateDropZone, 2000);
                } else {                    
                    $('.qq-upload-drop-area').show();
                    setTimeout(ValidateDropZone, 2000);
                }
            }
            ValidateDropZone();
        }
    },
    Calls: {
        GetAttachments: function (SectionID) {
            CHSI.Common.Attachments.Cache.SectionID = SectionID;

            var AjaxURL = '/api/Common/Attachment/GetByClientSection?ClientID=' + CHSI.Common.Attachments.Cache.ClientID + '&SectionID=' + SectionID + '&PolicyPeriod=' + CHSI.Common.Attachments.Cache.PolicyPeriod;
            $.ajax({
                async: true,
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Common.Attachments.Cache.AttachmentObject = e;
                    CHSI.Common.Attachments.BindPresentationLayer();
                    restart = true;
                    sorttable.init();
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },
        GetAttachmentsByClientID: function (successFunction) {
            var df = $.Deferred();
            var AjaxURL = '/api/Common/Attachment/GetAttachmentsByClientID?ClientID=' + CHSI.Common.Attachments.Cache.ClientID;
            $.ajax({
                async: true,
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Common.Attachments.Cache.AttachmentObject = e;
                    CHSI.Common.Attachments.Cache.AttachmentGlobal.push(e);
                    if (successFunction) {
                        successFunction();
                    }
                    df.resolve();
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                    df.reject();
                }
            });
            return df;
        },
        GetAttachmentSections: function (successFunction) {
            var df = $.Deferred();
            var AjaxURL = '/api/Common/Attachment/GetFileSections?ClientID=' + CHSI.Common.Attachments.Cache.ClientID;
            $.ajax({
                async: true,
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Common.Attachments.Cache.AttachmentSections = e;
                    CHSI.Common.Attachments.Cache.AttachmentGlobal = [e];
                    if (successFunction) {
                        successFunction();                        
                    }               
                    df.resolve();
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                    df.reject();
                }
            });
            return df;
        },
        DeleteAttachment: function (AttachmentObject) {
            var strconfirm = confirm("Are you sure you want to delete?");
            if (strconfirm == false) {
                return false;
            }

            var AjaxData = null
            AjaxData = JSON.stringify(AttachmentObject);

            var AjaxURL = '/api/Common/Attachment/SaveAttachment';
            $.ajax({
                async: true,
                type: "POST",
                url: AjaxURL,
                cache: false,
                data: AjaxData,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Common.Attachments.Calls.GetAttachments(CHSI.Common.Attachments.Cache.SectionID);                    
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },
        GetUWRule: function (QuoteType,GroupID, PolicyPeriod, Code) {
            CHSI.Common.Ajax.Get({Async: true,
                    URL: '/api/UW/UW/GetRules?QuoteType=' + QuoteType + '&GroupID=' + GroupID + '&PolicyPeriod=' + PolicyPeriod + '&Code=' + Code,
                    Success: function (e) {
                        CHSI.Common.Attachments.Cache.UWRule = e;
                        if (e.RuleValue) {
                            CHSI.Common.Attachments.Cache.SectionID = CHSI.Common.Attachments.Cache.UWRule.RuleValue;
                            CHSI.Common.Attachments.Events.GetAllowDocuments(CHSI.Common.Attachments.Cache.SectionID);
                            CHSI.Common.Attachments.Calls.GetAttachments(CHSI.Common.Attachments.Cache.SectionID);
                        } else {
                            if (Code != 'UWAtt') {
                                CHSI.Common.Attachments.Cache.Code = 'UWAtt';
                                CHSI.Common.Attachments.Calls.GetUWRule(QuoteType, GroupID, PolicyPeriod, CHSI.Common.Attachments.Cache.Code);
                            }
                        }
                    }
                })
        },
        GetClientInfo: function (ClientID) {
            CHSI.Common.Ajax.Get({Async:true,
                URL: '/api/CRM/Clients/GetClient?ClientID=' + ClientID,
                Success: function (e) {
                    CHSI.Common.Attachments.Cache.ClientInfo = e;
                    if (e) {
                        var GroupID = CHSI.Common.Attachments.Cache.ClientInfo.GroupID;
                        var QuoteType = CHSI.Common.Attachments.Cache.QuoteType;
                        var Code = CHSI.Common.Attachments.Cache.Code;
                        var PolicyPeriod = CHSI.Common.Attachments.Cache.PolicyPeriod;
                        CHSI.Common.Attachments.Calls.GetAttachmentSections(function () { CHSI.Common.Attachments.Calls.GetUWRule(QuoteType, GroupID, PolicyPeriod, Code) });                        
                    }
                }
            })          
        },
        GetPolicyPeriod: function (QuoteID) {
            CHSI.Common.Ajax.Get({Async: true,
                URL: '/api/UW/UW/GetPolicyYearByQuote?QuoteID=' + QuoteID,
                Success: function (e) {
                    CHSI.Common.Attachments.Cache.PolicyPeriod = e;
                    if (e) {
                        CHSI.Common.Attachments.Calls.GetClientInfo(CHSI.Common.Attachments.Cache.ClientID);
                    }
                }
            })          
        },
        GetQuoteInfo: function (QuoteID) {
            var df = $.Deferred();
            var Options = {}
            Options.Async = true;
            Options.URL = '/api/UW/MuniQuote/GetBasicQuote?QuoteID=' + CHSI.Common.Attachments.Cache.QuoteID;
            Options.Success = function (e) {
                if (e.QuoteStatus && e.QuoteStatus.Editable == 'N') {
                    CHSI.Common.Attachments.Cache.Editable = 'N';
                }
                CHSI.Common.Attachments.Cache.QuoteType = e.QuoteType;
                CHSI.Common.Attachments.Cache.PolicyPeriod = e.PolicyPeriod;
                var SecOptions = {},
                    isVerified = false;
                SecOptions.PermissionID = 14;
                SecOptions.Success = function (v) {                    
                    CHSI.Common.Attachments.Calls.GetClientInfo(CHSI.Common.Attachments.Cache.ClientID);
                    isVerified = v;

                    if (isVerified) {
                        CHSI.UW.MuniQuote.MenuController(e, '#jxMenuContainer');
                    }
                }

                CHSI.Security.HasPermission(SecOptions);
                df.resolve();
            }
            CHSI.Common.Ajax.Get(Options);
            return df;
        }
    }
}
