module PL.Claims.APIs {

    export class IntakeFormRepo {

        private static _routePrefix: string = "/api/ClaimSystem/Form/";

        public static get(incidentID: number, formTypeID: number, formTypeName: string = null): JQueryDeferred<Models.IntakeForm> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'GetIntakeForm?ClaimIncidentReportID=' + incidentID + '&FormTypeID=' + formTypeID + '&FormTypeName=' + formTypeName).done(function (data) {
                results.resolve(data);
            });
            return results;
        }
    }

} 