module PL.UW.LossHistory.API {

    export class LossHistoryAPI {

        static getPriorCoverage(PriorCoverageID: number, SuccessFunction?: (data) => any) {
            var URL: string = '/API/UW/PriorCoverage/GetPriorCoverage?PriorCoverageID=' + PriorCoverageID;
            $.get(URL).done(function (data) {

            }).then(function (data) {
                if (SuccessFunction) {
                    SuccessFunction(data);
                }
            });
        }

        static getPriorCoverageLoss(PriorLossID: number, SuccessFunction?: (data) => any): any {
            var URL = '/API/UW/PriorCoverageLoss/GetPriorCoverageLoss?PriorLossID=' + PriorLossID;
            $.get(URL).done(function () { }).then(function (data) {
                if (SuccessFunction) {
                    SuccessFunction(data);
                }
            });
        }        

        static getPriorCoverageLosses(PriorCoverageID: number, SuccessFunction?: (data) => any): any {
            var URL = '/API/UW/PriorCoverageLoss/GetPriorCoverageLosses?PriorCoverageID=' + PriorCoverageID;
            $.get(URL).done(function () { }).then(function (data) {
                if (SuccessFunction) {
                    SuccessFunction(data);
                }
            });
        }      
        
        static getPriorCoverageDetail(PriorDetailID: number, SuccessFunction?: (data) => any): any {
            var URL = '/API/UW/PriorCoverageDetail/GetPriorCoverageDetail?PriorDetailID=' + PriorDetailID;
            $.get(URL).done(function () { }).then(function (data) {
                if (SuccessFunction) {
                    SuccessFunction(data);
                }
            });
        }

        static getPriorCoverageDetails(PriorCoverageID: number, GroupID: string, EffectiveDate: string, SuccessFunction?: (data) => any): any {
            var URL = '/API/UW/PriorCoverageDetail/GetPriorCoverageDetails?PriorCoverageID=' + PriorCoverageID + '&GroupID=' + GroupID + '&EffectiveDate=' + EffectiveDate;
            $.get(URL).done(function () { }).then(function (data) {
                if (SuccessFunction) {
                    SuccessFunction(data);
                }
            });
        }

        static updatePriorCoverageUseLosses(priorCoverageID: number, useLosses: string): JQueryDeferred<any> {
            var URL: string = '/API/UW/PriorCoverage/UpdatePriorCoverageUseLosses?PriorCoverageID=' + priorCoverageID + '&UseLosses=' + useLosses;
            var result = $.Deferred();
            $.post(URL).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

        static ImportPriorCoverageLosses(ClientID: number, PolicyPeriod: number, AsOfDate: string, PriorCoverageID: number, StartDate: string, EndDate: string, SuccessFunction?: () => any) {
            var URL: string = '/API/UW/PriorCoverageLoss/ImportPriorCoverageLosses?ClientID=' + ClientID + '&PolicyPeriod=' + PolicyPeriod + '&AsOfDate=' + AsOfDate + '&PriorCoverageID=' + PriorCoverageID + '&StartDate=' + StartDate + '&EndDate=' + EndDate;
            $.get(URL).done(function () {
            }).then(function () {
                if (SuccessFunction) {
                    SuccessFunction();
                }
            });
        }

        static getClassCode(ClassCode: string): JQueryDeferred<number> {            
            var result = $.Deferred();
            var URL: string = '/api/UW/ClassCode/GetClassCodeID?ClassCode=' + ClassCode;
            $.get(URL).done(function (data) {                
                result.resolve(data);
            });            
            return result;
        }
        static getStates(GroupName: string, SuccuessFunction?: (data) => any) {
            var URL = '/API/UW/ClassCode/GetClassCodeStates?GroupName=' + GroupName;
            $.get(URL).done(function () { }).then(function (data) {
                if (SuccuessFunction) {
                    SuccuessFunction(data);
                }
            });
        }

        static savePriorCoverageLosses(PriorCoverageLossObject: Model.PriorCoverageLoss, SuccessFunction?: (data) => any): any {
            var URL = '/API/UW/PriorCoverageLoss/SavePriorCoverageLosses';
            $.post(URL, PriorCoverageLossObject).done(function () { }).then(function (data) {
                if (SuccessFunction) {
                    SuccessFunction(data);
                }
            });
        }

        static savePriorCoverageDetails(dataObject: any, SuccuessFunction?: (data) => any): any {
            var URL = '/API/UW/PriorCoverageDetail/SavePriorCoverageDetail';
            $.post(URL, dataObject).done(function () { }).then(function (data) {
                if (SuccuessFunction) {
                    SuccuessFunction(data);
                }
            });
        }

        static savePriorCoverage(priorCoverageObject: PL.UW.LossHistory.Model.PriorCoverage, SuccessFunction?: (data) => any) {
            var URL: string = '/API/UW/PriorCoverage/SavePriorCoverage';
            $.post(URL, priorCoverageObject).done(function () {
            }).then(function (data) {
                if (SuccessFunction) {
                    SuccessFunction(data);
                }
                });
        }
    }

} 