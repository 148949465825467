module PL.Que.Models {

    export class ElementCalculations {

        ElementID: number;
        CalculationType: any; // enum
        Format: string;

    }

}