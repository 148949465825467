
module PL.AdminTools.Controllers {
    export class QuoteDocsBatchEditController {

        QuoteDoc: PL.AdminTools.Models.QuoteDocumentBatch;
        $quoteDocContainer: JQuery = $('#jxQuoteDocContainer');
        $parameterContainer: JQuery;
        GroupLineProducts: CL.UW.Model.GroupProductLine[];
        AttachmentCategories: CL.Common.Models.AttachmentCategory[];

        constructor() {

        }

        init(ID?: number): void {

            var _self = this;
            if (ID != null) {
                Models.QuoteDocumentBatch.getFromAPI(ID).done(
                    function (quoteDocumentBatch) {
                        _self.setupWithQuoteDoc(quoteDocumentBatch);

                    });
            } else {
                _self.setupWithQuoteDoc(new PL.AdminTools.Models.QuoteDocumentBatch());

            }

        }

        setupWithQuoteDoc(quoteDoc: Models.QuoteDocumentBatch): void {
            var _self = this;
            _self.QuoteDoc = quoteDoc;
            CL.UW.Model.GroupProductLine.getGroupProductLines().done(
                function (groupLineDocuments) {
                    _self.GroupLineProducts = groupLineDocuments;

                    CL.Common.Models.AttachmentCategory.getAttachmentCategories().done(
                        function (catData) {
                            _self.AttachmentCategories = catData;
                            _self.bindPresentationLayer();
                            _self.setupEvents();

                        });

                });

        }
        setupEvents(): void {
            var _self = this;
            $('#jxCustomSchedule').attr('disabled', 'disabled');

            _self.$quoteDocContainer.off().on('click', '#jxAttachmentCategoryName', function () {
                if ($('#jxAttachmentIDContainer').is(':visible')) {
                    $('#jxAttachmentIDContainer').hide();
                } else {
                    $('#jxAttachmentIDContainer').show();
                }

            });

            _self.$quoteDocContainer.on('click', '.genericNavWrap ul li', function () {
                var attachmentCategoryID: number = $(this).children('a').data('attachmentcategoryid');
                $('#jxAttachmentIDContainer').hide();
            });

            $('#jxSave').on('click', function () {
                if (_self.validateForm()) {
                    _self.QuoteDoc.updateFromForm();
                    _self.QuoteDoc.save().done(
                        function () {
                            GUI.Windows.Popup.closeThis(true);
                        });
                }

            });

            _self.$quoteDocContainer.on('change', '#jxGroupProductLine', function () {
                _self.bindGroupLineDocuments();
            });

            _self.$quoteDocContainer.on('change', '#jxSchedule', function () {
                if ($(this).val() == 'custom') {
                    $('#jxCustomSchedule').removeAttr('disabled');

                } else {
                    $('#jxCustomSchedule').attr('disabled', 'disabled');
                }
            });

            var isCustom = false;
            $('#jxSchedule > option').each(function () {
                if (_self.QuoteDoc && $(this).val() == _self.QuoteDoc.Schedule) {

                    $('#jxSchedule').val($(this).val());
                    $('#jxCustomSchedule').attr('disabled', 'disabled');
                    $('#jxCustomSchedule').val('');
                    return false;
                } else {

                    $('#jxSchedule').val('custom');
                    $('#jxCustomSchedule').removeAttr('disabled');
                    if (_self.QuoteDoc) {
                        $('#jxCustomSchedule').val(_self.QuoteDoc.Schedule);
                    }

                }
            });

            $('#jxCancel').on('click', function () {
                GUI.Windows.Popup.closeThis(true);
            });
        }

        validateForm(): boolean {
            if ($('#jxSQLSource').val() == '' || $('#jxExportName').val() == '' || $('#jxFileName').val() == '' || $('#jxDestinationAddress').val() == '' || (($('#jxDestination').val() == 1 || $('#jxDestination').val() == 4) && ($('#jxDestinationUserName').val() == '' || $('#jxDestinationPassword').val() == '')) || $('#jxSchedule').val() == undefined) {
                alert('One or more fields is empty. Please fill them out before continuing.');
                return false;
            } else {
                return true;
            }
        }

        bindPresentationLayer(): void {
            var _self = this;
            GUI.Mustache.QuickBind($('#Template_QuoteDocument').html(), { QuoteDoc: _self.QuoteDoc, AttachmentCategories: _self.AttachmentCategories }, _self.$quoteDocContainer);

            var HTML = CL.UW.Views.GroupProductLineDropDown.getDropDownValues(_self.GroupLineProducts, _self.QuoteDoc.ProductLineID);

            $('#jxGroupProductLine').html(HTML);
            _self.bindGroupLineDocuments();

        }

        bindGroupLineDocuments(): void {
            var GroupProductLine = $('#jxGroupProductLine').val();
            var _self = this;
            if (GroupProductLine != '') {
                CL.UW.Model.GroupLineDocument.getGroupDocumentType(GroupProductLine).done(
                    function (GroupDocuments) {
                        var HTML = '';
                        var existingGroupDocumentID = (_self.QuoteDoc) ? _self.QuoteDoc.DocumentGroupID : 0;
                        HTML = CL.UW.Views.GroupLineDocumentDropDown.getDropDownValues(GroupDocuments, existingGroupDocumentID);
                        $('#jxGroupDocumentID').html(HTML);

                    });

            }

        }

    }

} 