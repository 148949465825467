declare var sorttable: any;
declare var restart: any;

module PL.AdminTools.Controllers {

    export class ClaimIntakeEdit extends PL.AdminTools.Controllers.Base_ClaimIntakeEdit {
        _$parentContainer: JQuery = $('#formTypeEditContainer');
        _$categoriesContainer: JQuery;

        FormTypeObject: PL.Claims.Models.FormType = new PL.Claims.Models.FormType;
        GroupObject: PL.CRM.Models.Group[] = new Array<PL.CRM.Models.Group>();
        ContentTypes: PL.Common.Models.ContentType[];

        constructor() {
            super();
        }

        init(formTypeID?: number): void {
            this.FormTypeObject = new PL.Claims.Models.FormType;
            this.FormTypeObject.FormTypeID = formTypeID;            
            if (this.FormTypeObject.FormTypeID != null) {
                this.FormTypeObject.getCompleteForm().done(() => {
                    this.initEvents();
                });
            } else {
                this.initEvents();
            }
        }

        initEvents(): void {
            new PL.Common.Models.ContentType().get().done((contentTypes) => {
                new PL.CRM.Models.Group().getActiveGroups().done((groups) => {

                    this.GroupObject = groups;
                    this.ContentTypes = contentTypes;

                    this.handlebarHelpers();

                    this.Information().init(this).done(() => {     
                                    
                        this.Categories().init(this).done(() => {
                            
                            this.Fields().init(this).done(() => {

                                if (this.FormTypeObject.FormTypeID != null) {
                                    this.Categories().setup();
                                    this.Fields().setupEvents();
                                    this.Fields().setup();
                                }

                                this.setupEvents();

                                this.events();

                            });
                        });

                    });
                });
            });       
        }

        setupEvents(): void {

            super.setupEvents();
            super.bindSortable();

            if (this.FormTypeObject.FormTypeID == null) {
                $('#formTypeEditTabs').tabs("option", "disabled", [1, 2]);
            }

            restart = true;
            sorttable.init();
        }

        events(): void {

            var _self = this;

            this._$parentContainer.on('sortstart', '.sortableFormTypeEditTable tbody', function (event, ui) {
                ui.item.css('box-shadow', '0 0 10px #000000');
                ui.item.css('background-color', 'white');
            });

            this._$parentContainer.on('sortstop', '.sortableFormTypeEditTable tbody', function (event, ui) {
                ui.item.css('box-shadow', '');
                _self.updateRowColor();
                _self.sortOrder();

                if ($(this).parent().hasClass('fieldTable')) {
                    $('#fieldSave').val(' Save Updated Field Sortorder ').prop('disabled', false);
                }
                if ($(this).parent().hasClass('categoryTable')) {
                    $('#catSave').val(' Save Updated Category Sortorder ').prop('disabled', false);
                }
            });

            this._$parentContainer.on('click', '.okClose', function () {
                $(this).val(' Saving... ');
                $(this).prop('disabled', true);
                // this is really save all                
                var remainingUnsavedFields = 0;
                new PL.Claims.Models.FormType().saveFormType(_self.FormTypeObject).done(() => {
                    new PL.Claims.Models.FormCategory().saveCategories(_self.FormTypeObject.FormCategories).done(() => {
                        if (_self.FormTypeObject.FormCategories.length > 0) {
                            remainingUnsavedFields = _self.FormTypeObject.FormCategories.length;
                            _self.FormTypeObject.FormCategories.forEach((fcO, fcI, fc) => {
                                new PL.Claims.Models.FormField().saveFormFields(fcO.FormFields).done(() => {
                                    remainingUnsavedFields = remainingUnsavedFields - 1;
                                    if (remainingUnsavedFields == 0) {
                                        closeThis(true);
                                    }
                                });
                            });
                        } else {
                            closeThis(true);
                        }
                    });
                });
            });

            this._$parentContainer.on('click', '.cancel', function () {
                closeThis(true);
            });

        }


    }

}