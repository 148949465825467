CHSI.Page.SCategories = {

    Init: function (successFunction) {
        var qs = jQuery.parseQuerystring();

        var Template_ScheduleCategories = $('#Template_ScheduleCategories').html();
        this.Templates.ScheduleCategories = Handlebars.compile(Template_ScheduleCategories);
        if (CHSI.Page.SInformation.Cache.ScheduleTypeID) {
            this.Calls.GetScheduleCategories(successFunction);
        }
        else {
            CHSI.Page.SCategories.Cache.ScheduleCategoriesObject = { ScheduleCategories: [] };
            CHSI.Page.SCategories.BindPresentationLayer();
            CHSI.Page.SCategories.Cache.SaveFlag = false;
            if (successFunction)
            { successFunction(); }
        }

        CHSI.Page.SCategories.Events.Global();

    },
    Templates: {

    },
    Cache: {
        ScheduleCategoryID: null,
        ScheduleCategoriesObject: null,
        SaveFlag: false

    },
    BindPresentationLayer: function () {
        $('#jxScheduleCategoriesContainer').html(this.Templates.ScheduleCategories(this.Cache.ScheduleCategoriesObject));
        restart = true;
        sorttable.init();
    },

    SaveArray: function () {
        if (CHSI.Page.SCategories.Cache.ScheduleCategoriesObject) {
            if (CHSI.Page.SCategories.Cache.ScheduleCategoriesObject.ScheduleCategories) {
                for (i = 0; i < CHSI.Page.SCategories.Cache.ScheduleCategoriesObject.ScheduleCategories.length; i++) {
                    if (CHSI.Page.SCategories.Cache.ScheduleCategoriesObject.ScheduleCategories[i].ScheduleCategoryID == 0) {
                        CHSI.Page.SCategories.Cache.ScheduleCategoriesObject.ScheduleCategories[i].ScheduleCategoryID = null;
                    }
                    CHSI.Page.SCategories.Cache.ScheduleCategoriesObject.ScheduleCategories[i].CategoryName = $('#uxSCCategoryName_' + i).val();
                    if ($('#uxSCPortalShow_' + i).is(":checked")) {
                        CHSI.Page.SCategories.Cache.ScheduleCategoriesObject.ScheduleCategories[i].PortalShow = true;
                    } else
                        CHSI.Page.SCategories.Cache.ScheduleCategoriesObject.ScheduleCategories[i].PortalShow = false;

                    CHSI.Page.SCategories.Cache.ScheduleCategoriesObject.ScheduleCategories[i].SortOrder = $('#uxSCSortOrder_' + i).val();
                }
            }

        }
    },
    Events: {
        Global: function () {

            $('#jxSCButtonsLeft').on('click', '#uxScheduleCategoryAddField', function () {
                CHSI.Page.SCategories.Events.AddField();
            });

            $('#jxSCButtonsRight').on('click', '#uxScheduleCategoryOK', function () {
                if (!CHSI.Page.SCategories.Cache.SaveFlag) {
                    if (CHSI.Page.SInformation.Cache.ScheduleTypeID > 0) {
                        $(this).attr('disabled', 'disabled');
                        CHSI.Page.SCategories.Calls.SaveCategories();
                        closeThis(true);
                    } else {
                        alert('Please save a new ScheduleType before add a category');
                    }

                }
            });


        },
        AutoSave: function () {
            if (!CHSI.Page.SCategories.Cache.SaveFlag && CHSI.Page.SInformation.Cache.ScheduleTypeID > 0) {
                CHSI.Page.SCategories.Calls.SaveCategories();

            }

        },
        AddField: function () {

            if (CHSI.Page.SInformation.Cache.ScheduleTypeID) {
                CHSI.Page.SCategories.SaveArray();
                var Category = new Object();

                Category = {
                    ScheduleCategoryID: 0,
                    ScheduleTypeID: CHSI.Page.SInformation.Cache.ScheduleTypeID,
                    CategoryName: null,
                    PortalShow: true,
                    SortOrder: 0,
                    CreateUser: null,
                    CreateTmsp: null,
                    UpdateUser: null,
                    UpdateTmsp: null,
                    FieldCount: null
                }
                if (CHSI.Page.SCategories.Cache.ScheduleCategoriesObject.ScheduleCategories == null)
                { CHSI.Page.SCategories.Cache.ScheduleCategoriesObject.ScheduleCategories = [];}
                CHSI.Page.SCategories.Cache.ScheduleCategoriesObject.ScheduleCategories.push(Category);
                CHSI.Page.SCategories.BindPresentationLayer();
            } else {
                alert('Please save a new ScheduleType before add a category');
            }
        },

        DeleteScheduleCateogory: function (ScheduleCategoryID, index) {
            CHSI.Page.SCategories.SaveArray();
            if (ScheduleCategoryID) {
                CHSI.Page.SCategories.Calls.DeleteCategory(ScheduleCategoryID);
            }
            CHSI.Page.SCategories.Cache.ScheduleCategoriesObject.ScheduleCategories.splice(index, 1);
            CHSI.Page.SCategories.BindPresentationLayer();
        }

    },
    Calls: {

        GetScheduleCategories: function (successFunction) {
            CHSI.Page.SCategories.Cache.SaveFlag = true;

            var ScheduleTypeID = CHSI.Page.SInformation.Cache.ScheduleTypeID;

            var AjaxURL = '/api/UW/ScheduleCategory/GetByType?ScheduleType=' + ScheduleTypeID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: true,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {

                    CHSI.Page.SCategories.Cache.ScheduleCategoriesObject = { ScheduleCategories: e };
                    CHSI.Page.SCategories.BindPresentationLayer();
                    CHSI.Page.SCategories.Cache.SaveFlag = false;

                    if (successFunction)
                    { successFunction(); }
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                    CHSI.Page.SCategories.Cache.SaveFlag = false;
                }
            });
        },


        DeleteCategory: function (CategoryID) {
            CHSI.Page.SCategories.Cache.SaveFlag = true;
            var AjaxURL = '/api/UW/ScheduleCategory/Delete?CategoryID=' + CategoryID;
            $.ajax({
                type: "Delete",
                url: AjaxURL,
                cache: true,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.SCategories.Cache.SaveFlag = false;
                    // CHSI.Page.SCategories.BindPresentationLayer();                   

                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                    CHSI.Page.SCategories.Cache.SaveFlag = false;
                }
            });
        },


        SaveCategories: function () {
            if (!CHSI.Page.SCategories.Cache.SaveFlag) {
                CHSI.Page.SCategories.Cache.SaveFlag = true;
                CHSI.Page.SCategories.SaveArray();
                var ajaxData = [];
                ajaxData = CHSI.Page.SCategories.Cache.ScheduleCategoriesObject.ScheduleCategories
                var ajaxString = JSON.stringify(ajaxData);

                var AjaxURL = '/api/UW/ScheduleCategory/SaveMultiple'
                $.ajax({
                    type: "POST",
                    url: AjaxURL,
                    data: ajaxString,
                    cache: true,
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    success: function (e) {
                        $('#uxScheduleCategoryOK').removeAttr('disabled');
                        CHSI.Page.SCategories.Calls.GetScheduleCategories();


                    },
                    error: function (e) {
                        CHSI.Common.LogObject(e);
                        CHSI.Page.SCategories.Cache.SaveFlag = false;
                        $('#uxScheduleCategoryOK').removeAttr('disabled');
                    }
                });
            }
        }

    }

}