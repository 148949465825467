module CL.UW.Views
{

    export class GroupProductLineDropDown {
        public static getDropDownValues(GroupProductLines: Model.GroupProductLine[], DocumentGroupID?: number)
        {
            var HTML = '';
            $.each(GroupProductLines, function () {
                var GroupLineProduct = this;
                var Selected = (DocumentGroupID == GroupLineProduct.GroupLineID) ? 'selected' : '';
                HTML += '<option value="' + GroupLineProduct.GroupLineID + '" ' + Selected + '>' + GroupLineProduct.GroupName + ' ' + GroupLineProduct.LineName + '</option>';
            });


            return HTML;
        }
    }
}