var Template_IncidentSearch_GroupResults = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "    <div style=\"color: Red; font-size: 16px;\">\r\n        More than 100 records found, please refine your search.\r\n    </div>\r\n";
  },"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "            <table cellspacing=0 cellpadding=0 class=\"dtable sortable\"><thead><tr><td style=\"width: 80px\">Group</td><td style=\"width: 200px\">Client</td><td style=\"width: 80px\">Incident No.</td><td style=\"width: 100px\">Incident Date</td><td style=\"width: 150px\">Employee Name</td><td style=\"width: 100px\">Status</td><td style=\"width: 80px\">&nbsp;</td></tr></thead><tbody>";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.IncidentTableRecord : depth0), {"name":"each","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</tbody></table>\r\n";
},"4":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;
  return "<tr><td style=\"width: 80px\">"
    + escapeExpression(lambda((depth0 != null ? depth0.GroupID : depth0), depth0))
    + "</td><td style=\"width: 200px\">"
    + escapeExpression(((helpers.TruncateString || (depth0 && depth0.TruncateString) || helperMissing).call(depth0, (depth0 != null ? depth0.ClientName : depth0), 34, {"name":"TruncateString","hash":{},"data":data})))
    + "</td><td style=\"width: 80px\">"
    + escapeExpression(lambda((depth0 != null ? depth0.IncidentID : depth0), depth0))
    + "</td><td style=\"width: 100px\">"
    + escapeExpression(((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || helperMissing).call(depth0, (depth0 != null ? depth0.IncidentDate : depth0), {"name":"CHSIDate","hash":{},"data":data})))
    + "</td><td style=\"width: 150px\">"
    + escapeExpression(((helpers.TruncateString || (depth0 && depth0.TruncateString) || helperMissing).call(depth0, (depth0 != null ? depth0.EmployeeName : depth0), 20, {"name":"TruncateString","hash":{},"data":data})))
    + "</td><td style=\"width: 100px\">"
    + escapeExpression(lambda((depth0 != null ? depth0.Status : depth0), depth0))
    + "</td><td style=\"width: 80px\">[<a href=\"/sig/Claims/details.aspx?id="
    + escapeExpression(lambda((depth0 != null ? depth0.IncidentID : depth0), depth0))
    + "\" class=\"minilink\">view</a>][<a href=\""
    + escapeExpression(((helpers.CreatePrintURL || (depth0 && depth0.CreatePrintURL) || helperMissing).call(depth0, (depth0 != null ? depth0.FormType : depth0), ((stack1 = (depth0 != null ? depth0.FormTypeObject : depth0)) != null ? stack1.ReportForm : stack1), (depth0 != null ? depth0.IncidentID : depth0), {"name":"CreatePrintURL","hash":{},"data":data})))
    + "\" target=\"_blank\" class=\"minilink\">print</a>]</td></tr>";
},"6":function(depth0,helpers,partials,data) {
  return "            <p>No incidents found using the supplied search filters.  Please refine your search and try again...</p>\r\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "﻿";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ShowTooManyRecords : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<br /><span style=\"font-size: 20px;\">Reported Incidents</span><hr style=\"color: #7ba2d6;\" />\r\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.IncidentTableRecord : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.program(6, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});