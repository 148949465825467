namespace PL.Common.Models {
    export class Chore {
        ChoreType: Enums.ChoreType
        Enabled: Boolean
        Recurrance: String
        NextExecution: Date
        LastExecution: Date

        constructor(source) {
            this.ChoreType = source.ChoreType;
            this.Enabled = source.Enabled;
            this.Recurrance = source.Recurrance;
            this.NextExecution = source.NextExecution;
            this.LastExecution = source.LastExecution;
            //mW.classes.propagatePropertyValues(source, this);
        }
 
    }
}