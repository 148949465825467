declare module CHSI.Forms { var isPortal: any; var qsObject: any;  };
module PL.Que.Utilities {

    export class QuestionnaireV2 {

        constructor() { }
        public static showScheduleInCurrentWindow(): void {
            //window.location.href = "/UW/Schedules/Default.aspx";
            if (CHSI.Forms.isPortal == undefined || CHSI.Forms.isPortal == null) {
                CHSI.Forms.isPortal = false;
            }
            if (CHSI.Forms.isPortal) {
                window.location.href = "/UW/Schedules/Default.aspx";
            } else {
                window.location.href = "/sig/underwriting/Schedules.aspx?clientid=" + CHSI.Forms.qsObject.clientid;
            }
        }
        // Opens Schedules in a new window

        public static showScheduleInNewWindow(): void {
            //window.open("/UW/Schedules/Default.aspx");
            if (CHSI.Forms.isPortal == undefined || CHSI.Forms.isPortal == null) {
                CHSI.Forms.isPortal = false;
            }
            if (CHSI.Forms.isPortal) {
                window.open("/UW/Schedules/Default.aspx");
            } else {
                window.open("/sig/underwriting/Schedules.aspx?clientid=" + CHSI.Forms.qsObject.clientid);
            }
        }
        // Opens Locations in a new window fromm Portal

        public static showLocationsInNewWindow(): void {
            if (CHSI.Forms.isPortal) {
                window.open("/MemberInfo/Locations.aspx");
            } else {
                window.open("/cm/locations.aspx?clientid=" + CHSI.Forms.qsObject.clientid);
            }
            
        }
        // Opens locations in new window from Connections
        
        public static showClientLocationsInNewWindow(): void {            
            window.open("/cm/locations.aspx?clientid=" + CHSI.Forms.qsObject.clientid);
        }

    }
}