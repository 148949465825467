module PL.AdminTools.Controllers {

    export class QuestionnaireFormGridController {

        private _$container: JQuery = $('#jxQuestionnaireFormEditGridContainer');
        Forms: any;
        FormEditGrid: GUI.Tables.DTables = new GUI.Tables.DTables;
        TemplateID: number;
        DataStructureID: number;

        init(): void {            

            this.getDataStructures();

            this.events();

        }

        events(): void {
            var _self = this;
            $('#jxDataStructure').on('change', function () {
                _self.DataStructureID = $(this).val();
                _self.getForms();
            });

            this._$container.on('click', '.jxFormCopy', function (e) {
                var d = _self.FormEditGrid.$grid.row($(this).closest('tr')).data();                
                var formID = d[0];
                var title = d[1];

                _self.openFormCopy(formID, title, e);
            });
            this._$container.on('click', '.jxFormEdit', function (e) {
                var d = _self.FormEditGrid.$grid.row($(this).closest('tr')).data();
                var formID = d[0];

                _self.openForm(formID, e);
            });

            this._$container.on('click', '.jxFormDelete', (e) => {
                var d = _self.FormEditGrid.$grid.row($(e.target).closest('tr')).data();
                if (d[9] == 'True') {
                    alert('This Form cannot be deleted because it is being used by Questionnaire(s).');
                } else {
                    
                }
            });

            //this._$container.on('click', '#jxQuestionnaireFormEditGridTable tbody tr', (e) => {            
                
            //    var d = _self.formEditGrid.$grid.row(e.currentTarget).data();
            //    var formID = d[0];

            //    _self.openForm(formID, e);
            //});

            $('#jxNewQuestionnaireForm, #jxNewQuestionnaireFormGrid').on('click', function (e) {
               _self.openForm(null, e);
            });           
            
        }

        getForms(): void {
            new PL.AdminTools.Models.QuestionnaireWYSIWYG().getForms(this.DataStructureID, this.TemplateID).done((data) => {
                this.Forms = data;
                this.bindGrid();
            });
        }

        deleteForm(formID: number, formTitle: string, clickEvent?: any): void {

            

        }

        openFormCopy(formID: number, formTitle: string, clickEvent?: any): void {

            var showPopUP = new GUI.Windows.Popup();
            showPopUP.URL = '/Que/Administration/Modal/FormCopyEditor.aspx?formid=' + formID + '&title=' + formTitle;
            showPopUP.WindowID = 'questionnaireformeditor';
            showPopUP.Title = 'Questionnaire Form Copy';
            showPopUP.Width = 300;
            showPopUP.Height = 130;
            showPopUP.CloseFunction = () => this.init()
            showPopUP.showIFrame(clickEvent);

        }

        openForm(formID: number, clickEvent?: any): void {

            var showPopUP = new GUI.Windows.Popup();
            showPopUP.URL = '/Que/Administration/Modal/FormEditor_2.aspx?formid=' + formID + '&templateid=' + this.TemplateID;
            showPopUP.WindowID = 'questionnaireformeditor';
            showPopUP.Title = 'Questionnaire Form Editor';
            showPopUP.Width = 800;
            showPopUP.Height = 450;
            //showPopUP.CloseFunction = () => this.init()
            showPopUP.showIFrame(clickEvent);

        }

        getDataStructures(): void {
            new PL.Que.Models.Data.DataStructure().getDataStructures().done((dataStructures) => {
                for (var i = 0; i < dataStructures.length; i++) {
                    $('#jxDataStructure').append($('<option>', {
                        value: dataStructures[i].StructureID,
                        text: dataStructures[i].StructureName,
                    }));
                }

                if (dataStructures.length > 0) {
                    $("#jxDataStructure").val($("#jxDataStructure option:eq(1)").val());
                    this.DataStructureID = $("#jxDataStructure").val();
                    this.getForms();
                }
            });
            

            
        }

        addEditColumn(): void {

            var _self = this;
            var cdt: GUI.Tables.DTables = new GUI.Tables.DTables;

            if (this.Forms) {
                var Column = new GUI.Smart.SmartColumnObject();
                //title: '',
                Column.data = null;
                Column.orderable = false;
                Column.class = 'details-control';
                Column.defaultContent = '[<a href="#" class="jxFormEdit minilink">Edit</a>]';
                Column.render = function (data, type, row) {                   
                    return "<a class='jxFormEdit minilink' title='Form ID " + data[0] + "'>[Edit]</a>";
                }

                this.Forms.Columns.push(Column);
            }

        }

        addDeleteColumn(): void {

            var _self = this;
            var cdt: GUI.Tables.DTables = new GUI.Tables.DTables;

            if (this.Forms) {
                var Column = new GUI.Smart.SmartColumnObject();
                //Column.title = 'Value';
                Column.data = null;
                Column.orderable = false;
                Column.class = 'details-control';
                Column.defaultContent = '[<a href="#" class="jxFormCopy minilink">Delete</a>]';
                Column.render = function (data, type, row) {
                    return "<a class='jxFormDelete minilink' title='Form ID " + data[0] + "'>[Delete]</a>";
                }

                this.Forms.Columns.push(Column);
            }

        }

        addCopyColumn(): void {

            var _self = this;
            var cdt: GUI.Tables.DTables = new GUI.Tables.DTables;

            if (this.Forms) {
                var Column = new GUI.Smart.SmartColumnObject();
                //Column.title = 'Value';
                Column.data = null;                
                Column.orderable = false;
                Column.class = 'details-control';
                Column.defaultContent = '[<a href="#" class="jxFormCopy minilink">Copy</a>]';
                Column.render = function (data, type, row) {
                    return "<a class='jxFormCopy minilink' title='Form ID " + data[0] + "'>[Copy]</a>";
                }

                this.Forms.Columns.push(Column);
            }

        }

        bindGrid(): void {
            //this.getForms();
            this.addDeleteColumn();
            this.addCopyColumn();
            this.addEditColumn();
            this.FormEditGrid.containerName = 'jxQuestionnaireFormEditGridContainer';
            this.FormEditGrid.tableName = 'jxQuestionnaireFormEditGridTable';
            this.FormEditGrid.data = this.Forms;
            this.FormEditGrid.buildTable();

        }        

    }

}