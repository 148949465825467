module PL.ClaimSystem.ClaimManagement {
    export class PartyModel {
        constructor() { }

        public InterestID: number;
        public ClaimID: number;
        public Involvement: string;
        public Status: string;
        public Company: string;
        public Contact: string;
        public Address1: string;
        public Address2: string;
        public City: string;
        public State: string;
        public Zip: string;
        public Phone1: string;
        public Phone2: string;
        public Fax: string;
        public Phone1Type: string;
        public Phone2Type: string;
        public Email: string;
        public Comments: string;
        public CreateUser: string;
        public CreateTmsp: string;
        public UpdateUser: string;
        public UpdateTmsp: string;

    }
}