
CHSI.CRM = {
    Images: {
        Models: {}
        , Controllers: {}
    },
            GetClient: function (clientID, SuccessFunction, errorFunction) {
		if (errorFunction == null) {
			errorFunction = function (e) { alert(e) };
		}

		var AjaxURL = '/api/CRM/Clients/GetClient?ClientID=' + clientID;
		jQuery.ajax({
			type: "GET", url: AjaxURL,
			cache: false,
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: SuccessFunction, error: errorFunction
		});

	},
	GetLocationsByClient: function (clientID, SuccessFunction, errorFunction) {
		if (errorFunction == null) {
			errorFunction = function (e) { alert(e) };
		}

		var AjaxURL = '/api/CRM/Location/GetLocationsByClientID?ClientID=' + clientID;
		jQuery.ajax({
			type: "GET", url: AjaxURL,
			cache: false,
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: SuccessFunction, error: errorFunction
		});
	},
	GetContactsByClient: function (clientID, SuccessFunction, errorFunction) {
		if (errorFunction == null) {
			errorFunction = function (e) { alert(e) };
		}

		var AjaxURL = '/api/CRM/Contact/GetContactsByClientID?ClientID=' + clientID;
		jQuery.ajax({
			type: "GET", url: AjaxURL,
			cache: false,
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: SuccessFunction, error: errorFunction
		});
	},
	GetGroupSpec: function (GroupID, SpecName, SuccessFunction, errorFunction) {
		if (errorFunction == null) {
			errorFunction = function (e) { alert(e) };
		}

		var AjaxURL = '/api/Common/GroupSpecification/GetGroupSpecification?GroupID=' + GroupID + '&SpecName=' + SpecName;
		jQuery.ajax({
			type: "GET", url: AjaxURL,
			cache: false,
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (e) {
				if (SuccessFunction) {
					SuccessFunction(e);
				}
			},
			error: function (e) {
				errorFunction(e);
			}
		});
	},
	GetAvailablePolicyYearByClientID: function (ClientID, successFunction) {
	    var options = {};
	    options.URL = '/api/CRM/PolicyYear/GetAvailablePolicyYear?ClientID=' + ClientID;
	    options.Success = function (data) {
	        if (successFunction) {
	            successFunction(data);
	        }
	    }
	    CHSI.Common.Ajax.Get(options);
	},
	GetAvailablePolicyYearByGroupID: function (GroupID, successFunction) {
	    var options = {};
	    options.URL = '/api/CRM/PolicyYear/GetAvailablePolicyYear?GroupID=' + GroupID;
	    options.Success = function (data) {
	        if (successFunction) {
	            successFunction(data);
	        }
	    }
	    CHSI.Common.Ajax.Get(options);
	}

};