module PL.ClaimSystem.ClaimManagement {
    export class ClaimantModel {
        constuctor() {}

        public ClaimID: number;
        public ClaimantID: number;
        public ClaimantType: string;
        public ClaimantName: string;
        public FirstName: string;
        public MiddleName: string;
        public LastName: string;
        public Address1: string;
        public Address2: string;
        public City: string;
        public State: string;
        public Zip: string;
        public Phone1: string;
        public Phone2: string;
        public Phone3: string;
        public Phone1Type: string;
        public Phone2Type: string;
        public Phone3Type: string;
        public Source: string;
        public ExtKey: number;
        public County: string;
        public DateOfBirth: string;
        public DateOfDeath: string;
        public Sex: string;
        public PrimaryLanguage: string;
        public Rate: number;
        public Email: string;
        public TaxID: string;
        public CreateUser: string;
        public CreateTmsp: string;
        public UpdateUser: string;
        public UpdateTmsp: string;

        // claimclaimant table details
        public Status: string;
        public DateHired: string;
        public DateTerminated: string;
        public MaritalStatus: string;
        public ClassCode: string;
        public FullDayLost: boolean;
        public FullPayOnLastDay: boolean;
        public OthersInvolved: boolean;
        public StillOffWork: boolean;
        public EmploymentType: string;
        public Wage: number;
        public WageFreq: string;
        public Supervisor: string;
        public Dependents: string;
        public TempDisabilityRate: number;
        public VocationalRehabRate: number;
        public PermDisabilityRate: number;
        public FirstDayLost: string;
        public Department: string;
        public EstReturnToWork: string;
        public LastDayWorked: string;
        public Occupation: string;
        public EmployeeID: string;
        public PrimaryClaimant: boolean;
        public MedicareEligible: boolean;
    }
}