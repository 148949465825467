CHSI.Page.GridRowHistory = {
    Settings: {
        FormID: 0
        , ResultSetID: 0
        , ValueIndex: 0
    }
    , Init: function (Settings) {
        if (Settings) {
            if (Settings.FormID != null)
                this.Settings.FormID = Settings.FormID;
            if (Settings.ResultSetID != null)
                this.Settings.ResultSetID = Settings.ResultSetID;
            if (Settings.ValueIndex != null)
                this.Settings.ValueIndex = Settings.ValueIndex;
        }


        this.Calls.GetRowHistory(CHSI.Page.GridRowHistory.Settings.FormID, CHSI.Page.GridRowHistory.Settings.ResultSetID, CHSI.Page.GridRowHistory.Settings.ValueIndex, function (e) { CHSI.Page.GridRowHistory.Cache.GridObject = e; CHSI.Page.GridRowHistory.BindPresentationLayer(); });
    },
    Templates: {
    },
    Cache: {
        GridObject: {}

    },
    BindPresentationLayer: function () {

        var DataSet = null;

        if (CHSI.Page.GridRowHistory.Cache.GridObject) {
            DataSet = CHSI.Page.GridRowHistory.Cache.GridObject.Values;
            var Columns = CHSI.Page.GridRowHistory.Cache.GridObject.Columns;            
        }
        if (DataSet != null) {
            CHSI.Page.ScheduleGrid.Cache.GridTable = $('#jxGridHistory').DataTable({
                data: DataSet,
                columns: Columns,
                lengthMenu: [[25], [25]],
                stateSave: true
            })
        } else {
            var HTML = [];
            $('#jxGridHistory').html('<tr><td>No results found.</td></tr>')
        }
               
        $('#jxLoading').hide();
    }
    , Calls: {
        GetRowHistory: function (FormID, ResultSetID, ValueIndex, Success) {
            var Options = {};
            Options.URL = "/api/Que/Grid/GetGridRowHistory?FormID=" + FormID + "&ResultSetID=" + ResultSetID + "&ValueIndex=" + ValueIndex;
            Options.Success = Success;
            CHSI.Common.Ajax.Get(Options);
        },

    }
}
