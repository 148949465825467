CHSI.UW.MuniUW_Premium = {
    Init: function (QuoteID) {
        this.Cache.QuoteID = QuoteID;
        this.Templates.Premium = CHSITemplates.Template_QuotePremium_Premium;

        if (this.Cache.cookieValue.length == 0) {
            if ($.cookie('QTPremiumSV')) {
                CHSI.UW.MuniUW_Premium.Cache.cookieValue.push($.cookie('QTPremiumSV'));
            }
        }

        this.Calls.GetQuoteDetails(QuoteID);
        this.Events.Global();
    },
    Templates: {},
    Cache: {
        QuoteObject: {},
        InfoObject: {},
        QuoteID: null,
        cookieValue: [],
        Editable: 'Y',
        CurrentSelectedElementID: null
    },
    Binds: {
        BindQuote: function () {
            if (QuoteObject) {
                CHSI.UW.MuniQuote.Cache.QuoteObject = QuoteObject;
                CHSI.MuniUW.QuoteObject = QuoteObject;
                QuoteObject = null;
            }
            $('#jxPremium').html(CHSI.UW.MuniUW_Premium.Templates.Premium(CHSI.UW.MuniQuote.Cache.QuoteObject));
            restart = true;
            sorttable.init();
            if (CHSI.UW.MuniUW_Premium.Cache.CurrentSelectedElementID) {
                $('#' + CHSI.UW.MuniUW_Premium.Cache.CurrentSelectedElementID).focus();
            }
            if (CHSI.UW.MuniQuote.Cache.QuoteList.length > 1 && CHSI.UW.MuniQuote.Cache.QuoteObject.ParentQuoteID) {
                        $('.QuoteLabel').html('Endorsement');
            }
            CHSI.UW.MuniUW_Premium.Events.ExpandCoverageLines();
            CHSI.UW.MuniQuote.DisablePage(CHSI.UW.MuniUW_Premium.Cache.Editable);
        }
    },
    Events: {
        Global: function () {

            $('#jxPremium').on('click', '.plusbutton', function () {
                var plusbuttonid = $(this).attr('id');
                var ids = plusbuttonid.split("_");
                var cl = ids[1];
                var cookieValue = null;
                if ($('#jxplusimg_' + cl).data("status") == '+') {
                    $('#jxplusimg_' + cl).attr('src', '/images/images/minus.gif');
                    $('#jxplusimg_' + cl).data("status", "-");
                    $('#jxCL_' + cl + ' .Collapsable').show();

                    CHSI.UW.MuniUW_Premium.Cache.cookieValue.push(cl);
                    $.cookie('QTPremiumSV', CHSI.UW.MuniUW_Premium.Cache.cookieValue, { expires: 7 });
                } else {
                    $('#jxplusimg_' + cl).attr('src', '/images/images/plus.gif');
                    $('#jxplusimg_' + cl).data("status", "+");
                    $('#jxCL_' + cl + ' .Collapsable').hide();

                    CHSI.UW.MuniUW_Premium.Cache.cookieValue = CHSI.UW.MuniUW_Premium.Cache.cookieValue.toString().replace(cl, '').replace(/,/g, '');
                    $.cookie('QTPremiumSV', CHSI.UW.MuniUW_Premium.Cache.cookieValue, { expires: 7 });
                    if ($.cookie('QTPremiumSV') == null || $.cookie('QTPremiumSV') == undefined || $.cookie('QTPremiumSV') == "") {
                        $.removeCookie('QTPremiumSV');
                        CHSI.UW.MuniUW_Premium.Cache.cookieValue = [];
                    }
                }
            });

            $('#jxCheatSheetLink').attr('href', 'FormulaWorksheet.aspx?QuoteID=' + CHSI.UW.MuniUW_Premium.Cache.QuoteID);
            jQuery('#jxCheatSheetLink').on('click', CHSI.UW.MuniUW_Premium.Events.ShowWorkSheet);


            $('#jxPremium').on('change', '.Variable', function () {
                QuoteObject = CHSI.UW.MuniQuote.Cache.QuoteObject;
                var Variable = $(this);
                if (CHSI.UW.MuniQuote.Cache.QuoteObject.QuoteStatus.RequireComments == 'Y') {
                    CHSI.UW.MuniUW_Premium.Events.ShowSaveVariable(Variable.attr("id"), CHSI.UW.MuniUW_Premium.Cache.QuoteID, Variable.val());
                } else {
                    CHSI.UW.MuniUW_Premium.Calls.SaveVariable(Variable.attr("id"), Variable.val(), false);
                }

            });

            $('#jxPremium').on('focus', '.Variable', function () {
                var Variable = $(this);
                Variable.val(Variable.val());
                $(this).select();
            });

            $('#jxPremium').on('focusout', '.Variable', function () {
                var Variable = $(this);
                var FormatedValue = '';
                if (Variable.data('format')) {
                    var Format = Variable.data('format');
                    var OriginalValue = Variable.data('value');
                    FormatedValue = CHSI.Common.String.Format(Format, OriginalValue);
                    Variable.val(FormatedValue);
                }

            });

            $('#jxSaveNext').on('click', function () {
                CHSI.Common.Navigation.NavigateToNextTab();
            });

            $('#jxCancel').on('click', function () {
                CHSI.Common.Navigation.NavigateToReturnURL();
            });
        },

        ShowSaveVariable: function (VarID, QuoteID, NewValue) {
            var ShowSaveVariableURL = '/UW/Modals/VariableSave.aspx?VarID=' + VarID + '&QuoteID=' + QuoteID + '&NewValue=' + NewValue;
            ShowPopup("SaveVariable", ShowSaveVariableURL, "Save Variable: " + VarID, 290, 110, CHSI.UW.MuniUW_Premium.Binds.BindQuote, false);

        },

        ShowWorkSheet: function (e) {
            var URL = 'FormulaWorksheet.aspx?QuoteID=' + CHSI.UW.MuniUW_Premium.Cache.QuoteID;

            if (e.shiftKey) {
                window.open(URL);
            }
            else { ShowPopup('FormulaWorksheet', URL, 'Formula Worksheet', 0, 0, CHSI.UW.MuniUW_Premium.Calls.GetQuoteDetails, true); }
            return false;

        },

        ExpandCoverageLines: function () {
            var qtPremiumCookieValue = 0;
            if ($.cookie('QTPremiumSV') != undefined) {
                qtPremiumCookieValue = $.cookie('QTPremiumSV');
            }
            if (qtPremiumCookieValue != 0) {
                if (qtPremiumCookieValue.length > 0) {
                    qtPremiumCookieValue = qtPremiumCookieValue.replace(/,/g, '');
                    $('.Collapsable').hide();
                    for (Index = 0; Index < qtPremiumCookieValue.length; Index++) {
                        $('#jxplusimg_' + qtPremiumCookieValue[Index]).attr('src', '/images/images/minus.gif');
                        $('#jxplusimg_' + qtPremiumCookieValue[Index]).data("status", "-");
                        $('#jxCL_' + qtPremiumCookieValue[Index] + ' .Collapsable').show();
                    }
                } else {
                    for (Index = 0; Index < qtPremiumCookieValue.length; Index++) {
                        $('#jxplusimg_' + qtPremiumCookieValue[Index]).attr('src', '/images/images/plus.gif');
                        $('#jxplusimg_' + qtPremiumCookieValue[Index]).data("status", "+");
                        $('#jxCL_' + qtPremiumCookieValue[Index] + ' .Collapsable').hide();
                    }
                }
            } else {
                $('.Collapsable').hide();
            }
        }

    },
    GetNextEditableFocus: function (currentID) {
        var NextInput = $('#' + currentID).closest('td').next().find('input');

        if (NextInput.length == 0) {
            NextInput = $('#' + currentID).closest('tr').next().children('td').children('input').first();
        }

        if (NextInput.length == 0) {


            return null;
        }
        else {
            if (NextInput.is('[readonly]')) {
                return CHSI.UW.MuniUW_Premium.GetNextEditableFocus(NextInput.attr('id'));
            }
            else {
                return NextInput.attr('id');
            }

        }


    },
    Calls: {
        GetQuoteDetails: function (QuoteID) {
            if (!QuoteID) {
                QuoteID = CHSI.UW.MuniUW_Premium.Cache.QuoteID;
            }
            var Success = function (e) {
                CHSI.UW.MuniQuote.Cache.QuoteList = e;
                CHSI.UW.MuniQuote.Cache.QuoteObject = e[0];
                if (CHSI.UW.MuniQuote.Cache.QuoteObject.QuoteStatus && CHSI.UW.MuniQuote.Cache.QuoteObject.QuoteStatus.Editable == 'N') {
                    CHSI.UW.MuniUW_Premium.Cache.Editable = 'N';
                }
                CHSI.UW.MuniUW_Premium.Binds.BindQuote();

                var SecOptions = {},
                        isVerified = false;
                SecOptions.PermissionID = 14;
                SecOptions.Success = function (e) {
                    isVerified = e;
                    if (isVerified) {
                        CHSI.UW.MuniQuote.MenuController(CHSI.UW.MuniQuote.Cache.QuoteObject, '#jxMenuContainer');
                    }
                    
                }
                CHSI.Security.HasPermission(SecOptions);
                
            }

            CHSI.UW.MuniQuote.GetQuoteDetails(QuoteID, Success);

        },

        SaveVariable: function (VarID, NewValue, ForceAutoCalculation) {
            var SourceObject = CHSI.UW.MuniQuote.GetQuoteObjectfromVarID(VarID);

            var ajaxData = {};
            ajaxData.DataObject = {};
            ajaxData.Comments = '';
            ajaxData.DataObject.isLocked = 1;
            ajaxData.DataObject.StringValue = SourceObject.StringValue;
            ajaxData.DataObject.DateValue = SourceObject.DateValue;
            ajaxData.DataObject.IntegerValue = SourceObject.IntegerValue;
            ajaxData.DataObject.DecimalValue = SourceObject.DecimalValue;
            ajaxData.DataObject.BoolValue = SourceObject.BoolValue;
            ajaxData.DataObject.DataType = SourceObject.DataType;
            ajaxData.DataObject.QuoteID = CHSI.UW.MuniQuote.Cache.QuoteObject.QuoteID;
            ajaxData.isAutoCalculated = SourceObject.isAutoCalculated;
            ajaxData.ForceAutoCalculation = ForceAutoCalculation;
            ajaxData.Status = 'active';
            ajaxData.Type='Quote';
            
            if(CHSI.UW.MuniQuote.Cache.QuoteList.length>1 && CHSI.UW.MuniQuote.Cache.QuoteObject.ParentQuoteID)
            {               
                ajaxData.Type = 'Endorsement';
            }
            CHSI.UW.MuniQuote.SetValue(ajaxData.DataObject, NewValue);
            CHSI.UW.MuniUW_Premium.Cache.CurrentSelectedElementID = CHSI.UW.MuniUW_Premium.GetNextEditableFocus(VarID);


            var Success = function (e) {
                var Appearance = CHSI.UW.MuniQuote.Cache.QuoteObject.Appearance;
                e.Appearance = Appearance;
                CHSI.UW.MuniQuote.Cache.QuoteObject = e;
                CHSI.UW.MuniQuote.Cache.QuoteList[0] = e;
                QuoteObject = CHSI.UW.MuniQuote.Cache.QuoteObject;
                CHSI.UW.MuniUW_Premium.Binds.BindQuote();

            }

            CHSI.UW.MuniQuote.SaveVariable(VarID, ajaxData, Success);
        }
    }

}
