module PL.UW.XMods.Controller{
    export class XmodController {

        XMod: GUI.Smart.SmartObject;
        $XmodGrid: GUI.Tables.DTables;
        PolicyPeriods: number[] = new Array<number>();
        Groups: any[] = new Array<any>();

        constructor() { }

        init(ClientID?: string|number, PolicyPeriod?: number, GroupID?: string): void {
            var _self = this;
            PL.UW.XMods.API.XModAPI.getXModPolicyPeriods().done(function (policyPeriodData) {
                PolicyPeriod = PolicyPeriod == null ? policyPeriodData[0] : PolicyPeriod;
                PL.UW.XMods.API.XModAPI.getActiveGroups().done(function (groupData) {
                    GroupID = GroupID == null ? groupData[0].GroupName : GroupID;
                    PL.UW.XMods.API.XModAPI.getxModWorkSheets(ClientID, PolicyPeriod, GroupID).done(function (xModData) {
                        _self.XMod = xModData;
                        _self.PolicyPeriods = policyPeriodData;
                        _self.Groups = groupData;

                        // load group tab template
                        var Template_GroupTabs = Handlebars.compile($('#Template_GroupTabs').html())
                        $('#jxTabMenu').html(Template_GroupTabs({ Groups: _self.Groups }));

                        // select first group tab by default
                        var links = $('#jxTabMenu > a');
                        $(links[0]).addClass('activesubmit');

                        $('#jxPolicyPeriod').html(_self.getPolicyPeriod());
                        //$('#jxGroups').html(_self.getGroups());
                        _self.bindForms();
                        _self.setupEvents();
                    });
                });
            });            
        }

        setupEvents(): void {
            var _self = this;
                            
            _self.$XmodGrid.clickColumn('ViewColumn', function (rowData) {
                var WorkSheetID = rowData[0], ClientID = rowData[1], PolicyPeriod = rowData[5];                
                var URL = '/UW/Xmod/XmodEdit.aspx?worksheetid=' + WorkSheetID + '&policyperiod=' + PolicyPeriod
                window.location.href = URL;
            });

            $('#jxPolicyPeriod').off().on('change', function () {   
                var PolicyPeriod: string = $(this).val();
                _self.refreshGrid(+PolicyPeriod, $('#jxTabMenu > a.activesubmit').text());
            });

            $('#jxTabMenu > a').on('click', function (e) {
                var links = $('#jxTabMenu > a');
                links.removeClass('activesubmit');

                $(e.target).addClass('activesubmit');
                _self.refreshGrid(+$('#jxPolicyPeriod').val(), e.target.textContent);
            });

            /*
            $('#jxGroups').off().on('change', function () {
                var group: string = $(this).val();
                _self.refreshGrid(+$('#jxPolicyPeriod').val(), group);
            });
            */

			$('#jxNewQuote').off().on('click', function () {
				PL.UW.XMods.API.XModAPI.getDefaultxModWorksheet(function (e) {
					window.location.href = '/UW/Xmod/XmodEdit.aspx?worksheetid=' + e;
				});
			});
        }

        bindForms(): void {
            this.$XmodGrid = new GUI.Tables.DTables;            
            this.$XmodGrid.containerName = 'jxXModGridContainer';
            this.$XmodGrid.tableName = 'jxXmodGrid';
            this.$XmodGrid.columnSearch = false;
            this.$XmodGrid.stateSave = false;
            this.$XmodGrid.data = this.XMod;
            this.$XmodGrid.editColumn(0);
            this.$XmodGrid.buildTable();
        }

        getPolicyPeriod(): string {            
            var _self = this;
            var HTML = [];
            for (var i = 0; i < _self.PolicyPeriods.length; i++) {
                HTML.push('<option value="' + _self.PolicyPeriods[i] + '">' + _self.PolicyPeriods[i] + '</option>');
                    //$('#jxPolicyPeriod').append($('<option></option>').attr('value', _self.PolicyPeriods[i]).text(_self.PolicyPeriods[i]));
            }                
                return HTML.join('');
        }

        getGroups(): string {            
            var _self = this;           
            var HTML = [];                     
            for (var i = 0; i < _self.Groups.length; i++) {
                HTML.push('<option value="' + _self.Groups[i].GroupName + '">' + _self.Groups[i].GroupName + '</option>');
                    //$('#jxGroups').append($('<option></option>').attr('value', _self.Groups[i].GroupName).text(_self.Groups[i].GroupName));
            }            
                return HTML.join('');
        }

        refreshGrid(PolicyPeriod: number, GroupID: string): void {            
            var _self = this;
            PL.UW.XMods.API.XModAPI.getxModWorkSheets(null, PolicyPeriod, GroupID).done(function (xmodData) {
                _self.$XmodGrid.refreshTable(xmodData, function () {
                    _self.$XmodGrid.editColumn(0);                    
                }, function () {
                    _self.setupEvents();
                    });
            });
        }
    }
} 