module PL.Que.Models.Design {

    export class ContentType {

        ContentTypeID: number;
        CssClass: string;
        MaxLength: string;

    }

}