CHSI.Page.SItemFormUploadImage = {

    Init: function (ScheduleItemID) {
        var Template_ScheduleItemFormImageUpload = $('#Template_ScheduleItemFormImageUpload').html();
        this.Templates.ScheduleItemFormImageUpload = Handlebars.compile(Template_ScheduleItemFormImageUpload);
        this.Cache.ScheduleImageObject.ScheduleItemID = ScheduleItemID;
        this.BindPresentationLayer();        
        this.Events.Global();
    },
    Templates: {

    },
    Cache: {
        ScheduleImageObject: {
            ScheduleItemID: null
          , Primary: 0
          , Description: ''
          , Comments: ''
        }        
    },    
    BindPresentationLayer: function () {
        $('#jxScheduleItemFormImageUploadContainer').html(this.Templates.ScheduleItemFormImageUpload(this.Cache.ScheduleImageObject));
        CHSI.Page.SItemFormUploadImage.Calls.GetFileID();
        this.Events.GetTextareaLength('jxImageDescription', 'DescriptionMaxCount', 190);
        this.Events.GetTextareaLength('jxImageComments', 'CommentsMaxCount', 490);
    },
    PersistFromCache: function () {
        var ScheduleImageObject = CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject
        if (!ScheduleImageObject.ScheduleItemID) {
            ScheduleImageObject.ScheduleItemID = CHSI.Page.SItemFormUploadImage.Cache.ScheduleItemID;
        }
        ScheduleImageObject.Primary = $('#jxImagePrimary').is(':checked') ? true : false;
        ScheduleImageObject.Description = $('#jxImageDescription').val();
        ScheduleImageObject.Comments = $('#jxImageComments').val();
        return ScheduleImageObject;
    },
    Events: {
        Global: function () {
            $('#jxScheduleItemFormImageUploadContainer').on('click', '#jxImageSave', function () {
                CHSI.Page.SItemFormUploadImage.Calls.SaveScheduleItemImage(true);                
                $('#jxImageCancel').attr('disabled', true);
                $("#ScheduleItemFormTabs").tabs('option', 'active', 2);
                $('#jxImageUploadTab').hide();                
            });

            $('#jxScheduleItemFormImageUploadContainer').on('click', '#jxImageCancel', function () {
                var ImageObject = CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject;
                ImageObject.FileID = null;
                ImageObject.ScheduleImageID = null;
                ImageObject.Primary = 0;
                ImageObject.Description = '';
                ImageObject.Comments = '';                
                CHSI.Page.SItemFormUploadImage.BindPresentationLayer();
                $("#ScheduleItemFormTabs").tabs('option', 'active', 2);
                $('#jxImageUploadTab').hide();
            });

            $('#jxScheduleItemFormImageUploadContainer').on('change', '#jxImagePrimary', function () {
                $('#jxImageSave').prop('disabled', false);
            });

            $('#jxScheduleItemFormImageUploadContainer').on('input', '#jxImageDescription, #jxImageComments', function () {
                $('#jxImageSave').prop('disabled', false);
            });

            this.GetTextareaLength('jxImageDescription', 'DescriptionMaxCount', 190);
            this.GetTextareaLength('jxImageComments', 'CommentsMaxCount', 490);
        },
        GetTextareaLength: function (TextareaID, TextareaCountID, MaxValue) {
            var Textarea = $('#' + TextareaID);
            var CounterID = $('#' + TextareaCountID);

            Textarea.on('keydown', function () {
                var CurrentChars = $(this).val().length;
                var RemaingChars = MaxValue - CurrentChars;
                CounterID.html(RemaingChars);
                if (CurrentChars > MaxValue) {
                    alert('You have exceeded the maximum amount of characters supported.')
                }
            });
        }        
    },
    Calls: {
        GetFileID: function () {
            var Uploader = new qq.FileUploader({
                element: document.getElementById('jxUploadattachment'),
                action: '/CHSIUploadHandler.axd',
                allowedExtensions: ['jpg', 'jpeg', 'png', 'gif'],
                debug: true,
                disableDefaultDropzone: true,
                onComplete: function (id, fileName, response) {

                    if (response.success == true) {

                        var CurrentValueIndex = 0;

                        jQuery('.qq-upload-list').hide();

                        var FileInfo = {
                            FileID: response.FileID,
                            FileName: fileName
                        };
                        
                        CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject.FileID = FileInfo.FileID;
                        CHSI.Page.SItemFormUploadImage.BindPresentationLayer();
                    }
                }
            });
        },
        GetScheduleItemImage: function (ScheduleImageID, isEdit) {
            var AjaxURL = '/api/UW/ScheduleItemImage/GetScheduleItemImage?ScheduleImageID=' + ScheduleImageID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject = e;                    
                    CHSI.Page.SItemFormUploadImage.BindPresentationLayer();
                    if (isEdit) {
                        $('#jxImageSave').prop('disabled', true);                        
                    }
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },
        SaveScheduleItemImage: function (RefreshTab) {
            var AjaxData = CHSI.Page.SItemFormUploadImage.PersistFromCache();            
            var AjaxString = JSON.stringify(AjaxData);
            var AjaxURL = '/api/UW/ScheduleItemImage/SaveScheduleItemImage';
            $.ajax({
                type: "POST",                
                url: AjaxURL,
                data: AjaxString,
                cache: true,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {                    
                    CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject.ScheduleImageID = e.ScheduleImageID;
                    if (RefreshTab) {
                        RefreshScheduleEditImageTab(CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject.ScheduleItemID);
                        RefreshScheduleItemTab(CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject.ScheduleItemID);
                    }
                    CHSI.Common.LogObject(e);
                }, error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        }
    }
}