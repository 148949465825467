namespace PL.CRM.Models {

    export class Group {

        GroupName: string;
        LongName: string;

        getActiveGroups(): JQueryDeferred<any> {
            var result = $.Deferred();
            PL.CRM.APIs.Group.getActiveGroups().done((clientInformation) => {
                result.resolve(clientInformation);
            });
            return result;
        }

    }

}