module PL.ClaimSystem.ClaimManagement {
    export class ProviderModel {
        constructor() { }

        public ProviderID: number;
        public ProviderName: string;
        public ProviderType: string;
        public Address1: string;
        public Address2: string;
        public City: string;
        public State: string;
        public Zip: string;
        public Phone: string;
        public Fax: string;
        public TaxID: string;
        public CreateUser: string;
        public CreateTmsp: string;
        public UpdateUser: string;
        public UpdateTmsp: string;
    }
}