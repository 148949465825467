CHSI.Que.DataStructureSubCategories = {

    Init: function (setup) {

        this.Cache.DataStructureID = setup.StructureID; 
        this.Calls.GetSubCategories(function () {
            CHSI.Que.DataStructureSubCategories.BindPresentationLayer();
            CHSI.Que.DataStructureSubCategories.Cache.GridTable.search('').draw();
        });
        this.Events.Global();
    },
    Utilities: {
        GetSubCategories: function () {
            var SubCategories = [];
            for (var CategoryIndex = 0; CategoryIndex < CHSI.Que.DataStructureSubCategories.Cache.SubCategoriesGrid.Values.length; CategoryIndex++) {
                SubCategories.push({ SubCategoryID: CHSI.Que.DataStructureSubCategories.Cache.SubCategoriesGrid.Values[CategoryIndex][0], SubCategoryTitle: CHSI.Que.DataStructureSubCategories.Cache.SubCategoriesGrid.Values[CategoryIndex][2] });
            }
            SubCategories = sortByKey(SubCategories, 'SubCategoryTitle');
            return SubCategories;
        }
    }
    , Cache: {
        SubCategoriesGrid: {
            Columns: null,
            Values: null
        },
        GridTable: null,
        SubCategoryObject: {}
    },
    BindPresentationLayer: function () {

        $('#jxStructureSubCategoriesContainer').html('<table width="100%" class="dtable" id="jxDataStructureSubCategoriesGrid"><tfoot></tfoot></table>');

        var DataSet = null;
        var ClientColumnDefinition = {};
        var columnDefs = [];

        if (CHSI.Que.DataStructureSubCategories.Cache.SubCategoriesGrid) {
            DataSet = CHSI.Que.DataStructureSubCategories.Cache.SubCategoriesGrid;
        }

        for (ColumnIndex = 0; ColumnIndex < DataSet.Columns.length; ColumnIndex++) {

            ClientColumnDefinition = {};
            ClientColumnDefinition.targets = ColumnIndex;

            if (DataSet.Columns[ColumnIndex].title == 'Status') {
                ClientColumnDefinition.render = function (data, type, row, meta) {

                    if (data == 'active') {
                        return '<select class="CategoryCellValue"><option value="active" selected>Active</option><option value="inactive">Inactive</option></select>';
                    } else {
                        return '<select class="CategoryCellValue"><option value="active">Active</option><option value="inactive" selected>Inactive</option></select>';
                    }
                }
            }
       
            else {
              
                ClientColumnDefinition.render = function (data, type, row, meta) {
                    return '<input type="text" class="CategoryCellValue" value="' + data + '"/>';
                }
            }

            //}

            columnDefs.push(ClientColumnDefinition);
        }

        if (DataSet.Values != null) {
            CHSI.Que.DataStructureSubCategories.Cache.GridTable = $('#jxDataStructureSubCategoriesGrid').DataTable({
                aaData: DataSet.Values,
                columns: DataSet.Columns,
                aoColumnDefs: columnDefs,
                stateSave: true
            })
        } else {
            var HTML = [];
            $('#jxDataStructureSubCategoriesGrid').html('<tr><td>No subcategories found.</td></tr>')
        }

        $('#jxLoading').hide();
    },
    Events: {
        Global: function () {
            $('#jxCreateSubCategory').off().on('click', function () {
                if (CHSI.Que.DataStructureSubCategories.Cache.SubCategoryObject.SubCategoryTitle == '') {
                    alert('please enter a category title');
                    return false;
                }
                CHSI.Que.DataStructureSubCategories.Cache.SubCategoryObject.SubCategoryTitle = $('#jxCategorySubTitle').val();
                CHSI.Que.DataStructureSubCategories.Cache.SubCategoryObject.SortOrder = $('#jxSubCategorySortOrder').val();
                if (isNaN(CHSI.Que.DataStructureSubCategories.Cache.SubCategoryObject.SortOrder) || CHSI.Que.DataStructureSubCategories.Cache.SubCategoryObject.SortOrder == "") {
                    CHSI.Que.DataStructureSubCategories.Cache.SubCategoryObject.SortOrder = 0;
                }

                CHSI.Que.DataStructureSubCategories.Cache.SubCategoryObject.Status = 'active';
                CHSI.Que.DataStructureSubCategories.Cache.SubCategoryObject.DataStructureID = CHSI.Que.DataStructureSubCategories.Cache.DataStructureID;
                CHSI.Que.DataStructureSubCategories.Cache.SubCategoryObject.SubCategoryID = null;
                CHSI.Que.DataStructureSubCategories.Calls.SaveSubCategory(function () {
                    CHSI.Que.DataStructureSubCategories.Calls.GetSubCategories(function () {
                        CHSI.Que.DataStructureSubCategories.BindPresentationLayer();
                    });
                });
            });

            $("#jxStructureSubCategoriesContainer").on('input', 'td', function () {
                var Cell = CHSI.Que.DataStructureSubCategories.Cache.GridTable.cell(this).index();
                var Row = CHSI.Que.DataStructureSubCategories.Cache.GridTable.row(Cell.row).data();
                var Column = CHSI.Que.DataStructureSubCategories.Cache.GridTable.settings()[0].aoColumns[Cell.column];
                var ValueType = $(this).find('.CategoryCellValue').val();

                CHSI.Que.DataStructureSubCategories.Cache.SubCategoryObject.SubCategoryID = Row[0];
                CHSI.Que.DataStructureSubCategories.Cache.SubCategoryObject.DataStructureID = Row[1];
                CHSI.Que.DataStructureSubCategories.Cache.SubCategoryObject.SubCategoryTitle = Column.title == 'Subcategory Title' ? ValueType : Row[2];
                CHSI.Que.DataStructureSubCategories.Cache.SubCategoryObject.SortOrder = Column.title == 'SortOrder' ? ValueType : Row[3];
                CHSI.Que.DataStructureSubCategories.Cache.SubCategoryObject.Status = Column.title == 'Status' ? ValueType : Row[4];
                
                CHSI.Que.DataStructureSubCategories.Cache.GridTable.cell({ row: CHSI.Que.DataStructureSubCategories.Cache.GridTable.row(Cell.row).index(), column: Cell.column }).data(ValueType);

                CHSI.Que.DataStructureSubCategories.Calls.SaveSubCategory(function () {
                    CHSI.Que.DataStructureSubCategories.Calls.GetSubCategories(function () {
                        CHSI.Que.DataStructureSubCategories.BindPresentationLayer();
                    });
                });
            });
        }
    },
    Calls: {
        GetSubCategories: function (successFunction) {
            var Options = {};
            Options.URL = '/api/Que/DataStructureSubCategory/Get?DataStructureID=' + CHSI.Que.DataStructureSubCategories.Cache.DataStructureID;
            Options.Success = function (data) {
                CHSI.Que.DataStructureSubCategories.Cache.SubCategoriesGrid = data;
                CHSI.Common.Log(data);
                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        SaveSubCategory: function (successFunction) {
            var Options = {};
            Options.URL = '/api/Que/DataStructureSubCategory/Save';
            Options.Data = CHSI.Que.DataStructureSubCategories.Cache.SubCategoryObject;
            Options.Success = function (data) {
                if (data && data.SubCategoryID>0) {
                    if (successFunction) {
                        successFunction();
                    }
                } else {
                    alert('Can not save duplicated titles');
                }
            };
            CHSI.Common.Ajax.Post(Options);
        }
    }

}