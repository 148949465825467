CHSI.Security = {
    HasPermission: function (Options) {        
        if(Options.PermissionID === null){
            throw "Please provide a PermisisonID.";
        }

        if (Options.Async === null) {
            Options.Async = true;
        }
        
       return $.ajax({
            type: "GET",
            url: '/api/Security/ConnectionsUser/HasPermissionByID?PermissionID=' + Options.PermissionID,
            cache: true,
            async: Options.Async,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: Options.Success,
            error: Options.Error
        });
    }
}