module GUI.Smart {

    export class SmartColumnObject {
        title: string;
        data: any;
        orderable: boolean;
        class: string;
        defaultContent: string;
        render: any;
        visible: boolean;
        targets: number;
        searchable: boolean;
        FieldName: string;
        isCurrency: boolean;        
        tooltip: string;
    }

    export class SmartObject {
        Columns: GUI.Smart.SmartColumnObject[] = new Array < GUI.Smart.SmartColumnObject>();
        Values: string[] = new Array<string>();        
    }
}
    