module CL.UW.Model {

    export class GroupProductLine {
        GroupLineID: number;
        GroupName: string;
        LineID: number;
        LineName: string;

        public static getGroupProductLines(): JQueryDeferred<Model.GroupProductLine[]> {
            return Factories.RepositoryFactory.getProductRepository().getGroupProductLines();
        }
    }
} 