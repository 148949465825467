namespace PL.AdminTools.Enums {

    export enum ClaimIntakeEditor_Information_Messages {

        NEW_UNSAVED,
        CHANGES_UNSAVED,
        MISSING_REQUIRED_INFORMATION,
        INVALID_INFORMATION,
        REPORT_FORM_ID_NOT_NUMERIC,
        DELETED,
        SAVED,
        SAVE_ERROR,
        UNKNOWN_ERROR,
        INFORMATION

    }

    export enum ClaimIntakeEditor_Category_Messages {

        NEW_UNSAVED,
        CHANGES_UNSAVED,
        DELETED,
        SAVED,
        SAVE_ERROR,
        UNKNOWN_ERROR,
        INFORMATION

    }

    export enum ClaimIntakeEditor_Field_Messages {

        NEW_UNSAVED,
        CHANGES_UNSAVED,
        DELETED,
        SAVED,
        SAVE_ERROR,
        UNKNOWN_ERROR,
        INFORMATION

    }

}