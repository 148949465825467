CHSI.Page.ClaimChartOverview = new Object();

CHSI.Page.ClaimChartOverview.ChartClientID = 0;
CHSI.Page.ClaimChartOverview.ChartAsOfDate = '';

CHSI.Page.ClaimChartOverview.Init = function (mainDivID, clientID) {
    // set font, since it doesn't work on chart creation for some reason
    Highcharts.setOptions({
        chart: {
            style: {
                fontFamily: 'Arial'
            }
        }
    });
    CHSI.Forms.ajaxFormDetails = CHSITemplates.Template_Claims_ClaimsOverview;

    jQuery("#" + mainDivID).html(CHSI.Forms.ajaxFormDetails());
       

        CHSI.Page.ClaimChartOverview.ChartClientID = clientID;

        var today = new Date();

        CHSI.Page.ClaimChartOverview.ChartAsOfDate = today.format('MM/dd/yyyy');
        CHSI.Page.ClaimChartOverview.BuildCharts(CHSI.Page.ClaimChartOverview.ChartAsOfDate, false);          

    $('#ChartPageOutput').on('change', '#jxChildClaims', function () {
        if ($(this).is(':checked')) {            
            CHSI.Page.ClaimChartOverview.BuildCharts(CHSI.Page.ClaimChartOverview.ChartAsOfDate, true);
        } else {            
            CHSI.Page.ClaimChartOverview.BuildCharts(CHSI.Page.ClaimChartOverview.ChartAsOfDate, false);
        }
    });    
};

CHSI.Page.ClaimChartOverview.CreateStackedColumnChart = function (outputDivID, chartTitle, obj) {
    var chart = new Highcharts.Chart({
        chart: {
            renderTo: outputDivID,
            type: 'column',
            style: {
                fontFamily: 'Arial'//this should work but doesn't :(
            }
        },
        exporting: {
            enabled: false
        },
        credits: { enabled: false },
        title: {
            text: chartTitle
        },
        xAxis: {
            categories: obj.categories
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontWeight: 'bold',
                    color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                }
            }
        },
        legend: {
            align: 'center',
            x: 0,
            y: 0,
            verticalAlign: 'bottom',
            floating: false,
            backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColorSolid) || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: true
        },
        tooltip: {
            formatter: function () {
                return '<b>' + this.x + '</b><br/>' +
                    this.series.name + ': ' + Highcharts.numberFormat(this.y, 0, '') + '<br/>' +
                    'Total: ' + Highcharts.numberFormat(this.point.stackTotal, 0, '');
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false,
                    color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
                    formatter: function () {
                        return Highcharts.numberFormat(this.y, 0, '');
                    }
                }
            }
        },
        series: obj.series
    });

};

CHSI.Page.ClaimChartOverview.ProcessChartError = function (outputDivID, chartTitle) {
    jQuery('#' + outputDivID).removeClass('loadSpinner');
    jQuery('#' + outputDivID).addClass('errorMessage');
    jQuery('#' + outputDivID).html("Error loading chart: <b>" + chartTitle + "</b>. Please contact your system administrator.");
    CHSI.DOM.ProcessAjaxError;
};

CHSI.Page.ClaimChartOverview.LoadSpinner = function (chartDivID, spinnerDivID) {
    jQuery("#" + chartDivID).html("<div id=\"" + spinnerDivID + "\" class=\"loadSpinner\"><img src=\"/images/Dialog/spinner.gif\" alt=\"loading\" /></div>");
};
CHSI.Page.ClaimChartOverview.CreateChart1 = function (IncludeChildren) {
    var chartDivID = 'jxChart1';
    var spinnerDivID = 'jxChartSpinner1'
    CHSI.Page.ClaimChartOverview.LoadSpinner(chartDivID, spinnerDivID);
    var chartTitle = 'Claim Payments By Category';
    CHSI.Forms.ajaxFormDetails = jQuery.ajax({
        type: "GET",
        url: "/api/ClaimSystem/ClaimChart/GetClaimPaymentsByCategory?clientID=" + CHSI.Page.ClaimChartOverview.ChartClientID + "&IncludeChildren=" + IncludeChildren,
        contentType: "application/json; charset=utf-8",
        cache: false,
        dataType: "json",
        success: function (e) {
            CHSI.Common.LogObject(e);
            CHSI.Page.ClaimChartOverview.CreateStackedColumnChart(chartDivID, chartTitle, e);
        }, error: function (e) {
            CHSI.Page.ClaimChartOverview.ProcessChartError(spinnerDivID, chartTitle);
        }
    });
};

CHSI.Page.ClaimChartOverview.CreateChart2 = function (IncludeChildren) {
    var chartDivID = 'jxChart2';
    var spinnerDivID = 'jxChartSpinner2'
    CHSI.Page.ClaimChartOverview.LoadSpinner(chartDivID, spinnerDivID);
    var chartTitle = 'Net Incurred Claim Costs By Program Year';
    CHSI.Forms.ajaxFormDetails = jQuery.ajax({
        type: "GET",
        url: "/api/ClaimSystem/ClaimChart/GetNetIncurredClaimCostsByPolicyYear?clientID=" + CHSI.Page.ClaimChartOverview.ChartClientID + "&IncludeChildren=" + IncludeChildren,
        contentType: "application/json; charset=utf-8",
        cache: false,
        dataType: "json",
        success: function (e) {
            CHSI.Common.LogObject(e);
            CHSI.Page.ClaimChartOverview.CreateStackedColumnChart(chartDivID, chartTitle, e);
        }, error: function (e) {
            CHSI.Page.ClaimChartOverview.ProcessChartError(spinnerDivID, chartTitle);
        }
    });
};

CHSI.Page.ClaimChartOverview.CreateChart3 = function (IncludeChildren) {
    var chartDivID = 'jxChart3';
    var spinnerDivID = 'jxChartSpinner3'
    CHSI.Page.ClaimChartOverview.LoadSpinner(chartDivID, spinnerDivID);
    var chartTitle = 'Claim Count By Program Year';
    CHSI.Forms.ajaxFormDetails = jQuery.ajax({
        type: "GET",
        url: "/api/ClaimSystem/ClaimChart/GetClaimCountByPolicyYear?clientID=" + CHSI.Page.ClaimChartOverview.ChartClientID + "&IncludeChildren=" + IncludeChildren,
        contentType: "application/json; charset=utf-8",
        cache: false,
        dataType: "json",
        success: function (e) {
            CHSI.Common.LogObject(e);
            CHSI.Page.ClaimChartOverview.CreateStackedColumnChart(chartDivID, chartTitle, e);
        }, error: function (e) {
            CHSI.Page.ClaimChartOverview.ProcessChartError(spinnerDivID, chartTitle);
        }
    });
};

CHSI.Page.ClaimChartOverview.CreateChart4 = function (IncludeChildren) {
    var chartDivID = 'jxChart4';
    var spinnerDivID = 'jxChartSpinner4'
    CHSI.Page.ClaimChartOverview.LoadSpinner(chartDivID, spinnerDivID);
    var chartTitle = 'Total Net Incurred Costs By Program Year';
    CHSI.Forms.ajaxFormDetails = jQuery.ajax({
        type: "GET",
        url: "/api/ClaimSystem/ClaimChart/GetTotalNetIncurredCostsByPolicyYear?clientID=" + CHSI.Page.ClaimChartOverview.ChartClientID + "&IncludeChildren=" + IncludeChildren,
        contentType: "application/json; charset=utf-8",
        cache: false,
        dataType: "json",
        success: function (e) {
            CHSI.Common.LogObject(e);
            CHSI.Page.ClaimChartOverview.CreateStackedColumnChart(chartDivID, chartTitle, e);
        }, error: function (e) {
            CHSI.Page.ClaimChartOverview.ProcessChartError(spinnerDivID, chartTitle);
        }
    });
};

CHSI.Page.ClaimChartOverview.BuildCharts = function (asOfDate, IncludeChildren) {
    CHSI.Page.ClaimChartOverview.ChartAsOfDate = asOfDate;
    CHSI.Page.ClaimChartOverview.CreateChart1(IncludeChildren);
    CHSI.Page.ClaimChartOverview.CreateChart2(IncludeChildren);
    CHSI.Page.ClaimChartOverview.CreateChart3(IncludeChildren);
    CHSI.Page.ClaimChartOverview.CreateChart4(IncludeChildren);
};
