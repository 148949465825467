CHSI.GUI = new Object();
CHSI.GUI.Romanize = function (num) {
    if (!+num)
        return false;
    var digits = String(+num).split(""),
		key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
		       "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
		       "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
		roman = "",
		i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
};
CHSI.GUI.DeRomanize = function (str) {
    var str = str.toUpperCase(),
		validator = /^M*(?:D?C{0,3}|C[MD])(?:L?X{0,3}|X[CL])(?:V?I{0,3}|I[XV])$/,
		token = /[MDLV]|C[MD]?|X[CL]?|I[XV]?/g,
		key = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 },
		num = 0, m;
    if (!(str && validator.test(str)))
        return false;
    while (m = token.exec(str))
        num += key[m[0]];
    return num;
};

CHSI.GUI.AjaxInit = function () {

    if (jQuery('#spinner').length === 0) {
        jQuery('body').append('<div id="spinner" class="spinner" style="display: none;"><img src="/images/Dialog/spinner.gif" alt="loading..." /><b> Loading...</b> </div>');
    }

    jQuery(document).ajaxStart(function () {
        jQuery('#spinner').show();
    }).ajaxStop(function () {
        jQuery('#spinner').hide();
    });
}
CHSI.GUI.AjaxStart = function () {
    jQuery('#spinner').show();
}
CHSI.GUI.AjaxStop = function () {
    jQuery('#spinner').hide();
    restart = true; sorttable.init(); SetSize();
}
CHSI.GUI.BuildSelectItemHTML = function (Collection, LabelField, ValueField, SelectedValue)
{
    var ResultHTML = [];
    for (var ResultIndex = 0, ResultSetLength = Collection.length; ResultIndex < ResultSetLength; ++ResultIndex) {
        var isSelected = '';
        if (SelectedValue == Collection[ResultIndex][ValueField])
        {
            isSelected = ' selected=selected ';
        }
        ResultHTML.push('<option value="' + Collection[ResultIndex][ValueField] + '" ' + isSelected + '>' + Collection[ResultIndex][LabelField] + '</option>');
    }
    return ResultHTML.join('');
}

CHSI.GUI.MakeSelectionBlock = function (control, VariableName, defaultvalue, callbackfunction) {
    control.addClass('Selector');
    var Links = control.children('a');
    var HTML = [];
    HTML.push('<div class="SelectorContainer">');
    HTML.push('<div class="SelectorContainerBG">');
    HTML.push('<div class="SelectorWindow"></div>');
    $.each(Links, function (index, obj) {
        HTML.push('<a href="javascript: void(0)" class="FormStatusLinks" data-selectedstatus=" " data-formstatusvalue="' + $(obj).html() + '" id="' + control.attr('id') + '_' + index + '">' + $(obj).html() + '</a>');
    });

    HTML.push('</div></div>');
    control.html(HTML.join(''));
    var RenderedLinks = jQuery(control.find('a'));
    RenderedLinks.bind('click', function () {
        var Link = jQuery(this);
        var NewValue = Link.html();
        CHSI.GUI.ActivateLink(Link, VariableName, NewValue, callbackfunction);
    });
    jQuery.each(RenderedLinks, function (index, obj) {
        var jLink = jQuery(obj);
        if (jLink.html() == defaultvalue) {
            CHSI.GUI.ActivateLink(jLink, VariableName, defaultvalue, callbackfunction);
            return;
        }
    });
}

CHSI.GUI.ActivateLink = function (sender, VariableName, NewValue, callbackfunction) {

    eval(VariableName + " = '" + NewValue + "'");
    var ActiveLink = sender;
    /* Find the selector window associated with this link*/
    var SelWindow = ActiveLink.parent().children('.SelectorWindow');
    SelWindow.height(ActiveLink.height());
    /* Find the left position and the width of the clicked link*/
    var NewLeft = ActiveLink.position().left;
    var NewWidth = ActiveLink.width() + 30;
    /* Move the selector window to match the clicked link using animation for .5 seconds */
    SelWindow.animate({
        width: NewWidth,
        left: NewLeft
    }, 500, function () {
        var Links = ActiveLink.parent().find('a');
        
        CHSI.Common.Log(Links);
        CHSI.Common.Log(ActiveLink);
        Links.css('font-weight', 'normal');
        Links.removeClass('ActiveFormStatus');
        ActiveLink.css('font-weight', 'bold');
        ActiveLink.addClass('ActiveFormStatus');
        callbackfunction();
    });

}

CHSI.GUI.ZebraStripe = function () {
    JQuery('.dtable tr:even').addClass('.arow');
}