module PL.ClaimSystem.ClaimManagement {
    export class ClaimModel {
        constructor() { }

        public ClaimID: number;
        public GroupLineID: number;
        public ClaimClass: string;
        public ClaimType: string;
        public IncidentID: number;
        public Source: string;
        public ExtKey: number;
        public ClientID: number;
        public LocationID: number;
        public ClaimStatus: string;
        public DateOfIncident: string;
        public DateOpened: string;
        public DateClosed: string;
        public DateReOpened: string;
        public DateDenied: string;
        public DateReported: string;
        public DateFormProvided: string;
        public DateReceived: string;
        public Subrogated: boolean;
        public Litigated: boolean;
        public Triaged: boolean;
        public UnderReview: boolean;
        public SubroPotential: boolean;
        public Escalated: boolean;
        public SubroAmount: number;
        public ReopenReason: string;
        public Jurisdiction: string;
        public CreateUser: string;
        public CreateTmsp: string;
        public UpdateUser: string;
        public UpdateTmsp: string;
    }
}