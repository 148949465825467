module PL.AdminTools.Enums {    

    export enum Alert_Type {

        MESSAGE,
        ACTION_MESSAGE

    }

    export enum Alert_Name {

        NO_NOTIFICATIONS_GRID,
        NO_NOTIFICATIONS_FORM,
        SAVE_SECTION_SUCCESS,
        SAVE_SECTION_ERROR,   
        SAVE_ELEMENT_SUCCESS,     
        SAVE_ELEMENT_ERROR,
        SAVE_ACTION_SUCCESS,
        SAVE_ACTION_ERROR

    }

    export enum Alert_Indicator {

        DEFAULT,
        SUCCESS,
        WARNING,
        ERRROR

    }

}
