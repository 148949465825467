module GUI.Tables {
    export class Helpers implements ITables {

        data: GUI.Smart.SmartObject = new GUI.Smart.SmartObject;
        containerName: string;
        $grid: any;
        $container: JQuery;
        $table: JQuery;

        masterDetailTemplate: (data: any) => any;

        editColumn(targetRow: number): void {
            var _self = this;
            var Column = new GUI.Smart.SmartColumnObject();
            //title: '',
            Column.data = null;
            Column.orderable = false;
            Column.class = 'details-control';
            Column.defaultContent = '[<a href="#" class="minilink">view</a>]';
            Column.render = function (data, type, row) {
                return "[<a class='jxEdit minilink' data-exceptionid='" + row[targetRow] + "' href='#'>Edit</a>]";
            }
            _self.data.Columns.push(Column);
        }

        addCustomColumn(columnName: string, columnNameAsHTML: string, columnDataIndex?: number): any {
            var _self = this;
            var Column = new GUI.Smart.SmartColumnObject();
            //title: '',
            Column.data = null;
            Column.orderable = false;
            Column.class = 'details-control';
            Column.defaultContent = '[<a href="#" class="minilink">view</a>]';
            Column.render = function (data, type, row) {
                return columnNameAsHTML;
            }

            _self.data.Columns.push(Column);
        }

        clickColumn(columnName?: string, clickCallback?: (rowData) => any): void {
            var _self = this;

            $('#' + _self.containerName + ' tbody').on('click', 'tr',(e) => {
                if (clickCallback) {
                    var rowData = _self.$grid.row(e.currentTarget).data();
                    clickCallback(rowData);
                }
            });

        }

        openMasterDetail(clickEventName: string, HTML: string) {
            var _self = this;

            _self.$container.on('click',(e) => {
                var tr = $(e.target).closest('tr');
                var row = _self.$grid.row(tr);

                if (row.child.isShown()) {
                    row.child.hide();
                    tr.removeClass('ui-icon-triangle-1-n');
                } else {
                    row.child(_self.masterDetailTemplate(row)).show();
                    tr.addClass('ui-icon-triangle-1-s');
                }
            });

            // TODO 

            // finish master detail method
        }

        //hasURL(URL: string): void {
        //    var _self = this;
        //    if (URL != null) {
        //        $.get(URL).done(function (data) {
        //            _self.data.Values = data.Values;
        //            _self.data.Columns = data.Columns;
        //        }).then(function () {
        //            _self.buildTable();
        //        });
        //    } else {
        //        _self.buildTable();
        //    }
        //}

        //rebindTable(newData): void {
        //    this.$table.remove();
        //    this.data = newData;
        //    this.buildTable();
        //}
    }
} 