module PL.CRM.ViewModels {
    export class ExcessPolicy {
        constructor() {
            this.Groups = [];
        }
        
        public Modal: GUI.Windows.Popup;
        public Policy: Models.ExcessPolicy;
        public BPolicy: Models.ExcessPolicy;
        public CPolicy: Models.ExcessPolicy;
        public DPolicy: Models.ExcessPolicy;
        public EPolicy: Models.ExcessPolicy;
        public GroupPolicy: Models.ExcessGroupPolicy;
        public Groups: Array<string>;
        public PrevPoliciesDataTable: DataTables.DataTable;

        public Init = () => {
            this.GetGroups().done(() => { // get group first
                this.LoadPageByGroup(this.Groups[0]);
            });
        };     

        private ShowLoading = () => {
            $('#jxLoading').show();
        };

        private HideLoading = () => {
            $('#jxLoading').hide();
        };

        public LoadPageByGroup(groupID: string) {
            this.ShowLoading();
            this.GetExcessByGroup(groupID).done(() => { // then get data needed for handlebars
                $.when(this.GetPolicyByLetter('B'), this.GetPolicyByLetter('C'), this.GetPolicyByLetter('D'), this.GetPolicyByLetter('E')).done(() => {
                    (<any>CHSI).HandleBars.Preparehelpers();
                    var template = Handlebars.compile($('#Template_ExcessPolicy').html());
                    $('#excess-policy-view').html(template({ Groups: this.Groups, GroupPolicy: this.GroupPolicy, BP: this.BPolicy, CP: this.CPolicy, DP: this.DPolicy, EP: this.EPolicy }));
                    $('#group-select').val(groupID);
                    $.each($('#excess-policy-view .date'), function (i, e) {
                        $(e).datepicker({
                            showOn: "both",
                            buttonImage: "/images/icons/cal.gif",
                            buttonImageOnly: true,
                            buttonText: "Select date"
                        });
                    });
                    if (this.GroupPolicy) {
                        $('input[name="ExcessProviderLetter"][value="' + this.GroupPolicy.ExcessProviderLetter + '"]').prop('checked', true);
                    } else {
                        $('#excess-companies-container').hide();
                    }
                    $('#excess-form-save').on('click', () => {
                        this.SaveExcessForm();
                    });
                    $('.add-policy').on('click', (e) => {
                        var letter = $(e.target).data('provider');
                        this.CreateNewPolicy(letter);
                    });
                    $('#group-select').on('change', () => {
                        this.LoadPageByGroup($('#group-select').val());
                    });
                    this.GetPolicies().done(() => {
                        this.HideLoading();
                    });
                });
            });
        };

        public MapFormToPolicy = () => {
            $.each($('#modal_new-policy .popup-form-input, #modal_new-policy .popup-form-textarea'), (i, e) => {
                this.Policy[$(e).attr('id')] = $(e).val();
            });
        };

        public MapFormToPolicyByLetter = (letter: string) => {
            $.each($('#provider-' + letter + ' .popup-form-input'), (i, e) => {                
                switch (letter.toLowerCase()) {
                    case 'B':
                        this.BPolicy[$(e).attr('id').replace('B-','')] = $(e).val();
                        break;
                    case 'C':
                        this.CPolicy[$(e).attr('id').replace('C-', '')] = $(e).val();
                        break;
                    case 'D':
                        this.DPolicy[$(e).attr('id').replace('D-', '')] = $(e).val();
                        break;
                    case 'E':
                        this.EPolicy[$(e).attr('id').replace('E-', '')] = $(e).val();
                        break;
                }
            });
        };

        public MapFormToExcess = () => {
            if (!this.GroupPolicy) this.GroupPolicy = new Models.ExcessGroupPolicy();
            $.each($('#excess-form-container .popup-form-input, #excess-form-container .popup-form-textarea'), (i, e) => {
                this.GroupPolicy[$(e).attr('id')] = $(e).val();
            });
            this.GroupPolicy.ExcessProviderLetter = $('input[name = "ExcessProviderLetter"]:checked').val();
            this.GroupPolicy.GroupName = $('#group-select').val();
        };

        public SaveExcessForm = (): JQueryDeferred<any> => {
            var df = $.Deferred();
            this.MapFormToExcess();
            this.SaveExcess().done(() => {
                $('#excess-companies-container').show();
                df.resolve();
            }).fail(() => {
                df.reject();
            });
            return df;
        };

        public SavePolicy = (): JQueryDeferred<any> => {
            let df = $.Deferred();
            this.ShowLoading();
            $.ajax({
                type: "POST",
                url: "/api/CRM/ExcessPolicy/SaveExcessPolicy",
                data: JSON.stringify(this.Policy),
                contentType: "application/json",
                success: () => {
                    this.HideLoading();
                    df.resolve();
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    //WebUtilities.genericAJAXErrorHandler(jqXHR, textStatus, errorThrown);
                    this.HideLoading();
                    df.reject();
                }
            });
            return df;
        };     

        public GetPolicyByLetter = (letter: string): JQueryDeferred<any> => {
            let df = $.Deferred();
            if (!letter || !this.GroupPolicy) {
                this.BPolicy = this.CPolicy = this.DPolicy = this.EPolicy = null;
                df.reject();
                return;
            }
            $.ajax({
                type: "GET",
                url: "/api/CRM/ExcessPolicy/GetPolicyByLetter?letter="+letter+"&excessID="+this.GroupPolicy.ExcessID,
                success: (policy: Models.ExcessPolicy) => {
                    switch (letter.toLowerCase()) {
                        case 'b':
                            if(policy && policy.EffectiveDate) policy.EffectiveDate = moment(policy.EffectiveDate).format('MM/DD/YYYY');
                            if (policy && policy.ExpirationDate) policy.ExpirationDate = moment(policy.ExpirationDate).format('MM/DD/YYYY');
                            this.BPolicy = policy;
                            break;
                        case 'c':
                            if (policy && policy.EffectiveDate) policy.EffectiveDate = moment(policy.EffectiveDate).format('MM/DD/YYYY');
                            if (policy && policy.ExpirationDate) policy.ExpirationDate = moment(policy.ExpirationDate).format('MM/DD/YYYY');
                            this.CPolicy = policy;
                            break;
                        case 'd':
                            if (policy && policy.EffectiveDate) policy.EffectiveDate = moment(policy.EffectiveDate).format('MM/DD/YYYY');
                            if (policy && policy.ExpirationDate) policy.ExpirationDate = moment(policy.ExpirationDate).format('MM/DD/YYYY');
                            this.DPolicy = policy;
                            break;
                        case 'e':
                            if (policy && policy.EffectiveDate) policy.EffectiveDate = moment(policy.EffectiveDate).format('MM/DD/YYYY');
                            if (policy && policy.ExpirationDate) policy.ExpirationDate = moment(policy.ExpirationDate).format('MM/DD/YYYY');
                            this.EPolicy = policy;
                            break;
                    }
                    df.resolve();
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    //WebUtilities.genericAJAXErrorHandler(jqXHR, textStatus, errorThrown);
                    df.reject();
                }
            });
            return df;
        };

        public SaveExcess = (): JQueryDeferred<any> => {
            let df = $.Deferred();
            this.ShowLoading();
            $.ajax({
                type: "POST",
                url: "/api/CRM/ExcessPolicy/SaveExcess",
                data: JSON.stringify(this.GroupPolicy),
                contentType: "application/json",
                success: (excessID) => {
                    this.GroupPolicy.ExcessID = excessID;
                    this.HideLoading();
                    df.resolve();
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    //WebUtilities.genericAJAXErrorHandler(jqXHR, textStatus, errorThrown);
                    this.HideLoading();
                    df.reject();
                }
            });
            return df;
        };     

        public GetGroups = (): JQueryDeferred<any> => {
            let df = $.Deferred();
            $.ajax({
                type: "GET",
                url: "/api/CRM/Group/GetActiveGroups",
                success: (groups) => {
                    for (var g of groups) {
                        this.Groups.push((<any>g).GroupName);
                    }
                    df.resolve();
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    //WebUtilities.genericAJAXErrorHandler(jqXHR, textStatus, errorThrown);
                    df.reject();
                }
            });
            return df;
        }

        public GetPolicies = (): JQueryDeferred<any> => {
            let df = $.Deferred();
            let groupID = $('#group-select').val();
            $.ajax({
                type: "GET",
                url: "/api/CRM/ExcessPolicy/GetPolicies?GroupID=" + groupID,
                success: (smartgrid) => {
                    // setup and create datatable
                    let settings = mW.DataTableHelpers.BuildSettings(smartgrid);
                    //settings.searching = true;
                    settings.paging = false;
                    //settings.scrollY = ($('#divTabs').height() - 200) + "px";
                    //settings.ordering = false;
                    this.PrevPoliciesDataTable = mW.DataTableHelpers.BuildDataTable('excess-previous-policies', settings);
                    df.resolve();
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    //WebUtilities.genericAJAXErrorHandler(jqXHR, textStatus, errorThrown);
                    df.reject();
                }
            });
            return df;
        };

        public GetExcessByGroup = (groupID: string): JQueryDeferred<any> => {
            let df = $.Deferred();
            $.ajax({
                type: "GET",
                url: "/api/CRM/ExcessPolicy/GetExcessByGroup?groupID=" + groupID,
                success: (excess: Models.ExcessGroupPolicy) => {
                    if (excess && excess.GroupPolicyEff) {
                        excess.GroupPolicyEff = moment(excess.GroupPolicyEff).format("MM/DD/YYYY");
                        this.GroupPolicy = excess;
                    }
                    df.resolve();
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    //WebUtilities.genericAJAXErrorHandler(jqXHR, textStatus, errorThrown);
                    df.reject();
                }
            });
            return df;
        };

        /* Assignee Modal */
        public CreateNewPolicy = (letter: string) => {
            this.Policy = new Models.ExcessPolicy();
            this.Policy.ExcessID = this.GroupPolicy.ExcessID;
            this.Policy.ExcessProvider = letter;
            this.CreatePolicyModal();
        };

        public CreatePolicyModal = () => {
            // create modal
            this.Modal = new GUI.Windows.Popup();
            this.Modal.WindowID = "new-policy";
            this.Modal.Title = "New Excess Policy";
            this.Modal.Width = 400;
            this.Modal.Height = 320;
            //this.Modal.HTMLContent = Handlebars.compile($('#claimant-template').html())(null);
            this.Modal.HTMLContent = CHSITemplates.CRM.NewExcessPolicy(this.Policy);
            this.Modal.showInline();
                
            // add widgets
            $.each($('#modal_new-policy .date'), function (i, e) {
                $(e).datepicker({
                    showOn: "both",
                    buttonImage: "/images/icons/cal.gif",
                    buttonImageOnly: true,
                    buttonText: "Select date"
                });
            });

            $('.policy-save').on('click', (e) => {
                this.MapFormToPolicy();
                $(e.target).prop('disabled', true); // prevent duplicated
                this.SavePolicy().done(() => {
                    GUI.Windows.Popup.closeThisInline('#modal_new-policy');
                    this.LoadPageByGroup(this.GroupPolicy.GroupName);
                });
            });

            $('.policy-cancel').on('click', () => {
                GUI.Windows.Popup.closeThisInline('#modal_new-policy');
            });
        };                     
    }
}