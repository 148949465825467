module PL.Claims.Models {
    export class SignNow {
        IncidentID: number;
        IframeURL: string;
        constructor() {
            this.IncidentID = null;
            this.IframeURL = null;
        }

        init(incidentID: number, iframeURL: string): void {

            this.IncidentID = incidentID;
            this.IframeURL = iframeURL;

            $("#signNowDocument").attr("src", iframeURL);

            $('#signatureContainer').load(function () {
                $('#loading-icon').show();
                $('#loading-text').show();
            });
            $("#signNowDocument").load(function () {
                $('#loading-text').hide();
            });
           // this.event();
        }

        //private event(): void {
        //    $('#signatureContainer').on('click', '#close', function () {
        //        closeThis(true);
        //    });
        //}
    }

}