module PL.ClaimSystem.ClaimManagement {
    export class LitigationModel {
        constructor() { }

        public ClaimRepID: number;
        public ClaimID: number;
        public CaseNumber: number;
        public Court: string;
        public Venue: string;
        public Served: string;
        public Answered: string;
        public Status: string;
        public Comments: string;
        public CreateUser: string;
        public CreateTmsp: string;
        public UpdateUser: string;
        public UpdateTmsp: string;
    }
}