module PL.Claims.Models {

    export class ClaimIncidentReport {

        IncidentID: number;
        ClientNumber: string;
        GroupID: string;
        IncidentDate: Date | string;
        Status: string | Enums.ClaimIntakeForm_Status; // we convert the enum value to a string value below in a private method.
        FormTypeID: number;
        FormType: string;
        LocationID: number;
        NatureOfBusiness: string;
        StateAccountNo: string;
        TypeOfEmployer: string;
        TimeReportedForWork: Date;
        DateOfDeath: Date;
        ReturnedToWork: string;
        LastDayOfWork: Date;
        DateReturned: Date;
        WagesPaid: string;
        WagesContinued: string;
        DateNotified: Date;
        DateFormProvided: Date;
        NatureOfInjuryCode: number;
        CauseOfInjuryCode: number;
        AnatomyCode: number;
        AnatomyAffected: string;
        IncidentLocation: string;
        IncidentAddress: string;
        IncidentCity: string;
        IncidentState: string;
        IncidentZip: string;
        IncidentCounty: string;
        OccurredOnPremises: string;
        IncidentDepartment: string;
        OthersInvolved: string;
        IncidentMechanism: string;
        IncidentActions: string;
        IncidentDescription: string;
        PhysicianTreating: string;
        PhysicianAddress: string;
        PhysicianCity: string;
        PhysicianState: string;
        PhysicianZip: string;
        PhysicianPhone: string;
        HospitalName: string;
        HospitalAddress: string;
        HospitalCity: string;
        HospitalState: string;
        HospitalZip: string;
        HospitalPhone: string;
        HispitalERVisit: string;
        LostDays: number;
        ReceivedUnemployment: string;
        IncidentSupervisor: string;
        ValidityDoubted: string;
        Witness1: string;
        Witness2: string;
        Witness3: string;
        LightDutyAvailable: string;
        MCOName: string;
        LastWagesDate: Date;
        EmployeeName: string;
        EmpMiddleInitial: string;
        EmpFirstName: string;
        EmpLastName: string;
        SocialSecurityNo: string;
        DateOfBirth: Date;
        EmpAddress: string;
        EmpCity: string;
        EmpState: string;
        EmpZip: string;
        EmpCountryCode: number;
        EmpPhone: string;
        EmpSex: string;
        EmpOccupation: string;
        DateOfHire: Date;
        EmpDailyHours: number;
        EmpWorkDays: number;
        EmpWeekHours: number;
        EmpStatus: string;
        IncidentClassCode: string;
        EmpWages: number;
        EmpWagesPer: string;
        NonReportdWages: string;
        MaritalStatus: string;
        EmpHireState: string;
        EmpOfficer: string;
        EmpProprietor: string;
        EmpPartner: string;
        EmpDepartment: string;
        EmployedAtIncident: string;
        EmpNormalStart: string;
        EmpNormalEnd: string;
        ScheduledDays: string;
        PayPeriodEnds: string;
        EmpPayFrequency: string;
        PrimaryLanguage: string;
        SafetyEquipmentProvided: string;
        SafetyEquipmentUsed: string;
        FormCompletedBy: string;
        FormCompletedTitle: string;
        FormCompletedDate: Date | string;
        LateReasonCode: string;
        ExtRefNum: string;
        TPACode: string;
        ClaimNumber: string;
        CreateTmsp: Date;
        CreateUser: string;
        UpdateTmsp: Date;
        UpdateUser: string;
        ReturnURL: string;
        EsignatureURL: string;
        EsignatureDocID: string;
        constructor(incident?: ClaimIncidentReport) {
            this.IncidentID = incident == undefined ? null : incident.IncidentID;
            this.ClientNumber = incident == undefined ? null : incident.ClientNumber;
            this.GroupID = incident == undefined ? null : incident.GroupID;
            this.IncidentDate = incident == undefined ? null : incident.IncidentDate;
            this.Status = incident == undefined ? null : incident.Status;
            this.FormTypeID = incident == undefined ? null : incident.FormTypeID;
            this.FormType = incident == undefined ? null : incident.FormType;
            this.LocationID = incident == undefined ? null : incident.LocationID;
            this.NatureOfBusiness = incident == undefined ? null : incident.NatureOfBusiness;
            this.StateAccountNo = incident == undefined ? null : incident.StateAccountNo;
            this.TypeOfEmployer = incident == undefined ? null : incident.TypeOfEmployer;
            this.TimeReportedForWork = incident == undefined ? null : incident.TimeReportedForWork;
            this.DateOfDeath = incident == undefined ? null : incident.DateOfDeath;
            this.ReturnedToWork = incident == undefined ? null : incident.ReturnedToWork;
            this.LastDayOfWork = incident == undefined ? null : incident.LastDayOfWork;
            this.DateReturned = incident == undefined ? null : incident.DateReturned;
            this.WagesPaid = incident == undefined ? null : incident.WagesPaid;
            this.WagesContinued = incident == undefined ? null : incident.WagesContinued;
            this.DateNotified = incident == undefined ? null : incident.DateNotified;
            this.DateFormProvided = incident == undefined ? null : incident.DateFormProvided;
            this.NatureOfInjuryCode = incident == undefined ? null : incident.NatureOfInjuryCode;
            this.CauseOfInjuryCode = incident == undefined ? null : incident.CauseOfInjuryCode;
            this.AnatomyCode = incident == undefined ? null : incident.AnatomyCode;
            this.AnatomyAffected = incident == undefined ? null : incident.AnatomyAffected;
            this.IncidentLocation = incident == undefined ? null : incident.IncidentLocation;
            this.IncidentAddress = incident == undefined ? null : incident.IncidentAddress;
            this.IncidentCity = incident == undefined ? null : incident.IncidentCity;
            this.IncidentState = incident == undefined ? null : incident.IncidentState;
            this.IncidentZip = incident == undefined ? null : incident.IncidentZip;
            this.IncidentCounty = incident == undefined ? null : incident.IncidentCounty;
            this.OccurredOnPremises = incident == undefined ? null : incident.OccurredOnPremises;
            this.IncidentDepartment = incident == undefined ? null : incident.IncidentDepartment;
            this.OthersInvolved = incident == undefined ? null : incident.OthersInvolved;
            this.IncidentMechanism = incident == undefined ? null : incident.IncidentMechanism;
            this.IncidentActions = incident == undefined ? null : incident.IncidentActions;
            this.IncidentDescription = incident == undefined ? null : incident.IncidentDescription;
            this.PhysicianTreating = incident == undefined ? null : incident.PhysicianTreating;
            this.PhysicianAddress = incident == undefined ? null : incident.PhysicianAddress;
            this.PhysicianCity = incident == undefined ? null : incident.PhysicianCity;
            this.PhysicianState = incident == undefined ? null : incident.PhysicianState;
            this.PhysicianZip = incident == undefined ? null : incident.PhysicianZip;
            this.PhysicianPhone = incident == undefined ? null : incident.PhysicianPhone;
            this.HospitalName = incident == undefined ? null : incident.HospitalName;
            this.HospitalAddress = incident == undefined ? null : incident.HospitalAddress;
            this.HospitalCity = incident == undefined ? null : incident.HospitalCity;
            this.HospitalState = incident == undefined ? null : incident.HospitalState;
            this.HospitalZip = incident == undefined ? null : incident.HospitalZip;
            this.HospitalPhone = incident == undefined ? null : incident.HospitalPhone;
            this.HispitalERVisit = incident == undefined ? null : incident.HispitalERVisit;
            this.LostDays = incident == undefined ? null : incident.LostDays;
            this.ReceivedUnemployment = incident == undefined ? null : incident.ReceivedUnemployment;
            this.IncidentSupervisor = incident == undefined ? null : incident.IncidentSupervisor;
            this.ValidityDoubted = incident == undefined ? null : incident.ValidityDoubted;
            this.Witness1 = incident == undefined ? null : incident.Witness1;
            this.Witness2 = incident == undefined ? null : incident.Witness2;
            this.Witness3 = incident == undefined ? null : incident.Witness3;
            this.LightDutyAvailable = incident == undefined ? null : incident.LightDutyAvailable;
            this.MCOName = incident == undefined ? null : incident.MCOName;
            this.LastWagesDate = incident == undefined ? null : incident.LastWagesDate;
            this.EmployeeName = incident == undefined ? null : incident.EmployeeName;
            this.EmpMiddleInitial = incident == undefined ? null : incident.EmpMiddleInitial;
            this.EmpFirstName = incident == undefined ? null : incident.EmpFirstName;
            this.EmpLastName = incident == undefined ? null : incident.EmpLastName;
            this.SocialSecurityNo = incident == undefined ? null : incident.SocialSecurityNo;
            this.DateOfBirth = incident == undefined ? null : incident.DateOfBirth;
            this.EmpAddress = incident == undefined ? null : incident.EmpAddress;
            this.EmpCity = incident == undefined ? null : incident.EmpCity;
            this.EmpState = incident == undefined ? null : incident.EmpState;
            this.EmpZip = incident == undefined ? null : incident.EmpZip;
            this.EmpCountryCode = incident == undefined ? null : incident.EmpCountryCode;
            this.EmpPhone = incident == undefined ? null : incident.EmpPhone;
            this.EmpSex = incident == undefined ? null : incident.EmpSex;
            this.EmpOccupation = incident == undefined ? null : incident.EmpOccupation;
            this.DateOfHire = incident == undefined ? null : incident.DateOfHire;
            this.EmpDailyHours = incident == undefined ? null : incident.EmpDailyHours;
            this.EmpWorkDays = incident == undefined ? null : incident.EmpWorkDays;
            this.EmpWeekHours = incident == undefined ? null : incident.EmpWeekHours;
            this.EmpStatus = incident == undefined ? null : incident.EmpStatus;
            this.IncidentClassCode = incident == undefined ? null : incident.IncidentClassCode;
            this.EmpWages = incident == undefined ? null : incident.EmpWages;
            this.EmpWagesPer = incident == undefined ? null : incident.EmpWagesPer;
            this.NonReportdWages = incident == undefined ? null : incident.NonReportdWages;
            this.MaritalStatus = incident == undefined ? null : incident.MaritalStatus;
            this.EmpHireState = incident == undefined ? null : incident.EmpHireState;
            this.EmpOfficer = incident == undefined ? null : incident.EmpOfficer;
            this.EmpProprietor = incident == undefined ? null : incident.EmpProprietor;
            this.EmpPartner = incident == undefined ? null : incident.EmpPartner;
            this.EmpDepartment = incident == undefined ? null : incident.EmpDepartment;
            this.EmployedAtIncident = incident == undefined ? null : incident.EmployedAtIncident;
            this.EmpNormalStart = incident == undefined ? null : incident.EmpNormalStart;
            this.EmpNormalEnd = incident == undefined ? null : incident.EmpNormalEnd;
            this.ScheduledDays = incident == undefined ? null : incident.ScheduledDays;
            this.PayPeriodEnds = incident == undefined ? null : incident.PayPeriodEnds;
            this.PrimaryLanguage = incident == undefined ? null : incident.PrimaryLanguage;
            this.SafetyEquipmentProvided = incident == undefined ? null : incident.SafetyEquipmentProvided;
            this.SafetyEquipmentUsed = incident == undefined ? null : incident.SafetyEquipmentUsed;
            this.FormCompletedBy = incident == undefined ? null : incident.FormCompletedBy;
            this.FormCompletedTitle = incident == undefined ? null : incident.FormCompletedTitle;
            this.FormCompletedDate = incident == undefined ? null : incident.FormCompletedDate;
            this.LateReasonCode = incident == undefined ? null : incident.LateReasonCode;
            this.ExtRefNum = incident == undefined ? null : incident.ExtRefNum;
            this.TPACode = incident == undefined ? null : incident.TPACode;
            this.ClaimNumber = incident == undefined ? null : incident.ClaimNumber;
            this.CreateTmsp = incident == undefined ? null : incident.CreateTmsp;
            this.CreateUser = incident == undefined ? null : incident.CreateUser;
            this.UpdateTmsp = incident == undefined ? null : incident.UpdateTmsp;
            this.UpdateUser = incident == undefined ? null : incident.UpdateUser;
            this.ReturnURL = incident == undefined ? null : incident.ReturnURL;
            this.EsignatureURL = incident == undefined ? null : incident.EsignatureURL;
            this.EsignatureDocID = incident == undefined ? null : incident.EsignatureDocID;
        }

        get(): JQueryDeferred<ClaimIncidentReport> {
            var result = $.Deferred();
            PL.Claims.APIs.ClaimIncidentReportRepo.getIncident(this.IncidentID).done((data) => {                            
                result.resolve(data);
            }).fail((data) => {
                result.resolve(data);
            });
            return result;
        }

        submit(): JQueryDeferred<ClaimIncidentReport> {
            var result = $.Deferred();                  

            PL.Claims.APIs.ClaimIncidentReportRepo.submitIncident(this.IncidentID).done((data) => {
                result.resolve(data);
            }).fail((data) => {
                result.reject(data);
            });
            return result;
        }

        save(): JQueryDeferred<number> {
            var result = $.Deferred();
            this.setEnumsStatusAsString();
            PL.Claims.APIs.ClaimIncidentReportRepo.saveIncident(this).done((data) => {
                result.resolve(data);
            }).fail((data) => {
                result.reject(data);
            });
            return result;
        }

        private setEnumsStatusAsString(): void {
            switch (this.Status) {
                case Enums.ClaimIntakeForm_Status.OPEN:
                    this.Status = 'Open';
                    break;
                case Enums.ClaimIntakeForm_Status.NEW:
                    this.Status = 'New';
                    break;
                case Enums.ClaimIntakeForm_Status.PENDING:
                    this.Status = 'Pending';
                    break;
                case Enums.ClaimIntakeForm_Status.SUBMITTED:
                    this.Status = 'Submitted';
                    break;
                case Enums.ClaimIntakeForm_Status.FIRST_AID:
                    this.Status = 'First Aid';
                    break;
            }
        }   
    }
}