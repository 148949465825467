declare function refreshXmodEditFromLossHistoryEdit(priorCoverageID, useLosses);

module PL.UW.LossHistory.Controller {

    export class LossHistoryEditController {

        PriorCoverageData: PL.UW.LossHistory.Model.PriorCoverage;
        PriorCoverageDetailData: GUI.Smart.SmartObject = new GUI.Smart.SmartObject;
        PriorCoverageLossData: GUI.Smart.SmartObject = new GUI.Smart.SmartObject;

        $lossInfoGrid: GUI.Tables.DTables;
        $payrollInfoGrid: GUI.Tables.DTables;

        LossHistoryContainerName: string = 'jxLossHistoryContainer';
        PriorCoverageID: number;
        ClientID: number;
        XmodID: number; // WorksheetID                        
        isXmod: boolean;
        isNewXmod: boolean;
        PolicyPeriod: number;
        MasterPolicyPeriod: number;

        _$lossHistoryContainer: JQuery;
        get $lossHistoryContainer(): JQuery {
            return $('#' + this.LossHistoryContainerName);
        }
        set $lossHistoryContainer(containerName: JQuery) {
            this._$lossHistoryContainer = $('#' + this.LossHistoryContainerName);
        }

        constructor() { }

        init(XmodID?: number, PriorCoverageID?: number|string, isNewXmod?: string, QuoteID?: number, GroupID?: string, ClientID?: number, PolicyPeriod?: number): void {
            var _self = this;

            this.XmodID = XmodID;
            this.PriorCoverageID = (PriorCoverageID == 'null' ? null : +PriorCoverageID);
            this.isXmod = (_self.XmodID > 0 ? true : false);
            this.isNewXmod = (isNewXmod == 'true' ? true : false);
            this.ClientID = ClientID;
            this.PolicyPeriod = PolicyPeriod;
            var xModSuccessFunction = null;

            PL.UW.LossHistory.API.LossHistoryAPI.getPriorCoverage(this.PriorCoverageID, function (pcData) {
                _self.PriorCoverageData = (pcData == null ? new PL.UW.LossHistory.Model.PriorCoverage : pcData);
                _self.PriorCoverageData.WorkSheetID = XmodID;
                _self.PriorCoverageData.GroupID = GroupID;
                _self.PriorCoverageData.ClientID = ClientID;
                _self.PriorCoverageData.MasterPolicyPeriod = PolicyPeriod;
                if (_self.isXmod) {
                    _self.xModSuccessFunction();
                } else {
                    // quote
                }
            });
        }

        xModSuccessFunction(): void {
            var _self = this;
            var API = PL.UW.LossHistory.API.LossHistoryAPI;
            API.getStates(_self.PriorCoverageData.GroupID, function (sData) {
                _self.bindLossHistoryPresentationLayer(function () {
                    _self.addStates(sData);
                    $('#master').data('groupid', _self.PriorCoverageData.GroupID);
                    $('#master').data('clientid', _self.PriorCoverageData.ClientID);
                    API.getPriorCoverageLosses(_self.PriorCoverageData.PriorCoverageID, function (plData) {
                        _self.PriorCoverageLossData = (plData == null ? new GUI.Smart.SmartObject : plData);
                        _self.bindLossesGrid(function () {
                            API.getPriorCoverageDetails(_self.PriorCoverageID, _self.PriorCoverageData.GroupID, null, function (prData) {
                                _self.PriorCoverageDetailData = (prData == null ? new GUI.Smart.SmartObject : prData);
                                _self.bindPayrollGird();
                                _self.setupEvents();
                            });
                        });
                    });
                });
            });
        }

        setupEvents(): void {

            $('#jxImportLosses').hide();
            $('#jxClaimNumContainer').hide();
            $('.errorMessage, .successMessage').hide();

            $(".dateField").datepicker({
                showOn: "both",
                buttonImage: "/images/icons/cal.gif",
                buttonImageOnly: true
            });

            var _self = this;

            _self.$lossHistoryContainer.on('click', '#jxSaveAndAdd', function () {
                var validationCode: number = 0;
                validationCode = (validationCode > 0 ? validationCode : _self.validateYear());
                validationCode = (validationCode > 0 ? validationCode : _self.validateRequiredFields('.jxPrimaryLossHistoryInformation'));
                if (!GUI.DateTime.dateRangeValidator($('#jxFromDate').val(), $('#jxToDate').val())) {
                    return false;
                }

                if (validationCode != 0) {
                    alert(_self.validationResponse(validationCode));
                } else {
                    _self.save(function () {
                        var models = PL.UW.LossHistory.Model;
                        models.PriorCoverage.clear();
                        models.PriorCoverageLoss.clear();
                        models.PriorCoverageDetail.clear();
                        _self.PriorCoverageLossData = new GUI.Smart.SmartObject;
                        _self.PriorCoverageDetailData = new GUI.Smart.SmartObject;
                        _self.$lossInfoGrid.newTable();
                        _self.$payrollInfoGrid.newTable();
                    });
                }
            });

            _self.$lossHistoryContainer.on('click', '#jxSaveAndFinish', function () {
                var validationCode: number = 0;
                validationCode = (validationCode > 0 ? validationCode : _self.validateYear());
                validationCode = (validationCode > 0 ? validationCode : _self.validateRequiredFields('.jxPrimaryLossHistoryInformation'));
                validationCode = (validationCode > 0 ? validationCode : _self.validateNumericFields('.jxPrimaryLossHistoryInformation'));

                if (!GUI.DateTime.dateRangeValidator($('#jxFromDate').val(), $('#jxToDate').val())) {
                    return false;
                }

                if (validationCode != 0) {
                    alert(_self.validationResponse(validationCode));
                } else {
                    _self.save(function () {
                        closeThis(false);
                    });
                }
            });

            _self.$lossHistoryContainer.on('click', '#jxAddPriorCoverageLosses', function () {
                var validationCode: number = 0;
                validationCode = (validationCode > 0 ? validationCode : _self.validateYear());
                validationCode = (validationCode > 0 ? validationCode : _self.validateRequiredFields('.jxPrimaryLossHistoryInformation'));
                validationCode = (validationCode > 0 ? validationCode : _self.validateNumericFields('.jxInformationFieldSet'));

                if (!GUI.DateTime.dateRangeValidator($('#jxFromDate').val(), $('#jxToDate').val())) {
                    return false;
                }

                if (validationCode != 0) {
                    alert(_self.validationResponse(validationCode));
                } else {
                    _self.saveLosses(function () {
                        $('#jxAddPriorCoverageLosses').val(' Add ');
                        $('#jxClaimNumContainer').hide();
                        $('#jxMedContainer').show();
                        $('#jxIndContainer').show();
                        PL.UW.LossHistory.Model.PriorCoverageLoss.clear();
                        _self.refreshLosses();
                    });
                }

            });

            _self.$lossHistoryContainer.on('click', '#jxAddPriorCoverageDetails', function () {
                //Call ValidateAndAddClassCode
                var validationCode: number = 0;
                var classCode = $('#jxClassCode').val();
                _self.validateClassCode(classCode).done(function (validationCode) {
                    validationCode = (validationCode > 0 ? validationCode : _self.validateYear());
                    validationCode = (validationCode > 0 ? validationCode : _self.validateRequiredFields('.jxPrimaryLossHistoryInformation'));
                    validationCode = (validationCode > 0 ? validationCode : _self.validateRequiredFields('#jxPayrollFieldSet'));
                    validationCode = (validationCode > 0 ? validationCode : _self.validateNumericFields('#jxPayrollFieldSet'));

                    if (!GUI.DateTime.dateRangeValidator($('#jxFromDate').val(), $('#jxToDate').val())) {
                        return false;
                    }

                    if (validationCode != 0) {
                        alert(_self.validationResponse(validationCode));
                    } else {
                        _self.saveDetails(function () {
                            $('#jxAddPriorCoverageDetails').val(' Add ');
                            PL.UW.LossHistory.Model.PriorCoverageDetail.clear();
                            _self.refreshPayroll();
                        });
                    }
                });
            });

            _self.$lossHistoryContainer.on('change', '#jxIndividual', function () {
                if ($(this).is(':checked')) {
                    $('#jxClaimNumContainer').show();
                    $('#jxMedContainer').hide();
                    $('#jxIndContainer').hide();
                    $('#jxOpen').prop('disabled', true);
                } else {
                    $('#jxClaimNumContainer').hide();
                    $('#jxMedContainer').show();
                    $('#jxIndContainer').show();
                    $('#jxOpen').prop('disabled', false);
                }
            });

            _self.$lossHistoryContainer.on('click', '#jxImportLosses', function () {
                var validationCode: number = 0;
                validationCode = (validationCode > 0 ? validationCode : _self.validateYear());
                validationCode = (validationCode > 0 ? validationCode : _self.validateRequiredFields('.jxPrimaryLossHistoryInformation'));

                if (validationCode != 0) {
                    alert(_self.validationResponse(validationCode));
                } else {
                    var fieldsValues = new PL.UW.LossHistory.Model.PriorCoverage();

                    PL.UW.LossHistory.API.LossHistoryAPI.ImportPriorCoverageLosses(+_self.ClientID, _self.PriorCoverageData.PolicyPeriod, _self.PriorCoverageData.ProductionDate, _self.PriorCoverageID, _self.PriorCoverageData.EffectiveDate, _self.PriorCoverageData.ExpirationDate, function () {
                        $('#jxImportErrorMessage').removeClass('errorMessage').addClass('successMessage').text('Import succesful').show();
                        $('.successMessage').delay(5000).fadeOut('slow');
                    });
                }
            });

            _self.$lossHistoryContainer.on('click', '#jxLossInformationContainer tbody tr',(e) => {
                $('#jxAddPriorCoverageLosses').val(' Update ');
                var rowData = _self.$lossInfoGrid.$grid.row(e.currentTarget).data();
                var $losses = new PL.UW.LossHistory.Views.xMod_LossHistoryEdit_PriorCoverageLoss();
                $('#master').data('priorlossid', rowData[0]);
                if (rowData[4] != '') {
                    $losses.$individual.prop('checked', true);
                    $('#jxMedContainer').hide();
                    $('#jxIndContainer').hide();
                    $losses.$claimNumber.val(rowData[4]);
                    $('#jxClaimNumContainer').show();
                } else {
                    $losses.$individual.prop('checked', false);
                    $('#jxClaimNumContainer').hide();
                    $losses.$medical.val(rowData[2]);
                    $losses.$indemnity.val(rowData[3]);
                    $('#jxMedContainer').show();
                    $('#jxIndContainer').show();
                }
                $losses.$paidActual.val(NumericHelpers.formatCurrency(rowData[6]));
                $losses.$paidAdjusted.val(NumericHelpers.formatCurrency(rowData[7]));
                $losses.$incurredActual.val(NumericHelpers.formatCurrency(rowData[8]));
                $losses.$inccuredAdjusted.val(NumericHelpers.formatCurrency(rowData[9]));
                $losses.$recovery.val(NumericHelpers.formatCurrency(rowData[5]));
                $losses.$type.val(rowData[12]);
                if (rowData[13] == 'O') {
                    $losses.$open.prop('checked', 'checked');
                }
                $losses.$lossComments.val(rowData[14]);
            });

            _self.$lossHistoryContainer.on('click', '#jxPayrollInformationContainer tbody tr',(e) => {
                $('#jxAddPriorCoverageDetails').val(' Update ');
                var rowData = _self.$payrollInfoGrid.$grid.row(e.currentTarget).data();
                var $payroll = new PL.UW.LossHistory.Views.xMod_LossHistoryEdit_PriorCoverageDetail();
                $('#master').data('priordetailid', rowData[0]);
                $payroll.$state.val(rowData[2]);
                $payroll.$classCode.val(rowData[4]);
                $payroll.$payroll.val(NumericHelpers.formatCurrency(rowData[5]));
            });

        }

        validateYear(): number {

            var yearValue = $('.jxPrimaryLossHistoryInformation .yearField');

            var isYearValid: number = 0;

            if (!GUI.DateTime.validateYear(yearValue.val())) {
                yearValue.css('border', '1px solid #FF9F9F');
                isYearValid = 4;
            } else {
                yearValue.css('border', '1px solid darkgray');
            }
            yearValue.css('padding', '2px 0 2px 2px');
            return isYearValid;
        }

        validateClassCode(ClassCode: string): JQueryDeferred<number> {
            var result = $.Deferred();
            PL.UW.LossHistory.API.LossHistoryAPI.getClassCode(ClassCode).done(function (data) {
                var isValidClassCode: number = 0;
                if (data == 0) {
                    $('#jxClassCode').css('border', '1px solid #FF9F9F');
                    isValidClassCode = 3;
                } else {
                    $('#jxClassCode').css('border', '1px solid darkgray');
                }
                $('#jxClassCode').css('padding', '2px 0 2px 2px');

                result.resolve(isValidClassCode);
            });
            return result;
        }

        validateRequiredFields(ParentContainerID: string): number {
            var areFieldsValid: number = 0;

            $(ParentContainerID + ' .requiredField').each(function () {
                if ($(this).val() == "") {
                    $(this).css('border', '1px solid #FF9F9F');
                    areFieldsValid = 1;
                } else {
                    $(this).css('border', '1px solid darkgray');
                }
                $(this).css('padding', '2px 0 2px 2px');
            });
            return areFieldsValid;
        }

        validateNumericFields(ParentContainerID: string): number {
            var areFieldsNumeric: number = 0;

            $(ParentContainerID + ' .numeric').each(function () {
                $(this).val(NumericHelpers.deformatCurrency($(this).val()));
                if (!$.isNumeric($(this).val())) {
                    $(this).css('border', '1px solid #FF9F9F');
                    areFieldsNumeric = 2;
                } else {
                    $(this).css('border', '1px solid darkgray');
                }
                $(this).css('padding', '2px 0 2px 2px');
            });
            return areFieldsNumeric;
        }

        validationResponse(responseCode: number, customReponse?: string): string {
            var response: string = '';
            switch (responseCode) {
                case 1:
                    response = 'Please fill out the required fields marked by *.';
                    break;
                case 2:
                    response = 'Please enter numeric values in the fields highlighted in red.';
                    break;
                case 3:
                    response = 'Pleas enter a valid class code.';
                    break;
                case 4:
                    response = 'Pleas enter a valid year.';
                    break;
                default:
                    response = customReponse;
            }
            return response;
        }

        save(successFunction?: (data) => any): void {
            var _self = this;
            if (this.isXmod) {
                PL.UW.LossHistory.API.LossHistoryAPI.savePriorCoverage(new PL.UW.LossHistory.Model.PriorCoverage().getValues(), function (data) {
                    _self.PriorCoverageData.PriorCoverageID = data;
                    _self.PriorCoverageID = data;
                    $('#master').data('priorcoverageid', data);
                    if (successFunction) {
                        successFunction(data);
                    }
                });
            } else {
                // this is for future use
                // different save api
            }
        }

        saveLosses(successFunction?: () => any): void {
            var _self = this;
            var lossesObject: PL.UW.LossHistory.Model.PriorCoverageLoss = new PL.UW.LossHistory.Model.PriorCoverageLoss();
            if (_self.PriorCoverageID == null) {
                _self.save(function () {
                    lossesObject.processPriorCoverageLossValues(function (pclData) {
                        PL.UW.LossHistory.API.LossHistoryAPI.savePriorCoverageLosses(pclData, function () {
                            if (successFunction) {
                                successFunction();
                            }
                        });
                    });
                });
            } else {
                lossesObject.processPriorCoverageLossValues(function (pclData) {
                    PL.UW.LossHistory.API.LossHistoryAPI.savePriorCoverageLosses(pclData, function () {
                        if (successFunction) {
                            successFunction();
                        }
                    });
                });
            }
        }

        saveDetails(successFunction?: () => any): void {
            var _self = this;
            var $payroll = new PL.UW.LossHistory.Views.xMod_LossHistoryEdit_PriorCoverageDetail();

            if (_self.PriorCoverageID == null) {
                _self.save(function () {
                    PL.UW.LossHistory.API.LossHistoryAPI.savePriorCoverageDetails(new PL.UW.LossHistory.Model.PriorCoverageDetail().getValues(), function (data) {
                        if (successFunction) {
                            successFunction();
                        }
                    });
                });
            } else {
                PL.UW.LossHistory.API.LossHistoryAPI.savePriorCoverageDetails(new PL.UW.LossHistory.Model.PriorCoverageDetail().getValues(), function (data) {
                    if (successFunction) {
                        successFunction();
                    }
                });
            }
        }

        bindLossHistoryPresentationLayer(successFunction?: () => any): void {
            var _self = this;
            GUI.Mustache.QuickBind($('#jxLossHistory_Template').html(), _self.PriorCoverageData, _self.$lossHistoryContainer, function () {
                if (successFunction) {
                    successFunction();
                }
            });
        }

        bindLossesGrid(SuccessFunction?: () => any): void {
            this.$lossInfoGrid = new GUI.Tables.DTables;

            this.$lossInfoGrid.containerName = 'jxLossInformationContainer';
            this.$lossInfoGrid.tableName = 'jxLossGrid';
            this.$lossInfoGrid.columnSearch = false;
            this.$lossInfoGrid.dtObject.globalFilter = false;
            this.$lossInfoGrid.dtObject.paging = false;
            this.$lossInfoGrid.stateSave = true;
            this.$lossInfoGrid.data = this.PriorCoverageLossData;
            this.$lossInfoGrid.editColumn(0);

            this.$lossInfoGrid.buildTable();

            if (SuccessFunction) {
                SuccessFunction();
            }
        }

        bindPayrollGird(SuccessFunction?: () => any): void {
            this.$payrollInfoGrid = new GUI.Tables.DTables;

            this.$payrollInfoGrid.containerName = 'jxPayrollInformationContainer';
            this.$payrollInfoGrid.tableName = 'jxPayGrid';
            this.$payrollInfoGrid.columnSearch = false;
            this.$payrollInfoGrid.dtObject.globalFilter = false;
            this.$payrollInfoGrid.dtObject.paging = false;
            this.$payrollInfoGrid.stateSave = true;
            this.$payrollInfoGrid.data = this.PriorCoverageDetailData;
            this.$payrollInfoGrid.editColumn(0);

            this.$payrollInfoGrid.buildTable();

            if (SuccessFunction) {
                SuccessFunction();
            }
        }

        refreshLosses(): void {
            var _self = this;
            PL.UW.LossHistory.API.LossHistoryAPI.getPriorCoverageLosses(_self.PriorCoverageID, function (PCLs) {
                _self.$lossInfoGrid.refreshTable(PCLs, function () {
                    _self.$lossInfoGrid.editColumn(0);
                });
            });
        }

        refreshPayroll(): void {
            var _self = this;
            PL.UW.LossHistory.API.LossHistoryAPI.getPriorCoverageDetails(_self.PriorCoverageID, _self.PriorCoverageData.GroupID, null, function (PCDs) {
                _self.$payrollInfoGrid.refreshTable(PCDs, function () {
                    _self.$payrollInfoGrid.editColumn(0);
                });
            });
        }

        addStates(StatesList: any[]): void {
            if (StatesList == null) {
                StatesList = [];
            }
            if (StatesList.length == 0) {
                $('#jxState').append($('<option></option>').attr('value', null).text('No States'));
            } else {
                for (var i = 0; i < StatesList.length; i++) {
                    $('#jxState').append($('<option></option>').attr('value', StatesList[i]).text(StatesList[i]));
                }
            }
        }
    }

} 
