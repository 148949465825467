namespace PL.AdminTools.Controllers {

    export class Base_ClaimIntakeEdit {

        FormTypeObject: PL.Claims.Models.FormType;
        _information: PL.AdminTools.Controllers.ClaimIntakeEditInformation;
        _categories: PL.AdminTools.Controllers.ClaimIntakeEditCategories;
        _fields: PL.AdminTools.Controllers.ClaimIntakeEditFields;

        Information = (source?: any) => {
            if (this._information == null) {
                this._information = new PL.AdminTools.Controllers.ClaimIntakeEditInformation();
            }
            return this._information;
        }

        Categories = (source?: any) => {
            if (this._categories == null) {
                this._categories = new PL.AdminTools.Controllers.ClaimIntakeEditCategories();
            }
            return this._categories;
        }

        Fields = (source?: any) => {
            if (this._fields == null) {
                this._fields = new PL.AdminTools.Controllers.ClaimIntakeEditFields();
            }
            return this._fields;
        }

        setupEvents(): void {
            var _self = this;

            $('#formTypeEditTabs').tabs({
                heightStyle: 'fill'
            }).on("tabsshow", function (event, ui) {

            });

            $('.date').datepicker({ showOn: "both", buttonImage: "/images/icons/cal.gif", buttonImageOnly: true });
        }

        bindSortable(): void {
            var fixHelper = function (e, ui) {
                ui.children().each(function () {
                    $(this).width($(this).width());
                });
                return ui;
            };

            $('.sortableFormTypeEditTable tbody').sortable({
                containment: "parent",
                items: '.sortableOnly',
                helper: fixHelper
            }).disableSelection();

            restart = true;
            sorttable.init();
        }

        updateRowColor(): void {
            $('.sortableFormTypeEditTable tbody tr:not(.fieldMasterDetail tbody tr)').each(function (i, v) {
                var backgroundcolor = 'arow';
                if (i % 2 == 0) {
                    backgroundcolor = 'prow';
                }
                $(this).removeClass('arow prow').addClass(backgroundcolor);
                return backgroundcolor;
            });
        }

        sortOrder(): void {
            var _self = this;
            $('.categoryTable tbody tr').each(function (i, v) {
                _self.FormTypeObject.FormCategories.forEach((cO, cI) => {
                    if (cO.FormCategoryID == $(this).data('formcategoryid')) {
                        cO.SortOrder = i;
                    }
                });
            });

            $('.fieldTable tbody tr').each(function (i, v) {
                _self.FormTypeObject.FormCategory.FormFields.forEach((fO, fI) => {
                    if (fO.FormFieldID == $(this).data('fieldid')) {
                        fO.FormSortOrder = i;
                        fO.GridSortOrder = i;
                    }
                });
            });
        }

        updateFieldCategories(): void {
            $('#fieldFilterCategory, .fieldCategory').empty();
            this.FormTypeObject.FormCategories.forEach((fcO, fcI) => {
                if (fcO.CategoryName != undefined) {
                    $('#fieldFilterCategory, .fieldCategory').append($("<option></option>").attr("value", fcO.CategoryName).text(fcO.CategoryName));
                }
            });
        }

        getTabMessage(activeTabIndex: number, message: Enums.ClaimIntakeEditor_Information_Messages | Enums.ClaimIntakeEditor_Category_Messages | Enums.ClaimIntakeEditor_Field_Messages): void {
            switch (activeTabIndex) {
                case 0:
                    this.informationMessage(<Enums.ClaimIntakeEditor_Information_Messages>message);
                    break;
                case 1:
                    this.categoryMessage(<Enums.ClaimIntakeEditor_Category_Messages>message);
                    break;
                case 2:
                    this.fieldMessage(<Enums.ClaimIntakeEditor_Field_Messages>message);
                    break;
            }
        }

        clearMessage(): void {
            $('#informationMessage, #categoryMessage, #fieldMessage').text('');
        }

        private informationMessage(message: Enums.ClaimIntakeEditor_Information_Messages): void {
            var $message = $('#informationMessage');
            $message.css('color', 'black').text('');
            switch (message) {
                case Enums.ClaimIntakeEditor_Information_Messages.NEW_UNSAVED:
                    $message.css('color', 'orange').text('Please save before continuing.');
                    break;
                case Enums.ClaimIntakeEditor_Information_Messages.SAVED:
                    $message.css('color', 'green').text('Form Information saved.');
                    break;
                case Enums.ClaimIntakeEditor_Information_Messages.MISSING_REQUIRED_INFORMATION:
                    $message.css('color', 'orange').text('You must provide a Form Type Name and a Form Name.');
                    break;
                case Enums.ClaimIntakeEditor_Information_Messages.REPORT_FORM_ID_NOT_NUMERIC:
                    $message.css('color', 'orange').text('Report Form ID is not required but must be numeric.');
                    break;
            }

        }

        private categoryMessage(message: Enums.ClaimIntakeEditor_Category_Messages): void {
            var $message = $('#categoryMessage');
            $message.css('color', 'black').text('');
            switch (message) {
                case Enums.ClaimIntakeEditor_Category_Messages.NEW_UNSAVED:
                    break;
                case Enums.ClaimIntakeEditor_Category_Messages.CHANGES_UNSAVED:
                    break;
                case Enums.ClaimIntakeEditor_Category_Messages.SAVE_ERROR:
                    $message.css('color', 'red').text('Please provide a category name.');
                    break;

            }

        }

        private fieldMessage(message: Enums.ClaimIntakeEditor_Field_Messages): void {
            var $message = $('#fieldMessage');
            $message.css('color', 'black').text('');
            switch (message) {
                case Enums.ClaimIntakeEditor_Field_Messages.NEW_UNSAVED:
                    $message.text('You have new unsaved fields');
                    break;
                case Enums.ClaimIntakeEditor_Field_Messages.CHANGES_UNSAVED:
                    $message.text('Save your changes before you close.');
                    break;
                case Enums.ClaimIntakeEditor_Field_Messages.SAVE_ERROR:
                    $message.text('An error occured when saving.');
                    break;

            }

        }

        handlebarHelpers(): void {

            Handlebars.registerHelper('backgroundcolor', function (value, options) {
                var backgroundcolor = 'arow';
                if (value % 2 == 0) {
                    backgroundcolor = 'prow';
                }
                return backgroundcolor;
            });

            Handlebars.registerHelper('plusOneIndex', function (options) {
                return (1 + options.data.index);
            });

            Handlebars.registerHelper('breakword', function (property) {
                return property.replace(/ /g, "</br>");
            });

            Handlebars.registerHelper('StickyCategory', function (FieldCategoryName, CategoryName, options) {

                if (FieldCategoryName == CategoryName) {

                    return options.fn(this);
                }
                return options.inverse(this);
            });

            Handlebars.registerHelper('TabIndex', function (index) {
                return index + 1;
            });

            Handlebars.registerHelper('SelectActiveFieldType', function (SavedFieldTypeID, FieldTypeID) {
                var returnActiveFieldType = null;
                if (SavedFieldTypeID == FieldTypeID) {

                    returnActiveFieldType = 'fieldTypeOptionsActive';

                }
                return returnActiveFieldType;
            });

            Handlebars.registerHelper('IfFieldTypeID', function (fieldTypeID) {
                var attributes = '';

                switch (fieldTypeID) {
                    case 6:
                        attributes = 'title="This field is not required when field type (HTML) is selected." disabled="disabled"';
                        break;
                    case 7:
                        attributes = 'title="This field is not required when field type (Attachment) is selected." disabled="disabled"';
                        break;
                }
                return attributes;
            });

            Handlebars.registerHelper('isRequired', function (fieldTypeID, isRequired) {
                var attributes = '', required = '';

                switch (+fieldTypeID) {
                    case 6:
                        attributes = 'title="This field is not required when field type (HTML) is selected." disabled="disabled"';
                        break;
                    case 7:
                        attributes = 'title="This field is not required when field type (Attachment) is selected." disabled="disabled"';
                        break;
                }

                if (!isRequired) {
                    required = '';
                } else {
                    required = ' checked="checked"';
                }

                if (fieldTypeID == 6 || fieldTypeID == 7) {
                    required = '';
                }

                attributes = attributes + required;

                return attributes;
            });

            Handlebars.registerHelper('SelectFieldCategoryID', function (categories, categoryID, options) {
                var HTML = [];

                for (var i = 0; i < categories.length; i++) {

                    HTML.push('<option value="' + categories[i].FormCategoryID + '"');
                    if (categories[i].FormCategoryID == categoryID) {
                        HTML.push(' Selected ');
                    }

                    if (categoryID == 0) {
                        HTML.push(' Selected ');
                    }

                    HTML.push('>' + categories[i].CategoryName + '</option>');

                }
                return HTML.join('');
            });
        }
    }

}