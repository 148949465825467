module CL.UW.Factories {
    var Repositories: any = {};
    export class RepositoryFactory {
        //The factory takes care of whether to create a new repository or not for efficiency
        public static getProductRepository(): APIs.ProductRepository {
            var Result = Repositories["ProductRepository"];
            if (Result == null) {
                Result = new APIs.ProductRepository();
                Repositories["ProductRepository"] = Result;
            }
            return Result;
        }

        public static getDocumentRepository(): APIs.DocumentRepository {
            var Result = Repositories["DocumentRepository"];
            if (Result == null) {
                Result = new APIs.DocumentRepository();
                Repositories["DocumentRepository"] = Result;
            }
            return Result;
        }
    }
}