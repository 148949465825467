module PL.Que.Models {

    export class ResultSetValue {

        ResultSetID: number;
        StructureColumnID: number;
        ValueIndex: number;
        HistoryIndex: number;
        StringValue: string;
        Status: string;
        DataTypeID: number;
        IntegerValue: number;
        DateValue: string|Date;
        DecimalValue: number;
        BitValue: boolean;
        PassedValidation: boolean;
        ValidationLevel: number;
        Tag: string;
        CreateUser: string;
        CreateTmsp: string|Date;
        CurrentResultAsString: string;
        PriorResultAsString: string;
        ChangeType: string|number; // enum
        Explanation: string;
        DataColumn: any;
        DataCategory: any;             

    }

}