module PL.Claims.APIs {

    export class FormTypeRepo {

       private static _routePrefix: string = "/api/ClaimSystem/Form/";

        public static getInstructions(formTypeID: number): JQueryDeferred<PL.Claims.Models.FormType> {
            var result = $.Deferred();
            $.get(this._routePrefix + 'GetInstructions?FormTypeID=' + formTypeID).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

        public static getFormType(formTypeID: number|string): JQueryDeferred<PL.Claims.Models.FormType> {
            var result = $.Deferred();
            $.get(this._routePrefix + 'GetFormType?FormTypeID=' + formTypeID).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

        public static saveFormType(formTypeObject: PL.Claims.Models.FormType): JQueryDeferred<PL.Claims.Models.FormType> {
            var result = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveFormType', formTypeObject).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

        public static getCategoriesByType(formTypeID: number | string): JQueryDeferred<PL.Claims.Models.FormCategory[]> {
            var result = $.Deferred();
            $.get(this._routePrefix + 'GetCategoriesByType?FormTypeID=' + formTypeID).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

        public static saveFormCategory(category: PL.Claims.Models.FormCategory): JQueryDeferred<number> {
            var $result = $.Deferred<number>();
            mW.io.post(this._routePrefix + 'SaveFormCategory', category).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        public static saveFormTypeCategories(formTypeCategories: PL.Claims.Models.FormCategory[]): JQueryDeferred<PL.Claims.Models.FormCategory[]> {
            var result = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveFormCategories', formTypeCategories).done(function (data) {
                result.resolve(data);
            });
            return result;
        }
        
        public static deleteFormCategory(formCategoryID: number): JQueryDeferred<any> {
            var result = $.Deferred<any>();
            mW.io.post(this._routePrefix + 'DeleteFormCategory?FormCategoryID=' + formCategoryID).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

        public static getFormFields(formTypeID: number | string, formFieldID: number = null, formCategoryID: number = null): JQueryDeferred<PL.Claims.Models.FormField[]> {
            var result = $.Deferred();
            $.get(this._routePrefix + 'GetFormFields?FormTypeID=' + formTypeID + '&FormFieldID=' + formFieldID + '&FormCategoryID=' + formCategoryID).done(function (data) {
                result.resolve(data);
            });
            return result;
        }
        
        public static saveFormTypeField(formTypeField: PL.Claims.Models.FormField): JQueryDeferred<number> {
            var result = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveFormField', formTypeField).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

        public static saveFormTypeFields(formTypeFields: PL.Claims.Models.FormField[]): JQueryDeferred<PL.Claims.Models.FormField[]> {
            var result = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveFormFields', formTypeFields).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

        public static deleteFormField(formFieldID: number, formTypeID: number): JQueryDeferred<PL.Claims.Models.FormField[]> {
            var result = $.Deferred();
            mW.io.post(this._routePrefix + 'DeleteFieldByFieldID?FormFieldID=' + formFieldID + '&FormTypeID=' + formTypeID).done(function (data) {
                result.resolve(data);
            });
            return result;
        }
                
    }

}