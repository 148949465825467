module PL.AdminTools.Factories {
    var Repositories: any = {};
    export class RepositoryFactory {
    //The factory takes care of whether to create a new repository or not for efficiency
        public static GetQuoteDocumentBatchRepository(): APIs.QuoteDocumentBatchRepository
        {
            var Result = Repositories["QuoteDocumentBatchRepository"];
            if (Result== null)
            {
                Result = new APIs.QuoteDocumentBatchRepository();
                Repositories["QuoteDocumentBatchRepository"] = Result;
            }
            return Result;
        }

        public static GetClaimServiceRepository(): APIs.ClaimServiceRepository
        {
            var Result = Repositories["ClaimServiceRepository"];
            if (Result == null) {
                Result = new APIs.ClaimServiceRepository();
                Repositories["ClaimServiceRepository"] = Result;
            }
            return Result;
        }

      

    }
}