/// <reference path="../smart/smartobject.ts" />
// CHSI DataTables (Tables)
module GUI.Tables {

    export class TableEvents {
        eventName: string;
        eventAction: () => any;
    }

    export class ColumnDefinitions implements DataTables.ColumnDefsSettings {
        targets: any;
        aTargets: any;
    }

    export class Settings {
        createdRow: (row: any, data: any, index: any) => void = function (r, d, i) { if (r && d && i == null) { return null } };

        colDefCollection: any[];
        colDef: ColumnDefinitions = new ColumnDefinitions;
        newColDef: ColumnDefinitions[] = new Array<ColumnDefinitions>();
        colHeaderCollection: GUI.Smart.SmartColumnObject[] = new Array<GUI.Smart.SmartColumnObject>();
        colHeader: GUI.Smart.SmartColumnObject = new GUI.Smart.SmartColumnObject;
        stateSave: boolean = true;
        globalFilter: boolean = true;
        paging: boolean = true;
        displayLength: number = 25;
        bFilter: boolean = true;
        showEntryDropDown: boolean = true;
        defaultSortColumn: any[];
        constructor() { }
    }



    export class DTables {

        URL: string;
        $grid: any;
        tableName: string;
        containerName: string;
        topSearch: boolean;
        columnSearch: boolean;
        data: GUI.Smart.SmartObject = new GUI.Smart.SmartObject;
        width: string = '100%';
        stateSave: boolean;
        masterDetailTemplate: (data: any) => any;
        tableEvents: TableEvents[] = new Array<TableEvents>();

        private _$container: JQuery = $('#' + this.containerName);
        get $container(): JQuery {
            return $('#' + this.containerName);
        }
        set $container(containerName: JQuery) {
            this._$container = $('#' + this.containerName);
        }
        private _$table: JQuery = $('#' + this.tableName);
        get $table(): JQuery { return $('#' + this.tableName); }
        set $table(tableName: JQuery) {
            this._$table = $('#' + this.tableName);
        }
        private _dtObject: GUI.Tables.Settings = new GUI.Tables.Settings;
        get dtObject(): GUI.Tables.Settings {
            return this._dtObject;
        }
        set dtObject(dto: GUI.Tables.Settings) {
            this._dtObject = dto;
        }



        constructor() { }

        private addTableEvent(eventName: string, eventAction: any): void {
            this.tableEvents.push({ eventName, eventAction });
        }

        private executeTableEvents(eventName?: string): void {
            var _self = this;
            for (var i = 0; i < _self.tableEvents.length; i++) {
                _self.tableEvents[i].eventAction();
            }
        }

        editColumn(targetRow: number): void {
            var _self = this;

            //_self.addTableEvent('editColumn_' + targetRow, function () {
            var Column = new GUI.Smart.SmartColumnObject();
            //title: '',
            Column.data = null;
            Column.orderable = false;
            Column.class = 'details-control';
            Column.defaultContent = '[<a href="#" class="minilink">view</a>]';
            Column.render = function (data, type, row) {
                return "[<a class='jxEdit minilink' data-exceptionid='" + row[targetRow] + "' href='#'>Edit</a>]";
            }
            _self.data.Columns.push(Column);
            //});
            //_self.executeTableEvents('editColumn_' + targetRow);
        }

        addCustomColumn(columnName: string, columnNameAsHTML: string, columnDataIndex?: number): any {
            var _self = this;

            //_self.addTableEvent(columnName, function () {
            var Column = new GUI.Smart.SmartColumnObject();
            //title: '',
            Column.data = null;
            Column.orderable = false;
            Column.class = 'details-control';
            Column.defaultContent = '[<a href="#" class="minilink">view</a>]';
            Column.render = function (data, type, row) {
                return columnNameAsHTML;
            }
            _self.data.Columns.push(Column);
            //});

            //_self.executeTableEvents(columnName);
        }

        clickColumn(columnName?: string, clickCallback?: (rowData) => any): void {
            var _self = this;

            //_self.addTableEvent(columnName, function () {
            $('#' + _self.containerName + ' tbody').on('click', 'tr', (e) => {
                if (clickCallback) {
                    var rowData = _self.$grid.row(e.currentTarget).data();
                    clickCallback(rowData);
                }
            });
            //});

            //_self.executeTableEvents(columnName);
        }

        openMasterDetail(clickEventName: string, HTML: string) {
            var _self = this;

            //_self.addTableEvent(clickEventName, function () {
            _self.$container.on('click', (e) => {
                var tr = $(e.target).closest('tr');
                var row = _self.$grid.row(tr);

                if (row.child.isShown()) {
                    row.child.hide();
                    tr.removeClass('ui-icon-triangle-1-n');
                } else {
                    row.child(_self.masterDetailTemplate(row)).show();
                    tr.addClass('ui-icon-triangle-1-s');
                }
            });
            //});

            //_self.executeTableEvents(clickEventName);
        }

        hasURL(URL: string): void {
            var _self = this;
            if (URL != null) {
                $.get(URL).done(function (data) {
                    _self.data.Values = data.Values;
                    _self.data.Columns = data.Columns;
                }).then(function () {
                    _self.buildTable();
                });
            } else {
                _self.buildTable();
            }
        }

        reponses(responseCode: number): string {
            var _self = this;
            var response: string;
            switch (responseCode) {
                case 1:
                    response = 'No results found.';
                    break;
                case 2:
                    break;
                case 3:
                    break;
                case 4:
                    break;
                case 5:
                    break;
                default:

            }
            return response;
        }

        refreshTable(newData?: any, preExecutionCallback?: () => any, successFunction?: () => any): void {
            this.$table.remove();
            if (newData) {
                this.data = newData;
            }
            if (preExecutionCallback) {
                preExecutionCallback();
            }
            this.buildTable(function () {
                if (successFunction) {
                    successFunction();
                }
            });
        }

        newTable(): void {
            this.$table.remove();
            this.data = new GUI.Smart.SmartObject;
            this.buildTable();
        }

        buildTable(successFunction?: () => any): any {
            var _self = this;

            this.$container.html('<table width="' + this.width + '" class="dtable" id="' + this.tableName + '"><tfoot></tfoot></table>');

            if (!this.dtObject.colDef) {
                this.dtObject.colDef = null;
            }

            if (this.URL != null) {
                $.get(_self.URL).done(function (data) {
                    _self.data = data;
                }).then(function () {
                    beginBuildingTable();
                });
            } else {
                beginBuildingTable();
            }

            function beginBuildingTable() {
                if (_self.data != null || _self.data != undefined) {
                    if (_self.data.Values != null || _self.data.Values != undefined) {
                        if (_self.data.Values.length > 0) {
                            _self.topSearch = _self.columnSearch ? false : true;

                            _self.$grid = _self.$table.DataTable({
                                data: _self.data.Values,
                                columns: _self.data.Columns,
                                columnDefs: _self.dtObject.colDefCollection,
                                createdRow: function (row, data, index) {
                                    _self.dtObject.createdRow(row, data, index);
                                },
                                stateSave: _self.stateSave,
                                searching: _self.columnSearch,
                                paging: _self.dtObject.paging,
                                pageLength: _self.dtObject.displayLength,
                                order: _self.dtObject.defaultSortColumn,
                                lengthChange: _self.dtObject.showEntryDropDown,
                                dom: '<"H"l' + (_self.topSearch ? 'f' : '<"dataTables_filter">') + 'r>t<"F"ip>'

                                // If you include a new property to data tables please update the "Settings" object within this page.
                            });
                            //Richard: commented this out to at least not error
                            //  _self.$grid = _self.$grid._('#' + _self.tableName);
                            if (_self.columnSearch && !_self.topSearch) {
                                // $('.dataTables_filter').html('<a id="' + _self.tableName + '_SearchLink">Advanced Search</a>');

                                //build the footer search fields
                                var HTML;
                                var Columns = _self.data.Columns;

                                for (var n = 0; n < Columns.length; n++) {
                                    if (_self.data.Columns[n].visible) {
                                        HTML += '<th><input type="text" data-columnindex="' + n + '" placeholder="Search ' + _self.data.Columns[n].title + '"/></th>';
                                    } else if (_self.data.Columns[n].title == '') {
                                        HTML += '<th>&nbsp;</th>';
                                    }
                                }

                                _self.$table.children('tfoot').html(HTML);
                                $('#' + _self.tableName + '_wrapper').append('<a id="AdvancedSearch"></a>');

                                _self.$grid.columns().eq(0).each(function (colIdx) {
                                    console.log('binding columns');
                                    $('input', _self.$grid.column(colIdx).footer()).on('keyup change', function () {

                                        var dataindex = $(this).data().columnindex;
                                        console.log('searching - ' + dataindex + ' for ' + this.value);
                                        _self.$grid.column(dataindex).search(this.value).draw();
                                    });
                                });

                                $('#' + _self.tableName + '_SearchLink').on('click', function () {
                                    $('#pageWrapper').scrollTop($('#AdvancedSearch').offset().top);
                                });
                            }
                        } else {
                            _self.$table.html('<tr><td>No results found.</td></tr>');
                        }
                    } else {
                        _self.$table.html('<tr><td>An error occurred. Values is null.</td></tr>');
                    }
                } else {
                    _self.$table.html('<tr><td>An error occurred. No Data was received.</td></tr>');
                }

                if (successFunction) {
                    successFunction();
                }
            }
        }



    }
}