
CHSI.Forms.Elements = {
    CachedResults: new Object()
    , GetElementByID: function (ElementID, formID) {
        var FoundElement;
        var Elements = CHSI.Forms.Forms['f' + formID].Elements;
        for (var ElementIndex = 0, ElementsLength = Elements.length; ElementIndex < ElementsLength; ++ElementIndex) {
            var Element = Elements[ElementIndex];
            if (Element.ElementID == ElementID) {
                FoundElement = Element;
                break;
            }
        }
        return FoundElement;
    }
    , ChangeElementEditability: function (elementID, valueIndex,formID,  isDisabled)
    {
        var Element = CHSI.Forms.Elements.GetElementByID(elementID,  formID);
        if (Element) {
            CHSI.Common.Log('element Info: ' + Element.ElementType)
            switch (Element.ElementType) {
                case 8: /*select list*/
                case 3:/*textbox*/
                    var TextBox = $('#jxElement_' + elementID + '_' + valueIndex);
                    if (TextBox.length == 1) {
                        if (isDisabled)
                        {
                            TextBox.attr("disabled", "disabled");
                        }
                        else
                        {
                            TextBox.removeAttr("disabled");
                        }
                        
                    }
                    break;
                case 10: /*vertical radio*/
                case 4: /*radio*/
                case 11: /*vertical checkbox*/
                case 5: /*checkbox list*/
                    var Items = $('input[name=jxElement_' + elementID + '_' + valueIndex + ']');
                    if (isDisabled) {
                        Items.attr("disabled", "disabled");
                    }
                    else {
                        Items.removeAttr("disabled");
                    }
                    break;

                default:

            }
        }
    }
    , UpdateElementValue: function (elementID, valueIndex, formID, newValue) {

        var Element = CHSI.Forms.Elements.GetElementByID(elementID, formID);
        if (Element) {
            CHSI.Common.Log('element Info: ' + Element.ElementType)
            switch (Element.ElementType) {
                case 8: /*select list*/
                case 3:/*textbox*/
                    this.UpdateElementTextboxValue(Element, valueIndex, formID, newValue);
                    break;
                case 10: /*vertical radio*/
                case 4: /*radio*/
                case 11: /*vertical checkbox*/
                case 5: /*checkbox list*/
                    this.UpdateElementGroupValue(elementID, valueIndex, formID, newValue);
                    break;
                    
                default:

            }
        }
    }
      , UpdateElementGroupValue: function (elementID, valueIndex, formID, newValue) {
          if (newValue == null)
          {
              newValue = '';
          }
          var StringValues = newValue.split('||');
          var Items = $('input[name=jxElement_' + elementID + '_' + valueIndex + ']');
          Items.removeAttr('checked');
          for (StringIndex = 0; StringIndex < StringValues.length; StringIndex++)
          {
              $(Items).each(function (Index, Item) {
                  var CurrentValue = $(Item);
                  if (CurrentValue.val() == StringValues[StringIndex])
                  {
          
                      CurrentValue.attr('checked', 'checked');
                      return false;
                  }
              });
          }

      }
    , UpdateElementTextboxValue: function (Element, valueIndex, formID, newValue) {
        var TextBox = $('#jxElement_' + Element.ElementID + '_' + valueIndex);
        
        if (TextBox.length == 1) {
            

            if (TextBox.data('rawvalue') != newValue) {

                TextBox.data('rawvalue', newValue);
                TextBox.focus();
                TextBox.val(CHSI.Forms.Elements.formatValue(Element, newValue));
                TextBox.blur();
                var Format = TextBox.data('format');

                if (Format) {
                    var FormattedValue = String.__Format(Format, +TextBox.val());
                    TextBox.data('rawvalue', +TextBox.val());
                    TextBox.val(FormattedValue);
                    
                }
            }
       
        }
    }
    , GetNoChangeCheckboxHTML: function (formElement, valueIndex, formID, isDisabled) {
        var CheckboxHTML = '';
        if ((CHSI.Forms.Settings.ShowNoChangeCheckbox || CHSI.Forms.Elements.isNoChangeCheckboxVisible(formElement, valueIndex)) && (formElement.DataStructure != null)) {
             
                var NoChangeResultValue = CHSI.Forms.Results.GetResultSetValue(formElement.DataStructure.ColumnID, valueIndex);
                var isCheckedValue = '';
                if (NoChangeResultValue) {
                    if (NoChangeResultValue.ChangeType == 1) {
                        isCheckedValue = 'checked';
                    }
                }
                var JSONObject = { ColumnID: formElement.DataStructure.ColumnID, ValueIndex: valueIndex, FormID: formID, ElementID: formElement.ElementID }
                var DisabledHTML = '';
                if (isDisabled == true)
                {
                    DisabledHTML = ' disabled="disabled" ';
                }
                CheckboxHTML = '<label class="ChangeType"><input type="checkbox" id="jxNoChange' + formElement.DataStructure.ColumnID + '_' + valueIndex + '" class="NoChangeCheckbox" value="true" ' + DisabledHTML + '  data-Parent=\'' + JSON.stringify(JSONObject) + '\'' + isCheckedValue + '>No&nbsp;Change</label>';
        
        }
        return CheckboxHTML;
    }
     , GetHelpForElement: function (elementID, FormID) {
         var HelpCollection = CHSI.Forms.Forms['f' + FormID].HelpIDs;         
         var HelpID;
         for (var HelpIndex = 0, HelpCount = HelpCollection.length; HelpIndex < HelpCount; HelpIndex++) {             
             if (HelpCollection[HelpIndex].ElementID == elementID) {
                 HelpID = HelpCollection[HelpIndex].HelpID;
                 break;
             }
         }
         return HelpID;
     }
    , GetHelpIconHTML: function (elementID, formID)
    {
        var HTML;
        var Help = this.GetHelpForElement(elementID, formID);
        if (Help == null) {
            HTML = '';
        }
        else {
            HTML = '<img class="HelpIcon" src="/images/icons/QueHelp.png" alt="Help Information" data-help=\'' + Help + '\' data-elementid=\'' + elementID + '\'>';
        }

        return HTML;
    }
    , ShowElementMessage: function (ElementID, Message, Color, ValueIndex)
    {

        var elem = jQuery('#jxElementContainer_' + ElementID +'_' + ValueIndex);
           corners = ['left center', 'right center'],
           flipIt = elem.parents('span.right').length > 0;
           var MessageID = 'jxElementMessage_' + ElementID + '_' + ValueIndex;
           var Error = Message;
            var TagClass = 'ui-tooltip-red';

            if (Color == 'orange') {
                TagClass = 'ui-tooltip-orange';
            }
            if (elem.qtip) {
                elem.qtip('destroy');
            };
              $('#' + MessageID).remove();
            
       
        
            $('<div class="elementvalidation ' + TagClass + '" id="' + MessageID + '">' + Message + '</div>').insertAfter(elem);
            
            //elem.qtip({
            //    overwrite: false,
            //    content: Message,
            //    position: {
            //        my: corners[flipIt ? 0 : 1],
            //        at: corners[flipIt ? 1 : 0],
            //        viewport: jQuery(window),
            //        container: jQuery('#FormRegion2')
            //    },

            //    show: {
            //        event: false,
            //        ready: true
            //    },
            //    hide: false,
            //    style: {
            //        classes: TagClass + ' elementvalidation'
            //    }

            //});

            //elem.qtip('option', 'content.text', Error);

     
    }
    , isNoChangeCheckboxVisible: function (FormElement, ValueIndex) {
        var ShowNoChange = false;
        if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].PreviousResultSetID != null)
        {
            for (MetaAttributeIndex = 0; MetaAttributeIndex < FormElement.MetaAttributes.length; MetaAttributeIndex++) {
                var Attribute = FormElement.MetaAttributes[MetaAttributeIndex];
                if (Attribute.MetaType == 28 && Attribute.MetaValue == 1) {
                    ShowNoChange = true;
                    break;
                }
            }

        }

        return ShowNoChange;
    }
    , GetElementByColumn: function (formID, columnID)
    {
        var FoundElement;
        var Elements = CHSI.Forms.Forms['f' + formID].Elements;
        for (var ElementIndex = 0, ElementsLength = Elements.length; ElementIndex < ElementsLength; ++ElementIndex) {
            var Element = Elements[ElementIndex];
            if (Element.DataStructureColumnID == columnID) {
                FoundElement = Element;
                break;
            }
        }
        return FoundElement;
    }
   
};


CHSI.Forms.Elements.ReturnRenderedElement = function (FormElement, ValueIndex, FormID, ShowRequiredLabel) {
    if (ShowRequiredLabel == null) {
        ShowRequiredLabel = true;
    }
    var HTML = [];
    if (ValueIndex == null) {
        ValueIndex = 0;
    }
    var ContainerID = 'jxContainer_' + ((FormElement.ElementID == 0) ? FormElement.ParentID : FormElement.ElementID);
    var CurrentValue = CHSI.Forms.Result;
    if (CHSI.Forms.DebugMode) {
        CHSI.Common.Log('Getting element for ' + FormID);
    }
    if (FormElement.BlockType == 1) {
        HTML.push('</tr><tr id="');
        HTML.push(ContainerID);
        HTML.push('" class="');
        if (FormElement.Visibility == 2 || FormElement.Visibility == 3) {
            HTML.push('hiddenBlock ');
        }

        if (FormElement.Visibility == 1 || FormElement.Visibility == 4) {
            HTML.push('visibleBlock ');
        }

        HTML.push('"><td >');
    }
    if (FormElement.BlockType == 2) {
        HTML.push('</tr><tr id="');
        HTML.push(ContainerID);
        HTML.push('" class="');
        if (FormElement.Visibility == 2 || FormElement.Visibility == 3) {
            HTML.push('hiddenBlock ');
        }

        HTML.push('"><td colspan="2"  >');

    }

    if (FormElement.BlockType == 4) {
        HTML.push('</td><td>');
    }
    if (FormElement.BlockType == 5) {
        HTML.push('<br class="NewLineBreak"/>');
    }
    HTML.push('<div  class="');
    if (FormElement.ElementType != 9) {
        if (FormElement.BlockType == 1) {
            HTML.push('LabelColumn ');
        }

        if (FormElement.BlockType == 2) {
            HTML.push('FullBlock ');
        }
        if (FormElement.BlockType == 3 || FormElement.BlockType == 4) {
            HTML.push('InlineBlock ');
        }

    }

    HTML.push(CHSI.Forms.Elements.GetCSSSafeString(FormElement.Tag) + ' ');

    HTML.push('"');

    if (FormElement.ElementID != 0)
    {
        HTML.push(' id="jxElementContainer_' + FormElement.ElementID + '_' + ValueIndex + '" ');
    }

    HTML.push('>');
    HTML.push(CHSI.Forms.Elements.GetElementHTML(FormElement, 0, FormID, true,true));
    

    if ((FormElement.BlockType == 1 || FormElement.BlockType == 2)) {

        var ParentFormElement = CHSI.Forms.Elements.GetElementByID(FormElement.ParentID, FormID);
        if (ParentFormElement != null) {
            if (ParentFormElement.ElementType == 3 || ParentFormElement.ElementType == 4 || ParentFormElement.ElementType == 5 || ParentFormElement.ElementType == 10 || ParentFormElement.ElementType == 11 || ParentFormElement.ElementType == 6 || ParentFormElement.ElementType == 8 || ParentFormElement.ElementType == 12)
            {
                var PriorValue = CHSI.Forms.Results.GetPriorResult(ParentFormElement.DataStructureColumnID, ValueIndex);
                if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].PreviousResultSetID != null) {
                    PriorValue = CHSI.Forms.Elements.formatValue(ParentFormElement, PriorValue);
                    HTML.push('<div class="PriorValue"><b>Prior Value:</b> ' + PriorValue + '</div>');
                }
            }
            
        }


    }
    HTML.push('</div>');



    return HTML.join('');
}

CHSI.Forms.Elements.MakeJoinedArrayReadable = function (elementvalue) {
    if (elementvalue.length >= 4) {
        if (elementvalue.indexOf('||') >= 0) {
            elementvalue = elementvalue.slice(2, elementvalue.length - 3).replace(/\|\|/g, ",");
        }

    }
    else { }
    return elementvalue;
}
/* Replace the value with the current resultset*/
CHSI.Forms.Elements.ReplaceElementValue = function (element, ValueIndex, FormID) {
    if (ValueIndex == null) {
        ValueIndex = 0;
    }
    if (element.DataStructure != null) {
        var RelatedValueItem = CHSI.Forms.Results.GetResultSetValue(element.DataStructure.ColumnID, ValueIndex);
        if (RelatedValueItem) {
            element.DataStructure.DefaultStringValue = RelatedValueItem.StringValue;
            element.DataStructure.DefaultDateValue = RelatedValueItem.DateValue;
            element.DataStructure.DefaultDecimalValue = RelatedValueItem.DecimalValue;
            element.DataStructure.DefaultBitValue = RelatedValueItem.BitValue;
            element.DataStructure.DefaultIntegerValue = RelatedValueItem.IntegerValue;
        }

    }

}

/* translate the meta contenttypes into css classes*/
CHSI.Forms.Elements.GetElementCssClasses = function (element, FormID) {
    var result = [];
    var Column = null;
    if (element.DataStructureColumnID != 0) {
        jQuery(CHSI.Forms.DataStructure.Columns).each(function (index, DataColumn) {
            if (DataColumn.ColumnID == element.DataStructureColumnID) {
                Column = DataColumn;
                return false;
            }
        });
    }
    result.push(CHSI.Forms.Elements.GetCSSSafeString(element.Tag) + ' ');
 
    if (element.MetaAttributes) {
      
        for (var i = 0; i < element.MetaAttributes.length; i++) {
            if (element.MetaAttributes[i].MetaType == 5) {
                result.push(CHSI.Forms.ContentTypes[element.MetaAttributes[i].MetaValue].CssClass);
            }
        }
    }



    if (Column) {
        jQuery(Column.MetaAttributes).each(function (index, metaattribute) {
            if (metaattribute.MetaType == 5) {
                result.push(CHSI.Forms.ContentTypes[metaattribute.MetaValue].CssClass);
            }
        });
    }
    return result.join(' ');
}

CHSI.Forms.Elements.OnElementFocus = function ($this) {

    var elementcontrol = $($this);

    elementcontrol.val(elementcontrol.data('rawvalue'));

};

/* injecting various &nbsp; to direct the browser to keep objects together when possible*/
CHSI.Forms.Elements.GetElementHTML = function (object, ValueIndex, FormID, ParentElementID, ShowRequiredLabel, ShowHelp) {

    if (ShowRequiredLabel == null) {
        ShowRequiredLabel = true;
    }
    if (ShowHelp == null) {
        ShowHelp = true;
    }
    var HTML = [];
    var ContentValue = '';
    var Disabled = false;
    Disabled = object.isDisabled;
    var isNADisabled = false;
    if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].isDisabled == true)
    {
        Disabled = CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].isDisabled;
        isNADisabled = Disabled;
    }
    

    if (ValueIndex == null) {
        ValueIndex = 0;
    }
    var TargetDataType = null;
    if (object.DataStructure == null) {
        Disabled = true;
        isNADisabled = true;
    }
    else {
        TargetDataType = object.DataStructure.DataTypeID;
    }
    
    if (object.DataStructure != null) {
        var ResultValue = CHSI.Forms.Results.GetResultSetValue(object.DataStructure.ColumnID, ValueIndex);

        if (ResultValue) {
            if (ResultValue.ChangeType == 1)
            {
                Disabled = true;
            }
        }
    }
    var NAChecked = '';
    
    var NAResult = 'false';


    if (object.AllowNA == true) {
        if (object.DataStructure != null) {
            NAResult = CHSI.Forms.Results.GetMetaAttributeValue(object.DataStructure.ColumnID, ValueIndex, 19);
            if (NAResult == null) {
                NAResult = false;
            }
            if (NAResult.toString().toLowerCase() == 'true') {
                NAChecked = 'checked';
                Disabled = true;
            }
            if (CHSI.Forms.DebugMode) {
                CHSI.Common.Log('Checking Debug for ElementID: ' + object.ElementID + ' ColumnID: ' + object.DataStructure.ColumnID + ', ValueIndex: ' + ValueIndex + ', metaattribute 19: value: ' + NAResult + ' Label: ' + object.Tag);
            }

        }

    }

    if (CHSI.Forms.DebugMode) {
        CHSI.Common.Log('Build HTML for ' + ValueIndex + ' in formid: ' + FormID + ', disabled: ' + object.isDisabled + ', element columnid: ' + object.DataStructureColumnID + ', Value Index: ' + ValueIndex + ', AllowNA: ' + object.AllowNA + ', Disabled: ' + Disabled);
    }
    if (object.ElementType == 1 || object.ElementType == 2) {
        if (CHSI.Forms.Forms['f' + FormID].DesignTemplateID == 2) {

            if (object.DataStructure != null) {

                ContentValue = CHSI.Forms.Results.GetValue(object.DataStructure.ColumnID, ValueIndex, FormID);
            }

            HTML.push(ContentValue + '&nbsp;');
        }
        else {
            HTML.push(object.Label + '&nbsp;');
        }

    }

    if (object.ElementType == 3) {
        if (object.DataStructure != null) {
            if (CHSI.Forms.DebugMode) {
                CHSI.Common.Log('Getting Text Field Value for Form: ' + FormID + ', object ElementID: ' + object.ElementID + ', ColumnID: ' + object.DataStructure.ColumnID + ', ValueIndex: ' + ValueIndex + ', FormID: ' + FormID);
            }
            ContentValue = CHSI.Forms.Results.GetValue(object.DataStructure.ColumnID, ValueIndex, FormID);
        }
        ContentValue = CHSI.Forms.Elements.formatValue(object, ContentValue);

        var OpeningTag = '<input type="text" ';
        var ClosingTag = '</input>';
        var ValueCode = ' value="' + CHSI.Common.ConditionalEscapeHTML(ContentValue, object.AllowHTML) + '" > ';
        var Formatting = '';

        jQuery(object.MetaAttributes).each(function (index, metaattribute) {
            
            // metatype 2 = formatting
            if (metaattribute.MetaType == 2) {
                
                if (ContentValue == '') {
                    ContentValue = 0;
                }

                Formatting = 'data-format="' + metaattribute.MetaValue + '" data-rawvalue="' + ContentValue + '" onfocus="CHSI.Forms.Elements.OnElementFocus(this);" ';
                
                var FormattedContentValue = String.__Format(metaattribute.MetaValue, +ContentValue);
                
                ValueCode = ' value="' + CHSI.Common.ConditionalEscapeHTML(FormattedContentValue, object.AllowHTML) + '" > ';
            }

            if (metaattribute.MetaType == 5) {
                if (metaattribute.MetaValue == '12') {
                    OpeningTag = '<textarea rows="5" columns="30" ';
                    ValueCode = '>' + CHSI.Common.ConditionalEscapeHTML(jQuery.trim(ContentValue), object.AllowHTML);
                    ClosingTag = '</textarea>';
                    return false;
                }
            }
        });        

        HTML.push(OpeningTag + ' id="' + CHSI.Forms.Elements.GetElementID(object.ElementID, ValueIndex) + '"' + Formatting + ' name="jxElement_' + object.ElementID + '_' + ValueIndex + '" ' + CHSI.Forms.Elements.GetSaveHTML(object, ValueIndex, FormID, ParentElementID, TargetDataType));
        if (Disabled == true) {
            HTML.push(' disabled="disabled" ');
        }
        HTML.push(' title="' + CHSI.Forms.Elements.GetElementTitle(object) + '" ');




        HTML.push(' class="' + CHSI.Forms.Elements.GetElementCssClasses(object, FormID) + '" ' + ValueCode + ClosingTag);
    }

    /*Radio button list*/
    if (object.ElementType == 4) {
        ContentValue = CHSI.Forms.Results.GetValue(object.DataStructure.ColumnID, ValueIndex, FormID);
        var StringValues = ContentValue.split('||');
        jQuery(object.Data).each(function (index, Data) {
            HTML.push('<label class="FieldLabel"><input type="radio" name="jxElement_' + object.ElementID + '_' + ValueIndex + '" value="' + CHSI.Common.ConditionalEscapeHTML(Data.Value, object.AllowHTML) + '" ' + ((jQuery.inArray(Data.Value, StringValues) > -1 > 0) ? 'checked' : ''));
            HTML.push(' title="' + CHSI.Forms.Elements.GetElementTitle(object) + '" ');
            HTML.push(CHSI.Forms.Elements.GetSaveHTML(object, ValueIndex, FormID, ParentElementID, TargetDataType) + ' ');
            if (Disabled == true) {
                HTML.push(' disabled="disabled" ');
            }
            HTML.push('  class="' + CHSI.Forms.Elements.GetElementCssClasses(object, FormID) + '"/>&nbsp;' + CHSI.Common.ConditionalEscapeHTML(Data.Text, object.AllowHTML) + '</label>');
        });

    }


    /*Vertical radio button list*/
    if (object.ElementType == 10) {
        ContentValue = CHSI.Forms.Results.GetValue(object.DataStructure.ColumnID, ValueIndex, FormID);
        var StringValues = ContentValue.split('||');
        jQuery(object.Data).each(function (index, Data) {
            HTML.push('<label class="FieldLabel"><input type="radio" name="jxElement_' + object.ElementID + '_' + ValueIndex + '" value="' + CHSI.Common.ConditionalEscapeHTML(Data.Value, object.AllowHTML) + '" ' + ((jQuery.inArray(Data.Value, StringValues) > -1 > 0) ? 'checked' : ''));
            HTML.push(' title="' + CHSI.Forms.Elements.GetElementTitle(object) + '" ');
            HTML.push(CHSI.Forms.Elements.GetSaveHTML(object, ValueIndex, FormID, ParentElementID, TargetDataType) + ' ');
            if (Disabled == true) {
                HTML.push(' disabled="disabled" ');
            }
            HTML.push('  class="' + CHSI.Forms.Elements.GetElementCssClasses(object, FormID) + '"/>&nbsp;' + CHSI.Common.ConditionalEscapeHTML(Data.Text, object.AllowHTML) + '</label><br />');
        });

    }


    if (object.ElementType == 5) {
        ContentValue = CHSI.Forms.Results.GetValue(object.DataStructure.ColumnID, ValueIndex, FormID);
        var StringValues = ContentValue.split('||'),
            NewTableRow = true,
            TableData = 0;

        HTML.push('<table style="border-bottom: solid 1px gray;">');

        jQuery(object.Data).each(function (index, Data) {
            TableData++;

            if (NewTableRow) {
                HTML.push('<tr>');
            }
            
            NewTableRow = false;

            if (TableData <= 2) {

                HTML.push('<td>');

                HTML.push('<label class="FieldLabel"><input type="checkbox" name="jxElement_' + object.ElementID + '_' + ValueIndex + '" value="' + CHSI.Common.ConditionalEscapeHTML(Data.Value, object.AllowHTML) + '" ' + ((jQuery.inArray(Data.Value, StringValues) > -1) ? 'checked' : '') + '  title="' + CHSI.Forms.Elements.GetElementTitle(object) + '" class="' + CHSI.Forms.Elements.GetElementCssClasses(object, FormID) + '" ' + CHSI.Forms.Elements.GetSaveHTML(object, ValueIndex, FormID, ParentElementID, TargetDataType));
                if (Disabled == true) {
                    HTML.push(' disabled="disabled" ');
                }
                HTML.push(' />&nbsp;');
                HTML.push(CHSI.Common.EscapeHTML(Data.Text));
                HTML.push('</label>');

                HTML.push('</td>');
            
            }

            if(TableData == 2) {
                HTML.push('</tr>');
                TableData = 0;
                NewTableRow = true;
            }
                        
        });

        HTML.push('</table>');
    }
    /*vertical*/
    if (object.ElementType == 11) {
        ContentValue = CHSI.Forms.Results.GetValue(object.DataStructure.ColumnID, ValueIndex, FormID);
        var StringValues = ContentValue.split('||');
        jQuery(object.Data).each(function (index, Data) {
            HTML.push('<label class="FieldLabel"><input type="checkbox" name="jxElement_' + object.ElementID + '_' + ValueIndex + '" value="' + CHSI.Common.ConditionalEscapeHTML(Data.Value) + '" ' + ((jQuery.inArray(Data.Value, StringValues) > -1) ? 'checked' : '') + '  title="' + CHSI.Forms.Elements.GetElementTitle(object) + '" class="' + CHSI.Forms.Elements.GetElementCssClasses(object, FormID) + '" ' + CHSI.Forms.Elements.GetSaveHTML(object, ValueIndex, FormID, ParentElementID, TargetDataType));
            if (Disabled == true) {
                HTML.push(' disabled="disabled" ');
            }
            HTML.push(' />&nbsp;');
            HTML.push(CHSI.Common.ConditionalEscapeHTML(Data.Text, object.AllowHTML));
            HTML.push('</label><br />');

        });
    }
    if (object.ElementType == 6) {
        if (CHSI.Forms.DebugMode) {
            CHSI.Common.Log('file upload status - ' + Disabled);
        }
        if (Disabled == false) {
            if (CHSI.Forms.DebugMode) {
                CHSI.Common.Log('Creating File Source');
            }
            HTML.push('<div name="jxElement_' + object.ElementID + '_' + ValueIndex + '"  id="' + CHSI.Forms.Elements.GetElementID(object.ElementID, ValueIndex) + '"   ></div>');
        }

        HTML.push('<div id="' + CHSI.Forms.Elements.GetElementID(object.ElementID, 'X') + '">');
        HTML.push(CHSI.Forms.Elements.GetFileUploadListing(object.DataStructure.ColumnID, FormID, object.ElementID));

        HTML.push('</div>');


    }
    
    var StyleBold = '';
    if (!object.AllowHTML) {
        StyleBold = 'style="font-weight:bold;"';
    }

    if (object.ElementType == 7) {
        HTML.push('<label class="FieldLabel" for="jxElement_' + object.ParentID + '_' + ValueIndex + '" ' + StyleBold + '  title="' + CHSI.Forms.Elements.GetElementTitle(object) + '"  class="' + CHSI.Forms.Elements.GetElementCssClasses(object, FormID) + '" ' + CHSI.Forms.Elements.GetSaveHTML(object, ValueIndex, FormID, ParentElementID, TargetDataType) + '>' + CHSI.Common.ConditionalEscapeHTML(object.Label, object.AllowHTML));

        CHSI.Common.Log('The IsRequired flag is: ' + object.IsRequired);
        if (object.IsRequired == true && ShowRequiredLabel == true) {
            HTML.push('<span class="RequiredSectionFlag"> *</span>');
        }

        HTML.push('</label>');

    }

    if (object.ElementType == 8) {
        ContentValue = CHSI.Forms.Results.GetValue(object.DataStructure.ColumnID, ValueIndex, FormID);
        HTML.push('<select id="' + CHSI.Forms.Elements.GetElementID(object.ElementID, ValueIndex) + '" name="jxElement_' + object.ElementID + '_' + ValueIndex + '"  title="' + CHSI.Forms.Elements.GetElementTitle(object) + '"  class="' + CHSI.Forms.Elements.GetElementCssClasses(object, FormID) + '" ' + CHSI.Forms.Elements.GetSaveHTML(object, ValueIndex, FormID, ParentElementID, TargetDataType) + ' ');
        if (Disabled == true) {
            HTML.push(' disabled="disabled" ');
        }
        HTML.push(' >');
        if (object.IsRequired == true) {
            HTML.push('<option></option>');
        }

        jQuery(object.Data).each(function (index, Data) {
            HTML.push('<option value="' + CHSI.Common.ConditionalEscapeHTML(Data.Value, object.AllowHTML) + '" ' + ((jQuery.inArray(Data.Value, ContentValue.split('||')) > -1) ? 'selected' : '') + '>' + CHSI.Common.ConditionalEscapeHTML(Data.Text, object.AllowHTML) + '</option>');
        });

        HTML.push('</select>');
    }
    if (object.ElementType == 9) {
        HTML.push('<div style="position:relative"><div id="Grid' + CHSI.Forms.Elements.GetElementID(object.ElementID, ValueIndex) + '" style="overflow:auto;"><table width="100%" id="' + CHSI.Forms.Elements.GetElementID(object.ElementID, ValueIndex) + '"  ><tr><td><img src="/images/ajax-loader.gif"/></td></tr></table></div></div>');
    }

    if (object.ElementType == 12)
    {
        var varValue;
        if (object.DataStructure.DataTypeID == 1)
        { varValue = 'Y';        }
        if (object.DataStructure.DataTypeID == 4)
        { varValue = '1'; }
        var isChecked = 0;
        
        if (varValue ==  CHSI.Forms.Results.GetValue(object.DataStructure.ColumnID, ValueIndex, FormID))
        { isChecked = 1;}
        HTML.push('<input type="checkbox" name="jxElement_' + object.ElementID + '_' + ValueIndex + '" value="' + CHSI.Common.ConditionalEscapeHTML(varValue, object.AllowHTML) + '" ' + ((isChecked) ? 'checked' : '') + '  title="' + CHSI.Forms.Elements.GetElementTitle(object) + '" class="' + CHSI.Forms.Elements.GetElementCssClasses(object, FormID) + '" ' + CHSI.Forms.Elements.GetSaveHTML(object, ValueIndex, FormID, object.ElementID, TargetDataType));
        if (Disabled == true) {
            HTML.push(' disabled="disabled" ');
        }
        HTML.push(' />&nbsp;');
    
    }


    HTML.push(CHSI.Forms.Elements.GetNoChangeCheckboxHTML(object, ValueIndex, FormID, isNADisabled));

    if (ShowHelp) {
        HTML.push(CHSI.Forms.Elements.GetHelpIconHTML(object.ElementID, FormID));
    }

    /*HTML.push('<label for="jxElement_' + object.ElementID + '" id="jxElement_' + object.ElementID + 'e" generated="true" class="error" style="display:none;"></label>');*/
  
    if (object.AllowNA == true && object.DataStructure) {
      
        HTML.push('<label><input type="checkbox" id="NA' + CHSI.Forms.Elements.GetElementID(object.ElementID, ValueIndex) + '" value="N/A" onclick="CHSI.Forms.Elements.SaveNA(' + object.ElementID + ',' + object.DataStructure.ColumnID + ',' + ValueIndex + ', ' + FormID + ');" ' + NAChecked + ' ');
        if (isNADisabled == true) {
            HTML.push(' disabled="disabled" ');
        }
        HTML.push('/>N/A</label>');
    }

    return HTML.join('');
}

CHSI.Forms.Elements.SaveNA = function (ElementID, ColumnID, ValueIndex, FormID) {
    var NACheckbox = jQuery('#NA' + CHSI.Forms.Elements.GetElementID(ElementID, ValueIndex));
    var isNA = NACheckbox.is(':checked');
    CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].DataChanged = true;
    if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].IsInternal == false) {
        CHSI.Forms.Navigation.SetSectionStatus(CHSI.Forms.CurrentActiveSectionIndex, 'In Progress');
        jQuery('#jxStatusTitle').html('In Progress');
    }

    CHSI.Forms.Navigation.UpdateSectionHTML();

    var NAValueItem = CHSI.Forms.Results.GetResultSetValue(ColumnID, ValueIndex);
    if (NAValueItem) {
        NAValueItem.isDisabled = isNA;

    }


    CHSI.Forms.Elements.SetElementActivation(ElementID, ColumnID, ValueIndex, FormID);
    CHSI.Forms.Results.SaveMetaAttribute(ColumnID, CHSI.Forms.ResultID, ValueIndex, 17, 19, 0, isNA, false, ElementID);
};

CHSI.Forms.Elements.SetElementActivation = function (ElementID, ColumnID, ValueIndex, FormID) {
    var Element;
    var isDisabled = false;
    var ResultItem = CHSI.Forms.Results.GetResultSetValue(ColumnID, ValueIndex);
    if (ResultItem.isDisabled) {
        isDisabled = ResultItem.isDisabled;
    }

    Element = jQuery('[name=jxElement_' + ElementID + '_' + ValueIndex + ']');
    if (CHSI.Forms.DebugMode) {
        CHSI.Common.Log('Element Disabled: ' + isDisabled + ', Elements Found: ' + Element.length);
    }
    if (isDisabled) {
        Element.attr('disabled', 'disabled');
    }
    else {
        Element.removeAttr('disabled');
    }


}

CHSI.Forms.Elements.GetFileUploadListing = function (ColumnID, FormID, ElementID) {

    if (CHSI.Forms.DebugMode) {
        CHSI.Common.Log('building html for column - ' + ColumnID + ' and form - ' + FormID + ', elementid ' + ElementID);
    }

    var HTML = [];
    var ExistingFileCount = 0;
    HTML.push('<table cellspacing="2" cellpadding="2" class="UploadGrid" width="100%"><thead><td>Description</td><td>Options</td></tr>');
    var ParentElement;
    jQuery(CHSI.Forms.Forms['f' + FormID].Elements).each(function (index, element) {
        if (element.ElementID == ElementID) {
            ParentElement = element;
            return false;
        }
    });
    if (ParentElement != null) {
        var ResultSetValues = CHSI.Forms.Results.GetResultSetValueArray(ColumnID);

        for (var ResultIndex = 0, ResultSetLength = ResultSetValues.length; ResultIndex < ResultSetLength; ++ResultIndex) {
            var ResultValue = ResultSetValues[ResultIndex];
            if (ResultValue.StringValue != '' && ResultValue.Status != 'deleted' && ResultValue.Status != 'virtual') {
                ExistingFileCount += 1;
                CHSI.Common.Log('adding attachment - ColumnID: ' + ColumnID + ', Status: ' + ResultValue.Status + ', FileID: ' + ResultValue.IntegerValue);
                HTML.push('<tr><td><input type="text" title="Element_' + ElementID + '_ColumnID_' + ParentElement.DataStructureColumnID + '_' + ResultValue.ValueIndex + ' " value="' + CHSI.Common.EscapeHTML(ResultValue.StringValue) + '" id="' + CHSI.Forms.Elements.GetElementID(ElementID, ResultValue.ValueIndex) + '" name="jxElement_' + ElementID + '_' + ResultValue.ValueIndex + '" ' + CHSI.Forms.Elements.GetSaveHTML(ParentElement, ResultValue.ValueIndex, FormID, ElementID, 1) + ' /></td>' + '<td><a href="/CHSIFileHandler.axd?FileID=' + ResultValue.IntegerValue + '" class="smalllink" target="_blank">[View File]</a> ');
                if (ParentElement.isDisabled == false) {
                    HTML.push('<img src="/images/icons/icon_close.png" title="Delete Attachment" style="vertical-align: middle; pointer: cursor;" onclick="CHSI.Forms.Results.DeleteElement(' + ElementID + ',' + ColumnID + ',' + ResultValue.ValueIndex + ',' + FormID + ')"/>');
                }

                HTML.push('</td></tr>');
            }
        }


    }

    if (ExistingFileCount == 0) {
        HTML.push('<tr><td>No Existing Files</td></tr>');
    }
    HTML.push('</table>');
    return HTML.join('');
}

CHSI.Forms.Elements.GetSaveHTML = function (element, ValueIndex, FormID, ParentElementID, TargetDataType) {

    if (ValueIndex == null) {
        ValueIndex = 0;
    }
    if (element.DataStructure == null) {
        return '';
    }
    if (ParentElementID == null) {
        ParentElementID = 'null';
    }

    var ElementTag = element.Tag.replace("{", "").replace("}", "").split('.');
    if (ElementTag.length == 3) {
        ElementTag.push(0); /*Default to Value Index 0 */
    }
    ElementTag[3] = ValueIndex;
    var FormattedTag = '{' + ElementTag.join('.') + '}';

    /* hardcoded to the first index*/
    var SaveHTML = [];

    if (element.ElementType == 3) {
        SaveHTML.push('onblur="CHSI.Forms.Results.SaveValue(this,');
    } else {
        SaveHTML.push('onchange="CHSI.Forms.Results.SaveValue(this,');
    }

    SaveHTML.push(element.DataStructureColumnID);
    SaveHTML.push(',\'');
    SaveHTML.push(FormattedTag);
    SaveHTML.push('\',');
    SaveHTML.push(ValueIndex);
    SaveHTML.push(',');
    SaveHTML.push(FormID);
    SaveHTML.push(',\'');
    SaveHTML.push(ParentElementID);
    SaveHTML.push('\',' + TargetDataType + '); "');
    return SaveHTML.join('');
}

CHSI.Forms.Elements.formatValue = function (element, ValueAsString) {
    if (ValueAsString != '') {
        jQuery(element.MetaAttributes).each(function (index, metaattribute) {
            if (metaattribute.MetaType == 5) {
                /* Currency*/
                if (metaattribute.MetaValue == '11') {
                    if (CHSI.Forms.DebugMode) {
                        CHSI.Common.Log('formatting as currency');
                    }
                    var UseDecimals = false;
                    if (element.DataType == 3) {
                        UseDecimals = true;
                    }
                    ValueAsString = CHSI.Common.Numbers.FormatCurrency(ValueAsString, UseDecimals);
                }
                return false;
            }
        });
    }
    return ValueAsString;
}
CHSI.Forms.Elements.RemoveFormatting = function (ColumnID, ValueAsString, FormID) {
    if (CHSI.Forms.DebugMode) {
        CHSI.Common.Log('removing Formatting for ' + ColumnID + ' in form: ' + FormID + ' searching element count: ' + CHSI.Forms.Forms['f' + FormID].Elements.length);
    }
    jQuery(CHSI.Forms.Forms['f' + FormID].Elements).each(function (index, element) {
        if (element.DataStructureColumnID == ColumnID) {
            jQuery(element.MetaAttributes).each(function (index, metaattribute) {
                if (metaattribute.MetaType == 5) {
                    if (metaattribute.MetaValue == '11') {
                        CHSI.Common.Log('removing currency filter');
                        ValueAsString = ValueAsString.replace(/,/g, '').replace(/\$/g, '');
                        if (element.DataStructure.DataTypeID == 5) {
                            CHSI.Common.Log('removing decimals');
                            var Values = ValueAsString.split('.');
                            ValueAsString = Values[0];
                        }
                    }
                    CHSI.Common.Log('New Value: ' + ValueAsString);

                    return false;
                }
            });
        }
    });

    return ValueAsString;
}


CHSI.Forms.Elements.GetElementTitle = function (element) {
    var result = [];
    if (CHSI.Forms.DebugMode == true) 
    {
        result.push('EI: ' + element.ElementID);
        if (element.DataStructure != null) {
            result.push('DT: ' + element.DataStructure.DataTypeID + ' ')
            result.push('DSCI: ' + element.DataStructureColumnID + ' ')
        }
    }
    

    return result;
}

CHSI.Forms.Elements.GetElementID = function (ElementID, ValueIndex) {
    if (ValueIndex == null) {
        ValueIndex = 0;
    }
    var OutputName = 'jxElement_' + ElementID + '_' + ValueIndex;

    return OutputName;
}


CHSI.Forms.Elements.GetCSSSafeString = function (valueobject) {
    if (valueobject) { return valueobject.replace(/[^a-zA-Z0-9]/g, ''); } else { return '';}

    
}

/* we'll add more properties as it makes sense to support them, the idea is that we could change anything */
CHSI.Forms.Elements.ChangeElementProperty = function (Tag, PropertyName, NewValue, TargetID) {
    Tag = CHSI.Forms.Elements.GetCSSSafeString(Tag);
    if (CHSI.Forms.DebugMode) {
        CHSI.Common.Log('Changing Element ' + TargetID + ' ' + PropertyName + ' value to ' + NewValue);
    }
    var Element = CHSI.DOM.Get('#jxContainer_' + TargetID);
    if (PropertyName == 'DefaultVisibility') {
        if (NewValue == 1) {
            if (CHSI.Forms.DebugMode) {
                CHSI.Common.Log('Showing ELement count ' + Element.length);
            }
            Element.removeClass('hiddenBlock');
            Element.addClass('visibleBlock');
        }
        else {
            if (CHSI.Forms.DebugMode) {
                CHSI.Common.Log('Hiding Element count ' + Element.length);
            }
            Element.removeClass('visibleBlock');
            Element.addClass('hiddenBlock');

        }

    } else {

        if (PropertyName == 'ReadOnly') {
            if (NewValue == 1) {
                if (CHSI.Forms.DebugMode) {
                    CHSI.Common.Log('Disable ELement count ' + Element.length);
                }
                Element.find(':input').attr("disabled", "disabled");

            }
            else {
                if (CHSI.Forms.DebugMode) {
                    CHSI.Common.Log('Enable Element count ' + Element.length);
                }
                if (!CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].isDisabled) {
                    Element.find(':input').removeAttr("disabled");
                }
            }

        }
    }
};

CHSI.Forms.Elements.ChangeSectionProperty = function (Tag, PropertyName, NewValue, TargetID) {
    var Section = jQuery('#jxSectionMenu' + TargetID);
    if (PropertyName == 'DefaultVisibility') {

        var objSection;
        jQuery(CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].Sections).each(function (index, section) {
            if (section.SectionID == TargetID) {
                objSection = section;

            }
        });
        if (objSection == null) {
            CHSI.Common.Log('Section ' + TargetID + ' does not exist in returned formset');
            return false;
        }
        if (objSection.CurrentVisibility == NewValue) {
            if (CHSI.Forms.DebugMode) {
                CHSI.Common.Log('Section already set, stopping Section Visibility Change');
            }
            return false;
        }

        objSection.CurrentVisibility = NewValue;
        if (NewValue == 1) {
            if (CHSI.Forms.DebugMode) {
                CHSI.Common.Log('Showing section count ' + Section.length);
            }
            Section.removeClass('hiddenBlock');
        }
        else {
            if (CHSI.Forms.DebugMode) {
                CHSI.Common.Log('Hiding section count ' + Section.length);
            }
            Section.addClass('hiddenBlock');
        }

        if (objSection) {
            CHSI.Forms.Results.SaveSection(CHSI.Forms.PrimaryFormID, TargetID, objSection.CurrentVisibility, CHSI.Forms.ResultID, null, true);
        }

    }
};

