interface JQuery {
    fineUploader(): JQuery;
    fineUploader(settings: any): JQuery;
}
declare module qq {
    function FineUploader(setting: any): void;
}
namespace PL.Claims.Controllers {

    export class ClaimIntakeDynamicV2Controller extends Abstract.ClaimIntakeFormBase {
        
        Notification: Claims.Controllers.ClaimIntakeNotificationV2Controller;

        private _categoryIndex: number;

        constructor(intakeForm: PL.Claims.Models.IntakeForm) {
            super(intakeForm);            

            this.ClaimIntakeForm = intakeForm;

        }

        init(categoryIndex: number): void {
            this._categoryIndex = categoryIndex;
            this.Notification.CategoryIndex = categoryIndex;
            this.Notification.ClaimIntakeForm = this.ClaimIntakeForm;
            this.handlebarHelpers();            
            this.bind().done(() => {
                this.setupEvents();
            });
        }

        bind(): JQueryDeferred<any> {
            var $result = $.Deferred();
            console.log(this.ClaimIntakeForm.Categories[this._categoryIndex]);
            mW.handlebars.bind(CHSITemplates.ClaimIntakeForm_Dynamic, { Category: this.ClaimIntakeForm.Categories[this._categoryIndex] }, this.$category_container).done(() => {                   

            });
            return $result.resolve(true);
        }

        setupEvents(): void {
            var _self = this;

            //this.updateCategoryProgress(this._categoryIndex);                                    

            $('.Date').datepicker({
                showOn: "both",
                buttonImage: "/images/icons/cal.gif",
                buttonImageOnly: true,
            });

            //maxDate of 0 only allows today and past dates to be chosen
            $('.Date.iPriorDate').datepicker('option', { maxDate: 0 });

            $.validator.methods.newDateCheck = function checkDate(dateString) {
                let momentDate = moment(dateString)
                return momentDate.isValid()
            };

            $('form').validate({
                rules: {
                    select: {
                        requiredSelect: true,
                        newDateCheck: true
                    }
                }
            });

            $('.attachmentContainer').each(function () {
                _self.fileUploader($(this).data('attachmentid'));
            });

            this.requestNotification();
        }

        events(): void {
        // event "input" helps us with autofill detection - not sure if it has any negative effects yet
            this.$category_container.on('blur input', "#dynamicIntakeFormContent select, #dynamicIntakeFormContent textarea, #dynamicIntakeFormContent input:not('.Date')", (e) => {
                var formFieldID = +$(e.target).data('formfieldid'), value = $(e.target).val(), isValid = $(e.target).valid(), formFieldType = +$(e.target).parent().data('formfieldtype');
                // for some reason jquery valid() is returning bit value, just incase we still check for a normal bool value
                if (+isValid == 1 || isValid) {
                    $(e.target).parent().find('.errorDescription').slideUp('fast');
                } else {
                    $(e.target).parent().find('.errorDescription').slideDown('fast');
                }

                if (formFieldType == 8) {
                    value = this.multiselectValueCombiner(value);
                }

                this.updateCategoryField(formFieldID, value, this._categoryIndex, isValid);
            });

            this.$category_container.on('change input', '#dynamicIntakeFormContent .Date', (e) => {
                var formFieldID = +$(e.target).data('formfieldid'), value = $(e.target).val(), isValid = $(e.target).valid();
                // for some reason jquery valid() is returning a bit value. just incase we still check for a normal bool value
                if (+isValid == 1 || isValid) {
                    $(e.target).parent().find('.errorDescription').slideUp('fast');
                } else {
                    $(e.target).parent().find('.errorDescription').slideDown('fast');
                }
                this.updateCategoryField(formFieldID, value, this._categoryIndex, isValid);
            });
            // needs to be optimized, we have all the data the get below is grabbing technically
            this.$category_container.on('click', '.attachmentName', (e) => {
                var fileid = $(e.target).parent().data('fileid'), formFieldID = $(e.target).parent().data('formfieldid'), additionalNotificationInformation = [], attachment = new PL.Claims.Models.ClaimIncidentAttachments();
                $(e.target).parent().find('.loading-icon').addClass('show-loading-icon');
                attachment.IncidentID = this.ClaimIntakeForm.IncidentReport.IncidentID;
                attachment.FormFieldID = formFieldID;
                attachment.get().done((attachmentInfo) => {
                    var attObj = null;
                    attachmentInfo.forEach((attO) => {
                        if (attO.FileID == fileid) {
                            attObj = attO;
                        }
                    });

                    additionalNotificationInformation.push({ FileID: attObj.FileID, FileName: attObj.FileName, FormFieldID: formFieldID, Description: attObj.Description});
                    this.Notification.getNotification(Enums.ClaimIntakeNotification_Request.FIELD_ATTACHMENT_PREVIEW_AND_EDIT, additionalNotificationInformation);
                    $(e.target).parent().find('.loading-icon').removeClass('show-loading-icon');
                });
                
            });

            this.$category_container.on('mouseover', '.attachmentDelete', (e) => {
                $(e.target).parent().addClass('ui-state-error-text');
            });

            this.$category_container.on('mouseout', '.attachmentDelete', (e) => {
                $(e.target).parent().removeClass('ui-state-error-text');
            });

            this.$category_container.on('click', '.attachmentDelete', (e) => {
                var fileID = $(e.target).parent().data('fileid'), formFieldID = $(e.target).parent().data('formfieldid');
                this.deleteAttachment($(e.target).parent(), fileID, formFieldID);
            });

            this.$container.on('click', '#jxSave', (e) => {
                $(e.target).prop('disabled', true);
                this.saveCategoryFields(this._categoryIndex).done(() => {
                    closeThis(true);
                });
            });
        }        

        fileUploader(attachmentID: number): void {
            var _self = this, additionalNotificationInformation = [];

            $('.attachmentContainer').fineUploader({
                element: document.getElementById('attachmentContainer_' + attachmentID),
                request: {
                    endpoint: '/CHSIIncidentAttachmentHandler.axd',
                    params: { FormFieldID: $('#attachmentContainer_' + attachmentID).data('formfieldid'), IncidentID: $('#attachmentContainer_' + attachmentID).data('incidentid') }
                },
                debug: true,
                allowedExtensions: ['jpg', 'jpeg', 'png', 'gif', 'pdf'],
                multiple: true,
                callbacks: {
                    onSubmit: function (id, fileName) {
                        $('#attachmentContainer_' + attachmentID).next().append(attachmentTemplate(id));
                        var $newAttachment = $('.newAttachment');
                        $newAttachment.hide().slideDown('fast');
                    },
                    onComplete: function (id, fileName, response) {            
                        // future note: response sends back a success boolean. "response.success". - The More You Know.   
                        var formFieldID = $('#attachmentContainer_' + attachmentID).data('formfieldid');
                        additionalNotificationInformation.push({ FileID: response.FileID, FileName: fileName, FormFieldID: formFieldID, Description: null });
                        _self.addAttachment(response.FileID, formFieldID , fileName); 
                        var $newAttachment = $('#newAttachment_' + id);
                        $newAttachment.find('.loading-icon').removeClass('show-loading-icon');
                        $newAttachment.data('fileid', response.FileID);
                        $newAttachment.find('.attachmentName').html(fileName).removeClass('saving');
                        $newAttachment.find('.attachmentName').attr('title', fileName);
                        $newAttachment.removeAttr('id');
                        _self.Notification.getNotification(Enums.ClaimIntakeNotification_Request.FIELD_ATTACHMENT_PREVIEW_AND_EDIT, additionalNotificationInformation);
                    }
                }
            });
                        
            var attachmentTemplate = function (id) {
                return '<div id="newAttachment_' + id + '" class="attachmentInformation" data-fileid="" data-formfieldid="' + $('#attachmentContainer_' + attachmentID).data('formfieldid') + '"><div class="attachmentName saving"> Saving... </div><span class="attachmentDelete ui-icon ui-icon-close"></span><div class="loading-icon show-loading-icon"></div></div>';
            };

        }

        requestNotification(): void {
            if (this.ClaimIntakeForm.Categories[this._categoryIndex]._Status == Enums.ClaimIntakeCategory_Status.REVIEW) {
                this.Notification.getNotification(Enums.ClaimIntakeNotification_Request.REVIEW);
            }
        }

        addAttachment(fileID: number, formFieldID: number, fileName: string): void {
            var newAttachment: Models.ClaimIncidentAttachments = new Models.ClaimIncidentAttachments();           
            newAttachment.FileID = fileID, newAttachment.FormFieldID = formFieldID, newAttachment.IncidentID = +this.ClaimIntakeForm.IncidentID, newAttachment.FileName = fileName;
            // add it to the category > fields > attachments object
            this.ClaimIntakeForm.Categories[this._categoryIndex].Fields.forEach((cfO, cfI) => {

                if (cfO.FormFieldID == formFieldID) {
                    
                    cfO.Attachments.push(newAttachment);

                }

            });

            // add it to the global formobject > attachments
            this.ClaimIntakeForm.Attachments.push(newAttachment);
        }

        deleteAttachment($attachment: JQuery, fileID: number, formFieldID: number): void {
            $attachment.find('.attachmentName').html('Deleting...').addClass('deleting');
            $attachment.find('.loading-icon').addClass('show-loading-icon');
            this.ClaimIntakeForm.Categories[this._categoryIndex].Fields.forEach((cfO, cfI) => {
                if (cfO.FormFieldID == formFieldID) {
                    cfO.Attachments.forEach((attO) => {
                        if (attO.FileID == fileID) {
                            attO.deleteAttachment().done(() => {

                                // remove it from the category > fields > attachments object
                                this.ClaimIntakeForm.Categories[this._categoryIndex].Fields.forEach((cfO, cfI) => {

                                    if (cfO.FormFieldID == formFieldID) {

                                        cfO.Attachments.forEach((faO, faI, fa) => {

                                            if (faO.FileID == fileID) {

                                                fa.splice(faI, 1);

                                            }

                                        });

                                    }

                                });

                                // now we remove it from the global formobject > attachments
            
                                this.ClaimIntakeForm.Attachments.forEach((faO, faI, fa) => {

                                    if (faO.FileID == fileID) {

                                        fa.splice(faI, 1);

                                    }

                                });

                                $attachment.slideUp('fast');
                            });
                        }
                    });
                }
            });            
        }

        multiselectValueCombiner(value: string[]): string {

            var combinedValue = '';

            for (var i = 0; i < value.length; i++) {

                combinedValue = combinedValue + value[i];

                if (i != value.length - 1) {
                    combinedValue = combinedValue + '||';
                }

            }

            return combinedValue;

        }

        handlebarHelpers(): void {
            var _self = this;

            Handlebars.registerHelper('isDisabled', function () {
                var isDisabled = '';
                if (_self.ClaimIntakeForm._Status == Enums.ClaimIntakeForm_Status.SUBMITTED) {
                    isDisabled = ' readonly="readonly" disabled="disabled"';
                }
                return isDisabled;
            });

            Handlebars.registerHelper('VerifiedValue', function (value) {
                var returnVerified: boolean = false;
                if ((value != null && value != "") || (value === 0)) {
                    returnVerified = true;
                } else {
                    returnVerified = false;
                }
                return returnVerified;
            });

            Handlebars.registerHelper('GetDateFieldValue', function (value, fieldName) {
                var returnValue, claimIncidents = _self.ClaimIntakeForm.IncidentReport;

                if (value != null) {
                    returnValue = value;
                } else {
                    if (claimIncidents.hasOwnProperty(fieldName)) {
                        returnValue = claimIncidents[fieldName];
                    }
                }

                if (returnValue == undefined || returnValue == '') {
                    returnValue = null;
                } else {
                    returnValue = GUI.DateTime.formatDate(returnValue);
                }
                return returnValue;
            });

            Handlebars.registerHelper('GetFieldValue', function (value, fieldName) {
                var returnValue, claimIncidents = _self.ClaimIntakeForm.IncidentReport;

                if (value != null) {
                    returnValue = value;
                } else {
                    if (claimIncidents.hasOwnProperty(fieldName)) {
                        returnValue = claimIncidents[fieldName];
                    }
                }
                return returnValue;
            });

            Handlebars.registerHelper('GetFieldValueSelected', function (selectValue, value, fieldName) {
                var returnSelected = '', currentValue, claimIncidents = _self.ClaimIntakeForm.IncidentReport;

                if (value != null) {
                    currentValue = value;
                } else {
                    if (claimIncidents.hasOwnProperty(fieldName)) {
                        currentValue = claimIncidents[fieldName];
                    }
                }

                if (currentValue == selectValue) {
                    returnSelected = 'selected="selected"';
                }

                return returnSelected
            });

            Handlebars.registerHelper('UnpipeMultiselectValues', function (selectValue, value, fieldName) {
                var returnSelected = '', currentValue, claimIncidents = _self.ClaimIntakeForm.IncidentReport;
                               

                if (value != null) {
                    currentValue = value.split('||');
                    for (var i = 0; i < currentValue.length; i++) {
                        if (currentValue[i] == selectValue) {
                            returnSelected = 'selected="selected"';
                        }
                    }
                } else {
                    if (claimIncidents.hasOwnProperty(fieldName)) {
                        currentValue = claimIncidents[fieldName];
                    }
                }                

                return returnSelected
            });
        }
    }

}