var Template_QuoteInfo_QuoteDetails = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "﻿<h1 style=\"text-align:left;\">\r\n    <a href=\"/sig/underwriting/uwmain.aspx\">Quotes</a> &raquo;\r\n    <a href=\"/clientmain.aspx?clientid="
    + alias3(((helper = (helper = helpers.ClientID || (depth0 != null ? depth0.ClientID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ClientID","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3(((helper = (helper = helpers.ClientName || (depth0 != null ? depth0.ClientName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ClientName","hash":{},"data":data}) : helper)))
    + "</a>\r\n</h1>\r\n<table cellspacing=\"2\" cellpadding=\"0\">\r\n    <tr>\r\n        <td>\r\n            Quote Name:\r\n        </td>\r\n        <td>\r\n            "
    + alias3((helpers.GetQuotePropertyTextbox || (depth0 && depth0.GetQuotePropertyTextbox) || alias1).call(depth0,"QuoteName",{"name":"GetQuotePropertyTextbox","hash":{},"data":data}))
    + "\r\n        </td>\r\n        <td>\r\n            Program Name:\r\n        </td>\r\n        <td colspan=\"3\">\r\n            "
    + alias3((helpers.GetQuotePropertyTextbox || (depth0 && depth0.GetQuotePropertyTextbox) || alias1).call(depth0,"ProgramName",{"name":"GetQuotePropertyTextbox","hash":{},"data":data}))
    + "\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>Quote Date: </td>\r\n        <td>\r\n            "
    + alias3((helpers.GetQuotePropertyTextbox || (depth0 && depth0.GetQuotePropertyTextbox) || alias1).call(depth0,"QuoteDate","Date",{"name":"GetQuotePropertyTextbox","hash":{},"data":data}))
    + "\r\n        </td>\r\n        <td>\r\n            Policy Period:\r\n        </td>\r\n        <td>\r\n            "
    + alias3((helpers.GetQuotePropertyTextbox || (depth0 && depth0.GetQuotePropertyTextbox) || alias1).call(depth0,"PolicyPeriod",{"name":"GetQuotePropertyTextbox","hash":{},"data":data}))
    + "\r\n        </td>\r\n        <td>\r\n            Current Status:\r\n        </td>\r\n        <td colspan=\"3\">\r\n            "
    + alias3((helpers.GetQuotePropertyTextbox || (depth0 && depth0.GetQuotePropertyTextbox) || alias1).call(depth0,"Status",{"name":"GetQuotePropertyTextbox","hash":{},"data":data}))
    + "\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>\r\n            Effective Date:\r\n        </td>\r\n        <td>\r\n            "
    + alias3((helpers.GetQuotePropertyTextbox || (depth0 && depth0.GetQuotePropertyTextbox) || alias1).call(depth0,"EffectiveDate","Date",{"name":"GetQuotePropertyTextbox","hash":{},"data":data}))
    + "\r\n        </td>\r\n        <td>\r\n            Expiration Date:\r\n        </td>\r\n        <td>\r\n            "
    + alias3((helpers.GetQuotePropertyTextbox || (depth0 && depth0.GetQuotePropertyTextbox) || alias1).call(depth0,"ExpirationDate","Date",{"name":"GetQuotePropertyTextbox","hash":{},"data":data}))
    + "\r\n        </td>\r\n        <td>\r\n            Pro Rate Days:\r\n        </td>\r\n        <td>\r\n            <input type=\"text\" id=\"proRateDays\" name=\"proRateDays\" size=\"4\" maxlength=\"4\" disabled=\"disabled\" value=\""
    + alias3(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Vars : depth0)) != null ? stack1.DaysInProgramYear : stack1)) != null ? stack1.GetValue : stack1), depth0))
    + "\" />\r\n        </td>\r\n    </tr>\r\n</table>";
},"useData":true});