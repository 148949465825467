CHSI.Common = {
    DebugMode: false,
    CancelNavigation: false,
    Navigation: {
        NavigateToNextTab: function (successFunction) {            

            if (successFunction) {
                successFunction();
            }            

            if (CHSI.Common.CancelNavigation) {
                return false;
            }

            var MenuLinks = $('.obtabs li a'),
                URL = location.href.split('?');
            CurrentTab = $('.obtabs li.current a'),
            CurrentIndex = CurrentTab.data('linkindex'),
            NextIndex = CurrentIndex + 1,
            NextPage = null,
            ReturnURL = URL[1];

            $.each(MenuLinks, function (Index, Value) {
                if (CurrentIndex == Index) {
                    NextPage = $(MenuLinks[NextIndex]).prop('href');
                    return false;
                }

            });

            location.href = NextPage;
        },

        UpdateNavigationTabs: function (successFunction) {
            var MenuLinks = $('.obtabs li a'),
        URL = location.href.split('?'),
        ReturnURL = '?' + URL[1],
        ReturnURLParameter = URL[1];

            if (!ReturnURLParameter.indexOf('ReturnURL') == -1) {
                ReturnURLParameter = ReturnURLParameter.split('ReturnURL')[1];
                ReturnURL = ReturnURL + '&ReturnURL' + ReturnURLParameter;
            }

            $.each(MenuLinks, function (Index, Value) {
                $(MenuLinks[Index]).prop('href', $(MenuLinks[Index]).prop('href').split('?')[0] + ReturnURL);
            });

            if (successFunction) {
                successFunction();
            }

        },

        NavigateToReturnURL: function (defaultReturnURL, successFunction) {
            
            if (successFunction) {
                successFunction();
            }

            var queryString = $.parseQuerystring(),
                ReturnURL = null;
                URL = location.href;

                if (defaultReturnURL) {
                    ReturnURL = defaultReturnURL;
                } else {                    
                    ReturnURL = '/sig/underwriting/uwmain.aspx#GroupName=' + queryString.GroupName + '&TabID=' + queryString.TabID + '&TabName=' + queryString.TabName + '&TabCategory=' + queryString.TabCategory; // This is the default passed in WC
                }

                var returnIndexValue = URL.indexOf('ReturnURL');

            if (returnIndexValue == -1) {
                ReturnURL = location.protocol + '//' + location.host + decodeURIComponent(ReturnURL);
            } else {
                ReturnURL = location.protocol + '//' + location.host + decodeURIComponent(URL.split('ReturnURL=')[1]);
            }
                        
            location.href = ReturnURL;
        }
    },
    Supports_Canvas: function () {
        return !!document.createElement('canvas').getContext;

    },
    Log: function (message) {
        if (CHSI.Common.DebugMode)
        {
            window.console && console.log(message); /* only log if console exists*/
        }
    
    },
    LogObject: function (obj) {
        if (CHSI.Common.DebugMode){
    
    
            var result = [];
            for (prop in obj) {
                result.push(prop + ': ' + obj[prop]);
            }
            CHSI.Common.Log('object values: ' + result.join(', '));
        }
    },
    EscapeHTML: function (Value) {
        if (Value == null) {
            return '';
        }
        else {
            return Value
            .toString()
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#039;")
        }

    },

    ConditionalEscapeHTML: function (Value, cond) {
        if (Value == null) {
            return '';
        }
        else {
            if (!cond) {
                return Value
                    .toString()
                  .replace(/&/g, "&amp;")
                  .replace(/</g, "&lt;")
                  .replace(/>/g, "&gt;")
                  .replace(/"/g, "&quot;")
                  .replace(/'/g, "&#039;")
            } else {
                return Value;
            }
            
        }

    },
    replaceString: function (oldS, newS, fullS) {
        // Replaces oldS with newS in the string fullS
        return fullS.split(oldS).join(newS);
    },
    PopulateListBox: function (Options, SourceData) {
        /*Options: {ListBox: null, SourceProperty: null, ValueProperty: null, ResetList}*/
        var HTML = [];
        var ItemCount = SourceData.length
        for (var i = 0; i < ItemCount.length; i++)
        {
            var Item = SourceData[i];
            HTML.push('<option value="' + Item[Options.ValueProperty] + '">' + Item[Options.SourceProperty] + '</option>')
        }
        if (Options.ResetList)
        {
            Options.ListBox.html('');
        }
        Options.ListBox.append(HTML.join(''));

    }
}
CHSI.Common = CHSI.Common || {};
