module PL.Que.Enums {

    export enum MetaAttributes {

        LIST_SOURCE_ID = 3,
        GRID_SOURCE_ID = 6,
        CONTENT_TYPE = 5,
        REQUIRED = 16,
        READONLY = 20,
        SHOW_NO_CHANGE = 28

    }

}
