CHSI.UW.MuniUW_Finish = {
    Init: function (QuoteID) {
        this.Cache.QuoteID = QuoteID;

        this.Templates.Premium = CHSITemplates.Template_QuotePremium;
        this.Templates.Billing = CHSITemplates.Template_QuotePremium_Billing;
        this.Templates.BillingSchedule = CHSITemplates.Template_QuotePremium_BillingSchedule;
        
        this.Calls.GetQuoteDetails(QuoteID);
        this.Events.Global();
       
    },
    Templates: {},
    Cache: {
        QuoteObject: {},
        InfoObject: {},
        QuoteID: null,
        BRULE: null,
        PDMax: 0,
        MinPR: 0,
        CalcD: 'Modified',
        DuDay: 1,
        EQPay:'N',
        AllowShortBillingMonthsInQuote:'N',
        FirstBillingOffset: 0,
        QuoteDetailArr: [],
        BillingObject: {},
        BillingRules: [],
        BillingType: 'Installment',
        Percentage: false,
        FirstPaymentDate: null,
        BillingPeriod:'Monthly',
        NumberOfPayments: 1,
        DepositPercentage: 0,
        BillingIncrement: 1,
        CalcDepositAmount: 0,
        PaymentTotal: 0,
        OnePayment: 0,
        DepositAmount:0,
        BillingSchedule: [],
        Fday: 1,
        Editable:'Y'



    },
    Binds: {
        BindQuote: function () {
 
            var PolicyTotal = 0;
            var PolicyTotalE = 0;
            var ProRateDays = 365;
            if (CHSI.UW.MuniQuote.Cache.QuoteList.length > 1 && CHSI.UW.MuniQuote.Cache.QuoteObject.ParentQuoteID) {
                ProRateDays = DateDiff(CHSI.UW.MuniQuote.Cache.QuoteList[0].EffectiveDate, CHSI.UW.MuniQuote.Cache.QuoteList[0].ExpirationDate);
                PolicyTotal = CHSI.UW.MuniUW_Finish.Events.CalculatePolicyTotal(CHSI.UW.MuniQuote.Cache.QuoteList[1]);
                PolicyTotalE = CHSI.UW.MuniUW_Finish.Events.CalculatePolicyTotal(CHSI.UW.MuniQuote.Cache.QuoteList[0]);
                PolicyTotalE = PolicyTotalE * (ProRateDays/365);
                CHSI.UW.MuniQuote.Cache.QuoteList[0].PolicyTotal = PolicyTotalE;                
            } else {
                PolicyTotal = CHSI.UW.MuniUW_Finish.Events.CalculatePolicyTotal(CHSI.UW.MuniQuote.Cache.QuoteObject);
            }
            CHSI.UW.MuniQuote.Cache.QuoteObject.PolicyTotalE = PolicyTotalE.toFixed(2);
            CHSI.UW.MuniQuote.Cache.QuoteObject.PolicyTotal = PolicyTotal;
            CHSI.UW.MuniQuote.Cache.QuoteObject.PolicyTotalD = PolicyTotal - PolicyTotalE;
            CHSI.UW.MuniQuote.Cache.QuoteObject.PolicyTotalD = CHSI.UW.MuniQuote.Cache.QuoteObject.PolicyTotalD.toFixed(2);
            $('#jxPremium').html(CHSI.UW.MuniUW_Finish.Templates.Premium(CHSI.UW.MuniQuote.Cache.QuoteObject));
            restart = true;
            sorttable.init();
            $('.Collapsable').hide();
            CHSI.UW.MuniQuote.DisablePage(CHSI.UW.MuniUW_Finish.Cache.Editable);
        },

        Billing: function () {
            $('#jxBilling').html(CHSI.UW.MuniUW_Finish.Templates.Billing(CHSI.UW.MuniUW_Finish.Cache.BillingObject));
            jQuery('.DatePicker').datepicker({ showOn: "both", buttonImage: "/images/icons/cal.gif", buttonImageOnly: true });
            if ($('#jxBillingType').val() == 'Installment') {
                $('.Installment').show();
            } else {
                $('.Installment').hide();
                $('#lblBillingScheduleTitle').hide();
            }
            CHSI.UW.MuniQuote.DisablePage(CHSI.UW.MuniUW_Finish.Cache.Editable);
        },

        BillingSchedule: function () {
            $('#jxBillingSchedule').html(CHSI.UW.MuniUW_Finish.Templates.BillingSchedule({ BillingSchedule: CHSI.UW.MuniUW_Finish.Cache.BillingSchedule }));
            if ($('#jxBillingType').val() == 'Installment') {
                $('#lblBillingScheduleTitle').show();
            } else {               
                $('#lblBillingScheduleTitle').hide();
            }
            
            restart = true;
            sorttable.init();
            CHSI.UW.MuniQuote.DisablePage(CHSI.UW.MuniUW_Finish.Cache.Editable);
        }
    },
    Events: {
        Global: function () {

            $('#jxBilling').on('click', '#jxDepositPercent', function () {               
                if ($(this).is(':checked')) {
                    $('#lblPercentageSign').html('%');                   
                } else {
                    $('#lblPercentageSign').html('');                   
                }
                CHSI.UW.MuniUW_Finish.Events.RefreshGUI();
                CHSI.UW.MuniUW_Finish.Events.SaveQuoteDetails(function () {
                    CHSI.UW.MuniUW_Finish.Calls.SavePaymentSchedules(function () {
                    });
                });
            });


            $('#jxBilling').on('change', '#jxBillingType', function () {
                CHSI.UW.MuniUW_Finish.Cache.BillingType = $('#jxBillingType').val();
                CHSI.UW.MuniUW_Finish.Cache.BillingObject.BillingType = CHSI.UW.MuniUW_Finish.Cache.BillingType;
                CHSI.UW.MuniUW_Finish.Events.BuildBillingRules(CHSI.UW.MuniUW_Finish.Cache.BRULE);
                CHSI.UW.MuniUW_Finish.Events.CalcPercentage();
                CHSI.UW.MuniUW_Finish.Binds.Billing();
                CHSI.UW.MuniUW_Finish.Binds.BillingSchedule();
                CHSI.UW.MuniUW_Finish.Events.SaveQuoteDetails(function () {
                    CHSI.UW.MuniUW_Finish.Calls.SavePaymentSchedules(function () {
                    });
                });
                
            });

            
            $('#jxBilling').on('change', '.AutoRefresh', function () {
                CHSI.UW.MuniUW_Finish.Events.RefreshGUI();
                CHSI.UW.MuniUW_Finish.Events.SaveQuoteDetails(function () {
                    CHSI.UW.MuniUW_Finish.Calls.SavePaymentSchedules(function () {
                    });
                });
            });

            $('#jxBilling').on('click', '#jxSave', function () {
                CHSI.UW.MuniUW_Finish.Events.SaveQuoteDetails();
                CHSI.UW.MuniUW_Finish.Calls.SavePaymentSchedules();

            });
            

            $('#jxBilling').on('change', '#jxBillingPeriod', function () {
                CHSI.UW.MuniUW_Finish.Cache.Percentage = true;                          
                CHSI.UW.MuniUW_Finish.Cache.BillingPeriod = $('#jxBillingPeriod').val();
                var BP = $('#jxBillingPeriod').find('option:selected');
                CHSI.UW.MuniUW_Finish.Cache.BillingIncrement = BP.data('billingincrement');
                CHSI.UW.MuniUW_Finish.Cache.DepositPercentage = BP.data('depositpercentage');
                CHSI.UW.MuniUW_Finish.Cache.NumberOfPayments= BP.data('numberofpayments');                
                CHSI.UW.MuniUW_Finish.Events.LoadDataFromCache();
                CHSI.UW.MuniUW_Finish.Events.RefreshGUI();
                CHSI.UW.MuniUW_Finish.Events.SaveQuoteDetails(function () {
                    CHSI.UW.MuniUW_Finish.Calls.SavePaymentSchedules(function () {                       
                    });
                });
               
            });
            
            $('#jxSaveNext').on('click', function () {
                CHSI.UW.MuniUW_Finish.Events.SaveQuoteDetails(function () {
                    CHSI.UW.MuniUW_Finish.Calls.SavePaymentSchedules(function () {
                        CHSI.Common.Navigation.NavigateToNextTab();
                    });
                });
            });            

            $('#jxCancel').on('click', function () {
                CHSI.Common.Navigation.NavigateToReturnURL();
            });
        },

        CalculatePolicyTotal: function (Quote) {
            var PT = 0;
            for(var key in Quote.Vars) {

                var Variable = Quote.Vars[key];

                if (Variable.includeInPolicyTotal) {
                    var Value = Variable.GetValue;
                    PT = CHSI.Math.SafeAdd(PT, Value);
                }
            }
            return PT;
        },
        RefreshPage: function () {
            CHSI.UW.MuniUW_Finish.Calls.GetQuoteDetails(CHSI.UW.MuniUW_Finish.Cache.QuoteID)
        },

        RefreshGUI: function () {            
            CHSI.UW.MuniUW_Finish.Events.LoadDataToCache();
            CHSI.UW.MuniUW_Finish.Events.CalcPercentage();
            CHSI.UW.MuniUW_Finish.Binds.Billing();
            CHSI.UW.MuniUW_Finish.Binds.BillingSchedule();
        },
        BuildBillingRules: function (strRule) {
            CHSI.UW.MuniUW_Finish.Cache.BillingRules = [];
            strRules = strRule.split('|');
            for (var i = 0; i < strRules.length; i++) {
                strBRules = strRules[i].split(',');
                var BillingRule = {};
                BillingRule.BillingType = strBRules[0];
                BillingRule.BillingRuleName = strBRules[1];
                BillingRule.BillingIncrement = strBRules[2];
                BillingRule.DepositPercentage = strBRules[3];
                BillingRule.NumberOfPayments= strBRules[4];
                if (CHSI.UW.MuniUW_Finish.Cache.BillingType.substring(0, 1) == BillingRule.BillingType) {
                    CHSI.UW.MuniUW_Finish.Cache.BillingRules.push(BillingRule);
                }                 
                              
            }
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.BillingRules = CHSI.UW.MuniUW_Finish.Cache.BillingRules;           
        },

        LoadDataFromCache:function(){
            $('#jxBillingIncrement').val(CHSI.UW.MuniUW_Finish.Cache.BillingIncrement);
            $('#jxDepositPercentage').val(CHSI.UW.MuniUW_Finish.Cache.DepositPercentage);
            $('#jxNumberOfPayments').val(CHSI.UW.MuniUW_Finish.Cache.NumberOfPayments);
            $('#jxBillingType').val(CHSI.UW.MuniUW_Finish.Cache.BillingType);
            $('#jxPaymentDate').val(CHSI.UW.MuniUW_Finish.Cache.FirstPaymentDate);
            $('#jxBillingPeriod').val(CHSI.UW.MuniUW_Finish.Cache.BillingPeriod);
            $('#jxDepositPercent').prop('checked', CHSI.UW.MuniUW_Finish.Cache.Percentage);       
            
        },
        LoadDataToCache: function () {
            CHSI.UW.MuniUW_Finish.Cache.BillingType = $('#jxBillingType').val();
            CHSI.UW.MuniUW_Finish.Cache.FirstPaymentDate = $('#jxPaymentDate').val();
            CHSI.UW.MuniUW_Finish.Cache.Percentage = $('#jxDepositPercent').is(':checked');
            CHSI.UW.MuniUW_Finish.Cache.BillingIncrement = $('#jxBillingIncrement').val();
            CHSI.UW.MuniUW_Finish.Cache.BillingPeriod = $('#jxBillingPeriod').val();
            CHSI.UW.MuniUW_Finish.Cache.NumberOfPayments = $('#jxNumberOfPayments').val();
            CHSI.UW.MuniUW_Finish.Cache.DepositPercentage = $('#jxDepositPercentage').val();           
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.BillingPeriod = CHSI.UW.MuniUW_Finish.Cache.BillingPeriod;
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.BillingIncrement = CHSI.UW.MuniUW_Finish.Cache.BillingIncrement;
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.DepositPercentage = CHSI.UW.MuniUW_Finish.Cache.DepositPercentage;
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.NumberOfPayments = CHSI.UW.MuniUW_Finish.Cache.NumberOfPayments;
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.Percentage = CHSI.UW.MuniUW_Finish.Cache.Percentage;
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.FirstPaymentDate = CHSI.UW.MuniUW_Finish.Cache.FirstPaymentDate;
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.BillingType = CHSI.UW.MuniUW_Finish.Cache.BillingType;
        },
        SaveQuoteDetails: function (successFunction) {
            CHSI.UW.MuniUW_Finish.Events.LoadDataToCache();
            if (CHSI.UW.MuniUW_Finish.Cache.DepositAmount == null) { CHSI.UW.MuniUW_Finish.Events.RefreshGUI(); };
            CHSI.UW.MuniQuote.SaveQuoteDetail(CHSI.UW.MuniUW_Finish.Cache.QuoteID, 28, CHSI.UW.MuniQuote.Cache.QuoteObject.PolicyTotal, function () {
                CHSI.UW.MuniQuote.SaveQuoteDetail(CHSI.UW.MuniUW_Finish.Cache.QuoteID, 29, CHSI.UW.MuniUW_Finish.Cache.BillingType, function () {
                    if (CHSI.UW.MuniUW_Finish.Cache.BillingPeriod == null) {
                        alert('Billing Period Rule is not currently configured properly. Please configure this item correctly to continue.');
                        CHSI.Common.CancelNavigation = true;
                    } else {
                        CHSI.UW.MuniQuote.SaveQuoteDetail(CHSI.UW.MuniUW_Finish.Cache.QuoteID, 30, CHSI.UW.MuniUW_Finish.Cache.BillingPeriod);
                        CHSI.Common.CancelNavigation = false;
                    }
                    CHSI.UW.MuniQuote.SaveQuoteDetail(CHSI.UW.MuniUW_Finish.Cache.QuoteID, 31, CHSI.UW.MuniUW_Finish.Cache.BillingIncrement, function () {
                    var strPercent = "entered";
                    if (CHSI.UW.MuniUW_Finish.Cache.BillingObject.Percentage) {
                        strPercent = "percent";
                    }
                    CHSI.UW.MuniQuote.SaveQuoteDetail(CHSI.UW.MuniUW_Finish.Cache.QuoteID, 32, strPercent, function () {
                        CHSI.UW.MuniQuote.SaveQuoteDetail(CHSI.UW.MuniUW_Finish.Cache.QuoteID, 33, CHSI.UW.MuniUW_Finish.Cache.DepositPercentage, function () {
                            CHSI.UW.MuniQuote.SaveQuoteDetail(CHSI.UW.MuniUW_Finish.Cache.QuoteID, 34, CHSI.UW.MuniUW_Finish.Cache.DepositAmount, function () {
                                CHSI.UW.MuniQuote.SaveQuoteDetail(CHSI.UW.MuniUW_Finish.Cache.QuoteID, 35, CHSI.UW.MuniUW_Finish.Cache.NumberOfPayments, function () {
                                    CHSI.UW.MuniQuote.SaveQuoteDetail(CHSI.UW.MuniUW_Finish.Cache.QuoteID, 36, CHSI.UW.MuniUW_Finish.Cache.OnePayment, function () {    
                                        CHSI.UW.MuniQuote.SaveQuoteDetail(CHSI.UW.MuniUW_Finish.Cache.QuoteID, 40, CHSI.UW.MuniUW_Finish.Cache.FirstPaymentDate, function () {
                                            if (successFunction) {
                                                successFunction();
                                            }
                                        });

                                    });
                                });
                            });
                        });
                    });
                    });
                });
            });

            
        },

        GetNextDate: function (CounterDate,Index) {
            dtCounterDate = new Date(CounterDate);           
                switch (CHSI.UW.MuniUW_Finish.Cache.BillingPeriod) {
                    case 'Weekly':
                        dtCounterDate = CHSI.Dates.addDays(dtCounterDate, 7 * CHSI.UW.MuniUW_Finish.Cache.BillingIncrement);                       
                        break;
                    case 'Quarterly':
                        if(Index==0){
                            dtCounterDate.setMonth(dtCounterDate.getMonth() + 3);
                            var cc=0;
                            while (dtCounterDate.getDate() != CHSI.UW.MuniUW_Finish.Cache.DuDay && cc < 31) {
                                dtCounterDate.setDate((dtCounterDate.getDate() + 1));
                                cc++;
                            }
                        }else{
                            dtCounterDate.setMonth(dtCounterDate.getMonth() + 3* CHSI.UW.MuniUW_Finish.Cache.BillingIncrement);
                        }                        
                        break;
                    case 'Semi-Annual':
                        if(Index==0){
                            if (dtCounterDate.getMonth() < 6) {
                                dtCounterDate = new Date('7/1/' + dtCounterDate.getFullYear());
                            } else {
                                dtCounterDate = new Date('1/1/' + (dtCounterDate.getFullYear() + 1));
                            }
                        }else{
                            dtCounterDate.setMonth(dtCounterDate.getMonth() + 6 * CHSI.UW.MuniUW_Finish.Cache.BillingIncrement);
                        }
                        break;
                    case 'Annual':
                        if(Index==0){
                            dtCounterDate = new Date((dtCounterDate.getMonth() + 1) + '/1/' + (dtCounterDate.getFullYear() + 1));
                        }else{
                            dtCounterDate.setMonth(dtCounterDate.getMonth() + 12 * CHSI.UW.MuniUW_Finish.Cache.BillingIncrement);
                        }
                        break;
                    default:
                        if (Index == 0) {
                            dtCounterDate = new Date((dtCounterDate.getMonth() + 1) + '/' + CHSI.UW.MuniUW_Finish.Cache.DuDay + '/' + dtCounterDate.getFullYear());
                            if (CHSI.UW.MuniUW_Finish.Cache.AllowShortBillingMonthsInQuote == 'Y') {
                                dtCounterDate.setMonth(dtCounterDate.getMonth() + 1);
                            } else {
                                if (CHSI.UW.MuniUW_Finish.Cache.Fday > 14) {
                                    dtCounterDate.setMonth(dtCounterDate.getMonth() + 2);
                                } else {
                                    dtCounterDate.setMonth(dtCounterDate.getMonth() + 1);
                                }

                            }
                        }else{
                            dtCounterDate.setMonth(dtCounterDate.getMonth() + 1 * CHSI.UW.MuniUW_Finish.Cache.BillingIncrement);
                        }

                        break;
                }
           
                return CHSI.Dates.GetShortDate(dtCounterDate);
        },       
        CalcPercentage: function () {
            var PolicyTotal = CHSI.UW.MuniQuote.Cache.QuoteObject.PolicyTotal;            
            var CalcDepositAmount = 0;
            if (CHSI.UW.MuniUW_Finish.Cache.Percentage) {
                var mulCalcDeposit= CHSI.Math.SafeMultiply(PolicyTotal, CHSI.UW.MuniUW_Finish.Cache.DepositPercentage);
                CalcDepositAmount = CHSI.Math.SafeDivide(mulCalcDeposit, 100);               
            } else {                
                CalcDepositAmount = CHSI.UW.MuniUW_Finish.Cache.DepositPercentage;
            }
            
            CHSI.UW.MuniUW_Finish.Cache.CalcDepositAmount = CalcDepositAmount;            
            CHSI.UW.MuniUW_Finish.Cache.DepositAmount = CalcDepositAmount*1;
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.DepositAmount = CalcDepositAmount;
            CHSI.UW.MuniUW_Finish.Cache.PaymentTotal = CHSI.Math.SafeSubtract(PolicyTotal, CalcDepositAmount);
            var CounterDate = CHSI.UW.MuniUW_Finish.Cache.FirstPaymentDate;
            var CounterAmount = CHSI.UW.MuniUW_Finish.Cache.DepositAmount;
            CHSI.UW.MuniUW_Finish.Cache.BillingSchedule = [];            
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.PaymentTotal = CHSI.UW.MuniUW_Finish.Cache.PaymentTotal;
            if ((CHSI.UW.MuniUW_Finish.Cache.NumberOfPayments*1)) {
                CHSI.UW.MuniUW_Finish.Cache.OnePayment = 1*CHSI.Math.SafeDivide(CHSI.UW.MuniUW_Finish.Cache.PaymentTotal, CHSI.UW.MuniUW_Finish.Cache.NumberOfPayments);               
                CHSI.UW.MuniUW_Finish.Cache.OnePayment = CHSI.UW.MuniUW_Finish.Cache.OnePayment.toFixed(2) * 1;
                CHSI.UW.MuniUW_Finish.Cache.DepositAmount = CHSI.UW.MuniUW_Finish.Cache.DepositAmount.toFixed(2) * 1;
                var OneMultNum = CHSI.Math.SafeMultiply(CHSI.UW.MuniUW_Finish.Cache.OnePayment, CHSI.UW.MuniUW_Finish.Cache.NumberOfPayments);
                var Surplus = CHSI.Math.SafeSubtract(PolicyTotal, OneMultNum);
                Surplus = CHSI.Math.SafeSubtract(Surplus * 1, CHSI.UW.MuniUW_Finish.Cache.DepositAmount);
                CHSI.UW.MuniUW_Finish.Cache.BillingObject.PaymentAmount = CHSI.UW.MuniUW_Finish.Cache.OnePayment;
                CHSI.UW.MuniUW_Finish.Cache.BillingObject.DepositAmount = CHSI.UW.MuniUW_Finish.Cache.DepositAmount;               
               
                if(CHSI.UW.MuniUW_Finish.Cache.EQPay=='N'){
                    CounterAmount = Surplus.toFixed(2)*1;                    
                } else {
                    CounterAmount = 0;
                }
            }
                var j = 0;
                if (CHSI.UW.MuniUW_Finish.Cache.DepositAmount > 0) {
                    var Deposit = {};
                    Deposit.UWPaymentScheduleID = 0;
                    Deposit.QuoteID = CHSI.UW.MuniUW_Finish.Cache.QuoteID;
                    Deposit.BillDate = CounterDate;
                    Deposit.BillAmount = CHSI.UW.MuniUW_Finish.Cache.DepositAmount * 1 + CounterAmount;
                    CHSI.UW.MuniUW_Finish.Cache.BillingSchedule.push(Deposit);
                    CounterDate = CHSI.UW.MuniUW_Finish.Events.GetNextDate(CounterDate, j);
                    CounterAmount = CHSI.UW.MuniUW_Finish.Cache.OnePayment;
                    j++;
                } else {
                    CounterAmount = CHSI.UW.MuniUW_Finish.Cache.OnePayment * 1 + CounterAmount;
                    CounterAmount = CounterAmount.toFixed(2) * 1;
                }
                for (var i = j; i < (CHSI.UW.MuniUW_Finish.Cache.NumberOfPayments*1+j*1); i++) {
                    var Deposit = {};
                    Deposit.UWPaymentScheduleID = 0;
                    Deposit.QuoteID = CHSI.UW.MuniUW_Finish.Cache.QuoteID;
                    Deposit.BillDate = CounterDate;
                    Deposit.BillAmount = CounterAmount;
                    CHSI.UW.MuniUW_Finish.Cache.BillingSchedule.push(Deposit);
                    CounterDate = CHSI.UW.MuniUW_Finish.Events.GetNextDate(CounterDate,i);
                    CounterAmount = CHSI.UW.MuniUW_Finish.Cache.OnePayment;
                }
                CHSI.UW.MuniUW_Finish.Cache.BillingObject.BillingSchedule = CHSI.UW.MuniUW_Finish.Cache.BillingSchedule;
            
          

            return CalcDepositAmount;
        },
        FirstPaymentDate: function(){
            var EffectiveDate = CHSI.Dates.GetShortDate(CHSI.UW.MuniQuote.Cache.QuoteObject.EffectiveDate);
            var DEffectiveDate = new Date(EffectiveDate);
            CHSI.UW.MuniUW_Finish.Cache.FirstPaymentDate = new Date(DEffectiveDate.getTime() + (24 * 60 * 60 * CHSI.UW.MuniUW_Finish.Cache.FirstBillingOffset));
            CHSI.UW.MuniUW_Finish.Cache.Fday = DEffectiveDate.getDay();
            CHSI.UW.MuniUW_Finish.Cache.FirstPaymentDate=CHSI.Dates.GetShortDate(CHSI.UW.MuniUW_Finish.Cache.FirstPaymentDate);
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.FirstPaymentDate = CHSI.UW.MuniUW_Finish.Cache.FirstPaymentDate;
            $('#jxPaymentDate').val(CHSI.Dates.GetShortDate(CHSI.UW.MuniUW_Finish.Cache.BillingObject.FirstPaymentDate));
        },

        LoadFromQuoteDetails: function () {
            for (var i = 0; i < CHSI.UW.MuniUW_Finish.Cache.QuoteDetailArr.length; i++) {
                switch (CHSI.UW.MuniUW_Finish.Cache.QuoteDetailArr[i].FieldID) {
                    case 29:
                        CHSI.UW.MuniUW_Finish.Cache.BillingType = CHSI.UW.MuniUW_Finish.Cache.QuoteDetailArr[i].FieldValue;
                        break;
                    case 30:
                        CHSI.UW.MuniUW_Finish.Cache.BillingPeriod = CHSI.UW.MuniUW_Finish.Cache.QuoteDetailArr[i].FieldValue;
                        break;
                    case 31:
                        CHSI.UW.MuniUW_Finish.Cache.BillingIncrement = CHSI.UW.MuniUW_Finish.Cache.QuoteDetailArr[i].FieldValue;
                        break;
                    case 32:
                        CHSI.UW.MuniUW_Finish.Cache.Percentage = (CHSI.UW.MuniUW_Finish.Cache.QuoteDetailArr[i].FieldValue == 'percent'?true:false);
                        break;
                    case 33:
                        CHSI.UW.MuniUW_Finish.Cache.DepositPercentage = CHSI.UW.MuniUW_Finish.Cache.QuoteDetailArr[i].FieldValue;
                        break;
                    case 34:
                        CHSI.UW.MuniUW_Finish.Cache.DepositAmount = CHSI.UW.MuniUW_Finish.Cache.QuoteDetailArr[i].FieldValue;
                        break;
                    case 35:
                        CHSI.UW.MuniUW_Finish.Cache.NumberOfPayments = CHSI.UW.MuniUW_Finish.Cache.QuoteDetailArr[i].FieldValue;
                        break;
                    case 36:
                        CHSI.UW.MuniUW_Finish.Cache.OnePayment = CHSI.UW.MuniUW_Finish.Cache.QuoteDetailArr[i].FieldValue;
                        CHSI.UW.MuniUW_Finish.Cache.PaymentAmount = CHSI.UW.MuniUW_Finish.Cache.QuoteDetailArr[i].FieldValue;
                        break;
                    case 40:
                        CHSI.UW.MuniUW_Finish.Cache.FirstPaymentDate = CHSI.UW.MuniUW_Finish.Cache.QuoteDetailArr[i].FieldValue;
                        break;
                }
            }
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.BillingPeriod = CHSI.UW.MuniUW_Finish.Cache.BillingPeriod;
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.BillingIncrement = CHSI.UW.MuniUW_Finish.Cache.BillingIncrement;
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.DepositPercentage = CHSI.UW.MuniUW_Finish.Cache.DepositPercentage;
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.NumberOfPayments = CHSI.UW.MuniUW_Finish.Cache.NumberOfPayments;
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.Percentage = CHSI.UW.MuniUW_Finish.Cache.Percentage;
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.FirstPaymentDate = CHSI.UW.MuniUW_Finish.Cache.FirstPaymentDate;
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.BillingType = CHSI.UW.MuniUW_Finish.Cache.BillingType;
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.PaymentAmount = CHSI.UW.MuniUW_Finish.Cache.PaymentAmount;
            CHSI.UW.MuniUW_Finish.Cache.BillingObject.DepositAmount = CHSI.UW.MuniUW_Finish.Cache.DepositAmount;
        },
        
    },

    Calls: {
        GetQuoteDetails: function (QuoteID) {
            if (!QuoteID) {
                QuoteID = CHSI.UW.MuniUW_Finish.Cache.QuoteID;
            }
            var Success = function (e) {
                CHSI.UW.MuniQuote.Cache.QuoteObject = e[0];
                CHSI.UW.MuniQuote.Cache.QuoteList = e;
                if (CHSI.UW.MuniQuote.Cache.QuoteObject.QuoteStatus && CHSI.UW.MuniQuote.Cache.QuoteObject.QuoteStatus.Editable == 'N') {
                    CHSI.UW.MuniUW_Finish.Cache.Editable = 'N';
                }
                CHSI.UW.MuniUW_Finish.Calls.GetUWRule('DMax', function (e) { if (e && e.RuleValue) { CHSI.UW.MuniUW_Finish.Cache.DMax = e.RuleValue; } else { CHSI.UW.MuniUW_Finish.Cache.DMax = 0 } });
                CHSI.UW.MuniUW_Finish.Calls.GetUWRule('MinPR', function (e) { if (e && e.RuleValue) { CHSI.UW.MuniUW_Finish.Cache.MinPR = e.RuleValue; } else { CHSI.UW.MuniUW_Finish.Cache.MinPR = 0 } });
                CHSI.UW.MuniUW_Finish.Calls.GetUWRule('CalcD', function (e) { if (e && e.RuleValue) { CHSI.UW.MuniUW_Finish.Cache.CalcD = e.RuleValue; } else { CHSI.UW.MuniUW_Finish.Cache.CalcD = 'Modified' } });
                CHSI.UW.MuniUW_Finish.Calls.GetUWRule('DuDay', function (e) { if (e && e.RuleValue) { CHSI.UW.MuniUW_Finish.Cache.DuDay = e.RuleValue; } else { CHSI.UW.MuniUW_Finish.Cache.DuDay = 1 } });
                CHSI.UW.MuniUW_Finish.Calls.GetUWRule('EQPay', function (e) { if (e && e.RuleValue) { CHSI.UW.MuniUW_Finish.Cache.EQPay = e.RuleValue; } else { CHSI.UW.MuniUW_Finish.Cache.EQPay = 'N' } });
                CHSI.UW.MuniUW_Finish.Calls.GetGroupSpec('AllowShortBillingMonthsInQuote', function (e) { if (e) { CHSI.UW.MuniUW_Finish.Cache.AllowShortBillingMonthsInQuote = e; } else { CHSI.UW.MuniUW_Finish.Cache.AllowShortBillingMonthsInQuote = 'N' } });
                CHSI.UW.MuniUW_Finish.Calls.GetUWRule('BRULE', function (e) {
                    if (e && e.RuleValue) {
                        CHSI.UW.MuniUW_Finish.Cache.BRULE = e.RuleValue;
                        CHSI.UW.MuniUW_Finish.Events.BuildBillingRules(e.RuleValue);
                    } else {
                        CHSI.UW.MuniUW_Finish.Cache.BRULE = null
                    }
                        CHSI.UW.MuniUW_Finish.Calls.GetGroupSpec('FirstBillingOffset', function (e) {
                            if (e) {
                                CHSI.UW.MuniUW_Finish.Cache.FirstBillingOffset = e;
                            } else { CHSI.UW.MuniUW_Finish.Cache.FirstBillingOffset = 0 }
                            CHSI.UW.MuniUW_Finish.Events.FirstPaymentDate();
                                CHSI.UW.MuniQuote.GetQuoteDetail('', '', QuoteID, function (e) {
                                    if (e) {
                                        CHSI.UW.MuniUW_Finish.Cache.QuoteDetailArr = e;
                                        CHSI.UW.MuniUW_Finish.Events.LoadFromQuoteDetails();
                                       
                                    }
                                    CHSI.UW.MuniUW_Finish.Binds.Billing();
                                    CHSI.UW.MuniUW_Finish.Calls.GetPaymentSchedules(function (e) {
                                        if (e) {
                                            CHSI.UW.MuniUW_Finish.Cache.BillingSchedule = e;       
                                        }                                        
                                        CHSI.UW.MuniUW_Finish.Binds.BillingSchedule();
                                    });
                                    
                                });
                    });
                });
               
                
                CHSI.UW.MuniUW_Finish.Binds.BindQuote();

                var SecOptions = {},
                        isVerified = false;
                SecOptions.PermissionID = 14;
                SecOptions.Success = function (e) {
                    isVerified = e;
                    if (isVerified) {
                        CHSI.UW.MuniQuote.MenuController(CHSI.UW.MuniQuote.Cache.QuoteObject, '#jxMenuContainer');
                    }
                }
                CHSI.Security.HasPermission(SecOptions);
                

                
            }

            CHSI.UW.MuniQuote.GetQuoteDetails(QuoteID, Success);                        
        },

        GetUWRule: function (Code,Success) {
            var QuoteType=CHSI.UW.MuniQuote.Cache.QuoteObject.QuoteType;
            var GroupID=CHSI.UW.MuniQuote.Cache.QuoteObject.GroupID;
            var PolicyPeriod = CHSI.UW.MuniQuote.Cache.QuoteObject.PolicyPeriod;
            CHSI.UW.MuniQuote.GetUWRule(QuoteType, GroupID, PolicyPeriod, Code, Success);
        },
        GetGroupSpec: function (SpecName, Success) {            
            var GroupID = CHSI.UW.MuniQuote.Cache.QuoteObject.GroupID;           
            CHSI.UW.MuniQuote.GetGroupSpec(GroupID, SpecName, Success);

        },

        SavePaymentSchedules: function (Success) {
            var Options = {};
            Options.URL = '/api/UW/UW/SavePaymentSchedules';
            Options.Data = CHSI.UW.MuniUW_Finish.Cache.BillingSchedule;
            Options.Success = Success;
            CHSI.Common.Ajax.Post(Options);
        },

        GetPaymentSchedules: function (Success) {
            var Options = {};
            Options.URL = '/api/UW/UW/GetPaymentSchedules?QuoteID=' + CHSI.UW.MuniUW_Finish.Cache.QuoteID;           
            Options.Success = Success;
            CHSI.Common.Ajax.Get(Options);
        },
    }

}
