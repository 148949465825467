module PL.Claims.APIs {

    export class IncidetnAttachmentRepo {

        private static _routePrefix: string = '/api/ClaimSystem/ClaimIncidentReport/';

        public static getIncident(incidentID: number): JQueryDeferred<Models.ClaimIncidentReport> {
            var result = $.Deferred();
            $.get(this._routePrefix + 'GetIncident?IncidentID=' + incidentID).done(function (data) {
                result.resolve(data);
            });
            return result;
        }
    }

}