module PL.Claims.Models {

    export class IntakeFormField extends FormField {

        FieldValue: string;
        PriorValue: string;
        NumOfUpdates: number;
        IncidentID: number;        

        constructor(field: IntakeFormField) {

            super(<FormField>field);
            this.FieldValue = field.FieldValue;
            this.PriorValue = field.PriorValue;
            this.NumOfUpdates = field.NumOfUpdates;
            this.IncidentID = field.IncidentID;


        }

        saveIntakeFormField(formFields: any[]): JQueryDeferred<Models.IntakeFormField> {
            var result = $.Deferred();

            APIs.IntakeFormFieldRepo.SaveIntakeFormField(formFields).done(function (data) {
                result.resolve(data);
            });
            return result;
        }                

    }

} 