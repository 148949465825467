module PL.Claims.Models {

    export class FormType {

        FormTypeID: number;
        FormTypeName: string;
        Name: string;
        Status: string;
        Description: string;
        ReportForm: string;
        DaysToStale: number;
        Instructions: string;
        FinishInstructions: string;
        AssociatedGroups: string[];
        Notifications: PL.Claims.Models.Notifcation[];
        AllNotifications: PL.Claims.Models.Notifcation[];
        FormFields: PL.Claims.Models.FormField[];
        FormCategories: PL.Claims.Models.FormCategory[];
        FormCategory: PL.Claims.Models.FormCategory;
        CreateTmsp: Date;
        CreateUser: string;
        UpdateTmsp: Date;
        UpdateUser: string;

        constructor() {
            this.FormTypeID = null;
            this.FormTypeName = null;
            this.Name = null;
            this.Status = null;
            this.Description = null;
            this.ReportForm = null;
            this.DaysToStale = null;
            this.Instructions = null;
            this.FinishInstructions = null;
            this.CreateUser = null;
            this.CreateTmsp = null;
            this.UpdateUser = null;
            this.UpdateTmsp = null;     
            this.FormCategory = new FormCategory();
            this.FormCategories = new Array<FormCategory>();
            this.AssociatedGroups = new Array<string>();
            this.FormFields = new Array<FormField>();
            this.Notifications = new Array<Notifcation>();
            this.AllNotifications = new Array<Notifcation>();
        }

        getInstructions(): JQueryDeferred<FormType> {
            var result = $.Deferred();
            PL.Claims.APIs.FormTypeRepo.getInstructions(this.FormTypeID).done((data) => {                
                result.resolve(data);
            });
            return result;
        }

       getFormType(): JQueryDeferred<FormType> {
            var result = $.Deferred();
            PL.Claims.APIs.FormTypeRepo.getFormType(this.FormTypeID).done((data) => {
                result.resolve(data);
            });
            return result;
        }

       saveFormType(formType?: PL.Claims.Models.FormType): JQueryDeferred<number> {
           var result = $.Deferred();
           if (formType == null) {
               formType = this;
           }
           PL.Claims.APIs.FormTypeRepo.saveFormType(formType).done((data) => {
               result.resolve(data);
           });
           return result;
       }

       saveCategories(): JQueryDeferred<FormCategory[]> {
           var $result = $.Deferred();                        
           // since we don't want to keep internal (considering it's fake) we remove it before we save
           var filteredCategories: PL.Claims.Models.FormCategory[] = new Array<PL.Claims.Models.FormCategory>();

           this.FormCategories.forEach((fcO, fcI) => {
               if (fcO.CategoryName != 'Internal') {
                   filteredCategories.push(fcO);
               }
           });

           PL.Claims.APIs.FormTypeRepo.saveFormTypeCategories(filteredCategories).done((data) => {
               $result.resolve(data);
           });
           return $result;
       }

       getCategoriesByFormTypeID(): JQueryDeferred<FormCategory[]> {
           var $result = $.Deferred();
           PL.Claims.APIs.FormTypeRepo.getCategoriesByType(this.FormTypeID).done((data) => {
               $result.resolve(data);
           });
           return $result;
       }

       getCompleteForm(): JQueryDeferred<FormType> {
           var result = $.Deferred();
           PL.Claims.APIs.FormTypeRepo.getFormType(this.FormTypeID).done((formType) => {
               PL.Claims.APIs.FormTypeRepo.getCategoriesByType(this.FormTypeID).done((formCategories) => {
                   PL.Claims.APIs.FormTypeRepo.getFormFields(this.FormTypeID).done((formFields) => {

                       mW.classes.propagatePropertyValues(formType, this);

                       formCategories.forEach((fcO, fcI) => {
                           this.FormCategories.push(new FormCategory(fcO));
                       });

                       formFields.forEach((ffO, ffI) => {
                           this.FormFields.push(new FormField(ffO));
                       });

                       formType.AllNotifications.forEach((noteO, noteI) => {
                           this.AllNotifications.push(noteO);
                       });

                       formType.Notifications.forEach((noteO, noteI) => {
                           this.Notifications.push(noteO);
                       });

                       formType.AssociatedGroups.forEach((agO, agI) => {
                           this.AssociatedGroups.push(agO);
                       });       

                       // we need to create a fake category to store orphaned fields                       
                       var internalCat: PL.Claims.Models.FormCategory = new PL.Claims.Models.FormCategory;
                       internalCat.CategoryName = 'Internal';                       
                       internalCat.FormCategoryID = null;

                       if (this.FormCategories.length > 0) {
                           internalCat.SortOrder = (this.FormCategories[this.FormCategories.length - 1].SortOrder + 1);
                       } else {
                           internalCat.SortOrder = 0;
                       }

                       this.FormCategories.push(internalCat);

                       this.FormCategories.forEach((cO, cI, cat) => {

                           //cO.FormFields = new Array<PL.Claims.Models.FormField>();

                           this.FormFields.forEach((ffO, fI) => {
                               
                               if (cO.FormCategoryID == ffO.FormCategoryID) {

                                   cO.FormFields.push(ffO);

                               }

                               if (ffO.CategoryName == null) {
                                   // add to Internal
                                   cat[cat.length - 1].FormFields.push(ffO);

                               }

                           });

                       });                       
                       
                       console.log(this.FormCategories);
                       console.log(this.FormFields);    

                       // lets just set a default selected category
                       if (this.FormCategories.length > 0) {
                           this.FormCategory = this.FormCategories[0];
                       }

                       result.resolve(this);
                   });
               });
           });
           return result;

       }

       
       reconstructFieldCategoryRelationship(): JQueryDeferred<PL.Claims.Models.FormCategory[]> {
           var $result = $.Deferred();
           this.FormCategories.forEach((fcO, fcI) => {

               fcO.FormFields = new Array<PL.Claims.Models.FormField>();

               this.FormFields.forEach((ffO, ffI) => {

                   if (fcO.FormCategoryID == ffO.FormCategoryID) {
                       fcO.FormFields.push(new FormField(ffO));
                   }
                   
                   if (fcO.CategoryName == 'Internal') {
                       if (ffO.CategoryName == null) {
                           fcO.FormFields.push(new FormField(ffO));
                       }
                   }

               });

           });

           return $result.resolve(true);
       }

       public static validateCategories(categories: PL.Claims.Models.FormCategory[]): boolean {
           var isValid = false;
           for (var i = 0; i < categories.length; i++) {               

               var catName = categories[i].CategoryName;

               if (catName == undefined || catName == null || catName == '') {
                   isValid = false;
                   break;    
                } else {
                    isValid = true;
               }

               if (catName.trim() == '') {
                   isValid = false;
                   break;
               } else {
                   isValid = true;
               }

               if (categories[i].Status == 'New') {
                   categories.forEach((catO, catI) => {
                       if (catO.FormCategoryID != categories[i].FormCategoryID) {
                           if (catO.CategoryName == categories[i].CategoryName) {
                               isValid = false;
                           }
                       }                       
                   });
               }
           }
           return isValid;
       }
    }

} 