CHSI.UW.NarrativeEditor = {
    Init: function (QuoteID, NarrativeTypeID, NarrativeID) {
       
        this.Cache.QuoteID = QuoteID;
        this.Cache.NarrativeTypeID = NarrativeTypeID;
        if (NarrativeID == "undefined") {
            this.Cache.NarrativeID = 0;
            this.Cache.Editor = CKEDITOR.replace('jxNarrativeText', { height: '350px' });
        } else {
            this.Cache.NarrativeID = NarrativeID;
        }

        this.Events.Global();
        this.Calls.GetNarrative();
    },
    Templates: {},
    Cache: {
        QuoteID: 0,
        NarrativeTypeID: 0,
        NarrativeID: 0,
        NarrativeObject: {},
        Editor: null
    },
    BindPresentationLayer: function () {
        this.Cache.Editor = CKEDITOR.replace('jxNarrativeText', { height: '350px' });
        CHSI.UW.NarrativeEditor.Cache.Editor.setData(this.Cache.NarrativeObject.NarrativeValue);
    },
    Events: {
        Global: function () {
            $('#jxSave').on('click', function () {
                CHSI.UW.NarrativeEditor.Calls.Save();
                closeThis(true);
            });
            $('#jxCancel').on('click', function () {
                closeThis(true);
            });

        },
        PersistValues: function () {
            var Narrative = {};
            Narrative.NarrativeID = 0;
            Narrative.NarrativeTypeID = CHSI.UW.NarrativeEditor.Cache.NarrativeTypeID;
            Narrative.QuoteID = CHSI.UW.NarrativeEditor.Cache.QuoteID;
            Narrative.Status = 'Active';
            if (CHSI.UW.NarrativeEditor.Cache.NarrativeID) {
                Narrative = CHSI.UW.NarrativeEditor.Cache.NarrativeObject;
            }
            Narrative.NarrativeValue = CHSI.UW.NarrativeEditor.Cache.Editor.getData();
            return Narrative;
        },

    },
    Calls: {
        GetNarrative: function () {
            if (CHSI.UW.NarrativeEditor.Cache.NarrativeID) {
                var Options = {};

                Options.URL = '/api/UW/Narrative/GetQuoteNarrative?NarrativeID=' + CHSI.UW.NarrativeEditor.Cache.NarrativeID;
                Options.Success = function (e) {
                    CHSI.UW.NarrativeEditor.Cache.NarrativeObject = e;
                    CHSI.UW.NarrativeEditor.BindPresentationLayer();
                }
                CHSI.Common.Ajax.Get(Options);
            }
        },
        Save: function () {

            var Options = {};
            Options.URL = '/api/UW/Narrative/SaveQuoteNarrative';
            Options.Data = CHSI.UW.NarrativeEditor.Events.PersistValues();
            Options.Success = function (e) {

            }
            CHSI.Common.Ajax.Post(Options);
        },
    }
}