namespace PL.Claims.Controllers {

    export class ClaimIntakeStartV2Controller extends Abstract.ClaimIntakeFormBase {

        Notification: Claims.Controllers.ClaimIntakeNotificationV2Controller;
        HideLocation: boolean;

        constructor(intakeForm: PL.Claims.Models.IntakeForm) {
            super(intakeForm);
            this.ClaimIntakeForm = intakeForm;
        }

        init(): void {
            this.handlebarHelpers();
            this.bind().done(() => {
                this.setupEvents();
            });

            this.getLocationGroupSpec();
        }

        bind(): JQueryDeferred<any> {
            var $result = $.Deferred();
            mW.handlebars.bind(CHSITemplates.ClaimIntakeForm_Start, { Status: this.ClaimIntakeForm.Status, ClaimObject: this.ClaimIntakeForm }, this.$category_container);
            return $result.resolve(true);
        }

        setupEvents(): void {

            $('#jxIncidentDate').datepicker({
                showOn: "both",
                buttonImage: "/images/icons/cal.gif",
                buttonImageOnly: true,
            });

        }

        events(): void {
            var _self = this;
            this.$category_container.on('change', '#jxLocation', function () {
                _self.ClaimIntakeForm.IncidentReport.LocationID = $(this).val();
            });

            this.$category_container.on('change', '#jxIncidentDate', function () {
                _self.ClaimIntakeForm.IncidentReport.IncidentDate = $(this).val();
            });

        }

        isStartValid(): boolean {
            var isValid: boolean = false;

            if (!this.HideLocation && ($('#jxLocation').find(":selected").text() === '' || $('#jxLocation').find(":selected").text() === undefined || $('#jxLocation').find(":selected").text() === null)) {
                alert('Please select a location before continuing');
                isValid = false;
            }
            else if ($('#jxIncidentDate').val() === '' || $('#jxIncidentDate').val() === undefined || $('#jxIncidentDate').val() === null) {
                alert('Please select a Date of Incident before continuing.');
                isValid = false;
            } else {
                isValid = true;
            }

            return isValid;
        }

        handlebarHelpers(): void {
            var _self = this;

            Handlebars.registerHelper('StartIncidentDate', function (value) {

                var returnValue = null;
                if (value != null) {
                    returnValue = GUI.DateTime.formatDate(returnValue);
                } else {
                    returnValue = null;
                }

                return returnValue;
            });

        }

        getLocationGroupSpec(): any {
            var ajax = new IO.Ajax;
            var self = this;
            var settings = {
                URL: `/api/Common/GroupSpecification/GetGroupSpecificationByClientID?ClientID=${this.ClaimIntakeForm.ClientID}&SpecName=ClaimIntakeFormHideLocation`,
                Success: function (data) {
                    if (data == "1") {
                        self.hideLocationDropdown();
                    }
                },
                Error: function () {
                    console.log('An error occured calling GetGroupSpecificationByClientID API.  The location dropdown will not be hidden.');
                }
            };
            ajax.Get(settings);
        }
        hideLocationDropdown(): void {
            this.HideLocation = true;
            $('#jxLocation').closest("tr").hide();
        }
    }
} 