
CHSI.Forms.Actions = new Object();

CHSI.Forms.Actions.ProcessActions = function (Actions) {
    /* we need to adjust the actionformulae to be jQuery specific*/
    jQuery(Actions).each(function (index, behavior) {
        behavior.ActionFormula = behavior.ActionFormula.replace(/(\{.*\}) in \((.*)\)/, '"$2".indexOf(\'"\' + $1 + \'"\') >= 0');
        behavior.ActionFormula = behavior.ActionFormula.replace(CHSI.Forms.ResultTagRegEx, "CHSI.Forms.Results.GetValueByTag('$1', " + CHSI.Forms.PrimaryFormID + ")");
        behavior.ActionFormula = behavior.ActionFormula.replace(/ = /g, ' == ');
        behavior.ActionFormula = behavior.ActionFormula.replace(/ and /g, ' && ');
        behavior.ActionFormula = behavior.ActionFormula.replace(/ or /g, ' || ');
        if (CHSI.Forms.DebugMode) {
            CHSI.Common.Log('Creating Action Formula - ' + behavior.ActionFormula);
        }
    });
    return Actions;
}

CHSI.Forms.Actions.ExecuteFormActions = function () {

    jQuery(CHSI.Forms.ActionCollection).each(function (index, behavior) {
        if (behavior.ActionFormula != '') {
            var Result = eval(behavior.ActionFormula);
            if (CHSI.Forms.DebugMode) {
                CHSI.Common.Log(behavior.ActionFormula + ' result = ' + Result + ', target tag: ' + behavior.TargetTag);
            }
            var NewValue = null;

            NewValue = (Result == true) ? behavior.TargetTrueValue : behavior.TargetFalseValue;

            if (behavior.TargetObjectType == 14) {
                CHSI.Forms.Elements.ChangeElementProperty(behavior.TargetTag, behavior.PropertyName, NewValue, behavior.TargetObjectID);
            }
            else if (behavior.TargetObjectType == 15) {
                CHSI.Forms.Elements.ChangeSectionProperty(behavior.TargetTag, behavior.PropertyName, NewValue, behavior.TargetObjectID);
            }


        }
        else if (behavior.ComparisonType == 9) {
            var ColumnID = behavior.ParentObjectID;
            var Sum = 0;
            if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].isDisabled == false)
            {
                jQuery(CHSI.Forms.ResultSetValues).each(function (resultindex, resultitem) {
                    if (resultitem.StructureColumnID == ColumnID) {
                        var Value = 0;
                        if (resultitem.DataTypeID == 5) {
                            Value = resultitem.IntegerValue;
                        }
                        else if (resultitem.DataTypeID == 3) {
                            Value = resultitem.DecimalValue;
                        }
                        Sum = CHSI.Math.SafeAdd(Sum, Value); /* Force value to be a number */
                    }
                });
                if (CHSI.Forms.DebugMode) {
                    CHSI.Common.Log('change element ' + '#jxElement_' + behavior.TargetObjectID + '_0');
                }
                var TargetElement = CHSI.DOM.Get('#jxElement_' + behavior.TargetObjectID + '_0');
                if (TargetElement.length > 0) {
                    if (TargetElement.val() != Sum) {
                        /*Retrieve any object for formatting purposes*/
                        var objResultValue = null;
                        jQuery(CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].Elements).each(function (index, FormElement) {
                            if (FormElement.ElementID == behavior.TargetObjectID ) {
                                objResultValue = FormElement;
                                return false;
                            }
                        });
                        

                        if (objResultValue)
                        {
                            Sum = CHSI.Forms.Elements.formatValue(objResultValue, Sum);
                        }
                        


                        TargetElement.val(Sum);


                        TargetElement.trigger('change');
                    }
                }

            }
       

        }

    });
    jQuery('#FooterNav').html(CHSI.Forms.Navigation.GetFooterNavHTML());
};
