CHSI.Que.DataStructureFields = {
    Init: function (setup) {

        this.Cache.DataStructureID = setup.StructureID;

        this.Calls.GetFields(function () {


            CHSI.Que.DataStructureFields.Calls.GetContentTypes(function () {
                CHSI.Que.DataStructureFields.Bind();
                CHSI.Que.DataStructureFields.Cache.GridTable.search('').draw();
            });
        });


        this.Events.Global();
    },
    Utilities: {
        GetNewField: function (FieldName) {
            var NewObject = {};
            NewObject.FieldName = FieldName;
            NewObject.DataTypeID = 1;
            NewObject.CalculationFormula = "";
            NewObject.CalculationProcessOrder = 0;
            NewObject.DataCategoryID = CHSI.Que.DataStructureCategories.Utilities.GetCategories()[0].CategoryID;
            NewObject.DataStructureID = CHSI.Que.DataStructureFields.Cache.DataStructureID;
            NewObject.Description = '';
            NewObject.SortOrder = 0;

            return NewObject;
        }
    }
    , Cache: {
        DataStructureID: null,
        FieldsGrid: null,
        GridTable: null,
        ContentTypes: null
    },
    Bind: function () {
        $('#jxStructureFieldsContainer').html('<table width="100%" class="dtable" id="jxDataStructureFieldsGrid"><tfoot></tfoot></table>');

        var DataSet = null;
        var ClientColumnDefinition = {};
        var columnDefs = [];
        var Column = null;
        var CustomColumns = [];

        if (CHSI.Que.DataStructureFields.Cache.FieldsGrid) {
            DataSet = CHSI.Que.DataStructureFields.Cache.FieldsGrid;
        }

        Column = {};
        Column.title = "Field Name";
        Column.data = null;
        Column.orderable = true;
        Column.class = 'details-control';

        CustomColumns.push(Column);

        Column = {};
        Column.data = null;
        Column.class = 'details-control';
        Column.orderable = false;
        CustomColumns.push(Column);

        var ClientColumnDefinition = {};
        ClientColumnDefinition.targets = 0;

        ClientColumnDefinition.render = function (data, type, row) {
            return row[18] + '.' + row[9];
        }
        columnDefs.push(ClientColumnDefinition);

        var ClientColumnDefinition = {};
        ClientColumnDefinition.targets = 1;
        ClientColumnDefinition.render = function (data, type, row) {
            return '<span class="ui-icon ui-icon-triangle-1-s" style="float: right;">Edit</span>';
        }
        columnDefs.push(ClientColumnDefinition);

        if (DataSet.Values != null) {
            CHSI.Que.DataStructureFields.Cache.GridTable = $('#jxDataStructureFieldsGrid').DataTable({
                aaData: DataSet.Values,
                columns: CustomColumns,
                columnDefs: columnDefs,
                stateSave: true
            })
        } else {
            var HTML = [];
            $('#jxDataStructureFieldsGrid').html('<tr><td>No fields found.</td></tr>')
        }

        $('#jxLoading').hide();
    },
    Events: {
        Global: function () {
            $('#jxStructureFieldsContainer').on('click', '#jxDataStructureFieldsGrid tbody td.details-control span', function () {
                var tr = $(this).closest('tr');
                var row = CHSI.Que.DataStructureFields.Cache.GridTable.row(tr);

                if (row.child.isShown()) {
                    row.child.hide();
                    tr.removeClass('ui-icon-triangle-1-n');
                } else {
                    row.child(FieldDetailFormat(row)).show();
                    tr.addClass('ui-icon-triangle-1-s');
                }
            });

            $('#tabStructureFields').on('input change', 'input:not(:#jxNewFieldName):not(#jxDataStructureFieldsGrid_filter > label > input), select', function () {
                var Row = $(this).data('row');
                var Column = $(this).data('columnid');
                var Index = $(this).data('index')*1;
                var DetailRow = '.jxFieldRow_' + Row;
                var ValueType = $(this).val();

                if (Index == 13 || Index == 14) {
                    ValueType = $(this).is(':checked') == true ? 1 : 0;
                }


                var CellValue = CHSI.Que.DataStructureFields.Cache.GridTable.cell({ row: Row, column: 0 }).data();
                CellValue[Index] = ValueType;
                CHSI.Que.DataStructureFields.Cache.GridTable.cell({ row: Row, column: 0 }).data(CellValue);
              
                CHSI.Que.DataStructureFields.Events.SaveFieldValues(DetailRow);
            });

            //$('#jxStructureFieldsContainer').on('change', 'select, input[type="checkbox"]', function () {
            //    var Row = $(this).data('row');
            //    var DetailRow = '.jxFieldRow_' +Row;
            //    CHSI.Que.DataStructureFields.Events.SaveFieldValues(DetailRow);
            //});

            $('#jxAddField').off().on('click', function () {
                if (CHSI.Que.DataStructureCategories.Utilities.GetCategories().length > 0) {
                    var FieldName = $('#jxNewFieldName').val();
                    if (FieldName == '')
                    {
                        alert('please enter a field name');
                        return false;
                    }
                    CHSI.Que.DataStructureFields.Calls.SaveFields(CHSI.Que.DataStructureFields.Utilities.GetNewField($('#jxNewFieldName').val()), function () {
                        CHSI.Que.DataStructureFields.Cache.GridTable.search(FieldName).draw();
                        $('#jxNewFieldName').val('');
                        CHSI.Que.DataStructureFields.Calls.GetFields(function () {
                                CHSI.Que.DataStructureFields.Bind();
                        });
                    });

                }
                else {
                    alert('Please add one or more categories before adding a field');
                }


            });
        },

        GenerateHTMLSelect: function (displayArray, displayArrySecondaryPos, valueArray, valueArrySecondaryPos, selectValue, ClassName, dataAtt) {
            var HTML = [];
            var arrayValue, arrayDisplay;

            if (dataAtt == undefined) {
                dataAtt = '';
            }

            if (ClassName == undefined) {
                ClassName = null;
            }

            if (selectValue == undefined) {
                selectValue = null;
            }

            if (valueArrySecondaryPos == undefined) {
                valueArrySecondaryPos = null;
            }

            if (displayArrySecondaryPos == undefined) {
                displayArrySecondaryPos = null;
            }

            if (valueArray == undefined) {
                valueArray = displayArray
            }

            HTML.push('<select class="' + ClassName + '"' + dataAtt + ' >');

            for (index = 0; index < displayArray.length; index++) {

                arrayDisplay = displayArrySecondaryPos == null ? displayArray[index] : displayArray[index][displayArrySecondaryPos];
                arrayValue = valueArrySecondaryPos == null ? valueArray[index] : valueArray[index][valueArrySecondaryPos];

                if (arrayValue != selectValue) {
                    HTML.push('<option value="' + arrayValue + '">' + arrayDisplay + '</option>');
                } else {
                    HTML.push('<option value="' + arrayValue + '" selected>' + arrayDisplay + '</option>');
                }
            }
            HTML.push('</select>');
            return HTML.join('');
        },
        GenerateHTMLCheckbox: function (trueValue, isBit, ClassName, dataAtt) {
            var HTML = [];

            if (dataAtt == undefined) {
                dataAtt = '';
            }

            if (ClassName == undefined) {
                ClassName = null;
            }

            if (isBit) {
                trueValue = (trueValue * 1);
                if (trueValue == 1) {
                    trueValue = true;
                } else {
                    trueValue = false;
                }
            }

            if (trueValue) {
                HTML.push('<input type="checkbox" class="' + ClassName + '" ' + dataAtt + ' checked="checked" />');
            } else {
                HTML.push('<input type="checkbox" class="' + ClassName + '" ' + dataAtt + ' />');
            }
            return HTML;
        },
        SaveFieldValues: function (Row) {
            var FieldObject = {};
            var MetaAttributeObject = {};

            FieldObject.MetaAttributes = [];
            CHSI.Common.Log('here');
            CHSI.Common.Log($(Row + ' .jxFieldName'));
            FieldObject.ColumnID = $(Row).data('columnid');
            FieldObject.DataCategoryID = $(Row + ' .jxCategory').val();
            FieldObject.DataStructureID = (CHSI.Que.DataStructureFields.Cache.DataStructureID * 1);
            FieldObject.DataTypeID = ($(Row + ' .jxDataType').val() * 1);
            FieldObject.DataSubCategoryID = $(Row + ' .jxExportCategory').val();
            FieldObject.DefaultStringValue = (FieldObject.DataTypeID == 1 ? $(Row + ' .jxDefaultValue').val() : null);
            FieldObject.DefaultDateValue = (FieldObject.DataTypeID == 2 ? $(Row + ' .jxDefaultValue').val() : null);
            FieldObject.DefaultDecimalValue = (FieldObject.DataTypeID == 3 ? $(Row + ' .jxDefaultValue').val() : null);
            FieldObject.DefaultBitValue = (FieldObject.DataTypeID == 4 ? $(Row + ' .jxDefaultValue').val() : null);
            FieldObject.DefaultIntegerValue = (FieldObject.DataTypeID == 5 ? $(Row + ' .jxDefaultValue').val() : null);
            FieldObject.DefaultValue = $(Row + ' .jxDefaultValue').val();
            FieldObject.SortOrder = $(Row + ' .jxSortOrder').val();
            FieldObject.DefaultValueFormula = $(Row + ' .jxDefaultFormula').val();
            FieldObject.CalculationFormula = $(Row + ' .jxCalculationFormula').val();
            FieldObject.CalculationProcessOrder = $(Row + ' .jxProcessOrder').val();
            FieldObject.FieldName = $(Row + ' .jxFieldName').val();
            FieldObject.Description = $(Row + ' .jxDescription').val();
            FieldObject.MetaAttributes.push({ MetaType: 5, MetaValue: $(Row + ' .jxContentType').val() * 1, MetaIndex: 0, Status: 'active' });
            FieldObject.MetaAttributes.push({
                MetaType: 25, MetaValue: $(Row + ' .jxDefaultPrior').is(':checked') == true ? 1 : 0, MetaIndex: 0, Status: 'active'
            });
            FieldObject.MetaAttributes.push({
                MetaType: 29, MetaValue: $(Row + ' .jxRequiresReview').is(':checked') == true ? 1 : 0, MetaIndex: 0, Status: 'active'
            });

         
            CHSI.Que.DataStructureFields.Calls.SaveFields(FieldObject);
            
        }
    },
    Calls: {
        GetFields: function (successFunction) {
            var Options = {};
            Options.URL = '/api/Que/DataStructureField/Get?DataStructureID=' + CHSI.Que.DataStructureFields.Cache.DataStructureID;
            Options.Success = function (data) {
                CHSI.Que.DataStructureFields.Cache.FieldsGrid = data;
                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        GetContentTypes: function (successFunction) {
            var Options = {};
            Options.URL = '/api/Common/ContentType/Get';
            Options.Success = function (data) {
                CHSI.Que.DataStructureFields.Cache.ContentTypes = data;

                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },

        GetSubCategories: function (successFunction) {
            var Options = {};
            Options.URL = '/api/Que/DataStructureSubCategory/Get?DataStructureID=' + CHSI.Que.DataStructureFields.Cache.DataStructureID;
            Options.Success = function (data) {
                CHSI.Que.DataStructureFields.Cache.SubCategories = data;
                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        SaveFields: function (field, successFunction) {
            var Options = {};
            Options.URL = '/api/Que/DataStructureField/Save';
            Options.Data = field;
            Options.Success = function (data) {

                if (successFunction) {
                    successFunction();
                }
            };
            Options.Error = function (xhr, status, error) {
                var err = eval("(" + xhr.responseText + ")");
                alert(err.ExceptionMessage);
            }
            CHSI.Common.Ajax.Post(Options);
        }
    }
}

function FieldDetailFormat(RowObject) {
    var ValueData = RowObject.data();
    var RowIndex = RowObject.index();
    var HTML = [];

    HTML.push('<table class="jxFieldRow_' + RowIndex + '" data-columnid="' + ValueData[0] + '" data-categoryid="' + ValueData[2] + '">' +
                    '<tr>' +
                        '<td>Name:</td>' +
                        '<td><input type="text" readonly="true" class="jxFieldName" data-columnid="' + ValueData[0] + '" data-index="9" data-row="' + RowIndex + '" value="' + ValueData[9] + '" /></td>' +
                        '<td>Description:</td>' +
                        '<td><input type="text" class="jxDescription"  data-columnid="' + ValueData[0] + '" data-index="10"  data-row="' + RowIndex + '" value="' + ValueData[10] + '" /></td>' +
                    '</tr>' +
                    '<tr>');
    HTML.push('<td>Category:</td>');

    HTML.push('<td><select class="jxCategory"  data-row="' + RowIndex + '"  data-columnid="' + ValueData[0] + '" data-index="2" >' + CHSI.GUI.BuildSelectItemHTML(CHSI.Que.DataStructureCategories.Utilities.GetCategories(), 'CategoryTitle', 'CategoryID', ValueData[2]) + '</select></td>');
    HTML.push('<td>Secondary Export Category:</td>');
    HTML.push('<td><select class="jxExportCategory" data-row="' + RowIndex + '"  data-columnid="' + ValueData[0] + '" data-index="17" >' + CHSI.GUI.BuildSelectItemHTML(CHSI.Que.DataStructureSubCategories.Utilities.GetSubCategories(), 'SubCategoryTitle', 'SubCategoryID', ValueData[17]) + '</select></td>');
    HTML.push('</tr>' +
               '<tr>' +
                    '<td>Data Type:</td>');
    HTML.push('<td>' + CHSI.Que.DataStructureFields.Events.GenerateHTMLSelect(['String', 'Date', 'Decimal', 'Boolean', 'Integer', 'File'], null, ["1", "2", "3", "4", "5", "6"], null, ValueData[1], 'jxDataType', 'data-row="' + RowIndex + '"  data-columnid="' + ValueData[0] + '" data-index="1" ') + '</td>');
    HTML.push('<td>Content Type:</td>');
    HTML.push('<td>' + CHSI.Que.DataStructureFields.Events.GenerateHTMLSelect(CHSI.Que.DataStructureFields.Cache.ContentTypes, 'CssClass', CHSI.Que.DataStructureFields.Cache.ContentTypes, 'ContentTypeID', ValueData[12], 'jxContentType', 'data-row="' + RowIndex + '"  data-columnid="' + ValueData[0] + '" data-index="12" ') + '</td>');
    HTML.push('</tr>' +
               '<tr>' +
                     '<td>Export Sort Order:</td>' +
                     '<td><input type="number" class="jxSortOrder" data-row="' + RowIndex + '"  data-columnid="' + ValueData[0] + '" data-index="11"  value="' + ValueData[11] + '"/></td>' +
                     '<td>Default From Prior:</td>');
    HTML.push('<td>' + CHSI.Que.DataStructureFields.Events.GenerateHTMLCheckbox(ValueData[13], true, 'jxDefaultPrior', 'data-row="' + RowIndex + '"  data-columnid="' + ValueData[0] + '" data-index="13" value="1" ') + '</td>');
    HTML.push('</tr>' +
       '<tr>' +
          '<td>Default Value:</td>' +
          '<td><input type="text" class="jxDefaultValue" data-row="' + RowIndex + '"  data-columnid="' + ValueData[0] + '" data-index="19"  value="' + ValueData[19] + '" /></td>' +
          '<td>Default value Formula:</td>' +
          '<td><input type="text" class="jxDefaultFormula" data-row="' + RowIndex + '"  data-columnid="' + ValueData[0] + '" data-index="8"  value="' + ValueData[8] + '" /></td>' +
      '</tr>' +
       '<tr>' +
          '<td>Requires Review:</td>');
    HTML.push('<td>' + CHSI.Que.DataStructureFields.Events.GenerateHTMLCheckbox(ValueData[14], true, 'jxRequiresReview', 'data-row="' + RowIndex + '"  data-columnid="' + ValueData[0] + '" data-index="14" value="1" ') + '</td>');
    HTML.push('<td>Calculation Formula:</td>' +
              '<td><input type="text" class="jxCalculationFormula" data-row="' + RowIndex + '"  data-columnid="' + ValueData[0] + '" data-index="16"  value="' + ValueData[16] + '" /></td>' +
          '</tr>' +
           '<tr>' +
           '<td></td>' +
                '<td></td>' +
              '<td>Calculation Order</td>' +
              '<td><input type="text" class="jxProcessOrder" data-row="' + RowIndex + '"  data-columnid="' + ValueData[0] + '" data-index="15"  value="' + ValueData[15] + '" /></td>' +
          '</tr>' +
   '</table>');
    return HTML.join('');
}