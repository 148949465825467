module PL.Que.Models.Data {

    export class DataStructureCategory {

        CategoryID: number;
        DataStructureID: number;
        CategoryTitle: string;
        status: string;
        StructureType: any;
        SortOrder: number;
        CreateUser: string;
        Columns: PL.Que.Models.Data.DataStructureColumn[] = new Array<PL.Que.Models.Data.DataStructureColumn>();
        CreateTmsp: string | Date;
        UpdateUser: string;
        UpdateTmsp: string | Date;

    }

}