var Template_GroupNoteEditor = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <option value=\""
    + escapeExpression(((helper = (helper = helpers.Value || (depth0 != null ? depth0.Value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Value","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.Key || (depth0 != null ? depth0.Key : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Key","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "﻿<table width=\"100%\">\r\n    <tr>\r\n        <td>\r\n            Subject\r\n        </td>\r\n        <td>\r\n            <input id=\"uxMessageHeader\" type=\"text\" />\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>\r\n            Category\r\n        </td>\r\n        <td>\r\n            <select id=\"uxCategory\">\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.CategoryType : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </select>\r\n        </td>\r\n        <td>\r\n            <input id=\"uxIsPrivate\" type=\"checkbox\" />Private\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td valign=\"top\">\r\n            Message\r\n        </td>\r\n        <td>\r\n            <textarea id=\"uxMessageBody\" rows=\"10\" cols=\"20\"></textarea>\r\n        </td>\r\n    </tr>\r\n</table>\r\n<div>\r\n    <input id=\"uxSubmit\" type=\"button\" value=\"OK\" />\r\n    <input id=\"uxCancel\" type=\"button\" value=\"Cancel\" onclick=\"closeThis(true);\" />\r\n</div>";
},"useData":true});