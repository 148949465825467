CHSI.Page.ScheduleGrid = {
    Settings: {
        isPortal: false
        , IncludeClientColumn: true
        ,ClientID:null
    }
    , Init: function (Settings) {
        if (Settings) {
            if (Settings.isPortal != null)
                this.Settings.isPortal = Settings.isPortal;
            if (Settings.IncludeClientColumn != null)
                this.Settings.IncludeClientColumn = Settings.IncludeClientColumn;
            if (Settings.ClientID != null)
                this.Cache.ClientID = Settings.ClientID;
        }
         
        var HashObject = CHSI.DOM.CreateHashObject(location.hash);
        this.Templates.ScheduleGroupGrid = CHSITemplates.ScheduleGridView;
        this.Events.Global();
    },
    Templates: {
    },
    Cache: {
        ScheduleGroupGridObject: null
       , StatusType: 3
       , Total: 0
       , SummaryEnabled: false
       , isPortal: false
        , ExportReportPath: null
        , ClientID: null
        , GroupID: null
        , GridTable: null
        , CurrentURLParameters: null
    },
    BindPresentationLayer: function () {        
        setTimeout(function () {
            $('#jxPageMainContent').html(CHSI.Page.ScheduleGrid.Templates.ScheduleGroupGrid({ ScheduleCategory: CHSI.Page.ScheduleItemSummary.Settings.TabName }));
            $('#jxLinks').html(CHSI.Page.ScheduleItemSummary.Templates.Links({ Details: true, ScheduleTypeID: CHSI.Page.ScheduleItemSummary.Settings.ScheduleTypeID }));
            if (CHSI.Page.ScheduleGrid.Settings.isPortal) {
                $('#jxExportLinks').html(CHSI.Page.ScheduleItemSummary.Templates.ExportLinks({ Details: true, ScheduleTypeID: CHSI.Page.ScheduleItemSummary.Settings.ScheduleTypeID }));
            }

            var DataSet = null;

            if (CHSI.Page.ScheduleGrid.Cache.ScheduleGroupGridObject) {
                DataSet = CHSI.Page.ScheduleGrid.Cache.ScheduleGroupGridObject.Values;
            
                var Columns = [];
                for (var i = 0; i < CHSI.Page.ScheduleGrid.Cache.ScheduleGroupGridObject.Columns.length; i++) {
                    var Column = {};
                    var SourceColumn = CHSI.Page.ScheduleGrid.Cache.ScheduleGroupGridObject.Columns[i];
                    Column.title = SourceColumn.title;
                    Column.visible = SourceColumn.visible;

                    if (Column.title == 'Client') {
                        if (CHSI.Page.ScheduleGrid.Settings.IncludeClientColumn == false) {
                            Column.visible = false;
                        }
                        Column.class = 'client-control';
                    }
                
                    Columns.push(Column);
                }
                var Column = {}
                Column.title = "";
                Column.data = null;
                Column.orderable = false;
                Column.class = 'details-control';
                Column.defaultContent = '[<a href="#" class="minilink">view</a>]';
                Columns.push(Column);
                var columnDefs = [];
                var ClientColumnDefinition = {};
                ClientColumnDefinition.targets = 2;
                ClientColumnDefinition.render = function (data, type, row) {
                    return "<a href='/clientmain.aspx?clientid=" + row[1] + "'>" + data + '</a>';
                }
                columnDefs.push(ClientColumnDefinition);
                /*Build Totals Object*/
                var TotalObj = {};
                CHSI.Page.ScheduleGrid.Cache.SummaryEnabled = false;

                if (CHSI.Page.ScheduleGrid.Cache.ScheduleGroupGridObject.Columns.length) {
                    for (var x = 0; x < CHSI.Page.ScheduleGrid.Cache.ScheduleGroupGridObject.Columns.length; x++) {
                        if (CHSI.Page.ScheduleGrid.Cache.ScheduleGroupGridObject.Columns[x].Summarized) {
                            CHSI.Page.ScheduleGrid.Cache.SummaryEnabled = true;
                        }
                    }
                    if (CHSI.Page.ScheduleGrid.Cache.SummaryEnabled) {
                        TotalObj.TotalCells = [];
                        TotalObj.Comments = '###Total###';
                        var TotalLabelDisplayed = false;
                        for (var x = 0; x < CHSI.Page.ScheduleGrid.Cache.ScheduleGroupGridObject.Columns.length; x++) {
                            var TotalDisplay = '';
                            if (CHSI.Page.ScheduleGrid.Cache.ScheduleGroupGridObject.Columns[x].isVisible) {
                                if (TotalLabelDisplayed == false) {
                                    TotalDisplay = 'Totals:';
                                    TotalLabelDisplayed = true;
                                }
                                else if (CHSI.Page.ScheduleGrid.Cache.ScheduleGroupGridObject.Columns[x].Summarized) {
                                    var TotalAmount = 0;
                                    if (CHSI.Page.ScheduleGrid.Cache.ScheduleGroupGridObject.Columns[x].DataType == 5 || CHSI.Page.ScheduleGrid.Cache.ScheduleGroupGridObject.Columns[x].DataType == 3) {
                                        for (var y = 0; y < CHSI.Page.ScheduleGrid.Cache.ScheduleGroupGridObject.Values.length; y++) {
                                            TotalAmount = CHSI.Math.SafeAdd(TotalAmount, CHSI.Page.ScheduleGrid.Cache.ScheduleGroupGridObject.Values[y][x]);
                                        }
                                    }
                                    else {
                                        TotalAmount = CHSI.Page.ScheduleGrid.Cache.ScheduleGroupGridObject.Values.length;
                                    }
                                    TotalDisplay = TotalAmount;
                                }
                                TotalObj.TotalCells.push(TotalDisplay);
                            }
                        }
                        TotalObj.TotalCells.push('');/*negate the last column*/
                    }
                }
                var FooterRenderFunction = null;
                if (CHSI.Page.ScheduleGrid.Cache.SummaryEnabled) {
                    FooterRenderFunction = function (nRow, aaData, iStart, iEnd, aiDisplay) {
                        var footer = $('#jxScheduleGrid tfoot');
                        footer.html('');
                        var tr = $('<tr></tr>');
                        for (var i = 0; i < TotalObj.TotalCells.length; i++) {
                            tr.append('<td>' + TotalObj.TotalCells[i] + '</td>');
                        }

                    
                        footer.append(tr);
                    

                    }
                
                }
            }

            if (DataSet != null) {
				//CHSI.Page.ScheduleGrid.Cache.GridTable = $('#jxScheduleGrid').DataTable({
				//	data: DataSet,
				//	columns: Columns,
				//	columnDefs: columnDefs,
				//	fnFooterCallback: FooterRenderFunction,
				//	stateSave: true
				//});
				DataSet.Values = DataSet;
				DataSet.Columns = Columns;
				CHSI.Page.ScheduleGrid.Cache.GridTable = mW.DataTableHelpers.BuildDataTableWithHeaders('jxScheduleGrid', DataSet, '', 600, true);
				$('#jxScheduleGrid tr.odd td').css('font-size', '9pt');
            } else {
                var HTML = [];                
                $('#jxScheduleGrid').html('<tr><td>No results found.</td></tr>')
            }
            
            
            SetSize();
            CHSI.Page.ScheduleGrid.Events.GridEvents();

            if (CHSI.Page.ScheduleGrid.Cache.CurrentURLParameters == null) {
                CHSI.Page.ScheduleGrid.Cache.CurrentURLParameters = location.hash;
            }

            location.hash = CHSI.Page.ScheduleGrid.Cache.CurrentURLParameters;

            $('.header').each(function () {
                var index = $(this).data('index');
                var width = $(this).width();
                $('#jxTotal_' + index).width(width);
            });

            if (CHSI.Page.ScheduleGrid.Cache.StatusType == 2) {
                $('#jxFilterPending').prop('checked', true);
            }

            if (CHSI.Page.ScheduleGrid.Cache.StatusType == 3) {
                $('#jxFilterActive').prop('checked', true);
            }
            if (CHSI.Page.ScheduleGrid.Cache.StatusType == 4) {
                $('#jxFilterInactive').prop('checked', true);
            }
            if (CHSI.Page.ScheduleGrid.Cache.StatusType == 7) {
                $('#jxFilterAll').prop('checked', true);
            }

        $('#jxLoading').hide();
        }, 1000);
    },
    BuildReportPath: function (ExportAll) {
        if (CHSI.Page.ScheduleGrid.Cache.ExportReportPath === undefined) {
            alert('invalid report path configured, check groupspec "Schedule Report Path"');
        }

        return '/CHSIFileHandler.axd?ReportName=' + CHSI.Page.ScheduleGrid.Cache.ExportReportPath + '&ReportType=5&ReportOptions=ScheduleTypeID%3D' + CHSI.Page.ScheduleItemSummary.Settings.ScheduleTypeID + '%26GroupID%3D' + CHSI.Page.ScheduleItemSummary.Settings.GroupFilter + '%26ClientID%3D' + ((CHSI.Page.ScheduleItemSummary.Settings.ClientID == "null" || CHSI.Page.ScheduleItemSummary.Settings.ClientID == null) ? 0 : CHSI.Page.ScheduleItemSummary.Settings.ClientID) + '%26ExportAll%3D' + (ExportAll ? 1 : 0);
    }
    , Events: {
        GridEvents: function () {
            $('#jxExport').off('click').on('click', function () {
                window.open(CHSI.Page.ScheduleGrid.BuildReportPath(false));
            });

            $('#jxExportAllFields').off('click').on('click', function () {
                window.open(CHSI.Page.ScheduleGrid.BuildReportPath(true));
            });

            $('#jxNewScheduleItem').off('click').on('click', function () {
                var qs = jQuery.parseQuerystring();
                var ScheduleID = $(this).data('scheduleid');
                if (CHSI.Page.ScheduleGrid.Cache.CurrentURLParameters == null) {
                    CHSI.Page.ScheduleGrid.Cache.CurrentURLParameters = location.hash;
                } else {
                    location.hash = CHSI.Page.ScheduleGrid.Cache.CurrentURLParameters;
                }
                var HashObject = CHSI.DOM.CreateHashObject(location.hash);
                var Name = decodeURI(HashObject.TabName);
                var Category = decodeURI(HashObject.TabName);
                var DefaultImageID = HashObject.DefaultImageID;

                CHSI.Page.ScheduleGrid.Events.ShowScheduleItemDetails(ScheduleID, null, Name, Category, CHSI.Page.ScheduleGrid.Cache.isPortal, DefaultImageID, CHSI.Page.ScheduleGrid.Cache.ClientID);
            });
        }
        , Global: function () {
            $('#jxPageHeader').html();
            /*Grid delegate event*/
            $('#jxPageMainContent').on('click', '#jxScheduleGrid td.details-control', function () {
                var tr = $(this).closest('tr');
                var row = CHSI.Page.ScheduleGrid.Cache.GridTable.row(tr);               
                var ScheduleItemID = row.data()[0];
                CHSI.Common.Log(row.data())
                CHSI.Common.Log('ScheduleItem ' + ScheduleItemID);
                if (CHSI.Page.ScheduleGrid.Cache.CurrentURLParameters == null) {
                    CHSI.Page.ScheduleGrid.Cache.CurrentURLParameters = location.hash;
                } else {
                    location.hash = CHSI.Page.ScheduleGrid.Cache.CurrentURLParameters;
                }
                var HashObject = CHSI.DOM.CreateHashObject(location.hash);
                var Name = HashObject.TabName;
                var Category = HashObject.ScheduleCategory;
                var isPortal = CHSI.Page.ScheduleGrid.Settings.isPortal;
                CHSI.Page.ScheduleGrid.Events.ShowScheduleItemDetails(CHSI.Page.ScheduleItemSummary.Settings.ScheduleTypeID, ScheduleItemID, Name, Category, isPortal, null, CHSI.Page.ScheduleGrid.Cache.ClientID);
            });
   


            $('#jxPageMainContent').on('click', '#jxBack', function () {
                var href = $(this).attr('href');
                CHSI.Page.ScheduleItemSummary.GetFiltersFromURL(href);
                CHSI.Page.ScheduleItemSummary.BindTabs();
                CHSI.Page.ScheduleItemSummary.DeterminePageMode();
            });

            $('#jxPageMainContent').on('click', '.Filter', function () {

                CHSI.Page.ScheduleGrid.Cache.StatusType = 0;
                if ($(this).is(':checked')) {
                    CHSI.Page.ScheduleGrid.Cache.StatusType = $(this).val();
                }                
                CHSI.Page.ScheduleGrid.Calls.GetSummary();                
            });

            
        }
        , ShowScheduleItemDetails: function (ScheduleTypeID, ScheduleItemID, Name, Category, isPortal, DefaultImageID, ClientID) {
            var QueryString = '?ScheduleTypeID=' + ScheduleTypeID + '&isPortal=' + isPortal;

            if (ScheduleItemID) {
                QueryString = QueryString + '&ScheduleItemID=' + ScheduleItemID + '&Name=' + Name + '&Category=' + Category;
            }
            if (DefaultImageID) {
                QueryString = QueryString + '&DefaultImageID=' + DefaultImageID;
            }
            if (ClientID) {
                QueryString = QueryString + '&ClientID=' + ClientID;
            }
            var URL = '/UW/Modals/ScheduleItemForm.aspx' + QueryString;
            ShowPopup('ScheduleItemForm', URL, 'Schedule Item - ' + decodeURI(Category.replace('%26', "&")) + ' - ' + decodeURI(Name.replace('%26', "&")), 720, 570, RefreshGrid, true);
        }
    },
    Calls: {
        GetSummary: function () {
            $('#jxLoading').show();
            var isPortal = CHSI.Page.ScheduleGrid.Settings.isPortal;
            var DisplayType = CHSI.Page.ScheduleItemSummary.Settings.DisplayType;
            var ScheduleTypeID = CHSI.Page.ScheduleItemSummary.Settings.ScheduleTypeID;
            var ClientID = CHSI.Page.ScheduleItemSummary.Settings.ClientID;

            var GroupID = CHSI.Page.ScheduleItemSummary.Settings.GroupFilter
            var StatusType = CHSI.Page.ScheduleGrid.Cache.StatusType;
            CHSI.CRM.GetGroupSpec(GroupID, 'Schedule Report Path', function (groupspecValue) {
                CHSI.Page.ScheduleGrid.Cache.ExportReportPath = groupspecValue;
                var AjaxURL = "/api/UW/ScheduleItem/GetSummary2?DisplayType=" + DisplayType + "&StatusType=" + StatusType + "&ClientID=" + ClientID + "&GroupID=" + GroupID + "&ScheduleTypeID=" + ScheduleTypeID + "&isPortal=" + isPortal;
                $.ajax({
                    type: "GET",
                    url: AjaxURL,
                    cache: false,
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    success: function (e) {                     
                        CHSI.Page.ScheduleGrid.Cache.ScheduleGroupGridObject = e;
                        CHSI.Page.ScheduleGrid.BindPresentationLayer();
                    },
                    error: function (e) {
                        CHSI.Common.Log('no data to return');
                    }
            });
            });
        }
    }
}

function RefreshGrid() {
    CHSI.Page.ScheduleGrid.Calls.GetSummary();
    if (CHSI.Page.ScheduleGrid.Cache.CurrentURLParameters == null) {
        CHSI.Page.ScheduleGrid.Cache.CurrentURLParameters = location.hash;
    } else {
        location.hash = CHSI.Page.ScheduleGrid.Cache.CurrentURLParameters;
    }
}

Handlebars.registerHelper('backgroundcolor', function (value, options) {
    var backgroundcolor = 'arow';
    if (value % 2 == 0) {
        backgroundcolor = 'prow';
    }
    return backgroundcolor;
});