CHSI.Claims.FormTypes.ClaimsForm = {
    Init: function () {
        var Template_ClaimsForm = $('#Template_ClaimsForm').html();
        this.Templates.ClaimsForm = Handlebars.compile(Template_ClaimsForm);
        var Template_Groups = $('#Template_Groups').html();
        this.Templates.Groups = Handlebars.compile(Template_Groups);

        this.Calls.GetGroups(function () {            
            CHSI.Claims.FormTypes.ClaimsForm.Binds.Groups();

            CHSI.Claims.FormTypes.ClaimsForm.Calls.GetForms(function () {
                CHSI.Claims.FormTypes.ClaimsForm.Binds.Forms();
            });
        });        
        
        this.Events.Global();
    },
    Templates: {

    },
    Cache: {
        FormsObject: {},
        isActive: false,
        GroupID: ' '
    },
    Binds: {
        Forms: function () {
            $('#ClaimsFormContainer').html(CHSI.Claims.FormTypes.ClaimsForm.Templates.ClaimsForm({ Forms: CHSI.Claims.FormTypes.ClaimsForm.Cache.FormTypeObject }));
            restart = true;
            sorttable.init();
        },
        Groups: function () {
            $('#GroupsContainer').html(CHSI.Claims.FormTypes.ClaimsForm.Templates.Groups({ Groups: CHSI.Claims.FormTypes.ClaimsForm.Cache.GroupObject }));
        }
    },
    Events: {
        Global: function () {            
            $('#GroupsContainer').on('change', '#jxGroups', function () {
                CHSI.Claims.FormTypes.ClaimsForm.Cache.GroupID = $(this).val();

                CHSI.Claims.FormTypes.ClaimsForm.Calls.GetForms(function () {
                    CHSI.Claims.FormTypes.ClaimsForm.Binds.Forms();
                });
            });

            $('#GroupsContainer').on('change', '#jxStatus', function () {
                if ($(this).is(':checked')) {
                    CHSI.Claims.FormTypes.ClaimsForm.Cache.isActive = true;
                } else {
                    CHSI.Claims.FormTypes.ClaimsForm.Cache.isActive = false;
                }

                CHSI.Claims.FormTypes.ClaimsForm.Calls.GetForms(function () {
                    CHSI.Claims.FormTypes.ClaimsForm.Binds.Forms();
                });
            });
        },
        ShowFormEditor: function (FormTypeID) {
            var URL = null;
            if (FormTypeID) {
                URL = '/Claims/Modals/FormTypeEditor.aspx?FormTypeID=' + FormTypeID;
            }else {
                URL = '/Claims/Modals/FormTypeEditor.aspx?FormTypeID=' + null;
            }

            ShowPopup('ClaimFormEditor', URL, 'Form Editor', 800, 465, CHSI.Claims.FormTypes.ClaimsForm.Events.RefreshGrid, true);
        },
        RefreshGrid: function () {
            CHSI.Claims.FormTypes.ClaimsForm.Calls.GetForms(function () {
                CHSI.Claims.FormTypes.ClaimsForm.Binds.Forms();
            });
        },
        deleteFormType: function (FormTypeID) {
            var c = confirm('Please confirm that you would like to delete this Form.');
            if (c) {
                CHSI.Claims.FormTypes.ClaimsForm.Calls.deleteForm(FormTypeID);
            }
        }
    },
    Calls: {
        GetForms: function (successFunction) {
            var Options = {};

            Options.URL = '/api/ClaimSystem/Form/GetFormTypes?GroupName=' + CHSI.Claims.FormTypes.ClaimsForm.Cache.GroupID + '&ActiveOnly=' + CHSI.Claims.FormTypes.ClaimsForm.Cache.isActive;
            Options.Success = function (data) {
                CHSI.Claims.FormTypes.ClaimsForm.Cache.FormTypeObject = data;

                if (successFunction) {                    
                    successFunction(data);                    
                }
            }
            CHSI.Common.Ajax.Get(Options);
        },
        GetGroups: function (successFunction) {
            var Options = {};

            Options.URL = '/api/CRM/Group/GetActiveGroups';
            Options.Data = null;
            Options.Success = function (data) {
                CHSI.Claims.FormTypes.ClaimsForm.Cache.GroupObject = data;

                if (successFunction) {                    
                    successFunction(data);
                }
            }
            CHSI.Common.Ajax.Get(Options);
        },
        deleteForm: function (FormTypeID) {
            var URL = '/api/ClaimSystem/Form/DeleteFormType?FormTypeID=' + FormTypeID;
            $.post(URL).done(function () {
                CHSI.Claims.FormTypes.ClaimsForm.Calls.GetForms(function () {
                    CHSI.Claims.FormTypes.ClaimsForm.Binds.Forms();
                });
            });
        }
    }
}