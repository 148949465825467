CHSI.Claims.SuperClaimsSearch = {
    Init: function (QS, isPortal, AutoSelectClient, SupportChildClaims) {
 
        this.Templates.DisplaySuperClaims = CHSITemplates.Template_SuperClaimsResults;
        this.Templates.SuperClaimsFilters = CHSITemplates.SuperClaimsFilter;

        this.Cache.isPortal = isPortal;

        if (SupportChildClaims == null) {
            this.Cache.SupportChildClaims = false
            this.Cache.IncludeChildren = false;
        } else {
            this.Cache.SupportChildClaims = SupportChildClaims;
        }

        if (AutoSelectClient == null) {
            this.Cache.AutoSelectClient = false;
        } else {
            this.Cache.AutoSelectClient = AutoSelectClient;
        }

        if (isPortal) {
            this.Cache.ClientID = ClientID;
        } else {
            this.Cache.ClientID = QS.ClientID || QS.clientid;
        }

        this.Calls.GetClaimTypes(function () {
            CHSI.Claims.SuperClaimsSearch.Calls.GetCoverages(function () {
                CHSI.Claims.SuperClaimsSearch.Calls.GetPolicyPeriods(function () {
                    CHSI.Claims.SuperClaimsSearch.BindFilter();
                });
            });
        });

        this.Events.Global();
    },
    Settings: {
        ShowInactive: false
      , Index: null
    },
    Cache: {
        isPortal: false,
        FilterObject: {
            ClaimTypes: {
                Key: 0,
                Value: ''
            },
            Coverages: {},
            Paramters: {
                IncludeChildren: false,
                Status: null,
                Description: null,
                Filterdescription: null,
                ClaimType: null,
                LineName: null,
                Claimant: null,
                FilterClaimant: null,
                PolicyPeriod: null,
                ClientID: null,
                DateOfIncidentFilter: null,
                DateOfIncidentValue1: null,
                DateOfIncidentValue2: null
            },
        },
        ClientID: 0,
        AutoSelectClient: false
    },
    Templates: {

    },
    BindPresentationLayer: function () {
        $('#jxSuperClaims').html(this.Templates.DisplaySuperClaims(this.SuperClaimsObject));
    },
    BindFilter: function () {
        $('#SuperClaimsFilterContainer').html(this.Templates.SuperClaimsFilters({ FilterObject: CHSI.Claims.SuperClaimsSearch.Cache.FilterObject }));
        $('#jxQuerying').hide();

        $('.jxDate').datepicker({
            showOn: "both",
            buttonImage: "/images/icons/cal.gif",
            buttonImageOnly: true
        });

        if (!CHSI.Claims.SuperClaimsSearch.Cache.SupportChildClaims) {
            $('#jxSupportChildClaims').hide();
        }

        if (CHSI.Claims.SuperClaimsSearch.Cache.isPortal || CHSI.Claims.SuperClaimsSearch.Cache.AutoSelectClient) {
            $('#jxSelectClient').hide();
            $('#jxCancelClient').hide();

            CHSI.Claims.SuperClaimsSearch.Calls.GetClientInformation(CHSI.Claims.SuperClaimsSearch.Cache.ClientID, function (data) {
                $('#jxClientName').text(data.ClientName);
                $('#jxClientIDValue').val(CHSI.Claims.SuperClaimsSearch.Cache.ClientID);
            });
        }
    },
    Events: {
        Global: function () {
            $('#SuperClaimsFilterContainer').on('click', '#jxSearch', function () {
                $('#jxQuerying').show();
                var Values = CHSI.Claims.SuperClaimsSearch.GetFilterValues();
                CHSI.Claims.SuperClaimsSearch.Calls.PostFilterResult(Values, function (data) {
                    for (i = 0; i < data.length; i++) {
                        data[i].Index = i;
                    }
                    CHSI.Claims.SuperClaimsSearch.SuperClaimsObject = { SuperClaims: data };
                    CHSI.Claims.SuperClaimsSearch.BindPresentationLayer();
                    restart = true;
                    sorttable.init();

                    $('#jxQuerying').hide();
                });
            });

            $('#SuperClaimsFilterContainer').on('change', '#jxDateOfIncidentFilter', function () {
                var SelectedValue = $(this).val();
                if (SelectedValue == 'equals' || SelectedValue == 'before' || SelectedValue == 'after') {
                    $('#jxDateOfIncidentContainer2').hide();
                } else {
                    $('#jxDateOfIncidentContainer2').show();
                }
            });
        }
    },
    GetFilterValues: function () {
        var Values = CHSI.Claims.SuperClaimsSearch.Cache.FilterObject.Paramters;

        if ($('#jxShowChildClaims').is(':checked')) {
            Values.IncludeChildren = true;
        } else {
            Values.IncludeChildren = false;
        }

        Values.Status = $('#jxStatusValue1').val();
        if (Values.Status == '') {
            Values.Status = null;
        }
        Values.Description = $('#jxDescriptionValue2').val();
        if (Values.Description == '') {
            Values.Description = null;
            Values.Filterdescription = null;
        } else {
            Values.Filterdescription = $('#jxDescriptionValue1').val();
        }

        if (Values.Filterdescription == '' || Values.Filterdescription == null) {
            Values.Filterdescription = null;
            Values.Description = null;
        }

        Values.ClaimType = $('#jxClaimTypeValue1').val();
        if (Values.ClaimType == '') {
            Values.ClaimType = null;
        }
        Values.LineName = $('#jxCoverageValue1').val();
        if (Values.LineName == '') {
            Values.LineName = null;
        }

        Values.Claimant = $('#jxClaimantValue2').val();
        Values.FilterClaimant = $('#jxClaimantValue1').val();

        if (Values.FilterClaimant == '' || Values.FilterClaimant == null) {
            Values.Claimant = null;
            Values.FilterClaimant = null;
        } else {
            Values.FilterClaimant = $('#jxClaimantValue1').val();
            Values.Claimant = $('#jxClaimantValue2').val();
        }
        Values.PolicyPeriod = $("#jxPolicyPeriodValue1").val();
        if (Values.PolicyPeriod == '') {
            Values.PolicyPeriod = null;
        }
        Values.ClientID = $('#jxClientIDValue').val();
        if (Values.ClientID == '') {
            Values.ClientID = null;
        }
        Values.DateOfIncidentFilter = $('#jxDateOfIncidentFilter').val();
        if (Values.DateOfIncidentFilter == '') {
            Values.DateOfIncidentFilter = null;
            Values.DateOfIncidentValue1 = null;
            Values.DateOfIncidentValue2 = null;
        } else {
            Values.DateOfIncidentValue1 = $('#jxDateOfIncidentValue1').val();
            Values.DateOfIncidentValue2 = $('#jxDateOfIncidentValue2').val();
        }
        return Values;
    },
    Calls: {
        GetClientInformation: function (ClientID, successFunction) {
            CHSI.CRM.GetClient(ClientID, successFunction);
        },
        //GetSuperClaims: function (ShowInactive) {
        //    var AjaxURL = "/api/ClaimSystem/SuperClaim/GetSuperClaims";
        //    $.ajax({
        //        type: "POST",
        //        url: AjaxURL,
        //        cache: false,
        //        contentType: "application/json; charset=utf-8",
        //        dataType: "json",
        //        success: function (e) {
        //            for (i = 0; i < e.length; i++) {
        //                e[i].Index = i;
        //            }
        //            CHSI.Claims.SuperClaimsSearch.SuperClaimsObject = { SuperClaims: e };

        //            CHSI.Claims.SuperClaimsSearch.BindPresentationLayer();
        //            restart = true;
        //            sorttable.init();
        //        },
        //        error: function (e) {
        //            CHSI.Common.LogObject(e);
        //        }
        //    });
        //},
        GetClaimTypes: function (successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/Claim/GetAllClaimTypes';
            Options.Success = function (data) {
                for (i = 0; i < data.length; i++) {
                    CHSI.Claims.SuperClaimsSearch.Cache.FilterObject.ClaimTypes[i] = { Key: i, Value: data[i] };
                }

                delete CHSI.Claims.SuperClaimsSearch.Cache.FilterObject.ClaimTypes.Key;
                delete CHSI.Claims.SuperClaimsSearch.Cache.FilterObject.ClaimTypes.Value;

                successFunction();
            }
            CHSI.Common.Ajax.Get(Options)
        },
        GetCoverages: function (successFunction) {

            if (!CHSI.Claims.SuperClaimsSearch.Cache.ClientID) {
                CHSI.Claims.SuperClaimsSearch.Cache.ClientID = 0;
            }

            var Options = {};
            Options.URL = '/api/ClaimSystem/Claim/GetCoverageTypes?ClientID=' + CHSI.Claims.SuperClaimsSearch.Cache.ClientID;
            Options.Success = function (data) {
                var i = -1;
                for (var value in data) {
                    i++;
                    CHSI.Claims.SuperClaimsSearch.Cache.FilterObject.Coverages[i] = { Key: value, Value: value };
                }
                successFunction();
            }
            CHSI.Common.Ajax.Get(Options)
        },
        GetPolicyPeriods: function (successFunction) {
            var AjaxURL = '/api/UW/UW/GetPolicyYears';
            $.ajax({
                type: "GET",
                url: AjaxURL,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                cache: false,
                success: function (e) {
                    CHSI.Claims.SuperClaimsSearch.Cache.FilterObject.PolicyPeriod = e;
                    if (successFunction) {
                        successFunction(e);
                    }
                },
                error: function (e) {
                    CHSI.Common.Log(e);
                }
            });
        },
        PostFilterResult: function (FilterParameters, successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/SuperClaim/GetSuperClaims';
            Options.Data = FilterParameters;
            Options.Success = function (data) {
                successFunction(data);
            }
            Options.Error = function (data) {
                $('#jxQuerying').hide();
            }
            CHSI.Common.Ajax.Post(Options)
        }
    }
};
