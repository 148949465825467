var Template_QuoteNarrative = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2=this.escapeExpression, alias3=this.lambda;

  return "<table class=\"dtable\" cellspacing=\"0\" width=\"100%\">\r\n    <thead>\r\n        <tr>\r\n            <td>"
    + alias2((helpers.AddNumber || (depth0 && depth0.AddNumber) || alias1).call(depth0,(data && data.index),1,{"name":"AddNumber","hash":{},"data":data}))
    + "&nbsp;-&nbsp;"
    + alias2(((helper = (helper = helpers.NarrativeText || (depth0 != null ? depth0.NarrativeText : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"NarrativeText","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td nowrap=\"\" width=\"1%\" align=\"right\">"
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.Narrative : depth0)) != null ? stack1.UpdateUser : stack1), depth0))
    + " - "
    + alias2((helpers.CHSIDateTime || (depth0 && depth0.CHSIDateTime) || alias1).call(depth0,((stack1 = (depth0 != null ? depth0.Narrative : depth0)) != null ? stack1.UpdateTmsp : stack1),{"name":"CHSIDateTime","hash":{},"data":data}))
    + "&nbsp;</td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n        <tr>\r\n            <td colspan=\"2\" style=\"word-break:break-all;\">\r\n                "
    + ((stack1 = alias3(((stack1 = (depth0 != null ? depth0.Narrative : depth0)) != null ? stack1.NarrativeValue : stack1), depth0)) != null ? stack1 : "")
    + "\r\n                <div align=\"right\" style=\"margin:7px\">\r\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.Narrative : depth0),{"name":"if","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.program(4, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                </div>\r\n            </td>\r\n        </tr>\r\n    </tbody>\r\n</table>\r\n";
},"2":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                    <input type=\"button\" name=\"EditNarrative\" data-narrativeid=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Narrative : depth0)) != null ? stack1.NarrativeID : stack1), depth0))
    + "\" data-narrativetypeid=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Narrative : depth0)) != null ? stack1.NarrativeTypeID : stack1), depth0))
    + "\" data-quoteid=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Narrative : depth0)) != null ? stack1.QuoteID : stack1), depth0))
    + "\" value=\"Edit Narrative\" id=\"jxEditNarrative\" style=\"width:130px;\">\r\n                    <input type=\"button\" name=\"DeleteNarrative\" data-narrativeid=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Narrative : depth0)) != null ? stack1.NarrativeID : stack1), depth0))
    + "\" data-narrativetypeid=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Narrative : depth0)) != null ? stack1.NarrativeTypeID : stack1), depth0))
    + "\" data-quoteid=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Narrative : depth0)) != null ? stack1.QuoteID : stack1), depth0))
    + "\" value=\"Delete Narrative\" id=\"jxDeleteNarrative\" style=\"width:130px;margin-left:10px\">\r\n";
},"4":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=this.escapeExpression;

  return "                    <input type=\"button\" name=\"EditNarrative\" data-narrativetypeid=\""
    + alias1(((helper = (helper = helpers.NarrativeTypeID || (depth0 != null ? depth0.NarrativeTypeID : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"NarrativeTypeID","hash":{},"data":data}) : helper)))
    + "\" data-quoteid=\""
    + alias1(this.lambda(((stack1 = (depths[2] != null ? depths[2].Narrative : depths[2])) != null ? stack1.QuoteID : stack1), depth0))
    + "\" value=\"Add Narrative\" id=\"jxEditNarrative\" style=\"width:130px;\">\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "﻿"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Narrative : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});