
interface PopUp extends Window {
    showPopUP: any;
}
namespace PL.AdminTools.Controllers {

    export class ClaimIntakeEditInformation extends PL.AdminTools.Controllers.Base_ClaimIntakeEdit {

        _$container: JQuery;

        private _activeTab: number = 0;

        FormTypeObject: PL.Claims.Models.FormType;
        GroupObject: PL.CRM.Models.Group[];
        ContentTypes: PL.Common.Models.ContentType[];

        init(claimIntake: Controllers.ClaimIntakeEdit): JQueryDeferred<any> {
            this._$container = claimIntake._$parentContainer;

            this.GroupObject = claimIntake.GroupObject;
            this.FormTypeObject = claimIntake.FormTypeObject;
            this.ContentTypes = claimIntake.ContentTypes;
            var $result = $.Deferred<any>();
            this.bind().done(() => {
                this.bindNotifications().done(() => {
                    this.setupEvents();
                    this.events();
                    $result.resolve(true);
                });
            });
            return $result;
        }   

        bind(): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            $result.resolve(mW.handlebars.bind(CHSITemplates.AdminTools_ClaimIntakeEditor, { FormType: this.FormTypeObject, Group: this.GroupObject }, this._$container));
            return $result;
        }
        
        bindNotifications(): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            $result.resolve(mW.handlebars.bind(CHSITemplates.AdminTools_ClaimIntakeEditor_Information_Notifications, { Notifications: this.FormTypeObject.Notifications }, $('#infoNotifications')));
            return $result;
        }

        bindCategoriesAndFields(): JQueryDeferred<any> {
            var $result = $.Deferred<any>();                        
            mW.handlebars.bind(CHSITemplates.AdminTools_ClaimIntakeEditor_Fields, { Fields: this.FormTypeObject.FormCategory.FormFields, Categories: this.FormTypeObject.FormCategories, ContentTypes: this.ContentTypes }, $('#fieldFields'));
            mW.handlebars.bind(CHSITemplates.AdminTools_ClaimIntakeEditor_Categories, { Categories: this.FormTypeObject.FormCategories }, $('#catFields'));
            this.Categories().FormTypeObject = this.FormTypeObject;
            this.Categories().setup();
            this.Fields().FormTypeObject = this.FormTypeObject;
            this.Fields().setupEvents();
            this.Fields().setup();
            this.bindSortable();
            return $result.resolve();
        }

        bindCategories(): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            
            super.bindSortable();
            return $result.resolve();
        }

        setupEvents(): void {
            if (this.FormTypeObject.FormTypeID == null) {
                $('#infoNotificationCreate, #infoNotificationEdit, #infoNotificationDelete, #infoNotificationAdd').attr('title', 'Please save the form before creating, editing, or deleting a notification.').prop('disabled', true);
                $('#infoInstructions, #infoFinishInstructions').attr('title', 'Please save the form before adding instructions.').prop('disabled', true);
                $('.okClose').attr('title', 'Please save the form before continuing.').prop('disabled', true);
            }            
        }

        events(): void {

            var _self = this;
            
            this._$container.on('keyup', '#infoReportForm', function () {
                _self.validateReportFormID();
            });

            this._$container.on('click', '#infoInstructions, #infoFinishInstructions', function (e) {
                var instructionID = $(this).attr('id');
                if (_self.validateInformation()) {
                    _self.showInstructionEditor(instructionID, e);
                }
            });

            this._$container.on('change', '.infoAssociatedGroup', function () {
                var groupName = $(this).data('groupname'), isIncluded = $(this).is(':checked');
                _self.updateGroup(groupName, isIncluded);
            });

            this._$container.on('click', '#infoNotificationAdd', function () {
                var notificationID = $('#infoNoteList').val();
                _self.updateFormNotifications(notificationID, 'add');
            });

            this._$container.on('click', '#infoNotificationCreate, #infoNotificationEdit', function (e) {
                var notificationID = $(this).attr('id') == 'infoNotificationCreate' ? null : $('#infoNoteList').val();
                                
                if (_self.FormTypeObject.FormTypeID == null) {
                    _self.save().done(() => {
                        _self.showNotificationEditor(notificationID, e);
                    });
                } else {
                    _self.showNotificationEditor(notificationID, e);
                }
            });

            this._$container.on('click', '#infoNotificationDelete', function () {
                var notificationID = $('#infoNoteList').val();
                _self.deleteNotification(notificationID);                
            });

            this._$container.on('click', '.infoNotificationRemove', function () {                
                var notificationID = $(this).data('notificationid');
                $(this).closest('tr').fadeOut();
                _self.updateFormNotifications(notificationID, 'remove');
            });

            this._$container.on('click', '#infoSave', (e) => {
            // hackish - we gotta clean this up
                var wasNew = _self.FormTypeObject.FormTypeID;
                $(e.target).prop('disabled', true);
                if (_self.validateInformation(true)) {
                    _self.save().done(() => {
                        if (wasNew == null) {
                            _self.FormTypeObject.getCompleteForm().done(() => {
                                _self.bind().done(() => {
                                    this.bindCategoriesAndFields().done(() => {
                                        super.setupEvents();
                                        $(e.target).prop('disabled', false);
                                        $('#formTypeEditTabs').tabs('option', 'disabled', []);
                                        $('#infoInstructions, #infoFinishInstructions').attr('title', '').prop('disabled', false);
                                        $('#infoNotificationCreate, #infoNotificationEdit, #infoNotificationDelete, #infoNotificationAdd').attr('title', '').prop('disabled', false);
                                        $('.okClose').attr('title', 'This saves all your work from each tab and closes this modal.').prop('disabled', false);
                                    });
                                });
                            });
                        } else {
                            $(this).prop('disabled', false);
                            $('#formTypeEditTabs').tabs('option', 'disabled', []);
                            $('#infoInstructions, #infoFinishInstructions').attr('title', '').prop('disabled', false);
                            $('#infoNotificationCreate, #infoNotificationEdit, #infoNotificationDelete, #infoNotificationAdd').attr('title', '').prop('disabled', false);
                            $('.okClose').attr('title', 'This saves all your work from each tab and closes this modal.').prop('disabled', false);                     
                        }
                                                                      
                    });
                } else {
                    $(e.target).prop('disabled', false);                    
                }
            });

        }

        showInstructionEditor(instructionID: string, clickEvent: any): void {
            var instructionType = null;

            if (instructionID == 'infoInstructions') {
                instructionType = 'start';
            } else {
                instructionType = 'finish';
            }

            var url = '/Claims/Modals/InstructionsEditor.aspx?formtypeid=' + this.FormTypeObject.FormTypeID + '&instructiontype=' + instructionType;
             
            var showPopUP = new GUI.Windows.Popup();
            showPopUP.URL = url;
            showPopUP.WindowID = 'questionnaireformeditor';
            showPopUP.Title = 'Claim Intake Editor - Instruction Editor';
            showPopUP.Width = 800;
            showPopUP.Height = 490;
            showPopUP.isParent = true;
            showPopUP.CloseFunction = () => this.updateInstructions();
            showPopUP.showIFrame(clickEvent);
        }

        showNotificationEditor(notificationID: number, clickEvent: any): void {
            var url = null;
            if (notificationID) {
                url = '/Claims/Modals/NotificationEditor.aspx?NotificationID=' + notificationID;
            } else {
                url = '/Claims/Modals/NotificationEditor.aspx';
            }
            var showPopUP = new GUI.Windows.Popup();
            showPopUP = new GUI.Windows.Popup();
            showPopUP.URL = url;
            showPopUP.WindowID = 'questionnaireformeditor';
            showPopUP.Title = 'Claim Intake Editor - Notification Editor';
            showPopUP.Width = 800;
            showPopUP.Height = 650;
            showPopUP.isParent = true;
            showPopUP.CloseFunction = () => this.updateAllNotifications();
            showPopUP.showIFrame(clickEvent);
        }

        validateInformation(override: boolean = false): boolean {
            var isValid = false;            
                        
            if ($('#infoFormType').val() != '' && $('#infoFormName').val() != '') {
                isValid = true;
            } else {
                isValid = false;
                this.getTabMessage(this._activeTab, Enums.ClaimIntakeEditor_Information_Messages.MISSING_REQUIRED_INFORMATION);
            }

            if (!override) {            
                if (isValid) {
                    if (this.FormTypeObject.FormTypeID != null) {
                        isValid = true;
                    } else {
                        this.getTabMessage(this._activeTab, Enums.ClaimIntakeEditor_Information_Messages.NEW_UNSAVED);
                        isValid = false;
                    }
                }            
            }

            return isValid;
        }

        validateReportFormID(): void {
            var reportFormValue = $('#infoReportForm').val();
            if (!$.isNumeric(reportFormValue)) {
                this.getTabMessage(this._activeTab, Enums.ClaimIntakeEditor_Information_Messages.REPORT_FORM_ID_NOT_NUMERIC);
            } else {
                this.clearMessage();
            }
        }

        save(): JQueryDeferred<number> {
            var $result = $.Deferred<number>();
            this.FormTypeObject.FormTypeID = this.FormTypeObject.FormTypeID;
            this.FormTypeObject.FormTypeName = $('#infoFormType').val();
            this.FormTypeObject.Name = $('#infoFormName').val();
            this.FormTypeObject.Description = $('#infoDescription').val();
            this.FormTypeObject.ReportForm = $('#infoReportForm').val();
            this.FormTypeObject.DaysToStale = $('#infoDaysToStale').val();
            this.FormTypeObject.Status = $('#infoStatus').val();
            this.FormTypeObject.AssociatedGroups = this.FormTypeObject.AssociatedGroups;
            this.FormTypeObject.Notifications = this.FormTypeObject.Notifications;          
            this.FormTypeObject.Instructions = this.FormTypeObject.Instructions;
            this.FormTypeObject.FinishInstructions = this.FormTypeObject.FinishInstructions;
            this.FormTypeObject.saveFormType().done((formtypeid) => {
                this.FormTypeObject.FormTypeID = formtypeid;
                this.FormTypeObject.FormTypeName = $('#infoFormType').val();
                this.FormTypeObject.Name = $('#infoFormName').val();
                this.FormTypeObject.Description = $('#infoDescription').val();
                this.FormTypeObject.ReportForm = $('#infoReportForm').val();
                this.FormTypeObject.DaysToStale = $('#infoDaysToStale').val();
                this.FormTypeObject.Status = $('#infoStatus').val();
                this.getTabMessage(this._activeTab, Enums.ClaimIntakeEditor_Information_Messages.SAVED);
                $result.resolve(formtypeid);
            });
            return $result;
        }

        updateInstructions(): void {
            var newFormType: PL.Claims.Models.FormType = new PL.Claims.Models.FormType;
            newFormType.FormTypeID = this.FormTypeObject.FormTypeID;
            newFormType.getInstructions().done((formType) => {
                this.FormTypeObject.Instructions = formType.Instructions;
                this.FormTypeObject.FinishInstructions = formType.FinishInstructions;                
            });
        }

        updateGroup(groupName: string, isIncluded: boolean): void {
            isIncluded == true ? this.FormTypeObject.AssociatedGroups.push(groupName) : this.FormTypeObject.AssociatedGroups.forEach((agO, agI, ag) => { ag.splice(agI, 1); });
        }

        updateFormNotifications(notificationID: number, status: string): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            $('#infoNotificationAdd, #infoNotificationDelete, .infoNotificationRemove').prop('disabled', true);
            var execute = () => {
                new PL.Claims.Models.Notifcation(null, this.FormTypeObject.FormTypeID).getNotificationsByFormTypeID().done((notifications) => {
                    this.FormTypeObject.Notifications = notifications;
                    this.updateAllNotifications().done(() => {
                        this.bindNotifications();
                        $('#infoNotificationAdd, #infoNotificationDelete, .infoNotificationRemove').prop('disabled', false);
                        restart = true;
                        sorttable.init();
                    });
                });
            };

            if (status == 'add') {
                new PL.Claims.Models.Notifcation(notificationID, this.FormTypeObject.FormTypeID).saveFormTypeNotification().done(() => {
                    execute();
                });
            } else {
                new PL.Claims.Models.Notifcation(notificationID, this.FormTypeObject.FormTypeID).removeFormTypeNotification().done(() => {
                    execute();
                });
            }   
            return $result.resolve();
        }

        updateAllNotifications(): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            var notification: PL.Claims.Models.Notifcation = new PL.Claims.Models.Notifcation;
            notification.FormTypeID = this.FormTypeObject.FormTypeID;
            notification.getNotificationsNotInFormType().done((notifications) => {
                this.FormTypeObject.AllNotifications = notifications;       
                $('#infoNoteList').empty();
                this.FormTypeObject.AllNotifications.forEach((anO, anI) => {                    
                    $('#infoNoteList').append($("<option></option>").attr("value", anO.NotificationID).text(anO.NotificationName));
                });                   
            });
            return $result.resolve();
        }

        deleteNotification(notificationID: number): void {
            var c = confirm('Are you sure you want to delete this Notification?');
            if (c) {
                new PL.Claims.Models.Notifcation(notificationID).deleteNotification().done(() => {
                    this.updateAllNotifications();
                });
            }            
        }

        removeFormNotification(notificationID: number): void {

            this.FormTypeObject.Notifications.forEach((nO, nI, n) => {

                if (nO.NotificationID == notificationID) {
                    n.splice(nI, 1);

                }

            });

        }

    }

}