module CL.UW.APIs
{
    export class DocumentRepository {

        getGroupDocumentType(GroupLineID: number): JQueryDeferred<Model.GroupLineDocument[]> {
            var Result = jQuery.Deferred();
            
            $.get(
                '/API/UW/GroupLineDocument/GetHeaders?GroupLineID=' + GroupLineID).done(
                function (data) {
                Result.resolve(data);
            });

            return Result;
        }
    }
} 