module PL.AdminTools.Models {
    export class QuoteDocumentBatch {
        ID: number;
        DocumentGroupID: number;
        ExportName: string;
        FileName: string;
        Status: string;
        SQLSource: string;
        Schedule: string;
        DestinationAddress: string;
        DestinationType: number | string;
        DestinationUsername: string;
        DestinationPassword: string;
        DestinationAttachmentCategoryID: number;
        ProductLineID: number;                

        constructor(jsonObject?: any) {            
            console.log('creating a new QuoteDocumentBatch object');
            if (jsonObject)
            {
                console.log('deserializing the quotedocumentbatch from JSON');
                for (var prop in jsonObject) this[prop] = jsonObject[prop];
            }

        }
        static getBatchGrid(): JQueryDeferred<GUI.Smart.SmartObject>
        {
            return Factories.RepositoryFactory.GetQuoteDocumentBatchRepository().getQuoteDocumentBatcheGrid();
        }
        static getFromAPI(ID: number): JQueryDeferred<QuoteDocumentBatch>
    {
            return Factories.RepositoryFactory.GetQuoteDocumentBatchRepository().getQuoteDocumentBatch(ID);
        }

        updateFromForm(): void {
            this.ExportName = $('#jxExportName').val();
            this.FileName = $('#jxFileName').val();
            this.Status = $('#jxStatus').val();
            this.SQLSource = $('#jxSQLSource').val();
            this.DestinationType = $('#jxDestination').val();
            this.DocumentGroupID = $('#jxGroupDocumentID').val() * 1;
            this.DestinationAddress = $('#jxDestinationAddress').val();
            this.DestinationUsername = $('#jxDestinationUserName').val();
            this.DestinationPassword = $('#jxDestinationPassword').val();
            this.DestinationAttachmentCategoryID = $('#jxDestinationAttachmentCategoryID').val();
            this.Schedule = $('#jxSchedule').val();
            if (this.Schedule == 'custom')
            {
                this.Schedule = $('#jxCustomSchedule').val();
            }
             
        }
        save(): JQueryDeferred<boolean> {
            var Results = jQuery.Deferred();
            var self = this;
            Factories.RepositoryFactory.GetQuoteDocumentBatchRepository().saveBatch(self).done(
                function () {
                    Results.resolve(true);
                });

            return Results;
        }
 
    }
}