CHSI.Page.SFields = {
    Init: function (successFunction) {
        
        var Template_ScheduleFields = $('#Template_ScheduleFields').html();
        this.Templates.ScheduleFields = Handlebars.compile(Template_ScheduleFields);
        CHSI.Page.SFields.Cache.SavingFlag = false;

        this.Calls.GetValidatorTypes(function () {
            
            if (CHSI.Page.SInformation.Cache.ScheduleTypeID) {
                CHSI.Page.SFields.Calls.GetScheduleFields(successFunction);
            }
            else {
                CHSI.Page.SFields.ProcessFieldCall([]);
            }

        });
        this.Events.Global();

    },
    Templates: {

    },
    Cache: {
        
         ValidatorTypes: []
      , ScheduleFieldsContainer: {
          Categories: []
      }
      ,SavingFlag:false
    },

    BindPresentationLayer: function () {
        CHSI.Page.SFields.Cache.ScheduleFieldsContainer.Categories = CHSI.Page.SCategories.Cache.ScheduleCategoriesObject.ScheduleCategories;
        $('#jxScheduleFieldsContainer').html(this.Templates.ScheduleFields(this.Cache.ScheduleFieldsContainer));

        $('.jxDatePicker').datepicker({
            showOn: "both",
            buttonImage: "/images/icons/cal.gif",
            buttonImageOnly: true
        });
    },
    GetDefaultField: function()
    {
        return {
            ScheduleTypeID: CHSI.Page.SInformation.Cache.ScheduleTypeID,
            FormEditable: true,
            Required: false,
            Status: 'Active',
            FieldType: '1',
            Summarize: false,
            GridShow: false,
            FormShow: false,
            PortalShow: false,
            FieldLogic:''
        }
    }
    ,BindFieldtoPresentation: function(index,Field)
    {        
        $('#jxSFieldName_' + index).val(Field.FieldName);
        $('#jxSFieldShowInGrid_' + index).prop('checked', Field.GridShow);
        if (Field.GridShow) {            
            $('#jxSFieldSortOrderDisplay_' + index).show();
        } else {            
            $('#jxSFieldSortOrderDisplay_' + index).hide();
        }
        $('#jxSFieldSortOrderGrid_' + index).val(Field.GridSortOrder);
        $('#jxSFieldShowInForm_' + index).prop('checked', Field.FormShow);        
        if (Field.FormShow) {            
            $('#jxSFieldShowFormDisplay_' + index).show();            
        } else {            
            $('#jxSFieldShowFormDisplay_' + index).hide();
        }
        $('#jxSFieldSortOrderForm_' + index).val(Field.FormSortOrder);
        $('#jxSFieldVisibleOnPortal_' + index).prop('checked', Field.PortalShow);
        if (Field.PortalShow) {            
            $('#jxSFieldReadonlyDisplay_' + index).show();
        } else {            
            $('#jxSFieldReadonlyDisplay_' + index).hide();
        }
        $('#jxSFieldReadOnly_' + index).prop('checked', Field.PortalReadOnly);
        $('#jxSFieldStatus_' + index).val(Field.Status);
        $('#jxSFieldCategory_' + index).val(Field.ScheduleCategoryID);
        $('#jxSFieldLongName_' + index).val(Field.LongName);
        $('#jxSFieldRequired_' + index).val(Field.Required.toString());
        
        $('#jxSFieldToolTip_' + index).val(Field.ToolTipText);
        $('#jxSFieldSummarized_' + index).val(Field.Summarize.toString());
        $('#jxSFieldFieldType_' + index).val(Field.FieldType);
        $('#jxSFieldDataType_' + index).val(Field.DataType.toString());
        $('#jxSFieldSize_' + index).val(Field.DataSize);
        switch (Field.FieldType * 1) {
            case 1/*regular*/:
                $('#jxSFieldValidator_' + index).val(Field.FieldLogic);
                break;
            case 2/*calculated*/:
            case 4/*dropdown*/:
                $('#jxSFieldFormula_' + index).val(Field.FieldLogic);
                break;
            case 3/*date*/:
                break;
            case 5/*textblock*/:
                break;
            default:
        }
        CHSI.Page.SFields.ProcessFieldTypeChange(index, Field.FieldType * 1);

        $('#jxSFieldDisplayFormat_' + index).val(Field.DisplayFormat);
    }
    , ProcessFieldTypeChange: function (index, newFieldType)
    {
        var jxFieldLogicLabel = $('#jxFieldLogicLabel_' + index);

        var jxSFieldValidator = $('#jxSFieldValidator_' + index);
        var jxSFieldFormula = $('#jxSFieldFormula_' + index);
        jxSFieldValidator.hide();
        jxSFieldFormula.hide();
        jxFieldLogicLabel.html('');
        switch (newFieldType) {
            case 1/*regular*/:
                jxSFieldValidator.show();
                jxFieldLogicLabel.html('Validator');
                break;
            case 2/*calculated*/:
                jxSFieldFormula.show();
                jxFieldLogicLabel.html('Calculation Formula');
                break;
            case 4/*dropdown*/:
                jxSFieldFormula.show();
                jxFieldLogicLabel.html('Dropdown source');
                break;
            case 3/*date*/:
                break;
            case 5/*textblock*/:
                break;
            default:
        }

    }
    , ShowHideDetailRow: function (index) {
        var FieldEditButton = $('#jxSFieldEdit_' + index);
        var DetailRow = $('#ItemDetailRow_' + index);
        
        if (DetailRow.is(':visible')) {
            DetailRow.hide();
            FieldEditButton.html('Edit<span class="ui-icon ui-icon-triangle-1-s" style="float:right;" ></span><span></span>');
        }
        else {
            DetailRow.show();
            FieldEditButton.html('Edit<span class="ui-icon ui-icon-triangle-1-n" style="float:right;" ></span><span></span>');
        }
    }
    ,Events: {
        Global: function () {

            $('#jxAddField').on('click', function () {
                CHSI.Page.SFields.Events.GetFieldsFromPresentationLayer();
                CHSI.Page.SFields.Cache.ScheduleFieldsContainer.ScheduleFields.push(CHSI.Page.SFields.GetDefaultField());
                CHSI.Page.SFields.BindPresentationLayer();
                CHSI.Page.SFields.ShowHideDetailRow(CHSI.Page.SFields.Cache.ScheduleFieldsContainer.ScheduleFields.length-1);
            });

            $('body').on('change', '.ShowGridCheckbox', function ()
            {
                var checkbox = $(this);
                var Index = checkbox.data('fieldindex');
                var jxSFieldSortOrderDisplay = $('#jxSFieldSortOrderDisplay_' + Index);
                if (checkbox.is(':checked'))
                {
                    jxSFieldSortOrderDisplay.show();                    
                } else {
                    jxSFieldSortOrderDisplay.hide();                    
                }
            });
            
            $('body').on('change', '.ShowFormCheckbox', function () {
                var checkbox = $(this);
                var Index = checkbox.data('fieldindex');
                var jxSFieldShowInForm = $('#jxSFieldShowFormDisplay_' + Index);
                if (checkbox.is(':checked'))
                {
                    jxSFieldShowInForm.show();                    
                } else {
                    jxSFieldShowInForm.hide();                    
                }
            });
            
            $('body').on('change', '.ShowFieldVisibleonPortalCheckbox', function () {
                var checkbox = $(this);
                var Index = checkbox.data('fieldindex');
                var jxSFieldReadonlyDisplay = $('#jxSFieldReadonlyDisplay_' + Index);
                if (checkbox.is(':checked'))
                {
                    jxSFieldReadonlyDisplay.show();                    
                } else {
                    jxSFieldReadonlyDisplay.hide();                    
                }
            });
            $('#uxScheduleFieldOK').on('click', function () {
                CHSI.Page.SFields.Events.SaveFields();
                closeThis(true);
            });

            $('body').on('click', '.FieldEdit', function () {
                
                var FieldEditButton = $(this);
                var Index = FieldEditButton.data('fieldindex');
                
                CHSI.Page.SFields.ShowHideDetailRow(Index);
                
            });
            $('body').on('click', '.revertbutton', function () {
                var RevertButton = $(this);
                var Index = RevertButton.data('fieldindex');
                RevertButton.attr('disabled', 'disabled');
                var isFound = false;
                $(CHSI.Page.SFields.Cache.ScheduleFieldsContainer.ScheduleFields).each(function (IndexInArray, Field) {
                    if (IndexInArray == Index) {
                        if (Field.ScheduleFieldID == null) {
                            CHSI.Page.SFields.BindFieldtoPresentation(IndexInArray, CHSI.Page.SFields.GetDefaultField());
                        }
                        else {
                            CHSI.Page.SFields.BindFieldtoPresentation(IndexInArray, Field);
                        }
                        
                        isFound = true;
                        return false;
                    }                 
                });


            });

            $('body').on('change', '.FieldType', function () {
                var FieldTypeList = $(this);
                var index = FieldTypeList.data('fieldindex');
                var FieldType = FieldTypeList.val() * 1;

                CHSI.Page.SFields.ProcessFieldTypeChange(index, FieldType);
            });

            $('#jxScheduleFieldsContainer').on('change', 'input,textarea,select', function () {
                var ChangeField = $(this);
                var Index = ChangeField.data('fieldindex');
                var RevertButton = $('#jxSFieldRevert_' + Index);
                RevertButton.removeAttr('disabled');
            });
        },
        GetFieldsFromPresentationLayer: function () {

            $(CHSI.Page.SFields.Cache.ScheduleFieldsContainer.ScheduleFields).each(function (index, Field) {
                Field.FieldName = $('#jxSFieldName_' + index).val();
                Field.GridShow = $('#jxSFieldShowInGrid_' + index).is(':checked');
                Field.GridSortOrder = $('#jxSFieldSortOrderGrid_' + index).val();
                Field.FormShow = $('#jxSFieldShowInForm_' + index).is(':checked');
                Field.FormSortOrder = $('#jxSFieldSortOrderForm_' + index).val();
                Field.PortalShow = $('#jxSFieldVisibleOnPortal_' + index).is(':checked');
                Field.PortalReadOnly = $('#jxSFieldReadOnly_' + index).is(':checked');
                Field.Status = $('#jxSFieldStatus_' + index).val();
                Field.ScheduleCategoryID = $('#jxSFieldCategory_' + index).val();
                Field.LongName = $('#jxSFieldLongName_' + index).val();
                Field.Required = $('#jxSFieldRequired_' + index).val();
                Field.ToolTipText = $('#jxSFieldToolTip_' + index).val();
                Field.Summarize = $('#jxSFieldSummarized_' + index).val();
                Field.FieldType = $('#jxSFieldFieldType_' + index).val();
                Field.DataType = $('#jxSFieldDataType_' + index).val();
                Field.DataSize = $('#jxSFieldSize_' + index).val();
                Field.FieldLogic = '';
                switch (Field.FieldType * 1) {
                    case 1/*regular*/:
                        Field.FieldLogic = $('#jxSFieldValidator_' + index).val();
                        break;
                    case 2/*calculated*/:
                    case 4/*dropdown*/:
                        Field.FieldLogic = $('#jxSFieldFormula_' + index).val();
                        break;
                    case 3/*date*/:
                        break;
                    case 5/*textblock*/:
                        break;
                    default:
                }
                Field.DisplayFormat = $('#jxSFieldDisplayFormat_' + index).val();
            });

        }
    , SaveFields: function () {
        if (CHSI.Page.SFields.Cache.ScheduleFieldsContainer.ScheduleFields) {
            CHSI.Page.SFields.Events.GetFieldsFromPresentationLayer();
            CHSI.Page.SFields.Calls.SaveScheduleFields();
        }
    },
        DynamicFieldTypes: function (FieldTypeValue) {
            var Calls = CHSI.Page.SFields.Calls;
            var Cache = CHSI.Page.Cache;
            var HTML = [];
            switch (FieldTypeValue) {
                case 1: // Regular
                    $('#jxSFieldDynamicFieldType_' + + '').html('Validator')
                    HTML.push('<select>');
                    HTML.push('<option value=""></option>');
                    $.each(Cache.ValidatorTypes, function () {
                        HTML.push('<option value="' + Cache.ValidatorTypes + '"></option>');
                    })
                    HTML.push('</select>');
                    break;
                case 2: // Calculated
                    HTML.push('');
                    break;
                case 3: // Date
                    HTML.push('<input type="text" class="jxDatePicker" />');
                    break;
                case 4: // DropDown
                    HTML.push('');
                    break;
                case 5: // TextBlock
                    HTML.push('');
                    break;
                default:
                    alert('Type not supported.');
                    break;
            }
            return HTML.join('');
        } 
    },
    ProcessFieldCall: function (Fields) {
        CHSI.Page.SFields.Cache.ScheduleFieldsContainer = { ScheduleFields: Fields, Categories: CHSI.Page.SCategories.Cache.ScheduleCategoriesObject.ScheduleCategories, ContentTypes: CHSI.Page.SFields.Cache.ContentTypes };
        
            CHSI.Page.SInformation.Calls.GetSummaryFields();

        
        CHSI.Page.SFields.BindPresentationLayer();
    }

    , Calls: {
        SaveScheduleFields: function () {
            if (!CHSI.Page.SFields.Cache.SavingFlag) {
                CHSI.Page.SFields.Cache.SavingFlag = true;
                var ajaxData = [];
                ajaxData = CHSI.Page.SFields.Cache.ScheduleFieldsContainer.ScheduleFields;
                var ajaxString = JSON.stringify(ajaxData);
                var AjaxURL = '/api/UW/ScheduleField/SaveScheduleFields';
                $.ajax({
                    type: "POST",
                    url: AjaxURL,
                    cache: true,
                    data: ajaxString,
                    contentType: "application/json; charset=utf-8",
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    success: function (e) {
                        CHSI.Page.SFields.ProcessFieldCall(e);
                        CHSI.Page.SFields.Cache.SavingFlag = false;

                    },
                    error: function (e) {
                        CHSI.Common.LogObject(e);
                    }
                });
            }

        }
        , GetScheduleFields: function (successFunction) {
            var AjaxURL = '/api/UW/ScheduleField/GetScheduleField?ScheduleTypeID=' + CHSI.Page.SInformation.Cache.ScheduleTypeID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.SFields.ProcessFieldCall(e);

                    if (successFunction)
                    { successFunction(); }

                },
                error: function (e) {
                    CHSI.Common.Log('no data to return');
                }
            });
        },
        GetValidatorTypes: function (successFunction) {
            var AjaxURL = '/api/Common/ContentType/Get';
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.SFields.Cache.ContentTypes = e;
                    if (successFunction) {
                        successFunction();
                    }

                },
                error: function (e) {
                    CHSI.Common.Log('no data to return');
                }
            });
        }
    }
}