
CHSI.MuniUW = new Object();

CHSI.MuniUW.QuoteObject = null;
/*This function should be overwritten with the current refresh script*/
CHSI.MuniUW.RefreshGUI = null;

CHSI.MuniUW.GetAppearanceCollection = function (ControlType) {
    if (CHSI.MuniUW.QuoteObject == null) {
        throw new Error('Quote Object is null');
    }
    if (CHSI.MuniUW.QuoteObject.Appearance == null) {
        throw new Error('Quote Object Appearance is null');
    }
    switch (ControlType) {
        case "LossColumn":
            return CHSI.MuniUW.QuoteObject.Appearance.LossColumns;
            break;
        case "RateVariables":
            return CHSI.MuniUW.QuoteObject.Appearance.RateVariables;
            break;
        case "Variables":
            return CHSI.MuniUW.QuoteObject.Appearance.Variables;
            break;
    }
};

CHSI.MuniUW.GetClientIDfromObject = function (ControlObject, ControlType) {
    switch (ControlType) {
        case "LossColumn":
            return 'LoN_' + ControlObject.ColumnValueID;
            break;
        case "RateVariables":
            return 'RaV_' + ControlObject.ProgramRateVariableID + '_' + ControlObject.RateInfoID;
            break;
        case "Variables":
            return 'VaR_' + ControlObject.VariableID;
            break;

    }

};

CHSI.MuniUW.GetVariable = function (VariableID) {
    if (CHSI.Common.DebugMode) {
        CHSI.Common.Log('Getting VariableID ' + VariableID);
    }


    for (var Variable in CHSI.MuniUW.QuoteObject.Vars) {
        if (CHSI.MuniUW.QuoteObject.Vars[Variable].VariableID == VariableID) {
            return CHSI.MuniUW.QuoteObject.Vars[Variable];
        }
    }

    for (var CL in CHSI.MuniUW.QuoteObject.CL) {
        if (CHSI.Common.DebugMode) {
            CHSI.Common.Log('CL ' + CL + ', ' + CHSI.MuniUW.QuoteObject.CL[CL].Rates.length)
        }

        for (var RateIndex = 0 ; RateIndex < CHSI.MuniUW.QuoteObject.CL[CL].Rates.length; RateIndex++) {
            if (CHSI.Common.DebugMode) {
                CHSI.Common.Log('Rate Index - ' + RateIndex);
            }

            for (var Variable in CHSI.MuniUW.QuoteObject.CL[CL].Vars) {
                if (CHSI.MuniUW.QuoteObject.CL[CL].Vars[Variable].VariableID == VariableID) {
                    return CHSI.MuniUW.QuoteObject.CL[CL].Vars[Variable];
                }
            }
        }
    }
};


CHSI.MuniUW.GetRateInfoVariable = function (ProgramRateVariableID, RateInfoID) {
    for (var CL in CHSI.MuniUW.QuoteObject.CL) {
        if (CHSI.Common.DebugMode) {
            CHSI.Common.Log('CL ' + CL + ', ' + CHSI.MuniUW.QuoteObject.CL[CL].Rates.length)
        }

        for (var RateIndex = 0 ; RateIndex < CHSI.MuniUW.QuoteObject.CL[CL].Rates.length; RateIndex++) {
            if (CHSI.Common.DebugMode) {
                CHSI.Common.Log('Rate Index - ' + RateIndex);
            }

            for (var RateType in CHSI.MuniUW.QuoteObject.CL[CL].Rates[RateIndex].RateTypes) {
                var RateDetail = CHSI.MuniUW.QuoteObject.CL[CL].Rates[RateIndex].RateTypes[RateType];
                if (CHSI.Common.DebugMode) {
                    CHSI.Common.Log('RateInfo - ' + RateDetail.RateInfoID);
                }

                if (RateDetail.RateInfoID == RateInfoID) {
                    if (CHSI.Common.DebugMode) {
                        CHSI.Common.Log('Rate Found');

                    }

                    for (var Variable in CHSI.MuniUW.QuoteObject.CL[CL].Rates[RateIndex].RateTypes[RateType].Vars) {
                        if (CHSI.Common.DebugMode) {
                            CHSI.Common.Log('Checking - ' + CHSI.MuniUW.QuoteObject.CL[CL].Rates[RateIndex].RateTypes[RateType].Vars[Variable].ProgramRateVariableID);
                        }

                        if (CHSI.MuniUW.QuoteObject.CL[CL].Rates[RateIndex].RateTypes[RateType].Vars[Variable].ProgramRateVariableID == ProgramRateVariableID) {

                            return CHSI.MuniUW.QuoteObject.CL[CL].Rates[RateIndex].RateTypes[RateType].Vars[Variable];
                        }
                    }
                }
            }
        }
    }
};

CHSI.MuniUW.GetQuoteObjectfromClientID = function (clientID) {
    if (CHSI.Common.DebugMode) {
        CHSI.Common.Log('getting quote object for ' + clientID);
    }

    switch (clientID.substring(0, 3)) {
        case "LoN":
            clientID = CHSI.Common.replaceString('LoN_', '', clientID);
            var ColumnValueID = clientID;

            break;
        case "RaV":
            var IDs = CHSI.Common.replaceString('RaV_', '', clientID).sCLit('_');
            var ProgramRateVariableID = IDs[0];
            var RateInfoID = IDs[1];
            if (CHSI.Common.DebugMode) {
                CHSI.Common.Log('Getting Rate Variable for ProgramRateVariableID - ' + ProgramRateVariableID + ' and RateInfoID - ' + RateInfoID);
            }

            return CHSI.MuniUW.GetRateInfoVariable(ProgramRateVariableID, RateInfoID);


            break;
        case "VaR":
            var VariableID = CHSI.Common.replaceString('VaR_', '', clientID);
            return CHSI.MuniUW.GetVariable(VariableID);
            break;


    }

};


CHSI.MuniUW.GetControlTypeFromClientID = function (clientID) {
    switch (clientID.substring(0, 3)) {
        case "LoN":
            return "LossColumn";
            break;
        case "RaV":
            return "RateVariables";
            break;
        case "VaR":
            return "Variables";
            break;

    }
};

CHSI.MuniUW.PrepareFunctions = function () {
    CHSI.HandleBars.Preparehelpers();
    CHSI.MuniUW.CurrentControl = null;
    CHSI.MuniUW.CurrentControlValue;
    CHSI.MuniUW.CurrentControlCachedValue;
    /*We want the first of a collection sometimes for creating headers*/
    Handlebars.registerHelper('GetAppearanceTitle', function (ObjectType, keyID, DisplayFilter) {
        var AppearanceCollection = CHSI.MuniUW.GetAppearanceCollection(ObjectType);

        var ret = "";
        var key = 'k' + keyID;
        for (var prop in AppearanceCollection) {
            if (prop == key) {
                var Display = true;
                if (DisplayFilter && AppearanceCollection[prop].DisplayMode != DisplayFilter) {
                    Display = false;
                }
                if (Display) {
                    ret = '<td class="VariableLabel">' + AppearanceCollection[prop].DisplayTitle + '</td>';
                }

            }
        }
        return new Handlebars.SafeString(ret);
    });


    Handlebars.registerHelper('GetAddCoverageLineControl', function () {
        return new Handlebars.SafeString(CHSI.MuniUW.GetAddCoverageLineControl());
    });

    Handlebars.registerHelper('GetAddCoverageLineSelectList', function () {
        return new Handlebars.SafeString(CHSI.MuniUW.GetAddCoverageLineSelectList());
    });

    Handlebars.registerHelper('GetAppearanceDisplayValue', function (ObjectType, keyID, DisplayFilter) {
        var AppearanceCollection = CHSI.MuniUW.GetAppearanceCollection(ObjectType);
        var ret = "";
        var key = 'k' + keyID;
        for (var prop in AppearanceCollection) {
            if (prop == key) {
                var Display = true;
                if (DisplayFilter && AppearanceCollection[prop].DisplayMode != DisplayFilter) {
                    Display = false;
                }
                if (Display) {

                    ret = '<td class="VariableLabel">' + AppearanceCollection[prop].DisplayTitle + '</td>';
                }
            }
        }
        return new Handlebars.SafeString(ret);
    });


    /*We want the first of a collection sometimes for creating headers*/
    Handlebars.registerHelper('GetAppearanceControl', function (ObjectType, keyID, SourceObject, DisplayFilter) {
        var AppearanceCollection = CHSI.MuniUW.GetAppearanceCollection(ObjectType);
        var ret = "";
        var key = 'k' + keyID;
        if (CHSI.Common.DebugMode) {
            CHSI.Common.Log('Key: ' + key);
        }
        for (var prop in AppearanceCollection) {
            if (prop == key) {
                var Display = true;
                if (DisplayFilter && AppearanceCollection[prop].DisplayMode != DisplayFilter) {
                    Display = false;
                }
                if (Display) {
                    var locked = '';
                    if (SourceObject.isLocked == true) {
                        locked = '<img src="/images/icons/warning_red_16.png" alt="Modified by user" style="vertical-align:top; margin-top:5px;">';
                    }
                    ret = '<td class="VariableValue">' + CHSI.MuniUW.GetControlType(AppearanceCollection[prop], SourceObject, ObjectType) + locked + '</td>';
                }
            }
        }
        return new Handlebars.SafeString(ret);
    });
    Handlebars.registerHelper('GetQuotePropertyTextbox', function (PropertyName, ContentType) {

        return new Handlebars.SafeString(CHSI.MuniUW.GetQuotePropertyDisplay(PropertyName, ContentType));
    });
};
/* The source object has to imCLement the interface in order for this to have any hope of working*/
CHSI.MuniUW.GetControlType = function (AppearanceTemplate, SourceObject, ObjectType) {
    var ControlType;
    if (AppearanceTemplate.inEditMode) {
        ControlType = AppearanceTemplate.EditControlType;
    }
    else {
        ControlType = AppearanceTemplate.ReadOnlyControlType;
    }
    CHSI.Common.Log('Creating Appearance Control - ' + ControlType + ' - Display Title: ' + AppearanceTemplate.DisplayTitle)
    var ControlStart, ControlEnd;

    if (ControlType == 2) {
        ControlStart = '<span>'
        ControlEnd = '</span>'
    }
    if (ControlType == 3) {
        ControlStart = '<input type="text" '

        if (AppearanceTemplate.inEditMode == false) {
            ControlStart += ' readonly ';
        }

        else {
            ControlStart += ' ' + CHSI.MuniUW.GetSaveEventCode(SourceObject, ObjectType) + ' ';
        }
        /* Generate ClientID */
        ControlStart += 'id="' + CHSI.MuniUW.GetClientIDfromObject(SourceObject, ObjectType) + '" ';

        ControlStart += ' value="';
        ControlEnd = '" />'
    }
    if (ControlType == 4) {
        ControlStart = 'radiobutton'
        ControlEnd = '" '
    }
    if (ControlType == 5) {
        ControlStart = 'checkbox'
        ControlEnd = '" '
    }
    if (ControlType == 8) {
        ControlStart = 'select list'
        ControlEnd = '" '
    }
    if (ControlType == 10) {
        ControlStart = 'vertical radio'
        ControlEnd = '" '
    }
    if (ControlType == 11) {
        ControlStart = ' vertical checkbox'
        ControlEnd = '" '
    }
    var CleanValue = SourceObject.GetValue;
    if (CleanValue == 'null' || CleanValue == null) {
        CleanValue = '';
    }
    return ControlStart + CleanValue + ControlEnd

};
CHSI.MuniUW.GetSaveEventCode = function (SourceObject, ObjectType) {
    var HTML = [];
    //HTML.push('    onChange="CHSI.MuniUW.ShowSaveWindow(\'QuoteVariable\',0,this);" ');
    HTML.push('    class="Variable" ');
    return HTML.join('');
};

/* UW LowerSection buttons */
CHSI.MuniUW.MenuController = function (QuoteObj, xTarget) {
    var HTML = [];

    HTML.push('<a class="actionButton" href="/sig/underwriting/main.aspx?clientid=' + QuoteObj.ClientID + '&pagemode=3">Go To Client</a>');

    HTML.push('<a class="actionButton" onclick="CHSI.MuniUW.ShowNewNote(' + QuoteObj.ClientID + ')" href="#">New Note</a>');

    HTML.push('<a class="actionButton" onclick="CHSI.MuniUW.ShowNewTask(' + QuoteObj.ClientID + ')" href="#">New Task</a>');

    HTML.push('<a class="actionButton" onclick="CHSI.MuniUW.ShowCopyQuote(' + QuoteObj.ClientID + ', ' + QuoteObj.QuoteID + ')" href="#">Copy Quote</a>');

    HTML.push("<a class='actionButton' onclick='CHSI.MuniUW.DeleteQuote(" + QuoteObj.QuoteID + ", " + '"' + QuoteObj.GroupID + '"' + ")' href='#'>Delete Quote</a>");

    $(xTarget).html(HTML.join(''));
};

CHSI.MuniUW.ShowNewNote = function (ClientID) {
    var NoteURL = '/utilities/actionitemedit.aspx?ClientID=' + ClientID;
    ShowPopup("NewNote", NoteURL, "New Note", 750, 530, null, false);
};

CHSI.MuniUW.ShowNewTask = function (ClientID) {
    var TaskURL = '/utilities/taskedit.aspx?ClientID=' + ClientID;
    ShowPopup("NewTask", TaskURL, "New Task", 870, 600, null, false);
};

CHSI.MuniUW.ShowCopyQuote = function (ClientID, QuoteID) {
    var CopyQuoteURL = '/UW/Modals/CopyQuote.aspx?ClientID=' + ClientID + '&QuoteID=' + QuoteID;
    ShowPopup("CopyQuote", CopyQuoteURL, "Copy Quote", 250, 200, null, false);
};

CHSI.MuniUW.DeleteQuote = function (QuoteID, GroupID) {
    var Response = confirm('Are you sure you want to delete this quote?');
    if (Response) {
        var Options = {};
        Options.URL = '/api/UW/MuniQuote/DeleteQuote?QuoteID=' + QuoteID;
        Options.Success = function () {
            alert('Quote deleted.');
            window.location = '/sig/underwriting/uwmain.aspx#GroupName=' + GroupID + '&TabID=2&TabName=QUOTED';
        };
        Options.Error = function () { alert('You do not have access to that function.'); };
        CHSI.Common.Ajax.Delete(Options);
    }
};

/* End UW LowerSection buttons */

CHSI.MuniUW.SaveOriginalValue = function (e) {
    CHSI.MuniUW.CurrentControlValue = jQuery(e).val();
};
CHSI.MuniUW.CacheValue = function () {
    CHSI.MuniUW.CurrentControlCachedValue = CHSI.MuniUW.CurrentControlValue;
};

CHSI.MuniUW.ShowSaveWindow = function (ObjectType, ObjectID, SourceControl) {
    var ClientID = SourceControl.id;
    var ControlType = CHSI.MuniUW.GetControlTypeFromClientID(ClientID);
    var SourceObject = CHSI.MuniUW.GetQuoteObjectfromClientID(ClientID);
    CHSI.MuniUW.CurrentControl = SourceControl;
    var HTML = [];
    HTML.push('<div>');
    HTML.push('Please add any comments for your change.')
    HTML.push('<textarea id="jxChangecomments" rows="4"  style="width:300px;"></textarea>');
    HTML.push('<div style="text-align:right;"><input type="button" value="Save" onclick="CHSI.MuniUW.SaveVariable(\'' + ClientID + '\',false);"/> <input type="button" value="Cancel" onclick="javascript: CHSI.MuniUW.CancelChange(\'' + ClientID + '\');"/></div>');
    HTML.push('</div>');

    ShowPagePopup(HTML.join(''), 'Save Confirmation', 350, 180, 'ClaimSaveConfirmation', true);
};
CHSI.MuniUW.CancelChange = function (clientID) {
    var SourceObject = CHSI.MuniUW.GetQuoteObjectfromClientID(clientID);
    jQuery('#' + clientID).val(SourceObject.GetValue);
    parent.CHSI.DOM.Modal.CloseModal('ClaimSaveConfirmation');

};
CHSI.MuniUW.SetValue = function (DataObject, DataType, NewValue) {
    DataObject.GetValue = NewValue;
    switch (DataType) {
        case 1:
            DataObject.StringValue = NewValue;
            break;
        case 2:
            DataObject.DateValue = NewValue;
            break;
        case 3:
            DataObject.DecimalValue = NewValue;
            break;
        case 4:
            DataObject.BoolValue = NewValue;
            break;
        case 5:
            DataObject.IntegerValue = NewValue;
            break;
    }
};

CHSI.MuniUW.SetDefaultValue = function (DataObject, DataType, NewValue) {
    DataObject.GetValue = NewValue;
    switch (DataType) {
        case 1:
            DataObject.DefaultStringValue = NewValue;
            break;
        case 2:
            DataObject.DefaultDateValue = NewValue;
            break;
        case 3:
            DataObject.DefaultDecimalValue = NewValue;
            break;
        case 4:
            DataObject.DefaultBoolValue = NewValue;
            break;
        case 5:
            DataObject.DefaultIntegerValue = NewValue;
            break;
    }
};

CHSI.MuniUW.GetDefaultValue = function (DataObject, DataType) {
    var result;

    switch (DataType) {
        case 1:
            result = DataObject.DefaultStringValue; break;
        case 2:
            result = DataObject.DefaultDateValue; break;
        case 3:
            result = DataObject.DefaultDecimalValue; break;
        case 4:
            result = DataObject.DefaultBoolValue; break;
        case 5:
            result = DataObject.DefaultIntegerValue; break;

            return result;
    }
};

CHSI.MuniUW.SaveVariable = function (ClientID, ForceAutoCalculation) {
    var ControlType = CHSI.MuniUW.GetControlTypeFromClientID(ClientID);
    var SourceObject = CHSI.MuniUW.GetQuoteObjectfromClientID(ClientID);
    var ajaxData = {};
    /*This portion is identical for all Formula Object saves*/
    ajaxData.DataObject = {};
    ajaxData.Comments = jQuery('#jxChangecomments').val();
    ajaxData.DataObject.isLocked = 1;
    ajaxData.DataObject.StringValue = SourceObject.StringValue;
    ajaxData.DataObject.DateValue = SourceObject.DateValue;
    ajaxData.DataObject.IntegerValue = SourceObject.IntegerValue;
    ajaxData.DataObject.DecimalValue = SourceObject.DecimalValue;
    ajaxData.DataObject.BoolValue = SourceObject.BoolValue;
    ajaxData.DataObject.DataType = SourceObject.DataType;
    ajaxData.DataObject.QuoteID = CHSI.MuniUW.QuoteObject.QuoteID;
    ajaxData.isAutoCalculated = SourceObject.isAutoCalculated;
    ajaxData.ForceAutoCalculation = ForceAutoCalculation;
    ajaxData.Status = 'active';
    if (!ForceAutoCalculation) {
        CHSI.MuniUW.SetValue(ajaxData.DataObject, SourceObject.DataType, jQuery('#' + ClientID).val());
    }
    var AjaxURL;
    switch (ControlType) {
        case "LossColumn":
            AjaxURL = '';
            break;
        case "RateVariables":
            AjaxURL = '/api/UW/MuniQuote/SaveQuoteRateVariable';
            if (ForceAutoCalculation) {
                AjaxURL = '/api/UW/MuniQuote/RecalculateQuoteRateVariable';
            }

            ajaxData.ProgramRateVariableID = SourceObject.ProgramRateVariableID;
            ajaxData.RateInfoID = SourceObject.RateInfoID;
            break;
        case "Variables":
            AjaxURL = '/api/UW/MuniQuote/SaveQuoteVariable';
            if (ForceAutoCalculation) {
                AjaxURL = '/api/UW/MuniQuote/RecalculateQuoteVariable';
            }
            ajaxData.VariableID = SourceObject.VariableID;
            break;
    }
    var ajaxString = JSON.stringify(ajaxData);
    if (CHSI.Common.DebugMode) {
        CHSI.Common.Log('value - ' + AjaxURL + ', Data: ' + ajaxString);
    }
    jQuery.ajax({
        type: "PUT",
        url: AjaxURL,
        data: ajaxString,
        processData: false,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (e) {
            parent.CHSI.DOM.Modal.CloseModal('ClaimSaveConfirmation');
            CHSI.MuniUW.QuoteObject = e;
            CHSI.MuniUW.RefreshGUI();
        }, error: CHSI.DOM.ProcessAjaxError
    });

};

CHSI.MuniUW.GetAddCoverageLineSelectList = function () {
    var html = [];
    var availableCL = 0;
    html.push('<select id="addNewCoverageLine" name="addNewCoverageLine">');

    for (var newCL in CHSI.MuniUW.QuoteObject.CoverageLineInfo) {

        var showCoverageLine = true;

        for (var CL in CHSI.MuniUW.QuoteObject.CL) {
            //showCoverageLine = (CL != newCL);
            if (CL == newCL) {
                showCoverageLine = false;
            }
        }

        if (showCoverageLine) {
            html.push('<option value="'
                + CHSI.MuniUW.QuoteObject.CoverageLineInfo[newCL].CoverageLineID + '">'
                + CHSI.MuniUW.QuoteObject.CoverageLineInfo[newCL].CoverageLineName + '</option>');

            availableCL++;
        }

    }

    if (availableCL > 0) {
        html.push('</select>');
        html.push('<input type="button" id="addCL" value="Add Product Line"/>');
    } else {
        html[0] = '<select id="addNewCoverageLine" name="addNewCoverageLine" disabled="disabled">'
            + '<option>No available product lines </option>'
            + '</select>';
    }

    return html.join('');

};

/*Quote Functionality*/


CHSI.MuniUW.SaveQuoteProperty = function (PropertyName, SourceObject) {
    var NewValue = jQuery(SourceObject).val();
    var ajaxData = {};
    ajaxData.PropertyName = PropertyName;
    ajaxData.QuoteID = CHSI.MuniUW.QuoteObject.QuoteID;
    ajaxData.NewValue = NewValue;
    var ajaxString = JSON.stringify(ajaxData);
    var AjaxURL = '/api/UW/MuniQuote/SaveQuoteproperty';
    if (CHSI.Common.DebugMode) {
        CHSI.Common.Log('Updating Property value with URL ' + AjaxURL + ' and Data: ' + ajaxString);
    }
    jQuery.ajax({
        type: "PUT",
        url: AjaxURL,
        data: ajaxString,
        processData: false,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (e) {
            if (CHSI.Common.DebugMode) {
                CHSI.Common.Log('Successfully Saved Property');
            }
            CHSI.MuniUW.QuoteObject = e;
            CHSI.MuniUW.RefreshGUI();
        }, error: CHSI.DOM.ProcessAjaxError
    });
};

CHSI.MuniUW.GetQuotePropertyDisplay = function (PropertyName, ContentType) {
    var ret = '<input type="text" id="jxQuoteProp' + PropertyName + '"  ';
    if (PropertyName == '')
    { }
    switch (PropertyName) {
        case "ProgramName":
        case "Status":
            ret += ' disabled=disabled ';
            break;
        case "PolicyPeriod":
            ret += ' disabled=disabled ';
            break;
        default:
            ret += ' onChange="CHSI.MuniUW.SaveQuoteProperty(\'' + PropertyName + '\',this);" '
    }
    ret += ' value="';
    switch (ContentType) {
        case "Date":
            ret += CHSI.Dates.GetShortDate(new Date(CHSI.MuniUW.QuoteObject[PropertyName]));
            break;
        default:
            ret += CHSI.MuniUW.QuoteObject[PropertyName];
    }
    ret += '" />';
    return ret;
};

