module CL.Common.APIs {

    export class AttachmentCategoryRepository {
        getAttachmentCategories(): JQueryDeferred<Models.AttachmentCategory[]> {
            var Results = jQuery.Deferred();
            $.get('/API/Common/AttachmentCategory/Get').done(
                function (data) {
                    Results.resolve(Models.AttachmentCategory.parseJSONArray(data));
                }
               );
            return Results;
        }

    }

}