CHSI.Arrays = new Object();

CHSI.Arrays.ReturnEmpty2DimensionalMatrix = function (RowCount, ColumnCount) {
    var Matrix = new Array(RowCount);
    for (var row = 0; row < RowCount; row++) {
        Matrix[row] = new Array(ColumnCount);
        for (var column = 0; column < ColumnCount; column++) {
            Matrix[row][column] = '';
        }
    }
    return Matrix;

};
