module PL.Que.Models.Data {

    export class DataStructureColumn  {

        ColumnID: number;
        DataStructureID: number;
        DataCategoryID: number;
        DataSubCategoryID: number;
        DataTypeID: number;
        DataTypeName: string;
        DefaultStringValue: string;
        DefaultIntegerValue: number;
        DefaultDateValue: string | Date;
        DefaultDecimalValue: number;
        DefaultBitValue: boolean;
        DefaultValueFormula: string;
        SortOrder: number;
        CalculationFormula: string;
        CalculationProcessOrder: number;
        FieldName: string;
        DataCategory: string;
        Description: string;
        CreateUser: string;
        CreateTmsp: string | Date;
        UpdateUser: string;
        UpdateTmsp: string | Date;

        save(): void {
            var result = $.Deferred<number>();
            throw new Error('Save not yet constructed');
        }

    }

}