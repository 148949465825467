CHSI.Claims.ClaimIntake.ClaimIntakeForm = {
    Init: function (qs) {
        var Template_ClaimIntakeForm = $('#Template_ClaimIntakeForm').html();
        this.Templates.ClaimIntakeForm = Handlebars.compile(Template_ClaimIntakeForm);

        this.Cache.IncidentID = qs.IncidentID;
        if (this.Cache.IncidentID == null || this.Cache.IncidentID == undefined || this.Cache.IncidentID == 0) {
            this.Cache.IncidentID = 0;
        }
        this.Cache.FormTypeID = qs.FormTypeID;
        this.Cache.ClientID = qs.ClientID;
        if (this.Cache.ClientID == null) {
            this.Cache.ClientID = CurrentClientID;
        }

        this.Calls.GetCategories(function () {
            CHSI.Claims.ClaimIntake.ClaimIntakeForm.Binds.Categories(function () {
                CHSI.Claims.ClaimIntake.Start.Init(CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.FormTypeID, CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClientID, CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.IncidentID);
            });
        });

        this.Events.Global();
    },    
    Templates: {},
    Cache: {
        ClaimIntakeObject: {
            NewIncidentObject: {}
        },
        Progress: 0,
        PreviousIndex: null,
        isValidated: false,        
    },
    Binds: {
        Categories: function (successFunction) {
            $('#ClaimIntakeFormContainer').html(CHSI.Claims.ClaimIntake.ClaimIntakeForm.Templates.ClaimIntakeForm({ ClaimIntakeObject: CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject }));

            CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.UpdateActiveNavigationLink(0);

            if (successFunction) {
                successFunction();
            }

            $('#jxInnerProgressBar').css('width', 0);
        }
    },
    Events: {
        Global: function () {
            // This adds the ability to allow the user to select the category links in order to traverse the form in addition to clicking the next and previous buttons.

            //$('#ClaimIntakeFormContainer').on('click', '.DisplayForm', function () {
            //    var CategoryName = $(this).data('categoryname'),
            //        FormCategoryID = $(this).data('formcategoryid'),
            //        CurrentIndex = $(this).data('index');

            //    $('#jxNext').data('currentindex', CurrentIndex);
            //    $('#jxNext').data('formcategoryid', '');
            //    $('#jxPrevious').data('currentindex', CurrentIndex);
            //    $('#jxPrevious').data('formcategoryid', '');

            //    CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.GetRequestedForm(CategoryName, FormCategoryID);
            //    CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.UpdateActiveNavigationLink(CurrentIndex);
            //});

            $('#ClaimIntakeFormContainer').on('click', '#jxPrevious', function () {
                var FormCategoryID = $(this).data('formcategoryid'),
                    CurrentIndex = $(this).data('currentindex'),
                    Fields = $('.FieldType').data('index'),
                    PreviousIndex = CurrentIndex - 1;

                $(this).attr('disabled', true);
                $('#jxPreparing').show();

                CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.ValidateFields(CurrentIndex, function () {
                    CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.RequestFieldSave(function () {
                        CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.TrackProgress(PreviousIndex, FormCategoryID);
                        CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.NavigateForms('#jxPrevious', '#jxNext', true);
                    });
                });                
            });

            $('#ClaimIntakeFormContainer').on('click', '#jxNext', function () {
                var FormCategoryID = $(this).data('formcategoryid'),
                    CurrentIndex = $(this).data('currentindex'),
                    Fields = $('.FieldType').data('index'),
                    MextIndex = CurrentIndex + 1;

                $(this).attr('disabled', true);
                $('#jxPreparing').show();

                if (CurrentIndex == 0) { // we're on the start page
                    CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.RequestIncidentSave(function (IncidentID) {
                        if (CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.IncidentID == null) {
                            CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.IncidentID = IncidentID;
                        }

                        CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.TrackProgress(MextIndex, FormCategoryID);
                        CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.NavigateForms('#jxNext', '#jxPrevious', false);
                    });                                                                        
                }
                else {
                    CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.ValidateFields(CurrentIndex, function () {
                        CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.RequestFieldSave(function () {
                            CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.TrackProgress(MextIndex, FormCategoryID);
                            CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.NavigateForms('#jxNext', '#jxPrevious', false);
                        });
                    });
                }                
            });

            $('#ClaimIntakeFormContainer').on('click', '#jxCancel', function () {
                closeThis(true);
            });

            $('#ClaimIntakeFormContainer').on('click', '#jxFinish', function () {
                // run notifications?
            });
        },

        GetRequestedForm: function (categoryName, formCategoryID) {
            var ClaimIntakeObject = CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject;

            switch (categoryName) {
                case 'Start':
                    CHSI.Claims.ClaimIntake.Start.Init(CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.FormTypeID, CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClientID, CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.IncidentID);
                    break;
                case 'Finish':
                    CHSI.Claims.ClaimIntake.Finish.Init(CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.IncidentID, CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.FormTypeID);
                    break;
                default:
                    for (Index = 0; Index < ClaimIntakeObject.Categories.length; Index++) {
                        if (formCategoryID == ClaimIntakeObject.Categories[Index].FormCategoryID) {
                            CHSI.Claims.ClaimIntake.Dynamic.Init(ClaimIntakeObject.Categories[Index], CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.IncidentID);
                            return true;
                        }
                    }
            }
        },

        ValidateFields: function (index, successFunction) {
            if ($('form').valid()) {                
                successFunction();
            } else {                
                alert('Please complete the form before proceeding.');
                return false;
            }
        },

        ConfirmIsValid: function (index, isValid) {
            var CurrentCategory = $('#jxCategoryLink_' + index);

            CurrentCategory.data('isvalidated', isValid);                
        },

        TrackProgress: function (index, formCategoryID) {
            var ProgressBar = $('#jxInnerProgressBar'),
                Categories = CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.Categories,
                NumberOfCategories = Categories.length,
                CurrentProgress = (100 / NumberOfCategories).toFixed(2),
                TotalProgress = CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.Progress,
                PreviousIndex = CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.PreviousIndex;

            CurrentProgress = parseFloat(CurrentProgress);
            
            for (CategoryCount = 0; CategoryCount < NumberOfCategories; CategoryCount++) {
                if (Categories[CategoryCount].FormCategoryID == formCategoryID || Categories[CategoryCount].CategoryName == 'Start' || Categories[CategoryCount].CategoryName == 'Finish') {

                    if (index > PreviousIndex || PreviousIndex == null) {
                        TotalProgress = TotalProgress += CurrentProgress;                        
                    }else{
                        TotalProgress = TotalProgress -= CurrentProgress;
                    }                    

                    ProgressBar.css('width', TotalProgress + '%');
                    CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.Progress = TotalProgress;
                    CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.PreviousIndex = index;
                    return true;
                }
            }

            // In order to update the progress bar based on whether or not a category/form was validated, use the "UpdateFormProgress" method. Otherwise, the "TrackProgress" method just updates the progress bar based on your position within the form.
        },

        //UpdateFormProgress: function (index, formCategoryID, isFormCompleted) {
        //    var ProgressBar = $('#jxInnerProgressBar'),
        //        Categories = CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.Categories,
        //        NumberOfCategories = Categories.length,
        //        CurrentProgress = (100 / NumberOfCategories).toFixed(2),
        //        TotalProgress = CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.Progress;

        //    CurrentProgress = parseFloat(CurrentProgress);

        //    if (!isFormCompleted || isFormCompleted == undefined) {
        //        for (CategoryCount = 0; CategoryCount < NumberOfCategories; CategoryCount++) {
        //            if (Categories[CategoryCount].FormCategoryID == formCategoryID || Categories[CategoryCount].CategoryName == 'Start' || Categories[CategoryCount].CategoryName == 'Finish') {

        //                TotalProgress = TotalProgress += CurrentProgress;
        //                ProgressBar.css('width', TotalProgress + '%');
        //                CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.Progress = TotalProgress;
        //                CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.ConfirmIsValid(index, true);
        //                return true;
        //            }
        //        }
        //    }
        //},

        UpdateActiveNavigationLink: function (index) {
            var DisplayForm = $('.DisplayForm'),
                CurrentIndex = 0;

            $('li a').removeClass('ActiveLink');

            $.each(DisplayForm, function (Index, Value) {
                CurrentIndex = $(this).data('index');

                if (CurrentIndex == index) {
                    $(this).addClass('ActiveLink');
                }
            });
        },

        NavigateForms: function (primaryTarget, secondaryTarget, isPrevious) {
            var Categories = CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.Categories,
                    CurrentIndex = $(primaryTarget).data('currentindex'),
                    NextIndex = 0,
                    NextCategoryID = 0,
                    NextCategoryName = null;

            if (isPrevious) {
                NextIndex = CurrentIndex * 1 - 1;
            } else {
                NextIndex = CurrentIndex * 1 + 1;
            }

            NextCategoryName = Categories[NextIndex].CategoryName;
            NextCategoryID = Categories[NextIndex].FormCategoryID;

            CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.GetRequestedForm(NextCategoryName, NextCategoryID);

            CHSI.Claims.ClaimIntake.ClaimIntakeForm.Events.UpdateActiveNavigationLink(NextIndex);

            $(primaryTarget).data('currentindex', NextIndex);
            $(primaryTarget).data('formcategoryid', Categories[NextIndex].FormCategoryID);
            $(secondaryTarget).data('currentindex', NextIndex);
            $(secondaryTarget).data('formcategoryid', Categories[NextIndex].FormCategoryID);
        },

        RequestFieldSave: function (successFunction) {
            CHSI.Claims.ClaimIntake.Dynamic.Calls.SaveFields(successFunction);
        },

        RequestIncidentSave: function (successFunction) {
            CHSI.Claims.ClaimIntake.Start.Calls.SaveIncident(successFunction);
        }
    },
    Calls: {
        GetCategories: function (successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/Form/GetIntakeForm?ClaimIncidentReportID=' + CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.IncidentID+'&FormTypeID='+CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.FormTypeID;
            Options.Data = null;
            Options.Success = function (data) {
                CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject = data;
                if (!(data && data.IncidentDate)) {
                    d = new Date();
                    CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.IncidentDate = (d.getUTCMonth() + 1) + '/' + d.getUTCDate() + '/' + d.getUTCFullYear();
                }
                CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.Categories.unshift({ CategoryName: 'Start' });
                CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.Categories.push({ CategoryName: 'Finish' });
                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Get(Options);
        }
    }
}