module PL.UW.LossHistory.Views {
    export class xMod_LossHistoryEdit_PriorCoverageLoss {
        $individual: JQuery;
        $claimNumber: JQuery;
        $medical: JQuery;
        $indemnity: JQuery;
        $paidActual: JQuery;
        $paidAdjusted: JQuery;
        $incurredActual: JQuery;
        $inccuredAdjusted: JQuery;
        $recovery: JQuery;
        $type: JQuery;
        $open: JQuery;
        $lossComments: JQuery;

        constructor() {
            this.$individual = $('#jxIndividual');
            this.$claimNumber = $('#jxClaimNumber');
            this.$medical = $('#jxMedical');
            this.$indemnity = $('#jxIndemnity');
            this.$paidActual = $('#jxPaidActual');
            this.$paidAdjusted = $('#jxPaidAdjusted');
            this.$incurredActual = $('#jxIncurredActual');
            this.$inccuredAdjusted = $('#jxIncurredAdjusted');
            this.$recovery = $('#jxRecovery');
            this.$type = $('#jxType');
            this.$open = $('#jxOpen');
            this.$lossComments = $('#jxLossComments');
        }

    }
} 