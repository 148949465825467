
CHSI.Math = {};


CHSI.Math.FormatNumber = function (num, decimalNum, bolLeadingZero, bolParens, bolCommas, bolPadDecimals)
    /**********************************************************************
    IN:
    NUM - the number to format
    decimalNum - the number of decimal places to format the number to
    bolLeadingZero - true / false - display a leading zero for
    numbers between -1 and 1
    bolParens - true / false - use parenthesis around negative numbers
    bolCommas - put commas as number separators.
     
    RETVAL:
    The formatted number!
    **********************************************************************/ {
    if (isNaN(parseInt(num))) return "NaN";

    var tmpNum = num;
    var iSign = num < 0 ? -1 : 1; 	// Get sign of number

    // Adjust number so only the specified number of numbers after
    // the decimal point are shown.
    tmpNum *= Math.pow(10, decimalNum);

    tmpNum = Math.round(Math.abs(tmpNum))
    tmpNum /= Math.pow(10, decimalNum);
    tmpNum *= iSign; 				// Readjust for sign

    // Create a string object to do our formatting on
    var tmpNumStr = new String(tmpNum);

    // See if we need to strip out the leading zero or not.
    if (!bolLeadingZero && num < 1 && num > -1 && num != 0)
        if (num > 0)
            tmpNumStr = tmpNumStr.substring(1, tmpNumStr.length);
        else
            tmpNumStr = "-" + tmpNumStr.substring(2, tmpNumStr.length);

    // See if we need to put in the commas
    if (bolCommas && (num >= 1000 || num <= -1000)) {
        var iStart = tmpNumStr.indexOf(".");
        if (iStart < 0)
            iStart = tmpNumStr.length;

        iStart -= 3;
        while (iStart >= 1) {
            if (iStart == 1 && tmpNumStr.charAt(0) == "-") {
                //We do not place a comma here, would be like saying -,1,000
                //This fixes continues even if we use the parans option theoretically it'd 
                //transform into (,1,000), but this conditional stops it.
            }
            else {
                tmpNumStr = tmpNumStr.substring(0, iStart) + "," + tmpNumStr.substring(iStart, tmpNumStr.length)
            }
            iStart -= 3;
        }
    }

    //handle partial decimals
    var decimalpos = tmpNumStr.indexOf(".") + 1;
    if (bolPadDecimals) {
        if (decimalpos == 0) {
            tmpNumStr = tmpNumStr + "."
            decimalpos = tmpNumStr.length;
            //For now, I'm ok with NOT forcing decimalNum positions, I just
            //want to avoid *partial* decimalNum, so we don't do anything
        }
        if (decimalpos + decimalNum != tmpNumStr.length) {
            while (decimalpos + decimalNum != tmpNumStr.length) {
                tmpNumStr = tmpNumStr + "0"
            }
        }
    }

    // See if we need to use parenthesis
    if (bolParens && num < 0)
        tmpNumStr = "(" + tmpNumStr.substring(1, tmpNumStr.length) + ")";
    //tmpNumStr = Left(tmpNumStr + '00', tmpNumStr.length);
    if (decimalNum == 0) {
        tmpNumStr = tmpNumStr.replace('.', '');
    }
    return tmpNumStr; 	// Return our formatted string!
}
CHSI.Math.Automove = function (currentlength, maxlength, boxtomove) {
    if (currentlength == maxlength) {
        document.getElementById(boxtomove).focus();
    }
}

CHSI.Math.SafeAdd = function (val1, val2, val3, val4, val5, val6, val7, val8) {
    var result = val1;

    if (val2 != undefined) {
        result = ((result * 10000) + (val2 * 10000)) / 10000;
    }
    if (val3 != undefined) {
        result = ((result * 10000) + (val3 * 10000)) / 10000;
    }
    if (val4 != undefined) {
        result = ((result * 10000) + (val4 * 10000)) / 10000;
    }
    if (val5 != undefined) {
        result = ((result * 10000) + (val5 * 10000)) / 10000;
    }
    if (val6 != undefined) {
        result = ((result * 10000) + (val6 * 10000)) / 10000;
    }
    if (val7 != undefined) {
        result = ((result * 10000) + (val7 * 10000)) / 10000;
    }
    if (val8 != undefined) {
        result = ((result * 10000) + (val8 * 10000)) / 10000;
    }
    return result;
}

CHSI.Math.SafeSubtract = function (val1, val2, val3, val4, val5, val6, val7, val8) {
    var result = val1;

    if (val2 != undefined) {
        result = ((result * 10000) - (val2 * 10000)) / 10000;
    }
    if (val3 != undefined) {
        result = ((result * 10000) - (val3 * 10000)) / 10000;
    }
    if (val4 != undefined) {
        result = ((result * 10000) - (val4 * 10000)) / 10000;
    }
    if (val5 != undefined) {
        result = ((result * 10000) - (val5 * 10000)) / 10000;
    }
    if (val6 != undefined) {
        result = ((result * 10000) - (val6 * 10000)) / 10000;
    }
    if (val7 != undefined) {
        result = ((result * 10000) - (val7 * 10000)) / 10000;
    }
    if (val8 != undefined) {
        result = ((result * 10000) - (val8 * 10000)) / 10000;
    }
    return result;
}

CHSI.Math.SafeMultiply = function (val1, val2, val3, val4, val5, val6, val7, val8) {
    var result = val1;
    if (val2 != undefined) {
        result = ((result * 10000) * (val2 * 10000)) / 100000000;

    }
    if (val3 != undefined) {
        result = ((result * 10000) * (val3 * 10000)) / 100000000;
    }
    if (val4 != undefined) {
        result = ((result * 10000) * (val4 * 10000)) / 100000000;
    }
    if (val5 != undefined) {
        result = ((result * 10000) * (val5 * 10000)) / 100000000;
    }
    if (val6 != undefined) {
        result = ((result * 10000) * (val6 * 10000)) / 100000000;
    }
    if (val7 != undefined) {
        result = ((result * 10000) * (val7 * 10000)) / 100000000;
    }
    if (val8 != undefined) {
        result = ((result * 10000) * (val8 * 10000)) / 100000000;
    }
    return result;
}

CHSI.Math.SafeDivide = function (val1, val2, val3, val4, val5, val6, val7, val8) {
    var result = val1;

    if (val2 != undefined) {
        result = (result * 10000) / (val2 * 10000);
    }
    if (val3 != undefined) {
        result = (result * 10000) / (val3 * 10000);
    }
    if (val4 != undefined) {
        result = (result * 10000) / (val4 * 10000);
    }
    if (val5 != undefined) {
        result = (result * 10000) / (val5 * 10000);
    }
    if (val6 != undefined) {
        result = (result * 10000) / (val6 * 10000);
    }
    if (val7 != undefined) {
        result = (result * 10000) / (val7 * 10000);
    }
    if (val8 != undefined) {
        result = (result * 10000) / (val8 * 10000);
    }
    return result;
}



CHSI.Math.round = function (value, precision, mode) {   

    var m, f, isHalf, sgn; // helper variables
    precision |= 0; // making sure precision is integer
    m = Math.pow(10, precision);
    value *= m;
    sgn = (value > 0) | -(value < 0); // sign of the number
    isHalf = value % 1 === 0.5 * sgn;
    f = Math.floor(value);

    if (isHalf) {
        switch (mode) {
            case 'ROUND_HALF_DOWN':
                value = f + (sgn < 0); // rounds .5 toward zero
                break;
            case 'ROUND_HALF_EVEN':
                value = f + (f % 2 * sgn); // rouds .5 towards the next even integer
                break;
            case 'ROUND_HALF_ODD':
                value = f + !(f % 2); // rounds .5 towards the next odd integer
                break;
            default:
                value = f + (sgn > 0); // rounds .5 away from zero
        }
    }

    return (isHalf ? value : Math.round(value)) / m;
}