module PL.Claims.Models {

    export class LateReasonCodes {

        LateCode: string;
        LateType: string;
        LateDescription: string;

        constructor() {
            this.LateCode = null;
            this.LateType = null
            this.LateDescription = null;
        }

        getLateReasons(): JQueryDeferred<Models.LateReasonCodes[]> {
            var results = $.Deferred();
            PL.Claims.APIs.ClaimRepo.getLateReasons().done(function (data) {
                results.resolve(data);
            });        
            return results;
        }

    }

}