namespace PL.UW.Quotes.APIs {

    
    export class QuoteStatus_Repo {

        private static _routePrefix: string = '/api/UW/QuoteStatus';

        private static getQuoteStatuses(): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            $.get(this._routePrefix + 'GetQuoteStatuses').done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        public static saveQuoteStatues(quoteStatusObject: any): JQueryDeferred<number> {
            var $result = $.Deferred<any>();
            mW.io.post(this._routePrefix + 'SaveQuoteStatuses', quoteStatusObject).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

    }

}