CHSI.Que.StructureEdit = {
    Init: function (qs) {

       

        this.Cache.StructureID = qs.structureid;
       

        if (this.Cache.StructureID) {
            this.Calls.Fields.GetStructureFields(function () {
                CHSI.Que.StructureEdit.Binds.Fields();
            });
            /* show and bind the dependent tabs only if we already have a data structure*/
            this.Binds.DependentTabs();
        }  

        this.Events.Global();
    },
    Cache: {
        StructureID: null
        ,FieldObject: {}
        , DependentTabsBound: false
    },
    Binds: {
        Fields: function () {
            var fieldObj = CHSI.Que.StructureEdit.Cache.FieldObject;
            var FieldStamp = 'Created - ' + fieldObj.CreateUser + ' ' + CHSI.Common.Dates.GetShortDate(fieldObj.CreateTmsp) + ' (Updated by ' + fieldObj.UpdateUser + ' ' + CHSI.Common.Dates.GetShortDate(fieldObj.UpdateTmsp) + ')';

            $('#jxFieldName').val(fieldObj.StructureName);
            $('#jxFieldStatus').val(fieldObj.Status);
            $('#jxFieldDescription').html(fieldObj.StructureDescription);            
            $('#jxFieldStamp').html(FieldStamp);
        }
        , DependentTabs: function ()
        {
            $('#jxStructureCategoriesTab').show();
            $('#jxStructureSubCategoriesTab').show();
            $('#jxStructureFieldsTab').show();
            CHSI.Que.DataStructureCategories.Init({ StructureID: CHSI.Que.StructureEdit.Cache.StructureID });
            CHSI.Que.DataStructureSubCategories.Init({ StructureID: CHSI.Que.StructureEdit.Cache.StructureID });
            CHSI.Que.DataStructureFields.Init({ StructureID: CHSI.Que.StructureEdit.Cache.StructureID });
            CHSI.Que.StructureEdit.Cache.FieldObject.DependentTabsBound = true;
        }
    },
    Events: {
        Global: function () {
            $('#tabStructure').on('change', '.AutoSave', function () {
                
                var fieldObject = CHSI.Que.StructureEdit.Cache.FieldObject;
                fieldObject.StructureName = $('#jxFieldName').val();
                fieldObject.Status = $('#jxFieldStatus').val();
                fieldObject.StructureDescription = $('#jxFieldDescription').val();
                if (CHSI.Que.StructureEdit.Cache.StructureID) {
                    fieldObject.StructureID = CHSI.Que.StructureEdit.Cache.StructureID;
                }
                CHSI.Que.StructureEdit.Calls.Fields.SaveStructureFields();
            });
        }
    },
    Calls: {
        Fields: {
            GetStructureFields: function (successFunction) {
                var Options = {};
                Options.URL = '/api/Que/DataStructure/GetDataStructureFields?StructureID=' + CHSI.Que.StructureEdit.Cache.StructureID;
                Options.Success = function (data) {
                    CHSI.Que.StructureEdit.Cache.FieldObject = data;
                    if (successFunction) {
                        successFunction();
                    }
                };
                CHSI.Common.Ajax.Get(Options);
            },
            SaveStructureFields: function () {
                var Options = {};
                Options.URL = '/api/Que/DataStructure/SaveDataStructureFields';
                Options.Data = CHSI.Que.StructureEdit.Cache.FieldObject;
                Options.Success = function (data) {
                    CHSI.Que.StructureEdit.Cache.StructureID = data;
                    if (CHSI.Que.StructureEdit.Cache.DependentTabsBound == false)
                    {
                        CHSI.Que.StructureEdit.Binds.DependentTabs();
                    }
                    
                  
                };
                CHSI.Common.Ajax.Post(Options);
            }
        }
    }
}