CHSI.Enums = {
    Object: {
        ConnectionsObject: [
              [1, 'Provider']
            , [2, 'Clients']
            , [3, 'ProviderGroups']
            , [4, 'TPAClaims']
            , [5, 'FileAttachments']
            , [6, 'Assessments']
            , [7, 'BankAccount']
            , [8, 'QueueEmail']
            , [10, 'DynDesignTemplate']
            , [11, 'DynResultSet']
            , [12, 'DynDataStructure']
            , [13, 'DynDataStructureColumn']
            , [14, 'DynFormElement']
            , [15, 'DynFormSection']
            , [16, 'DynForm']
            , [17, 'DynResultSetValue']
            , [18, 'TPAClaimPayments']
            , [19, 'TPAClaimReserves']
            , [20, 'TPAClaimNotes']
            , [21, 'Locations']
            , [22, 'DynFormNotification']
            , [23, 'UW_Rate']
            , [24, 'SummaryClientDemogrpahics']
        ]
      , MetaAttributes: [
              [1, 'Primary_Key']
			, [2, 'Formatting']
			, [3, 'List_Source_ID']
			, [4, 'Mask_Type']
			, [5, 'Content_Type']
			, [6, 'Grid_Source_ID']
			, [7, 'Print_Header']
			, [8, 'Print_Footer']
			, [9, 'Due_Date']
			, [10, 'Show_Prior_Response']
			, [11, 'Recurrance']
			, [12, 'Target_Storage_Type']
			, [13, 'Target_Storage_FieldID']
			, [14, 'Response_Required']
			, [15, 'Attachment_CategoryID']
			, [16, 'Required']
			, [17, 'Visible_on_Portal']
			, [18, 'Default_Values_Generated']
			, [19, 'N_A']
			, [20, 'Readonly']
			, [21, 'Review_Note']
			, [22, 'Save_Destination_Path']
			, [23, 'Show_Add_New_Button']
			, [24, 'Display_Title']
			, [25, 'Use_Default_Value_as_Default']
			, [26, 'Element_Filter']
			, [27, 'Create_Default_Value']
			, [28, 'Show_No_Change_Option']
			, [29, 'Requires_Review']
      ]
    },
    Utilities: {
        GetEnumValue: function (EnumObject, Key, AdditionalResponse) {
            var EnumValue = null;            
            for (i = 0; i < EnumObject.length; i++) {
                if (EnumObject[i][0] == Key) {                    
                    EnumValue = EnumObject[i][1];
                    if (EnumValue.indexOf('_') > 0) {                        
                        EnumValue.replace(/_/g, " ");
                    }                    
                    if (AdditionalResponse != null) {
                        EnumValue = EnumValue + ' ' + AdditionalResponse;
                    } else {
                        EnumValue;
                    }
                    return EnumValue;
                }
            }
        }
    }
};

