CHSI.DOM = {

    CreateHashObject: function (hash) {
        var hashVarObj = {};
        var hashArr = hash.replace("#", "").split("&");
        for (var i = 0; i < hashArr.length; i++) {
            var t = hashArr[i].split("=");
            hashVarObj[t[0]] = t[1];
        }
        return hashVarObj;
    }

};
CHSI.DOM.CachedElements = {};

CHSI.DOM.ClearCache = function () {
    CHSI.DOM.CachedElements = {};
}

CHSI.DOM.Get = function (selector, context) {
    var ElementKey = selector;
    if (context) {
        ElementKey += '_' + context;
    }
    var DOMObject = CHSI.DOM.CachedElements[ElementKey];
    if (DOMObject) {
        return DOMObject;
    }
    else {
        DOMObject = jQuery(selector, context);
        CHSI.DOM.CachedElements[ElementKey] = DOMObject;
        return DOMObject;
    }
}

CHSI.DOM.ProcessAjaxError = function (xmlHttpRequest, textStatus, errorThrown) {
    if (xmlHttpRequest)
    {
        if (xmlHttpRequest.responseText) {
            if (xmlHttpRequest.responseText.indexOf('User Session has expired') != '-1') {
                alert('Your session timeout has expired, redirecting the page');
                location.href = location.href;

            }
            else if (xmlHttpRequest.readyState == 0 || xmlHttpRequest.status == 0) {
                CHSI.Common.Log('Error 0 being ignored');
                return;  // it's not really an error
            }
            else {
                alert(xmlHttpRequest.status + ' ' + xmlHttpRequest.statusText);
            }
        }
    }
    
} 
