CHSI.Page.ClaimSearch = new Object();

//#region "Global Variables"
CHSI.Page.ClaimSearch.Groups = new Array();
CHSI.Page.ClaimSearch.GroupLineID = [];
CHSI.Page.ClaimSearch.PolicyPeriods = new Array();
CHSI.Page.ClaimSearch.BuildClaimTypeListHTML = [];
CHSI.Page.ClaimSearch.Coverages = new Array();
CHSI.Page.ClaimSearch.Coverages.Index = [];
CHSI.Page.ClaimSearch.Coverages.Value = [];
CHSI.Page.ClaimSearch.ClaimTypes = new Array();
CHSI.Page.ClaimSearch.ClaimTypesSpecific = [];
CHSI.Page.ClaimSearch.SelectedClaimTypes = new Array();
CHSI.Page.ClaimSearch.SelectedOption = null;
CHSI.Page.ClaimSearch.SearchOptionsID = 0;
CHSI.Page.ClaimSearch.GridOutputID = 0;
CHSI.Page.ClaimSearch.CurrentOptionIndex = 0;
CHSI.Page.ClaimSearch.Template_SearchParameters = '';
CHSI.Page.ClaimSearch.Template_ClaimSearch_FilterOptions = '';
CHSI.Page.ClaimSearch.Template_ClaimSearch_GroupResults = '';
CHSI.Page.ClaimSearch.Template_ClaimSearch_ClientResults = '';
CHSI.Page.ClaimSearch.FormFilterOptions = {
    SearchLevel: 'group',
    ClientID: '',
    IncludeChildren: false,
    SearchOptions: []    
};

CHSI.Page.ClaimSearch.SearchOption = function () {
    this.FilterType = '';
    this.FilterPosition = '';
    this.FilterValue1 = '';
    this.FilterValue2 = '';
    this.FilterOperator = '';
};

CHSI.Page.ClaimSearch.decimalNumberPattern = /(^(\+|\-)(0|([1-9][0-9]*))(\.[0-9]{1,2})?$)|(^(0{0,1}|([1-9][0-9]*))(\.[0-9]{1,2})?$)/;
//#endregion "Global Variables

//#region "Init Functions"
CHSI.Page.ClaimSearch.Init = function (FilterOptionsID, GridOutputID) {
    
    CHSI.Common.Log('running');
    Handlebars.registerHelper('GenerateSearchOptions', function () {
        return new Handlebars.SafeString(CHSI.Page.ClaimSearch.ReturnSearchParameterHTML(0));
    });

    Handlebars.registerHelper('PopulateOptionDropdown', function () {
        var returnList = '';

        if (CHSI.Page.ClaimSearch.FormFilterOptions.SearchLevel == 'client' || CHSI.Page.ClaimSearch.FormFilterOptions.SearchLevel == 'portal') {
            returnList = returnList + '<option value="policyperiod">Policy Period</option>';
            returnList = returnList + '<option value="coverage">Coverage</option>';
            returnList = returnList + '<option value="claim class">Claim Class</option>';
            returnList = returnList + '<option value="claim type">Claim Type</option>';
            returnList = returnList + '<option value="date of incident">Date of Incident</option>';
            returnList = returnList + '<option value="date opened">Date Opened</option>';
            returnList = returnList + '<option value="date closed">Date Closed</option>';
            returnList = returnList + '<option value="claim number">Claim Number</option>';
            returnList = returnList + '<option value="claimant">Claimant</option>';
            returnList = returnList + '<option value="county">County</option>';
            returnList = returnList + '<option value="status">Status</option>';
            returnList = returnList + '<option value="paid">Paid</option>';
            returnList = returnList + '<option value="reserves">Reserves</option>';
            returnList = returnList + '<option value="incurred">Incurred</option>';

        } else if (CHSI.Page.ClaimSearch.FormFilterOptions.SearchLevel == 'group') {
            returnList = returnList + '<option value="policyperiod">Policy Period</option>';
            returnList = returnList + '<option value="coverage">Coverage</option>';
            returnList = returnList + '<option value="clientid">Client Name</option>';
            returnList = returnList + '<option value="groupid">Group</option>';
            returnList = returnList + '<option value="claim class">Claim Class</option>';
            returnList = returnList + '<option value="claim type">Claim Type</option>';
            returnList = returnList + '<option value="date of incident">Date of Incident</option>';
            returnList = returnList + '<option value="date opened">Date Opened</option>';  
            returnList = returnList + '<option value="date closed">Date Closed</option>';
            returnList = returnList + '<option value="claim number">Claim Number</option>';
            returnList = returnList + '<option value="claimant">Claimant</option>';
            returnList = returnList + '<option value="county">County</option>';
            returnList = returnList + '<option value="status">Status</option>';
            returnList = returnList + '<option value="paid">Paid</option>'
            returnList = returnList + '<option value="reserves">Reserves</option>';
            returnList = returnList + '<option value="incurred">Incurred</option>';
        }
        CHSI.Page.ClaimSearch.Events();
        return new Handlebars.SafeString(returnList);
    });

    Handlebars.registerHelper('GetClaimDetailsURL', function (ClaimVersion, ClaimID, ClientID) {
        //javascript: ShowPopup('ClaimView', 'modaltpadetails.aspx?ClaimKey={{this.ClaimID}}&clientid={{this.ClientID}}
        if (ClaimVersion == 'v1') {
            return 'javascript: ShowPopup("ClaimView", "modaltpadetails.aspx?ClaimKey=' + ClaimID + '&clientid=' + ClientID + '", "Claim", 900, 600, ModalRefresh, true);';
        } else if (ClaimVersion == 'v2') {
            return '/Claims/ClaimsMain.aspx?ClaimID=' + ClaimID;
        }
    });

    CHSI.Page.ClaimSearch.SearchOptionsID = FilterOptionsID;
    CHSI.Page.ClaimSearch.GridOutputID = GridOutputID;
    CHSI.Page.ClaimSearch.GetTemplates();
};

CHSI.Page.ClaimSearch.GetTemplates = function () {

    var ResultsTemplate = ''

    if (CHSI.Page.ClaimSearch.FormFilterOptions.SearchLevel == 'client') {
        ResultsTemplate = CHSITemplates.Template_ClaimSearch_ClientResults;
    } else if (CHSI.Page.ClaimSearch.FormFilterOptions.SearchLevel == 'group') {
        ResultsTemplate = CHSITemplates.Template_ClaimSearch_GroupResults;
    } else if (CHSI.Page.ClaimSearch.FormFilterOptions.SearchLevel == 'portal') {
        ResultsTemplate = CHSITemplates.Template_ClaimSearch_PortalResults;
    }

    CHSI.Forms.ajaxFormDetails = CHSITemplates.Template_ClaimSearch_FilterOptions;
    CHSI.Page.ClaimSearch.Template_ClaimSearch_FilterOptions = CHSI.Forms.ajaxFormDetails;

    
        CHSI.Page.ClaimSearch.Template_SearchParameters = CHSI.Forms.ajaxFormDetails;

        CHSI.Forms.ajaxFormDetails = ResultsTemplate

        CHSI.Page.ClaimSearch.Template_ClaimSearch_Results = CHSI.Forms.ajaxFormDetails
        CHSI.Page.ClaimSearch.ShowFilterOptions();        
};

CHSI.Page.ClaimSearch.ShowFilterOptions = function () {

    var filtertemplate = CHSITemplates.Template_ClaimSearch_FilterOptions;
    jQuery('#' + CHSI.Page.ClaimSearch.SearchOptionsID).html(filtertemplate(CHSI.Page.ClaimSearch.FormFilterOptions));

    CHSI.Page.ClaimSearch.GetGroups(function () {
        CHSI.Page.ClaimSearch.GetPolicyPeriods(function () {          
            CHSI.Page.ClaimSearch.GetCoverageLines(function () {
                CHSI.Page.ClaimSearch.GetClaimTypes(function () {
                    CHSI.Page.ClaimSearch.BuildSearchOptionInputs(0);
                });
            });            
        });
    });
};
//#endregion

//#region "Web API Calls"
CHSI.Page.ClaimSearch.GetGroups = function (successfunction) {
    CHSI.Common.Log('Loading selected groups');
    
    jQuery.ajax({
        type: "GET",
        url: "/api/CRM/Group/GetActiveGroups",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        cache: false,
        success: function (e) {

            CHSI.Common.LogObject(e);

            jQuery.each(e, function (index, value) {
                CHSI.Page.ClaimSearch.Groups.push(value.GroupName);
            });

            if (successfunction) {
                successfunction();
            }
        }, error: CHSI.DOM.ProcessAjaxError
    });
};

CHSI.Page.ClaimSearch.GetCoverageLines = function (successfunction) {
    CHSI.Common.Log('Loading selected groups');

    var ajaxData = {};
    if (CHSI.Page.ClaimSearch.FormFilterOptions.ClientID && CHSI.Page.ClaimSearch.FormFilterOptions.ClientID != '') {
        ajaxData.ClientID = CHSI.Page.ClaimSearch.FormFilterOptions.ClientID;
    } else {
        ajaxData.ClientID = 0;
    }
    
    jQuery.ajax({
        type: "GET",
        url: "/api/ClaimSystem/Claim/GetCoverageTypes",
        data: ajaxData,
        contentType: "application/json; charset=utf-8",
        cache: false,
        success: function (e) {
            CHSI.Common.LogObject(e);

            jQuery.each(e, function (index, value) {
                var isExists = false;
                jQuery.each(CHSI.Page.ClaimSearch.Coverages, function (aryindex, aryvalue) {
                    if (value == aryvalue) {
                        isExists = true;
                    }                    
                });

                if (isExists == false) {
                    CHSI.Page.ClaimSearch.Coverages.Index.push(index);
                    CHSI.Page.ClaimSearch.Coverages.Value.push(value);
                }
            });

            if (successfunction) {
                successfunction();
            }
        }, error: CHSI.DOM.ProcessAjaxError
    });
};

CHSI.Page.ClaimSearch.GetPolicyPeriods = function (successfunction) {
    CHSI.Common.Log('Loading Policy Periods');

    var ajaxData = {};
    if (CHSI.Page.ClaimSearch.FormFilterOptions.ClientID != '') {
        ajaxData.ClientID = CHSI.Page.ClaimSearch.FormFilterOptions.ClientID;
    }
   
    jQuery.ajax({
        type: "GET",
        url: "/api/CRM/PolicyYear/GetAvailablePolicyYear",
        data: ajaxData,
        contentType: "application/json; charset=utf-8",
        cache: false,
        success: function (e) {
            CHSI.Common.LogObject(e);

            jQuery.each(e, function (index, value) {
                CHSI.Page.ClaimSearch.PolicyPeriods.push(value);
            });

            if (successfunction) {
                successfunction();
            }
        }, error: CHSI.DOM.ProcessAjaxError
    });
};

CHSI.Page.ClaimSearch.GetClaimTypes = function (successfunction) {
    CHSI.Common.Log('Getting an array of appropriate claim types')

    var ajaxData = {};
    ajaxData.Coverages = '';

    jQuery.ajax({
        type: "GET",
        url: "/api/ClaimSystem/Claim/GetClaimTypes",
        data: ajaxData,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        cache: false,
        success: function (e) {
            jQuery.each(e, function (index, value) {
                CHSI.Page.ClaimSearch.ClaimTypes.push(value);
            });

            if (successfunction) {
                successfunction();
            }
        }
    });
};

CHSI.Page.ClaimSearch.GetAllClaimTypes = function (successfunction) {
    jQuery.ajax({
        type: "GET",
        url: "/api/ClaimSystem/Claim/GetAllClaimTypes",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        cache: false,
        success: function (e) {
            CHSI.Common.LogObject(e);

            jQuery.each(e, function (index, value) {
                CHSI.Page.ClaimSearch.ClaimTypesSpecific.push(value);
            });
            if (successfunction) {
                successfunction();
            }
        }
    });
};

CHSI.Page.ClaimSearch.FilterResults = function () {
    CHSI.Common.Log('Filtering Results');
    CHSI.Page.ClaimSearch.FormFilterOptions.SearchOptions = CHSI.Page.ClaimSearch.BuildSearchOptionArray();
    var ajaxData = CHSI.Page.ClaimSearch.FormatFormFilterOptions();

    var search = new PL.Claims.ClaimSearch('ResultsGrid');
    search.GetFilteredClaims(ajaxData);
};
//#endregion

//#region "Events"
CHSI.Page.ClaimSearch.Events = function () {

}
//#endregion

//#region "Builder Methods"
CHSI.Page.ClaimSearch.ReturnSearchParameterHTML = function (newIndex) {
    var filtertemplate = CHSITemplates.Template_ClaimSearch_Parameters;
    var SearchOption = {};
    SearchOption.FilterIndex = newIndex;
    SearchOption.ShowAdditionalFilter = (newIndex < 10);
    var HTML = filtertemplate(SearchOption);
    return HTML;
};

CHSI.Page.ClaimSearch.BuildSearchOptionArray = function () {
    var arySelectedOptions = jQuery('.SearchOptionRow');
    var aryFormattedOptions = [];

    jQuery.each(arySelectedOptions, function () {
        var option = new CHSI.Page.ClaimSearch.SearchOption;
        var currentOption = jQuery(this);

        if (currentOption.find('input.FilterValue1').first().val() && currentOption.find('input.FilterValue1').first().val() != '') {
            option.FilterType = currentOption.find('select.searchoption').first().val();
            if (currentOption.find('input.FilterValue1.Currency').first().val()) {
                option.FilterValue1 = currentOption.find('input.FilterValue1.Currency').first().val();
                option.FilterValue1 = option.FilterValue1.replace('$', '');
                option.FilterValue1 = option.FilterValue1.split(',').join('');
            } else {
                option.FilterValue1 = currentOption.find('input.FilterValue1').first().val();
            }

            if (currentOption.find('select.FilterPosition').first().val()) {
                option.FilterPosition = currentOption.find('select.FilterPosition').first().val();
                if (currentOption.find('input.FilterValue2').first().val()) {
                    if (option.FilterPosition == 'between') {
                        option.FilterValue2 = currentOption.find('input.FilterValue2').first().val();
                    }
                }
            }

            if (currentOption.find('select.FilterOperator').first().val()) {
                option.FilterOperator = currentOption.find('select.FilterOperator').first().val();
            }
            aryFormattedOptions.push(option);
        } else if (currentOption.find('select.FilterValue1').first().val() && currentOption.find('select.FilterValue1').first().val() != '') {
            option.FilterType = currentOption.find('select.searchoption').first().val();
            option.FilterValue1 = currentOption.find('select.FilterValue1').first().val();

            if (currentOption.find('select.FilterOperator').first().val()) {
                option.FilterOperator = currentOption.find('select.FilterOperator').first().val();
            }
            aryFormattedOptions.push(option);
        }
    });

    return aryFormattedOptions;
};

CHSI.Page.ClaimSearch.BuildSearchOptionInputs = function (index) {
    var selectedOption = jQuery('#jxSelectedOption' + index).val();
    CHSI.Page.ClaimSearch.SelectedOption = selectedOption;
    CHSI.Page.ClaimSearch.CurrentOptionIndex = index;

    switch (selectedOption) {
        case 'date of incident':
            var jxSearchOption = jQuery('#SearchFields' + index);
            var html = CHSI.Page.ClaimSearch.BuildDateFilter(index, selectedOption);
            jxSearchOption.html(html.join(''));
            break;
        case 'date opened':
            var jxSearchOption = jQuery('#SearchFields' + index);
            var html = CHSI.Page.ClaimSearch.BuildDateFilter(index, selectedOption);
            jxSearchOption.html(html.join(''));
            break;
        case 'date closed':
            var jxSearchOption = jQuery('#SearchFields' + index);
            var html = CHSI.Page.ClaimSearch.BuildDateFilter(index, selectedOption);
            jxSearchOption.html(html.join(''));
            break;
        case 'claimant':
            var jxSearchOption = jQuery('#SearchFields' + index);
            var html = CHSI.Page.ClaimSearch.BuildTextFilter(index, selectedOption);
            jxSearchOption.html(html.join(''));
            break;
        case 'claim number':
            var jxSearchOption = jQuery('#SearchFields' + index);
            var html = CHSI.Page.ClaimSearch.BuildTextFilter(index, selectedOption);
            jxSearchOption.html(html.join(''));
            break;
        case 'county':
            var jxSearchOption = jQuery('#SearchFields' + index);
            var html = CHSI.Page.ClaimSearch.BuildTextFilter(index, selectedOption);
            jxSearchOption.html(html.join(''));
            break;
        case 'paid':
            var jxSearchOption = jQuery('#SearchFields' + index);
            var html = CHSI.Page.ClaimSearch.BuildNumericTextFilter(index, selectedOption);
            jxSearchOption.html(html.join(''));
            break;
        case 'reserves':
            var jxSearchOption = jQuery('#SearchFields' + index);
            var html = CHSI.Page.ClaimSearch.BuildNumericTextFilter(index, selectedOption);
            jxSearchOption.html(html.join(''));
            break;
        case 'incurred':
            var jxSearchOption = jQuery('#SearchFields' + index);
            var html = CHSI.Page.ClaimSearch.BuildNumericTextFilter(index, selectedOption);
            jxSearchOption.html(html.join(''));
            break;
        case 'groupid':
            var html = new Array();
            var jxSearchOption = jQuery('#SearchFields' + index)

            html.push('<select style="width: 150px; vertical-align: middle;" id="uxGroup' + index + '" class="FilterValue1">')
            for (currentIndex = 0; currentIndex < CHSI.Page.ClaimSearch.Groups.length; currentIndex++) {
                html.push('<option value="' + CHSI.Page.ClaimSearch.Groups[currentIndex] + '">' + CHSI.Page.ClaimSearch.Groups[currentIndex] + '</option>');
            }
            html.push('</select>')
            jxSearchOption.html(html.join(''));
            break;
        case 'clientid':
            var html = new Array();
            var jxSearchOption = jQuery('#SearchFields' + index)
            html.push('<span id="SelectedClient' + index + '" style="color:maroon; vertical-align: middle;">No Client Selected</span>&nbsp;&nbsp;');
            html.push('<a style="background:#eee;border-radius:4px;padding:3px;border:1px solid #aaa; vertical-align: middle;"   href="Javascript: ChooseClient()" >Select Client</a>');
            html.push('<input type="hidden" id="ClientID' + index + '" value="" class="FilterValue1">')
            jxSearchOption.html(html.join(''));
            break;
        case 'status':
            var html = new Array();
            var jxSearchOption = jQuery('#SearchFields' + index)
            html.push('<select style="width: 150px; vertical-align: middle;" id="uxStatus' + index + '" class="FilterValue1">')
            html.push('<option value="open">Open</option>');
            html.push('<option value="closed">Closed</option>');
            html.push('</select>')
            jxSearchOption.html(html.join(''));
            break;
        case 'coverage':
            var html = new Array();
            var jxSearchOption = jQuery('#SearchFields' + index)

            html.push('<select style="width: 150px; vertical-align: middle;" onchange="CHSI.Page.ClaimSearch.ChangeGetGroupLineID()" id="uxCoverage' + index + '" class="FilterValue1 SelectedCoverage">')
            html.push('<option value=" ">  </option>');
            for (currentIndex = 0; currentIndex < CHSI.Page.ClaimSearch.Coverages.Index.length; currentIndex++) {
                html.push('<option data-grouplineid="' + CHSI.Page.ClaimSearch.Coverages.Value[currentIndex] + '" value="' + CHSI.Page.ClaimSearch.Coverages.Index[currentIndex] + '">' + CHSI.Page.ClaimSearch.Coverages.Index[currentIndex] + '</option>');
            }
            html.push('</select>')
            jxSearchOption.html(html.join(''));
            break;
        case 'policyperiod':
            var html = new Array();
            var jxSearchOption = jQuery('#SearchFields' + index)

            html.push('<select style="width: 150px; vertical-align: middle;" id="uxPolicyPeriod' + index + '" class="FilterValue1">')
            for (currentIndex = 0; currentIndex < CHSI.Page.ClaimSearch.PolicyPeriods.length; currentIndex++) {
                html.push('<option value="' + CHSI.Page.ClaimSearch.PolicyPeriods[currentIndex] + '">' + CHSI.Page.ClaimSearch.PolicyPeriods[currentIndex] + '</option>');
            }
            html.push('</select>')
            jxSearchOption.html(html.join(''));
            break;
        case 'claim type':
            var jxSearchOption = jQuery('#SearchFields' + index);
            var html = [];            
                CHSI.Page.ClaimSearch.GetAllClaimTypes(BuildClaimTypeList);

                function BuildClaimTypeList() {
                    html.push(CHSI.Page.ClaimSearch.BuildClaimTypeList(index, selectedOption));
                    jxSearchOption.html(html.join(''));
                }

            jxSearchOption.html(html.join(''));
            break;
        case 'claim class':
            var html = new Array();
            var jxSearchOption = jQuery('#SearchFields' + index)
            html.push('<select style="width: 150px; vertical-align: middle;" id="uxClaimClass' + index + '" class="FilterValue1 ClaimClassSelect">')
            html.push(CHSI.Page.ClaimSearch.BuildClaimClassOptions());
            html.push('</select>')
            jxSearchOption.html(html.join(''));
            break;
    }

    CHSI.Page.ClaimSearch.BuildDatePickers();
    jQuery('.ui-datepicker-trigger').css({ 'vertical-align': 'middle' });
};

CHSI.Page.ClaimSearch.BuildDateFilter = function (index, filtertype) {
    var html = new Array();
    html.push('<select id="' + filtertype + '' + index + '" onchange="CHSI.Page.ClaimSearch.ChangeDateFilterOptions(this)" class="FilterPosition" style="width: 100px; vertical-align: middle;">');
    html.push('<option value="between">Between</option>');
    html.push('<option value="equals">Equals</option>');
    html.push('<option value="before">Before</option>');
    html.push('<option value="after">After</option>');
    html.push('</select>&nbsp;');
    html.push('<input type="text" id="' + filtertype + 'From' + index + '" class="dateOption FilterValue1" style="width: 70px; vertical-align: middle;" />');
    html.push('<span id="' + filtertype + 'Options' + index + '">');
    html.push(' AND ');
    html.push('<input type="text" id="' + filtertype + 'To' + index + '" class="dateOption FilterValue2" style="width: 70px; vertical-align: middle;" />');
    html.push('</span>');
    return html;
};

CHSI.Page.ClaimSearch.BuildTextFilter = function (index, filtertype) {
    var html = new Array();
    html.push('<select id="' + filtertype + '' + index + '" class="FilterPosition" style="width: 100px; vertical-align: middle;">');
    html.push('<option value="begins with">Begins With</option>');
    html.push('<option value="ends with">Ends With</option>');
    html.push('<option value="contains">Contains</option>');
    html.push('<option value="equals">Equals</option>');
    html.push('</select>&nbsp;');
    html.push('<input type="text" id="' + filtertype + '' + index + '" class="FilterValue1" style="width: 210px; vertical-align: middle;" />');
    return html;
};

CHSI.Page.ClaimSearch.BuildNumericTextFilter = function (index, filtertype) {
    var html = new Array();
    html.push('<select id="' + filtertype + '' + index + '" class="FilterPosition" style="width: 100px; vertical-align: middle;">');
    html.push('<option value="equals">Equals</option>');
    html.push('<option value="less than">Less Than</option>');
    html.push('<option value="greater than">Greater Than</option>');
    html.push('</select>&nbsp;');
    html.push('<input type="text" id="' + filtertype + '' + index + '" class="FilterValue1 Currency" style="width: 210px; vertical-align: middle;" onblur="CHSI.Page.ClaimSearch.ValidateCurrencyField(this)" />');
    return html;
};

CHSI.Page.ClaimSearch.BuildClaimClassOptions = function () {
    var html = new Array();

    for (currentIndex = 0; currentIndex < CHSI.Page.ClaimSearch.ClaimTypes.length; currentIndex++) {
        html.push('<option value="' + CHSI.Page.ClaimSearch.ClaimTypes[currentIndex] + '">' + CHSI.Page.ClaimSearch.ClaimTypes[currentIndex] + '</option>');
    }

    return html;
};

CHSI.Page.ClaimSearch.BuildNewSearchOption = function (sourcecontrol) {
    var selectedBuildType = sourcecontrol.value;
    var elementid = selectedBuildType.id;
    var NewIndex = jQuery(".SearchOptionRow").length;
    if (selectedBuildType == 'none') {
        //Delete the current row
        var currentRow = jQuery(sourcecontrol);
        var rowToDelete = currentRow.parent().parent().nextAll('.SearchOptionRow').remove();
    } else {
        var isEdit = CHSI.Page.ClaimSearch.IsEdit(sourcecontrol);
        if (isEdit == false) {
            var html = CHSI.Page.ClaimSearch.ReturnSearchParameterHTML(NewIndex)
            var optionContainer = jQuery(".SearchOptionContainer tbody");
            optionContainer.append(html);
            CHSI.Page.ClaimSearch.BuildSearchOptionInputs(NewIndex);
        }
    }
};

CHSI.Page.ClaimSearch.BuildDatePickers = function () {
    jQuery(".dateOption").datepicker({ showOn: "both", buttonImage: "/images/icons/cal.gif", buttonImageOnly: true });
};

CHSI.Page.ClaimSearch.BuildClaimTypeList = function (index, filtertype) {
    var html = [];
    var ClaimTypes = CHSI.Page.ClaimSearch.ClaimTypesSpecific;
    html.push('<select id="uxClaimTypesList' + index + '" class="FilterValue1" style="width: 150px; vertical-align: middle;">');
    $.each(ClaimTypes, function (ObjectIndex, Value) {
        html.push('<option value="' + ClaimTypes[ObjectIndex] + '">' + ClaimTypes[ObjectIndex] + '</option>');
    });
    html.push('</select>&nbsp;');
    return html;
};
//#endregion

//#region "Support and Control Action methods"
CHSI.Page.ClaimSearch.SearchClaims = function () {
    CHSI.GUI.AjaxStart();
    CHSI.Page.ClaimSearch.FilterResults();
};

CHSI.Page.ClaimSearch.FormatFormFilterOptions = function () {
    var SearchOptions = CHSI.Page.ClaimSearch.FormFilterOptions.SearchOptions;
    var ajaxData = {}    
    if (CHSI.Page.ClaimSearch.FormFilterOptions.ClientID == '') {
        ajaxData.ClientID = 0;
    } else {
        ajaxData.ClientID = CHSI.Page.ClaimSearch.FormFilterOptions.ClientID;
    }

    if ($('#jxChildClaims').is(':checked')) {
        ajaxData.IncludeChildren = true;
        
    } else {
        ajaxData.IncludeChildren = false;
        
    }

    ajaxData.SearchOptions = CHSI.Page.ClaimSearch.FormFilterOptions.SearchOptions;
    return ajaxData;
};

CHSI.Page.ClaimSearch.ChangeDateFilterOptions = function (sourceControl) {
    var selectedDateFilterType = jQuery(sourceControl);
    var jxDateFilterOption = selectedDateFilterType.nextAll('span').first();

    if (selectedDateFilterType.val() == 'between') {
        jxDateFilterOption.show();
    } else {
        jxDateFilterOption.hide();
    }
};

CHSI.Page.ClaimSearch.ChangeGetGroupLineID = function () {
    var Index = CHSI.Page.ClaimSearch.CurrentOptionIndex;
    var GroupLineID = $('.SelectedCoverage').find(':selected').data('grouplineid');
    CHSI.Page.ClaimSearch.GroupLineID = GroupLineID;
};

CHSI.Page.ClaimSearch.IsEdit = function (sourcecontrol) {
    var currentRow = jQuery(sourcecontrol);
    var currentID = currentRow.attr("id");
    var currentIndex = currentID.charAt(currentID.length - 1);
    var nextRow = jQuery('#jxSelectedOption' + ((currentIndex * 1) + 1));
    var nextID = nextRow.attr("id");

    if (nextID == null) {
        return false;
    } else {
        return true;
    }
};

CHSI.Page.ClaimSearch.GetSelectedClaimTypes = function () {
    CHSI.Page.ClaimSearch.SelectedClaimTypes.length = 0;
    var SelectedTypes = jQuery('.ClaimType');

    if (SelectedTypes.lenght > 0) {
        jQuery.each(SelectedTypes, function () {
            currentSelection = jQuery(this);
            CHSI.Page.ClaimSearch.SelectedClaimTypes.push(currentSelection.attr('id') + ',' + currentSelection.val());
        });
    }
};

CHSI.Page.ClaimSearch.ValidateEntry = function (objToValidate, pattern) {
    jQuery(objToValidate).val(jQuery.trim(objToValidate.value));
    var sanitizedInput = jQuery(objToValidate).val().replace(/[,_]/g, '');
    sanitizedInput = sanitizedInput.replace('$', '');
    if ((!(pattern.test(sanitizedInput))) && sanitizedInput) {
        jQuery(objToValidate).css({ "background-color": "#F6989D" });
        return false;
    }
    jQuery(objToValidate).css({ "background-color": "white" });
    return true;
};

CHSI.Page.ClaimSearch.ValidateCurrencyField = function (objToValidate) {
    if (!(CHSI.Page.ClaimSearch.ValidateEntry(objToValidate, CHSI.Page.ClaimSearch.decimalNumberPattern))) {
        alert('Please input a valid currency number');
        objToValidate.focus();
        return false;
    }
   
    return true;
};

CHSI.Page.ClaimSearch.FilterChildResults = function (filterChildren) {
    var ChildClaims = $('#jxChildClaims');
    if (filterChildren) {
        
    } else {

    }
};
//#endregion
