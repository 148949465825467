/* The system is currently designed with the thought process that we'd only allow one form be displayed on the page at any one time.*/

CHSI.Forms = {
    Settings: {
        ShowNoChangeCheckbox: false
        ,MaxElementExplanation: 200
    }
    , FormContainerID: ''
    , FormContainer: null
    , FooterPadding: 20 /* it naturally sizes to fit remaining page except for this amount */
    , CurrentActiveSectionIndex: 0
    , ResultID: 0
    , ReturnURL: ''
    , isPortal: false
    , ResultSetValues: null
    , PrimaryFormID: 0
    , ContentTypes: []
    , Forms: {}
    , FromID: null
    , InitialIndex: null
    , ActionCollection: []
    , NavigationCreated: false
    , ResultTagRegEx: new RegExp(/({Result[a-zA-Z0-9\.\ _\\\#\(\)\-\/\&\%\,\''\,]*})/ig)
    , CurrentValidationObjects: new Object
    , CurrentDesignTemplate: null
    , DebugMode: true
    , ajaxFormDetails: null
    , ajaxContainerHTML: null
    , DataStructure: null
    , CalculatedResults: null
    , ValidatedResults: null
    , ChangeResults: null
    , isFullPageMode: false
    , qsObject: null
    , BindEvents: function () {
        $('.NoChangeCheckbox').off('click').on('click', function () {
            var NoChangeCheckbox = jQuery(this);
            var EventInfo = NoChangeCheckbox.data('parent');
            var NewValue = '';

            CHSI.Forms.Results.MarkFormAsChanged();

            if (NoChangeCheckbox.is(':checked')) {
                NewValue = CHSI.Forms.Results.GetPriorResult(EventInfo.ColumnID, EventInfo.ValueIndex)
                CHSI.Forms.Elements.ChangeElementEditability(EventInfo.ElementID, EventInfo.ValueIndex, EventInfo.FormID, true);
                CHSI.Forms.Elements.UpdateElementValue(EventInfo.ElementID, EventInfo.ValueIndex, EventInfo.FormID, NewValue);
            }
            else {
                CHSI.Forms.Results.SetLastUserResult(EventInfo.ColumnID, EventInfo.ElementID, EventInfo.ValueIndex, EventInfo.FormID);
                CHSI.Forms.Elements.ChangeElementEditability(EventInfo.ElementID, EventInfo.ValueIndex, EventInfo.FormID, false);
            }

        });

        $('#FormRegion2').off('click', '.HelpIcon').on('click' , '.HelpIcon',function () {
            var HelpIcon = $(this);
            var HelpID = HelpIcon.data('help');
            var ParentElementID = HelpIcon.data('elementid');
            //alert(HelpID);
            CHSI.Help.Settings.ParentElement = $('#jxElementContainer_' + ParentElementID + '_0');
            CHSI.Help.Settings.ParentContainer = $('#FormRegion2');
            CHSI.Help.GetHelpInformation(HelpID);
        });
        $('#jxFormContainer').off('keyup', '.ExplanationField').on('keyup', '.ExplanationField', function () {
            var Field = $(this);
            if (Field.val().length > CHSI.Forms.Settings.MaxElementExplanation)
            {
                Field.val(Field.val().substr(0, CHSI.Forms.Settings.MaxElementExplanation));
            }
            
        });
        $('#jxFormContainer').off('change', '.ExplanationField').on('change', '.ExplanationField', function () {
            var ExplanationField = $(this);
            var ElementID = ExplanationField.data('elementid');
            CHSI.Forms.Results.SaveExplanation(ElementID, ExplanationField.val());
        });

        $('#jxFormContainer').off('click', '.SubmitQuestionnaire').on('click', '.SubmitQuestionnaire', function () {
            var FormID = $(this).data('formid');
                CHSI.Common.Page.ReadOnly(true, true, false);
                $(this).attr('readonly', true);
                $(this).css('background-color', 'gray');
                jQuery('#jxLoadingText').show();
                $('#jxFormContainer').hide();
            CHSI.Forms.Results.SaveForm(true, false, FormID, function () {

                if (CHSI.Forms.Results.isFormSaved) {                    
                    if (CHSI.Forms.isPortal) {
                        location.href = CHSI.Forms.ReturnURL;
                    } else {
                        closeThis(true);
                    }
                } else {                    
                    return false;
                }

            });

        });
        
    }
    ,

    RedirectToSchedule: function () {
        if (CHSI.Forms.isPortal) {
            if (SiteType == 'BrokerConnect') {
                location.href = '/ProducerClients/' + ClientID + '/UW/Schedules/default.aspx';
            } else {
                location.href = '/UW/Schedules/Default.aspx';
            }
        } else {
            closeThis(false);
            parent.location.href = '/sig/underwriting/Schedules.aspx?clientid=' + CHSI.Forms.qsObject.clientid;
        }
    },

    DisplayCurrentForm: function (formID) {

        var PolicyPeriod;
        var ouputHTML = [];
        /*Build form container if necessary*/
        CHSI.Forms.BuildFormContainer(false);
        CHSI.Forms.ReplaceTemplatePlaceholders(formID);

       CHSI.Forms.DebugMode =  CHSI.Common.DebugMode;
        /* Build Section Details */
        var jxSectionDetails = CHSI.DOM.Get('#jxSectionDetails');

        if (jQuery.trim(jxSectionDetails.html()) == '') {
            var SectionHeaderDetails = [];
            var ReportName = CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].ReportFormName;
            if (ReportName == '') {
                ReportName = 'Forms%5CDefaultQuestionaire.rpt'
            }
            var urlEncodedPrintURL = '/chsifilehandler.axd?reportname=' + ReportName + '&ReportType=1&ReportOptions=' + encodeURIComponent('FormID=' + CHSI.Forms.Forms['f' + formID].FormID + '&ResultSetID=' + CHSI.Forms.ResultID);
            var CurrentURL = document.URL;
            
            if (CHSI.Forms.isFullPageMode || CHSI.Forms.isPortal) {
            SectionHeaderDetails.push('<div style="text-align:right;"><a id="jxGoBack" href="' + CHSI.Forms.ReturnURL + '">Go Back</a> &nbsp&nbsp');
            SectionHeaderDetails.push('<a href="' + urlEncodedPrintURL + '" target="blank">Print Document</a></div>');
            } else {
                SectionHeaderDetails.push('<div style="text-align:right;"><a id="jxOpenInNewTab" href="' + CurrentURL + '&isFullPageMode=True" target="blank">Open in new tab</a> &nbsp&nbsp');
                SectionHeaderDetails.push('<a href="' + urlEncodedPrintURL + '" target="blank">Print Document</a></div>');
            }

            jxSectionDetails.html(SectionHeaderDetails.join(''));
        }        

        // Close the modal after we open it in a new window/tab
        var NewTab = $('#jxOpenInNewTab');
        NewTab.on('click', function () {
            setTimeout(function () {
                closeThis(true);
            }, 1000);
        });

        /* Build Nav*/
        var NavContainer = CHSI.DOM.Get('#jxFormNav');

        if (CHSI.Forms.NavigationCreated == false) {
            var NavHTML = [];

            NavHTML.push('<div id="FormNavRegion">');
            CHSI.Common.Log('Generating Section');
            NavHTML.push(CHSI.Forms.Navigation.GenerateNavigation(formID));
            NavHTML.push('</div>');
            NavContainer.html(NavHTML.join(''));
            CHSI.Forms.NavigationCreated = true;
        }
        else {
            CHSI.Forms.Navigation.UpdateSectionHTML();
        }
        
        /*build array containers per region*/
        var RegionHTML = CHSI.Forms.GetRegionHTMLArray(formID);       
        CHSI.Forms.ReplaceTemplatePlaceholders(formID);
        CHSI.Forms.ReplaceRegionsWithContent(RegionHTML);

        /* Get Grid Values */
        CHSI.Forms.Grid.ProcessGridElements(formID);

        CHSI.Forms.ResizeFormContainer();
        CHSI.Forms.Actions.ExecuteFormActions();
        CHSI.Forms.BindEvents();
        if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].isDisabled == false) {
            CHSI.Forms.Results.GetChangedResults(CHSI.Forms.ResultID, formID);
        }
    }
    , ReplaceTemplatePlaceholders: function (formID)
    {
        /* Form Details */
        var FormDetails = CHSI.DOM.Get('#jxFormDetails');
        if (jQuery.trim(FormDetails.html()) == '') {
            var FormDetailHTML = [];

            //loop through resultsetid to find the policyperiod
            var PolicyPeriodRegEx = /{Result\..*\.PolicyPeriod\.0}/g;
            var PolicyPeriodDisplayRegEx = /{Result\..*\.PolicyPeriodDisplay\.0}/g;
            var PolicyPeriod = '';
            var PolicyResult = null;
            var ShowPolicyPeriod = null;

            PolicyResult = CHSI.Forms.Results.GetResultSetValueByTagRegex(PolicyPeriodDisplayRegEx);

            if (PolicyResult == null || PolicyResult.StringValue == null) {
                PolicyResult = CHSI.Forms.Results.GetResultSetValueByTagRegex(PolicyPeriodRegEx);
                if (PolicyResult) {
                    PolicyPeriod = PolicyResult.IntegerValue;
                    ShowPolicyPeriod = PolicyPeriod + '-' + (PolicyPeriod + 1);
                }
            } else {
                ShowPolicyPeriod = PolicyResult.StringValue;
            }
                        
            FormDetailHTML.push('<h3><div style="vertical-align: bottom;width:150px; display:inline-block;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;" title="' + CHSI.Forms.Forms['f' + formID].FormClient.ClientName + '">' + CHSI.Forms.Forms['f' + formID].FormClient.ClientName + '</div>  ' + CHSI.Forms.Forms['f' + formID].Title + ': ' + ShowPolicyPeriod + '</h3>');
            /* Todo: Setup this next line to pick either the due date or submitted date depending on form status */
            if (CHSI.Forms.Forms['f' + formID].Status == 'Complete') {
                FormDetailHTML.push('Completed Date: ' + CHSI.Dates.GetShortDate(CHSI.Forms.Forms['f' + formID].DateOfStatus));
            }
            else {
                FormDetailHTML.push('Due Date: ' + CHSI.Dates.GetShortDate(CHSI.Forms.Forms['f' + formID].DueDate));
            }

            FormDetailHTML.push(' Status: <span id="jxStatusTitle" class="DynStatus_' + CHSI.Forms.Forms['f' + formID].Status.replace(' ', '_') + ' ">' + CHSI.Forms.Forms['f' + formID].Status + '</span>');

            FormDetails.html(FormDetailHTML.join(''));
        }
    }
    , ReplaceRegionsWithContent: function (RegionHTML)
    {
        /* Build Navigation */
        var NavigationHTML = [];
        NavigationHTML.push('<div id="FooterNav"></div>');

        for (var RegionIndex = 1, NumberRegions = CHSI.Forms.CurrentDesignTemplate.NumberRegions; RegionIndex <= NumberRegions; RegionIndex++) {
            var OutputHTML = [];
            OutputHTML.push(RegionHTML[RegionIndex - 1].Header);
            OutputHTML.push(RegionHTML[RegionIndex - 1].Elements.join(''));
            OutputHTML.push(RegionHTML[RegionIndex - 1].Footer);
            if (CHSI.Forms.CurrentDesignTemplate.NumberRegions == RegionIndex) {
                OutputHTML.push(NavigationHTML.join(''));
            }
            jQuery('#FormRegion' + RegionIndex).html(OutputHTML.join(''));
        }
    }
    , GetRegionHTMLArray: function (formID)
    {

        var RegionHTML = [];
        for (var RegionIndex = 0, NumberRegions = CHSI.Forms.CurrentDesignTemplate.NumberRegions; RegionIndex <= NumberRegions; RegionIndex++) {
            RegionHTML.push({ Header: '', Elements: [], Footer: '' });
            /* start the table*/
            RegionHTML[RegionIndex].Header = '<table cellspacing="0" cellpadding="2" width="100%" style="table-layout:fixed;"><tr><td id="jxFirstColumn"></td><td></td></tr><tr>';
        }

        /* build elements */
        jQuery(CHSI.Forms.Forms['f' + formID].Elements).each(function (index, element) {
            RegionHTML[element.TemplateRegionIndex].Elements.push(CHSI.Forms.Elements.ReturnRenderedElement(element, 0, formID));
        });
        /* close the region tables */
        for (var RegionIndex = 0, NumberRegions = CHSI.Forms.CurrentDesignTemplate.NumberRegions; RegionIndex < NumberRegions; RegionIndex++) {
            if (RegionHTML[RegionIndex].Elements.length > 0) {

                RegionHTML[RegionIndex].Footer = '</td></tr></table>';
            }
            else {
                if (CHSI.Forms.DebugMode) {
                    CHSI.Common.Log('Clearing Header');
                }
                RegionHTML[RegionIndex].Header = '';
            }
        }
        return RegionHTML;
    }
}
CHSI.Forms.CreateForm = function (qs, outputContainerID, isPortal, ReturnURL) {
    CHSI.Common.Log(qs);
    CHSI.Forms.qsObject = qs;
    CHSI.Common.Log(CHSI.Forms.qsObject);
    CHSI.Forms.FormContainerID = outputContainerID;
    /* Fix IE checkboxes */
    if (jQuery.browser.msie) {
        jQuery(function () {
            CHSI.DOM.Get('#' + CHSI.Forms.FormContainerID).on('click', 'input:radio, input:checkbox', function () {
                this.blur();
                this.focus();
            });
        });
    }

    if (ReturnURL == undefined) {
        ReturnURL = '/sig/underwriting/forms.aspx';
    }

    if (isPortal == null || isPortal == undefined) {
        isPortal = false;
    }

    CHSI.Forms.isFullPageMode = qs.isFullPageMode;
    if (CHSI.Forms.isFullPageMode == null || CHSI.Forms.isFullPageMode == undefined || CHSI.Forms.isFullPageMode == '') {
        CHSI.Forms.isFullPageMode = false;
    }
    CHSI.Forms.isPortal = isPortal;
    CHSI.Forms.ReturnURL = ReturnURL;
    CHSI.Forms.ResultID = qs.ResultID;
    CHSI.Forms.FormID = qs.FormID;
    CHSI.Forms.InitialIndex = qs.LastFormIndex;

    if (CHSI.Forms.InitialIndex == null || CHSI.Forms.InitialIndex == undefined || CHSI.Forms.InitialIndex == '') {
        CHSI.Forms.InitialIndex = 0;
    }

    CHSI.Forms.GetFormDisplay(CHSI.Forms.FormID, CHSI.Forms.InitialIndex);
    jQuery(window).on("resize", function () {
        CHSI.Forms.ResizeFormContainer();
    });
}

CHSI.Forms.GetFormMetaAttributeBoolean = function (FormInstance, MetaattributeID, DefaultValue) {
    var CurrentValue = DefaultValue;
    jQuery(FormInstance.MetaAttributes).each(function (index, metaattribute) {
        if (metaattribute.MetaType == MetaattributeID) {
            if (metaattribute.MetaValue == 1) {
                CurrentValue = true;
            }
            return false;
        }


    });
    return CurrentValue
};


CHSI.Forms.GetFormDisplay = function (formID, sectionIndex) {

    var ajaxLookup = new Object();
    ajaxLookup.formid = formID;
    ajaxLookup.sectionindex = sectionIndex;
    ajaxLookup.resultid = CHSI.Forms.ResultID;

    ajaxLookup.ElementsOnly = true;
    if (CHSI.Forms.PrimaryFormID == 0) {
        ajaxLookup.ElementsOnly = false;
        CHSI.Forms.PrimaryFormID = formID;
    }

    CHSI.Forms.CurrentActiveSectionIndex = sectionIndex;
    CHSI.DOM.ClearCache(); /*Rebuilding the page, clear the cache to be safe*/
    
    CHSI.Forms.ajaxFormDetails = jQuery.ajax({
        type: "GET", url: CHSI.Forms.APIs.QuoteDetailsAPI(),
        data: ajaxLookup,
        cache: false,
        contentType: "application/json; charset=utf-8",
        success: function (e) {
            CHSI.Forms.Forms['f' + formID] = e;
            if (CHSI.Forms.ResultSetValues == null) {
                CHSI.Forms.ResultSetValues = e.ResultSetValues;
            }
            if (CHSI.Forms.DataStructure == null) {
                CHSI.Forms.DataStructure = e.ParentDataStructure;
            }

            if (e.HelpIDs == null)
            {
                CHSI.Forms.HelpIDs = e.HelpIDs;                
            }


            CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].DataChanged = false;
            CHSI.Forms.Settings.ShowNoChangeCheckbox = CHSI.Forms.GetFormMetaAttributeBoolean(e, 28, 0);
            /* Replace all existing Actions with this section's actions*/
            CHSI.Forms.ActionCollection = CHSI.Forms.Actions.ProcessActions(CHSI.Forms.Forms['f' + formID].ActionBehaviors);

            if (CHSI.Forms.CurrentDesignTemplate == null) {
                CHSI.Forms.BuildContainerHTML(e.DesignTemplateID, formID);
            }
            else {
                jQuery('#jxFormMessageWindow').hide();
                CHSI.Forms.DisplayCurrentForm(formID);
            };

        }, error: CHSI.DOM.ProcessAjaxError
    });
};



/* This retrieves the outside email template and sets the HTML before calling the Display Form Contents*/
CHSI.Forms.BuildContainerHTML = function (templateid, formID) {
    CHSI.Forms.ajaxContainerHTML = jQuery.ajax({
        type: "POST", url: "/webservice/formfunctions.asmx/GetDesignTemplate",
        data: '{"templateid":"' + templateid + '"}',
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (e) {
            CHSI.Forms.CurrentDesignTemplate = e.d;

            /* Create a dictionary hash from the content types */
            CHSI.Common.Log('Create Container HTML');
            jQuery(CHSI.Forms.CurrentDesignTemplate.ContentTypes).each(function (index, contenttype) {
                CHSI.Forms.ContentTypes[contenttype.ContentTypeID] = contenttype;
            });

            CHSI.Forms.BuildFormContainer(true);
            CHSI.Forms.DisplayCurrentForm(formID);
        }, error: CHSI.DOM.ProcessAjaxError
    });

};


/*This function generally does nothing if the HTML is already built*/
CHSI.Forms.BuildFormContainer = function (replaceEvenIfExists) {
    if (CHSI.Forms.FormContainer == null) {
        CHSI.Forms.FormContainer = jQuery('#' + CHSI.Forms.FormContainerID);
    }

    if (CHSI.Forms.FormContainer.html() == '' || replaceEvenIfExists == true) {
        CHSI.Common.Log('Build Container HTML');
        CHSI.Forms.FormContainer.html(CHSI.Forms.CurrentDesignTemplate.Templatebody + '<div id="jxFormMessageWindow" style="height:400px;"><div id="jxFormHeader">Validation Results:</div><div id="jxFormMessage">Message Here</div><div id="jxFormDialog">Dialog Buttons Here</div></div>');
        /* At this point, we need to resize elements*/
        CHSI.Forms.ResizeFormContainer();

    }

}

/* Resize the form container to take up all available space on the screen */
CHSI.Forms.ResizeFormContainer = function () {

    if (CHSI.Forms.FormContainer == null) {
        return void (0);
    }
    var jxWin = jQuery(window);
    var TotalWindowHeight = jxWin.height();
    var ContainerY;

    if (CHSI.Forms.FormContainer.offset() == null) {
        ContainerY = 0;
    }
    else {
        ContainerY = CHSI.Forms.FormContainer.offset().top;
    }


    var WindowFrameHeight = Math.min(TotalWindowHeight - ContainerY - CHSI.Forms.FooterPadding, TotalWindowHeight - CHSI.Forms.FooterPadding);

    var AppropriateFrameHeight = 0,
        PortalWindowHeight = 0;

    if (CHSI.Forms.isPortal) {
        AppropriateFrameHeight = 110;
        PortalWindowHeight = 30;
    } else {
        AppropriateFrameHeight = 50;
    }


    CHSI.Forms.FormContainer.height(WindowFrameHeight - PortalWindowHeight);
    WindowFrameHeight = WindowFrameHeight - AppropriateFrameHeight;
    var MainContentRegion = CHSI.DOM.Get('.FormNavMain')
    var NavRegion = jQuery('#FormNavRegion');
    var Header = CHSI.DOM.Get('#FormRegion1');
    var Footer = CHSI.DOM.Get('#FormRegion3');
    var Body = CHSI.DOM.Get('#FormRegion2');
    Body.css('position', 'relative');
    var FormDetails = CHSI.DOM.Get('#jxFormDetails');
    var jxFormTable = CHSI.DOM.Get('#jxFormTable');
    Body.css('overflow', 'auto');
    /* reset heights to get real values*/
    var NavScrollTop = NavRegion.scrollTop();
    Body.height('auto');
    Header.height('auto');
    Footer.height('auto');
    NavRegion.height('auto');
    MainContentRegion.height('auto');
    jxFormTable.height('auto');
    /*now retrieve the height values*/
    var Bodyheight = Body.height();
    var Footerheight = Footer.height();
    var Headerheight = Header.height() + FormDetails.height();
    var Navheight = NavRegion.height();
    if (Headerheight < 10) {
        Header.hide();
        Headerheight = 0;
    }
    else { Header.show(); }

    var Width = Body.width();
    var LabelWidth = 200;

    LabelWidth = Width - 126;
    LabelWidth = Math.min(LabelWidth / 2, 450);



    CHSI.DOM.Get('#jxFirstColumn').width(LabelWidth);


    var Scrollheight = Bodyheight + Headerheight + Footerheight;

    var NewBodyheight = WindowFrameHeight - Headerheight - Footerheight;

    /* check height */
    if (CHSI.Forms.DebugMode) {
        /*CHSI.Common.Log('Header height: ' + Headerheight + ', Footer: ' + Footerheight + ', Body: ' + Bodyheight + ', ScrollHeight: ' + Scrollheight + ', New Body Height: ' + NewBodyheight + ', Label Width: ' + LabelWidth + ', Form Details Height: ' + FormDetails.height());*/
    }

    /*fix to scroll */
    Body.height(NewBodyheight);
    MainContentRegion.height(WindowFrameHeight);
    NavRegion.height(WindowFrameHeight);
    jxFormTable.height(WindowFrameHeight);
    if (NavScrollTop) {
        if (CHSI.Forms.DebugMode) {
            /*CHSI.Common.Log('resetting scroll top to ' + NavScrollTop);*/
        }
        NavRegion.scrollTop(NavScrollTop);
    }
}





/* Prepare Validation for the form*/
CHSI.Forms.PrepareValidation = function (formID) {
    CHSI.Common.Log('setting up validation');
    jQuery('form').validate({
        debug: true,
        ignore: ':hidden'
    });
 
}


