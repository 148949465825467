module PL.BrokerPortal {
    export class SmartGrid {
        $formGrid: GUI.Tables.DTables;
        BrokerPortalData: GUI.Smart.SmartObject;

        init(): void {
            var _self = this;
            var URL: string = '/api/crm/producer/getclients';
            
            $.get(URL).done(function(data){
                _self.BrokerPortalData = data;
            }).then(function () {
                _self.buildFormColumnDefinitions();
            });
        }

        buildFormColumnDefinitions(): void {
            var Column: GUI.Smart.SmartColumnObject = new GUI.Smart.SmartColumnObject;

            var clientID = this.BrokerPortalData.Values;

            Column.title = "";
            Column.data = null;
            Column.orderable = false;
            Column.class = "details-control";
            Column.defaultContent = '[<a href="#" class="minilink jxEditForm">View</a>]';
            Column.render = function (row, data, type) {
                return '[<a href="/ProducerClients/' + row[0] + '/memberinfo.aspx" class="minilink jxEditForm">View</a>]';
            }

            this.BrokerPortalData.Columns.push(Column);
            this.bindForms();
        }

        bindForms(): void {
            this.$formGrid = new GUI.Tables.DTables;

            this.$formGrid.containerName = 'jxBrokerPortalSG';
            this.$formGrid.tableName = 'jxFormGrid';
            this.$formGrid.columnSearch = true;
			this.$formGrid.stateSave = false;
            this.$formGrid.data = this.BrokerPortalData;

            this.$formGrid.buildTable();

            $(".sorting").attr("title", 'For Advanced Search go to the bottom of the grid');
        }
    }
}