module PL.Que.Models.Design {

    export class Form {

        FormID: number;
        ResultSetID: number;
        Title: string;
        DueDate: string|Date;
        Status: string;
        ParentDataStructure: any;
        DateOfStatus: string|Date;
        FormDescription: string;
        ActiveDate: string|Date;
        InactiveDate: string|Date;
        DataStructureID: number;
        DesignTemplateID: number;
        DataStructureName: string;
        StructureTypeName: string;
        FieldsRequired: boolean;
        isPortalEditable: boolean;
        isConnectionsEditable: boolean;
        CreateTsmp: string|Date;
        CreateUser: string;
        UpdateUser: string;
        UpdateTmsp: string|Date;
        HelpIDs: any;
        isInternal: boolean;
        ClientID: number;
        FormClientID: any; // makes a call
        ReportFormName: string;
        ReportID: number;
        PreviousResultSetID: number;
        Sections: any[]; // makes a call
        Elements: any[]; // makes a call
        ElementCalculations: any[] // makes a call
        ResultSetValues: any[] // makes a call
        ActionBehaviors: any[] // makes a call
        MetaAttributes: PL.Que.Models.MetaAttributes[] = new Array<PL.Que.Models.MetaAttributes>();

        //getFormQuestionsByIndex(designID: number, sectionIndex: number, showElementsOnly: boolean, resultSetID?: number, returnPreviousResultSet?: boolean, CustomLabels?: boolean): JQueryDeferred<Form> {



        }

}