module WebUtilities {

    export class ParameterHelpers {

        public static getParameterByName(url: string, name: string): string {
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                results = regex.exec(url);
            return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
        }
        // insert parameter func
        // get list of parameters func
    }

    export function genericAJAXErrorHandler(jqXHR: any, textStatus: any, errorThrown: any) {
        if (jqXHR.readyState == 4) // http error
            console.log("(╯°□°）╯︵ ┻━┻  Error: " + textStatus + "\r ... looks like we had trouble getting your data.\r\r If this problem persists, please contact our support team at: support@chsitech.com");
        else if (jqXHR.readyState == 0) // network error
            console.log("(っ˘̩╭╮˘̩)っ  Error: Network error\rCheck your internet connection!");
        else // other error
            console.log("(╯°□°）╯︵ ┻━┻  Error: Application error\r ... looks like we had trouble getting your data.\r\r If this problem persists, please contact our support team at: support@chsitech.com");
    }
}