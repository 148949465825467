CHSI.Page.IncidentSearch = new Object();

//#region "Global Variables"
CHSI.Page.IncidentSearch.Groups = new Array();
CHSI.Page.IncidentSearch.SearchOptionsID = 0;
CHSI.Page.IncidentSearch.GridOutputID = 0;
CHSI.Page.IncidentSearch.CurrentOptionIndex = 0;
CHSI.Page.IncidentSearch.Template_SearchParameters = '';
CHSI.Page.IncidentSearch.Template_IncidentSearch_FilterOptions = '';
CHSI.Page.IncidentSearch.Template_IncidentSearch_Results = '';
CHSI.Page.IncidentSearch.FormFilterOptions = {
    SearchLevel: 'group',
    ClientID: '',
    SearchOptions: []
};


CHSI.Page.IncidentSearch.SearchOption = function () {
    this.FilterType = '';
    this.FilterPosition = '';
    this.FilterValue1 = '';
    this.FilterValue2 = '';
    this.FilterOperator = '';
};
//#endregion

//#region "Init functions"
CHSI.Page.IncidentSearch.Init = function (FilterOptionsID, GridOutputID) {

    CHSI.HandleBars.Preparehelpers();
    Handlebars.registerHelper('GenerateSearchOptions', function () {
        return new Handlebars.SafeString(CHSI.Page.IncidentSearch.ReturnSearchParameterHTML(0));
    });

    Handlebars.registerHelper('PopulateOptionDropdown', function () {
        var returnList = '';

        if (CHSI.Page.IncidentSearch.FormFilterOptions.SearchLevel == 'client') {
            returnList = returnList + '<option value="date of incident" selected>Date of Incident</option>';
            returnList = returnList + '<option value="status">Status</option>';
            returnList = returnList + '<option value="employee name">Employee Name</option>';
        } else if (CHSI.Page.IncidentSearch.FormFilterOptions.SearchLevel == 'group') {
            returnList = returnList + '<option value="date of incident" selected>Date of Incident</option>';
            returnList = returnList + '<option value="groupid">Group</option>';
            returnList = returnList + '<option value="status">Status</option>';
            returnList = returnList + '<option value="clientid">Client Name</option>';
            returnList = returnList + '<option value="employee name">Employee Name</option>';
        }
        return new Handlebars.SafeString(returnList);
    });

    Handlebars.registerHelper('FixBlankGroup', function (inGroupID) {
        if (inGroupID == '') {
            return '';
        } else {
            return inGroupID;
        }
    });

    Handlebars.registerHelper('CreatePrintURL', function (FormType, ReportName, IncidentID) {
        var reportName = '';
        var reportOptions = '';
        reportOptions = encodeURIComponent('ReportNumber=' + IncidentID);

        if (!isNaN(ReportName)) // if report name is numeric, change url to use Reportid
        {
            return 'chsifilehandler.axd?Reportid=' + ReportName + '&ReportType=1&ReportOptions=' + reportOptions;
        }

        if (FormType && FormType.toLowerCase() == 'nv-c3') {
            reportName = encodeURIComponent('/nvc3.rpt');
        } else {
            if (FormType && FormType.toLowerCase() == 'ca-5020') {
                reportName = encodeURIComponent('/ca5020.rpt');
            } else {
                reportName = encodeURIComponent(ReportName);
            }
        }

        return 'chsifilehandler.axd?ReportName=' + reportName + '&ReportType=1&ReportOptions=' + reportOptions;
    });

    Handlebars.registerHelper('CreateAttachmentPrintURL', function (IncidentID) {        
        return 'claimintakeattachmentprint.aspx?IncidentID=' + IncidentID;
    });

    CHSI.Page.IncidentSearch.SearchOptionsID = FilterOptionsID;
    CHSI.Page.IncidentSearch.GridOutputID = GridOutputID;
    CHSI.Page.IncidentSearch.GetTemplates();
};

CHSI.Page.IncidentSearch.GetTemplates = function () {    

    CHSI.Forms.ajaxFormDetails = CHSITemplates.Template_IncidentSearch_FilterOptions;
    CHSI.Page.IncidentSearch.Template_IncidentSearch_FilterOptions = CHSI.Forms.ajaxFormDetails;

    CHSI.Forms.ajaxFormDetails = CHSITemplates.Template_IncidentSearch_Parameters;
    CHSI.Page.IncidentSearch.Template_SearchParameters = CHSI.Forms.ajaxFormDetails;

    var TemplateSource = '';
    if (CHSI.Page.IncidentSearch.FormFilterOptions.SearchLevel == 'group') {
        TemplateSource = CHSITemplates.Template_IncidentSearch_GroupResults;
    } else if (CHSI.Page.IncidentSearch.FormFilterOptions.SearchLevel == 'client') {
        TemplateSource = CHSITemplates.Template_IncidentSearch_ClientResults;
    }

    CHSI.Forms.ajaxFormDetails = TemplateSource;
    CHSI.Page.IncidentSearch.Template_IncidentSearch_Results = CHSI.Forms.ajaxFormDetails;
    CHSI.Page.IncidentSearch.ShowFilterOptions();

            CHSI.Forms.ajaxFormDetails = jQuery.get(TemplateSource, function (data) {
                CHSI.Page.IncidentSearch.Template_IncidentSearch_Results = data;
                CHSI.Page.IncidentSearch.ShowFilterOptions();
            });
 
};

CHSI.Page.IncidentSearch.ShowFilterOptions = function () {

    var filtertemplate = CHSITemplates.Template_IncidentSearch_FilterOptions;
    jQuery('#' + CHSI.Page.IncidentSearch.SearchOptionsID).html(filtertemplate(CHSI.Page.IncidentSearch.FormFilterOptions));

    CHSI.Page.IncidentSearch.GetGroups(function () {
        CHSI.Page.IncidentSearch.BuildDefaultSearchParameters(CHSI.Page.IncidentSearch.FormFilterOptions.SearchLevel);
    });
};
//#endregion

//#region "Web API Calls"
CHSI.Page.IncidentSearch.GetGroups = function (successfunction) {
    CHSI.Common.Log('Loading selected groups');

    CHSI.Forms.ajaxFormDetails = jQuery.ajax({
        type: "GET",
        url: "/api/CRM/Group/GetActiveGroups",
        cache: false,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (e) {

            CHSI.Common.LogObject(e);

            jQuery.each(e, function (index, value) {
                CHSI.Page.IncidentSearch.Groups.push(value.GroupName);
            });

            if (successfunction) {
                successfunction();
            }
        }, error: CHSI.DOM.ProcessAjaxError
    });
};

CHSI.Page.IncidentSearch.FilterResults = function () {
    CHSI.Common.Log('Filtering Results');

    CHSI.Page.IncidentSearch.FormFilterOptions.SearchOptions = CHSI.Page.IncidentSearch.BuildSearchOptionArray();
    var ajaxData = CHSI.Page.IncidentSearch.FormatFormFilterOptions();
    CHSI.Common.Log('Filtering Form with the following Settings: ' + ajaxData);
    jQuery.ajax({
        type: 'GET',
        url: '/api/ClaimSystem/Incident/GetIncidents',
        data: ajaxData,
        contentType: 'application/json; charset=utf-8',
        cache: false,
        success: function (e) {
            CHSI.Common.LogObject(e);
            var IncidentContainer = {};
            var TotalIncidents = 0;
            IncidentContainer.IncidentTableRecord = e;
            IncidentContainer.ClientID = CHSI.Page.IncidentSearch.FormFilterOptions.ClientID;
            TotalIncidents = e.length;
            IncidentContainer.ShowTooManyRecords = (TotalIncidents > 100);

            var incidenttemplate = CHSI.Page.IncidentSearch.Template_IncidentSearch_Results;

            jQuery('#' + CHSI.Page.IncidentSearch.GridOutputID).html(incidenttemplate(IncidentContainer));
            CHSI.GUI.AjaxStop();
        },
        error: jQuery_ErrorResult
    });
};

//#endregion

//#region "Builder Methods"
CHSI.Page.IncidentSearch.BuildDefaultSearchParameters = function (searchlevel) {
    
    if (searchlevel && (searchlevel == 'group' || searchlevel == '')) {
        jQuery('#jxSelectedOption0').val('status');
        CHSI.Page.IncidentSearch.BuildSearchOptionInputs(0);
        jQuery('#uxStatus0').val('submitted');
    } else if (searchlevel == 'client') {
        CHSI.Page.IncidentSearch.BuildSearchOptionInputs(0);
        jQuery('#IncidentDateType0').val('after');
        jQuery('#IncidentDateFrom0').val(CHSI.Page.IncidentSearch.GetIntialDate());
    }
};

CHSI.Page.IncidentSearch.BuildSearchOptionArray = function () {
    var arySelectedOptions = jQuery('.SearchOptionRow');
    var aryFormattedOptions = [];

    jQuery.each(arySelectedOptions, function () {
        var option = new CHSI.Page.IncidentSearch.SearchOption;
        var currentOption = jQuery(this);

        if (currentOption.find('input.FilterValue1').first().val() && currentOption.find('input.FilterValue1').first().val() != '') {
            option.FilterType = currentOption.find('select.searchoption').first().val();
            option.FilterValue1 = currentOption.find('input.FilterValue1').first().val();

            if (currentOption.find('select.FilterPosition').first().val()) {
                option.FilterPosition = currentOption.find('select.FilterPosition').first().val();
                if (currentOption.find('input.FilterValue2').first().val()) {
                    if (option.FilterPosition == 'between') {
                        option.FilterValue2 = currentOption.find('input.FilterValue2').first().val();
                    }
                }
            }

            if (currentOption.find('select.FilterOperator').first().val()) {
                option.FilterOperator = currentOption.find('select.FilterOperator').first().val();
            }
            aryFormattedOptions.push(option);
        } else if (currentOption.find('select.FilterValue1').first().val() && currentOption.find('select.FilterValue1').first().val() != '') {
            option.FilterType = currentOption.find('select.searchoption').first().val();
            option.FilterValue1 = currentOption.find('select.FilterValue1').first().val();

            if (currentOption.find('select.FilterOperator').first().val()) {
                option.FilterOperator = currentOption.find('select.FilterOperator').first().val();
            }
            aryFormattedOptions.push(option);
        }
    });

    return aryFormattedOptions;
};

CHSI.Page.IncidentSearch.BuildSearchOptionInputs = function (index) {
    var selectedOption = jQuery('#jxSelectedOption' + index).val();
    CHSI.Page.IncidentSearch.CurrentOptionIndex = index;

    switch (selectedOption) {
        case 'date of incident':
            var html = new Array();
            var jxSearchOption = jQuery('#SearchFields' + index);

            html.push('<select id="IncidentDateType' + index + '" onchange="CHSI.Page.IncidentSearch.ChangeDateFilterOptions(this)" class="FilterPosition" style="width: 100px">');
            html.push('<option value="between">Between</option>');
            html.push('<option value="equals" selected>Equals</option>');
            html.push('<option value="before">Before</option>');
            html.push('<option value="after">After</option>');
            html.push('</select>&nbsp;');
            html.push('<input type="text" id="IncidentDateFrom' + index + '" class="dateOption FilterValue1" style="width: 70px" />');
            html.push('<span id="IncidentDateOptions' + index + '" style="display: none;">');
            html.push(' AND ');
            html.push('<input type="text" id="IncidentDateTo' + index + '" class="dateOption FilterValue2" style="width: 70px" />');
            html.push('</span>');
            jxSearchOption.html(html.join(''));
            break;
        case 'employee name':
            var html = new Array();
            var jxSearchOption = jQuery('#SearchFields' + index)

            html.push('<select id="IncidentEmployeeName' + index + '" class="FilterPosition" style="width: 100px">');
            html.push('<option value="begins with">Begins With</option>');
            html.push('<option value="ends with">Ends With</option>');
            html.push('<option value="contains">Contains</option>');
            html.push('<option value="equals">Equals</option>');
            html.push('</select>&nbsp;');
            html.push('<input type="text" id="EmployeeName' + index + '" class="FilterValue1" style="width: 210px" />');
            jxSearchOption.html(html.join(''));
            break;
        case 'groupid':
            var html = new Array();
            var jxSearchOption = jQuery('#SearchFields' + index)

            html.push('<select style="width: 100px" id="uxGroup' + index + '" class="FilterValue1">')
            for (currentIndex = 0; currentIndex < CHSI.Page.IncidentSearch.Groups.length; currentIndex++) {
                html.push('<option value="' + CHSI.Page.IncidentSearch.Groups[currentIndex] + '">' + CHSI.Page.IncidentSearch.Groups[currentIndex] + '</option>');
            }
            html.push('</select>')
            jxSearchOption.html(html.join(''));
            break;
        case 'clientid':
            var html = new Array();
            var jxSearchOption = jQuery('#SearchFields' + index)
            html.push('<span id="SelectedClient' + index + '" style="color:maroon">No Client Selected</span>&nbsp;&nbsp;');
            html.push('<a style="background:#eee;border-radius:4px;padding:3px;border:1px solid #aaa"   href="Javascript: ChooseClient()" >Select Client</a>');
            html.push('<input type="hidden" id="ClientID' + index + '" value="" class="FilterValue1">')
            jxSearchOption.html(html.join(''));
            break;
        case 'status':
            var html = new Array();
            var jxSearchOption = jQuery('#SearchFields' + index)

            html.push('<select style="width: 100px" id="uxStatus' + index + '" class="FilterValue1">')
            html.push('<option value="">All</option>');
            html.push('<option value="submitted">Submitted</option>');
            html.push('<option value="open">Open</option>');
            html.push('<option value="new">New</option>');
            html.push('<option value="first aid">First Aid</option>');
            html.push('</select>')
            jxSearchOption.html(html.join(''));
            break;
    }

    CHSI.Page.IncidentSearch.BuildDatePickers();
};

CHSI.Page.IncidentSearch.BuildNewSearchOption = function (sourcecontrol) {
    var selectedBuildType = sourcecontrol.value;
    var elementid = selectedBuildType.id;
    var NewIndex = jQuery(".SearchOptionRow").length;
    if (selectedBuildType == 'none') {
        //Delete the current row
        var currentRow = jQuery(sourcecontrol);
        var rowToDelete = currentRow.parent().parent().nextAll('.SearchOptionRow').remove();
    } else {
        var isEdit = CHSI.Page.IncidentSearch.IsEdit(sourcecontrol);
        if (isEdit == false) {
            var html = CHSI.Page.IncidentSearch.ReturnSearchParameterHTML(NewIndex)
            var optionContainer = jQuery(".SearchOptionContainer tbody");
            optionContainer.append(html);
            CHSI.Page.IncidentSearch.BuildSearchOptionInputs(NewIndex);
        }
    }
};

CHSI.Page.IncidentSearch.BuildDatePickers = function () {
    jQuery(".dateOption").datepicker({ showOn: "both", buttonImage: "/images/icons/cal.gif", buttonImageOnly: true });
};

CHSI.Page.IncidentSearch.ReturnSearchParameterHTML = function (newIndex) {
    var filtertemplate = CHSI.Page.IncidentSearch.Template_SearchParameters;
    var SearchOption = {};
    SearchOption.FilterIndex = newIndex;
    SearchOption.ShowAdditionalFilter = (newIndex < 10);
    var HTML = filtertemplate(SearchOption);
    return HTML;

};
//#endregion

//#region "Support and Control Action methods"
CHSI.Page.IncidentSearch.SearchIncidents = function () {
    CHSI.GUI.AjaxStart();
    CHSI.Page.IncidentSearch.FilterResults();
};

CHSI.Page.IncidentSearch.FormatFormFilterOptions = function () {
    var SearchOptions = CHSI.Page.IncidentSearch.FormFilterOptions.SearchOptions;
    var ajaxData = {}
    if (CHSI.Page.IncidentSearch.FormFilterOptions.ClientID == '') {
        ajaxData.ClientID = 0;
    } else {
        ajaxData.ClientID = CHSI.Page.IncidentSearch.FormFilterOptions.ClientID;
    }

    ajaxData.SearchOptions = CHSI.Page.IncidentSearch.FormFilterOptions.SearchOptions;

    return ajaxData;
};

CHSI.Page.IncidentSearch.ChangeDateFilterOptions = function (sourceControl) {
    var selectedDateFilterType = jQuery(sourceControl);
    var jxDateFilterOption = selectedDateFilterType.nextAll('span').first();

    if (selectedDateFilterType.val() == 'between') {
        jxDateFilterOption.show();
    } else {
        jxDateFilterOption.hide();
    }
};

CHSI.Page.IncidentSearch.IsEdit = function (sourcecontrol) {
    var currentRow = jQuery(sourcecontrol);
    var currentID = currentRow.attr("id");
    var currentIndex = currentID.charAt(currentID.length - 1);
    var nextRow = jQuery('#jxSelectedOption' + ((currentIndex * 1) + 1));
    var nextID = nextRow.attr("id");

    if (nextID == null) {
        return false;
    } else {
        return true;
    }
};

CHSI.Page.IncidentSearch.GetIntialDate = function () {
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    return '1/1/' + CurrentYear;
};
//#endregion

function showIntakeForm(IncidentID, ClientID, FormTypeID, FormTypeName) {
    if(!FormTypeName){
        FormTypeName = null;
    }
    var URL = '/Claims/Modals/ClaimIntakeForm.aspx?claimincidentid=' + IncidentID + '&clientid=' + ClientID + '&formtypeid=' + FormTypeID + '&formtypename=' + FormTypeName;
    ShowPopup("NewForm", URL, "New Form", 1000, 770, parent.RefreshGrid, true);
}