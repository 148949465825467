module PL.AdminTools.Modules {

    export class $alerts {

        private _$alert_container: JQuery = $('.alertContainer');        
        private _action_type_container: JQuery; 
        private _html_container: string;        

        private Name: Enums.Alert_Name;
        private Type: number;
        private Indicator: number
        private Content: any;

        getAlert(name: Enums.Alert_Name): $alerts {
            this.Name = name;

            $('.toolNav li').removeClass('activeToolLink').first().addClass('activeToolLink');

            this.setContent();                        
            this.setType();
            this.setIndication();

            $('.widgetContainer').slideUp();
            $('.alertContainer').slideDown();

            return this;
        }        

        displaySectionNotification(): void {                        
            $('#sectionNotification').fadeIn();            
        }

        displayElementNotification(message: string): void {

        }

        private setType(): void {
            this._$alert_container.empty();
            if (this.Type == Enums.Alert_Type.MESSAGE) {                
                this._$alert_container.text(this.Content);
            } else {                
                this._$alert_container.html(this.Content);
            }
        }

        private setIndication(): void {
            this._$alert_container.removeClass('alertSuccess, alertWarning, alertError');
            switch (this.Indicator) {

                case Enums.Alert_Indicator.DEFAULT:
                    // nothing atm
                    break;
                case Enums.Alert_Indicator.SUCCESS:
                    this._$alert_container.addClass('alertSuccess');
                    break;
                case Enums.Alert_Indicator.WARNING:
                    this._$alert_container.addClass('alertWarning');
                    break;
                case Enums.Alert_Indicator.ERRROR:
                    this._$alert_container.addClass('alertError');
                    break;                
            }

        }

        private setContent(): void {

            this._$alert_container.html(mW.handlebars.html(CHSITemplates.AdminTools_QuestionnaireForm_Notifications, {}));
            console.log(this._$alert_container.html());
            switch (this.Name) {
                case Enums.Alert_Name.NO_NOTIFICATIONS_FORM:
                    $('#notification_no_news').show();
                    break;
                case Enums.Alert_Name.NO_NOTIFICATIONS_GRID:
                    break;
                case Enums.Alert_Name.SAVE_SECTION_ERROR:
                    this.sectionSaveError();
                    break;
                case Enums.Alert_Name.SAVE_ELEMENT_ERROR:
                    alert('implement it');
                    break;
                case Enums.Alert_Name.SAVE_ACTION_ERROR:
                    alert('implement it');
                    break;
                default:
                    $('#notification_no_news').show();
            }
        }

        private sectionSaveError(): void {            
            this.Name = Enums.Alert_Name.SAVE_SECTION_ERROR, this.Type = Enums.Alert_Type.MESSAGE, this.Indicator = Enums.Alert_Indicator.ERRROR, this.Content = 'You are attempting to create a new section. Please save your latest section before continuing.';
        }

        private elementSaveError(): void {

        }

        private actionSaveError(): void {

        }
    }

}