CHSI.UW.Quote.QuoteType = {
    GetByGroupID: function (GroupID, successFunction) {
        var Options = {};
        Options.URL = '/api/UW/UW/GetQuotetypeByGroupID?GroupID=' + GroupID + '&isCreateable=' + true;
        Options.Data = null;
        Options.Success = successFunction;
        CHSI.Common.Ajax.Get(Options);
    },


    GetByClientID: function (ClientID, successFunction) {
        var Options = {};
        Options.URL = '/api/UW/UW/GetQuotetypeByClientID?ClientID=' + ClientID + '&isCreateable=' + true;
        Options.Data = null;
        Options.Success = successFunction;                          
        CHSI.Common.Ajax.Get(Options);
    },
    
}