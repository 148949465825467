module PL.Claims {
    export class ClaimSearch {

        constructor(gridID: string) {
            this.GridID = gridID; // id of table elem
        }

        public GridID: string;
        public Grid: DataTables.DataTable;

        public GetFilteredClaims(filters: any): JQueryDeferred<any> {
            var df = $.Deferred();
            this._getFilteredClaims(filters).done(this._andBuildTable, function () { df.resolve(); });
            return df;
        }

        private _getFilteredClaims = (filters: any): JQueryDeferred<any> => {
            var df = $.Deferred();

            jQuery.ajax({
                type: 'GET',
                url: '/api/ClaimSystem/Claim/GetClaimSearchResults_V2',
                data: filters,
                contentType: 'application/json; charset=utf-8',
                cache: false,
                success: function (smartgrid) {
                    df.resolve(smartgrid);                    
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    alert("Error getting filtered claims");
                }
            });

            return df;
        }

        private _andBuildTable = (data: any) => {
            this.Grid = mW.DataTableHelpers.BuildDataTable(this.GridID, mW.DataTableHelpers.BuildSettings(data));
        } 
    }
}