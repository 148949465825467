CHSI.Page.SItemFormUploadImage = {

    Init: function (ScheduleItemID) {
        var Template_ScheduleItemFormImageUpload = $('#Template_ScheduleItemFormImageUpload').html();
        this.Templates.ScheduleItemFormImageUpload = Handlebars.compile(Template_ScheduleItemFormImageUpload);
        this.Cache.ScheduleImageObject.ScheduleItemID = ScheduleItemID;
        this.Cache.ScheduleItemID = ScheduleItemID;
        this.BindPresentationLayer();        
        this.Events.Global();
    },
    Templates: {

    },
    Cache: {
        ScheduleImageObject: {
            ScheduleItemID: null
          , Primary: 0
          , Description: ''
          , Comments: ''
        }        
    },    
    BindPresentationLayer: function (isNewImage) {                        
        if (isNewImage) {
            var ScheduleImageObject = CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject;
            ScheduleImageObject.ScheduleItemID = null;
            ScheduleImageObject.FileID = null;
            ScheduleImageObject.Primary = false;
            ScheduleImageObject.Description = '';
            ScheduleImageObject.Comments = '';
        }
        $('#jxScheduleItemFormImageUploadContainer').html(this.Templates.ScheduleItemFormImageUpload(this.Cache.ScheduleImageObject));        
        CHSI.Page.SItemFormUploadImage.Calls.GetFileID();
        
        this.Events.GetTextareaLength('jxImageDescription', 'DescriptionMaxCount', 190, CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject.Description.length);
        this.Events.GetTextareaLength('jxImageComments', 'CommentsMaxCount', 490, CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject.Comments.length);
        
        
    },
    PersistFromCache: function () {
        var ScheduleImageObject = CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject;
        if (!ScheduleImageObject.ScheduleItemID) {
            ScheduleImageObject.ScheduleItemID = CHSI.Page.SItemFormUploadImage.Cache.ScheduleItemID;
        }
        ScheduleImageObject.Primary = $('#jxImagePrimary').is(':checked') ? true : false;
        ScheduleImageObject.Description = $('#jxImageDescription').val();
        ScheduleImageObject.Comments = $('#jxImageComments').val();
        return ScheduleImageObject;
    },
    Events: {
        Global: function () {
            $('#jxScheduleItemFormImageUploadContainer').on('click', '#jxImageSave', function () {
                CHSI.Page.SItemFormUploadImage.Calls.SaveScheduleItemImage(true);                
                $('#jxImageCancel').attr('disabled', true);
                $("#ScheduleItemFormTabs").tabs('option', 'active', 2);
                $('#jxImageUploadTab').parent().hide();                
            });

            $('#jxScheduleItemFormImageUploadContainer').on('click', '#jxImageCancel', function () {
                var ImageObject = CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject;
                ImageObject.FileID = null;
                ImageObject.ScheduleImageID = null;
                ImageObject.Primary = 0;
                ImageObject.Description = '';
                ImageObject.Comments = '';                
                CHSI.Page.SItemFormUploadImage.BindPresentationLayer();
                $("#ScheduleItemFormTabs").tabs('option', 'active', 2);
                $('#jxImageUploadTab').parent().hide();
            });

            

            $('.SCButtonsRight').on('click', '#uxScheduleItemEditImageOK', function () {              
                CHSI.Page.SItemFormItem.Cache.SaveButton = true;
                if (CHSI.Page.SItemFormItem.Cache.Deleted && CHSI.Page.SItemFormItem.Cache.SItemObject.Status == 'Pending-Delete') {
                    var r = true;
                    if (CHSI.Page.SItemFormItem.Cache.AutoApprove || !CHSI.Page.SItemFormFields.Cache.isPortal) {
                        r = confirm("The item status will be updated to Deleted by confirming this message.");
                    } else {
                        return false;
                    }
                    if (r == true) {
                        CHSI.Page.SItemFormItem.Calls.SaveItem(false);
                        closeThis(true);
                    } else {
                        return false;
                    }
                } else {
                    if (jQuery('form').valid()) {
                        if (CHSI.Page.SItemFormFields.Cache.isPortal && CHSI.Page.SItemFormItem.Cache.AutoApprove == false) {
                            CHSI.Page.SItemFormFields.SaveAllFields('Pending');
                        } else {
                            var r = true
                            if (CHSI.Page.SItemFormFields.Cache.PendingChanges || CHSI.Page.SItemFormFields.Cache.NewItem) {
                                r = confirm("Pending values and the item status will be updated to active by confirming this message.");
                            }
                            if (r == true) {
                                CHSI.Page.SItemFormFields.SaveAllFields('Active');
                                if (CHSI.Page.SItemFormItem.Cache.SItemObject.Status == 'Pending') {
                                    CHSI.Page.SItemFormItem.Cache.SItemObject.Status = 'Active';
                                    CHSI.Page.SItemFormItem.Calls.SaveItem(false);
                                }
                            } else {
                                return false;
                            }
                        }
                        closeThis(true);
                    }
                }
                CHSI.Page.SItemFormItem.Cache.SaveButton = false;
            });

            $('#jxScheduleItemFormImageUploadContainer').on('change', '#jxImagePrimary', function () {
                $('#jxImageSave').prop('disabled', false);
            });

            $('#jxScheduleItemFormImageUploadContainer').on('input', '#jxImageDescription, #jxImageComments', function () {
                $('#jxImageSave').prop('disabled', false);
            });

            this.GetTextareaLength('jxImageDescription', 'DescriptionMaxCount', 190);
            this.GetTextareaLength('jxImageComments', 'CommentsMaxCount', 490);
        },
        GetTextareaLength: function (TextareaID, TextareaCountID, MaxValue, UsedChars) {
            var Textarea = $('#' + TextareaID);
            var CounterID = $('#' + TextareaCountID);
            var RemaingChars;
            
            if (UsedChars == null || 0) {
                UsedChars = MaxValue;
            }

            RemaingChars = MaxValue - UsedChars;

            Textarea.on('keydown', function () {
                var CurrentChars = $(this).val().length;
                RemaingChars = MaxValue - CurrentChars;
                CounterID.html(RemaingChars);
                if (CurrentChars > MaxValue) {
                    alert('You have exceeded the maximum amount of characters supported.')
                }
            });

            CounterID.html(RemaingChars);
        }
    },
    Calls: {
        GetFileID: function () {
            var Uploader = new qq.FileUploader({
                element: document.getElementById('jxUploadattachment'),
                action: '/CHSIUploadHandler.axd',
                allowedExtensions: ['jpg', 'jpeg', 'png', 'gif'],
                debug: true,
                disableDefaultDropzone: true,
                onComplete: function (id, fileName, response) {

                    if (response.success == true) {

                        var CurrentValueIndex = 0;

                        jQuery('.qq-upload-list').hide();

                        var FileInfo = {
                            FileID: response.FileID,
                            FileName: fileName
                        };
                        
                        CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject.FileID = FileInfo.FileID;
                        CHSI.Page.SItemFormUploadImage.PersistFromCache();
                        CHSI.Page.SItemFormUploadImage.BindPresentationLayer();
                        
                    }
                }
            });
        },
        GetScheduleItemImage: function (ScheduleImageID, isEdit, isNewImage) {
                        
            var AjaxURL = '/api/UW/ScheduleItemImage/GetScheduleItemImage?ScheduleImageID=' + ScheduleImageID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject = e;                    
                    CHSI.Page.SItemFormUploadImage.BindPresentationLayer(isNewImage);
                    if (isEdit) {
                        $('#jxImageSave').prop('disabled', true);                        
                    }
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },
        SaveScheduleItemImage: function (RefreshTab) {
            if (!CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject.FileID) {
                alert('You cannot save without uploading an image.')
                return false;
            }
            var AjaxData = CHSI.Page.SItemFormUploadImage.PersistFromCache();            
            var AjaxString = JSON.stringify(AjaxData);
            var AjaxURL = '/api/UW/ScheduleItemImage/SaveScheduleItemImage';
            $.ajax({
                type: "POST",                
                url: AjaxURL,
                data: AjaxString,
                cache: true,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {                    
                    CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject.ScheduleImageID = e.ScheduleImageID;
                    if (RefreshTab) {
                        RefreshScheduleEditImageTab(CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject.ScheduleItemID);
                        RefreshScheduleItemTab(CHSI.Page.SItemFormUploadImage.Cache.ScheduleImageObject.ScheduleItemID);
                    }
                    CHSI.Common.LogObject(e);
                }, error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        }
    }
}