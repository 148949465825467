CHSI.UW.MuniQuote_VariableSave = {
    Init: function (VarID, QuoteID, NewValue) {
        this.Cache.VarID = VarID;
        this.Cache.QuoteID = QuoteID;
        this.Cache.NewValue = NewValue;
        this.Events.Global();
        this.Calls.GetHistory();
    },
    Templates: {},
    Cache: {
        VarID: null,
        QuoteID: null,      
        HistoryObj: null,
        NewValue: null
    },
    BindPresentationLayer: function () {      

    },

    Events: {
        Global: function () {
            $('#jxCancel').on('click', function () {
                closeThis(true);
            });

            $('#jxSave').on('click', function () {
                CHSI.UW.MuniQuote_VariableSave.Calls.SaveVariable(CHSI.UW.MuniQuote_VariableSave.Cache.VarID, CHSI.UW.MuniQuote_VariableSave.Cache.NewValue);
            });

        }
    },
    Calls: {


        GetHistory: function () {
            var VarID = CHSI.UW.MuniQuote_VariableSave.Cache.VarID;
            var Options = {};
            Options.Data = {};
            var Parameters = {};
            switch (VarID.substring(0, 3)) {
                case "RaV":
                    var IDs = VarID.replace('RaV_', '').split('_');
                    Options.Data.ProgramRateVariableID = IDs[0];
                    Options.Data.RateInfoID = IDs[1];
                    Options.URL = '/api/UW/MuniQuote/QuoteRateVariableHistory' + "?ProgramRateVariableID=" + Options.Data.ProgramRateVariableID + "&RateInfoID=" + Options.Data.RateInfoID;
                    break
                case "VaR":
                    Options.Data.VariableID = VarID.replace('VaR_', '');
                    Options.Data.QuoteID = CHSI.UW.MuniQuote_VariableSave.Cache.QuoteID;
                    Options.URL = '/api/UW/MuniQuote/QuoteVariableHistory?QuoteID=' + Options.Data.QuoteID + "&VariableID=" + Options.Data.VariableID;
                    break;
            }
            Options.Success = function (e) {
                                              
                CHSI.UW.MuniQuote_VariableSave.Cache.HistoryObj = e;                
                $('#jxSave').removeAttr("disabled");
            }
            CHSI.Common.Ajax.Post(Options);

        },

        SaveVariable: function (VarID, NewValue) {
            var ajaxData = CHSI.UW.MuniQuote_VariableSave.Cache.HistoryObj[0];
           
            ajaxData.Comments = jQuery('#jxChangecomments').val();
            ajaxData.DataObject.isLocked = 1;
            ajaxData.DataObject.QuoteID = CHSI.UW.MuniQuote_VariableSave.Cache.QuoteID;
            ajaxData.Status = 'active';
            CHSI.UW.MuniQuote.SetValue(ajaxData.DataObject, NewValue);
            var Success = function (e) {
                var Appearance = parent.QuoteObject.Appearance;
                e.Appearance = Appearance;
                parent.QuoteObject = e;
                CHSI.UW.MuniQuote.Cache.QuoteObject = e;
                closeThis(true);
            }

            CHSI.UW.MuniQuote.SaveVariable(VarID, ajaxData, Success);
        }

    }
}
