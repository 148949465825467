var Template_DistributionList = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = helpers.unless.call(depth0, (data && data.index), {"name":"unless","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        <tr>\r\n            <td><img src=\"../../images/Icons/ListType/ListType"
    + escapeExpression(((helper = (helper = helpers.ListTypeID || (depth0 != null ? depth0.ListTypeID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ListTypeID","hash":{},"data":data}) : helper)))
    + ".png\" /></td>\r\n            <td>"
    + escapeExpression(((helper = (helper = helpers.ListName || (depth0 != null ? depth0.ListName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ListName","hash":{},"data":data}) : helper)))
    + " - <b>("
    + escapeExpression(((helper = (helper = helpers.CreateUser || (depth0 != null ? depth0.CreateUser : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"CreateUser","hash":{},"data":data}) : helper)))
    + ")</b></td>\r\n            <td class=\"DStatus\">"
    + escapeExpression(((helper = (helper = helpers.Status || (depth0 != null ? depth0.Status : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Status","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>"
    + escapeExpression(((helper = (helper = helpers.MemberCount || (depth0 != null ? depth0.MemberCount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"MemberCount","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <!--<td>";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.AllowEmail : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</td>\r\n            <td>";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.AllowPost : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</td>\r\n            <td>";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.AllowPhone : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</td>\r\n            <td>";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.AllowFax : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</td>-->\r\n            <td>";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.AllowEmail : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</td>\r\n            <td>";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.AllowPost : depth0), {"name":"if","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</td>\r\n            <td>";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.AllowPhone : depth0), {"name":"if","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</td>\r\n            <td>";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.AllowFax : depth0), {"name":"if","hash":{},"fn":this.program(15, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</td>\r\n            <td>[<a class=\"minilink\" href=\"javascript:ShowDistributionListAdmin(";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ListID : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.program(9, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + ");\">edit</a>]</td>\r\n        </tr>\r\n";
},"2":function(depth0,helpers,partials,data) {
  return "    <thead>\r\n        <tr>\r\n            <td>Type</td>\r\n            <td>Distrubution List</td>\r\n            <td class=\"DStatus\">Status</td>\r\n            <td>Membership</td>\r\n            <td class=\"sorttable_nosort\">Email</td>\r\n            <td class=\"sorttable_nosort\">Post</td>\r\n            <td class=\"sorttable_nosort\">Phone</td>\r\n            <td class=\"sorttable_nosort\">Fax</td>\r\n            <td class=\"sorttable_nosort\">&nbsp</td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n";
  },"4":function(depth0,helpers,partials,data) {
  return "x";
  },"6":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<a href=\"javascript:ShowEmail(";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ListID : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.program(9, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + ","
    + escapeExpression(((helper = (helper = helpers.ListTypeID || (depth0 != null ? depth0.ListTypeID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ListTypeID","hash":{},"data":data}) : helper)))
    + ", '"
    + escapeExpression(((helper = (helper = helpers.ListSource || (depth0 != null ? depth0.ListSource : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ListSource","hash":{},"data":data}) : helper)))
    + "',0);\"><img src=\"../../images/emailicon.gif\" /></a> ";
},"7":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return escapeExpression(((helper = (helper = helpers.ListID || (depth0 != null ? depth0.ListID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ListID","hash":{},"data":data}) : helper)));
  },"9":function(depth0,helpers,partials,data) {
  return "null";
  },"11":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<a href=\"javascript:ShowPost(";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ListID : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.program(9, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + ","
    + escapeExpression(((helper = (helper = helpers.ListTypeID || (depth0 != null ? depth0.ListTypeID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ListTypeID","hash":{},"data":data}) : helper)))
    + ", '"
    + escapeExpression(((helper = (helper = helpers.ListSource || (depth0 != null ? depth0.ListSource : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ListSource","hash":{},"data":data}) : helper)))
    + "',1);\"><img src=\"../../images/envelope.gif\" /></a> ";
},"13":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<a href=\"javascript:ShowPhone(";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ListID : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.program(9, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + ","
    + escapeExpression(((helper = (helper = helpers.ListTypeID || (depth0 != null ? depth0.ListTypeID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ListTypeID","hash":{},"data":data}) : helper)))
    + ", '"
    + escapeExpression(((helper = (helper = helpers.ListSource || (depth0 != null ? depth0.ListSource : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ListSource","hash":{},"data":data}) : helper)))
    + "',2);\"><img src=\"../../images/phone.gif\" /></a> ";
},"15":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<a href=\"javascript:ShowFax(";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ListID : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.program(9, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + ","
    + escapeExpression(((helper = (helper = helpers.ListTypeID || (depth0 != null ? depth0.ListTypeID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ListTypeID","hash":{},"data":data}) : helper)))
    + ", '"
    + escapeExpression(((helper = (helper = helpers.ListSource || (depth0 != null ? depth0.ListSource : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ListSource","hash":{},"data":data}) : helper)))
    + "',3);\"><img src=\"../../images/printicon.gif\" /></a> ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "﻿<table width=\"100%\" class=\"dtable sortable\">\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.DistributionList : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </tbody>\r\n</table>";
},"useData":true});