var Template_ClaimSearch_ClientResults = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "    <div style=\"color: Red; font-size: 16px;\">\r\n        More than 100 records found, please refine your search.\r\n    </div>\r\n";
  },"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "            <table cellspacing=0 cellpadding=0 class=\"dtable sortable\"><thead><tr><td style=\"width: 100px\">Coverage</td><td style=\"width: 100px\">Type</td><td style=\"width: 100px\">Claim No.</td><td style=\"width: 150px\">Claimant</td><td style=\"width: 100px\">Incident Date</td><td style=\"width: 100px\">Date Opened</td><td style=\"width: 100px\">Status</td><td style=\"width: 100px; text-align: right\">Paid</td><td style=\"width: 100px; text-align: right\">Reserves</td><td style=\"width: 100px; text-align: right\">Incurred</td><td style=\"width: 60px\" class=\"sorttable_nosort\">&nbsp;</td></tr></thead><tbody>";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.ClaimTableRecord : depth0), {"name":"each","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</tbody></table>\r\n";
},"4":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;
  return "<tr><td style=\"width: 100px\">"
    + escapeExpression(lambda((depth0 != null ? depth0.Coverage : depth0), depth0))
    + "</td><td style=\"width: 100px\">"
    + escapeExpression(((helpers.TruncateString || (depth0 && depth0.TruncateString) || helperMissing).call(depth0, (depth0 != null ? depth0.Type : depth0), 15, {"name":"TruncateString","hash":{},"data":data})))
    + "</td><td style=\"width: 100px\">"
    + escapeExpression(lambda((depth0 != null ? depth0.ClaimNumber : depth0), depth0))
    + "</td><td style=\"width: 150px\">"
    + escapeExpression(((helpers.TruncateString || (depth0 && depth0.TruncateString) || helperMissing).call(depth0, (depth0 != null ? depth0.Claimant : depth0), 22, {"name":"TruncateString","hash":{},"data":data})))
    + "</td><td style=\"width: 100px\">"
    + escapeExpression(((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || helperMissing).call(depth0, (depth0 != null ? depth0.DateOfIncident : depth0), {"name":"CHSIDate","hash":{},"data":data})))
    + "</td><td style=\"width: 100px\">"
    + escapeExpression(((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || helperMissing).call(depth0, (depth0 != null ? depth0.DateOpened : depth0), {"name":"CHSIDate","hash":{},"data":data})))
    + "</td><td style=\"width: 100px\">"
    + escapeExpression(lambda((depth0 != null ? depth0.Status : depth0), depth0))
    + "</td><td style=\"width: 100px; text-align: right\">"
    + escapeExpression(((helpers.FormatCurrency || (depth0 && depth0.FormatCurrency) || helperMissing).call(depth0, (depth0 != null ? depth0.Paid : depth0), true, {"name":"FormatCurrency","hash":{},"data":data})))
    + "</td><td style=\"width: 100px; text-align: right\">"
    + escapeExpression(((helpers.FormatCurrency || (depth0 && depth0.FormatCurrency) || helperMissing).call(depth0, (depth0 != null ? depth0.Reserves : depth0), true, {"name":"FormatCurrency","hash":{},"data":data})))
    + "</td><td style=\"width: 100px; text-align: right\">"
    + escapeExpression(((helpers.FormatCurrency || (depth0 && depth0.FormatCurrency) || helperMissing).call(depth0, (depth0 != null ? depth0.Incurred : depth0), true, {"name":"FormatCurrency","hash":{},"data":data})))
    + "</td><td style=\"width: 60px\">[<a href=\""
    + escapeExpression(((helpers.GetClaimDetailsURL || (depth0 && depth0.GetClaimDetailsURL) || helperMissing).call(depth0, (depth0 != null ? depth0.ClaimVersion : depth0), (depth0 != null ? depth0.ClaimID : depth0), (depth0 != null ? depth0.ClientID : depth0), {"name":"GetClaimDetailsURL","hash":{},"data":data})))
    + "\" class=\"minilink\">view</a>]</td></tr>";
},"6":function(depth0,helpers,partials,data) {
  return "            <p>No claims found using the supplied search filters.  Please refine your search and try again...</p>\r\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "﻿";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ShowTooManyRecords : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<br /><span style=\"font-size: 20px;\">Reported Claims</span><hr style=\"color: #7ba2d6;\" />\r\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ClaimTableRecord : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.program(6, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});