module PL.CRM.APIs {

    export class Group {

        private static _routePrefix = '/api/CRM/Group/';

        public static getActiveGroups(): JQueryDeferred<any> {
            var result = $.Deferred();
            $.get(this._routePrefix + 'GetActiveGroups').done(function (data) {
                result.resolve(data);
            });
            return result;
        }

    }

}