namespace PL.Common.APIs {
    export class ChoreRepository {

        private   _routePrefix: string = '/api/Common/ChoreCoordinator/';
        public   Get(): JQueryDeferred<GUI.Smart.SmartObject> {
            var $result = $.Deferred<any>();
            $.get(this._routePrefix + 'getChores').done((data) => {
                
                $result.resolve(data);

            });
            return $result;
        }
        public EnableChore(choreType: number, recurrance: string): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            $.ajax({
                url: this._routePrefix + 'enableChore', type: 'POST', dataType: 'json', data: { ChoreType: choreType, Recurrence: recurrance }
            }).done((data) => { $result.resolve(data)});

            return $result;
        }
        public DisableChore(choreType: number): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            $.ajax({
                url: this._routePrefix + 'disableChore', type: 'DELETE', dataType: 'json',data: { ChoreType: choreType}
            }).done((data) => { $result.resolve(data) });


            return $result;
        }

    }

}