module PL.ClaimSystem.ClaimManagement {
    export class IncidentModel {
        constructor() { }

        public IncidentID: number;
        public ClaimID: number;
        public LocationID: number;
        public IncidentDescription: string;
        public Address1: string;
        public Address2: string;
        public State: string;
        public City: string;
        public Zip: string;
        public IncidentLocation: string;
        public Cause: string;
        public IncidentType: string;
        public OSHADesc: string;
        public OccurredOnPremises: boolean;
        public PhysicalRestrictions: boolean;
        public ERTreatment: boolean;
        public Hospitalized: boolean;
        public OtherClaimNumber: string;
        public Activity: string;
        public EquipmentUsed: string;
        public InjuryDescription: string;
        public IllnessDescription: string;
        public InjuryCause: string;
        public IllnessCause: string;
        public CreateUser: string;
        public CreateTmsp: string;
        public UpdateUser: string;
        public UpdateTmsp: string;
    }
}