module GUI {
    export class Sortable {

        DropTargets: string;
        DragTarget: string;
        ConnectedClass: string;

        $dropTargets: JQuery;
        $dragTargets: JQuery;
        $listContainer: JQuery;


        static recalculateOrder(classList: any[], HTMLList: JQuery, sortIndexProp: string, IDProp: string): any[] {            

            HTMLList.each(function (index, element) {
                var ID: number = $(element).data(IDProp.toLowerCase());
                
                for (var i = 0; i < classList.length; i++) {
                    if (classList[i][IDProp] == ID) {
                        classList[i][sortIndexProp] = +index;
                        break;
                    }
                }
            });
            
            return this.sortList(classList, sortIndexProp);
        }

        static sortList(classList: any[], sortIndexProp: string): any[] {

            var newList: any[] = new Array;

            for (var i = 0; i < classList.length; i++) {
                newList[+classList[i][sortIndexProp]] = classList[i];
            }
            return newList;
        }

        connectedLists(): void {

        }

        generateList(HTMLTemplate: HandlebarsTemplateDelegate, dataObject: any): any {
            var _self = this;
            var HTML = [];

            HTML.push('<ul id="'+ _self.DropTargets +'" class= "connectedSortable">');
            for (var index = 0; index < dataObject[index].length; index++) {
                HTML.push('<li class="ui-state-highlight">' + HTMLTemplate +'</li>');
            }
            HTML.push('</ul>');

            return this.$listContainer.html(HTML.join(''));
        }

        generateNewListTile(tileHTML: string): void {

        }
    }
}


