module GUI.Tables {

    export class Settings2 {

        // remove the number 2 from "Settings2" when deleting out the settings class inside the Tables.ts
        
        createdRow: (row: any, data: any, index: any) => void = function (r, d, i) { if (r && d && i == null) { return null } };
        colDefCollection: ColumnDefinitions[] = new Array<ColumnDefinitions>();
        colDef: ColumnDefinitions = new ColumnDefinitions;
        newColDef: ColumnDefinitions[] = new Array<ColumnDefinitions>();
        colHeaderCollection: GUI.Smart.SmartColumnObject[] = new Array<GUI.Smart.SmartColumnObject>();
        colHeader: GUI.Smart.SmartColumnObject = new GUI.Smart.SmartColumnObject;
        stateSave: boolean = true;
        globalFilter: boolean = true;
        paging: boolean = true;
    }

}