module CL.Common.Factories {

    var Repositories: any = {};
    export class RepositoryFactory {
        public static getAttachmentRepository():
            APIs.AttachmentCategoryRepository
        {
            var Result = Repositories[""];
            if (Result == null)
            {
                Result = new APIs.AttachmentCategoryRepository();
                Repositories["AttachmentCategoryRepository"] = Result;
            }
            return Result;
        }

    }

} 