module PL.Que.APIs {

    export class DataStructure_Repo {

        private static _routePrefix: string = '/api/Que/DataStructure/';

        public static getDataStructures(activeOnly: boolean): JQueryDeferred<Models.Data.DataStructure[]> {

            var result = $.Deferred();

            $.get(this._routePrefix + 'GetDataStructures?ActiveOnly=' + activeOnly).done((data) => {
            
                result.resolve(data);

            });

            return result;
        }

    }

}