module PL.ClaimSystem.ClaimManagement {
    export class PayeeModel {
        constructor() { }

        public PayeeID: number;
        public Name: string;
        public TaxID: string;
        public Company: string;
        public Address1: string;
        public Address2: string;
        public City: string;
        public State: string;
        public Zip: string;
        public Phone: string;
        public Fax: string;
        public Email: string;
        public Is1099: boolean;
        public CreateUser: string;
        public CreateTmsp: string;
        public UpdateUser: string;
        public UpdateTmsp: string;
    }
}