
CHSI.Forms.Navigation = new Object();
CHSI.Forms.Navigation.CurrentDialog = [];
CHSI.Forms.Navigation.ChangeSectionDirection = function (Direction, FormID) {

    
    var CurrentSectionIndex = CHSI.Forms.Navigation.GetSectionIndexbyIndex(CHSI.Forms.CurrentActiveSectionIndex);
    var NumSections = CHSI.Forms.Forms['f' + FormID].Sections.length;
    var sectionIndex;
    var NewSectionIndex;
    if (Direction > 0) {
        CHSI.Common.Log('Starting increase direction from section index: ' + (CurrentSectionIndex));
        for (sectionIndex = CurrentSectionIndex; sectionIndex < NumSections; sectionIndex++) {
            var section = CHSI.Forms.Forms['f' + FormID].Sections[sectionIndex];
            CHSI.Common.Log('Section Check: ' + section.SectionIndex + ', looking for not ' + CurrentSectionIndex);
            if ((section.CurrentVisibility == 1 || section.CurrentVisibility == 4) && section.SectionIndex != CurrentSectionIndex) {
                CHSI.Common.Log('Old Index: ' + CHSI.Forms.CurrentActiveSectionIndex + ', New SectionIndex: ' + section.SectionIndex);
                NewSectionIndex = section.SectionIndex;
                break;
            }
        }
    }
    else {
        CHSI.Common.Log('Starting decrease direction from section index: ' + CurrentSectionIndex);
        for (sectionIndex = CurrentSectionIndex; sectionIndex > -1; sectionIndex--) {
            var section = CHSI.Forms.Forms['f' + FormID].Sections[sectionIndex];
            CHSI.Common.Log('Section Check: ' + section.SectionIndex + ', looking for not ' + CurrentSectionIndex);
            if ((section.CurrentVisibility == 1 || section.CurrentVisibility == 4) && section.SectionIndex < CurrentSectionIndex) {
                CHSI.Common.Log('Old Index: ' + CHSI.Forms.CurrentActiveSectionIndex + ', New SectionIndex: ' + section.SectionIndex);
                NewSectionIndex = section.SectionIndex;
                break;
            }
        }
    }

    if (NewSectionIndex != CurrentSectionIndex) {
        CHSI.Common.Log('changing section, ' + NewSectionIndex + '!=' + CurrentSectionIndex);
        CHSI.Forms.Navigation.ChangeActiveSection(NewSectionIndex, FormID);
    }
    else {
        CHSI.Common.Log('not changing section, ' + NewSectionIndex + '=' + CurrentSectionIndex);
    }

}

CHSI.Forms.Navigation.ChangeActiveSection = function (newIndex, FormID) {

    if (CHSI.Forms.DebugMode) {
        CHSI.Common.Log('ChangeActiveSection called');
    }
    


    if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].DataChanged == true ) {
        if (CHSI.Forms.DebugMode) {
            CHSI.Common.Log('Form Data Changed, can\'t change section yet');
        }

        var ResultMessage = '';

        ResultMessage = ResultMessage + 'Have you completed this section?';

        var DialogWindow = jQuery('#jxFormMessageWindow');
        var DialogMessage = jQuery('#jxFormMessage', DialogWindow);
        var jxFormDialog = jQuery('#jxFormDialog', DialogWindow);

        var DialogButtons;

        DialogButtons = '<input type="button" value="Yes - Mark Section as Complete" onclick="CHSI.Forms.Navigation.CurrentDialog.Confirm();" /><input type="button" value="No - Save and Continue" onclick="CHSI.Forms.Navigation.CurrentDialog.Cancel();" />';

        CHSI.Forms.Navigation.CurrentDialog.Confirm = function () {
            CHSI.Forms.Navigation.SubmitSection(CHSI.Forms.Navigation.GetSectionIDbyIndex(CHSI.Forms.CurrentActiveSectionIndex), FormID, newIndex);
 
        };
        CHSI.Forms.Navigation.CurrentDialog.Cancel = function () {
            CHSI.Forms.CurrentActiveSectionIndex = newIndex;
            CHSI.Forms.GetFormDisplay(FormID, CHSI.Forms.CurrentActiveSectionIndex);
            jQuery('#jxFormMessageWindow').hide();
        };

        jxFormDialog.html(DialogButtons);
        DialogMessage.html(ResultMessage);

        DialogWindow.css('left', -5000);
        DialogWindow.css('top', -5000);
        DialogWindow.show();
        DialogWindow.position({ at: "center center", collision: "flip flip", of: jQuery('#' + CHSI.Forms.FormContainerID) });

    }
    else {
        CHSI.Common.Log('hiding any existing windows');
        jQuery('#jxFormMessageWindow').hide();
        CHSI.Forms.CurrentActiveSectionIndex = newIndex;
        CHSI.Forms.GetFormDisplay(FormID, CHSI.Forms.CurrentActiveSectionIndex);

    }

    return false;
}

CHSI.Forms.Navigation.GetSectionIndexbyIndex = function (sectionIndex) {
    for (i = 0; i< CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].Sections.length; ++i  ) {
        if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].Sections[i].SectionIndex == sectionIndex) {
            return i;
        }
    }
}


CHSI.Forms.Navigation.GetSectionIDbyIndex = function (sectionIndex)
{
    for (i = 0; i< CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].Sections.length; ++i  ) {
        if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].Sections[i].SectionIndex == i) {
            return CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].Sections[i].SectionID;
        }
    }
}

CHSI.Forms.Navigation.SubmitSection = function (SectionID, FormID, newSectionIndex) {
    if (newSectionIndex == null) {
        newSectionIndex = -1;
    }
    var Section = CHSI.Forms.Forms['f' + FormID].Sections[CHSI.Forms.Navigation.GetSectionIndexbyIndex(CHSI.Forms.CurrentActiveSectionIndex)];
    Section.CreateTmsp = CHSI.Dates.GetShortDate(Section.CreateTmsp);
    Section.UpdateTmsp = CHSI.Dates.GetShortDate(Section.UpdateTmsp);
    if (Section == null) {
        alert('Error Accessing Section');
    }
    else {
        CHSI.Forms.Results.SaveSection(FormID, Section.SectionID, Section.CurrentVisibility, CHSI.Forms.ResultID, 'Complete', false, newSectionIndex);

    }
    return false;
};

CHSI.Forms.Navigation.SetSectionStatus = function (sectionIndex, newStatus)
{
    CHSI.Common.Log('changing section status to ' + newStatus);
    for (i = 0; i< CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].Sections.length; i+= 1)
    {
        if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].Sections[i].SectionIndex == sectionIndex)
        {
            CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].Sections[i].Status = newStatus;

            CHSI.Forms.Results.SaveSection(CHSI.Forms.PrimaryFormID, CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].Sections[i].SectionID, CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].Sections[i].CurrentVisibility, CHSI.Forms.ResultID, newStatus, true);

            CHSI.Forms.Navigation.UpdateSectionHTML();

            break;
        }
    }
    CHSI.Common.Log('Section Status not found');
}

CHSI.Forms.Navigation.GenerateNavigation = function (formID) {
    var NavHTML = [];

    jQuery(CHSI.Forms.Forms['f' + formID].Sections).each(function (index, section) {

    
        NavHTML.push('<div class="FormNavSection');
        if (section.SectionIndex == CHSI.Forms.CurrentActiveSectionIndex) {
            NavHTML.push(' activeSection');
        }
        if (section.CurrentVisibility == 2 || section.CurrentVisibility == 3) {
            NavHTML.push(' hiddenBlock');
        }

        if (section.CurrentVisibility == 1 || section.CurrentVisibility == 4) {
            NavHTML.push(' visibleBlock');
        }
        NavHTML.push('"  onclick="javascript: CHSI.Forms.Navigation.ChangeActiveSection(' + (section.SectionIndex) + ',' + formID + ');" id="jxSectionMenu' + section.SectionID + '">' +
        CHSI.Forms.Navigation.GetSectionCompletionFlag(section.Status, section.Tooltip) + section.Title);
        if (section.IsRequired == true) {
            NavHTML.push('<span class="RequiredSectionFlag"> *</span>');
        }

        NavHTML.push('</div>');
    });
    return NavHTML.join('');
}

CHSI.Forms.Navigation.UpdateSectionHTML = function () {


    jQuery(CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].Sections).each(function (index, section) {
        var SectionElement = jQuery('#jxSectionMenu' + section.SectionID);
        if (section.SectionIndex == CHSI.Forms.CurrentActiveSectionIndex) {
            SectionElement.addClass('activeSection');
        }
        else {
            SectionElement.removeClass('activeSection');
        }
        SectionElement.children('div').removeClass().addClass(CHSI.Forms.Navigation.GetSectionCompletionFlagCSS(section.Status, section.Tooltip)).attr('title', section.Tooltip);
        
    });

}

/* Translate the section status into a class that can be styled and have a tooltip? */
CHSI.Forms.Navigation.GetSectionCompletionFlag = function (sectionstatus, tooltip) {
    var result = '';
    if (sectionstatus == 'Complete')/*Complete*/ 
    { result = '<div class="Flag NavComplete" title = "Section Complete"></div>'; }
    else if (sectionstatus == 'Complete' && tooltip != '')/* Soft Warning*/
    { result = '<div class="Flag NavWarning" title = "Warning - ' + tooltip + '"></div>'; }
    else if (sectionstatus == 'In Progress')/* Soft Warning*/
    { result = '<div class="Flag NavInProgress" title = "In Progress"></div>'; }
    else if (sectionstatus == 'Error')/* Error*/
    { result = '<div class="Flag NavError" title="' + tooltip + '"></div>'; }
    else
    { result = '<div class="Flag NavUnstarted"></div>' }
    return result;
}
CHSI.Forms.Navigation.GetSectionCompletionFlagCSS = function (sectionstatus, tooltip) {
  
    var result = 'Flag';
    if (sectionstatus == 'Complete')/*Complete*/
    { result += ' NavComplete'; }
    else if (sectionstatus == 'Complete' && tooltip != '')/* Soft Warning*/
    { result += ' NavWarning'; }
    else if (sectionstatus == 'In Progress')/* Soft Warning*/
    { result += ' NavInProgress'; }
    else if (sectionstatus == 'Error')/* Error*/
    { result += ' NavError'; }
    else
    { result += ' NavUnstarted' }
    return result;
}


CHSI.Forms.Navigation.GetFooterNavHTML = function (FormID) {    
    var NavigationHTML = [];
    if (FormID == null) {
        FormID = CHSI.Forms.PrimaryFormID;
    }

    CHSI.Common.Log('Checking Footer Nav for ' + FormID);
    if (CHSI.Forms.Forms['f' + FormID].isDisabled == false) {
        var LastVisibleIndex = 0;
        var FirstVisibleIndex = -1;
        var NumSections = CHSI.Forms.Forms['f' + FormID].Sections.length;
        var isElementVisible = false;

        var ElementCount = CHSI.Forms.Forms['f' + FormID].Elements.length;
        CHSI.Common.Log('checking ' + ElementCount + 'elements');
        for (ElementIndex = 0; ElementIndex < ElementCount ; ++ElementIndex) {
            var Element = CHSI.Forms.Forms['f' + FormID].Elements[ElementIndex];
            if ((Element.Visibility == 1 || Element.Visibility == 4) && (Element.ElementType == 3 || Element.ElementType == 4 || Element.ElementType == 5 || Element.ElementType == 6 || Element.ElementType == 8 || Element.ElementType == 9 || Element.ElementType == 10 || Element.ElementType == 11)) {
                isElementVisible = true;
                break;
            }
        }
       
        for (sectionIndex = 0; sectionIndex < NumSections; sectionIndex++) {
            var section = CHSI.Forms.Forms['f' + FormID].Sections[sectionIndex];

            if (FirstVisibleIndex == -1) {
                FirstVisibleIndex = section.SectionIndex
            };

            if (section.CurrentVisibility == 1 || section.CurrentVisibility == 4) {
                LastVisibleIndex = section.SectionIndex;
            }
            /*CHSI.Common.Log('Section Visible: ' + FirstVisibleIndex + ', LastVisibleIndex: ' + LastVisibleIndex + ', from section info: visibility: ' + section.CurrentVisibility + ', index: ' + section.SectionIndex);
            */
        }


        if (CHSI.Forms.CurrentActiveSectionIndex != FirstVisibleIndex) {
            NavigationHTML.push('<input type="button" value="Previous" onclick="javascript: CHSI.Forms.Navigation.ChangeSectionDirection(-1, ' + FormID + ');" />');
        }

        if (CHSI.Forms.CurrentActiveSectionIndex != LastVisibleIndex) {
            NavigationHTML.push('<input type="button" value="Next" onclick="javascript: CHSI.Forms.Navigation.ChangeSectionDirection(1, ' + FormID + ');"/>');
        }
      
        if (isElementVisible == true) {

            NavigationHTML.push('<br/>');
            NavigationHTML.push('<input type="button" value="Complete Section" onclick="javascript: CHSI.Forms.Navigation.SubmitSection(' + CHSI.Forms.Navigation.GetSectionIDbyIndex(CHSI.Forms.CurrentActiveSectionIndex) + ',' + FormID + ');" class="ImportantButton"/>');

        }

        if (CHSI.Forms.CurrentActiveSectionIndex == LastVisibleIndex) {
            NavigationHTML.push('<input type="button" value="Submit Questionnaire" data-formid="'+ FormID +'" class="ImportantButton SubmitQuestionnaire"/>');
        }

    }
    return NavigationHTML.join('');

}
