namespace PL.Common.Models {

    export class ContentType {

        ContentTypeID: number;
        CssClass: string;
        MexLength: number;
        MinLength: number;
        IsRequired: boolean;

        get(): JQueryDeferred<ContentType[]> {
            var $result = $.Deferred();
            PL.Common.APIs.ContentType_Repo.get().done((data) => { $result.resolve(data); });
            return $result;
        }
    }

}