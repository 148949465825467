module PL.Que.Models.Data {

    export class DataStructure {

        SamplePropertyID: number;
        SchemaType: number;
        StructureID: number;
        Name: string; // added "Name" because the smartgrid object was named incorrectly. "StructureName" should have been used instead.
        StructureName: string;
        StructureType: string;
        StructureTypeName: string;
        Status: string;
        StructureDescription: string;
        CreateUser: string;
        CreateTmsp: string|Date;
        UpdateUser: string;
        UpdateTmsp: string|Date;
        Columns: any[]; // makes a call
        Categories: any[];

        getDataStructures(activeOnly: boolean = true): JQueryDeferred<DataStructure[]> {
            var result = $.Deferred();
            PL.Que.APIs.DataStructure_Repo.getDataStructures(activeOnly).done((data) => {
                data = mW.io.reconstruct_object(data);
                result.resolve(data);
            });
            return result;
        }

    }

}