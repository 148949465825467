CHSI.UW.PortalTiles = {
    Init: function (TileType) {
        var Template_PortalTiles = $('#Template_PortalTiles').html();
        this.Templates.PortalTiles = Handlebars.compile(Template_PortalTiles);
        this.Calls.GetAllQuoteStatuses(function () {
            CHSI.UW.PortalTiles.Calls.GetPortalMenuTabs(function () {
                CHSI.UW.PortalTiles.Binds.PortalTileContainer();
            });
        });

        this.TileType = TileType;
        this.Events.Global();
    },
    Properties: {
        SaveQueue: 0,
        QuoteStatus: {},
        QuoteStatuses: [],
        AvailableQuoteStatus: [],
        TileObject: {},
        TilesObject: [],
        SortOrderList: [],
        TileEditor: {
            tileTop: 154,
            tileLeft: 9,
            TileHeaderName: null,
            BGColor: '#000000',
            FGColor: null
        },
        AutoSave: true,
        ContentScrollPosition: 0,
    },
    Templates: {},
    Binds: {
        PortalTileContainer: function (successFunction) {
            for (i = 0; i < CHSI.UW.PortalTiles.Properties.TilesObject.length; i++) {
                CHSI.Common.Log(CHSI.UW.PortalTiles.Properties.TilesObject[i].QuoteStatuses);
            }

            $('#PortalTileContainer').html(CHSI.UW.PortalTiles.Templates.PortalTiles({ Tiles: CHSI.UW.PortalTiles.Properties }));

            $('.TileEditorContainer').hide();

            $('#AvailableQuoteStatus, .SortableStatusColumn').sortable({
                connectWith: ".SortableStatusColumn",
                cancel: '.jxHowToUse'
            }).disableSelection();

            $('#SortableTileContainer').sortable({
            	connectWith: "#SortableTileContainer",
            	cancel: '.TileEditorCapture'
            }).disableSelection();

            if (successFunction) {
                successFunction();
            }

            $('#SortableTileContainer').scrollTop(CHSI.UW.PortalTiles.Properties.ContentScrollPosition);

            ControlledObjects.push($('#PortalTileContainer'));
            ControlledObjectWidthModifiers.push('i');
            ControlledObjectHeightModifiers.push(-200);
            ControlledObjects.push($('#AvailableQuoteStatus'));
            ControlledObjectWidthModifiers.push('i');
            ControlledObjectHeightModifiers.push(CHSI.UW.PortalTiles.Properties.AutoSave == true ? -240 : -360);
            ControlledObjects.push($('#SortableTileContainer'));
            ControlledObjectWidthModifiers.push('i');
            ControlledObjectHeightModifiers.push(CHSI.UW.PortalTiles.Properties.AutoSave == true ? -235 : -290);
            SetSize();
        }
    },
    Events: {
        Global: function () {
            var Prop = CHSI.UW.PortalTiles.Properties;
            var Event = CHSI.UW.PortalTiles.Events;
            var Call = CHSI.UW.PortalTiles.Calls;
            var AutoSave = CHSI.UW.PortalTiles.Properties.AutoSave;

            $('#PortalTileContainer').on("sortstart", '#SortableTileContainer', function (event, ui) {
                var start_pos = ui.item.index();
                ui.item.data('start_pos', start_pos);

                $('.TileContainer').css('box-shadow', '');
                ui.item.css('box-shadow', '0 0 10px #000000');
                ui.item.css('background-color', 'white');
            });

            $('#PortalTileContainer').on("sortstop", '#SortableTileContainer', function (event, ui) {
                $('.TileContainer').css('box-shadow', '');
                ui.item.css('background-color', '');
                ui.item.css('box-shadow', '');
            });

            $('#PortalTileContainer').on("sortupdate", '#SortableTileContainer', function (event, ui) {
                CHSI.UW.PortalTiles.Events.SaveTileSortOrder();
            });


            // This section is for the Quote Statuses

            $('#PortalTileContainer').on("sortstart", '#AvailableQuoteStatus, .SortableStatusColumn', function (event, ui) {
                $('#' + ui.item[0].lastElementChild.id).data('previoustabid', event.target.id);

                ui.helper.addClass('TransparentQuoteStatus');

                Prop.QuoteStatus = {};
                Prop.QuoteStatus.OriginalQuotestatus = $('#' + ui.item[0].lastElementChild.id).data('qtname');
                Prop.QuoteStatus.QStatus = $('#' + ui.item[0].lastElementChild.id).data('qtname');
                Prop.QuoteStatus.Status = "active";
            });

            $('#PortalTileContainer').on("sortstop", '#AvailableQuoteStatus, .SortableStatusColumn', function (event, ui) {
                $(ui.item).removeClass('TransparentQuoteStatus');
                $('.SortableStatusColumn ').css('box-shadow', '');
            });

            $('#PortalTileContainer').on("sortover", '#AvailableQuoteStatus, .SortableStatusColumn', function (event, ui) {
                $('.SortableStatusColumn ').css('box-shadow', '');
                $('#' + event.target.id).css('box-shadow', '0 0 10px #000000');
            });

            $('#PortalTileContainer').on("sortreceive", '#AvailableQuoteStatus, .SortableStatusColumn', function (event, ui) {
                var QuoteStatusUpdate = CHSI.UW.PortalTiles.TileType == 1 ? { TabID: $('#' + event.target.id).data('tabid') } : { PortalTabID: $('#' + event.target.id).data('tabid') };
                QuoteStatusUpdate.Status = 'active';
                QuoteStatusUpdate.QStatus = $('#' + ui.item[0].lastElementChild.id).data('qtname');
                QuoteStatusUpdate.OriginalQuotestatus = QuoteStatusUpdate.QStatus;

                PropertyQuoteStatus = CHSI.UW.PortalTiles.Events.GetQuoteStatusFromProperties(QuoteStatusUpdate.QStatus);
                QuoteStatusUpdate.Bind = PropertyQuoteStatus.Bind;
                QuoteStatusUpdate.RequireComments = PropertyQuoteStatus.RequireComments;
                QuoteStatusUpdate.QCAvailable = PropertyQuoteStatus.QCAvailable;
                QuoteStatusUpdate.DisplayDays = PropertyQuoteStatus.DisplayDays;
                QuoteStatusUpdate.Editable = PropertyQuoteStatus.Editable;
                QuoteStatusUpdate.Print = PropertyQuoteStatus.Print;
                QuoteStatusUpdate.AccessRole = PropertyQuoteStatus.AccessRole;

                /*
                 * Reset each tile's quote status to match HTML elements.
                 */
                for (TileIndex = 0; TileIndex < Prop.TilesObject.length; TileIndex++) {
                    /*
                     * Use local object to refer to global object for ease of use.
                     */
                    var TileObject = CHSI.UW.PortalTiles.Properties.TilesObject[TileIndex];
                    TileObject.QuoteStatuses = [];
                    $('#TileQuoteStatus_' + TileObject.TabID + ' li').each(function (index) {
                        if ($(this).hasClass('jxHowToUse') != true) {
                            TileObject.QuoteStatuses.push({ QStatus: $(this).children().children().html() });
                        }
                        else {
                            $(this).remove();
                        }
                    });
                    /*Replace any how to use tiles that might now be needed.*/
                    if (TileObject.QuoteStatuses.length == 0) {
                        $('#TileQuoteStatus_' + TileObject.TabID).html($('#jxTileInstructionTemplate').html());
                    }
                }
                /*
                 * Resetting the available quote statuses before rebuilding.
                 */
                CHSI.UW.PortalTiles.Properties.AvailableQuoteStatus = [];
                $('#AvailableQuoteStatus li div span').each(function (index) {
                    CHSI.UW.PortalTiles.Properties.AvailableQuoteStatus.push({ QStatus: $(this).html() });
                });
                for (var i = 0; i < CHSI.UW.PortalTiles.Properties.QuoteStatuses.length; i++) {
                    if (CHSI.UW.PortalTiles.Properties.QuoteStatuses[i].QStatus == QuoteStatusUpdate.QStatus) {
                        CHSI.UW.PortalTiles.Properties.QuoteStatuses[i] = QuoteStatusUpdate;
                        break;
                    }
                }
                if (AutoSave == true) {
                    Event.SaveQuoteStatus(QuoteStatusUpdate);
                }
            });

            $('#PortalTileContainer').on('click', '#jxSaveAll', function () {
             
                $(this).attr('disabled', 'disabled');
                $('#SaveProgressContainer').css('display', 'block');

                CHSI.UW.PortalTiles.Calls.SaveStatuses(CHSI.UW.PortalTiles.Events.ShowSaveAllComplete);
                CHSI.UW.PortalTiles.Calls.SaveTiles(CHSI.UW.PortalTiles.Events.ShowSaveAllComplete);
            });

            $('#PortalTileContainer').on('click', '.PortalTile > .TileEditButton', function () {
                var Tile = $(this).parent();
                var TileEditorContainer = $('#' + Tile.attr('id') + ' > .TileEditorCapture');
                
                var TabID = Tile.data('tabid');
                var TabName = Tile.data('tabname');
                var BGColor = Tile.data('bgcolor');
                var FGColor = Tile.data('fgcolor');
                var ID = Tile.prop('id');

                $('.PortalTile').css('border', '1px solid white');
                Tile.css('border', '1px solid black');

                $('.TileEditorContainer').appendTo(TileEditorContainer);

                //Prop.TileEditor.tileLeft = Tile.position().left;
                //Prop.TileEditor.tileTop = Tile.position().top + 120;
                Prop.TileEditor.ID = ID;
                Prop.TileEditor.TabID = TabID;
                Prop.TileEditor.TileHeaderName = TabName;
                Prop.TileEditor.BGColor = BGColor;
                Prop.TileEditor.FGColor = FGColor;

                $('#jxColorPicker').val(Prop.TileEditor.BGColor);
                $('#jxColorPicker').spectrum({
                    move: function (hex) { Prop.TileEditor.BGColor = hex.toHexString(); },
                    preferredFormat: "hex"
                });
                $('.TileEditorContainer').draggable('disable');

                Event.ShowTileEditor(Prop.TileEditor);
            });

            $('#jxAddNew').off().on('click', function () {
                if (CHSI.UW.PortalTiles.Properties.SaveQueue == 0) {
                    Event.GenerateNewTile();
                }
            });

            $('#PortalTileContainer').on('keyup', '#jxHexPicker', function () {
                var CurrentValue = $(this).val();

                if (CurrentValue.length == 7) {

                    if ($('#jxColorSelector').val() == 'background') {
                        Prop.TileEditor.BGColor = CurrentValue;
                        $('#jxColorPicker').spectrum('set', Prop.TileEditor.BGColor);
                    } else {
                        Prop.TileEditor.FGColor = CurrentValue;
                        $('#jxColorPicker').spectrum('set', Prop.TileEditor.FGColor);
                    }

                }

            });

            $('#PortalTileContainer').on('change', '#jxColorSelector', function () {
                if ($(this).val() == 'background') {
                    $('#jxColorPicker').val(Prop.TileEditor.BGColor);
                    $('#jxHexPicker').val(Prop.TileEditor.BGColor);

                    $('#jxColorPicker').spectrum({
                        move: function (hex) { Prop.TileEditor.BGColor = hex.toHexString(); },
                        preferredFormat: "hex"
                    });

                    $('.TileEditorContainer').data('bgcolor', Prop.TileEditor.BGColor);
                } else {
                    $('#jxColorPicker').val(Prop.TileEditor.FGColor);
                    $('#jxHexPicker').val(Prop.TileEditor.FGColor);

                    $('#jxColorPicker').spectrum({
                        move: function (hex) { Prop.TileEditor.FGColor = hex.toHexString(); },
                        preferredFormat: "hex"
                    });

                    $('.TileEditorContainer').data('fgcolor', Prop.TileEditor.FGColor);
                }
            });

            $('#PortalTileContainer').on('change', '#jxColorPicker', function () {
                $(this).val($(this).val());
                $('#jxHexPicker').val($(this).val());
            });

            $('#PortalTileContainer').on('click', '#jxDeleteTile', function () {
                var TabID = Prop.TileEditor.TabID;
                var ConfirmDelete = confirm('Are you sure you want to delete this tile?')

                if (ConfirmDelete == true) {
                    CHSI.UW.PortalTiles.Properties.ContentScrollPosition = $('#SortableTileContainer').scrollTop();

                    Call.DeleteTile(TabID, function () {
                        for (TileIndex = 0; TileIndex < Prop.TilesObject.length; TileIndex++) {
                            if (Prop.TilesObject[TileIndex].TabID == TabID) {
                                for (StatusIndex = 0; StatusIndex < Prop.TilesObject[TileIndex].QuoteStatuses.length; StatusIndex++) {
                                    Prop.TilesObject[TileIndex].QuoteStatuses[StatusIndex].PortalTabID = null;
                                    Prop.AvailableQuoteStatus.push(Prop.TilesObject[TileIndex].QuoteStatuses[StatusIndex]);
                                    Prop.TilesObject[TileIndex].QuoteStatuses[StatusIndex].OriginalQuotestatus = Prop.TilesObject[TileIndex].QuoteStatuses[StatusIndex].QStatus;
                                    CHSI.UW.PortalTiles.Properties.QuoteStatus = Prop.TilesObject[TileIndex].QuoteStatuses[StatusIndex];
                                    Call.SaveStatus();
                                }
                                Prop.TilesObject.splice(TileIndex, 1);
                            }
                        }
                        CHSI.UW.PortalTiles.Binds.PortalTileContainer();
                    });
                }
            });

            $('#PortalTileContainer').on('click', '#jxSaveTile', function () {
                var Editor = $('.TileEditorContainer');

                Editor.data('tabname', $('#jxTileHeaderName').val());
                Editor.data('bgcolor', Prop.TileEditor.BGColor);
                Editor.data('fgcolor', Prop.TileEditor.FGColor);

                Prop.TileEditor.BGColor = Editor.data('bgcolor');
                Prop.TileEditor.FGColor = Editor.data('fgcolor');
                Prop.TileEditor.TileHeaderName = Editor.data('tabname');
                Prop.TileEditor.TabID = Editor.data('tabid');

                for (TileIndex = 0; TileIndex < Prop.TilesObject.length; TileIndex++) {
                    if (Prop.TilesObject[TileIndex].TabID == Prop.TileEditor.TabID) {
                        Prop.TilesObject[TileIndex].TabName = Prop.TileEditor.TileHeaderName;
                        Prop.TilesObject[TileIndex].BackColor = Prop.TileEditor.BGColor;
                        Prop.TilesObject[TileIndex].ForeColor = Prop.TileEditor.FGColor;
                        CHSI.UW.PortalTiles.Properties.TileObject = Prop.TilesObject[TileIndex];
                        break;
                    }
                }

                CHSI.UW.PortalTiles.Properties.ContentScrollPosition = $('#SortableTileContainer').scrollTop();

                Call.SaveTile(function () {
                    CHSI.UW.PortalTiles.Binds.PortalTileContainer();

                });
            });

            $('#PortalTileContainer').on('click', '#jxCancel', function () {
                Event.HideTileEditor();
            });

            //$('#PortalTileContainer').on('focusout', '.TileEditorContainer', function () {
            //    Event.HideTileEditor();
            //});
        },
        ShowSaveAllComplete: function () {
            if (CHSI.UW.PortalTiles.Properties.SaveQueue == 0) {
                $('#SaveProgressContainer').css('display', 'none');
                $('#jxSaveAll').removeAttr('disabled');
            }
        },
        GetQuoteStatusFromProperties: function (QStatus) {
            var QuoteStatus = null;
            for (i = 0; i < CHSI.UW.PortalTiles.Properties.QuoteStatuses.length; i++) {
                if (QStatus == CHSI.UW.PortalTiles.Properties.QuoteStatuses[i].QStatus) {
                    QuoteStatus = CHSI.UW.PortalTiles.Properties.QuoteStatuses[i];
                    break;
                }
            }
            return QuoteStatus;
        },
        ShowTileEditor: function (TileEditorObject) {
            var Editor = $('.TileEditorContainer');

            //Editor.css('top', TileEditorObject.tileTop - 120);
            //Editor.css('left', TileEditorObject.tileLeft + 125);

            Editor.data('bgcolor', TileEditorObject.BGColor);
            Editor.data('fgcolor', TileEditorObject.FGColor);
            Editor.data('tabname', TileEditorObject.TileHeaderName);
            Editor.data('tabid', TileEditorObject.TabID);

            $('#jxTileHeaderName').val(TileEditorObject.TileHeaderName);
            $('#jxHexPicker').val(TileEditorObject.BGColor);

            Editor.show();

            $(this).focus();
        },
        HideTileEditor: function () {
            var Editor = $('.TileEditorContainer');
            var Tile = $('.PortalTile');

            Editor.hide();
            Tile.css('border', '');

            $(Editor).prependTo( $('#SortableTileContainer') );
        },
        GenerateNewTile: function () {
            var TilesObject = CHSI.UW.PortalTiles.Properties.TilesObject;

            CHSI.UW.PortalTiles.Properties.TileObject = {
                BackColor: '#D3D3D3',
                ForeColor: '#000000',
                SortOrder: (CHSI.UW.PortalTiles.Properties.TilesObject.length + 1),
                TabID: null,
                TabName: 'New Tile',
                TileType: CHSI.UW.PortalTiles.TileType,
                QuoteStatuses: []
            };

            $('#SaveProgressContainer').css('display', 'block');

            CHSI.UW.PortalTiles.Calls.SaveTile(function () {
                TilesObject.push(CHSI.UW.PortalTiles.Properties.TileObject);              

                CHSI.UW.PortalTiles.Properties.ContentScrollPosition = $('#SortableTileContainer')[0].scrollHeight;

                CHSI.UW.PortalTiles.Binds.PortalTileContainer();
                CHSI.UW.PortalTiles.Events.ShowSaveAllComplete();
            });
        },
        SaveQuoteStatus: function (QuoteStatus, successFunction) {
            CHSI.UW.PortalTiles.Calls.SaveStatus(QuoteStatus, successFunction);
        },
        SaveTileSortOrder: function () {
            var HTMLTiles = $('#SortableTileContainer .TileContainer');
            for (var HTMLTileIndex = 0; HTMLTileIndex < HTMLTiles.length; HTMLTileIndex++) {
                var TileID = HTMLTiles.get(HTMLTileIndex).id.replace(/TileContainerID_/, '');
                for (TileIndex = 0; TileIndex < CHSI.UW.PortalTiles.Properties.TilesObject.length; TileIndex++) {
                    if (CHSI.UW.PortalTiles.Properties.TilesObject[TileIndex].TabID == TileID) {
                        CHSI.UW.PortalTiles.Properties.TilesObject[TileIndex].SortOrder = HTMLTileIndex;
                    }

                }
            }
            CHSI.UW.PortalTiles.Properties.TilesObject = sortByKey(CHSI.UW.PortalTiles.Properties.TilesObject, 'SortOrder');

            if (CHSI.UW.PortalTiles.Properties.AutoSave) {
                CHSI.UW.PortalTiles.Calls.SaveTiles();
            }
        }
    },
    Calls: {
        GetAllQuoteStatuses: function (successFunction) {
            var Options = {};
            Options.URL = '/api/UW/QuoteStatus/GetQuoteStatuses?TabID=' + 4;
            Options.Data = null;
            Options.Success = function (data) {

                CHSI.UW.PortalTiles.Properties.QuoteStatuses = data;

                for (StatusIndex = 0; StatusIndex < data.length; StatusIndex++) {
                    if (CHSI.UW.PortalTiles.Properties.QuoteStatuses[StatusIndex].TabID == 0 && CHSI.UW.PortalTiles.TileType == 1) {
                        CHSI.UW.PortalTiles.Properties.AvailableQuoteStatus.push(data[StatusIndex]);
                    }

                    if (CHSI.UW.PortalTiles.Properties.QuoteStatuses[StatusIndex].PortalTabID == 0 && CHSI.UW.PortalTiles.TileType == 2) {
                        CHSI.UW.PortalTiles.Properties.AvailableQuoteStatus.push(data[StatusIndex]);
                    }
                }

                if (successFunction) {
                    successFunction();
                }
            }
            CHSI.Common.Ajax.Get(Options);
        },
        GetPortalMenuTabs: function (successFunction) {
            var Options = {};
            Options.URL = '/api/UW/MenuTab/GetPortalMenuTabs?TileType=' + CHSI.UW.PortalTiles.TileType;
            Options.Data = null;
            Options.Success = function (data) {

                CHSI.UW.PortalTiles.Properties.TilesObject = data;

                for (TileIndex = 0; TileIndex < CHSI.UW.PortalTiles.Properties.TilesObject.length; TileIndex++) {
                    CHSI.UW.PortalTiles.Properties.SortOrderList.push(CHSI.UW.PortalTiles.Properties.TilesObject[TileIndex].SortOrder);
                    CHSI.UW.PortalTiles.Properties.TilesObject[TileIndex].QuoteStatuses = [];
                    for (StatusIndex = 0; StatusIndex < CHSI.UW.PortalTiles.Properties.QuoteStatuses.length; StatusIndex++) {
                        if (CHSI.UW.PortalTiles.Properties.TilesObject[TileIndex].TabID == CHSI.UW.PortalTiles.Properties.QuoteStatuses[StatusIndex].PortalTabID && CHSI.UW.PortalTiles.TileType == 2) {
                            CHSI.UW.PortalTiles.Properties.TilesObject[TileIndex].QuoteStatuses.push(CHSI.UW.PortalTiles.Properties.QuoteStatuses[StatusIndex]);
                        } else if (CHSI.UW.PortalTiles.Properties.TilesObject[TileIndex].TabID == CHSI.UW.PortalTiles.Properties.QuoteStatuses[StatusIndex].TabID && CHSI.UW.PortalTiles.TileType == 1) {
                            CHSI.UW.PortalTiles.Properties.TilesObject[TileIndex].QuoteStatuses.push(CHSI.UW.PortalTiles.Properties.QuoteStatuses[StatusIndex]);
                        }
                    }
                }

                if (successFunction) {
                    successFunction(data);
                }
            }
            CHSI.Common.Ajax.Get(Options);
        },
        SaveTile: function (successFunction) {
            var Options = {};
            Options.URL = '/api/UW/MenuTab/SaveMenuTab';
            Options.Data = CHSI.UW.PortalTiles.Properties.TileObject;
            Options.Success = function (data) {
                CHSI.UW.PortalTiles.Properties.TileObject.TabID = data;
                if (successFunction) {
                    successFunction();
                }
            }
            CHSI.Common.Ajax.Post(Options);
        },
        SaveTiles: function (successFunction) {
            CHSI.UW.PortalTiles.Properties.SaveQueue += 1;
            var Options = {};
            Options.URL = '/api/UW/MenuTab/SaveMenuTabs';
            Options.Data = CHSI.UW.PortalTiles.Properties.TilesObject;
            Options.Success = function (data) {
                CHSI.UW.PortalTiles.Properties.SaveQueue -= 1;
                if (successFunction) {
                    successFunction(data);
                }
            }
            CHSI.Common.Ajax.Post(Options);
        },
        SaveStatus: function (QuoteStatus, successFunction) {
            var Options = {};
            Options.URL = '/api/UW/QuoteStatus/SaveQuoteStatus';
            Options.Data = QuoteStatus;
            Options.Success = function (data) {
                CHSI.UW.PortalTiles.Properties.QuoteStatus.StatusName = data;

                if (successFunction) {
                    successFunction();
                }
            }
            CHSI.Common.Ajax.Post(Options);
        }
        , SaveStatuses: function (successFunction) {
            CHSI.UW.PortalTiles.Properties.SaveQueue += 1;
            var Options = {};
            Options.URL = '/api/UW/QuoteStatus/SaveQuoteStatuses';
            Options.Data = CHSI.UW.PortalTiles.Properties.QuoteStatuses;
            Options.Success = function (data) {
                CHSI.UW.PortalTiles.Properties.QuoteStatuses = data;
                CHSI.UW.PortalTiles.Properties.SaveQueue -= 1;
                if (successFunction) {
                    successFunction();
                }
            }
            CHSI.Common.Ajax.Post(Options);
        }
        ,
        DeleteTile: function (TabID, successFunction) {
            var Options = {};
            Options.URL = '/api/UW/MenuTab/DeleteMenuTab?TabID=' + TabID;
            Options.Success = function () {
                if (successFunction) {
                    successFunction();
                }
            }
            CHSI.Common.Ajax.Delete(Options);
        }
    }
}

Handlebars.registerHelper('ToUpperCase', function (stringValue) {
    return stringValue.toUpperCase();
});

Handlebars.registerHelper('ConvertToUnderScore', function (stringValue) {
    return stringValue.replace(/ /g, "_");
});