module PL.Que.Models.Design {

    export class Element {
        ElementID: number = null;
        ParentID: number = null;
        DataStructureColumnID: number = null;
        FormSectionID: number = null;
        ElementType: number = null;
        TemplateRegionIndex: number = null;
        Status: string = null;
        Label: string = null;
        LabelTemplate: string = null;
        RequiresReview: boolean = null;
        BlockType: number = null;
        Visibility: number = null;
        SortIndex: number = null;        
        IsRequired: boolean = null;
        AllowNA: boolean = null;
        AllowHTML: boolean = null;
        PrevAllowNA: boolean = null;
        DataStructure: any = null;
        Tag: string = null;
        Data: any = null;
        Changed: boolean = false;
        ControlTypeName: string = null;
        MetaAttribute: PL.Que.Models.MetaAttributes = new PL.Que.Models.MetaAttributes;
        MetaAttributes: PL.Que.Models.MetaAttributes[] = new Array<PL.Que.Models.MetaAttributes>();
        CreateUser: string = null;
        CreateTmsp: string|Date = null;
        UpdateUser: string = null;
        UpdateTmsp: string|Date = null;

        getMetaAttribute(metaType: number, objectTypeID?: number): PL.Que.Models.MetaAttributes {
            var foundMeta = false;
            var metaAttribute: any;
            this.MetaAttributes.forEach((mO, mI, meta) => {
                if (metaType == mO.MetaType) {
                    mW.classes.propagatePropertyValues(mO, this.MetaAttributes[mI]);
                    this.MetaAttribute = mO;
                    metaAttribute = this.MetaAttributes[mI];
                    foundMeta = true;
                }
            });

            if (!foundMeta) {
                var newMeta: PL.Que.Models.MetaAttributes;
                this.MetaAttributes.push(newMeta = { ParentID: this.ElementID, ParentSecondID: null, ParentThirdID: null, ObjectTypeID: objectTypeID, Description: null, MetaType: metaType, MetaIndex: null, MetaValue: null, Status: 'Active', CreateUser: null, CreateTmsp: null, UpdateUser: null, UpdateTmsp: null });
                mW.classes.propagatePropertyValues(newMeta, this.MetaAttribute);
                metaAttribute = newMeta;
            }

            return metaAttribute;
        }
    }
}