module PL.Claims.Models {

    export class IntakeForm extends FormType  {        

        IncidentID: number;
        ClientID: number;
        FormTypeName: string;
        Categories: PL.Claims.Models.IntakeFormCategory[];
        Attachments: PL.Claims.Models.ClaimIncidentAttachments[];
        Locations: PL.CRM.Models.Location[];
        LateReasons: PL.Claims.Models.LateReasonCodes[];
        IncidentReport: PL.Claims.Models.ClaimIncidentReport;
        ClientInformation: PL.CRM.Models.Client;
        AdditionalClientInformation: PL.CRM.Models.ClientAddInfo;
        ClientAddress: PL.CRM.Models.ClientAddress;

        // internal property to helps us identify the forms status - claim intake form
        _Status: Enums.ClaimIntakeForm_Status;
        
        constructor() {
            super();
            this.IncidentID = null;
            this.ClientID = null;
            this.FormTypeName = null;
            this.Categories = new Array<PL.Claims.Models.IntakeFormCategory>();
            this.Attachments = new Array<PL.Claims.Models.ClaimIncidentAttachments>();
            this.Locations = new Array<PL.CRM.Models.Location>();
            this.LateReasons = new Array<PL.Claims.Models.LateReasonCodes>();
            this.IncidentReport = new PL.Claims.Models.ClaimIncidentReport();
            this.ClientInformation = new PL.CRM.Models.Client();
            this.AdditionalClientInformation = new PL.CRM.Models.ClientAddInfo();
            this.ClientAddress = new PL.CRM.Models.ClientAddress();
        }

        get(): JQueryDeferred<IntakeForm> {
        // Review this, we can clean this up
            var $result = $.Deferred();
            PL.Claims.APIs.IntakeFormRepo.get(this.IncidentID, this.FormTypeID).done((form) => {                
                mW.classes.propagatePropertyValues(form, this);
                form.Attachments.forEach((attO) => { this.Attachments.push(new ClaimIncidentAttachments(attO)); });
                form.Categories.forEach((catO, catI) => { this.Categories.push(new Models.IntakeFormCategory(catO)); });

                // this a temporary fix, it's best to add the attachments to the fields on the backend to avoid the below code.
                this.Categories.forEach((catO, catI) => {

                    catO.Fields.forEach((cfO, cfI) => {

                        this.Attachments.forEach((attO, attI) => {

                            if (attO.FormFieldID == cfO.FormFieldID) {

                                cfO.Attachments.push(new ClaimIncidentAttachments(attO));

                            }

                        });

                    });

                });

                this.IncidentReport.IncidentID = this.IncidentID;
                this.IncidentReport.get().always((incident) => {
                    mW.classes.propagatePropertyValues(incident, this.IncidentReport);
                    PL.Claims.APIs.ClaimRepo.getLateReasons().always((lateReasons) => {
                        lateReasons.forEach((lateO, lateI) => {
                            this.LateReasons.push(lateO);
                        });
                        PL.Claims.APIs.FormTypeRepo.getInstructions(this.FormTypeID).always((instructions) => {
                            //mW.classes.propagatePropertyValues(instructions, this.Instructions);
                            this.FinishInstructions = instructions.FinishInstructions;
                            this.ClientInformation.ClientID = this.ClientID;
                            this.ClientInformation.getClientInformation().always((clientInfo) => {
                                mW.classes.propagatePropertyValues(clientInfo, this.ClientInformation);
                                this.ClientAddress.ClientID = this.ClientID;
                                this.ClientAddress.get().always((clientAddress) => {
                                    mW.classes.propagatePropertyValues(clientAddress, this.ClientAddress);
                                    this.AdditionalClientInformation.ClientID = this.ClientID;
                                    this.AdditionalClientInformation.AddTypeID = 4;
                                    this.AdditionalClientInformation.get().always((additionalClientInfo) => {
                                        mW.classes.propagatePropertyValues(additionalClientInfo, this.AdditionalClientInformation);
                                        PL.CRM.APIs.Location.getLocations(this.ClientID).always((locations) => {
                                            locations.forEach((locO, locI) => {
                                                this.Locations.push(locO);
                                            });                                            
                                            $result.resolve(this);
                                        });
                                    });
                                });
                            });
                        });
                    });
                });                                                                
            });                                        
            return $result;
        }

        save(): JQueryDeferred<number> {
            var $result = $.Deferred();

            this.IncidentReport.FormTypeID = this.FormTypeID;
            this.IncidentReport.ClientNumber = this.ClientInformation.ClientNumber;
            this.IncidentReport.GroupID = this.ClientInformation.GroupID;            
            this.IncidentReport.IncidentID = (this.IncidentID == 0 ? 0 : this.IncidentID);
            this.IncidentReport.save().done((incidentID) => {
                this.IncidentID = incidentID;
                $result.resolve(incidentID);
            });
            
            return $result;
        }

    }

} 