class NumericHelpers {

    //The new ECMAScript Internationalization API offers a numberformat function.
    //http://www.ecma-international.org/ecma-402/1.0/
    //https://developer.mozilla.org/en-US/docs/JavaScript/Reference/Global_Objects/NumberFormat        

    static formatCurrency(value: number|string, decPlaces: number = 2, thouSeparator: string = ',', decSeparator: string = '.', isCurrencySymbol: boolean = false): string {
                
        decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
        decSeparator = decSeparator == undefined ? "." : decSeparator;
        thouSeparator = thouSeparator == undefined ? "," : thouSeparator;
        var currencySymbol = (isCurrencySymbol == true ? "$" : '');

        var n:any = value,
            sign = n < 0 ? "-" : "",
            i:any = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
            j = (j = i.length) > 3 ? j % 3 : 0;

        return sign + currencySymbol + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
    }

    static deformatCurrency(value: string): string {
        value = value.replace(',', '').replace('$','');        
        return value;
    }
} 