var Template_DistributionEmailList = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.DistributionList : depth0), {"name":"each","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n";
},"2":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <tr><td>"
    + escapeExpression(((helper = (helper = helpers.Greeting || (depth0 != null ? depth0.Greeting : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Greeting","hash":{},"data":data}) : helper)))
    + "</td><td>"
    + escapeExpression(((helper = (helper = helpers.FirstName || (depth0 != null ? depth0.FirstName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"FirstName","hash":{},"data":data}) : helper)))
    + "</td><td>"
    + escapeExpression(((helper = (helper = helpers.LastName || (depth0 != null ? depth0.LastName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"LastName","hash":{},"data":data}) : helper)))
    + "</td><td>"
    + escapeExpression(((helper = (helper = helpers.Title || (depth0 != null ? depth0.Title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Title","hash":{},"data":data}) : helper)))
    + "</td><td>"
    + escapeExpression(((helper = (helper = helpers.Client || (depth0 != null ? depth0.Client : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Client","hash":{},"data":data}) : helper)))
    + "</td><td>"
    + escapeExpression(((helper = (helper = helpers.Email || (depth0 != null ? depth0.Email : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Email","hash":{},"data":data}) : helper)))
    + "</td></tr>";
},"4":function(depth0,helpers,partials,data) {
  return "        No list to display.\r\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "﻿<table width=\"100%\" class=\"dtable sortable\">\r\n    <thead>\r\n        <tr>\r\n            <td>Greeting</td>\r\n            <td>First Name</td>\r\n            <td>Last Name</td>\r\n            <td>Title</td>\r\n            <td>Client</td>\r\n            <td>Email Address</td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.DistributionList : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </tbody>\r\n</table>";
},"useData":true});