module PL.Claims.APIs {

    export class IntakeFormCategoryRepo {

        private static _routePrefix = '';



    }

} 