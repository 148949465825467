
module PL.AdminTools.Controllers {

    export class ClaimIntakeEditFields extends PL.AdminTools.Controllers.Base_ClaimIntakeEdit {

        private _$fieldContainer: JQuery = $('#fieldFields');
        private _$parentContainer: JQuery = $('#formTypeEditContainer');
        private _openCount: number = 0;
        private _activeTab: number = 2;

        FormTypeObject: PL.Claims.Models.FormType;
        CurrentCategory: PL.Claims.Models.FormCategory;
        ContentTypes: PL.Common.Models.ContentType[];

        init(claimIntake: Controllers.ClaimIntakeEdit): JQueryDeferred<any> {
            this._$parentContainer = claimIntake._$parentContainer;
            var $result = $.Deferred<any>();
            this.FormTypeObject = claimIntake.FormTypeObject;
            this.ContentTypes = claimIntake.ContentTypes;
                this.bind().done(() => {
                    //this.setupEvents();
                    //this.setup();
                });
            return $result.resolve();
        }

        bind(): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            mW.handlebars.bind(CHSITemplates.AdminTools_ClaimIntakeEditor_Fields, { Fields: this.FormTypeObject.FormCategory.FormFields, Categories: this.FormTypeObject.FormCategories, ContentTypes: this.ContentTypes }, this._$fieldContainer);
            this.bindSortable();
            return $result.resolve();
        }

        bindCategories(): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            mW.handlebars.bind(CHSITemplates.AdminTools_ClaimIntakeEditor_Categories, { Categories: this.FormTypeObject.FormCategories }, $('#catFields'));
            super.bindSortable();
            return $result.resolve();
        }

        setupEvents(): void {
            $('#fieldFilterCategory').val(this.FormTypeObject.FormCategory.CategoryName);
            $('#fieldToggleAll').prop('disabled', true);
        }

        setup(): void {
            var _self = this;
            
            this._$parentContainer.on('change', '#fieldFilterCategory', function () {
                var categoryName = $(this).val();
                _self.filterRow(categoryName);
                _self.getExpandedRowCount();
            });

            this._$parentContainer.on('click', '#fieldToggleAll', function () {
                _self.minimizeAll();
            });

            this._$parentContainer.on('click', '#fieldAdd', function () {
                _self.add();
            });

            this._$parentContainer.on('click', '#fieldSave', function () {
                _self.save();
            });

            this._$fieldContainer.on('change', '.fieldType', function () {
                var fieldTypeID = $(this).val();
                fieldTypeID = fieldTypeID - 1;
                _self.toggleFieldTypeOptions(fieldTypeID, $(this).closest('.field'));
            });

            this._$fieldContainer.on('keyup', '.fieldTypeText', function () {
                var fieldID = $(this).closest('.field').data('fieldid'), name = $(this).attr('name'), value = $(this).val();
                _self.update(fieldID, name, value);
            });

            this._$fieldContainer.on('change', '.fieldTypeSelect', function () {
                var fieldID = $(this).closest('.field').data('fieldid'), name = $(this).attr('name'), value = $(this).val();
                _self.update(fieldID, name, value);
            });

            this._$fieldContainer.on('change', '.fieldTypeCheckbox', function () {
                var fieldID = $(this).closest('.field').data('fieldid'), name = $(this).attr('name'), value = ($(this).is(':checked') == true ? true : false);
                _self.update(fieldID, name, value);
            });

            this._$fieldContainer.on('change', '.fieldCategory', function () {
                var fieldID = $(this).closest('.field').data('fieldid'), value = $(this).find('option').filter(':selected').text();
                _self.update(fieldID, 'CategoryName', value);
            });

            this._$fieldContainer.on('click', '.fieldEdit', function () {
                _self.toggleRowDisplay($(this));
            });

            this._$fieldContainer.on('click', '.fieldRevert', function () {
                // TBD - requires specs
            });

            this._$fieldContainer.on('click', '.fieldDelete', function () {
                var fieldID = $(this).closest('.field').data('fieldid');
                $(this).closest('.field').fadeOut('fast');
                _self.delete(fieldID);
            });

            this._$fieldContainer.on('keyup', '.fieldTypeHTML, .fieldCalculated, .fieldDropDownSource, .fieldTypeAttachment', function () {
                var remaining = (490 - $(this).val().length);
                $(this).closest('.field').find('.htmlRemainingLength').text(remaining);
            });
        }

        selectCategory(categoryName: string): void {
            this.FormTypeObject.FormCategories.forEach((cO, cI) => {
                if (cO.CategoryName == categoryName) {
                    this.FormTypeObject.FormCategory = cO;
                }
            });
        }

        filterRow(categoryName: string): void {
            this.selectCategory(categoryName);
            this.bind();
        }

        toggleFieldTypeOptions(fieldTypeID: number, $parent: JQuery): void {
            var fieldID = $parent.data('fieldid');
            $parent.find('.fieldTypeOptions').removeClass('fieldTypeOptionsActive');
            $parent.find('.fieldTypeOptions').eq(fieldTypeID).addClass('fieldTypeOptionsActive');

            $parent.find('.fieldToolTip, .fieldDataType, .fieldDataSize, .fieldDisplayFormat, .fieldIsSummarized, .fieldIsRequired').prop('disabled', false).attr('title', '');
            $parent.find('.fieldIsRequired').prop('checked', true);

            if (fieldTypeID == 5) {
                $parent.find('.fieldLongName, .fieldToolTip, .fieldDataType, .fieldDataSize, .fieldDisplayFormat, .fieldIsSummarized, .fieldIsRequired').prop('disabled', true).attr('title', 'This field is not required when field type (HTML) is selected.');
                $parent.find('.fieldIsRequired, .fieldIsSummarized').prop('checked', false);
                this.update(fieldID, 'Required', false);
                this.update(fieldID, 'Summarized', false);
            }

            if (fieldTypeID == 6) {
                $parent.find('.fieldLongName, .fieldToolTip, .fieldDataType, .fieldDataSize, .fieldDisplayFormat, .fieldIsSummarized, .fieldIsRequired').prop('disabled', true).attr('title', 'This field is not required when field type (Attachment) is selected.');
                $parent.find('.fieldIsRequired, .fieldIsSummarized').prop('checked', false);
                this.update(fieldID, 'Required', false);
                this.update(fieldID, 'Summarized', false);
            }
        }

        minimizeAll(): void {
            this.FormTypeObject.FormCategory.FormFields.forEach((ffO, ffI) => { ffO.isExpanded = false; });
            $('.fieldMasterDetail').slideUp('fast', function () {
                $('.editIcon').removeClass('ui-icon-triangle-1-n');
                $('.editIcon').addClass('ui-icon-triangle-1-s');
                $('.fieldMasterDetail').removeClass('fieldMasterDetailShow');
                $('#fieldToggleAll').data('opencount', '').prop('disabled', true);
            });
        }

        toggleRowDisplay($jqContext: JQuery): void {
            var openCount = +$('#fieldToggleAll').data('opencount'), fieldID = $jqContext.closest('.field').data('fieldid'), _self = this, $detail = $jqContext.closest('.field').find('.fieldMasterDetail');

            if ($detail.is(':visible')) {
                $detail.slideUp('fast', function () {
                    $jqContext.find('.editIcon').removeClass('ui-icon-triangle-1-n');
                    $jqContext.find('.editIcon').addClass('ui-icon-triangle-1-s');
                    $detail.removeClass('fieldMasterDetailShow');
                    _self.update(fieldID, 'isExpanded', false);
                    _self.getExpandedRowCount();
                });
            } else {
                $detail.slideDown('fast', function () {
                    $jqContext.find('.editIcon').removeClass('ui-icon-triangle-1-s');
                    $jqContext.find('.editIcon').addClass('ui-icon-triangle-1-n');
                    _self.update(fieldID, 'isExpanded', true);
                    _self.getExpandedRowCount();
                });
            }
        }

        getExpandedRowCount(): void {
            this.FormTypeObject.FormCategory.FormFields.forEach((ffO, ffI) => {
                if (ffO.isExpanded != undefined) {
                    if (ffO.isExpanded) {
                        this._openCount = (this._openCount + 1);
                    }
                }
            });

            if (this._openCount > 1) {
                $('#fieldToggleAll').prop('disabled', false);
            } else {
                $('#fieldToggleAll').prop('disabled', true);
            }
            this._openCount = 0;
        }

        save(): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            $('#fieldSave').prop('disabled', true);
            $('#fieldSave').val(' Saving... ');
            this.FormTypeObject.FormCategory.saveFormFields().done((data) => {
                $('#fieldSave').val(' Save ');
                $('#fieldSave').prop('disabled', true);
                $('#fieldFilterCategory').prop('disabled', false).attr('title', '');
                this.FormTypeObject.FormCategory.getFormFields().done((formFields) => {   
                    this.FormTypeObject.FormFields = formFields;
                    this.FormTypeObject.reconstructFieldCategoryRelationship();                    
                    this.bind();
                    $('.okClose').prop('disabled', false);
                    $result.resolve(true);
                });
            });
            return $result;
        }

        add(): void {
            $('.okClose').prop('disabled', true);
            $('#fieldFilterCategory').prop('disabled', true).attr('title', 'You can only save field changes to one category at a time. Please save your changes before switching to a new category.');
            if (this.FormTypeObject.FormCategory == undefined) {
                this.FormTypeObject.FormCategory = this.FormTypeObject.FormCategories[0];
                this.FormTypeObject.FormCategory.FormFields = new Array<PL.Claims.Models.FormField>();
            }

            var field: PL.Claims.Models.FormField = new PL.Claims.Models.FormField;

            if (this.FormTypeObject.FormCategory.FormFields.length == 0) {
                field.FormFieldID = -1;
                field.FormSortOrder = 0;
                field.GridSortOrder = 0;
            } else {
                field.FormFieldID = (-Math.abs(this.FormTypeObject.FormCategory.FormFields[this.FormTypeObject.FormCategory.FormFields.length - 1].FormFieldID - 1));
                field.FormSortOrder = (this.FormTypeObject.FormCategory.FormFields[this.FormTypeObject.FormCategory.FormFields.length - 1].FormSortOrder + 1);
                field.GridSortOrder = (this.FormTypeObject.FormCategory.FormFields[this.FormTypeObject.FormCategory.FormFields.length - 1].GridSortOrder + 1);
            }
            field.FormCategoryID = this.FormTypeObject.FormCategory.FormCategoryID;
            field.CategoryName = this.FormTypeObject.FormCategory.CategoryName;
            field.FormTypeID = this.FormTypeObject.FormTypeID;
            field.Status = 'New';
            field.ToolTipText = '';
            field.Required = true;
            field.FieldType = 1;
            field.FieldLogic = '';
            field.DataType = 1;
            field.DataSize = null;
            field.Summarize = false;
            field.FormShow = true;
            field.GridShow = false; // when we add grid support, we'll have to update this
            field.PortalReadOnly = false // when we add portal support, we'll have to update this
            field.FormEditable = false;
            field.DisplayFormat = ''; // TODO
            field.isExpanded = false;

            this.FormTypeObject.FormCategory.FormFields.push(field);
            this.FormTypeObject.FormFields.push(field);

            this.FormTypeObject.FormCategory.FieldCount = this.FormTypeObject.FormCategory.FieldCount++;

            this.FormTypeObject.FormCategories.forEach((fcO, fcI) => {
                if (this.FormTypeObject.FormCategory.CategoryName == fcO.CategoryName) {
                    fcO.FieldCount = (fcO.FieldCount + 1);
                }
            });

            $('#fieldSave').val(' Save unsaved Fields ').prop('disabled', false);

            this.bind().done(() => {
                this.bindCategories();
            });

        }

        update(formFieldID: number, propName: string, value: string | number | boolean): void {
            this.FormTypeObject.FormCategory.FormFields.forEach((ffO, ffI) => {
                if (ffO.FormFieldID == formFieldID) {
                    if (propName == 'isExpanded') {
                        ffO.isExpanded = <boolean>value;
                    }
                    if (<Object>ffO.hasOwnProperty(propName)) {
                        ffO[propName] = value;
                    }
                }
            });
            if (propName != 'isExpanded') {

                $('#fieldSave').val(' Save Field Changes ').prop('disabled', false);
            }
        }

        delete(formFieldID: number): void {
            var field: PL.Claims.Models.FormField = new PL.Claims.Models.FormField,
                newFieldCount = 0;
            field.FormFieldID = formFieldID;
            field.FormTypeID = this.FormTypeObject.FormTypeID;

            this.FormTypeObject.FormCategory.FormFields.forEach((ffO, ffI, ff) => {

                if (ffO.FormFieldID == formFieldID) {
                    if (ffO.Status != 'New') {
                        field.deleteField();
                    }

                    ff.splice(ffI, 1);

                    this.FormTypeObject.FormCategories.forEach((fcO, fcI) => {
                        if (this.FormTypeObject.FormCategory.CategoryName == fcO.CategoryName) {
                            fcO.FieldCount = (fcO.FieldCount - 1);
                        }
                    });

                    this.bind().done(() => {
                        this.bindCategories();
                    });

                }

                if (ffO.Status == 'New') {
                    newFieldCount++;
                }

            });

            if (newFieldCount == 1) {
                $('.okClose').prop('disabled', false);
            }
        }

        getField(formFieldID: number): PL.Claims.Models.FormField {
            var field: PL.Claims.Models.FormField;
            this.FormTypeObject.FormCategory.FormFields.forEach((ffO, ffI) => {
                if (ffO.FormFieldID == formFieldID) {
                    field = ffO;
                }
            });
            return field;
        }
    }
}