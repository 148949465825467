var Template_QuotePremium = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "    <tr>\r\n        "
    + alias2((helpers.GetAppearanceTitle || (depth0 && depth0.GetAppearanceTitle) || alias1).call(depth0,"Variables",((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.VariableID : stack1),1,{"name":"GetAppearanceTitle","hash":{},"data":data}))
    + "\r\n        "
    + alias2((helpers.GetAppearanceControl || (depth0 && depth0.GetAppearanceControl) || alias1).call(depth0,"Variables",((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.VariableID : stack1),(depth0 != null ? depth0.value : depth0),1,1,{"name":"GetAppearanceControl","hash":{},"data":data}))
    + "\r\n    </tr>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing;

  return "﻿<h3 style=\"clear:both; border-bottom: solid 1px gray;\">Summary</h3>\r\n<br />\r\n<table style=\"float:left; margin-right:5px;\" cellspacing=\"0\" cellpadding=\"2\" width=\"550px\" class=\"VerticalTable\">\r\n"
    + ((stack1 = (helpers.eachProperty || (depth0 && depth0.eachProperty) || alias1).call(depth0,(depth0 != null ? depth0.Vars : depth0),{"name":"eachProperty","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    <tr>\r\n        <td>\r\n            &nbsp;\r\n        </td>\r\n    </tr>\r\n</table>\r\n<h3 style=\"clear:both;border-bottom: solid 1px gray;\">Policy Total</h3>\r\n<br />\r\n<div style=\"text-align:left; padding-right: 42px;\">\r\n    <span class=\"FreeLabel\">Policy Total: </span><input type=\"text\" id=\"jxPolicyTotal\" readonly=\"readonly\" value=\""
    + this.escapeExpression(((helper = (helper = helpers.PolicyTotal || (depth0 != null ? depth0.PolicyTotal : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"PolicyTotal","hash":{},"data":data}) : helper)))
    + "\" />\r\n</div>";
},"useData":true});