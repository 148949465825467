module PL.ClaimSystem.ClaimManagement {
    export class EventModel {
        constructor() { }

        public EventID: number;
        public ParentID: number;
        public EventType: string;
        public EventResult: string;
        public Status: string;
        public CreateUser: string;
        public CreateTmsp: string;
    }
}