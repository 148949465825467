module PL.AdminTools.APIs {
    export class QuoteDocumentBatchRepository {

        getQuoteDocumentBatch(ID: number): JQueryDeferred<Models.QuoteDocumentBatch> {
            var Results = jQuery.Deferred();
            $.get('/API/BI/QuoteDocumentBatch/GetByID?ID=' + ID).done(
                function (data) {
                    Results.resolve(new Models.QuoteDocumentBatch(data));
                });

            return Results;
        }

        //Return a typed deferred object.  handles all async issues plus adds Type Validation on the receiving side.
        getQuoteDocumentBatcheGrid(): JQueryDeferred<GUI.Smart.SmartObject> {
            var Results = jQuery.Deferred();
            $.get('/API/BI/QuoteDocumentBatch/Get').done(
                function (data) {
                    Results.resolve(data);
                })
            return Results;
        }

        saveBatch(QuoteDoc: PL.AdminTools.Models.QuoteDocumentBatch): JQueryDeferred<boolean> {
            var Results = jQuery.Deferred();
            console.log('about to save');
            //Don't send full objects to the web.api, the results are unreliable
            var CleanedObject = new IO.cleanedJSONObject(QuoteDoc);
            var URL = '/API/BI/QuoteDocumentBatch/Save';
            $.post(URL, CleanedObject).done(
                function () {
                    Results.resolve(true);
                });
            return Results;
        }
    }
}