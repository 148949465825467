CHSI.Claims.ClaimIntake.Start = {
    Init: function (formTypeID, ClientID, incidentID) {

        $('#jxNext').hide();
        $('#jxCancel').hide();
        $('#jxPrevious').hide();
        $('#jxFinish').hide(); 

        
        this.Templates.Start = CHSITemplates.ClaimIntakeForm_Start;
        
        this.Cache.IncidentID = incidentID;
        this.Cache.FormTypeID = formTypeID;
        this.Cache.ClientID = ClientID;
       
        this.Calls.GetInstructions(function () {
            CHSI.Claims.ClaimIntake.Start.Calls.GetClientInformation(function () {
                CHSI.Claims.ClaimIntake.Start.Calls.GetAdditionalClientInformation(function () {
                    CHSI.Claims.ClaimIntake.Start.Calls.GetLocations(function () {
                        CHSI.Claims.ClaimIntake.Start.Binds.Start();
                    });
                });                
            });            
        });        

        this.Events.Global();
    },
    Templates: {},
    Cache: {
        ClaimObject: {}
    },
    Binds: {
        Start: function () {
            $('#DynamicFormContainer').html(CHSI.Claims.ClaimIntake.Start.Templates.Start({ ClaimObject: CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject }));

            $('#jxIncidentDate').datepicker({
                showOn: "both",
                buttonImage: "/images/icons/cal.gif",
                buttonImageOnly: true,                
            });

            $('#ui-datepicker-div').hide();

            $('#jxNext').show();
            $('#jxCancel').show();
            $('#jxPrevious').hide();
            $('#jxFinish').hide();
            $('#jxPreparing').hide();
        }
    },
    Events: {
        Global: function () {

        },
        PersistIncident: function () {
            CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.NewIncidentObject = {};
            var NewIncidentObject = CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.NewIncidentObject,
                ClaimIntakeObject = CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject;

            NewIncidentObject.StateAccountNo = ClaimIntakeObject.AdditionalClientInformation.DetailValue == null ? '' : ClaimIntakeObject.AdditionalClientInformation.DetailValue;
            NewIncidentObject.TypeOfEmployer = 'Private';
            NewIncidentObject.FormType = ClaimIntakeObject.FormTypeName;
            NewIncidentObject.NatureOfBusiness = ClaimIntakeObject.ClientInformation.Industry;
            NewIncidentObject.ClientNumber = ClaimIntakeObject.ClientInformation.ClientNumber;
            NewIncidentObject.GroupID = ClaimIntakeObject.ClientInformation.GroupID;
            NewIncidentObject.LocationID = $('#jxLocation').val();
            NewIncidentObject.IncidentDate = $('#jxIncidentDate').val();
            NewIncidentObject.IncidentID = CHSI.Claims.ClaimIntake.Start.Cache.IncidentID != null ? CHSI.Claims.ClaimIntake.Start.Cache.IncidentID : 0;
            NewIncidentObject.Status = ClaimIntakeObject.Status;
            if (NewIncidentObject.Status.toLowerCase() == 'active')
            {
                NewIncidentObject.Status = 'new';
            }
            NewIncidentObject.FormTypeID = CHSI.Claims.ClaimIntake.Start.Cache.FormTypeID;

            CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.NewIncidentObject = NewIncidentObject;
            return NewIncidentObject;
        }
    },
    Calls: {
        GetClientInformation: function (successFunction) {
            var Options = {};
            Options.URL = '/api/CRM/Clients/GetClient?ClientID=' + CHSI.Claims.ClaimIntake.Start.Cache.ClientID;
            Options.Success = function (data) {
                CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.ClientInformation = data;
                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        GetAdditionalClientInformation: function (successFunction) {
            var Options = {};
            Options.URL = '/api/CRM/Clients/GetClientAddInfo?ClientID=' + CHSI.Claims.ClaimIntake.Start.Cache.ClientID + '&AddTypeID=4';
            Options.Success = function (data) {
                CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.AdditionalClientInformation = data;
                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        GetLocations: function (successFunction) {
            var Options = {};
            Options.URL = '/api/CRM/Location/GetLocationsByClientID?ClientID=' + CHSI.Claims.ClaimIntake.Start.Cache.ClientID;
            Options.Success = function (data) {
                CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.Locations = data;
                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        GetInstructions: function (successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/Form/GetInstructions?FormTypeID=' + CHSI.Claims.ClaimIntake.Start.Cache.FormTypeID;
            Options.Success = function (data) {
                CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.InstructionsObject = data;
                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        SaveIncident: function (successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/ClaimIncidentReport/SaveIncident';
            Options.Data = CHSI.Claims.ClaimIntake.Start.Events.PersistIncident();
            Options.Success = function (data) {
                if (CHSI.Claims.ClaimIntake.Start.Cache.IncidentID == null||CHSI.Claims.ClaimIntake.Start.Cache.IncidentID ==0) {
                    CHSI.Claims.ClaimIntake.Start.Cache.IncidentID = data;
                    CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.IncidentID = data;
                    CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.NewIncidentObject.IncidentID = data;
                }
                if (successFunction) {
                    successFunction(data);
        }
            };
            CHSI.Common.Ajax.Post(Options);
        },
    }
}