CHSI.Common.Reference = {
    Init: function (ReferenceTable) {

        
        switch (ReferenceTable) {
            case 'UW':
                this.Cache.NameSpace = 'UW';
                this.UpdateURL('uw_Reference', 'uw_reference');
                this.Cache.ReferenceEditorURL = '/UW/Modals/ReferenceEditor.aspx?ReferenceTable=UW';
                break;
            case 'ClaimSystem':
                this.Cache.NameSpace = 'ClaimSystem';
                this.UpdateURL('cl_Reference', 'cl_Reference');
                this.Cache.ReferenceEditorURL = '/UW/Modals/ReferenceEditor.aspx?ReferenceTable=ClaimSystem';
                break;
            case 'Inventory':
                this.Cache.NameSpace = 'Inventory';
                this.UpdateURL('st_Reference', 'st_Reference');
                this.Cache.ReferenceEditorURL = '/UW/Modals/ReferenceEditor.aspx?ReferenceTable=Inventory';
                break;
        }
        var Template_ReferenceType = $('#Template_ReferenceType').html();
        this.Templates.ReferenceType = Handlebars.compile(Template_ReferenceType);
        var Template_References = $('#Template_References').html();
        this.Templates.References = Handlebars.compile(Template_References);
        this.Calls.GetReferenceTypes();
        CHSI.Common.Reference.Events.Global();
    },
    Templates: {

    },
    Cache: {
        ReferenceTypes: null,
        References: null,
        FieldName: '',
        Status: 'active'
        , NameSpace: ''
        , ReferenceTable: ''
        , TableName:''
        , GetReferencesURL: ''
        , GetReferenceTypesURL: ''
        , ReferenceEditorURL:''

    },
    BindPresentationLayer: function () {
        $('#jxReferenceContainer').html(this.Templates.References(this.Cache.References));
        restart = true;
        sorttable.init();
    },
    BindPresentationLayerReferenceType: function () {
        $('#jxReferenceTypeContainer').html(this.Templates.ReferenceType(this.Cache.ReferenceTypes));        
    },
    GetSelectedReference: function (){
        CHSI.Common.Reference.Cache.FieldName = $('#jxReferenceType').val();
        CHSI.Common.Reference.UpdateURL($('#jxReferenceType').find(':selected').data('tablename'), $('#jxReferenceType').find(':selected').data('referencetable'));
        CHSI.Common.Reference.Calls.GetReferences();
    },
    Events: {
        Global: function () {
            $('#jxReferenceTypeContainer').on('change', '#jxReferenceType', function () {                
                CHSI.Common.Reference.GetSelectedReference();
            });

            $('#jxAddNew').on('click', function () {
                CHSI.Common.Reference.ShowNewReferenceEdit(CHSI.Common.Reference.Cache.FieldName, CHSI.Common.Reference.Cache.TableName, CHSI.Common.Reference.Cache.ReferenceTypes.ReferenceTypes[0].DisplayName);
            });

            $('#jxReferenceTypeContainer').on('change', '#jxStatus', function () {
                if ($(this).is(':checked')) {
                    CHSI.Common.Reference.Cache.Status = 'active';
                    CHSI.Common.Reference.Calls.GetReferences();
                } else {
                    CHSI.Common.Reference.Cache.Status = '';
                    CHSI.Common.Reference.Calls.GetReferences();
                }
            });

        }
    },
    Calls: {
        GetReferences: function () {
            var AjaxURL = CHSI.Common.Reference.Cache.GetReferencesURL + CHSI.Common.Reference.Cache.FieldName + '&Status=' + CHSI.Common.Reference.Cache.Status;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: true,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Common.Reference.Cache.References = { References: e };
                    CHSI.Common.Reference.BindPresentationLayer();
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },

        GetReferenceTypes: function () {
            var AjaxURL = CHSI.Common.Reference.Cache.GetReferenceTypesURL;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Common.Reference.Cache.ReferenceTypes = { ReferenceTypes: e };
                    CHSI.Common.Reference.BindPresentationLayerReferenceType();
                    if (e.length > 0) {
                        CHSI.Common.Reference.GetSelectedReference();
                    }                    
                },
                error: function (e) {
                    CHSI.Common.Log('no data to return');
                }
            });
        }
    },
    UpdateURL: function (TableName, ReferenceTable) {
        this.Cache.TableName = TableName;
        this.Cache.ReferenceTable = ReferenceTable;
        this.Cache.GetReferenceTypesURL = '/api/' + this.Cache.NameSpace + '/ReferenceSpec/GetTypes?TableName=' + this.Cache.TableName;
        this.Cache.GetReferencesURL = '/api/' + this.Cache.NameSpace + '/ReferenceSpec/Get?TableName=' + this.Cache.TableName + '&ReferenceTable=' + this.Cache.ReferenceTable + '&IncludeValues=true&FieldName=';        
    },
    RefreshPage: function () {
        CHSI.Common.Reference.Calls.GetReferenceTypes();
        CHSI.Common.Reference.Calls.GetReferences();
    },

    ShowReferenceEdit: function (ReferenceValueID, FieldName, TableName, DisplayName, isNew) {
        var QueryString = '';
        if (ReferenceValueID != 0) {
            QueryString = '&ReferenceValueID=' + ReferenceValueID
        }
        if (FieldName) {
            QueryString += '&FieldName=' + FieldName;
        }
        if (TableName) {
            QueryString += '&TableName=' + TableName;
        }
        if (isNew == null) {
            isNew = false;
            QueryString += '&NewReference=' + isNew;
        } else {            
            QueryString += '&NewReference=' + isNew;
        }
        var ModifedReferenceObject = CHSI.Common.Reference.Cache.References;        
        ModifedReferenceObject.References.ReferenceValues = null;
        var URL = CHSI.Common.Reference.Cache.ReferenceEditorURL + QueryString + '&ReferenceObject=' + JSON.stringify(ModifedReferenceObject);        
        ShowPopup('ReferenceEditor', URL, DisplayName, 300, 150, CHSI.Common.Reference.RefreshPage, true);
    },
    ShowNewReferenceEdit: function (FieldName, TableName, DisplayName) {
        this.ShowReferenceEdit(0, FieldName, TableName, DisplayName, true);
    }

}