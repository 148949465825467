module PL.ClaimSystem.ClaimManagement {
    export class PaymentModel {
        constructor() { this.Payee = new PayeeModel; }

        public PaymentID: number;
        public ClaimID: number;
        public ClaimantID: number;
        public Payee: PayeeModel;
        public Status: string;
        public Source: string;
        public ReserveType: string;
        public ExtKey: string;
        public PaymentCategory: string;
        public PaymentMethod: string;
        public PaymentDate: string;
        public InvoicedAmount: number;
        public DiscountAmount: number;
        public PaidAmount: number;
        public ReferenceNumber: string;
        public InvoiceNumber: string;
        public Recovery: boolean;
        public Comments: string;
        public Printed: boolean;
        public Voided: boolean;
        public CreateUser: string;
        public CreateTmsp: string;
        public UpdateUser: string;
        public UpdateTmsp: string;
    }
}