CHSI.Page.BrokerQuoteSubmissions = {
    Init: function () {
        var Template_QuoteSubmissions = $('#Template_QuoteSubmissions').html();
        this.Templates.QuoteSubmissions = Handlebars.compile(Template_QuoteSubmissions);
        this.Calls.GetGroups();
        this.Calls.GetQuoteSubmissionsObject(function () {
            CHSI.Page.BrokerQuoteSubmissions.Binds.QuoteSubmissions();
        });        
        this.Events.Global();
    },
    Templates: {},
    Cache: {
        SubmissionsObject: []
        , Groups: null
        , GroupSelectorFlag:true
    },
    Settings: {

    },
    Binds: {
        QuoteSubmissions: function () {
            $('#jxQuoteSubmissionsContainer').html(CHSI.Page.BrokerQuoteSubmissions.Templates.QuoteSubmissions({ Submissions: CHSI.Page.BrokerQuoteSubmissions.Cache.SubmissionsObject }));
        }
    },
    Events: {
        Global: function () {
            $('#jxQuoteSubmissionsContainer').on('click', '#jxSubmitQuote', function () {
                // TBD
                var ShowGroupSelectorURL = '/producers/modals/GroupSelector.aspx?UserID=' + CurrentUserID;
                var ModalWidth = 260;
                var ModalHeight = 160;
                var ModalTitle = "Group Selector";
                if (CHSI.Page.BrokerQuoteSubmissions.Cache.GroupSelectorFlag == false) {
                    ShowGroupSelectorURL = '/producers/modals/brokerportalquoteeditor.aspx?groupid=' + CHSI.Page.BrokerQuoteSubmissions.Cache.Groups[0].GroupName;
                    ModalHeight = 500;
                    ModalWidth = 700;
                    ModalTitle = "Quote Indication";
                }
                ShowPopup("GroupSelector", ShowGroupSelectorURL, ModalTitle, ModalWidth, ModalHeight, null, true);
            });
        }
    },
    Calls: {
        GetQuoteSubmissionsObject: function (successFunction) {
            var Options = {};
            Options.URL = '/api/UW/BrokerPortal/Get';
            Options.Success = function (data) {

                CHSI.Page.BrokerQuoteSubmissions.Cache.SubmissionsObject = data;

                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },

        GetGroups: function (successFunction) {
            var Options = {};
            Options.URL = '/api/CRM/Group/GetGroupsFromProducerUserID?UserID=' + CurrentUserID;
            Options.Success = function (data) {

                CHSI.Page.BrokerQuoteSubmissions.Cache.Groups = data;

                if (data && data.length > 1) {
                    CHSI.Page.BrokerQuoteSubmissions.Cache.GroupSelectorFlag = true;
                } else {
                    CHSI.Page.BrokerQuoteSubmissions.Cache.GroupSelectorFlag = false;
                }

                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        GetLocalization: function (successFunction) {
            var Options = {};
            Options.URL = '';
            Options.Success = function (data) {
                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        HasPermission: function () {
            var Options = {};
            Options.URL = '';
            Options.Success = function (data) {
                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Get(Options);
        }
    }
}

Handlebars.registerHelper('ConfirmRole', function (RoleList, argh1, options) {

    for (i = RoleList.length - 1; i <= RoleList.length; i--) {

        if (RoleList[i] == argh1) {
            return options.fn(this);
        }

        if (i == 0) {
            return options.inverse(this);
        }
    }
});