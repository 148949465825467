module PL.Claims.Modules {

    export class _$category {

        $: JQuery;
        $active: JQuery;
        $category: JQuery;
        $progress_bar: JQuery;

        index: number;
        next_index: number;
        previous_index: number;
        last_index: number;
        category_type: string;
        status: string;
        categories: any[];
        category: any;
        Fields: PL.Claims.Models.IntakeFormField[] = new Array<PL.Claims.Models.IntakeFormField>();

        constructor(index?: number) {

            this.$ = $('.categoryContainer');
            this.$active = $('.activeCategory')
            this.index = 0;
            this.categories = this.categories == undefined ? [] : this.categories;
            this.category = this.categories[this.index];
            this.next_index = (this.index + 1);
            this.previous_index = (this.index - 1);
            this.Fields = this.category == undefined ? [] : this.category[this.index].Fields;

        }

        $makeActive(): void {

            this.$.removeClass('activeCategory');
            $('.staticCategoryContainer').removeClass('activeCategory');
            this.$category.addClass('activeCategory');
            this._$makeActiveProgress(this.$progress_bar.attr('class').split(' ')[1]);

        }

        getCategory(index?: number): _$category {

            this.$category = this.$.eq(index);
            this.$progress_bar = this.$category.find('.innerCategoryProgress');
            this.category_type = this.$category.data('type');            
            this.status = this.$progress_bar.data('status');
            this.index = this.$category.data('index');
            this.next_index = (this.index + 1);
            this.previous_index = (this.index - 1);
            this.category = this.categories[index];
            if (this.categories[index] == null) {
                this.Fields = [];
            } else {
                this.Fields = this.categories[index].Fields;
            }
            this.last_index = this.categories.length - 1;

            return this;

        }

        $getCategory(index): _$category {

            this.$category = this.$.eq(index);
            this.category_type = this.$category.data('type');

            return this;

        }

        private _$makeActiveProgress(hasClass): void {

            $('.innerCategoryProgress').removeClass('activeSuccess activeWarning activeError');

            switch (hasClass) {

                case 'progressSuccess':
                    this.$progress_bar.addClass('activeSuccess');
                    break;
                case 'progressWarning':
                    this.$progress_bar.addClass('activeWarning');
                    break;
                case 'progressError':
                    this.$progress_bar.addClass('activeError');
                    break;
                default:
                    this.$progress_bar.addClass('activeSuccess');

            }

        }

    }

    export class ClaimIntakeForum_Category {

        

    }

}