CHSI.Claims.ClaimIntake.Dynamic = {
    Init: function (fieldObject, incidentID) {
        
        this.Templates.Dynamic = CHSITemplates.ClaimIntakeForm_Dynamic; // Handlebars.compile(Template_Dynamic);

        this.Cache.IncidentID = incidentID;
        this.Cache.FieldsObject = fieldObject.Fields;
        
        this.Binds.Dynamic(function () {
            CHSI.Claims.ClaimIntake.Dynamic.Events.PopulateFieldData();
        });

        this.Events.Global();
    },
    Templates: {},
    Cache: {
        FieldsObject: {},
        FieldsObjectValues: {},
        SelectedDate: null
    },
    Binds: {
        Dynamic: function (successFunction) {
            $('#DynamicFormContainer').html(CHSI.Claims.ClaimIntake.Dynamic.Templates.Dynamic({ Fields: CHSI.Claims.ClaimIntake.Dynamic.Cache.FieldsObject }));
                        
            $('.Date').datepicker({
                showOn: "both",
                buttonImage: "/images/icons/cal.gif",
                buttonImageOnly: true,
                onSelect: function () {                    
                    var ua = window.navigator.userAgent;
                    var msie = ua.indexOf("MSIE ");

                    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)){
                        $(this).blur();
                    }else{
                        $(this).focus().blur();
                    }                    
                }
            });

            $('#jxPrevious').attr('disabled', false);
            $('#jxPrevious').show();
            $('#jxNext').attr('disabled', false);
            $('#jxNext').show();
            $('#jxFinish').hide();
            $('#jxPreparing').hide();

            if (successFunction) {
                successFunction();
            }
        }
    },
    Events: {
        Global: function () {
            $('#DynamicFormContainer').on('keyup', 'input', function () {

                var ChangedField = $(this),
                    index = ChangedField.data('index'),
                    dataSize = ChangedField.data('datasize'),
                    value = ChangedField.val();

                ChangedField.val(CHSI.Claims.ClaimIntake.Dynamic.Events.DataSizeValidator(value, dataSize));
            });

            $('#DynamicFormContainer').on('blur', '.Regular', function () {
                var Element = $(this);
                var ElementVal = Element.val();
                var Format = Element.data('displayformat');
                var DataType = Element.data('datatype');
                Element.val(CHSI.Claims.ClaimIntake.Dynamic.Events.FormatString(ElementVal, Format, DataType));

                var validator = $('form').validate({
                    debug: true,
                    onkeyup: false,
                    onfocusout: false
                });

                validator.element(this);                

                $('#jxNext').attr('disabled', false);
                $('#jxPrevious').attr('disabled', false);
            });

            $('#DynamicFormContainer').on('blur', '.Calculated', function () {
            });

            $('#DynamicFormContainer').on('blur', '.Date', function () {
                var Element = $(this);
                var FormatedDate = CHSI.Dates.GetShortDate(Element.val());
                Element.val(FormatedDate);

                var validator = $('form').validate({
                    debug: true,
                    onkeyup: false,
                    onfocusout: false
                });

                validator.element(this);   

                $('#jxNext').attr('disabled', false);
                $('#jxPrevious').attr('disabled', false);
            });

            $('#DynamicFormContainer').on('blur', '.DropDown', function () {
                var validator = $('form').validate({
                    debug: true,
                    onkeyup: false,
                    onfocusout: false
                });
                validator.element(this);                

                $('#jxNext').attr('disabled', false);
                $('#jxPrevious').attr('disabled', false);
            });

            $('#DynamicFormContainer').on('blur', '.TextBlock', function () {
                var validator = $('form').validate({
                    debug: true,
                    onkeyup: false,
                    onfocusout: false
                });
                validator.element(this);                

                $('#jxNext').attr('disabled', false);
                $('#jxPrevious').attr('disabled', false);
            });            

        },
        SaveFields: function () {
            var NewFieldsArray = [],
                NewFieldsObject = {},
                Fields = $('.FieldType');
            
            for (Index = 0; Index < Fields.length ; Index++) {
                NewFieldsObject = {};                
                NewFieldsObject.FormFieldID = CHSI.Claims.ClaimIntake.Dynamic.Cache.FieldsObject[Index].FormFieldID;
                NewFieldsObject.IncidentID = CHSI.Claims.ClaimIntake.Dynamic.Cache.IncidentID;
                NewFieldsObject.FieldValue = $('#jxField_' + Index).val();
                NewFieldsArray.push(NewFieldsObject);
            }
            return NewFieldsArray;
        },
        PopulateFieldData: function () {
            CHSI.Claims.ClaimIntake.Dynamic.Calls.GetFieldValueInformation(function () {
                var FieldValues = CHSI.Claims.ClaimIntake.Dynamic.Cache.FieldsObjectValues,
                Fields = $('.FieldType');

                for (Index = 0; Index < Fields.length; Index++) {
                    var CurrentField = $('#jxField_' + Index),
                        CurrentFormFieldID = CurrentField.data('formfieldid')

                    for (FieldIndex = 0; FieldIndex < FieldValues.length; FieldIndex++) {
                        if (CurrentFormFieldID == FieldValues[FieldIndex].FormFieldID) {
                            CurrentField.val(FieldValues[FieldIndex].FieldValue);
                            break;
                        }
                    }
                }
            });
            
        },
        DataSizeValidator: function (CurrentValue, DataSize) {
            var CharLimit = 0;
            
            if (CurrentValue.indexOf('.') == -1) {
                CharLimit = CurrentValue;
            } else {
                var DecimalValue = CurrentValue.split('.'),
                    Characteristic = DecimalValue[0],
                    Mantissa = DecimalValue[1] * 1;
                    
                if (Mantissa == 0 || '') {
                    CharLimit = CurrentValue;
                } else {                    
                    if (Mantissa.toString().length > DataSize) {
                        Mantissa = Mantissa.toString().substring(0, DataSize);
                    }
                    DecimalValue = Characteristic + '.' + Mantissa;
                    CharLimit = DecimalValue;
                }                
            }

            return CharLimit;
        },
        ApplyPrevious: function (index, priorvalue, actualvalue) {
            $('#jxField_' + index).val(priorvalue);
            $('#jxRevertField_' + index).hide();
            $('#jxField_' + index).removeClass('HighlightGreen');
        },
        SetLabel: function () {
            if (this.Cache.PendingChanges) {
                $('#jxFieldLabel').html("Values have been changed and are waiting for administrator action.<br />The scheduled item status will remain pending until changes are saved.");
                if (CHSI.Page.SItemFormItem.Cache.Deleted == false) {
                    $('#jxItemLabel').html("Approval Required:  Field Value(s) Changed");
                    if (this.Cache.NewItem) {
                        $('#jxItemLabel').html("Approval Required:  New Item Added");
                    }
                }
            } else {
                if (this.Cache.NewItem) {
                    $('#jxFieldLabel').html("A new item has been added and waiting for administrator action.<br />The scheduled item status will remain pending until changes are saved.");
                    if (CHSI.Page.SItemFormItem.Cache.Deleted == false) {
                        $('#jxItemLabel').html("Approval Required:  New Item Added");
                    }
                } else {
                    $('#jxFieldLabel').html("");
                    if (CHSI.Page.SItemFormItem.Cache.Deleted == false) {
                        $('#jxItemLabel').html("");
                    }
                }
            }
        },
        FormatString: function (SValue, Format, DataType) {
            var FormatedText = SValue;
            if (SValue && Format && DataType) {
                var ElementVal = CHSI.Claims.ClaimIntake.Dynamic.Events.GetValue(SValue, DataType);
                FormatedText = String.__Format(Format, ElementVal);
            }
            return FormatedText;
        },
        GetValue: function (SValue, DataType) {
            var ElementVal = SValue;
            switch (DataType) {
                case 3: //Decimal
                    ElementVal = parseFloat(ElementVal.replace(/[^0-9\.]/g, ''));
                    break;
                case 5: //Integer                    
                    ElementVal = parseInt(ElementVal.replace(/,/g, ''), 10);
                    break;
            }
            return ElementVal;
        },
    },
    Calls: {
        GetFieldValueInformation: function (successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/Form/GetIntakeFormFields?ClaimIncidentReportID=' + CHSI.Claims.ClaimIntake.Dynamic.Cache.IncidentID;
            Options.Success = function (data) {
                CHSI.Claims.ClaimIntake.Dynamic.Cache.FieldsObjectValues = data;
                if (successFunction) {
                    successFunction(data);
                }                
            }
            CHSI.Common.Ajax.Get(Options);
        },
        SaveFields: function (successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/Form/SaveIntakeFormField';
            Options.Data = CHSI.Claims.ClaimIntake.Dynamic.Events.SaveFields();
            Options.Success = function () {
                if (successFunction) {
                    successFunction();
                }                    
            }
            CHSI.Common.Ajax.Post(Options);
        }
    }
}