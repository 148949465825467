declare function ShowPopup(WindowID?, WinURL?, WinTitle?, width?, height?, CloseFunction?, ismodal?, isWindowLocked?, ShowHelp?, SuccessFunction?, panelContent?);

declare function closeThis(closeFunction);

module PL.Que.Models {

   export class Form {
       FormID: number;
       Title: string;
       DisplayTitle: string;
       FormDescription: string;
       Status: string;
       InactiveDate: Date;
       ActiveDate: Date;
       isPortalEditable: boolean;
       ReportID: number;
       DataStructureID: number;
       DataStructureName: string;
       DesignTemplateID: number;       
       isCollapsed: boolean;
       Sections: PL.Que.Models.Section[] = new Array<PL.Que.Models.Section>();
       GridFormObject: GUI.Smart.SmartObject = new GUI.Smart.SmartObject;
   }

    export class Element {
        ElementID: number;
        ParentID: number;
        Label: string;
        BlockType: number;
        ElementType: number;
        FormSectionID: number;
        IsRequired: boolean;
        AllowNA: boolean;
        AllowHTML: boolean;
        Status: string;
        TemplateRegionIndex: string;
    }

    export class Actions {
        // add props
    }

    export class Section {
        SectionID: number;
        FormID: number;
        Title: string;
        Tooltip: string;
        SortOrder: number;
        Status: string;
        CurrentVisibility: number;
        isRequired: boolean;
        SectionIndex: number;
        isCollapsed: boolean;
        Elements: Que.Models.Element[] = new Array<Que.Models.Element>();
    }

    export class QuestionnaireBase {    
            
        FormID: number;
        Title: string;
        DisplayTitle: string;
        FormDescription: string;
        Status: string;
        InactiveDate: Date;
        ActiveDate: Date;
        isPortalEditable: boolean;
        ReportID: number;
        DataStructureID: number;
        DataStructureName: string;
        DesignTemplateID: number;

        Form: PL.Que.Models.Form;
        Sections: PL.Que.Models.Section[];
        Elements: PL.Que.Models.Element[];
        Actions: PL.Que.Models.Actions[];

        ElementColumns: GUI.Smart.SmartColumnObject[] = new Array<GUI.Smart.SmartColumnObject>();
        ElementValues: string[] = new Array<string>();

        constructor() {
            this.Form = new PL.Que.Models.Form;
            this.Sections = new Array<PL.Que.Models.Section>();
            this.Elements = new Array<PL.Que.Models.Element>();
            this.Actions = new Array<PL.Que.Models.Actions>();        
        }

        static init(): void {
            var formController: PL.Que.Controllers.FormController = new PL.Que.Controllers.FormController;
            formController.initFormGrid();
        }
    }


}