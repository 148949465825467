module PL.UW.XMods.API {
    export class XModAPI {
		static getDefaultxModWorksheet(SuccessFunction?: (data) => any) {
			var URL: string = '/API/UW/Xmod/CreateDefaultxModWorksheet';
			$.get(URL).done(function (data) {
			}).then(function (data) {
				if (SuccessFunction) {
					SuccessFunction(data);
				}
			});
		}

        static getxModWorkSheet(XModID: number, SuccessFunction?: (data) => any) {
            var URL: string = '/API/UW/Xmod/GetxModWorksheet?WorksheetID=' + XModID;
            $.get(URL).done(function (data) {

            }).then(function (data) {
                if (SuccessFunction) {
                    SuccessFunction(data);
                }
            });
        }

        static getxModWorkSheets(ClientID?: string|number, PolicyPeriod?: number, GroupID?: string): JQueryDeferred<any> {
            var result = $.Deferred();
            var URL: string = '/API/UW/XMod/GetxModWorksheets?ClientID=' + ClientID + '&PolicyYear=' + PolicyPeriod + '&GroupID=' + GroupID;
            $.get(URL).done(function (data) {
                result.resolve(data);
            });
            return result;
        }
        
        static getXModPolicyPeriods(): JQueryDeferred<any> {
            var result = $.Deferred();
            var URL = '/API/UW/Xmod/GetXmodPolicyYears';
            $.get(URL).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

        static getPolicyPeriods(SuccessFunction?: (data) => any): any {
            var URL: string = '';
            $.get(URL).done(function () { }).then(function (data) {
                if (SuccessFunction) {
                    SuccessFunction(data);
                }
            });

        }
        static getActiveGroups(): JQueryDeferred<any> {
            var result = $.Deferred();
            var URL = '/API/CRM/Group/GetActiveGroups';
            $.get(URL).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

        static savexModWorksheet(xModObject: any, SuccuessFunction?: (data) => any): any {
            var URL = '/API/UW/Xmod/SavexModWorksheet';
            $.post(URL, xModObject).done(function () { }).then(function (data) {
                if (SuccuessFunction) {
                    SuccuessFunction(data);
                }
            });
        }

    }
}