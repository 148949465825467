var Template_ReportParameters = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div id=\"jxReportBlock\">\r\n    <div id=\"ReportTitle\"><h2>"
    + escapeExpression(((helper = (helper = helpers.ReportName || (depth0 != null ? depth0.ReportName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ReportName","hash":{},"data":data}) : helper)))
    + "</h2></div>\r\n    <div id=\"ReportDescription\">"
    + escapeExpression(((helper = (helper = helpers.Description || (depth0 != null ? depth0.Description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Description","hash":{},"data":data}) : helper)))
    + "</div>\r\n\r\n    <div id=\"ParameterSection\">\r\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.ApplicationReportType : depth0), 1, {"name":"compare","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n        <div id=\"ParameterTableContainer\">\r\n            <table>\r\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.Parameters : depth0), {"name":"if","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            </table>\r\n        </div>\r\n    </div>\r\n</div>\r\n<br style=\"clear: both;\" />\r\n<div id=\"jxOptionSection\">\r\n    <table>\r\n        <tr>\r\n            <td>\r\n                Output report as\r\n            </td>\r\n            <td>\r\n                <select id=\"jxOutput\">\r\n                    <option value=\"1\">PDF</option>\r\n                    <option value=\"2\">Excel</option>\r\n                    <option value=\"5\">Excel Data Only</option>\r\n                    <option value=\"6\">CSV</option>\r\n                </select>\r\n            </td>\r\n        </tr>\r\n    </table>\r\n\r\n    <br style=\"clear:both\" />\r\n    <div style=\"float:right;\">\r\n        <input type=\"button\" class=\"ui-button\" id=\"jxRunReport\" value=\"Run Report\" onclick=\"CHSI.Page.ReportInformation.RunReport();\" />\r\n        <input type=\"button\" class=\"ui-button\" id=\"jxCancel\" value=\"Cancel\" />\r\n    </div>\r\n    <input type=\"button\" class=\"ui-button\" id=\"jxAddtoFavorites\" value=\"Add to Favorites\" onclick=\"CHSI.Page.ReportInformation.AddToFavorites("
    + escapeExpression(((helper = (helper = helpers.ReportID || (depth0 != null ? depth0.ReportID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ReportID","hash":{},"data":data}) : helper)))
    + ");\" />\r\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.ApplicationReportType : depth0), 1, {"name":"compare","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n</div>\r\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.Parameters : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.program(7, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "        Select the ";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.Parameters : depth0), {"name":"each","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " to include on this report.\r\n        <br /><br />\r\n";
},"4":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = helpers['if'].call(depth0, (data && data.index), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + escapeExpression(((helper = (helper = helpers.Description || (depth0 != null ? depth0.Description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Description","hash":{},"data":data}) : helper)));
},"5":function(depth0,helpers,partials,data) {
  return ", ";
  },"7":function(depth0,helpers,partials,data) {
  return "\r\n";
  },"9":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.ApplicationReportType : depth0), 1, {"name":"compare","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.Parameters : depth0), {"name":"each","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"11":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <tr>\r\n                    <td>\r\n                        <b>"
    + escapeExpression(((helper = (helper = helpers.Description || (depth0 != null ? depth0.Description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Description","hash":{},"data":data}) : helper)))
    + "</b>\r\n                    </td>\r\n                    <td>\r\n                        "
    + escapeExpression(((helpers.GetParameterControl || (depth0 && depth0.GetParameterControl) || helperMissing).call(depth0, depth0, {"name":"GetParameterControl","hash":{},"data":data})))
    + "\r\n                    </td>\r\n                </tr>\r\n";
},"13":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <input type=\"button\" class=\"ui-button\" id=\"jxAddtoSchedule\" value=\"Add to Schedule\" onclick=\"CHSI.Page.ReportInformation.AddToSchedule("
    + escapeExpression(((helper = (helper = helpers.ReportID || (depth0 != null ? depth0.ReportID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ReportID","hash":{},"data":data}) : helper)))
    + ");\" />\r\n    <input type=\"button\" class=\"ui-button\" id=\"jxEditReportDetails\" onclick=\"ShowEditReportDetails("
    + escapeExpression(((helper = (helper = helpers.ReportID || (depth0 != null ? depth0.ReportID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ReportID","hash":{},"data":data}) : helper)))
    + ");\" value=\"Edit Report Details\" />\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "﻿";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ReportID : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});