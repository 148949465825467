CHSI.UW.ScheduleTypes.Models.ScheduleTypes = {
    ScheduleTypes: null,

    GetGroups: function(resultFunction){
        var Options = {};
        Options.URL = '/api/CRM/Group/GetActiveGroups';
        Options.Success = resultFunction;

        CHSI.Common.Ajax.Get(Options);
    },

    GetScheduleTypes: function (showInactive, groupName, resultFunction) {
        var Options = {};
        Options.URL = '/api/uw/schedule/GetAllScheduleTypes?ShowInactive=' + showInactive + '&GroupName=' + groupName;
        Options.Success = resultFunction;

        CHSI.Common.Ajax.Get(Options);
    }
}