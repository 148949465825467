CHSI.Common.ReferenceEditor = {
    Init: function (QSObject) {
        this.Cache.ReferenceValueID = QSObject.ReferenceValueID;
        this.Cache.FieldName = QSObject.FieldName;
        this.Cache.TableName = QSObject.TableName;
        this.Cache.isNew = QSObject.NewReference;
        this.Cache.AdditionalReferenceObject = QSObject.jsonReferences;
        var Template_ReferenceEditor = $('#Template_ReferenceEditor').html();
        this.Templates.ReferenceEditor = Handlebars.compile(Template_ReferenceEditor);
        this.Events.Global();

        switch (QSObject.ReferenceTable) {
            case 'UW':
                this.Cache.NameSpace = 'UW';
                this.Events.UpdateAPI_URL_GET('uw_Reference');
                this.Events.UpdateAPI_URL_DELETE('uw_Reference', 'uw_reference');
                this.Events.UpdateAPI_URL_POST('uw_Reference', 'uw_reference');
                break;
            case 'ClaimSystem':
                this.Cache.NameSpace = 'ClaimSystem';
                this.Events.UpdateAPI_URL_GET('cl_Reference');
                this.Events.UpdateAPI_URL_DELETE('cl_Reference', 'cl_Reference');
                this.Events.UpdateAPI_URL_POST('cl_Reference', 'cl_Reference');
                break;
            case 'Inventory':
                this.Cache.NameSpace = 'Inventory';
                this.Events.UpdateAPI_URL_GET('st_Reference');
                this.Events.UpdateAPI_URL_DELETE('st_Reference', 'st_Reference');
                this.Events.UpdateAPI_URL_POST('st_Reference', 'st_Reference');
                break;
        }
    },
    Templates: {},
    Cache: {
        References: {
            Status: 'Active',
            Value1: '',
            Value2: '',
            ValueN: 0
        },
        NewReferences: {
            Status: 'Active',
            Value1: ' ',
            Value2: ' ',
            ValueN: 0
        },
        ReferenceValueID: null,
        NameSpace: null,
        TableName: null,
        FieldName: null,
        ReferenceTable: null,
        GetReferencesURL: null,
        DeleteReferencesURL: null,
        PostReferencesURL: null,
        AdditionalReferenceObject: null,
        isNew: false
    },
    Events: {
        Global: function () {
            $('#jxSave').on('click', function () {
                CHSI.Common.ReferenceEditor.Events.PersistFormToCache();
                CHSI.Common.ReferenceEditor.Calls.SaveReferences();
                closeThis(true);
            });

            $('#jxDelete').on('click', function () {
                CHSI.Common.ReferenceEditor.Events.PersistFormToCache();
                CHSI.Common.ReferenceEditor.Calls.DeleteReferences();
                closeThis(true);
            });

            $('#jxCancel').on('click', function () {
                closeThis(true);
            });
        },
        UpdateAPI_URL_GET: function (ReferenceTable) {
            CHSI.Common.ReferenceEditor.Cache.ReferenceTable = ReferenceTable;
            CHSI.Common.ReferenceEditor.Cache.GetReferencesURL = '/api/' + CHSI.Common.ReferenceEditor.Cache.NameSpace + '/ReferenceValue/Get?TableName=' + CHSI.Common.ReferenceEditor.Cache.TableName + '&ReferenceValueID=' + CHSI.Common.ReferenceEditor.Cache.ReferenceValueID + '&FieldName=' + CHSI.Common.ReferenceEditor.Cache.FieldName;
            if (CHSI.Common.ReferenceEditor.Cache.isNew == "true") {
                CHSI.Common.ReferenceEditor.BindNewPresentationLayer();
            } else {
                CHSI.Common.ReferenceEditor.Calls.GetReferences();
            }
        },
        UpdateAPI_URL_DELETE: function () {
            CHSI.Common.ReferenceEditor.Cache.DeleteReferencesURL = '/api/' + CHSI.Common.ReferenceEditor.Cache.NameSpace + '/ReferenceValue/Delete?TableName=' + CHSI.Common.ReferenceEditor.Cache.TableName + '&ReferenceTable=' + CHSI.Common.ReferenceEditor.Cache.ReferenceTable + '&IncludeValues=true&FieldName=';
        },
        UpdateAPI_URL_POST: function () {
            CHSI.Common.ReferenceEditor.Cache.PostReferencesURL = '/api/' + CHSI.Common.ReferenceEditor.Cache.NameSpace + '/ReferenceValue/Post?TableName=' + CHSI.Common.ReferenceEditor.Cache.TableName + '&ReferenceTable=' + CHSI.Common.ReferenceEditor.Cache.ReferenceTable + '&IncludeValues=true&FieldName=';
        },
        PersistFormToCache: function () {
            var References = CHSI.Common.ReferenceEditor.Cache.References;
            var Value1 = $('#jxValue1');
            if (Value1.val()) {
                References.Value1 = $('#jxValue1').val();
            } else {
                References.Value1 = '';
            }
            var Value2 = $('#jxValue2');
            if (Value2.val()) {
                References.Value2 = $('#jxValue2').val();
            } else {
                References.Value2 = '';
            }
            var ValueN = $('#jxValueN');
            if (ValueN.val()) {
                References.ValueN = $('#jxValueN').val();
            } else {
                References.ValueN = 0;
            }
            References.Status = $('#jxStatus').is(':checked') ? References.Status = 'Active' : References.Status = 'Inactive';
            References.ReferenceValueID = CHSI.Common.ReferenceEditor.Cache.ReferenceValueID;
            References.TableName = CHSI.Common.ReferenceEditor.Cache.TableName;
            References.FieldName = CHSI.Common.ReferenceEditor.Cache.FieldName;
            return References;
        }
    },
    BindPresentationLayer: function () {
        $('#jxReferenceEditorContainer').html(this.Templates.ReferenceEditor(this.Cache.References));
    },
    BindNewPresentationLayer: function () {
        var AdditionalReferenceObject = CHSI.Common.ReferenceEditor.Cache.AdditionalReferenceObject
        CHSI.Common.ReferenceEditor.Cache.References = { References: CHSI.Common.ReferenceEditor.Cache.NewReferences, AdditionalReferences: AdditionalReferenceObject };
        $('#jxReferenceEditorContainer').html(this.Templates.ReferenceEditor(this.Cache.References));
    },
    Calls: {
        GetReferences: function () {
            var AjaxURL = CHSI.Common.ReferenceEditor.Cache.GetReferencesURL;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: true,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    var AdditionalReferenceObject = CHSI.Common.ReferenceEditor.Cache.AdditionalReferenceObject
                    CHSI.Common.ReferenceEditor.Cache.References = { References: e, AdditionalReferences: AdditionalReferenceObject };
                    CHSI.Common.ReferenceEditor.BindPresentationLayer();
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },
        SaveReferences: function () {
            var AjaxURL = CHSI.Common.ReferenceEditor.Cache.PostReferencesURL;
            var ajaxString = JSON.stringify(CHSI.Common.ReferenceEditor.Cache.References);
            $.ajax({
                type: "POST",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                data: ajaxString,
                success: function (e) {
                },
                error: function (e) {
                    CHSI.Common.Log(e);
                }
            });
        },
        DeleteReferences: function () {
            var AjaxURL = CHSI.Common.ReferenceEditor.Cache.DeleteReferencesURL;
            var ajaxString = JSON.stringify(CHSI.Common.ReferenceEditor.Cache.References);
            $.ajax({
                type: "DELETE",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                data: ajaxString,
                success: function (e) {
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        }
    }
}
