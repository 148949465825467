CHSI.UW.PrintStatus = {
    GetPendingPrintRequests: function (successFunction) {
        var Options = {};
        Options.URL = '/api/Que/PrintRequest/GetPrintRequests';
        Options.Success = successFunction;
        CHSI.Common.Ajax.Get(Options);
    },
    GetPendingPrintRequest: function (queueID, successFunction) {
        var Options = {};
        Options.URL = '/api/Que/PrintRequest/Get?queueID=' + queueID;
        Options.Success = successFunction;
        CHSI.Common.Ajax.Get(Options);        
    },
    GetRequestsAndCreatePending: function (printRequestObject, successFunction) {
        var Options = {};
        Options.URL = '/api/Que/PrintRequest/GetRequestsAndCreatePending';
        Options.Data = printRequestObject;
        Options.Success = successFunction;
        CHSI.Common.Ajax.Post(Options);
    },
    CancelPrintRequest: function (queueID, successFunction) {
        var Options = {};
        Options.URL = '/api/Que/PrintRequest/Cancel?queueID=' + queueID;        
        Options.Success = successFunction;
        CHSI.Common.Ajax.Delete(Options);
    }
}