CHSI.UW.MuniQuote_VariableHistory = {
    Init: function (VarID, QuoteID, EditMode) {
        this.Cache.VarID = VarID;
        this.Cache.QuoteID = QuoteID;
        if (EditMode == 'true') {
            this.Cache.EditMode = true;
        } else {
            this.Cache.EditMode = false;
        }
       
        this.Templates.History = CHSITemplates.Template_QuotePremium_VariableHistory;
        this.Events.Global();
        this.Calls.GetHistory();
    },
    Templates: {},
    Cache: {
        VarID: null,
        QuoteID: null,
        EditMode: false,
        HistoryObj: null,
        VariableName: ''
    },
    BindPresentationLayer: function () {
        $('#jxHistoryContainer').html(this.Templates.History({ HistoryObj: this.Cache.HistoryObj }));
        restart = true;
        sorttable.init();

    },

    Events: {
        Global: function () {
            $('#jxCancel').on('click', function () {
                closeThis(true);
            });

        }
    },
    Calls: {


        GetHistory: function () {
            var VarID = CHSI.UW.MuniQuote_VariableHistory.Cache.VarID;
            var Options = {};
            Options.Data = {};
            var Parameters = {};
            switch (VarID.substring(0, 3)) {
                case "RaV":
                    var IDs = VarID.replace('RaV_', '').split('_');
                    Options.Data.ProgramRateVariableID = IDs[0];
                    Options.Data.RateInfoID = IDs[1];
                    Options.URL = '/api/UW/MuniQuote/QuoteRateVariableHistory' + "?ProgramRateVariableID=" + Options.Data.ProgramRateVariableID + "&RateInfoID=" + Options.Data.RateInfoID;
                    break
                case "VaR":
                    Options.Data.VariableID = VarID.replace('VaR_', '');
                    Options.Data.QuoteID = CHSI.UW.MuniQuote_VariableHistory.Cache.QuoteID;
                    Options.URL = '/api/UW/MuniQuote/QuoteVariableHistory' + "?VariableID=" + Options.Data.VariableID + "&QuoteID=" + Options.Data.QuoteID;
                    break;
            }
            Options.Success = function (e) {

                e.EditMode = CHSI.UW.MuniQuote_VariableHistory.Cache.EditMode;
                e.VarID = CHSI.UW.MuniQuote_VariableHistory.Cache.VarID;
                CHSI.UW.MuniQuote_VariableHistory.Cache.HistoryObj = e;
                CHSI.UW.MuniQuote_VariableHistory.BindPresentationLayer();
            }
            CHSI.Common.Ajax.Post(Options);

        },

        ChangeHistory: function (VarID, NewValue, Index) {
            var ajaxData = CHSI.UW.MuniQuote_VariableHistory.Cache.HistoryObj[Index];                        
            ajaxData.Comments = 'History Change';            
            ajaxData.DataObject.isLocked = 1;           
            ajaxData.DataObject.QuoteID = CHSI.UW.MuniQuote_VariableHistory.Cache.QuoteID;
            ajaxData.Status = 'active';           
            CHSI.UW.MuniQuote.SetValue(ajaxData.DataObject, NewValue);           
            var Success = function (e) {
                var Appearance = parent.QuoteObject.Appearance;
                e.Appearance = Appearance;
                parent.QuoteObject = e;
                CHSI.UW.MuniQuote.Cache.QuoteObject = e;
                closeThis(true);
            }

            CHSI.UW.MuniQuote.SaveVariable(VarID, ajaxData, Success);
            
        },


       
    }
}
