CHSI.Common.String = new Object();

CHSI.Common.String.IsEmail = function (inputValue) {
    /*Using RegEx this function will validate a number*/
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(inputValue)) {
        return true;
    } else {
        return false;
    }
};

CHSI.Common.String.FilterString = function (Query, SearchTerm) {
    SearchTerm = SearchTerm.toLowerCase();
    var doesContain = false;
    if (Query != null) {
        if (Query.toLowerCase().indexOf(SearchTerm) != -1) {
            doesContain = true;
        }
    }
    return doesContain;
};


CHSI.Common.String.Format = function (Format, Value) {    
    return String.__Format(Format, Value);
};