module PL.CRM.Models {

    export class ClientAddInfo {

        ClientAddID: number;
        ClientID: number;
        AddTypeID: number;
        DetailValue: string;
        DetailName: string;
        CreateTmsp: Date;
        CreateUser: string;
        UpdateTmsp: Date;
        UpdateUser: string;

        constructor() {
            this.ClientAddID = null;
            this.ClientID = null;
            this.AddTypeID = null;
            this.DetailValue = null;
            this.DetailName = null;
            this.CreateTmsp = null;
            this.CreateUser = null;
            this.UpdateTmsp = null;
            this.UpdateUser = null;
        }

        get(): JQueryDeferred<ClientAddInfo> {
            var $result = $.Deferred();

            PL.CRM.APIs.Clients.getAdditionalClientInfo(this.ClientID, this.AddTypeID).done((clientInformation) => {

                $result.resolve(clientInformation);

            });

            return $result;

        }

    }

} 