module PL.AdminTools.APIs {

    export class WYSIWYG_Repo {

        private static _routePrefix: string = '/api/Que/WYSIWYG/';

        public static getForms(dataStructureID: number, templateID: number): JQueryDeferred<PL.Common.Models.SmartGrid> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'GetForms?DataStructureID=' + dataStructureID + '&TemplateID=' + templateID).done((data) => {
                results.resolve(data);
            });
            return results;
        }
        
        public static getFormTypeGridByDataStructureID(dataStructureID: number): JQueryDeferred<PL.Common.Models.SmartGrid> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'GetFormTypeGridByDataStructureID?DataStructureID=' + dataStructureID).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static saveForm(form: any): JQueryDeferred<number> {
            var results = $.Deferred();
            $.post(this._routePrefix + 'SaveForm', form).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static getCompleteFormByFormID(formID: number): JQueryDeferred<any> {
            var results = $.Deferred<any>();
            $.get(this._routePrefix + 'GetCompleteFormByFormID?FormID=' + formID).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static saveSection(section: any): JQueryDeferred<number> {
            var results = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveSection', section).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static saveSections(sections: any[]): JQueryDeferred<any> {
            var results = $.Deferred<any>();
            var cleanedSections = JSON.stringify(sections);
            mW.io.post(this._routePrefix + 'SaveSections', sections).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static saveElement(section: any): JQueryDeferred<number> {
            var results = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveElement', section).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static saveElements(sections: any[]): JQueryDeferred<any> {
            var results = $.Deferred<any>();
            var cleanedSections = JSON.stringify(sections);
            mW.io.post(this._routePrefix + 'SaveElements', sections).done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static getValueList(): JQueryDeferred<any> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'GetListSource').done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static getContentType(): JQueryDeferred<any> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'GetContentType').done((data) => {
                results.resolve(data);
            });
            return results;
        }

        public static copyForm(formObject: PL.Que.Models.Design.Form): JQueryDeferred<any> {
            var results = $.Deferred();
            mW.io.post(this._routePrefix + 'CopyForm', formObject).done((data) => {
                results.resolve(data);
            });
            return results;
        }
    }

}