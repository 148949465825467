module PL.Claims.Models {

    export class IntakeFormCategory extends FormCategory {

        Fields: Models.IntakeFormField[];

        constructor(category?: IntakeFormCategory) {

            super(<FormCategory>category);
            this.Fields = new Array<Models.IntakeFormField>();

            category.Fields.forEach((cfO, cfI) => {

                this.Fields.push(new IntakeFormField(cfO));

            });


        }

        // internal property to helps us identify the category phases - claim intake form
        //_Phase: Enums.ClaimIntakeForm_Phase | string;

        saveFields(): JQueryDeferred<Models.IntakeForm[]> {
            var result = $.Deferred();            
            APIs.IntakeFormFieldRepo.SaveIntakeFormField(this.Fields).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

    }

}