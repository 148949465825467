CHSI.SystemNotification = {
    ContainerName: '#MiniSystemNotificationResponse' || '.MiniSystemNotification' || '#SystemNotification' || '.SystemNotification', // questionable
    SetResponse: function (message, responseType, delayTimerSeconds) {
        var SystemNotification = $(this.ContainerName);

        clearTimeout(NotificationTimer);

        responseType = this.GetResponseType(responseType);

        if (delayTimerSeconds == null) {
            delayTimerSeconds = 5000;
        } else {
            delayTimerSeconds = delayTimerSeconds * 1000;
        }                		        

        if (responseType == 'red') {
            delayTimerSeconds = 9000;
        }       
		
        SystemNotification.text('');
        SystemNotification.css('color', responseType);
        SystemNotification.text(message)
        SystemNotification.show();

        var NotificationTimer = setTimeout(function () {
            SystemNotification.fadeOut('fast');
        }, delayTimerSeconds);
    },
    SetTimer: function (delayTimerSeconds) {

    },
    GetResponseType: function (responseTypeNo) {
        if (responseTypeNo == null || undefined) {
            responseTypeNo = 1;
        }        

        switch (responseTypeNo) {
            case 1: responseTypeNo = 'black'; // success/common 1
                break;
            case 2: responseTypeNo = 'red'; // warning/failure 2
                break;
            case 3: responseTypeNo = 'orange'; // misc - generic messages 3
                break;
            default:
                responseTypeNo = 'black';
        }
        return responseTypeNo;
    },
    ConstructContainer: function () {
        return '<div id="MiniSystemNotification"></div>';
    },
    SetParentTarget: function (target) {
        $(this.ContainerName).appendTo(target);
    }
}
