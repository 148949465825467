var Template_ReportImagePreview = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <img id=\"jxReportImage\" src=\"/CHSIFileHandler.axd?fileid="
    + escapeExpression(((helper = (helper = helpers.PreviewImageID || (depth0 != null ? depth0.PreviewImageID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"PreviewImageID","hash":{},"data":data}) : helper)))
    + "\" />\r\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.PreviewImageURL : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <img id=\"Img1\" src=\""
    + escapeExpression(((helper = (helper = helpers.PreviewImageURL || (depth0 != null ? depth0.PreviewImageURL : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"PreviewImageURL","hash":{},"data":data}) : helper)))
    + "\" />\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "﻿<div id=\"jxImageContainer\">\r\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.PreviewImageID : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div> ";
},"useData":true});