CHSI.Page.SInformation = {
    Init: function (QSObject, successFunction) {
        var Template_ScheduleInformation = $('#Template_ScheduleInformation').html();
        this.Templates.ScheduleInformation = Handlebars.compile(Template_ScheduleInformation);        
        this.Cache.ScheduleTypeID = QSObject.ScheduleTypeID;
        
        this.Calls.GetGroups(successFunction);
        this.Events.Global();
    },
    Templates: {

    },
    Cache: {
        ScheduleInformationObject: {
            SummaryFields: []
        }
      , Groups: null
    },
    BindPresentationLayer: function () {
        
        if (this.Cache.ScheduleInformationObject == null)
        {
            this.Cache.ScheduleInformationObject = { Groups: this.Cache.Groups, BGColor: '#595656', TextColor: '#FFFFFF' }
        } else {
            this.Cache.ScheduleInformationObject.Groups = this.Cache.Groups;
        }
        
        

        $('#jxScheduleInformationContainer').html(this.Templates.ScheduleInformation(this.Cache.ScheduleInformationObject));
        $('#jxBackgroundColorPicker').spectrum({
            move: function (hex) { CHSI.Page.SInformation.Cache.ScheduleInformationObject.BGColor = hex.toHexString(); }
          , preferredFormat: "hex"          
        });
        $('#jxTextColorPicker').spectrum({
            move: function (hex) { CHSI.Page.SInformation.Cache.ScheduleInformationObject.TextColor = hex.toHexString(); }
          , preferredFormat: "hex"          
        });        
    },
    Events: {
        SaveScheduleInformation: function()
        {
            if (!CHSI.Page.SInformation.Events.ValidateSummaryFields()) {
                alert('Please select a value in Summary Field.');
                return false;
            }
            CHSI.Page.SInformation.Events.PersistFormToCache();
            CHSI.Page.SInformation.Calls.SaveScheduleInformation(CHSI.Page.SInformation.Cache.ScheduleInformationObject);
            return true;
        }
        ,Global: function () {
           
       
            $('#jxScheduleInformationContainer').on('keyup', '#jxTileName', function () {
                CHSI.Page.SInformation.Cache.ScheduleInformationObject.TileName = $(this).val().replace(/ /g, "</br>").toUpperCase();
                $('#jxTileInformationName').html(CHSI.Page.SInformation.Cache.ScheduleInformationObject.TileName);
            });

            $('#jxScheduleInformationContainer').on('keyup', '#jxLabel', function () {
                CHSI.Page.SInformation.Cache.ScheduleInformationObject.SummaryLabel = $(this).val();
                $('#jxTileInformationLabel').html(CHSI.Page.SInformation.Cache.ScheduleInformationObject.SummaryLabel);
            });

            $('#jxScheduleInformationContainer').on('change', '#jxBackgroundColorPicker', function () {               
                CHSI.Page.SInformation.Events.UpdateColorPickerOnChange();
            });

            $('#jxScheduleInformationContainer').on('change', '#jxBackgroundColorHex', function () {
                var UpdatedBGHex = $(this).val();
                CHSI.Page.SInformation.Cache.ScheduleInformationObject.BGColor = UpdatedBGHex;
                CHSI.Page.SInformation.Events.UpdateHexOnChange(CHSI.Page.SInformation.Cache.ScheduleInformationObject.BGColor);
            });

            $('#jxScheduleInformationContainer').on('change', '#jxTextColorPicker', function () {                
                CHSI.Page.SInformation.Events.UpdateColorPickerOnChange();
            });

            $('#jxScheduleInformationContainer').on('change', '#jxTextColorHex', function () {
                var UpdateTextHex = $(this).val();
                CHSI.Page.SInformation.Cache.ScheduleInformationObject.TextColor = UpdateTextHex;
                CHSI.Page.SInformation.Events.UpdateHexOnChange(CHSI.Page.SInformation.Cache.ScheduleInformationObject.TextColor);
            });            
        },
        AutoSave: function () {
            CHSI.Page.SInformation.Events.PersistFormToCache();
            CHSI.Page.SInformation.Calls.SaveScheduleInformation();
        },
        UpdateColorPickerOnChange: function () {
            var SInformationObject = CHSI.Page.SInformation.Cache.ScheduleInformationObject;            
            $('#jxBackgroundColorPicker').val(SInformationObject.BGColor);
            $('#jxBackgroundColorHex').val(SInformationObject.BGColor);
            $('#jxTextColorPicker').val(SInformationObject.TextColor);
            $('#jxTextColorHex').val(SInformationObject.TextColor);
            CHSI.Page.SInformation.Events.PersistFormToCache();
            CHSI.Page.SInformation.BindPresentationLayer();
        },
        UpdateHexOnChange: function (UpdatedHexValue) {
            var UpdatedHexValue = CHSI.Page.SInformation.Cache.ScheduleInformationObject;
            $('#jxBackgroundColorPicker').val(UpdatedHexValue.BGColor);
            $('#jxBackgroundColorHex').val(UpdatedHexValue.BGColor);
            $('#jxTextColorPicker').val(UpdatedHexValue.TextColor);
            $('#jxTextColorHex').val(UpdatedHexValue.TextColor);
            CHSI.Page.SInformation.Events.PersistFormToCache();
            CHSI.Page.SInformation.BindPresentationLayer();
        },
        PersistFormToCache: function () {
            var SInformationObject = CHSI.Page.SInformation.Cache.ScheduleInformationObject;
            SInformationObject.Name = $('#jxName').val();
            SInformationObject.GroupName = $('#jxGroup').val();
            SInformationObject.Status = $('#jxStatus').val();
            SInformationObject.Description = $('#jxDescription').val();
            SInformationObject.TypeCategory = $('#jxCategory').val();
            SInformationObject.SortOrder = $('#jxSortOrder').val();
            SInformationObject.DefaultImageID = $('#jxDefaultImageID').val();
            SInformationObject.TileName = $('#jxTileName').val().toUpperCase();
            SInformationObject.BGColor = $('#jxBackgroundColorHex').val();
            SInformationObject.TextColor = $('#jxTextColorHex').val();
            SInformationObject.SummaryFieldID = $('#jxSummaryField').val();
            SInformationObject.SummaryLabel = $('#jxLabel').val();
            CHSI.Page.SInformation.Cache.ScheduleInformationObject = SInformationObject;
        },
        ValidateSummaryFields: function () {
            var SInformationObject = CHSI.Page.SInformation.Cache.ScheduleInformationObject;
            var isValid = false;
            var SummaryFieldHasValue = (SInformationObject.SummaryFields.length == 0) ? false : true;            
            
            if (SummaryFieldHasValue) {
                if ($('#jxSummaryField').val()) {
                    isValid = true;
                } else {
                    isValid = false;
                }
            } else {
                isValid = true;
            }
            return isValid;
        }
    },
    Calls: {
        GetDefaultImages: function () {
            var AjaxURL = "/api/UW/Schedule/GetScheduleDefaultImage";
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.SInformation.Cache.ScheduleInformationObject.DefaultImages = e;                    
                    CHSI.Page.SInformation.Calls.GetSummaryFields();                    
                },
                error: function (e) {
                    CHSI.Common.Log('no data to return');
                }
            });
        },
        GetGroups: function (successFunction) {
            var AjaxURL = "/api/CRM/Group/GetActiveGroups";
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {

                    CHSI.Page.SInformation.Cache.Groups = e;
                    
                    if (CHSI.Page.SInformation.Cache.ScheduleTypeID) {
                        
                        CHSI.Page.SInformation.Calls.GetScheduleInformationByID(CHSI.Page.SInformation.Cache.ScheduleTypeID);
                    }
                    else
                    {
                        CHSI.Page.SInformation.BindPresentationLayer();
                    }
                    successFunction();
                },
                error: function (e) {
                    CHSI.Common.Log('no data to return');
                }
            });
        }
        , GetScheduleInformationByID: function (ScheduleTypeID) {
            var AjaxURL = '/api/UW/Schedule/GetScheduleTypeByScheduleTypeID?ScheduleTypeID=' + ScheduleTypeID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: true,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    
                    CHSI.Page.SInformation.Cache.ScheduleInformationObject = e;                    
                    CHSI.Page.SInformation.Calls.GetDefaultImages(CHSI.Page.SInformation.Cache.ScheduleInformationObject.DefaultImageID);
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },
        GetSummaryFields: function () {
            var AjaxURL = '/api/UW/ScheduleField/GetSummarizedFields?ScheduleTypeID=' + CHSI.Page.SInformation.Cache.ScheduleTypeID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: true,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {                    
                    CHSI.Page.SInformation.Cache.ScheduleInformationObject.SummaryFields = e;
                    CHSI.Page.SInformation.BindPresentationLayer();                    
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },
        SaveScheduleInformation: function (ScheduleInformationObject) {            
            var AjaxURL = '/api/UW/Schedule/SaveScheduleType';
            var ajaxString = JSON.stringify(ScheduleInformationObject);
            $.ajax({
                type: "POST",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                data: ajaxString,
                success: function (e) {
                    CHSI.Page.SInformation.Cache.ScheduleTypeID = e;
                    CHSI.Page.SInformation.Cache.ScheduleInformationObject.ScheduleTypeID = e;
                    CHSI.Common.Log('saved');                            
                },
                error: function (e) {
                    CHSI.Common.Log(e);
                }
            });
        }
    }
}