module PL.Que.Models.Design {

    export class ValueList {

        ValueListID: number;
        ListName: string;
        ListSourceType: number;
        ListSourceValues: string;
        Status: string;
        ListOptions: PL.Que.Models.Design.ValueListOptions[] = new Array<PL.Que.Models.Design.ValueListOptions>();
        CreateTmsp: string|Date;
        CreateUser: string;
        UpdateTmsp: string|Date;
        UpdateUser: string;

    }

}