class ObjectHelpers {

    static arrayEquals(leftObject: any[], rightObject: any[]): boolean {
        var isChanged = false;
        for (var i = 0; i < leftObject.length; i++) {
            if (leftObject[i] != rightObject[i]) {
                isChanged = true;
            }
        }
        return isChanged
    }

} 