module PL.Que.Models {

    export class QuoteFormSummaryLookup {

        FormTypeID: number = null;
        GroupID: string = null;
        Status: string = null;
        DueWithin: number = null;
        PolicyPeriod: number = null;
        SchemaTypeID: number = null;
        ResultSetID: number = null;

        constructor(object: any) {
            mW.classes.propagatePropertyValues(object, this);
        }

        getQuoteSummaries(): JQueryDeferred<Models.QuoteForm> {

            var result = $.Deferred();

            PL.Que.APIs.QuoteForm_Repo.getQuoteSummaries(this.FormTypeID, this.GroupID, this.Status, this.PolicyPeriod, this.DueWithin, this.SchemaTypeID, this.ResultSetID).done((data) => {

                result.resolve(data);

            });

            return result;

        }

    }

}