

module GUI {

    export class DateTime {

        static date: Date;
        

        constructor() {
            
        }


        static getTodaysDate(): any {         
            console.log(moment());         
            return moment(moment()).format('MM/DD/YYYY');
        }

        static formatDate(dateFormat: Date, format?: string): any {            
            
            if (format == null || format == undefined) {
                format = 'MM/DD/YYYY';
            }

            return moment(dateFormat).format(format);

        }

        getMonthName(isFullMonthName: boolean): any {
            if (isFullMonthName == undefined) {
                isFullMonthName = true;
            }

            var month = [
                ['January', 'Jan'],
                ['February', 'Feb'],
                ['March', 'Mar'],
                ['April', 'Apr'],
                ['May', 'May'],
                ['June', 'June'],
                ['July', 'July'],
                ['August', 'Aug'],
                ['September', 'Sept'],
                ['October', 'Oct'],
                ['November', 'Nov'],
                ['December', 'Dec'],                
            ];
        }

        static validateYear(yearValue: number): boolean {
            return moment().year(yearValue).isValid();
        }

        static yearRangeValidator(yearValue): boolean {
            var isYearValid = true;
            var oldestYear = 1900;
            if (yearValue < oldestYear) {
                isYearValid = false;
            }
            var greatestYear = new Date().getFullYear() + 10; // we can increase this year range but 10 seems realistic at this point.
            if (yearValue > greatestYear) {
                isYearValid = false;
            }
            return isYearValid;
        }

        static dateRangeValidator(startDate: string, endDate: string): boolean {
            var isValid = true;
            var regExp = /(\d{1,2})\/(\d{1,2})\/(\d{2,4})/;
            if (parseInt(startDate.replace(regExp, "$3$2$1")) > parseInt(endDate.replace(regExp, "$3$2$1"))) {                
                alert('The start date cannot be greater than the end date.');
                isValid = false;
            }            
            return isValid;
        }
    }

}