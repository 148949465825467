module PL.UW.XMods.Model {
    export class XMod {
        WorkSheetID: number;
        ClientID: number;
        Status: string;
        ExcessLossAdj: number;
        ProDate: string;
        EffDate: string;
        Evaluator: string;
        Credibility: number;
        xMod: number;
        Comments: string;
        // TODO: update rest of properties
    }
} 