
module IO {
    export class JQueryHelper {

        static parseQuerystring(): any {

            var nvpair = {};
            var qs = window.location.search.replace('?', '') == '' || window.location.search.replace('?', '') == null ? window.location.href.replace('?', '') : null;
            var pairs = qs.split('&');
            $(pairs).each(function (i: number, v) {
                var pair = (<any>v).split('=');
                nvpair[pair[0]] = pair[1];
                nvpair[pair[0].toLowerCase()] = pair[1];
            });
            return nvpair;
        }

    }   
}

