var Template_ReportEditSummary = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <option value=\""
    + escapeExpression(((helper = (helper = helpers.RoleKey || (depth0 != null ? depth0.RoleKey : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"RoleKey","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.RoleName || (depth0 != null ? depth0.RoleName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"RoleName","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"3":function(depth0,helpers,partials,data) {
  var helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing;
  return "                <option value=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\" data-parameterid=\""
    + escapeExpression(((helper = (helper = helpers.ParameterID || (depth0 != null ? depth0.ParameterID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ParameterID","hash":{},"data":data}) : helper)))
    + "\" data-isrequired=\""
    + escapeExpression(((helper = (helper = helpers.Status || (depth0 != null ? depth0.Status : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Status","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.ParameterName || (depth0 != null ? depth0.ParameterName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ParameterName","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"5":function(depth0,helpers,partials,data) {
  return " checked ";
  },"7":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.ReportParameters : depth0), {"name":"each","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.Status : depth0), "Deleted", {"name":"compare","hash":{
    'operator': ("!=")
  },"fn":this.program(9, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing, buffer = "                <div class=\"jxSubmittedParameter\" data-index=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\" data-parameterid=\""
    + escapeExpression(((helper = (helper = helpers.ParameterID || (depth0 != null ? depth0.ParameterID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ParameterID","hash":{},"data":data}) : helper)))
    + "\">\r\n                    "
    + escapeExpression(((helper = (helper = helpers.ParameterName || (depth0 != null ? depth0.ParameterName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ParameterName","hash":{},"data":data}) : helper)))
    + "\r\n                    <span class=\"deleteLink\">[<a class=\"minilink jxDeleteParameter\" href=\"#\">delete</a>]</span>\r\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.Status : depth0), "required", {"name":"compare","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                </div>\r\n";
},"10":function(depth0,helpers,partials,data) {
  return "                    <span class=\"requiredIndicator\">X</span>\r\n";
  },"12":function(depth0,helpers,partials,data) {
  return "";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "﻿<table id=\"tempid1\" width=\"600\" cellpadding=\"2\">\r\n    <tr>\r\n        <td width=\"115\">Report Name</td>\r\n        <td colspan=\"3\"><input id=\"jxReportName\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.ReportName || (depth0 != null ? depth0.ReportName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ReportName","hash":{},"data":data}) : helper)))
    + "\" class=\"required\" maxlength=\"50\" /></td>\r\n        <td width=\"98\"><span id=\"jxReportName-alert\" class=\"fieldAlert\"></span></td>\r\n    </tr>\r\n    <tr>\r\n        <td valign=\"top\">Description</td>\r\n        <td colspan=\"3\"><textarea id=\"jxDescription\" rows=\"5\" cols=\"5\">"
    + escapeExpression(((helper = (helper = helpers.Description || (depth0 != null ? depth0.Description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Description","hash":{},"data":data}) : helper)))
    + "</textarea></td>\r\n        <td>&nbsp;</td>\r\n    </tr>\r\n    <tr>\r\n        <td>Report Group</td>\r\n        <td colspan=\"3\"><input id=\"jxReportGroup\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.ReportGroup || (depth0 != null ? depth0.ReportGroup : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ReportGroup","hash":{},"data":data}) : helper)))
    + "\" class=\"required\" /></td>\r\n        <td><span id=\"jxReportGroup-alert\" class=\"fieldAlert\"></span></td>\r\n    </tr>\r\n    <tr>\r\n        <td>Status</td>\r\n        <td colspan=\"3\">\r\n            <select id=\"jxStatus\" style=\"width:135px\">\r\n                <option value=\"Active\">Active</option>\r\n                <option value=\"Inactive\">Inactive</option>\r\n                <option value=\"Deleted\">Deleted</option>\r\n            </select>\r\n        </td>\r\n        <td>&nbsp;</td>\r\n    </tr>\r\n    <tr>\r\n        <td>File Name</td>\r\n        <td colspan=\"2\"><input id=\"jxFileName\" style=\"width: 250px\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.FileName || (depth0 != null ? depth0.FileName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"FileName","hash":{},"data":data}) : helper)))
    + "\" class=\"required\" /></td>\r\n        <td>\r\n            <div id=\"AttachmentStatusContainer\">\r\n                <img class=\"jxHasFileimg\" alt=\"File found\" src=\"/Images/Icons/success.png\" /><span class=\"jxHasFiletxtSuccess\">File found</span>\r\n            </div>\r\n        </td>\r\n        <td><span id=\"jxFileName-alert\" class=\"fieldAlert\"></span></td>\r\n    </tr>\r\n    <tr>\r\n        <td>Security Role</td>\r\n        <td colspan=\"3\">\r\n            <select id=\"jxSecurityRoles\" style=\"width:220px\">\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.ReportRoles : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            </select>\r\n        </td>\r\n        <td>&nbsp;</td>\r\n    </tr>\r\n    <tr>\r\n        <td>Original User</td>\r\n        <td><input id=\"jxOriginalUser\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.OriginalUser || (depth0 != null ? depth0.OriginalUser : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"OriginalUser","hash":{},"data":data}) : helper)))
    + "\" style=\"width: 125px;\" /></td>\r\n        <td width=\"99\">Approved Date</td>\r\n        <td width=\"114\"><input id=\"jxApprovedDate\" type=\"text\" style=\"width: 80px; margin-right: 4px\" value=\""
    + escapeExpression(((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || helperMissing).call(depth0, (depth0 != null ? depth0.ApprovedDate : depth0), {"name":"CHSIDate","hash":{},"data":data})))
    + "\" /></td>\r\n        <td></td>\r\n    </tr>\r\n    <tr>\r\n        <td>Criteria Instruction</td>\r\n        <td colspan=\"3\"><input id=\"jxCriteria\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.CriteriaInstruction || (depth0 != null ? depth0.CriteriaInstruction : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"CriteriaInstruction","hash":{},"data":data}) : helper)))
    + "\" /></td>\r\n        <td></td>\r\n    </tr>\r\n    <tr>\r\n        <td>Report Parameters</td>\r\n        <td>\r\n            <select id=\"jxReportParameters\">\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.AllReportParameters : depth0), {"name":"each","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            </select>\r\n        </td>\r\n        <td><input id=\"jxRequired\" type=\"checkbox\" ";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ReportParameters : depth0)) != null ? stack1['0'] : stack1)) != null ? stack1.Status : stack1), "required", {"name":"compare","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += " />Required</td>\r\n        <td align=\"right\"><input id=\"jxAddParameter\" type=\"button\" value=\"Add\" /></td>\r\n        <td></td>\r\n    </tr> \r\n    <tr>\r\n        <td></td>\r\n        <td colspan=\"3\">\r\n            <div id=\"ReportParamEditContainer\">\r\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ReportParameters : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.program(12, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </div>\r\n        </td>\r\n        <td valign=\"top\"></td>\r\n    </tr>\r\n</table>";
},"useData":true});