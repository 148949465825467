module CT.Que.Utilities {
    // No longer use this file. Keeping it for backward-compatiblity
    export class Questionnaire {

        constructor() { }
        public static showScheduleInCurrentWindow(): void {

            window.location.href = "/UW/Schedules/Default.aspx";

        }
        // Opens Schedules in a new window

        public static showScheduleInNewWindow(): void {
            window.open("/UW/Schedules/Default.aspx");

        }
        
    }
}