CHSI.Claims.FormTypes.FormPicker = {
    Init: function (qs) {
        var FormSelect_Template = $('#Template_FormTypeSelect').html();
        this.Templates.FormSelect = Handlebars.compile(FormSelect_Template);

        this.Cache.ClientID = qs.ClientID;

        if (this.Cache.ClientID) {
            CHSI.Claims.FormTypes.FormPicker.Calls.GetClientInfo(this.Cache.ClientID, function (data) {
                var ClientInfoObject = CHSI.Claims.FormTypes.FormPicker.Cache.ClientInfoObject;

                ClientInfoObject = data;
                CHSI.Claims.FormTypes.FormPicker.Cache.ClientID = data.ClientID;
                CHSI.Claims.FormTypes.FormPicker.Cache.GroupID = data.GroupID;

                $('#jxClientInfo').text(ClientInfoObject.ClientName);

                CHSI.Claims.FormTypes.FormPicker.Calls.GetFormTypes(ClientInfoObject.ClientID, function () {
                    if (CHSI.Claims.FormTypes.FormPicker.Cache.FormTypesObject.length == 1) {
                        if (CHSI.Claims.FormTypes.FormPicker.Cache.FormTypesObject[0].FormTypeID) {
                            CHSI.Claims.FormTypes.FormPicker.Cache.FormPath = '/Claims/Modals/ClaimIntakeForm.aspx?claimincidentid=0&formtypeid=' + CHSI.Claims.FormTypes.FormPicker.Cache.FormTypesObject[0].FormTypeID + '&clientid=' + CHSI.Claims.FormTypes.FormPicker.Cache.ClientID;
                        } else {
                            CHSI.Claims.FormTypes.FormPicker.Cache.FormPath = '/Claims/' + CHSI.Claims.FormTypes.FormPicker.Cache.FormTypesObject[0].FormTypeName + '?&ClientID=' + CHSI.Claims.FormTypes.FormPicker.Cache.ClientID;                            
                        }
                        CHSI.Claims.FormTypes.FormPicker.Events.RedirectToForm(CHSI.Claims.FormTypes.FormPicker.Cache.FormPath);
                    } else {
                        CHSI.Claims.FormTypes.FormPicker.BindPresentationLayer();
                    }
                });
            });
        } else {
            this.BindPresentationLayer();
        }

        this.Events.Global();
    },
    Templates: {},
    Cache: {},
    BindPresentationLayer: function () {
        $('#FormPickerContainer').html(CHSI.Claims.FormTypes.FormPicker.Templates.FormSelect({ FormTypesObject: CHSI.Claims.FormTypes.FormPicker.Cache.FormTypesObject }));

        CHSI.Claims.FormTypes.FormPicker.Events.ConfigureFields();
    },
    Events: {
        Global: function () {
            $('#FormPickerContainer').on('change', '#jxFormTypeselector', function () {
                CHSI.Claims.FormTypes.FormPicker.Events.GetCachedValues();

            });

            $('#jxClientSelect').on('click', function () {
                var thisWindow = window.name;
                var ReturnFunctionString = ((thisWindow == '') ? 'ChangeClient' : 'parent.window.frames.' + thisWindow + '.ChangeClient');
                parent.ShowAdvancedSearchForm('ChooseClient', null, ReturnFunctionString);
            });

            $('#FormPickerContainer').on('click', '#jxCancel', function () {
                closeThis(true);
            });

            $('#FormPickerContainer').on('click', '#jxGotoForm', function () {                
                CHSI.Claims.FormTypes.FormPicker.Events.GetCachedValues();
                CHSI.Claims.FormTypes.FormPicker.Events.RedirectToForm(CHSI.Claims.FormTypes.FormPicker.Cache.FormPath);

            });
        },
        RedirectToForm: function (FormPath) {
            console.log(CHSI.Claims.FormTypes.FormPicker.Cache.FormPath);
            parent.ShowPopup("NewForm", FormPath, "New Form", 1010, 770, parent.RefreshGrid, true);
            closeThis(false);

        },

        ConfigureFields: function () {
            $('#jxFormTypeselector').hide();
            $('#jxClientSelect').hide();
            $('#QuoteInfoContainer').hide();

            if (CHSI.Claims.FormTypes.FormPicker.Cache.ClientID) {
                $('#jxClientSelect').show();
                $('#jxFormTypeselector').show();
            } else {
                $('#jxClientSelect').show();
            }
        },

        GetCachedValues: function () {
            var FormTypeName = $('#jxFormTypeselector').find(':selected').data('formtypename'),
                    FormTypeID = $('#jxFormTypeselector').find(':selected').data('formtypeid'),
                    Name = $('#jxFormTypeselector').find(':selected').data('name'),
                    ReportForm = $('#jxFormTypeselector').find(':selected').data('reportform');

            CHSI.Claims.FormTypes.FormPicker.Cache.Name = Name;
            CHSI.Claims.FormTypes.FormPicker.Cache.ReportForm = ReportForm;
            CHSI.Claims.FormTypes.FormPicker.Cache.FormTypeID = FormTypeID;
            CHSI.Claims.FormTypes.FormPicker.Cache.FormTypeName = FormTypeName;
            if (FormTypeID) {
                CHSI.Claims.FormTypes.FormPicker.Cache.FormPath = '/Claims/Modals/ClaimIntakeForm.aspx?claimincidentid=0&formtypeid=' + FormTypeID + '&clientid=' + CHSI.Claims.FormTypes.FormPicker.Cache.ClientID + '&formtypename=' + FormTypeName;
            } else {
                CHSI.Claims.FormTypes.FormPicker.Cache.FormPath = '/Claims/' + FormTypeName + '?ClientID=' + CHSI.Claims.FormTypes.FormPicker.Cache.ClientID;
            }
        }
    },
    Calls: {
        GetFormTypes: function (ClientID, successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/Form/GetFormTypesByClientID?ClientID=' + ClientID;
            Options.Data = null;
            Options.Success = function (data) {
                CHSI.Claims.FormTypes.FormPicker.Cache.FormTypesObject = data;
                if (successFunction) {
                    successFunction();
                }
            }
            CHSI.Common.Ajax.Get(Options);
        },
        GetClientInfo: function (ClientID, successFunction) {
            var Options = {};
            Options.URL = '/api/CRM/Clients/GetClient?ClientID=' + ClientID;
            Options.Data = null;
            Options.Success = function (data) {
                CHSI.Claims.FormTypes.FormPicker.Cache.ClientInfoObject = data;
                if (successFunction) {
                    successFunction(data);
                }
            };
            CHSI.Common.Ajax.Get(Options);
        }
    }
}
