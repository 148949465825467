/* These functions are outside of a namespace */
/* isnull would normally only operate on null in SQL, but in this case, even if blank, we want the replacement value */
function isnull(value, replacementvalue)
{
    if (value == null)
     { return replacementvalue;}
    else
    {
        if(value == '')
        {
            return replacementvalue;
        }
        else
        {
            return value;
        }
    }
}

function sortByKey(array, key) {
    return array.sort(function (a, b) {
        var x = a[key]; var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}