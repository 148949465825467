module PL.Claims.APIs {

    export class ClaimIncidentReportRepo {

        private static _routePrefix: string = '/api/ClaimSystem/ClaimIncidentReport/';

        public static getIncident(incidentID: number): JQueryDeferred<Models.ClaimIncidentReport> {
            var result = $.Deferred();
            $.get(this._routePrefix + 'GetIncident?IncidentID=' + incidentID).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

        public static submitIncident(incidentID: number): JQueryDeferred<Models.ClaimIncidentReport> {
            var result = $.Deferred();
            $.post(this._routePrefix + 'SubmitIncident?IncidentID=' + incidentID).done(function (data) {
                result.resolve(data);
            }).fail((data) => {
                result.reject(data);
            }); 
            return result;
        }

        public static saveIncident(incidentObject: Models.ClaimIncidentReport): JQueryDeferred<number> {
            var result = $.Deferred();            
            mW.io.post(this._routePrefix + 'SaveIncident', incidentObject).done((data) => {
                result.resolve(data);
            });
            return result;
        }  

        public static executeClaimIncidentSteps(incidentID: number, executionStage: string): JQueryDeferred<number> {
            var result = $.Deferred();
            $.post(this._routePrefix + 'ExecuteClaimIncidentReportSteps?IncidentID=' + incidentID + '&ExecutionStage=' + executionStage).done(function (data) {
                result.resolve(data);
            }).fail((data) => {
                result.reject(data);
            });
            return result;
        } 
        
        public static getAttachments(incidentID: number, formFieldID: number = null): JQueryDeferred<any> {
            var result = $.Deferred();
            $.get(this._routePrefix + 'GetAttachments?IncidentID=' + incidentID + '&FormFieldID=' + formFieldID).done(function (data) {
                result.resolve(data);
            });
            return result;
        } 

        public static saveAttachment(attachment: any): JQueryDeferred<any> {
            var result = $.Deferred();
            mW.io.post(this._routePrefix + 'SaveAttachment', attachment).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

        public static deleteAttachment(fileID: number): JQueryDeferred<any> {
            var result = $.Deferred();
            $.post(this._routePrefix + 'DeleteAttachment?FileID=' + fileID).done(function (data) {
                result.resolve(data);
            });
            return result;
        }      
    }

}