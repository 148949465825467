module PL.ClaimSystem.ClaimManagement {
    export class LitigationContactModel {
        constructor() { }

        public ContactID: number;
        public ClaimRepID: number;
        public Type: string;
        public ContactName: string;
        public Firm: string;
        public AssistantName: string;
        public Status: string;
        public Address1: string;
        public Address2: string;
        public City: string;
        public State: string;
        public Zip: string;
        public Phone1: string;
        public Phone1Ext: string;
        public Phone1Type: string;
        public Phone2: string;
        public Phone2Ext: string;
        public Phone2Type: string;
        public Phone3: string;
        public Phone3Ext: string;
        public Phone3Type: string;
        public Fax: string;
        public Email: string;
        public CreateUser: string;
        public CreateTmsp: string;
        public UpdateUser: string;
        public UpdateTmsp: string;
    }
}
