CHSI.Claims.FormTypes.FormEditor = {
    Init: function (QSObject) {
        CHSI.Claims.FormTypes.Information.Init(QSObject, function () {
            CHSI.Claims.FormTypes.Categories.Init(function () {
                CHSI.Claims.FormTypes.Fields.Init();
            });
        });

        this.Events.Global();
    },
    Templates: {

    },
    Cache: {
        ScheduleObject: null,
        CombinedScheduleObject: null,
        FieldObject: null
    },
    Events: {
        Global: function () {            
            $('.actionProcess').hide();
            $('#FormTypeEditTabs').tabs({
                heightStyle: 'fill'
            }).on("tabsshow", function (event, ui) {
                switch (ui.index) {
                    case 0:
                        break;
                    case 1:
                        CHSI.Claims.FormTypes.Categories.Events.updateFieldsCategoriesList();
                        break;
                    case 2:                        
                        break;
                    default: 0
                }                
            });

            $('.jxSaveAll').on('click', function () {                
                CHSI.Claims.FormTypes.FormEditor.Events.SaveAll();
            });

            $('.jxOK').on('click', function () {
                $(this).attr('disabled', true);
                CHSI.SystemNotification.SetResponse('Saving...', 3);
                CHSI.Claims.FormTypes.Information.Events.SaveInformation(function () {
                    CHSI.Claims.FormTypes.Categories.Events.SaveCategories(function () {                        
                        CHSI.SystemNotification.SetResponse('Saved');
                        closeThis(true);                        
                    });
                });
            });
        },
        SaveAll: function () {
            $('.jxSaveAll').attr('disabled', true);
            $('.actionProcess').show();
            CHSI.SystemNotification.SetResponse('Saving...', 3);
            CHSI.Claims.FormTypes.Information.Events.SaveInformation(function () {
                CHSI.Claims.FormTypes.Categories.Events.SaveCategories(function () {
                    CHSI.Claims.FormTypes.Fields.Events.SaveFields(function () {
                        CHSI.Claims.FormTypes.Categories.Calls.GetCategories(function () {
                            CHSI.Claims.FormTypes.Fields.Calls.GetFields(function () {
                                CHSI.Claims.FormTypes.Categories.Binds.Categories();
                                CHSI.Claims.FormTypes.Fields.Binds.Fields();
                                CHSI.Claims.FormTypes.Categories.Events.updateCategorySortOrder();
                                $('.jxSaveAll').attr('disabled', false);
                                $('.actionProcess').hide();
                                CHSI.SystemNotification.SetResponse('Saved');
                            });

                        });
                    });
                });
            });

        }
    }
}

Handlebars.registerHelper('backgroundcolor', function (value, options) {
    var backgroundcolor = 'arow';
    if (value % 2 == 0) {
        backgroundcolor = 'prow';
    }
    return backgroundcolor;
});

Handlebars.registerHelper('plusOneIndex', function (options) {
    return (1 + options.data.index);
});

Handlebars.registerHelper('breakword', function (property) {
    return property.replace(/ /g, "</br>");
});

Handlebars.registerHelper('StickyCategory', function (FieldCategoryName, CategoryName, options) {

    if (FieldCategoryName == CategoryName) {

        return options.fn(this);
    }
    return options.inverse(this);
});