CHSI.Page.Review = {
    Init: function () {
        this.Settings.Filter.FormID = qs['FormID'];
        this.Settings.Filter.ParentFormID = qs['FormID'];
        this.Settings.Filter.ResultSetID = qs['ResultID'];
        this.Settings.LastFormIndex = qs['LastFormIndex'];
        this.Settings.PolicyPeriod = qs['PolicyPeriod'];
        this.Settings.GroupID = qs['GroupID'];
        this.Settings.DataStructureID = qs['DataStructureID'];
        CHSI_DataTables.SetupDataGridInputSearch();
        this.BindCategories();

        this.Templates.AnswerGrid = CHSITemplates.Template_AnswerGrid;

        this.DisplayGrid(this.Settings.Filter);

        this.Calls.GetGridForms(function () {
            CHSI.Page.Review.Settings.Filter.FormID = $('#jxGridForm').val();
            CHSI.Page.Review.Events.GetGridForms(CHSI.Page.Review.Settings.Filter.FormID);
        });        

        this.Calls.GetAttachments(function () {
            CHSI.Page.Review.BindAttachments();
        });

        this.Events.Global();

        this.BindGUI();
    }    
    ,Cache: {
        AttachmentsObject: {},
        ReviewSectionObject: {},
        PayrollIndex: null,
        FTEIndex: null,
        isSummarized: []
    },
    Settings: {
        Filter: {
            CategoryID: null,
            FormID: null,
            ResultSetID: null,
            AnswerStatus: 43
        },
        DataStructureID: null,
        LastFormIndex: null,
        PolicyPeriod: null,
        GroupID: null
    },
    Templates: {

    },
    AjaxCalls: function (Parameters) {
        if (!isAjaxRunning) {
            isAjaxRunning = true;
            CHSI.Page.Review.DisplayGrid(Parameters);
        } else {
            CHSI.Page.Review.AjaxCalls.DisplayGrid.abort();
        }
    },
    BindAttachments: function (successFunction) {
        $('#uxAttachmentsTable').dataTable({
            data: CHSI.Page.Review.Cache.AttachmentsObject.Values,
            aoColumnDefs: [
                {
                    aTargets: [0],
                    mRender: function (data, type, row) {
                        return row[1];
                    }
                },
                {
                    aTargets: [1],
                    mRender: function (data, type, row) {
                        return row[2];
                    }
                },
                {
                    aTargets: [2],
                    mRender: function (data, type, row) {
                        return row[3];
                    }
                },
                {
                    aTargets: [3],
                    mRender: function (data, type, row) {
                        return row[4];
                    }
                },
                {   bSortable:false,
                    aTargets: [4],
                    mRender: function (data, type, row) {
                        return '<td>[<a href="../chsifilehandler.axd?fileid=' + row[0] + '" target="_blank" class="minilink">View</a>]</td>';
                    }
                }
            ],
            stateSave: true
        });

        if (successFunction) {
            successFunction();
        }
    },
    BindReviewSection: function () {
        CHSI.Page.Review.Settings.ReviewContainer = {};

        $('#jxGridContainer').html('<table id="uxReviewGrid" width="100%" class="dtable"><tfoot></tfoot></table>');

        var DataSet = CHSI.Page.Review.Cache.ReviewSectionObject;
        var Row = [];
        var Rows = [];
        var columnDefs = [];
        var ClientColumnDefinition = {};
        var FooterRenderFunction = null;

        for (ColumnIndex = 0; ColumnIndex < DataSet.Columns.length; ColumnIndex++) {

            if (DataSet.Columns[ColumnIndex].title == 'Payroll') {
                CHSI.Page.Review.Cache.PayrollIndex = ColumnIndex;
            }

            if (DataSet.Columns[ColumnIndex].title == 'FTE') {
                CHSI.Page.Review.Cache.FTEIndex = ColumnIndex;
            }

            ClientColumnDefinition = {};
            ClientColumnDefinition.targets = ColumnIndex;
            /*
            If they are textboxes, we need to use custom sorting.  This will BREAK non-textbox columns
            */
            if (ColumnIndex < DataSet.Columns.length - 4)
            {
                if (DataSet.Columns[ColumnIndex].DataType == 5 || DataSet.Columns[ColumnIndex].DataType == 3) {
                /*blanks will be treated as 0s*/
                    DataSet.Columns[ColumnIndex].orderDataType = 'dom-text-numeric';
                }
                if (DataSet.Columns[ColumnIndex].DataType == 1 || DataSet.Columns[ColumnIndex].DataType == 2 || DataSet.Columns[ColumnIndex].DataType == 4) {
                    DataSet.Columns[ColumnIndex].orderDataType = 'dom-text';
                    DataSet.Columns[ColumnIndex].type = 'string'
                }
                                
                if (DataSet.Columns[ColumnIndex].CalculationType > 0) {
                    CHSI.Page.Review.Cache.isSummarized.push({ title: DataSet.Columns[ColumnIndex].title, index: ColumnIndex, total: 0 });
                } else {
                    CHSI.Page.Review.Cache.isSummarized.push({ title: '', index: null, total: 0 });
                }
            }
            
            if (DataSet.Columns[ColumnIndex].ElementID > 0) {

                if (DataSet.Columns[ColumnIndex].editable == false) {                    
                    ClientColumnDefinition.render = function (data, type, row, meta) {                        
                        return '<input type="text" class="ReviewRowCell ReadOnlyCell" value="' + data + '" readonly />';
                    }
                } else {
                    ClientColumnDefinition.render = function (data, type, row, meta) {
                        return '<input type="text" class="ReviewRowCell" value="' + data + '"/>';
                    }
                }

                
            } else {
                ClientColumnDefinition.render = function (data, type, row) {                
                    return data;
                }
            }

            if (DataSet.Columns[ColumnIndex].ElementID == -1) {
                ClientColumnDefinition.render = function (data, type, row) {
                    if (data == 'Completed') {
                        return '<span class="checkMark">ü</span>';
                    } else {
                        return '';
                    }                    
                }
            }

            columnDefs.push(ClientColumnDefinition);

            // Build table tfoot


        }

        var Column = {}
        Column.title = "";
        Column.data = null;
        Column.orderable = false;
        Column.class = 'details-control';
        Column.defaultContent = '[<a href="#" class="jxRowGridHistory minilink">History</a>]';
        DataSet.Columns.push(Column);

        var Column = {}
        Column.title = "";
        Column.data = null;
        Column.orderable = false;
        Column.class = 'details-control';
        Column.defaultContent = '<input type="button" class="jxRowGridReview" value="Review" />';
        DataSet.Columns.push(Column);

        ClientColumnDefinition = {};
        ClientColumnDefinition.targets = -2;
        ClientColumnDefinition.render = function (data, type, row, meta) {
            return '[<a href="#"  data-rowindex="' + meta.row + '" class="jxRowGridHistory minilink">History</a>]';
        }
        columnDefs.push(ClientColumnDefinition);

        ClientColumnDefinition = {};
        ClientColumnDefinition.targets = -1;
        ClientColumnDefinition.render = function (data, type, row, meta) {
            if (row[row.length - 3] == 'Completed') {
                return '';
            }else{
                return '<input type="button" data-rowindex="' + meta.row + '" class="jxRowGridReview" value="Review" />';
            }
        }
        columnDefs.push(ClientColumnDefinition);

        
        FooterRenderFunction = function (nRow, aaData, iStart, iEnd, aiDisplay) {
            
            var PayrollTotal = 0;
            var FTETotal = 0;
            var FooterHTML = [];
            var Total = 0;
            var Values = DataSet.Values;
            var Columns = DataSet.Columns;
            var footer = $('#uxReviewGrid tfoot');
            footer.html('');
            var tr = $('<tr></tr>');
          
            for (ColumnIndex = 1; ColumnIndex < Columns.length; ColumnIndex++) {                    
                if (Columns[ColumnIndex].CalculationType > 0) {
                    var Total = 0;
                    for (var IndexValue = 0; IndexValue < Values.length; IndexValue++) {
                        Total = Total + (Values[IndexValue][ColumnIndex] * 1);
                    }
                    if (isNaN(Total))
                    {
                        Total = 0;
                    }



                    tr.append('<td><span id="jxTotalColIndex_' + ColumnIndex + '" class="totalBold">' + 'Total: ' + Total + '</span></td>');
                } else {
                    tr.append('<td></td>');
                }            
            }                        
            footer.append(tr);
        }

        CHSI.Page.Review.Settings.ReviewContainer = $('#uxReviewGrid').DataTable({
            aaData: DataSet.Values,
            columns: DataSet.Columns,
            aoColumnDefs: columnDefs,
            fnFooterCallback: FooterRenderFunction,
            stateSave: true
        });
    },
    BindPresentationLayer: function () {
        $('#jxAnswerGrid').html(this.Templates.AnswerGrid(this.AnswerGridObject));
        if (this.AnswerGridObject.Answers && this.AnswerGridObject.Answers.flagAR) {
            $('#jxAutoReview').removeAttr("disabled");
        }

        $('#jxSectionTitle').text('Submission Review');
        $('#tabNavigation a').hide();
    },
    BindCategories: function () {
        var DataStructureID = CHSI.Page.Review.Settings.DataStructureID;
        var AjaxURL = "/api/Que/Questionnaire/GetCategoriesByDataStructureID?DataStructureID=" + DataStructureID;
        jQuery.ajax({
            type: "GET", url: AjaxURL,
            cache: false,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (e) {
                var html = '<option value="">All</option>' + CHSI.GUI.BuildSelectItemHTML(e, 'Value', 'Key');
                jQuery('#jxFilterCategory').html(html);
            }, error: function (e) {
                alert(e);
            }
        });
    },    
    BindGUI: function () {

        $('.Filter').off('click').on('click', function () {
            CHSI.Page.Review.Settings.Filter.AnswerStatus = 0;
            if ($('#jxFilterRequiresReview').prop('checked')) {
                CHSI.Page.Review.Settings.Filter.AnswerStatus = CHSI.Page.Review.Settings.Filter.AnswerStatus | $('#jxFilterRequiresReview').val();
            }
            if ($('#jxFilterWarnings').prop('checked')) {
                CHSI.Page.Review.Settings.Filter.AnswerStatus = CHSI.Page.Review.Settings.Filter.AnswerStatus | $('#jxFilterWarnings').val();
            }
            if ($('#jxFilterCompleted').prop('checked')) {
                CHSI.Page.Review.Settings.Filter.AnswerStatus = CHSI.Page.Review.Settings.Filter.AnswerStatus | $('#jxFilterCompleted').val();
            }
            if ($('#jxFilterModified').prop('checked')) {
                CHSI.Page.Review.Settings.Filter.AnswerStatus = CHSI.Page.Review.Settings.Filter.AnswerStatus | $('#jxFilterModified').val();
            }
            if ($('#jxFilterUnmodified').prop('checked')) {
                CHSI.Page.Review.Settings.Filter.AnswerStatus = CHSI.Page.Review.Settings.Filter.AnswerStatus | $('#jxFilterUnmodified').val();
            }

            if ($('#jxHideReviewed').prop('checked')) {
                CHSI.Page.Review.Settings.Filter.AnswerStatus = CHSI.Page.Review.Settings.Filter.AnswerStatus | $('#jxHideReviewed').val();
            }

            CHSI.Page.Review.AjaxCalls(CHSI.Page.Review.Settings.Filter);

        });



        $('#jxFilterCategory').change(function () {
            CHSI.Page.Review.Settings.Filter.CategoryID = jQuery(this).val();
            CHSI.Page.Review.AjaxCalls(CHSI.Page.Review.Settings.Filter);
        });


        
        $('#jxAnswerGrid').on('click', '.SingleAutoReview', function () {
            var date = new Date();
            var offset = date.getTimezoneOffset() * -1;
            var time = (Number(date) + (offset * 60 * 1000));
            var Button = $(this);
            var Review = {};
            Review.ColumnID = Button.data('columnid');
            Review.ValueIndex = Button.data('valueindex');
            Review.HistoryIndex = null;
            Review.ResultSetID = Button.data('resultsetid');
            Review.Status = 'Completed';
            Review.ReviewerNotes = ' System Verified - Quick Complete';
            Review.ReviewedBy = null;
            Review.ReviewedDate = CHSI.Dates.GetDateTime(new Date(time));
            Review.FormID = qs.formid;
            CHSI.Page.Review.SingleAutoReview(Review,function () {
                CHSI.Page.Review.AjaxCalls(CHSI.Page.Review.Settings.Filter);
            });

        });

        $('#Filters').on('click', '#jxAutoReview', function () {
            if (confirm('Do you want to automatically mark ALL records as \'Review Completed\' where the current and prior value match?')) {
                CHSI.Page.Review.Settings.Filter.Status = 'Completed';
                CHSI.Page.Review.Settings.Filter.ReviewerNotes = 'System Verified - Auto Review';
                CHSI.Page.Review.AutoReview(function () {                   
                    CHSI.Page.Review.AjaxCalls(CHSI.Page.Review.Settings.Filter);
                });
                $(this).attr("disabled", "disabled");
            } else {
                // Do nothing!
            }
           
        });
    },

    Events: {
        Global: function () {

            $('#tabFormReview').on('change', '#jxGridForm', function () {                
                var FormID = $(this).val();
                CHSI.Page.Review.Settings.Filter.FormID = FormID;                
                CHSI.Page.Review.Events.GetGridForms(FormID);
            });

            $('#jxGridContainer').on('change', 'td', function () {
              
                var Cell = CHSI.Page.Review.Settings.ReviewContainer.cell(this).index();                
                var Row = CHSI.Page.Review.Settings.ReviewContainer.row(Cell.row).data();
                var Column = CHSI.Page.Review.Settings.ReviewContainer.settings()[0].aoColumns[Cell.column];                
                var ValueIndex = Row[0];                
                var CellData = {};
                var ValueType = $(this).find('.ReviewRowCell').val();                

                CellData.resultid = CHSI.Page.Review.Settings.Filter.ResultSetID;
                CellData.columnid = Column.StructureColumnID;
                CellData.formid = $("#jxGridForm").val();
                CellData.valueindex = ValueIndex;
                CellData.StringValue = Column.DataType == 1 ? ValueType : null;
                CellData.DateValue = Column.DataType == 2 ? ValueType : null;
                CellData.DecimalValue = Column.DataType == 3 ? ValueType : null;
                CellData.BitValue = Column.DataType == 4 ? ValueType : null;
                CellData.IntegerValue = Column.DataType == 5 ? ValueType*1 : null;
                CellData.passedvalidation = true;
                CellData.ChangeType = Column.DataType;
                CellData.Explanation = null;
                CellData.WarningLevel = 0;
                CellData.ParentElementID = Column.ElementID;
                CellData.ClearReviewed = false;
                CHSI.Page.Review.Settings.ReviewContainer.cell({ row: CHSI.Page.Review.Settings.ReviewContainer.row(Cell.row).index(), column: Cell.column }).data(ValueType);                
                
                CHSI.Page.Review.Calls.SaveResultSetValue(CellData);                
                CHSI.Page.Review.Events.RecalculateTotals(CHSI.Page.Review.Settings.ReviewContainer.settings()[0].aoColumns[Cell.column].sTitle, CHSI.Page.Review.Settings.ReviewContainer.settings()[0].aoColumns[Cell.column].idx);
 
            });

            $("#jxGridContainer").on('click', '.jxRowGridHistory', function () {
                var tr = $(this).closest('tr');
                var CellData = CHSI.Page.Review.Settings.ReviewContainer.row(tr).data();
                var FormID = $('#jxGridForm').val();
                var ValueIndex = CellData[0];

                ShowGridRowHistory(ValueIndex, FormID, CHSI.Page.Review.Settings.Filter.ResultSetID);
            });

            $("#jxGridContainer").on('click', '.jxRowGridReview', function () {
                var tr = $(this).closest('tr');
                var CellData = CHSI.Page.Review.Settings.ReviewContainer.row(tr).data();
                var FormID = $('#jxGridForm').val();
                var ValueIndex = CellData[0];
                var RowObject = {};

                RowObject.Status = 'completed';
                RowObject.ResultSetID = CHSI.Page.Review.Settings.Filter.ResultSetID;
                RowObject.Comments = '';
                RowObject.FormID = $('#jxGridForm').val();
                RowObject.ValueIndex = ValueIndex;

                CHSI.Page.Review.Calls.SaveRowCellStatus(RowObject, function () {
                    var FormID = $('#jxGridForm').val();
                    CHSI.Page.Review.Settings.Filter.FormID = FormID;
                    CHSI.Page.Review.Events.GetGridForms(FormID);
                });
            });
        },
        ShowReviewHistory: function () {
            var URL = '';
            ShowPopup('ReviewHistory', URL, 'Review History', 350, 280, null, false);
        },
        SaveRow: function (rowIndex, structureColumnID) {

        },
        GetGridForms: function (FormID) {                        
            CHSI.Page.Review.Calls.GetGridValues(FormID, function () {
                CHSI.Page.Review.BindReviewSection();
            });
        },
        RecalculateTotals: function (calculateColumn, columnIndex) {            
            var DT = CHSI.Page.Review.Settings.ReviewContainer;
            var TotalCellCount = DT.rows()[0].length;            
            var NewPayrollTotal = 0;
            var currentValue = 0;                                   

            for (CellIndex = 0; CellIndex < TotalCellCount; CellIndex++) {                
                currentValue = DT.cell({ row: CellIndex, column: columnIndex }).data();
                NewPayrollTotal = NewPayrollTotal + (currentValue * 1);
            }            
            
            $('#jxTotalColIndex_' + columnIndex).html('<span id="jxTotalColIndex_' + columnIndex + '" class="totalBold" data-total="' + NewPayrollTotal + '">' + 'Total: ' + NewPayrollTotal + '</span>');
            
        },        

    },

    AutoReview: function (Success) {
        var Parameters = {};
        Parameters.AnswerStatus = CHSI.Page.Review.Settings.Filter.AnswerStatus;
        Parameters.CategoryID = CHSI.Page.Review.Settings.Filter.CategoryID;
        Parameters.FormID = CHSI.Page.Review.Settings.Filter.ParentFormID;
        Parameters.ResultSetID = CHSI.Page.Review.Settings.Filter.ResultSetID;

        var Options = {};
        Options.URL = "/api/Que/Result/AutoReviewResultSetValueReview";
        Options.Data = Parameters;
        Options.Success = Success;
        CHSI.Common.Ajax.Post(Options);
    },

    SingleAutoReview: function (Review, Success){        
        var Options = {};
        Options.URL = "/api/Que/Result/SaveResultSetValueReview";
        Options.Data = Review;
        Options.Success = Success;
        CHSI.Common.Ajax.Post(Options);
    },

    DisplayGrid: function (Filter) {
        var Parameters = {};
        Parameters.AnswerStatus = Filter.AnswerStatus;
        Parameters.CategoryID = Filter.CategoryID;
        Parameters.FormID = Filter.ParentFormID;
        Parameters.ResultSetID = Filter.ResultSetID;

        var AjaxURL = "/api/Que/Result/GetReviewFormAnswers";
        var ajaxString = JSON.stringify(Parameters);
        CHSI.Page.Review.AjaxCalls.DisplayGrid = $.ajax({
            type: "POST",
            url: AjaxURL,
            cache: false,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            data: ajaxString,
            success: function (e) {
                Answers = CHSI.Page.Review.ProcessAnswers(e);
                CHSI.Page.Review.AnswerGridObject = { Answers: Answers.AnswerRow };
                CHSI.Page.Review.BindPresentationLayer();
                restart = true;
                sorttable.init();
            },
            error: function (e) {
                CHSI.Common.LogObject(e);
            },
            complete: function () {
                isAjaxRunning = false;
            }
        });


    },

    ProcessAnswers: function (Answers) {
        var flagAR = Answers.AutoReviewFlag;
        for (i = 0; i < Answers.AnswerRow.length; i++) {
            switch (Answers.AnswerRow[i].DataTypeID) {
                case 1: //string
                    Answers.AnswerRow[i].Value = Answers.AnswerRow[i].StringValue;
                    Answers.AnswerRow[i].PriorValue = Answers.AnswerRow[i].PriorStringValue;
                    break;
                case 2: //date
                    Answers.AnswerRow[i].Value = CHSI.Dates.GetFormattedJSONDate(Answers.AnswerRow[i].DateValue);
                    Answers.AnswerRow[i].PriorValue = CHSI.Dates.GetFormattedJSONDate(Answers.AnswerRow[i].PriorDateValue);
                    break;
                case 3: //decimal
                    if (Answers.AnswerRow[i].ContentType == 11) {
                        Answers.AnswerRow[i].Value = CHSI.Common.Numbers.FormatCurrency(Answers.AnswerRow[i].DecimalValue, 2);
                        Answers.AnswerRow[i].PriorValue = CHSI.Common.Numbers.FormatCurrency(Answers.AnswerRow[i].PriorDecimalValue, 2);
                    } else {
                        Answers.AnswerRow[i].Value = Answers.AnswerRow[i].DecimalValue;
                        Answers.AnswerRow[i].PriorValue = Answers.AnswerRow[i].PriorDecimalValue;
                    }
                    break;
                case 4: //bit
                    Answers.AnswerRow[i].Value = Answers.AnswerRow[i].BitValue;
                    Answers.AnswerRow[i].PriorValue = Answers.AnswerRow[i].PriorBitValue;
                    break;
                case 5: //integer                                
                    if (Answers.AnswerRow[i].ContentType == 11) {
                        Answers.AnswerRow[i].Value = CHSI.Common.Numbers.FormatCurrency(Answers.AnswerRow[i].IntegerValue, 2);
                        Answers.AnswerRow[i].PriorValue = CHSI.Common.Numbers.FormatCurrency(Answers.AnswerRow[i].PriorIntegerValue, 2);
                    } else {
                        Answers.AnswerRow[i].Value = Answers.AnswerRow[i].IntegerValue;
                        Answers.AnswerRow[i].PriorValue = Answers.AnswerRow[i].PriorIntegerValue;
                    }
                    break;

            }

            if (Answers.AnswerRow[i].ReviewStatus != 'Completed') {
                if (Answers.AnswerRow[i].Value == Answers.AnswerRow[i].PriorValue || (Answers.AnswerRow[i].PriorValue == null && Answers.AnswerRow[i].Value == null)) {
                    flagAR = true;
                } else {
                    Answers.AnswerRow[i].flagAR = true;
                }                
               
            }
        }
        Answers.AnswerRow.flagAR = flagAR;
        return Answers;

    },    
    Calls: {
        GetAttachments: function (successFunction) {
            var Options = {};
            Options.URL = '/api/Que/Questionnaire/GetAttachments?ResultSetID=' + CHSI.Page.Review.Settings.Filter.ResultSetID + '&FormID=' + CHSI.Page.Review.Settings.Filter.ParentFormID;
            Options.Success = function (data) {

                CHSI.Page.Review.Cache.AttachmentsObject = data;

                if (successFunction) {
                    successFunction();
                }

            };
            CHSI.Common.Ajax.Get(Options);
        },
        GetGridValues: function (FormID, successFunction) {
            var Options = {};
            Options.URL = '/api/Que/Grid/GetGrid?FormID=' + FormID + '&ResultSetID=' + CHSI.Page.Review.Settings.Filter.ResultSetID + '&ParentFormID=' + CHSI.Page.Review.Settings.Filter.ParentFormID;
            Options.Success = function (data) {
                CHSI.Page.Review.Cache.ReviewSectionObject = data;

                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        GetGridForms: function (successFunction) {
            var Options = {};
            Options.URL = '/api/Que/Form/GetGridForms?FormID=' + CHSI.Page.Review.Settings.Filter.FormID;
            Options.Success = function (data) {
                $('#jxGridForm').html(CHSI.GUI.BuildSelectItemHTML(data, 'Title', 'FormID', null));
                if (successFunction) {
                    successFunction(data);
                }
            }
            CHSI.Common.Ajax.Get(Options);
        },
        SaveRowCellStatus: function (Filter, successFunction) {

            var Options = {};
            Options.URL = '/api/Que/Result/ReviewGridRow';
            Options.Data = Filter;
            Options.Success = function () {
                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Post(Options)
        },
        SaveResultSetValue: function (CellObject, successFunction) {
            var DataString = JSON.stringify(CellObject);
            jQuery.ajax({
                type: "POST",
                url: "/api/Que/Result/SaveResultField",
                data: DataString,
                async: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    if (successFunction) {
                        successFunction();
                    }
                }, error: jQuery_ErrorResult
            });
        },
    }
};

function ShowGridRowHistory(ValueIndex, FormID, ResultSetID) {
    var URL = '/Que/Modals/GridRowHistory.aspx?ValueIndex=' + ValueIndex + '&FormID=' + FormID + '&ResultSetID=' + ResultSetID;
    ShowPopup('QuestionReviewModal', URL, 'Question Review', 800, 700, null, true);
}

function ShowReviewAnswer(ElementID, ColumnID, ValueIndex, FormID, ResultSetID) {
    var URL = '/Que/Modals/QuestionReview.aspx?ElementID=' + ElementID + '&ColumnID=' + ColumnID + '&ValueIndex=' + ValueIndex + '&FormID=' + FormID + '&ResultSetID=' + ResultSetID;
    parent.ShowPopup('QuestionReviewModal', URL, 'Question Review', 800, 700, ModalRefresh, true);
}

function ModalRefresh() {
    CHSI.Page.Review.DisplayGrid(CHSI.Page.Review.Settings.Filter);
}