module PL.AdminTools.Models {

    export class QuestionnaireWYSIWYG {

        Form: PL.Que.Models.Design.Form;
        Section: PL.Que.Models.Design.Section = new PL.Que.Models.Design.Section;
        Sections: PL.Que.Models.Design.Section[] = new Array<PL.Que.Models.Design.Section>();
        Element: PL.Que.Models.Design.Element = new PL.Que.Models.Design.Element();
        Elements: PL.Que.Models.Design.Element[] = new Array <PL.Que.Models.Design.Element>();        
        ContentType: PL.Que.Models.Design.ContentType[] = new Array<PL.Que.Models.Design.ContentType>();
        ValueList: PL.Que.Models.Design.ValueList[] = new Array<PL.Que.Models.Design.ValueList>();
        DataStructureCategories: PL.Que.Models.Data.DataStructureCategory[] = new Array<PL.Que.Models.Data.DataStructureCategory>();
        DataStructureColumns: PL.Que.Models.Data.DataStructureColumn[] = new Array<PL.Que.Models.Data.DataStructureColumn>();
        UsedDataStructureColumns: PL.Que.Models.Data.DataStructureColumn[] = new Array<PL.Que.Models.Data.DataStructureColumn>();
        DataStructureCategory: PL.Que.Models.Data.DataStructureCategory = new PL.Que.Models.Data.DataStructureCategory();

        getForms(dataStructureID: number, templateID: number): JQueryDeferred<PL.Common.Models.SmartGrid> {
            var result = $.Deferred();
            PL.AdminTools.APIs.WYSIWYG_Repo.getForms(dataStructureID, templateID).done((data) => {
                result.resolve(data);
            });
            return result;
        }        

        getFormTypeGridByDataStructureID(dataStructureID: number): JQueryDeferred<PL.Common.Models.SmartGrid> {
            var result = $.Deferred();
            PL.AdminTools.APIs.WYSIWYG_Repo.getFormTypeGridByDataStructureID(dataStructureID).done((data) => {
                result.resolve(data);
            });
            return result;
        }        

        getCompleteFormByFormID(formID: number): JQueryDeferred<QuestionnaireWYSIWYG> {

            var results = $.Deferred<QuestionnaireWYSIWYG>(), _self = this;

            PL.AdminTools.APIs.WYSIWYG_Repo.getCompleteFormByFormID(formID).done((data) => {

                var metaSections: PL.Que.Models.MetaAttributes[] = mW.io.reconstruct_object(data.SectionMetaAttributes);
                var metaElements: PL.Que.Models.MetaAttributes[] = mW.io.reconstruct_object(data.ElementMetaAttributes);
                var metaForm: PL.Que.Models.MetaAttributes[] = mW.io.reconstruct_object(data.FormMetaAttributes);

                _self.Form = mW.io.reconstruct_object(data.Form)[0];
                _self.Sections = mW.io.reconstruct_object(data.Sections);
                _self.Elements = mW.io.reconstruct_object(data.Elements);
                _self.ContentType = mW.io.reconstruct_object(data.ContentType);
                _self.ValueList = mW.io.reconstruct_object(data.ValueList);
                _self.DataStructureCategories = mW.io.reconstruct_object(data.DataStructureCategories);
                _self.DataStructureColumns = mW.io.reconstruct_object(data.DataStructureColumns);

                // add meta attributes to the form, if it has any
                _self.Form.MetaAttributes = metaForm;                             
                // add meta attributes to their respective elements
                _self.Elements.forEach((eO, eI) => {
                    eO.MetaAttributes = new Array<PL.Que.Models.MetaAttributes>();
                    metaElements.forEach((mO, mI) => {
                        if (eO.ElementID == mO.ParentID) {
                            eO.MetaAttributes.push(mO);
                        }

                        // lets remove any used datastructure columns from the "DataStructureColumns" object that are currently being used by any of the elements as a meta attribute and store them in the "UsedDataStructureColumns" object when we need to use them again later
                        if (mO.ObjectTypeID == 13) {

                            _self.DataStructureColumns.forEach((dscolO, dscolI, dscol) => {

                                if (+mO.MetaValue == dscolO.ColumnID) {

                                    // add it to "UsedDataStructureColumns"
                                    _self.UsedDataStructureColumns.push(dscolO);
                                    // remove it from "DataStructureColumns"
                                    dscol.splice(dscolI, 1);

                                }

                            });

                        }

                    });
                });                

                // next we assemble the datastructures categories and columns (notice that we build the datastructures and columns after we build the elements)
                _self.DataStructureCategories.forEach((dscatO, dscatI, dscat) => {
                    dscatO.Columns = new Array<PL.Que.Models.Data.DataStructureColumn>();
                    _self.DataStructureColumns.forEach((dscolO, dscolI) => {
                        if (dscatO.CategoryID == dscolO.DataCategoryID) {
                            dscatO.Columns.push(dscolO);
                        }
                    });
                });
                
                _self.Sections.forEach((sO, sI) => {
                    // we have to initialize the meta attributes and elements arrays since typescript won't do it for us :(
                    sO.MetaAttributes = new Array<PL.Que.Models.MetaAttributes>();
                    sO.Elements = new Array<PL.Que.Models.Design.Element>();
                    // add meta attributes to their respective sections
                    metaSections.forEach((mO, mI) => {
                        if (sO.SectionID == mO.ParentID) {
                            sO.MetaAttributes.push(mO);
                        }
                    });
                    // while we're inside the sections, let's add the elements to their respective sections since we've already applied the meta attributes to those elements                    
                    _self.Elements.forEach((eO, eI) => {
                        if (sO.SectionID == eO.FormSectionID) {
                            sO.Elements.push(eO);
                        }
                    });
                });
                
                results.resolve(_self);
            });

            return results;

        }

        saveForm(section: any): JQueryDeferred<number> {
            var result = $.Deferred();
            PL.AdminTools.APIs.WYSIWYG_Repo.saveForm(section).done((data) => {
                result.resolve(data);
            });
            return result;
        }

        saveSection(section: any): JQueryDeferred<number> {
            var result = $.Deferred();
            PL.AdminTools.APIs.WYSIWYG_Repo.saveSection(section).done((data) => {
                result.resolve(data);
            });
            return result;
        }

        saveSections(sections: any[]): JQueryDeferred<any> {
            var result = $.Deferred<any>();
            PL.AdminTools.APIs.WYSIWYG_Repo.saveSections(sections).done((data) => {
                result.resolve(data);
            });
            return result;
        }

        saveElement(element: any): JQueryDeferred<number> {
            var result = $.Deferred();
            PL.AdminTools.APIs.WYSIWYG_Repo.saveElement(element).done((data) => {
                result.resolve(data);
            });
            return result;
        }

        saveElements(elements: any[]): JQueryDeferred<any> {
            var result = $.Deferred<any>();
            PL.AdminTools.APIs.WYSIWYG_Repo.saveElements(elements).done((data) => {
                result.resolve(data);
            });
            return result;
        }

        getValueList(): JQueryDeferred<PL.Que.Models.Design.ValueList[]> {
            var result = $.Deferred<PL.Que.Models.Design.ValueList[]>();
            PL.AdminTools.APIs.WYSIWYG_Repo.getValueList().done((data) => {
                result.resolve(data);
            });
            return result;
        }

        getContentType(): JQueryDeferred<PL.Que.Models.Design.ContentType[]> {
            var result = $.Deferred<PL.Que.Models.Design.ContentType[]>();
            PL.AdminTools.APIs.WYSIWYG_Repo.getContentType().done((data) => {                
                result.resolve(data);
            });
            return result;
        }

        getSelection(sectionID: number): PL.Que.Models.Design.Section {
            var section: PL.Que.Models.Design.Section;
            this.Sections.forEach((eO, eI) => {
                if (eO.SectionID == sectionID) {
                    section = eO;
                }
            });
            return section;

        }
    }

}