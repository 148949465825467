module CL.UW.Model {
    export class GroupLineDocument
    {
        DocumentID: number;
        GroupLineID: number;
        DocumentType: string;
        ReportID: number;
        Status: string;
        Description: string;
        SortOrder: number;
        OutputPath: string;
        ParentDocumentID: number;

        public static getGroupDocumentType(GroupLineID: number): JQueryDeferred<Model.GroupLineDocument[]>
        {
            return Factories.RepositoryFactory.getDocumentRepository().getGroupDocumentType(GroupLineID);
        }

    }

}