namespace PL.Claims.Controllers {

    export class ClaimIntakeNavigationV2Controller extends Abstract.ClaimIntakeFormBase {

        private _categoryIndex: number;
        private _start: Claims.Controllers.ClaimIntakeStartV2Controller;
        private _dynamic: Claims.Controllers.ClaimIntakeDynamicV2Controller;
        private _finish: Claims.Controllers.ClaimIntakeFinishV2Controller;

        constructor(intakeForm: PL.Claims.Models.IntakeForm) {

            super(intakeForm);
            this.ClaimIntakeForm = intakeForm;

        }

        init(): void {            
            this.bind().done(() => {
                this.setupEvents();                
                this.event();
                this.bindCategory(Enums.ClaimIntakeForm_Phase.START);
            });
        }        

        private bind(): JQueryDeferred<any> {   
            var $result = $.Deferred();
            $result.resolve(mW.handlebars.bind(CHSITemplates.ClaimIntakeForm_Navigation, { IntakeForm: this.ClaimIntakeForm }, this.$container))
            return $result;
        }

        private setupEvents(): void {               
            
            this.ClaimIntakeForm.Categories.forEach((catO, catI) => {                
                this.updateCategoryProgress(catI);
                this.setCategoryStatusAsEnum(catO._Status, catI);
            });

            this.Notification = new Claims.Controllers.ClaimIntakeNotificationV2Controller;
            this.Notification.init();            
            this._start = new Claims.Controllers.ClaimIntakeStartV2Controller(this.ClaimIntakeForm);
            this._start.Notification = this.Notification;
            this._start.events();
            this._dynamic = new Claims.Controllers.ClaimIntakeDynamicV2Controller(this.ClaimIntakeForm);
            this._dynamic.Notification = this.Notification;
            this._dynamic.events();
            this._finish = new Claims.Controllers.ClaimIntakeFinishV2Controller(this.ClaimIntakeForm);
            this._finish.Notification = this.Notification;
            this._finish.events();
        }

        private event(): void {
            var _self = this;

            this.$container.on('click', '.staticCategoryContainer', function () {

                var categoryPhase = $(this).data('phase');

                $('.categoryContainer, .staticCategoryContainer').removeClass('activeCategory');
                $('.innerCategoryProgress').removeClass('activeSuccess activeWarning activeError');
                $(this).addClass('activeCategory');
                
                _self.bindCategory(categoryPhase);                

            });

            this.$container.on('click', '.categoryContainer', function () {

                $('.categoryContainer').removeClass('activeCategory');

                if (_self.ClaimIntakeForm._Status != Enums.ClaimIntakeForm_Status.NEW) {
                    var categoryIndex = $(this).data('index'), categoryPhase = $(this).data('phase');   
                    $('.categoryContainer').eq(categoryIndex).addClass('activeCategory');                 
                    _self.bindCategory(categoryPhase, categoryIndex);
                    //_self.getInformation();
                } else {
                    alert('Please fill in the required information and Start the Form');
                }

            });

            this.$container.on('click', '#jxStart', function () {
                if (!_self._start.HideLocation && ($('#jxLocation').find(":selected").text() === '' || $('#jxLocation').find(":selected").text() === undefined || $('#jxLocation').find(":selected").text() === null)) {
                    alert('Please select a location before continuing');
                }
                else if ($('#jxIncidentDate').val() === '' || $('#jxIncidentDate').val() === undefined || $('#jxIncidentDate').val() === null) {
                    alert('Please select a Date of Incident before continuing.');
                } else {
                    _self.setFormStatusAsEnum(Enums.ClaimIntakeForm_Status.OPEN);
                    _self.ClaimIntakeForm.save().done(() => {
                        _self.bindCategory(Enums.ClaimIntakeForm_Phase.START_DYNAMIC, 0);
                    });                
                }                
            });

            this.$container.on('click', '#jxNext', (e) => {  
                _self.validateFields().done(() => {
                    _self.saveCategoryFields(_self._categoryIndex);
                    _self.bindCategory($(e.target).data('phase'), _self._categoryIndex + 1);
                }).fail(() => {
                    this.Notification.getNotification(Enums.ClaimIntakeNotification_Request.FIELD_VALIDATION_ERROR);
                });                
            });
            this.$container.on('click', '#jxPrevious', function () {
                _self.validateFields().done(() => {
                    _self.saveCategoryFields(_self._categoryIndex);
                    _self.bindCategory($(this).data('phase'), _self._categoryIndex - 1);
                }).fail(() => {
                    this.Notification.getNotification(Enums.ClaimIntakeNotification_Request.FIELD_VALIDATION_ERROR);
                });              
            });
            this.$container.on('click', '#jxFinalize', function () {
                _self.validateFields().done(() => {
                    _self.saveCategoryFields(_self._categoryIndex);
                    _self.bindCategory(Enums.ClaimIntakeForm_Phase.FINISH);
                }).fail(() => {
                    this.Notification.getNotification(Enums.ClaimIntakeNotification_Request.FIELD_VALIDATION_ERROR);
                });                      
            });            
            this.$container.on('click', '#jxCancel, #jxClose', function () {
                closeThis(true);
            });

        }

        private bindCategory(categoryPhase: Enums.ClaimIntakeForm_Phase, categoryIndex?: number): void {

            $('#formInformationContainer').slideUp('fast');

            this._categoryIndex = categoryIndex;

            if (categoryIndex == -1) {
                categoryPhase = Enums.ClaimIntakeForm_Phase.START;
            }

            if (categoryIndex > this.ClaimIntakeForm.Categories.length) {
                categoryPhase = Enums.ClaimIntakeForm_Phase.FINISH;
            }

            switch (categoryPhase) {
                case Enums.ClaimIntakeForm_Phase.START:
                    this._start.init();
                    break;
                case Enums.ClaimIntakeForm_Phase.START_DYNAMIC:
                case Enums.ClaimIntakeForm_Phase.DYNAMIC:
                case Enums.ClaimIntakeForm_Phase.DYNAMIC_FINISH:                                    
                    this._dynamic.init(categoryIndex);
                    break;
                case Enums.ClaimIntakeForm_Phase.FINISH:
                    this._finish.init();
                    break;
            }

            this.navigationBar(<Enums.ClaimIntakeForm_Phase>categoryPhase);
            this.configureNavButtons();
            this.updateFormProgress();
            this.addInputMasks();
        }

        private addInputMasks(): void {
            $("[data-displayformat][data-displayformat!='']").each((i, e) => {
                $(e).mask($(e).attr("data-displayformat"));
            });
        }

        private navigationBar(categoryPhase: Enums.ClaimIntakeForm_Phase): void {                                   

            $('#jxStart, #jxPrevious, #jxNext, #jxFinalize, #jxFinish, #jxSave, #jxSubmitted').hide();

            if (this.ClaimIntakeForm._Status == Enums.ClaimIntakeForm_Status.SUBMITTED) {
                $('#jxSubmitted').show().attr('disabled', 'disabled');
            } else {
                switch (categoryPhase) {
                    case Enums.ClaimIntakeForm_Phase.START:
                        $('#jxStart').show();
                        break;
                    case Enums.ClaimIntakeForm_Phase.START_DYNAMIC:
                        $('#jxNext, #jxSave').show();
                        break;
                    case Enums.ClaimIntakeForm_Phase.DYNAMIC:
                        $('#jxNext, #jxPrevious, #jxSave').show();
                        break;
                    case Enums.ClaimIntakeForm_Phase.DYNAMIC_FINISH:
                        $('#jxPrevious, #jxFinalize, #jxSave').show();
                        break;
                    case Enums.ClaimIntakeForm_Phase.FINISH:
                        $('#jxFinish').show();
                        break;
                    case Enums.ClaimIntakeForm_Phase.UNKNOWN:
                        $('#jxFinalize, #jxSave').show();
                        break;
                    default:
                        $('#jxNext, #jxPrevious').show();
                }
            }
        }
        
        private configureNavButtons(): void {
            $('#jxNext').data('phase', $('.categoryContainer').eq(this._categoryIndex + 1).data('phase'));
            $('#jxPrevious').data('phase', $('.categoryContainer').eq(this._categoryIndex - 1).data('phase'));         
        }    

    }


}