module PL.ClaimSystem.ClaimManagement {
    export class NoteModel {
        public NoteID: number;
        public ParentNoteID: number;
        public ClaimID: number;
        public NoteDate: any;
        public NoteType: any;
        public NoteCategory: any;
        public Subject: string;
        public Note: string;
        public CreateUser: string;
        public CreateTmsp: any;
        public Source: string;
        public Status: string;
        public UpdateUser: string;
        public UpdateTmsp: any;

    }
}