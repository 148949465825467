CHSI.Page.SItemFormFields = {
    Init: function (scheduleItemID, scheduleTypeID) {        
        var Template_ScheduleItemFormFields = $('#Template_ScheduleItemFormFields').html();
        this.Templates.ScheduleItemFormFields = Handlebars.compile(Template_ScheduleItemFormFields);
        var Template_ScheduleItemFieldHistory = $('#Template_ScheduleItemFieldHistory').html();
        this.Templates.ScheduleItemFieldHistory = Handlebars.compile(Template_ScheduleItemFieldHistory);
        this.Cache.ScheduleItemID = scheduleItemID;
        this.Cache.ScheduleTypeID = scheduleTypeID;
        
        this.Calls.GetFields();
        this.Events.Global();
       
    },
    SaveAll: function (Status) {
        this.PersistFieldsFromHTML();

        //this.Calls.SaveFields(Status);
        this.Events.SaveFields();
    },
    SaveAllFields: function (Status)
    {
        this.PersistFieldsFromHTML();

        this.Calls.SaveFields(Status);
        $('.jxUndo').hide();

    }
     , PersistFieldsFromHTML: function () {
         for (var index = 0; index < CHSI.Page.SItemFormFields.Cache.FieldsObject.length; index++) {
             var Element = $('#jxField_' + index);
             var DataType = Element.data('datatype');
             var ElementVal = Element.val();
             ElementVal = CHSI.Page.SItemFormFields.GetValue(ElementVal, DataType);
             if (DataType == 3 || DataType == 5) {
                 ElementVal = isNaN(ElementVal) ? '' : ElementVal;
             }
             CHSI.Page.SItemFormFields.Cache.FieldsObject[index].ColumnValue = ElementVal;
             CHSI.Page.SItemFormFields.Cache.FieldsObject[index].Status = $('#jxField_' + index).data('newstatus');
         }
     }
    ,Templates: {

    },
    Cache: {
        Fields: null
       , SHistoryObject: null
        , FieldsObject: null
        , HistoryName: null
        , Active: false
        , validator: null

    },
    BindPresentationLayer: function () {
        $('#jxScheduleItemFormFieldsContainer').html(this.Templates.ScheduleItemFormFields({ Fields: this.Cache.Fields}));

        restart = true;
        sorttable.init();
       

        $('.Date').datepicker({
            showOn: "both",
            buttonImage: "/images/icons/cal.gif",
            buttonImageOnly: true
        });
    },
    BindHistory: function () {
        var HTML ='';
        HTML = CHSI.Page.SItemFormFields.Templates.ScheduleItemFieldHistory(CHSI.Page.SItemFormFields.Cache.SHistoryObject);
        ShowInlinePopup('FieldHistoryModal', HTML, 'History:' + CHSI.Page.SItemFormFields.Cache.HistoryName, 400, 400, null, true);
        restart = true;
        sorttable.init();
        
    },
    Events: {
        ClearStatusChangedFields: function () {
            $('.jxUndo').hide();
            CHSI.Page.SItemFormFields.Cache.Fields = CHSI.Page.SItemFormFields.Events.ProcessFields(CHSI.Page.SItemFormFields.Cache.FieldsObject);
            CHSI.Page.SItemFormFields.BindPresentationLayer();
        },

        ProcessFields: function (e) {
            var obj = {};
            if (e) {
                for (i = 0; i < e.length; i++) {
                    if (e[i]) {
                        if (!e[i].CategoryName) {
                            e[i].CategoryName = 'Other';
                        }
                        e[i].index = i;
                        if (typeof obj[e[i].CategoryName] == 'undefined') {
                            obj[e[i].CategoryName] = [];
                        }
                        e[i].ColumnValue = CHSI.Page.SItemFormFields.FormatString(e[i].ColumnValue, e[i].DisplayFormat, e[i].DataType);
                        if (e[i].Status) {
                            if (e[i].Status.substring(0, 7).toLowerCase() == 'pending') {
                                CHSI.Page.SItemFormFields.Cache.PendingChanges = true;
                            }
                        }
                        if (CHSI.Page.SItemFormFields.Cache.ScheduleItemID == undefined && CHSI.Page.SItemFormFields.Cache.isPortal) {
                            CHSI.Page.SItemFormFields.Cache.NewItem = true;
                        }

                        if (e[i].NumOfUpdates < 1 && CHSI.Page.SItemFormFields.Cache.ScheduleItemID && CHSI.Page.SItemFormItem.Cache.Status == 'Pending' && CHSI.Page.SItemFormItem.Cache.AddNew == false) {
                            CHSI.Page.SItemFormFields.Cache.NewItem = true;
                        }

                        obj[e[i].CategoryName].push(e[i]);
                    }
                }
            }

            return obj;
        },
        Global: function () {
            $('#jxScheduleItemFormFieldsContainer').on('change', 'select,input,textarea', function () {
                var ChangedField = $(this);
                //var ScheduleItemID = ChangedField.data('scheduleitemid');
                //var PreviousValue = CHSI.Page.SItemFormFields.Calls.GetHistory(ScheduleItemID);
                ChangedField.data('newstatus', 'changed');
                var index = ChangedField.data('index');
                $('#jxUndoField_' + index).show();
                $('#jxScheduleItemFormFieldsContainer').on('click', '#jxUndoField_' + index, function () {
                    ChangedField.val(ChangedField.data('originalvalue'));
                    ChangedField.data('newstatus', ChangedField.data('status'));
                    $('#jxUndoField_' + index).hide();
                });
                              
                //ChangedField.append('<span>Previous Value: ' + PreviousValue + '[<a href=# class="minilink" onclick="ApplyPrevious()">revert</a>]</span>');
            });

            $('#jxScheduleItemFormFieldsContainer').on('blur', '.Regular', function () {                
                var Element = $(this);
                var ElementVal = Element.val();
                var Format = Element.data('displayformat');
                var DataType= Element.data('datatype');
                Element.val(CHSI.Page.SItemFormFields.FormatString(ElementVal, Format, DataType));

                var validator = jQuery('form').validate({ onkeyup: false });
                validator.element(this);
               

            });

            $('#jxScheduleItemFormFieldsContainer').on('blur', '.Calculated', function () {
                
            });

            $('#jxScheduleItemFormFieldsContainer').on('blur', '.Date', function () {
                var Element = $(this);
                var FormatedDate = CHSI.Dates.GetShortDate(Element.val());
                Element.val(FormatedDate);
            });

            $('#jxScheduleItemFormFieldsContainer').on('blur', '.DropDown', function () {
                var validator = jQuery('form').validate({ onkeyup: false });
                validator.element(this);
            });

            $('#jxScheduleItemFormFieldsContainer').on('blur', '.TextBlock', function () {
                var validator = jQuery('form').validate({ onkeyup: false });
                validator.element(this);
            });

            $('#uxScheduleItemFieldsOK').on('click', function () {
                if (jQuery('form').valid()) {
                    CHSI.Page.SItemFormFields.SaveAllFields('active');
                    closeThis(true);
                }
            });
        },
        InsertRevertOption: function () {

        },
        ValidateFields: function () {

        }
 
    },
    ApplyPrevious: function (index, priorvalue, actualvalue) {
        $('#jxField_' + index).val(priorvalue);
        $('#jxRevertField_' + index).hide();
        $('#jxField_' + index).removeClass('HighlightGreen');
    },
    FormatString: function (SValue, Format, DataType)
    {
        var FormatedText = SValue;
        if (SValue && Format && DataType) {            
                var ElementVal = CHSI.Page.SItemFormFields.GetValue(SValue, DataType);
                FormatedText = String.__Format(Format, ElementVal);                            
        }
        return FormatedText;
    },
    GetValue: function (SValue, DataType)
    {
        var ElementVal = SValue;
        switch (DataType) {
            case 3: //Decimal
                ElementVal = parseFloat(ElementVal.replace(/[^0-9\.]/g, ''));
                break;
            case 5: //Integer
                ElementVal = parseInt(ElementVal.replace(/[^0-9]/g, ''));
                break;

        }

        return ElementVal;

    },
    Calls: {
        GetFields: function () {
            
            var AjaxURL = '/api/UW/ScheduleField/GetScheduleField?ScheduleTypeID=' + CHSI.Page.SItemFormFields.Cache.ScheduleTypeID;

            if (CHSI.Page.SItemFormFields.Cache.ScheduleItemID) {
                AjaxURL = '/api/UW/ScheduleItemField/Get?ScheduleItemID=' + CHSI.Page.SItemFormFields.Cache.ScheduleItemID;
            } else {
                if (CHSI.Page.SItemFormItem.Cache.SItemObject.ScheduleItemID) {
                    AjaxURL = '/api/UW/ScheduleItemField/Get?ScheduleItemID=' + CHSI.Page.SItemFormItem.Cache.SItemObject.ScheduleItemID;
                }
            }

            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                   
                    if (e) {
                        var obj = {};
                        for (i = 0; i < e.length; i++) {
                            if (!e[i].CategoryName) {
                                e[i].CategoryName = 'Other';
                            }
                            e[i].index = i;
                            if (typeof obj[e[i].CategoryName] == 'undefined') {
                                obj[e[i].CategoryName] = [];
                            }
                            e[i].ColumnValue = CHSI.Page.SItemFormFields.FormatString(e[i].ColumnValue, e[i].DisplayFormat, e[i].DataType);
                            obj[e[i].CategoryName].push(e[i]);
                        }

                    }
                    CHSI.Page.SItemFormFields.Cache.FieldsObject = e;
                    CHSI.Page.SItemFormFields.Cache.Fields = obj;
                    CHSI.Page.SItemFormFields.BindPresentationLayer();
                },
                error: function (e) {
                    CHSI.Common.Log('no data to return');
                }
            });
        },
        GetHistory: function (ScheduleItemID, ScheduleFieldID, Name) {
            var AjaxURL = '/api/UW/ScheduleItemFieldValue/GetHistory?ScheduleItemID=' + ScheduleItemID + '&ScheduleFieldID='+ScheduleFieldID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.SItemFormFields.Cache.HistoryName = Name;
                    CHSI.Page.SItemFormFields.Cache.SHistoryObject = { History:  e };
                    CHSI.Page.SItemFormFields.BindHistory();
                },
                error: function (e) {
                    CHSI.Common.Log('no data to return');
                }
            });
        },
        SaveFields: function (Status) {
            var AjaxURL = '/api/UW/ScheduleItemField/SaveScheduleFields';
            var PendingFields = [];
            if (!CHSI.Page.SItemFormFields.Cache.FieldsObject) {
                return false;
            }
            if (CHSI.Page.SItemFormFields.Cache.FieldsObject.length) {                
                for (i = 0; i < CHSI.Page.SItemFormFields.Cache.FieldsObject.length; i++) {
                    var FieldStatus= CHSI.Page.SItemFormFields.Cache.FieldsObject[i].Status.toLowerCase();
                    if (FieldStatus == 'pending' || FieldStatus=='changed')
                    {
                        if (Status != '') {
                            PendingFields.push(CHSI.Page.SItemFormFields.Cache.FieldsObject[i]);
                        } else {
                            if (FieldStatus == 'changed') {
                                CHSI.Page.SItemFormFields.Cache.FieldsObject[i].Status = 'pending';
                                PendingFields.push(CHSI.Page.SItemFormFields.Cache.FieldsObject[i]);
                            }
                        }
                    }
                }
            }


            var FieldsContainer = {};
            FieldsContainer.ItemFields = PendingFields;
            FieldsContainer.Status = Status;
            var ajaxString = JSON.stringify(FieldsContainer);
            $.ajax({
                type: "POST",
                url: AjaxURL,
                data: ajaxString,
                cache: true,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    //CHSI.Page.SItemFormFields.Calls.GetFields();
                    CHSI.Page.SItemFormFields.Events.ClearStatusChangedFields();
                },
                error: function (e) {
                    CHSI.Common.Log(e);
                }
            });
        }
    }

}

Handlebars.registerHelper('eachArray', function (context, options) {
    var ret = "";
    var index = -1;
    var size = 0;
    var isLast = false;
    if (Object.prototype.toString.apply(context) === '[object Array]') {
        size = context.length;
        for (var i = 0; i < context.length; i++) {
            index++;
            isLast = false;
            if (index >= size - 1) {
                isLast = true;
            }
            ret = ret + options.fn({ property: i, value: context[i], index: index, isLast: isLast, size: size });
        }
    } else {
        for (var prop in context) {
            size++;
        }
        for (var prop in context) {
            index++;
            isLast = false;
            if (index >= size - 1) {
                isLast = true;
            }
            ret = ret + options.fn({ property: prop, value: context[prop], index: index, isLast: isLast, size: size });
        }
    }

    return ret;
});