module PL.UW.LossHistory.Model {
    export class PriorCoverage {

        PriorCoverageID: number = (isNaN(+$('#master').data('priorcoverageid')) == true ? null : +$('#master').data('priorcoverageid'));
        ClientID: number = (isNaN(+$('#master').data('clientid')) == true ? null : +$('#master').data('clientid'));
        QuoteID: number = (isNaN(+$('#master').data('quoteid')) == true ? null : +$('#master').data('quoteid'));
        WorkSheetID: number = +$('#master').data('worksheetid');
        PolicyPeriod: number;
        EffectiveDate: string = null;
        ExpirationDate: string;
        ProductionDate: string;
        GroupID: string;
        Comments: string;
        ClientName: string;
        UseLosses: string;
        Policy: string;
        Premium: string;
        Mod: string;
        Claims: number;
        AmountPaid: string;
        Reserve: string;
        MasterPolicyPeriod: number;

        constructor() {

        }

        getValues(): PriorCoverage {
            var $coverage = new PL.UW.LossHistory.Views.xMod_LossHistoryEdit_PriorCoverage();
            var priorCoverage: PriorCoverage = new PriorCoverage;

            if (this.PriorCoverageID == null || this.PriorCoverageID == undefined) {
                priorCoverage.PriorCoverageID = 0;
            } else {
                priorCoverage.PriorCoverageID = this.PriorCoverageID;
            }
            priorCoverage.QuoteID = null;
            priorCoverage.PolicyPeriod = $coverage.$policyPeriod.val();
            priorCoverage.ProductionDate = $coverage.$lossRunDate.val();
            priorCoverage.EffectiveDate = $coverage.$fromDate.val();
            priorCoverage.ExpirationDate = $coverage.$toDate.val();
            priorCoverage.ClientName = $coverage.$carrier.val();
            priorCoverage.Premium = $coverage.$premium.val();
            priorCoverage.Mod = $coverage.$xMod.val();
            priorCoverage.Comments = $coverage.$overallComments.val();

            return priorCoverage;
        }

        static clear(): void {
            var $priorCoverage = new PL.UW.LossHistory.Views.xMod_LossHistoryEdit_PriorCoverage();
            $('#master').data('priorcoverageid', null);
            $priorCoverage.$carrier.val('');
            $priorCoverage.$fromDate.val('');
            $priorCoverage.$lossRunDate.val('');
            $priorCoverage.$overallComments.val('');
            $priorCoverage.$policyPeriod.val('');
            $priorCoverage.$premium.val('');
            $priorCoverage.$toDate.val('');
            $priorCoverage.$xMod.val('');
        }
    }
}