module PL.Common.ViewModels {
    export class ValidationAlert {
        
        private _fail = 'critical';
        private _warning = 'warning';
        private _pass = 'good';

        public ValidationQueryResults: Models.ValidationQueryResult[] = [];
        public Modal: GUI.Windows.Popup;

        constructor() { }

        private _hasValidationErrors = (): boolean => {
            for (var r of this.ValidationQueryResults) {
                if (r.ValidationStatus.toLowerCase() == this._fail)
                    return true;
            }
            return false;
        };

        private _hasValidationWarnings = (): boolean => {
            for (var r of this.ValidationQueryResults) {
                if (r.ValidationStatus.toLowerCase() == this._warning)
                    return true;
            }
            return false;
        };

        public Init = (name: string, id: string): JQueryDeferred<any> => {
            let df = $.Deferred();
            this.GetValidationQueryResults(name, id)
                .done(() => {
                    df.resolve();
                })
                .fail(() => {
                    df.reject();
                });               
            return df;
        }

        public CreateValidationSummaryDiv = (containerID: string) => {
            let div, icon, msg = '';
            let linkClass = 'alerts-link';
            let cursorCSS = 'cursor:pointer;';
            if (this._hasValidationErrors()) {
                icon = 'statusoutofoffice';
                msg = 'Errors detected, click to view';
            } else if (this._hasValidationWarnings()) {
                icon = 'statusvacation';
                msg = 'Warnings detected, click to view';
            } else {
                icon = 'statusintheoffice';
                msg = 'No errors or warnings detected.';    
                linkClass = cursorCSS = '';
            }
            div = '\
<div style="width:17px;height:100%;display:inline-block;">\
    <a class="statusicon ' + icon + ' linkClass" style="top:30%;position:relative;' + cursorCSS +'"></a>\
</div>\
<div style="width:120px;height:100%;display:inline-block;vertical-align:top;">\
    <a class="' + linkClass + '" style="top: 30%;position: relative;' + cursorCSS + '">' + msg + '</a>\
</div>';

//            div = `
//<div style="width:17px;height:100%;display:inline-block;">
//    <a class="statusicon ${icon} ${linkClass}" style="top:30%;position:relative;${cursorCSS}"></a>
//</div>
//<div style="width:120px;height:100%;display:inline-block;vertical-align:top;">
//    <a class="${linkClass}" style="top: 30%;position: relative;${cursorCSS}">${msg}</a>
//</div>`;
            $('#' + containerID).html(div);
            $('.alerts-link').on('click', () => {
                this.CreateValidationAlertModal();
            });
        };

        public CreateValidationAlertModal = (): void => {
            //replace validationdetail.aspx?
            this.Modal = new GUI.Windows.Popup();
            this.Modal.WindowID = 'validation-alert';
            this.Modal.Title = 'Validation Alerts';
            this.Modal.Width = 500;
            this.Modal.Height = 300;
            this.Modal.HTMLContent = CHSITemplates.Common.ValidationAlertsModal({ results: this.ValidationQueryResults });
            this.Modal.showInline();

            $('.validation-cancel').on('click', () => {
                GUI.Windows.Popup.closeThisInline('#modal_validation-alert');
            });
        };

        public CreatePrebindValidationModal = (): void => {
            var hasWarnings = false;
            var hasErrors = false;
            for (var r of this.ValidationQueryResults) {
                if (r.ValidationStatus.toLowerCase() == this._warning) hasWarnings = true;
                else if (r.ValidationStatus.toLowerCase() == this._fail) hasErrors = true;
            }
            this.Modal = new GUI.Windows.Popup();
            this.Modal.WindowID = 'validation-alert';
            this.Modal.Title = 'Validation Alerts';
            this.Modal.Width = 500;
            this.Modal.Height = 300;
            this.Modal.HTMLContent = CHSITemplates.Common.QuotePrebindModal({ hasWarnings: hasWarnings, hasErrors: hasErrors, results: this.ValidationQueryResults });
            this.Modal.showInline();

            $('.validation-cancel').on('click', () => {
                GUI.Windows.Popup.closeThisInline('#modal_validation-alert');
            });

            if (!this._hasValidationErrors()) {                
                $('.validation-bind').on('click', () => {
                    GUI.Windows.Popup.closeThisInline('#modal_validation-alert');
                    $('.quote-bind')[0].click();
                });
            }
        };

        public GetValidationQueryResults = (name: string, id: string): JQueryDeferred<any> => {
            let df = $.Deferred();
            $.ajax({
                type: "POST",
                url: "/api/Shared/ValidationQuery/GetValidationQueryResults?validationObjectName=" + name +"&pkid=" + id,
                success: (results: Array<Models.ValidationQueryResult>) => {
                    this.ValidationQueryResults = this.ValidationQueryResults.concat(results);
                    df.resolve();
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    WebUtilities.genericAJAXErrorHandler(jqXHR, textStatus, errorThrown);
                    df.reject();
                }
            });
            return df;
        };

        public ValidationPassed = (): boolean => {
            //for (var r of this.ValidationQueryResults) {
            //    if (r.ValidationStatus.toLowerCase() != this._pass)
            //        return false;
            //}
            //return true;
            return !this._hasValidationErrors(); // only show popup if critical errors for now
        };
    }
}