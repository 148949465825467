CHSI.Claims.Notification = {
    Init: function (QSObject) {
        if (typeof (QSObject.NotificationID) == "undefined") {
            this.Cache.NotificationID = null;
        } else {
            this.Cache.NotificationID = QSObject.NotificationID;
        }
        var Template_NotificationEditor = $('#Template_NotificationEditor').html();
        this.Templates.NotificationEditor = Handlebars.compile(Template_NotificationEditor);
        if (this.Cache.NotificationID) {
            this.Calls.GetNotification(this.Binds.Notification);
        } else {
            this.Binds.Notification();
        }
    },
    Cache: {
        From: 'webmaster@connections-ms.com'
        , NotificationID: null
        , DistributionListObject: null
        , NotificationObject: {
            NotificationID: null
                        , NotificationName: ''
                        , NotificationType: 1
                        , Status: 'active'
                        , From: ''
                        , Subject: ''
                        , IncludeReport: false
                        , BodyTemplate: ''
                        , DestinationType: 1
                        , Destination: ''
                        , CCDestinationType: 1
                        , CCDestination: ''
                        , BCCDestinationType: 1
                        , BCCDestination: ''
                        , GroupName: ''
                        , TemplateID: null
        }
        , EmailDistributionListObject: null
        , ActiveGroups: null
        , GroupSpec: null
        , GroupSpecs: null
        , GroupName: null
        , GroupSpecCode: null
        , DestinationType: 1
        , EmailTemplates: []
    },
    Templates: {

    },
    Binds: {
        Notification: function () {
            $('#jxNotificationEditor').html(CHSI.Claims.Notification.Templates.NotificationEditor({ Notification: CHSI.Claims.Notification.Cache.NotificationObject }));
            CKEDITOR.replace('uxMessageBody', { height: "240" });
            CHSI.Claims.Notification.Calls.GetDistributionList(function () {
                CHSI.Claims.Notification.Calls.GetGroups(function () {
                    CHSI.Claims.Notification.Calls.GetGroupSepcFromGroupSpecType(function () {
                        CHSI.Claims.Notification.Calls.GetQueueEmailTemplates(function (){
                            CHSI.Claims.Notification.Binds.DistributionList();
                            CHSI.Claims.Notification.Binds.EmailTemplates();
                            CHSI.Claims.Notification.Events.GetDestinationType(CHSI.Claims.Notification.Cache.NotificationObject.DestinationType);
                            CHSI.Claims.Notification.Events.ConfigureDestinationTypeFilters(CHSI.Claims.Notification.Cache.NotificationObject.DestinationType, CHSI.Claims.Notification.Cache.NotificationObject.Destination);
                            CHSI.Claims.Notification.Events.Global();
                        });                        
                    });
                    
                });                
            });                        
        },

        DistributionList: function () {
            var DistributionListObject = CHSI.Claims.Notification.Cache.DistributionListObject;
            $('#uxDistributionList option[value!="0"]').remove();
            for (var i = 0; i < DistributionListObject.length; i++) {
                if (DistributionListObject[i].AllowEmail) {
                    $('#uxDistributionList').append($("<option></option>").attr("data-listtypeid", DistributionListObject[i].ListTypeID).attr("data-listsource", DistributionListObject[i].ListSource).attr("value", DistributionListObject[i].ListName).attr("data-listid", DistributionListObject[i].ListID).text(DistributionListObject[i].ListName));
                }
            }
            
        },
        GroupSpec: function () {
            if (CHSI.Claims.Notification.Cache.DestinationType == 3) {
                $('#uxEmailAddressesTo').val('');
                $('#uxEmailAddressesTo').val(CHSI.Claims.Notification.Cache.GroupSpec);
            }
        },
        GroupSpecs: function () {
            $('#uxGroups option[value!="0"]').remove();
            var GroupSpecs = CHSI.Claims.Notification.Cache.GroupSpecs;
            for (var i = 0; i < GroupSpecs.length; i++) {
                if (GroupSpecs[i]) {
                    $('#uxGroupSpecsList').append($("<option></option>").attr("value", GroupSpecs[i].SpecificationName).attr('title', GroupSpecs[i].Description).text(GroupSpecs[i].SpecificationName));
                }
            }
        },
        EmailTemplates: function () {
            $('#uxTemplateSelect option[value!="0"]').remove();            
                $('#uxTemplateSelect').append($("<option></option>").attr("value", '').text('None'));
                var EmailTemplates = CHSI.Claims.Notification.Cache.EmailTemplates;
                for (var i = 0; i < EmailTemplates.length; i++) {
                    if (EmailTemplates[i]) {
                        if (CHSI.Claims.Notification.Cache.NotificationObject.TemplateID == EmailTemplates[i].TemplateID) {
                            $('#uxTemplateSelect').append($("<option></option>").attr("value", EmailTemplates[i].TemplateID).attr('title', EmailTemplates[i].TemplateName).attr('selected', 'selected').text(EmailTemplates[i].TemplateName));
                        } else {
                            $('#uxTemplateSelect').append($("<option></option>").attr("value", EmailTemplates[i].TemplateID).attr('title', EmailTemplates[i].TemplateName).text(EmailTemplates[i].TemplateName));

                        }
                        
                    }
                }
        }        
    },
    Events: {
        Global: function () {
            $('#uxDestinationType').on('change', function () {
                var DestinationType = +$(this).val();
                CHSI.Claims.Notification.Events.GetDestinationType(DestinationType);
            }); 

            $('#uxSave').on('click', function () {
                CHSI.Claims.Notification.Calls.SaveNotification(function () { closeThis(true); });
            });
        },
        ConfigureDestinationTypeFilters: function (DestinationType, DestinationValue) {
            var object = [];
            $('#uxDestinationType').val(DestinationType);
            switch (DestinationType) {
                case 1:
                    $("#uxEmailAddressesTo").val(DestinationValue);
                    break;
                case 2:
                    var values = DestinationValue.split('|');
                    $('#uxDistributionList').val(values[1]);
                    break;
                case 3:
                    $('#uxGroupSpecsList').val(DestinationValue);
                    break;
            }
            return object;
        },
        GetDestinationType: function (DestinationType) {
            $('.Optional').hide();
            switch (DestinationType) {
                case 1:
                    $("#uxEmailAddressesTo").attr('disabled', false);
                    break;
                case 2:
                    $('#uxDistributionList').show();
                    //CHSI.Claims.Notification.Binds.EmailDistributionList();
                    $("#uxEmailAddressesTo").attr('disabled', true);
                    break;
                case 3:
                    $('.GroupSpec').show();
                    CHSI.Claims.Notification.Binds.GroupSpecs();
                    $("#uxEmailAddressesTo").attr('disabled', true);
                    break;
            }
        },
        PersistDistributionListToCache: function () {
            var Data = {
                ListID: null,
                Group: true,
                ContactType: true,
                Committee: true,
                Custom: true,
                Query: true,
                ConstantContact: true,
                SystemUser: null,
                Status: false
            };

            return Data;
        },
        PersistNotificationToCache: function () {
            var Data = CHSI.Claims.Notification.Cache.NotificationObject;
            Data.NotificationName = $('#uxNotificationName').val();
            Data.NotificationType = $('#uxNotificationType').val();            
            Data.Status = $('#uxStatus').val();
            Data.DestinationType = $('#uxDestinationType').val();
            Data.From = $('#uxEmailAddressesFrom').val();
            switch (+Data.DestinationType) {
                case 1:
                    Data.Destination = $('#uxEmailAddressesTo').val();
                    break;
                case 2:
                    Data.Destination = $('#uxDistributionList').find('option:selected').data('listtypeid') + '|' + $('#uxDistributionList').find('option:selected').data('listsource') + '|' + ($('#uxDistributionList').find('option:selected').data('listid') == undefined ? null : $('#uxDistributionList').find('option:selected').data('listid'));
                    break;
                case 3:
                    Data.Destination = $("#uxGroupSpecsList").val();
                    break;
            }            
            Data.Subject = $('#uxSubject').val();
            Data.BodyTemplate = CKEDITOR.instances.uxMessageBody.getData();
            Data.IncludeReport = $('#uxIncludeReport').is(':checked');
            Data.TemplateID = $('#uxTemplateSelect').val() == '' ? null : $('#uxTemplateSelect').val();
            return Data;
        },
        SelectDropDownListValue: function ($listID, cachedValue) {
            $($listID).val(cachedValue);
        }
    },
    Calls: {
        GetNotification: function (successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/Form/GetNotification?NotificationID=' + CHSI.Claims.Notification.Cache.NotificationID;
            Options.Success = function (data) {
                CHSI.Claims.Notification.Cache.NotificationObject = data;
                if (successFunction) {
                    successFunction(data);
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        SaveNotification: function (successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/Form/SaveNotification';
            Options.Data = CHSI.Claims.Notification.Events.PersistNotificationToCache();
            Options.Success = function (data) {
                CHSI.Claims.Notification.Cache.NotificationID = data;
                if (successFunction) {
                    successFunction(data);
                }
            };

            CHSI.Common.Ajax.Post(Options);
        },
        GetDistributionList: function (successFunction) {
            var Options = {};

            Options.Data = CHSI.Claims.Notification.Events.PersistDistributionListToCache();
            Options.URL = '/api/CRM/DistributionList/GetFilteredList';
            Options.Success = function (data) {
                CHSI.Claims.Notification.Cache.DistributionListObject = data;
                if (successFunction) {
                    successFunction(data);
                }
            };
            CHSI.Common.Ajax.Post(Options);
        },

        //GetEmailDistributionList: function (successFunction) {
        //    var Options = {};

        //    Options.Data = CHSI.Claims.Notification.Events.PersistEmailDistributionListToCache();
        //    Options.URL = '/api/CRM/DistributionList/PostEmailList';
        //    Options.Success = function (data) {
        //        CHSI.Claims.Notification.Cache.EmailDistributionListObject = data;
        //        if (successFunction) {
        //            successFunction(data);
        //        }
        //    };
        //    CHSI.Common.Ajax.Post(Options);
        //},
        GetGroupSpec: function (GroupID, SpecName, successFunction) {
            var Options = {};
            Options.URL = '/api/Common/GroupSpecification/GetGroupSpecification?GroupID=' + GroupID + '&SpecName=' + SpecName;
            Options.Success = function (data) {
                CHSI.Claims.Notification.Cache.GroupSpec = data;
                if (successFunction) {
                    successFunction(data);
                }
            };
            Options.Error = CHSI.Common.Log('Please check the groupid or specname.');
            CHSI.Common.Ajax.Get(Options);
        },
        GetGroupSepcFromGroupSpecType: function (successFunction) {
            var Options = {};
            Options.URL = '/api/Common/GroupSpecification/GetGroupSepcFromGroupSpecType?SpecID=null&FormatType=null&CategoryType=4';
            Options.Success = function (data) {
                CHSI.Claims.Notification.Cache.GroupSpecs = data;
                if (successFunction) {
                    successFunction(data);
                }
            };
            Options.Error = CHSI.Common.Log('Please check the groupid or specname.');
            CHSI.Common.Ajax.Get(Options);
        },
        GetGroups: function (successFunction) {
            var Options = {};
            Options.URL = '/api/CRM/Group/GetActiveGroups';
            Options.Data = null;
            Options.Success = function (data) {
                CHSI.Claims.Notification.Cache.ActiveGroups = data;

                if (successFunction) {
                    successFunction(data);
                }
            }
            CHSI.Common.Ajax.Get(Options);
        },
        GetQueueEmailTemplates: function (successFunction) {
            var Options = {};
            Options.URL = '/api/Common/Email/GetEmailTemplates?emailTemplateID=null';
            Options.Data = null;
            Options.Success = function (data) {
                CHSI.Claims.Notification.Cache.EmailTemplates = data;

                if (successFunction) {
                    successFunction(data);
                }
            }
            CHSI.Common.Ajax.Get(Options);        
        }

    }
}