module PL.CRM.Models {

    export class ClientAddress {

        AddressID: number;
        ClientID: number;
        AddressType: string;
        Status: string;
        Address1: string;
        Address2: string;
        City: string;
        State: string;
        Zip: string;
        Zip4: number;
        County: string;
        CreateUser: string;
        CreateTmsp: string|Date;
        UpdateUser: string;
        UpdateTmsp: string | Date;

        constructor() {
            this.AddressID = null;
            this.ClientID = null;
            this.AddressType = null;
            this.Status = null;
            this.Address1 = null;
            this.Address2 = null;
            this.City = null;
            this.State = null;
            this.Zip = null;
            this.Zip4 = null;
            this.County = null;
            this.CreateUser = null;
            this.CreateTmsp = null;
            this.UpdateUser = null;
            this.UpdateUser = null;
        }

        get(): JQueryDeferred<ClientAddress> {

            var result = $.Deferred();

            PL.CRM.APIs.ClientAddress.getClientAddressByClientID(this.ClientID).done((data) => {

                result.resolve(data);

            });

            return result;

        }

    }

}