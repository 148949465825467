CHSI.CM.FileUpload = {
    Init: function (ClientID, FileID, SectionID, FileName, AttachmentID) {
       
        if (AttachmentID == 'undefined') {
            AttachmentID = null;
        }

        this.Cache.AttachmentID = AttachmentID;
        this.Events.Global();
        if (ClientID) {
            if (SectionID) {
                this.Cache.SectionID = SectionID;
            }
            this.Cache.ClientID = ClientID;

            if (FileID) {
                this.Cache.FileIDs = FileID.split(',');
                if (FileName) {
                    this.Cache.FileNames = FileName.split(',');
                }

                for (var i = 0; i < this.Cache.FileIDs.length; i++) {
                    var fileInfo = {
                        AttachmentID: null,
                        FileID: this.Cache.FileIDs[i],
                        FileName: this.Cache.FileNames[i],
                    };
                    CHSI.CM.FileUpload.Cache.ArrFileAttachment.push(fileInfo);

                }

                this.Events.BindFileNames();
                if (AttachmentID) {
                    this.Cache.NewFile = false;
                } else {
                    this.Cache.NewFile = true;
                }
                this.Cache.FileID = FileID;
                this.Cache.FileName = FileName;
                //$('#jxViewFile').show();


            } else {
                this.Cache.NewFile = true;
            }

            CHSI.CM.FileUpload.Calls.GetClientInfo(ClientID, function () {
                CHSI.CM.FileUpload.Calls.GetPolicyYears(ClientID, function (data) {

                    CHSI.CM.FileUpload.Cache.PolicyYears = [];
                    CHSI.CM.FileUpload.Cache.PolicyYears = data;

                    CHSI.CM.FileUpload.Calls.GetFileSections(ClientID, function () {
                        if (CHSI.CM.FileUpload.Cache.AttachmentID) {
                            CHSI.CM.FileUpload.Calls.GetAttachment(CHSI.CM.FileUpload.Cache.AttachmentID, function () {
                                CHSI.CM.FileUpload.BindPresentationLayer();
                            });
                        } else {
                            CHSI.CM.FileUpload.BindPresentationLayer();
                        }
                    });
                });                
            });

            
            this.Calls.CreateUploader();
        }
    },
    Template: {

    },
    Cache: {
        FileAttachmentObject: {

        }
        , NewFile: false
        , PolicyYears: []
        , ClientID: null
        , FileID: null
        , FileIDs: []
        , FileNames:[]
        , SectionID: null
        , ClientInfo: null
        , GroupSpec: null
        , Attachment: {}
        , AttachmentPost: null
        , FileAttachment: null
        , ArrFileAttachment: []
        , ArrAttachmentPost:[]
        , PolicyYear: null
        , Sections: null
        , AddFile: false
        , Description:''
    },
    BindPresentationLayer: function () {

        if (CHSI.CM.FileUpload.Cache.PolicyYears) {
            var select = $('#jxPolicyPeriod');
            $('option', select).remove();
            $('#jxPolicyPeriod').append($('<option>', {
                value: '',
                text: ''
            }));

            for (i = 0; i < CHSI.CM.FileUpload.Cache.PolicyYears.length; i++) {
                $('#jxPolicyPeriod').append($('<option>', {
                    value: CHSI.CM.FileUpload.Cache.PolicyYears[i],
                    text: CHSI.CM.FileUpload.Cache.PolicyYears[i]
                }));
            }
        }

        $('.jxSectionChk').prop('checked', false);
        if (CHSI.CM.FileUpload.Cache.SectionID) {
            $('.jxSectionChk[data-sectionid=' + CHSI.CM.FileUpload.Cache.SectionID + ']').prop('checked', true);
        }
        
        //$('#jxDescription').val(CHSI.CM.FileUpload.Cache.Description);
        $('#jxDate').val(CHSI.Dates.GetShortDate(new Date()));

        if (CHSI.CM.FileUpload.Cache.Attachment && CHSI.CM.FileUpload.Cache.Attachment.AttachmentID) {
            CHSI.CM.FileUpload.Cache.Attachment.ItemDate = CHSI.CM.FileUpload.Cache.Attachment.ItemDate == null ? CHSI.Dates.GetShortDate(new Date()) : CHSI.CM.FileUpload.Cache.Attachment.ItemDate;
            $('#jxDescription0').val(CHSI.CM.FileUpload.Cache.Attachment.Description);
            $('#jxDate').val(CHSI.Dates.GetShortDate(CHSI.CM.FileUpload.Cache.Attachment.ItemDate));
           

            for (i = 0; i < CHSI.CM.FileUpload.Cache.Attachment.AttachmentCategories.length; i++) {
                var section = CHSI.CM.FileUpload.Cache.Attachment.AttachmentCategories[i];
                $('.jxSectionChk[data-sectionid=' + section.ID + ']').prop('checked', true);

            }

            if (CHSI.CM.FileUpload.Cache.Attachment.FileID) {
                $('#jxViewFile').show();
                $('#jxViewFile').attr('href', '../chsifilehandler.axd?fileid=' + CHSI.CM.FileUpload.Cache.Attachment.FileID);
            }

            var select = $('#jxPolicyPeriod');
            select.val(CHSI.CM.FileUpload.Cache.Attachment.PolicyPeriod);

        }

        CHSI.CM.FileUpload.Events.BindFileNames(function () {            
            $('#FileUploadContainer').show();
        });        

    },
    Events: {
        Global: function () {

            $('#jxFilterResultsContainer').on('click', '.jxSectionChk', function () {
                var section = $(this);
            });

            $('#jxSave').on('click', function () {
                CHSI.CM.FileUpload.Events.GetFieldsFromPresentationLayer();
                if (CHSI.CM.FileUpload.Cache.AttachmentPost.SectionIDs.length == 0) {
                    alert('Please select at least one classification');
                }
                else {
                    if (CHSI.CM.FileUpload.Cache.ArrFileAttachment.length) {
                        CHSI.CM.FileUpload.Calls.SaveAttachmentFromPost();
                        parent.CHSI.Common.Attachments.Cache.SectionID = CHSI.CM.FileUpload.Cache.AttachmentPost.SectionIDs[0];
                        closeThis(true);
                    } else {
                        alert('Please add at least one file to the attachment');
                    }
                   
                }
                
            });

            $('#jxCancel').on('click', function () {
                closeThis(false);
            });

        },

        BindFileNames: function (successFunction) {
            
            var CommaSeparator = '';               
            var HTML = [];
            var FileNameList = '';
            $('#jxFileName').html('');
            HTML.push('<table>');
            for (var i = 0; i < CHSI.CM.FileUpload.Cache.ArrFileAttachment.length; i++) {
                    var Description = CHSI.CM.FileUpload.Cache.ArrFileAttachment[i].Description;
                    if (!CHSI.CM.FileUpload.Cache.ArrFileAttachment[i].Description) {
                        Description = CHSI.CM.FileUpload.Cache.ArrFileAttachment[i].FileName.replace(/.[^.]+$/, '');
                    }                   
                    FileNameList = FileNameList + CommaSeparator + CHSI.CM.FileUpload.Cache.ArrFileAttachment[i].FileName;
                    $('#jxFileName').html(FileNameList);
                    HTML.push('<tr><td>Description for ' + CHSI.CM.FileUpload.Cache.ArrFileAttachment[i].FileName + '</td>');
                    HTML.push('<td colspan="4"><input type="text" id="jxDescription' + i + '"  style="width:85%;" value="' + Description + '"/>&nbsp;<a href="#" onclick="CHSI.CM.FileUpload.Events.DeleteFileName(' + i + ');"><img src="/Images/Icons/smRedX.gif"/></a></td></tr>');
                    CommaSeparator = ', ';
             }
             HTML.push('</table>');
            $('#jxDescription').html(HTML.join(''));
            
            if (successFunction) {
                successFunction();
            }

        },

        DeleteFileName:function(Index){
            CHSI.CM.FileUpload.Cache.ArrFileAttachment.splice(Index, 1);
            CHSI.CM.FileUpload.Events.BindFileNames();
        },

        GetPolicyYears: function (InitYear) {
            CHSI.CM.FileUpload.Cache.PolicyYears = [];
            var currentTime = new Date();
            var TodayYear = currentTime.getFullYear();
            for (i = TodayYear; i >= InitYear; i--) {
                CHSI.CM.FileUpload.Cache.PolicyYears.push(i);
            }
            CHSI.CM.FileUpload.BindPresentationLayer();
        },

        GetFieldsFromPresentationLayer: function () {
            CHSI.CM.FileUpload.Cache.ArrAttachmentPost = [];
            CHSI.CM.FileUpload.Cache.AttachmentPost = CHSI.CM.FileUpload.Cache.Attachment;
            CHSI.CM.FileUpload.Cache.AttachmentPost.SectionIDs = [];
            CHSI.CM.FileUpload.Cache.AttachmentPost.ClientID = CHSI.CM.FileUpload.Cache.ClientID * 1;
            CHSI.CM.FileUpload.Cache.AttachmentPost.Status = 'Active';           
            CHSI.CM.FileUpload.Cache.AttachmentPost.ItemDate = $('#jxDate').val();
            CHSI.CM.FileUpload.Cache.AttachmentPost.PolicyPeriod = $('#jxPolicyPeriod').val();


            $('.jxSectionChk').each(function (Index, Value) {
                if ($(this).is(':checked')) {
                    CHSI.CM.FileUpload.Cache.AttachmentPost.SectionIDs.push($(this).data('sectionid'));
                }
            });

            for (var i = 0; i < CHSI.CM.FileUpload.Cache.ArrFileAttachment.length; i++)
            {
                var AttachmentPostClone = jQuery.extend(true, {}, CHSI.CM.FileUpload.Cache.AttachmentPost);
                AttachmentPostClone.Description = $('#jxDescription'+i).val();
                AttachmentPostClone.AttachmentID = CHSI.CM.FileUpload.Cache.ArrFileAttachment[i].AttachmentID;
                AttachmentPostClone.FileName = CHSI.CM.FileUpload.Cache.ArrFileAttachment[i].FileName;
                AttachmentPostClone.FileID = CHSI.CM.FileUpload.Cache.ArrFileAttachment[i].FileID;
                CHSI.CM.FileUpload.Cache.ArrAttachmentPost.push(AttachmentPostClone);
            }           
        }

    },
    Calls: {

        GetClientInfo: function (ClientID, successFunction) {
            var AjaxURL = '/api/CRM/Clients/GetClient?ClientID=' + ClientID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.CM.FileUpload.Cache.ClientInfo = e;
                    if (e) {
                        CHSI.CM.FileUpload.Cache.GroupSpec = CHSI.CM.FileUpload.Calls.GetGroupSpec(e.GroupID, 'UseCurrentYearAsPolicyPeriod');
                        CHSI.CM.FileUpload.Cache.PolicyYear = CHSI.Dates.GetYear(e.StartDate) * 1;                        
                            
                        if (CHSI.CM.FileUpload.Cache.AttachmentID == null || CHSI.CM.FileUpload.Cache.AttachmentID == "undefined") {
                            CHSI.CM.FileUpload.Cache.Attachment = { AttachmentCategories: [], AttachmentID: null };                              
                        }
                    }

                    if (successFunction) {
                        successFunction();
                    }

                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },

        GetPolicyYears: function (ClientID, successFunction) {
            CHSI.CRM.GetAvailablePolicyYearByClientID(ClientID, successFunction);
        },

        GetGroupSpec: function (GroupID, SpecName) {
            var AjaxURL = '/api/Common/GroupSpecification/GetGroupSpecification?GroupID=' + GroupID + '&SpecName=' + SpecName;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {

                    if (e) {
                        if (e) {
                            CHSI.CM.FileUpload.Cache.GroupSpec = true;
                        }
                    }

                }, error: function () {
                    CHSI.Common.Log('Please check the groupid or specname.');
                }
            });
        },

        GetAttachment: function (AttachmentID, successFunction) {
            var AjaxURL = '/api/Common/Attachment/GetAttachmentDetailsByAttachmentID?AttachmentID=' + AttachmentID;

            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {

                    CHSI.CM.FileUpload.Cache.Attachment = e;
                    var fileInfo = {
                        AttachmentID: CHSI.CM.FileUpload.Cache.Attachment.AttachmentID,
                        FileID: CHSI.CM.FileUpload.Cache.Attachment.FileID,
                        FileName: CHSI.CM.FileUpload.Cache.Attachment.FileName,
                        Description: CHSI.CM.FileUpload.Cache.Attachment.Description
                    };

                    $('#jxFileName').html(CHSI.CM.FileUpload.Cache.Attachment.FileName);
                    CHSI.CM.FileUpload.Cache.ArrFileAttachment.push(fileInfo);
                    //CHSI.CM.FileUpload.BindPresentationLayer();

                    if (successFunction) {
                        successFunction();
                    }

                }, error: function () {
                    CHSI.Common.Log('Please check the groupid or specname.');
                }
            });
        },

        GetFileAttachment: function (FileID) {
            var AjaxURL = '/api/Common/FileAttachment/Get?FileID=' + FileID;

            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {

                    CHSI.CM.FileUpload.Cache.FileAttachment = e;


                }, error: function () {
                    CHSI.Common.Log('Please check the groupid or specname.');
                }
            });
        },

        GetFileSections: function (ClientID, successFunction) {
            var AjaxURL = '/api/Common/Attachment/GetFileSections?ClientID=' + ClientID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.CM.FileUpload.Cache.Sections = e;
                    CHSI.CM.AttachmentTree.Events.GenerateAttachmentTreeview(CHSI.CM.FileUpload.Cache.Sections, false, true, true);

                    if (successFunction) {
                        successFunction();
                    }
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },

        SaveAttachmentFromPost: function () {
            var AjaxURL = '/api/Common/Attachment/SaveAttachmentFromPost';
            var ajaxString = JSON.stringify(CHSI.CM.FileUpload.Cache.ArrAttachmentPost);
            $.ajax({
                type: "POST",
                url: AjaxURL,
                data: ajaxString,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },


        CreateUploader: function () {
            /* Uploader Script */
            $('#jxFileUpload').fineUploader({
                element: document.getElementById('jxFileUpload'),
                request: { endpoint: '/CHSIUploadHandler.axd' },
                debug: true,
                multiple: true,                
                callbacks: {
                        onSubmit: function (id, fileName) {
                            // $('.qq-upload-list').empty();
                            //jQuery('.qq-upload-list').hide();
                        },
                        onComplete: function (id, fileName, response) {

                            if (response.success == true) {

                                CHSI.CM.FileUpload.Cache.AddFile = true;
                                jQuery('.qq-upload-list').hide();

                                var fileInfo = {
                                    AttachmentID: null,
                                    FileID: response.FileID,
                                    FileName: fileName,
                                };

                                CHSI.CM.FileUpload.Cache.FileName = fileName;
                                CHSI.CM.FileUpload.Cache.FileID = response.FileID;
                                CHSI.CM.FileUpload.Cache.FileAttachment = fileInfo;
                                CHSI.CM.FileUpload.Cache.ArrFileAttachment.push(fileInfo);


                            }
                        },
                        onAllComplete: function (successful, failed) {
                            CHSI.CM.FileUpload.Events.BindFileNames();
                    }
                }
            });           
        }
    }
}