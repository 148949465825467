module PL.CRM.Models {

    export class ExcessPolicy {

        constructor() {}

        ExcessID: number;
        Company: string;
        ExcessProvider: string;
        PolicyNumber: string;
        EffectiveDate: string;
        ExpirationDate: string;
        SIR: number;
        MaxLiability: number;
        CededPct: number;
        PremiumSource: string;
    }
} 