
CHSI.Forms.APIs = new Object();
CHSI.Forms.APIs.version = 2;
CHSI.Forms.APIs.Repositories = [];
CHSI.Forms.APIs.QuoteDetailsAPI = function () {
    return CHSI.Forms.APIs.Repositories[CHSI.Forms.APIs.version].QuoteDetailsAPI;
}
CHSI.Forms.APIs.ChangeResultsAPI = function () {
    return CHSI.Forms.APIs.Repositories[CHSI.Forms.APIs.version].ChangeResultsAPI;
}
CHSI.Forms.APIs.SaveSectionAPI = function () {
    return CHSI.Forms.APIs.Repositories[CHSI.Forms.APIs.version].SaveSectionAPI;
}
CHSI.Forms.APIs.SubmitQuestionnaireAPI = function () {
    return CHSI.Forms.APIs.Repositories[CHSI.Forms.APIs.version].submitQuestionnaireAPI;
}
CHSI.Forms.APIs.Repositories[1] = {
    QuoteDetailsAPI: '/api/Que/Result/QuoteDetails',
    ChangeResultsAPI: '/api/Que/Result/GetChangeResults',
    SaveSectionAPI: '/api/Que/Form/SaveSection',
    submitQuestionnaireAPI: "/webservice/formfunctions.asmx/SubmitSavedValues"
}
CHSI.Forms.APIs.Repositories[2] = {
    QuoteDetailsAPI: '/api/Que/FormInstance/GetFormInstanceByIndex',
    ChangeResultsAPI: '/api/Que/FormInstance/GetChangeResults',
    SaveSectionAPI: '/api/Que/FormInstance/SaveSection',
    submitQuestionnaireAPI: '/api/Que/FormInstance/SubmitQuestionnaire'
}

