
module PL.AdminTools.Controllers {

    export class ClaimIntakeEditCategories extends PL.AdminTools.Controllers.Base_ClaimIntakeEdit {
        private _$categoryContainer: JQuery = $('#catFields');
        private _$parentContainer: JQuery = $('#formTypeEditContainer');
        private _activeTab: number = 1;

        FormTypeObject: PL.Claims.Models.FormType;
        ContentTypes: PL.Common.Models.ContentType[];

        init(claimIntake: Controllers.ClaimIntakeEdit): JQueryDeferred<any> {
            this._$parentContainer = claimIntake._$parentContainer;
            this.FormTypeObject = claimIntake.FormTypeObject;
            this.ContentTypes = claimIntake.ContentTypes;
            var $result = $.Deferred<any>();
            this.bind().done(() => {
                //this.setup();
            });
            return $result.resolve();
        }

        bind(): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            mW.handlebars.bind(CHSITemplates.AdminTools_ClaimIntakeEditor_Categories, { Categories: this.FormTypeObject.FormCategories }, $('#catFields'));
            super.bindSortable();
            return $result.resolve();
        }

        setup(): void {
            var _self = this;

            $('#catFields').on('keyup', '.catName', function () {
                var categoryID = $(this).closest('.category').data('formcategoryid'), name = $(this).val();
                _self.update(categoryID, name);
            });

            this._$parentContainer.on('click', '#catAdd', function () {
                $('#catSave').attr('title', 'Unsaved categories will not appear in the categories list inside the field tab until they are saved.').val(' Save unsaved Categories ').prop('disabled', false);
                _self.add();
            });

            this._$parentContainer.on('click', '#catSave', function () {
                _self.save().done(() => {
                    _self.updateFieldCategories();
                    _self.Fields().FormTypeObject = _self.FormTypeObject;      
                    _self.Fields().ContentTypes = _self.ContentTypes;              
                    _self.Fields().bind();
                });
            });

            this._$parentContainer.on('click', '.catCancel', function () {
                _self.delete($(this).data('formcategoryid'));
            });

            this._$parentContainer.on('click', '.catDelete', function () {
                _self.delete($(this).closest('.category').data('formcategoryid')).done(() => {
                    $(this).closest('.category').fadeOut('fast');
                });
            });
        }

        add(): void {

            var category: PL.Claims.Models.FormCategory = new PL.Claims.Models.FormCategory;
            category.FormTypeID = this.FormTypeObject.FormTypeID;
            if (this.FormTypeObject.FormCategories.length == 0) {
                category.FormCategoryID = -1;
                category.SortOrder = 0;
            } else {
                category.FormCategoryID = (-Math.abs(this.FormTypeObject.FormCategories[this.FormTypeObject.FormCategories.length - 1].FormCategoryID - 1));
                category.SortOrder = (this.FormTypeObject.FormCategories[this.FormTypeObject.FormCategories.length - 1].SortOrder + 1);
            }
            category.Status = 'New';
            this.FormTypeObject.FormCategories.push(category);

            this.bind();

        }

        update(formCategoryID: number, name: string): void {
            this.FormTypeObject.FormCategories.forEach((fcO, fcI) => {
                if (fcO.FormCategoryID == formCategoryID) {
                    fcO.CategoryName = name;
                }
            });
        }


        save(): JQueryDeferred<any> {
            var $result = $.Deferred();
            $('#catSave').prop('disabled', true);
            $('#catSave').val(' Saving... ');
            if (PL.Claims.Models.FormType.validateCategories(this.FormTypeObject.FormCategories)) {

                this.FormTypeObject.saveCategories().done(() => {
                    $('#catSave').attr('title', 'This will save all the changes you make related to the categories tab.').val(' Save ');
                    this.FormTypeObject.getCategoriesByFormTypeID().done((categories) => {

                        this.FormTypeObject.FormCategories = new Array<Claims.Models.FormCategory>();

                        categories.forEach((catO) => {
                            this.FormTypeObject.FormCategories.push(new Claims.Models.FormCategory(catO));
                        });

                        var internalCat: PL.Claims.Models.FormCategory = new PL.Claims.Models.FormCategory;
                        internalCat.CategoryName = 'Internal';
                        internalCat.FormCategoryID = null;

                        if (this.FormTypeObject.FormCategories.length > 0) {
                            internalCat.SortOrder = (this.FormTypeObject.FormCategories[this.FormTypeObject.FormCategories.length - 1].SortOrder + 1);
                        } else {
                            internalCat.SortOrder = 0;
                        }

                        this.FormTypeObject.FormCategories.push(internalCat);

                        this.FormTypeObject.reconstructFieldCategoryRelationship();

                        this.FormTypeObject.FormCategory = this.FormTypeObject.FormCategories[0];
                        $result.resolve(true)
                    });
                });

            } else {
                $('#catSave').attr('title', 'This will save all the changes you make related to the categories tab.').val(' Save ');
                $('#catSave').prop('disabled', false);
                super.getTabMessage(this._activeTab, Enums.ClaimIntakeEditor_Category_Messages.SAVE_ERROR);
            }
            return $result;
        }

        delete(formCategoryID: number): JQueryDeferred<any> {
            this.getCategory(formCategoryID);
            var $result = $.Deferred<any>();
            this.FormTypeObject.FormCategories.forEach((fcO, fcI, fc) => {
                if (fcO.FormCategoryID == formCategoryID) {

                    // added new cat after restructuring the formcategory object - refactor TODO
                    if (fcO.FieldCount > 0) {
                        var c = confirm('You have ' + fcO.FieldCount + ' field(s) associted with this category. If you delete it, all associted fields will be orphaned and may result in data loss.');
                        if (c) {
                            this.FormTypeObject.FormCategory.deleteCategory().done(() => {
                                fc.splice(fcI, 1);
                                super.updateFieldCategories();
                                this.bind().done(() => {
                                    $result.resolve();
                                });
                            });
                        } else {
                            $result.reject();
                        }
                    } else {
                        this.FormTypeObject.FormCategory.deleteCategory().done(() => {
                            fc.splice(fcI, 1);
                            super.updateFieldCategories();
                            this.bind().done(() => {
                                $result.resolve();
                            });
                        });
                    }

                }

            });
            return $result;
        }

        getCategory(formCategoryID: number): void {
            this.FormTypeObject.FormCategories.forEach((fcO) => {
                if (fcO.FormCategoryID == formCategoryID) {
                    this.FormTypeObject.FormCategory = fcO;
                }
            });
        }
    }

}