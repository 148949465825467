CHSI.Page.SItemFormItem = {
    Init: function (ScheduleItemID, scheduleTypeID, DefaultImageID, ClientID, isPortal) {

        this.Templates.ScheduleItemFormItem = Handlebars.compile($('#Template_ScheduleItemFormItem').html());
        this.Cache.isPortal = isPortal;
        if (ScheduleItemID) {
            this.Calls.GetItem(ScheduleItemID);
            this.Cache.AddNew = false;
        } else {
            this.Cache.AddNew = true;
            this.Cache.SItemObject = this.GetNewItem(scheduleTypeID, ClientID);
            var SuccessFunction;
            if (ClientID) {
                SuccessFunction = function () {
                    CHSI.Page.SItemFormItem.Calls.GetClientInfo(ClientID)
                };
            } else {
                SuccessFunction = CHSI.Page.SItemFormItem.BindPresentationLayer();
            }
            this.Calls.GetScheduleTypeByID(scheduleTypeID, SuccessFunction);
        }
        this.Events.Global();
    },
    Templates: {

    },
    Cache: {
        SItemObject: null
      , ClientID: null
      , Active: true
      , isPortal: false
      , Deleted: false
      , Status: 'Pending'
      , AutoApprove: false
      , AddNew: false
      , SaveButton: false
    },
    GetNewItem: function (scheduleTypeID, ClientID) {
        return {
            Locations: []
          , ClientInfo: []
          , FileID: null
          , Comments: ''
          , ScheduleTypeID: scheduleTypeID
          , DefaultFileID: null
          , ClientID: ClientID
          , Status: 'Active'
            

        }
    },
    BindPresentationLayer: function () {

        $('#jxScheduleItemFormItemContainer').html(this.Templates.ScheduleItemFormItem(this.Cache.SItemObject));

        if (this.Cache.AddNew && this.Cache.isPortal == false) {
            $('#jxStatus').val('Active');
        }

        if (CHSI.Page.SItemFormItem.Cache.ClientID) {
            $('.uxScheduleItemFormUploadImage').attr('disabled', false);
        } else {l
            $('.uxScheduleItemFormUploadImage').attr('disabled', true);
        }

        if ($('#jxClientID').val()) {
            $('.uxScheduleItemFormUploadImage').attr('disabled', false);
        } else {
            $('.uxScheduleItemFormUploadImage').attr('disabled', true);
        }

        if (CHSI.Page.SItemFormItem.Cache.Deleted) {
            if (!CHSI.Page.SItemFormItem.Cache.isPortal) {
                $('#jxItemLabel').html("Approval Required:  Item Delete Request");
            }
        } else {
            //$('#jxItemLabel').html("");
            CHSI.Page.SItemFormFields.SetLabel();
        }

        if (CHSI.Page.SItemFormItem.Cache.isPortal) {
            $('#jxClientSelectContainer').hide();
        }

        // logic for activating/deactivating status
        $('#jxStatusUpdate').on('click', function () {
            var msg = 'Are you sure you want to deactivate this item? You can reactivate at a later time.';
            var s = $('#jxStatus').val();
            if (s === 'Active' || s === 'Pending-Active')
            {
                if (!confirm(msg)) return;
                if (CHSI.Page.SItemFormItem.Cache.isPortal && CHSI.Page.SItemFormItem.Cache.AutoApprove) {
                    $('#jxStatus').val('Inactive');
                    $('#jxStatusUpdate').val('Activate');
                }
                else {
                    $('#jxStatus').val('Pending-Inactive');
                    $('#jxStatusUpdate').val('Activate');
                }
            }
            else {
                if (CHSI.Page.SItemFormItem.Cache.isPortal && CHSI.Page.SItemFormItem.Cache.AutoApprove) {
                    $('#jxStatus').val('Active');
                    $('#jxStatusUpdate').val('Deactivate');
                }
                else {
                    $('#jxStatus').val('Pending-Active');
                    $('#jxStatusUpdate').val('Deactivate');
                }
            }
        });
    },
    Events: {
        Global: function () {
            $('.uxScheduleItemDelete').on('click', function () {
                if (CHSI.Page.SItemFormItem.Cache.AutoApprove || !CHSI.Page.SItemFormItem.Cache.isPortal) {
                    var r = confirm("Are you sure you want to delete this item?");
                    if (r == true) {
                        CHSI.Page.SItemFormItem.Calls.DeleteItem(false);
                        closeThis(true);
                    }
                    else { return false; }
                } else {
                    var r = confirm("Confirming this action will submit a request to delete this item.  When your program administrator approves the request the item will be removed from the schedule.");
                    if (r == true)
                    {
                        CHSI.Page.SItemFormItem.Calls.DeleteItem(true);
                        closeThis(true);
                    }
                    else { return false; }
                }

            });

            $('#jxScheduleItemFormItemContainer').on('click', '#jxClientSelect', function () {
                var thisWindow = window.name;
                var ReturnFunctionString = ((thisWindow == '') ? 'ChangeClient' : 'parent.window.frames.' + thisWindow + '.ChangeClient');
                parent.ShowAdvancedSearchForm('ChooseClient', null, ReturnFunctionString);
            });
        },
        GetStatus: function (isPortal, isAutoApprove, isDeleted) {
            var status = $('#jxStatus').val();// not sure where else to get status
            switch (status) {
                case 'Active':
                    return (isPortal && !isAutoApprove) ? 'Pending-Active' : status;
                case 'Pending-Inactive':
                    return (isPortal && !isAutoApprove) ? ((isPortal && isAutoApprove) ? 'Inactive' : status) : 'Pending-Inactive';
                case 'Deleted':
                    return (isPortal && !isAutoApprove) ? 'Pending-Delete' : status;
                case 'Pending-Delete':
                    return (!isPortal && isAutoApprove) ? 'Deleted' : status;
                case 'Pending-Active':
                    return (!isPortal && isAutoApprove) ? ((isPortal && isAutoApprove) ? 'Active': 'Pending-Inactive') : status;
                default:
                    return status;
            }
        },

        PersistFromCache: function () {
            var SItemObject = CHSI.Page.SItemFormItem.Cache.SItemObject;
            SItemObject.ClientID = $('#jxClientID').val();
            SItemObject.LocationID = $('#jxLocation option:selected').val();
            SItemObject.Name = $('#jxName').val();

            SItemObject.Status = CHSI.Page.SItemFormItem.Events.GetStatus(CHSI.Page.SItemFormItem.Cache.isPortal, CHSI.Page.SItemFormItem.Cache.AutoApprove, CHSI.Page.SItemFormItem.Cache.Deleted);
           
            SItemObject.Comments = $('#jxComments').val();
            CHSI.Page.SItemFormItem.Cache.SItemObject = SItemObject;
        }
    },
    Calls: {
        GetItem: function (ScheduleItemID) {
            CHSI.Page.SItemFormItem.Cache.Deleted = false;
            var AjaxURL = '/api/UW/ScheduleItem/Get?ScheduleItemID=' + ScheduleItemID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.SItemFormItem.Cache.SItemObject = e;
                    CHSI.Page.SItemFormItem.Cache.Status = e.Status;
                    if (e.Status == 'Pending-Delete') {
                        CHSI.Page.SItemFormItem.Cache.Deleted = true;
                    }
                    if (e.AutoApprove) {
                        CHSI.Page.SItemFormItem.Cache.AutoApprove = true;
                    }
                    if (e.ClientID == null) {
                        CHSI.Page.SItemFormItem.BindPresentationLayer();
                    } else {

                        CHSI.Page.SItemFormItem.Cache.ClientID = e.ClientID;
                        CHSI.Page.SItemFormItem.Calls.GetClientInfo(CHSI.Page.SItemFormItem.Cache.ClientID);

                    }
                    $("#ScheduleItemFormTabs").tabs('enable', 1);
                    $("#ScheduleItemFormTabs").tabs('enable', 2);
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },
        GetClientInfo: function (ClientID) {
            CHSI.Page.SItemFormItem.Cache.SItemObject.ClientID = ClientID;
            var AjaxURL = '/api/CRM/Clients/GetClient?ClientID=' + ClientID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.SItemFormItem.Cache.SItemObject.ClientInfo = e;

                    CHSI.Page.SItemFormItem.Calls.GetLocationByClientID(CHSI.Page.SItemFormItem.Cache.SItemObject.ClientID);
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },
        GetLocationByClientID: function (ClientID) {
            var AjaxURL = '/api/CRM/Location/GetLocationsByClientID?ClientID=' + ClientID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {

                    CHSI.Page.SItemFormItem.Cache.SItemObject.Locations = e;
                    CHSI.Page.SItemFormItem.BindPresentationLayer();
                   
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },
        GetScheduleTypeByID: function (ScheduleTypeID, SuccessFunction) {
            var AjaxURL = '/api/UW/Schedule/GetScheduleTypeByScheduleTypeID?ScheduleTypeID=' + ScheduleTypeID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: true,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    if (e.AutoApprove) {
                        CHSI.Page.SItemFormItem.Cache.AutoApprove = true;
                    }
                    if (e.DefaultFileID) {
                        CHSI.Page.SItemFormItem.Cache.SItemObject.DefaultFileID = e.DefaultFileID;
                    }
                    if (SuccessFunction) {
                        SuccessFunction(e);
                    }
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },
        SaveItem: function (isPortal, successFunction) {
            CHSI.Page.SItemFormItem.Events.PersistFromCache();            

            if (CHSI.Page.SItemFormItem.Cache.SItemObject.ClientID) {
                var ajaxData = [];
                ajaxData = CHSI.Page.SItemFormItem.Cache.SItemObject;
                var ajaxString = JSON.stringify(ajaxData);
                var AjaxURL = '/api/UW/ScheduleItem/SaveScheduleItem';
                $.ajax({
                    type: "POST",
                    url: AjaxURL,
                    data: ajaxString,
                    cache: true,
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    success: function (e) {
                        CHSI.Page.SItemFormItem.Cache.SaveButton = false;
                        CHSI.Page.SItemFormItem.Cache.SItemObject.ScheduleItemID = e.ScheduleItemID;
                        $("#ScheduleItemFormTabs").tabs('enable', 1);
                        $("#ScheduleItemFormTabs").tabs('enable', 2);

                        if (successFunction) {
                            successFunction();
                        }
                    },
                    error: function (e) {
                        CHSI.Common.Log(e);
                    }
                });
            } else {
                alert('Please add a valid client');
            }
        },

        DeleteItem: function (isPortal, successFunction) {
            CHSI.Page.SItemFormItem.Events.PersistFromCache();
            if (isPortal) {
                CHSI.Page.SItemFormItem.Cache.SItemObject.Status = 'Pending-Delete';
            } else {
                CHSI.Page.SItemFormItem.Cache.SItemObject.Status = 'Deleted';
            }
            if (CHSI.Page.SItemFormItem.Cache.SItemObject.ClientID) {
                var ajaxData = [];
                ajaxData = CHSI.Page.SItemFormItem.Cache.SItemObject;
                var ajaxString = JSON.stringify(ajaxData);
                var AjaxURL = '/api/UW/ScheduleItem/SaveScheduleItem';
                $.ajax({
                    type: "POST",
                    url: AjaxURL,
                    data: ajaxString,
                    cache: true,
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    success: function (e) {
                        CHSI.Page.SItemFormItem.Cache.SItemObject.ScheduleItemID = e.ScheduleItemID;
                        if (successFunction) {
                            successFunction();
                        }
                    },
                    error: function (e) {
                        CHSI.Common.Log(e);
                    }
                });
            } else {
                alert('Please add a valid client');
            }
        }
    }
}