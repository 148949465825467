var Template_SuperClaimsResults = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "<table width=\"100%\" class=\"dtable\">\r\n    <thead>\r\n        <tr> \r\n            <td class=\"sorttable_nosort\" width=\"150\">Client</td>\r\n            <td class=\"sorttable_nosort\">Coverage</td>\r\n            <td class=\"sorttable_nosort\">Event/Claim #</td>\r\n            <td class=\"sorttable_nosort\">Claim Type</td>\r\n            <td class=\"sorttable_nosort\">Date Of Incident</td>\r\n            <td class=\"sorttable_nosort\">No. Claims</td>\r\n            <td class=\"sorttable_nosort\">Status</td>\r\n            <td class=\"sorttable_nosort\" style=\"text-align: right;\">Incurred</td>\r\n            <td class=\"sorttable_nosort\" style=\"padding-left: 20px;\">&nbsp</td>\r\n            <td class=\"sorttable_nosort\">&nbsp</td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.SuperClaims : depth0), {"name":"each","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </tbody>\r\n</table>\r\n";
},"2":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", lambda=this.lambda, buffer = "        <tr class=\""
    + escapeExpression(((helpers.backgroundcolor || (depth0 && depth0.backgroundcolor) || helperMissing).call(depth0, (depth0 != null ? depth0.Index : depth0), {"name":"backgroundcolor","hash":{},"data":data})))
    + "\">\r\n            <td colspan=\"2\">"
    + escapeExpression(((helper = (helper = helpers.ClientName || (depth0 != null ? depth0.ClientName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ClientName","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td colspan=\"2\">"
    + escapeExpression(((helper = (helper = helpers.Description || (depth0 != null ? depth0.Description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Description","hash":{},"data":data}) : helper)))
    + "</td>   \r\n            <td>&nbsp;</td>\r\n            <td>"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.Claims : depth0)) != null ? stack1.length : stack1), depth0))
    + "</td>\r\n            <td>"
    + escapeExpression(((helper = (helper = helpers.Status || (depth0 != null ? depth0.Status : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Status","hash":{},"data":data}) : helper)))
    + "</td>            \r\n            <td style=\"text-align: right;\">"
    + escapeExpression(((helpers.FormatCurrency || (depth0 && depth0.FormatCurrency) || helperMissing).call(depth0, (depth0 != null ? depth0.TotalIncurred : depth0), true, {"name":"FormatCurrency","hash":{},"data":data})))
    + "</td>\r\n            <td>[<a href=\"javascript: ShowSuperClaimModal("
    + escapeExpression(((helper = (helper = helpers.SuperClaimID || (depth0 != null ? depth0.SuperClaimID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"SuperClaimID","hash":{},"data":data}) : helper)))
    + ");\" class=\"minilink\">edit</a>]</td>\r\n        </tr>\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.Claims : depth0), {"name":"each","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data,depths) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "        <tr class=\""
    + escapeExpression(((helpers.backgroundcolor || (depth0 && depth0.backgroundcolor) || helperMissing).call(depth0, (depths[1] != null ? depths[1].Index : depths[1]), {"name":"backgroundcolor","hash":{},"data":data})))
    + "\">            \r\n            <td>&nbsp;</td>\r\n            <td>"
    + escapeExpression(((helper = (helper = helpers.Coverage || (depth0 != null ? depth0.Coverage : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Coverage","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>"
    + escapeExpression(((helper = (helper = helpers.ClaimNumber || (depth0 != null ? depth0.ClaimNumber : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ClaimNumber","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>"
    + escapeExpression(((helper = (helper = helpers.ClaimTypeCategory || (depth0 != null ? depth0.ClaimTypeCategory : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ClaimTypeCategory","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>"
    + escapeExpression(((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || helperMissing).call(depth0, (depth0 != null ? depth0.DateOfIncident : depth0), {"name":"CHSIDate","hash":{},"data":data})))
    + "</td>            \r\n            <td>"
    + escapeExpression(((helper = (helper = helpers.Claimant || (depth0 != null ? depth0.Claimant : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Claimant","hash":{},"data":data}) : helper)))
    + "</td>            \r\n            <td>"
    + escapeExpression(((helper = (helper = helpers.Status || (depth0 != null ? depth0.Status : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Status","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td style=\"text-align: right;\">"
    + escapeExpression(((helpers.FormatCurrency || (depth0 && depth0.FormatCurrency) || helperMissing).call(depth0, (depth0 != null ? depth0.Incurred : depth0), true, {"name":"FormatCurrency","hash":{},"data":data})))
    + "</td>\r\n            <td>[<a href=\"javascript: ShowClaimView("
    + escapeExpression(((helper = (helper = helpers.ClaimID || (depth0 != null ? depth0.ClaimID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ClaimID","hash":{},"data":data}) : helper)))
    + ", "
    + escapeExpression(((helper = (helper = helpers.ClientID || (depth0 != null ? depth0.ClientID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ClientID","hash":{},"data":data}) : helper)))
    + ", "
    + escapeExpression(((helper = (helper = helpers.ClaimSystemVersion || (depth0 != null ? depth0.ClaimSystemVersion : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ClaimSystemVersion","hash":{},"data":data}) : helper)))
    + ")\" class=\"minilink\">view</a>]</td>\r\n        </tr>\r\n";
},"5":function(depth0,helpers,partials,data) {
  return "<h4>No results found.</h4>\r\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "﻿";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.SuperClaims : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.program(5, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true,"useDepths":true});