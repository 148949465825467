
CHSI.UW.MuniUW_Details = {
    Init: function (qs) {
        this.Cache.QuoteID = qs.QuoteID;
        this.Cache.GroupID = qs.GroupName;
        if (typeof (qs.ClientID) == "undefined") {
            this.Cache.ClientID = qs.clientid;
        } else {
            this.Cache.ClientID = qs.ClientID;
        }
        console.log('MuniUW_Details Init');
        this.Cache.PDF.setHours(0,0,0,0);
        
        this.Templates.CoverageLine = CHSITemplates.Template_QuoteInfo_CoverageLine;
        this.Templates.Commissions = CHSITemplates.Template_QuoteInfo_Commissions;
        this.Templates.Payroll = CHSITemplates.Template_QuoteInfo_Payroll;
        this.Templates.Details = CHSITemplates.Template_QuoteInfo_QuoteDetails;

        this.Calls.GetQuoteDetails(this.Cache.QuoteID);
        this.Calls.GetCommissions();

        this.Events.Global();
    },
    Templates: {},
    Cache: {
        CommissionsObject: {},
        SavedCommissionObject: {},
        NewestCommissionID: 0,
        PayrollObject: {},
        SavedPayrollObject: {},
        NewestPayrollID: 0,
        QuoteObject: {},
        CoverageLineObject: {},
        ValidationObject: {},
        InfoObject: {},
        ActiveEditableCommssionIndex: null,
        ActiveEditablePayrollIndex: null,
        QuoteID: null,
        GroupID: null,
        isClassCodeValid: false,
        Producers: [],
        States: [],
        UseEstimatedPayroll: false,
        PDF: new Date(),
        PDL: new Date('01/01/2299'),
        PDMax: 0,
        DateDiff: 366,
        ProRateDays: 0,
        Editable:'Y'


    },
    Binds: {
        BindQuoteDetails: function () {
            $('#QuoteDetailContainer').html(CHSI.UW.MuniUW_Details.Templates.Details({ QuoteObject: CHSI.UW.MuniQuote.Cache.QuoteObject }));
            jQuery('.DatePicker').datepicker({ showOn: "both", buttonImage: "/images/icons/cal.gif", buttonImageOnly: true });            
            CHSI.UW.MuniUW_Details.Cache.ProRateDays = DateDiff(CHSI.UW.MuniQuote.Cache.QuoteObject.EffectiveDate, CHSI.UW.MuniQuote.Cache.QuoteObject.ExpirationDate);
            $('#proRateDays').val(CHSI.UW.MuniUW_Details.Cache.ProRateDays);
            restart = true;
            sorttable.init();
            if (CHSI.UW.MuniQuote.Cache.QuoteList.length > 1 && CHSI.UW.MuniQuote.Cache.QuoteObject.ParentQuoteID) {
                $('.QuoteLabel').html('Endorsement');
            }
            CHSI.UW.MuniQuote.DisablePage(CHSI.UW.MuniUW_Details.Cache.Editable);


        },
        BindCoverageLines: function () {            
            $('#CoverageLineContainer').html(CHSI.UW.MuniUW_Details.Templates.CoverageLine({ CoverageLineObject: CHSI.UW.MuniQuote.Cache.CoverageLineObject }));
            restart = true;
            sorttable.init();

            CHSI.UW.MuniQuote.DisablePage(CHSI.UW.MuniUW_Details.Cache.Editable);
        },
        BindCommissions: function () {
            $('#CommissionsContainer').html(CHSI.UW.MuniUW_Details.Templates.Commissions({ CommissionsObject: CHSI.UW.MuniQuote.Cache.CommissionsObject }));
            restart = true;
            sorttable.init();

            CHSI.UW.MuniUW_Details.Calls.GetProducers(function (data) {
                var ProducerList = CHSI.UW.MuniUW_Details.Events.ConstructProducersList(data, 'id="jxNewCommissionProducers"');
                $('#jxProducers').html(ProducerList);
                CHSI.UW.MuniQuote.DisablePage(CHSI.UW.MuniUW_Details.Cache.Editable);
            });
            
        },
        BindPayroll: function () {
            $('#PayrollContainer').html(CHSI.UW.MuniUW_Details.Templates.Payroll({ PayrollObject: CHSI.UW.MuniUW_Details.Cache.PayrollObject }));
            restart = true;
            sorttable.init();

            CHSI.UW.MuniUW_Details.Calls.GetStates(function (data) {
                var StateList = CHSI.UW.MuniUW_Details.Events.ConstructStateList(data, 'jxNewPayrollStates', 'validateNewClassCode');
                $('#jxStates').html(StateList);
                CHSI.UW.MuniQuote.DisablePage(CHSI.UW.MuniUW_Details.Cache.Editable);
            });

            $('#jxPayrollErrorMessage').hide();

            
        }
    },
    Events: {
        Global: function () {
            // Coverage Lines
            $('#CoverageLineContainer').on('click', '#addPL', function () {
                var ProductLineID = $('#addNewCoverageLine').val();
                CHSI.UW.MuniUW_Details.Calls.AddQuoteCoverageLine(CHSI.UW.MuniUW_Details.Cache.QuoteID, ProductLineID);
            });

            $('#CoverageLineContainer').on('click', '.jxDeleteCL', function () {
                var ProductLineID = $(this).data('clid');
                CHSI.UW.MuniUW_Details.Calls.DeleteCoverageLine(CHSI.UW.MuniUW_Details.Cache.QuoteID, ProductLineID);
            });

            $('#QuoteDetailContainer').on('change', '.QuoteProperty', function () {
                var Property = $(this).val();
                var PropertyName = $(this).attr('data-propertyname');
                var Validation = CHSI.UW.MuniUW_Details.Events.ValidateQuoteProperty(PropertyName, Property);
              
                if (Validation.length == 0 || (CHSI.UW.MuniQuote.Cache.QuoteList.length > 1 && CHSI.UW.MuniQuote.Cache.QuoteObject.ParentQuoteID))
                {
                    $('.QuoteProperty').css({ "border": '2px inset' });                   
                    if (PropertyName == 'EffectiveDate') {                       
                        //CHSI.UW.MuniQuote.SaveQuoteProperty('ExpirationDate', CHSI.UW.MuniQuote.Cache.QuoteObject.ExpirationDate, function () {
                        //    CHSI.UW.MuniUW_Details.Calls.SaveQuoteProperty(PropertyName, Property);
                        //});
                    } else {
                       
                        //CHSI.UW.MuniUW_Details.Calls.SaveQuoteProperty(PropertyName, Property);
                    }

                } else {
                    for (var c = 0; c < Validation.length; c++) {
                        alert(Validation[c].ErrorMessage);
                        $('#jxQuoteProp' + PropertyName).css({ "border": '#FF0000 2px inset' });
                    }
                }

            });

            // Commissions
            $('#CommissionsContainer').on('click', '.jxEditCommission', function () {
                var Edit = $(this),
                    Index = Edit.data('commissionindex');

                $('#NewCommission').find('input, select').prop('disabled', true);

                if (CHSI.UW.MuniUW_Details.Cache.ActiveEditableCommssionIndex != Index) {
                    CHSI.UW.MuniUW_Details.Events.CommissionMode(CHSI.UW.MuniUW_Details.Cache.ActiveEditableCommssionIndex, true);
                }

                CHSI.UW.MuniUW_Details.Events.CommissionMode(Index);

                CHSI.UW.MuniUW_Details.Cache.ActiveEditableCommssionIndex = Index;
            });

            $('#CommissionsContainer').on('click', '#jxNewResetCommission', function () {
                $('#jxNewCommissionProducers').val($("#jxNewCommissionProducers option:first").val());
                $('#jxNewCommissionRepAgent').val('');
                $('#jxNewCommissionCustNo').val('');
                $('#jxNewCommissionPrimary').prop('checked', false);
                $('#jxNewCommissionRate').val('');
                $('#jxNewCommissionRating').prop('checked', false);
            });

            $('#CommissionsContainer').on('click', '.jxCancelCommission', function () {
                var Index = $(this).data('commissionindex');

                $('#NewCommission').find('input, select').prop('disabled', false);

                CHSI.UW.MuniUW_Details.Events.CommissionMode(Index, true);
            });

            $('#CommissionsContainer').on('click', '.jxDeleteCommission', function () {
                var ID = $(this).data('commissionid'),
                    Response = confirm('Please confirm that you would like to delete this Commission item.');

                if (Response) {
                    CHSI.UW.MuniUW_Details.Calls.DeleteCommission(ID, function () {
                        CHSI.UW.MuniUW_Details.Calls.GetCommissions();
                    });
                }
            });

            $('#CommissionsContainer').on('click', '.jxSaveCommission', function () {
                var ID = $(this).data('commissionid'),
                    Index = $(this).data('commissionindex');

                CHSI.UW.MuniUW_Details.Cache.SavedCommissionObject = CHSI.UW.MuniUW_Details.Events.PersistCommission(ID, Index);
                CHSI.UW.MuniUW_Details.Calls.SaveCommission(function () {
                    CHSI.UW.MuniUW_Details.Calls.GetCommissions();
                });
            });

            $('#CommissionsContainer').on('click', '#jxSaveNewCommission', function () {
                CHSI.UW.MuniUW_Details.Cache.SavedCommissionObject = CHSI.UW.MuniUW_Details.Events.PersistCommission();
                CHSI.UW.MuniUW_Details.Calls.SaveCommission(function () {
                    CHSI.UW.MuniUW_Details.Calls.GetCommissions();
                });
            });

            // Payroll
            $('#PayrollContainer').on('click', '.jxEditPayroll', function () {
                var Edit = $(this),
                    Index = Edit.data('payrollindex');

                $('#jxPayrollErrorMessage').val('');
                $('#jxPayrollErrorMessage').hide();

                $('#NewPayroll').find('input, select').prop('disabled', true);

                if (CHSI.UW.MuniUW_Details.Cache.ActiveEditablePayrollIndex != Index) {
                    CHSI.UW.MuniUW_Details.Events.PayrollMode(CHSI.UW.MuniUW_Details.Cache.ActiveEditablePayrollIndex, true);
                }

                CHSI.UW.MuniUW_Details.Events.PayrollMode(Index);

                CHSI.UW.MuniUW_Details.Cache.ActiveEditablePayrollIndex = Index;


            });

            $('#PayrollContainer').on('click', '#jxResetNewPayroll', function () {
                $('#jxNewPayrollStates').val($('#jxNewPayrollStates option:first').val())
                $('#jxNewClassCode').val('');
                $('#jxNewAltClassCode').val('');
                $('#jxNewEmployees').val('');
                $('#jxNewDescrption').val('');
                $('#jxNewPayroll').val('');
                $('#jxNewPayrollRate').val('');
                $('#jxNewGrossPrem').val('');
                $('#jxNewPayrollBaseRate').val('');

            });

            $('#PayrollContainer').on('click', '.jxDeletePayroll', function () {
                var ID = $(this).data('detailid'),
                   Response = confirm('Please confirm that you would like to delete this Payroll item.');

                if (Response) {
                    CHSI.UW.MuniUW_Details.Calls.DeletePayroll(ID, function () {
                        CHSI.UW.MuniUW_Details.Calls.GetPayroll();
                    });
                }
            });

            $('#PayrollContainer').on('click', '.jxCancelPayroll', function () {
                var Index = $(this).data('payrollindex');

                $('#NewPayroll').find('input, select').prop('disabled', false);

                CHSI.UW.MuniUW_Details.Events.PayrollMode(Index, true);
            });

            $('#PayrollContainer').on('click', '#jxSaveNewPayroll', function () {

                if (CHSI.UW.MuniUW_Details.Cache.isClassCodeValid) {
                    CHSI.UW.MuniUW_Details.Cache.SavedPayrollObject = CHSI.UW.MuniUW_Details.Events.PersistPayroll();
                    CHSI.UW.MuniUW_Details.Calls.SavePayroll(function () {
                        CHSI.UW.MuniUW_Details.Calls.GetPayroll();
                    });
                }

            });

            $('#PayrollContainer').on('click', '.jxSavePayroll', function () {

                var ID = $(this).data('payrollid'),
                    Index = $(this).data('payrollindex');

                if (CHSI.UW.MuniUW_Details.Cache.isClassCodeValid) {
                    CHSI.UW.MuniUW_Details.Cache.SavedPayrollObject = CHSI.UW.MuniUW_Details.Events.PersistPayroll(ID, Index);
                    CHSI.UW.MuniUW_Details.Calls.SavePayroll(function () {
                        CHSI.UW.MuniUW_Details.Calls.GetPayroll();
                    });
                }
            });


            $('#PayrollContainer').on('change', '.validateNewClassCode', function () {
                var Index = $(this).data('index'),
                    ClassCodeFilters = {},
                    ClassCodeResult = {};

                if (!$('#jxNewClassCode').val() == '') {
                    ClassCodeFilters.State = $('#jxNewPayrollStates').val();
                    ClassCodeFilters.ClassCode = $('#jxNewClassCode').val();
                    ClassCodeFilters.GroupID = CHSI.UW.MuniUW_Details.Cache.GroupID;
                    ClassCodeFilters.EffectiveDate = CHSI.UW.MuniQuote.Cache.QuoteObject.EffectiveDate;
                    ClassCodeFilters.DescriptionIndex = $('#jxNewDescription').val();

                    CHSI.UW.MuniUW_Details.Events.ClassCodeValidationResponse(ClassCodeFilters);
                }
            });

            $('#PayrollContainer').on('change', '.validateClassCode', function () {
                var Index = $(this).data('index'),
                    ClassCodeFilters = {},
                    ClassCodeResult = {};

                if (!$('#jxClassCodeMode_' + Index + ' > input').val() == '') {
                    ClassCodeFilters.State = $('#jxStateMode_' + Index + ' > select').val();
                    ClassCodeFilters.ClassCode = $('#jxClassCodeMode_' + Index + ' > input').val();
                    ClassCodeFilters.GroupID = CHSI.UW.MuniUW_Details.Cache.GroupID;
                    ClassCodeFilters.EffectiveDate = CHSI.UW.MuniQuote.Cache.QuoteObject.EffectiveDate;
                    ClassCodeFilters.DescriptionIndex = $('#jxDescriptionMode_' + Index + ' > select').val();

                    CHSI.UW.MuniUW_Details.Events.ClassCodeValidationResponse(ClassCodeFilters);
                }
            });

            $('#jxSaveNext').on('click', function () {
                CHSI.Common.Navigation.NavigateToNextTab(function () {
                    var BatchQuoteProperties = [],
                        QuotePropertyObject = {};

                    QuotePropertyObject.QuoteID = CHSI.UW.MuniUW_Details.Cache.QuoteID;
                    QuotePropertyObject.PropertyName = 'QuoteName';
                    QuotePropertyObject.NewValue = $('#jxQuotePropQuoteName').val();                    
                    BatchQuoteProperties.push(QuotePropertyObject);

                    QuotePropertyObject = {};
                    QuotePropertyObject.QuoteID = CHSI.UW.MuniUW_Details.Cache.QuoteID;
                    QuotePropertyObject.PropertyName = 'QuoteDate';
                    QuotePropertyObject.NewValue = $('#jxQuotePropQuoteDate').val();                    
                    BatchQuoteProperties.push(QuotePropertyObject);

                    QuotePropertyObject = {};
                    QuotePropertyObject.QuoteID = CHSI.UW.MuniUW_Details.Cache.QuoteID;
                    QuotePropertyObject.PropertyName = 'EffectiveDate';
                    QuotePropertyObject.NewValue = $('#jxQuotePropEffectiveDate').val();                    
                    BatchQuoteProperties.push(QuotePropertyObject);

                    QuotePropertyObject = {};
                    QuotePropertyObject.QuoteID = CHSI.UW.MuniUW_Details.Cache.QuoteID;
                    QuotePropertyObject.PropertyName = 'ExpirationDate';
                    QuotePropertyObject.NewValue = $('#jxQuotePropExpirationDate').val();                    
                    BatchQuoteProperties.push(QuotePropertyObject);

                    BatchQuoteProperties.push({
                        QuoteID: CHSI.UW.MuniUW_Details.Cache.QuoteID,
                        PropertyName: 'PolicyPeriod',
                        NewValue: $('#jxQuotePropPolicyPeriod').val()
                    });

                    CHSI.UW.MuniQuote.SaveBatchQuoteProperties(BatchQuoteProperties);
                });
            });

            $('#jxCancel').on('click', function () {
                CHSI.Common.Navigation.NavigateToReturnURL();
            });
        },
        ValidateQuoteProperty: function (quoteproperty, value) {
            var Validation = [];
            var Valid = true;
            var ErrorMessage = '';
            var PDF = CHSI.UW.MuniUW_Details.Cache.PDF;
            var PDL = CHSI.UW.MuniUW_Details.Cache.PDL;
            var EffectiveDate = CHSI.UW.MuniQuote.Cache.QuoteObject.EffectiveDate;
            var ExpirationDate = CHSI.UW.MuniQuote.Cache.QuoteObject.ExpirationDate;

            switch (quoteproperty) {
                case 'EffectiveDate':
                    var DateValue = new Date(value);
                    EffectiveDate = value;
                    CHSI.UW.MuniQuote.Cache.QuoteObject.EffectiveDate = value;
                    if (DateValue < PDF || DateValue > PDL) {
                        Valid = false;
                        ErrorMessage = 'Effective date must be equal to or greater than ' + CHSI.Dates.GetShortDate(PDF);
                    } else {
                        var NewExpiration=DateValue;
                        NewExpiration.setFullYear(DateValue.getFullYear()+1);
                        CHSI.UW.MuniQuote.Cache.QuoteObject.ExpirationDate = CHSI.Dates.GetShortDate(NewExpiration);
                        ExpirationDate = CHSI.UW.MuniQuote.Cache.QuoteObject.ExpirationDate;
                        $('#jxQuotePropExpirationDate').val(CHSI.UW.MuniQuote.Cache.QuoteObject.ExpirationDate);
                    }

                    break;
                case 'ExpirationDate':
                    var DateValue = new Date(value);
                    ExpirationDate = value;
                    CHSI.UW.MuniQuote.Cache.QuoteObject.ExpirationDate = value;
                    if (DateValue < PDF || DateValue > PDL) {
                        Valid = false;
                        ErrorMessage = 'Expiration date must be equal to or less than ' + CHSI.Dates.GetShortDate(PDL);
                    }
                    break;
                case 'PolicyPeriod':
                    var DateValue = value*1;
                    if (DateValue < 1900 || DateValue > 9999) {
                        Valid = false;
                        ErrorMessage = 'Please enter a valid Policy Period';
                    }
                    break;

            }
            if (Valid == false) {
                var ValidationItem = { Id: quoteproperty, Valid: Valid, ErrorMessage: ErrorMessage };
                Validation.push(ValidationItem);
            }

            var ProRateDays = DateDiff(EffectiveDate, ExpirationDate);
            CHSI.UW.MuniUW_Details.Cache.ProRateDays = ProRateDays;
            $('#proRateDays').val(CHSI.UW.MuniUW_Details.Cache.ProRateDays);
            if (ProRateDays > CHSI.UW.MuniUW_Details.Cache.DateDiff) {
                Valid = false;
                ErrorMessage = 'Policy Length > 1 year';
                var ValidationItem = { Id: quoteproperty, Valid: Valid, ErrorMessage: ErrorMessage };
                Validation.push(ValidationItem);                           
                
            }


            return Validation;
        },
        ValidateUseEstimatedPayroll: function () {
            CHSI.UW.MuniUW_Details.Cache.UseEstimatedPayroll = false;
            $('#PayrollContainer').hide();
            if (CHSI.UW.MuniQuote.Cache.QuoteObject.CL) {
                for (var CL in CHSI.UW.MuniQuote.Cache.QuoteObject.CL) {
                    if (CHSI.UW.MuniQuote.Cache.QuoteObject.CL[CL].UseEstimatedPayroll) {
                        CHSI.UW.MuniUW_Details.Cache.UseEstimatedPayroll = true;
                        $('#PayrollContainer').show();
                    }
                }

            }

        },
        ConstructProducersList: function (brokers, idTag, CurrentIndex) {
            var HTML = [];

            HTML.push('<select ' + idTag + ' style="width: 150px;">');
            for (var index = 0; index < brokers.length; index++) {
                if ($('#jxProducerMode_' + CurrentIndex).data('producerid') == brokers[index].ProducerID) {
                    HTML.push('<option value="' + brokers[index].ProducerID + '" selected="selected">' + brokers[index].ProducerName + '</option>');
                } else {
                    HTML.push('<option value="' + brokers[index].ProducerID + '">' + brokers[index].ProducerName + '</option>');
                }
            }
            HTML.push('</select>');

            return HTML.join('');
        },
        ConstructStateList: function (states, idTag, classTag, CurrentIndex) {
            var HTML = [];

            HTML.push('<select id="' + idTag + '" class="' + classTag + '" style="width: 50px;">');
            for (var index = 0; index < states.length; index++) {
                if ($('#jxStateMode_' + CurrentIndex).data('state') == states[index]) {
                    HTML.push('<option value="' + states[index] + '" selected="selected">' + states[index] + '</option>');
                } else {
                    HTML.push('<option value="' + states[index] + '">' + states[index] + '</option>');
                }

            }
            HTML.push('</select>');

            return HTML.join('');
        },
        CommissionMode: function (Index, isCancelled) {

            BrokerEdit = $('#jxProducerMode_' + Index),
            CurrentBroker = BrokerEdit.data('producer'),
            ProducerRepEdit = $('#jxRepAgentMode_' + Index),
            CurrentProducerRep = ProducerRepEdit.data('repagent'),
            RateEdit = $('#jxRateMode_' + Index),
            CurrentRate = RateEdit.data('rate'),
            CustomerEdit = $('#jxCustomerMode_' + Index),
            CurrentCustomer = CustomerEdit.data('customerno'),
            PrimaryEdit = $('#jxPrimaryMode_' + Index),
            CurrentPrimary = PrimaryEdit.data('primary'),
            RatingEdit = $('#jxRatingMode_' + Index),
            CurrentRating = RatingEdit.data('rating'),
            EditMode = $('#jxEditMode_' + Index),
            DeleteMode = $('#jxDeleteMode_' + Index),
            CurrentCommissionID = DeleteMode.data('commissionid');

            if (isCancelled == null) {
                isCancelled = false;
            }

            if (isCancelled) {
                // readonly
                BrokerEdit.html(CurrentBroker);
                RateEdit.html(CurrentRate);
                ProducerRepEdit.html(CurrentProducerRep);
                CustomerEdit.html(CurrentCustomer);
                PrimaryEdit.html(CurrentPrimary);
                RatingEdit.html(CurrentRating);
                EditMode.html('<input type="button" class="jxEditCommission buttonWidth" data-commissionindex="' + Index + '" data-commissionid="' + CurrentCommissionID + '" value=" Edit " />');
                DeleteMode.html('<input type="button" class="jxDeleteCommission buttonWidth" data-commissionid="' + CurrentCommissionID + '" value=" Delete " />');
            } else {
                // editable
                if (CHSI.UW.MuniUW_Details.Cache.Producers) {
                    var ProducerList = CHSI.UW.MuniUW_Details.Events.ConstructProducersList(CHSI.UW.MuniUW_Details.Cache.Producers, 'id="jxCommissionProducers_' + Index + '"', Index);
                    BrokerEdit.html(ProducerList);
                } else {
                    CHSI.UW.MuniUW_Details.Calls.GetProducers(function (data) {
                        var ProducerList = CHSI.UW.MuniUW_Details.Events.ConstructProducersList(data, 'id="jxCommissionProducers_' + Index + '"');
                        BrokerEdit.html(ProducerList);
                    });
                }

                if (CurrentPrimary == 'Y') {
                    PrimaryEdit.html('<input type="checkbox" checked="checked" />');
                } else {
                    PrimaryEdit.html('<input type="checkbox" />');
                }

                if (CurrentRating == 'Y') {
                    RatingEdit.html('<input type="checkbox" checked="checked" />');
                } else {
                    RatingEdit.html('<input type="checkbox" />');
                }

                ProducerRepEdit.html('<input type="text" value="' + CurrentProducerRep + '" />');
                CustomerEdit.html('<input type="text" value="' + CurrentCustomer + '" />');
                RateEdit.html('<input type="text" value="' + CurrentRate + '" />');
                EditMode.html('<input class="jxCancelCommission buttonWidth" data-commissionindex="' + Index + '"  type="button" value=" Cancel " />');
                DeleteMode.html('<input class="jxSaveCommission buttonWidth" data-commissionindex="' + Index + '" data-commissionid="' + CurrentCommissionID + '" type="button" value=" Save " />');
            }
        },
        PayrollMode: function (Index, isCancelled) {

            StateEdit = $('#jxStateMode_' + Index),
            CurrentState = StateEdit.data('state'),
            ClassCodeEdit = $('#jxClassCodeMode_' + Index),
            CurrentClassCode = ClassCodeEdit.data('classcode'),
            AltClassEdit = $('#jxAltClassMode_' + Index),
            CurrentAltClassCode = AltClassEdit.data('altclasscode'),
            EmployeesEdit = $('#jxEmployeesMode_' + Index),
            CurrentEmployees = EmployeesEdit.data('employees'),
            DescriptionEdit = $('#jxDescriptionMode_' + Index),
            CurrentDescription = DescriptionEdit.data('description'),
            PayrollEdit = $('#jxPayrollMode_' + Index),
            CurrentPayroll = PayrollEdit.data('payroll'),
            PayrollBaseRateEdit = $('#jxPayrollBaseRateMode_' + Index),
            CurrentBaseRate = PayrollBaseRateEdit.data('baserate'),
            GrossPremEdit = $('#jxGrossPremMode_' + Index),
            CurrentGrossPrem = GrossPremEdit.data('gross'),
            EditMode = $('#jxPayrollEditMode_' + Index),
            DeleteMode = $('#jxPayrollDeleteMode_' + Index),
            CurrentPayrollID = DeleteMode.data('detailid');

            if (isCancelled == null) {
                isCancelled = false;
            }

            if (isCancelled) {
                // readonly
                StateEdit.html(CurrentState);
                ClassCodeEdit.html(CurrentClassCode);
                AltClassEdit.html(CurrentAltClassCode);
                EmployeesEdit.html(CurrentEmployees);
                DescriptionEdit.html(CurrentDescription);
                PayrollEdit.html(CurrentPayroll);
                PayrollBaseRateEdit.html(CurrentBaseRate);
                GrossPremEdit.html(CurrentGrossPrem);
                EditMode.html('<input type="button" data-payrollindex="' + Index + '" class="jxEditPayroll buttonWidth" value=" Edit " />');
                DeleteMode.html('<input type="button" data-payrollid="' + CurrentPayrollID + '" class="jxDeletePayroll buttonWidth" value=" Delete " />');
            } else {
                // editable
                if (CHSI.UW.MuniUW_Details.Cache.States) {
                    var StateList = CHSI.UW.MuniUW_Details.Events.ConstructStateList(CHSI.UW.MuniUW_Details.Cache.States, 'jxPayrollStates_' + Index, 'validateClassCode', Index);
                    StateEdit.html(StateList);
                } else {
                    CHSI.UW.MuniUW_Details.Calls.GetStates(function (data) {
                        var StateList = CHSI.UW.MuniUW_Details.Events.ConstructStateList(data, 'jxPayrollStates_' + Index, 'validateClassCode');
                        StateEdit.html(StateList);
                    });
                }
                CHSI.UW.MuniUW_Details.Cache.isClassCodeValid = true;
                ClassCodeEdit.html('<input type="text" class="validateClassCode" data-index="' + Index + '" value="' + CurrentClassCode + '" />');
                AltClassEdit.html('<input type="text" value="' + CurrentAltClassCode + '" />');
                EmployeesEdit.html('<input type="text" value="' + CurrentEmployees + '" />');
                //DescriptionEdit.html('<input type="text" style="width: 330px;" value="' + CurrentDescription + '" />');
                DescriptionEdit.html(function () {
                    var HTML = [],
                        DescriptionID = $('#jxDescriptionMode_' + Index).data('descriptionid');
                    HTML.push('<select data-index="' + Index + '" class="validateClassCode">');
                    if (DescriptionID == 0) {
                        HTML.push('<option value="0" selected="selected">Default</option>');
                    } else {
                        HTML.push('<option value="0">Default</option>');
                    }
                    if (DescriptionID == 1) {
                        HTML.push('<option value="1 selected="selected"">Alt 1</option>');
                    } else {
                        HTML.push('<option value="1">Alt 1</option>');
                    }
                    if (DescriptionID == 2) {
                        HTML.push('<option value="2" selected="selected">Alt 2</option>');
                    } else {
                        HTML.push('<option value="2">Alt 2</option>');
                    }
                    if (DescriptionID == 3) {
                        HTML.push('<option value="3" selected="selected">Alt 3</option>');
                    } else {
                        HTML.push('<option value="3">Alt 3</option>');
                    }
                    HTML.push('</select>');
                    return HTML.join('');
                });
                PayrollEdit.html('<input type="text" value="' + CurrentPayroll + '" />');
                PayrollBaseRateEdit.html('<input type="text" value="' + CurrentBaseRate + '" />');
                EditMode.html('<input class="jxCancelPayroll buttonWidth" type="button" data-payrollindex="' + Index + '" value=" Cancel " />');
                DeleteMode.html('<input class="jxSavePayroll buttonWidth" type="button" data-payrollindex="' + Index + '" data-payrollid="' + CurrentPayrollID + '" value=" Save " />');
            }
        },
        PersistCommission: function (CommissionID, Index) {
            var Commission = {},
                QuoteObject = CHSI.UW.MuniQuote.Cache.QuoteObject;

            if (CommissionID == null) { // new commission
                Commission.QuoteID = CHSI.UW.MuniUW_Details.Cache.QuoteID;
                Commission.ProducerID = $('#jxNewCommissionProducers').val();
                Commission.ClientID = CHSI.UW.MuniUW_Details.Cache.ClientID;
                Commission.Status = 'Active';
                Commission.PolicyPeriod = QuoteObject.PolicyPeriod;
                Commission.EffectiveDate = QuoteObject.EffectiveDate;
                Commission.ExpirationDate = QuoteObject.ExpirationDate;
                Commission.PayCommission = 'N';
                Commission.ProducerCustomerID = $('#jxNewCommissionCustNo').val();
                Commission.ProducerCSR = null;
                Commission.Comments = null;
                Commission.CommissionRate = $('#jxNewCommissionRate').val();
                Commission.UseRating = $('#jxNewCommissionRating').is(':checked') ? 'Y' : 'N';
                Commission.PrimaryProducer = $('#jxNewCommissionPrimary').is(':checked') ? 'Y' : 'N';
                Commission.ProducerRep = $('#jxNewCommissionRepAgent').val();
            } else {
                Commission.CommissionID = CommissionID;
                Commission.QuoteID = CHSI.UW.MuniUW_Details.Cache.QuoteID;
                Commission.ProducerID = $('#jxCommissionProducers_' + Index).val();
                Commission.ClientID = CHSI.UW.MuniUW_Details.Cache.ClientID;
                Commission.Status = 'Active';
                Commission.PolicyPeriod = QuoteObject.PolicyPeriod;
                Commission.EffectiveDate = QuoteObject.EffectiveDate;
                Commission.ExpirationDate = QuoteObject.EffectiveDate;
                Commission.PayCommission = $('#jxEditMode_' + Index).data('paycommission');
                Commission.ProducerCustomerID = $('#jxEditMode_' + Index).data('producercustomerid');
                Commission.ProducerCSR = $('#jxEditMode_' + Index).data('producercsr');
                Commission.Comments = $('#jxEditMode_' + Index).data('comments');
                Commission.CommissionRate = $('#jxRateMode_' + Index + '> input').val();
                Commission.UseRating = $('#jxRatingMode_' + Index + '> input').is(':checked') ? 'Y' : 'N';
                Commission.PrimaryProducer = $('#jxPrimaryMode_' + Index + '> input').is(':checked') ? 'Y' : 'N';
                Commission.ProducerRep = $('#jxRepAgentMode_' + Index + '> input').val();
            }
            return Commission;
        },
        PersistPayroll: function (PayrollID, Index) {
            var Payroll = {},
                QuoteObject = CHSI.UW.MuniQuote.Cache.QuoteObject,
                ClassCodeFilters = {};

            if (PayrollID == null) { // new payroll
                Payroll.QuoteID = CHSI.UW.MuniUW_Details.Cache.QuoteID;
                Payroll.ClassCodeID = CHSI.UW.MuniUW_Details.Cache.ValidationObject.ClassCodeID;
                Payroll.AltClassCode = $('#jxNewAltClassCode').val();
                Payroll.State = $('#jxNewPayrollStates').val();
                Payroll.StartDate = QuoteObject.EffectiveDate;
                Payroll.EndDate = QuoteObject.ExpirationDate;
                Payroll.BaseRate = $('#jxNewPayrollBaseRate').val();
                Payroll.xMod = "1";
                Payroll.AdjustmentFactor = 0;
                Payroll.Commission = CHSI.UW.MuniQuote.Cache.CommissionsObject.TotalCommission;
                Payroll.NumberOfEE = $('#jxNewEmployees').val();
                Payroll.UseDescription = $('#jxNewDescrption').val();
                Payroll.PayrollAmount = $('#jxNewPayroll').val();
                Payroll.ParentDetailID = QuoteObject.parentappid;
                // original Rate logic - Payroll.Rate = Payroll.BaseRate * (1 + (Payroll.Commission / 100)) * (1 + (Payroll.AdjustmentFactor / 100)) * Payroll.xMod;
                Payroll.Rate = CHSI.Math.SafeMultiply(Payroll.BaseRate, (CHSI.Math.SafeAdd(1, (CHSI.Math.SafeDivide(Payroll.Commission, 100)))), (CHSI.Math.SafeAdd(1, (CHSI.Math.SafeDivide(Payroll.AdjustmentFactor, 100)))), Payroll.xMod);
            } else {
                Payroll.DetailID = $('#jxPayrollEditMode_' + Index).data('detailid');
                Payroll.QuoteID = CHSI.UW.MuniUW_Details.Cache.QuoteID;
                Payroll.ClassCodeID = $('#jxClassCodeMode_' + Index).data('classcodeid');
                Payroll.State = $('#jxPayrollStates_' + Index).val();
                Payroll.AltClassCode = $('#jxAltClassMode_' + Index + ' > input').val();
                Payroll.StartDate = QuoteObject.EffectiveDate;
                Payroll.EndDate = QuoteObject.ExpirationDate;
                Payroll.BaseRate = $('#jxPayrollBaseRateMode_' + Index + ' > input').val();
                Payroll.xMod = $('#jxPayrollEditMode_' + Index).data('xmod');
                Payroll.AdjustmentFactor = $('#jxPayrollEditMode_' + Index).data('adjustmentfactor');
                Payroll.Commission = CHSI.UW.MuniQuote.Cache.CommissionsObject.TotalCommission;
                Payroll.NumberOfEE = $('#jxEmployeesMode_' + Index + ' > input').val();
                Payroll.UseDescription = $('#jxDescriptionMode_' + Index + ' > select').val();
                Payroll.PayrollAmount = $('#jxPayrollMode_' + Index + ' > input').val();
                Payroll.ParentDetailID = QuoteObject.parentappid;
                Payroll.Rate = CHSI.Math.SafeMultiply(Payroll.BaseRate, (CHSI.Math.SafeAdd(1, (CHSI.Math.SafeDivide(Payroll.Commission, 100)))), (CHSI.Math.SafeAdd(1, (CHSI.Math.SafeDivide(Payroll.AdjustmentFactor, 100)))), Payroll.xMod);

            }
            return Payroll;
        },
        ClassCodeValidationResponse: function (ClassCodeFilters) {
            CHSI.UW.MuniUW_Details.Calls.ValidateClassCode(ClassCodeFilters, function (data) {
                ClassCodeResult = data;

                var ErrorMsg = $('#jxPayrollErrorMessage');

                if (ClassCodeResult.Description == 'That is not a valid Class Code') {
                    ErrorMsg.css('color', 'red');
                    ErrorMsg.text(ClassCodeResult.Description);
                    ErrorMsg.show();
                    CHSI.UW.MuniUW_Details.Cache.isClassCodeValid = false;
                } else {
                    ErrorMsg.css('color', 'green');
                    ErrorMsg.text(ClassCodeResult.Description);
                    ErrorMsg.show();
                    CHSI.UW.MuniUW_Details.Cache.isClassCodeValid = true;
                }
            });
        }
    },

    Calls: {
        ValidateClassCode: function (ClassCodeFilters, successFunction) {
            var Options = {};
            Options.URL = '/api/UW/ClassCode/AdvancedGetDescriptionByClassCode?ClassCode=' + ClassCodeFilters.ClassCode + '&GroupID=' + ClassCodeFilters.GroupID + '&EffectiveDate=' + ClassCodeFilters.EffectiveDate + '&State=' + ClassCodeFilters.State + '&DescriptionIndex=' + ClassCodeFilters.DescriptionIndex;
            Options.Success = function (data) {
                if (successFunction) {
                    CHSI.UW.MuniUW_Details.Cache.ValidationObject = data;
                    successFunction(data);
                } else {
                    CHSI.UW.MuniUW_Details.Cache.ValidationObject = data;
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        AddQuoteCoverageLine: function (QuoteID, CoverageLineID) {

            var Success = function (e) {
                CHSI.UW.MuniUW_Details.Calls.GetQuoteDetails(QuoteID);
            }

            CHSI.UW.MuniQuote.AddQuoteCoverageLine(QuoteID, CoverageLineID, Success);
        },
        GetQuoteDetails: function (QuoteID) {
            if (!QuoteID) {
                QuoteID = CHSI.UW.MuniUW_Details.Cache.QuoteID;
            }
            var Success = function (e) {
                console.log('Getting Quote Details');
                CHSI.UW.MuniQuote.Cache.QuoteList = e;
                CHSI.UW.MuniQuote.Cache.CoverageLineObject = e[0];
                CHSI.UW.MuniQuote.Cache.QuoteObject = e[0];
                if (CHSI.UW.MuniQuote.Cache.QuoteObject.QuoteStatus && CHSI.UW.MuniQuote.Cache.QuoteObject.QuoteStatus.Editable == 'N') {
                    CHSI.UW.MuniUW_Details.Cache.Editable = 'N';
                }
                
                CHSI.UW.MuniUW_Details.Cache.GroupID = CHSI.UW.MuniQuote.Cache.QuoteObject.GroupID;
                CHSI.UW.MuniQuote.Cache.QuoteObject.EffectiveDate = CHSI.UW.MuniQuote.Cache.QuoteObject.EffectiveDate;
                CHSI.UW.MuniQuote.Cache.QuoteObject.ExpirationDate = CHSI.UW.MuniQuote.Cache.QuoteObject.ExpirationDate;
                CHSI.UW.MuniUW_Details.Binds.BindCoverageLines();
                CHSI.UW.MuniUW_Details.Binds.BindQuoteDetails();
                CHSI.UW.MuniUW_Details.Events.ValidateUseEstimatedPayroll();
                if (CHSI.UW.MuniUW_Details.Cache.UseEstimatedPayroll) {
                    CHSI.UW.MuniUW_Details.Calls.GetPayroll();
                }

                CHSI.UW.MuniUW_Details.Calls.GetUWRule('PDF', function (e) { if (e && e.RuleValue) { CHSI.UW.MuniUW_Details.Cache.PDF = e.RuleValue; } });
                CHSI.UW.MuniUW_Details.Calls.GetUWRule('PDL', function (e) { if (e && e.RuleValue) { CHSI.UW.MuniUW_Details.Cache.PDL = e.RuleValue; } });
                CHSI.UW.MuniUW_Details.Calls.GetUWRule('PDMax', function (e) { if (e && e.RuleValue) { CHSI.UW.MuniUW_Details.Cache.PDMax = e.RuleValue; } });


                var SecOptions = {},
                    isVerified = false;

                SecOptions.PermissionID = 14;
                SecOptions.Success = function (e) {
                    isVerified = e;
                    console.log('has security permission 14');
                    if (isVerified) {
                        CHSI.UW.MuniQuote.MenuController(CHSI.UW.MuniQuote.Cache.CoverageLineObject, '#jxMenuContainer');
                    }
                }
                CHSI.Security.HasPermission(SecOptions);
                
        
                
            }

            CHSI.UW.MuniQuote.GetQuoteDetails(QuoteID, Success);

        },
        GetProducers: function (successFunction) {
            var Options = {};
            Options.URL = '/api/CRM/Producer/GetProducerByGroup?GroupID=' + CHSI.UW.MuniUW_Details.Cache.GroupID;
            Options.Success = function (data) {
                if (successFunction) {
                    successFunction(data);
                    CHSI.UW.MuniUW_Details.Cache.Producers = data;
                } else {
                    CHSI.UW.MuniUW_Details.Cache.Producers = data;
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        GetStates: function (successFunction) {
            var Options = {};
            Options.URL = '/api/UW/ClassCode/GetClassCodeStates?GroupName=' + CHSI.UW.MuniUW_Details.Cache.GroupID;
            Options.Success = function (data) {
                if (successFunction) {
                    successFunction(data);
                    CHSI.UW.MuniUW_Details.Cache.States = data;
                } else {
                    CHSI.UW.MuniUW_Details.Cache.States = data;
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        GetCommissions: function (successFunction) {
            var Options = {};
            Options.URL = '/api/UW/ClientCommissions/GetQuoteCommissions?ClientID=' + CHSI.UW.MuniUW_Details.Cache.ClientID + '&QuoteID=' + CHSI.UW.MuniUW_Details.Cache.QuoteID;
            Options.Success = function (data) {
                if (successFunction) {
                    successFunction(data);
                }

                var totalCommission = 0,
                    totalRatableCommission = 0;
                for (var index = 0; index < data.length; index++) {
                    totalCommission = CHSI.Math.SafeAdd(data[index].CommissionRate, totalCommission);
                    CommissionRate = data[index].CommissionRate;

                    if (data[index].UseRating == 'Y') {
                        totalRatableCommission = CHSI.Math.SafeAdd(data[index].CommissionRate, totalRatableCommission);
                    }
                }

                CHSI.UW.MuniQuote.Cache.CommissionsObject = data;
                CHSI.UW.MuniQuote.Cache.CommissionsObject.TotalCommission = totalCommission;
                CHSI.UW.MuniQuote.Cache.CommissionsObject.TotalRatableCommission = totalRatableCommission;
                CHSI.UW.MuniQuote.SaveQuoteDetail(CHSI.UW.MuniUW_Details.Cache.QuoteID, 4, totalCommission);
                CHSI.UW.MuniQuote.SaveQuoteDetail(CHSI.UW.MuniUW_Details.Cache.QuoteID, 5, totalRatableCommission);
                CHSI.UW.MuniUW_Details.Binds.BindCommissions();
            };
            CHSI.Common.Ajax.Get(Options);
        },
        GetPayroll: function (successFunction) {
            var Options = {};
            Options.URL = '/api/UW/MonthlyActualPayrollQuote/Get?QuoteID=' + CHSI.UW.MuniUW_Details.Cache.QuoteID;
            Options.Success = function (data) {
                if (successFunction) {
                    successFunction(data);
                }

                var totalEEs = 0,
                    totalPayroll = 0,
                    totalGrossPrem = 0;
                for (var index = 0; index < data.length; index++) {
                    totalEEs += data[index].NumberOfEE;
                    totalPayroll += data[index].PayrollAmount;
                    totalGrossPrem += CHSI.UW.MuniQuote.CalculateGrossPrem(data[index].PayrollAmount, data[index].BaseRate);
                }

                CHSI.UW.MuniUW_Details.Cache.PayrollObject = data;
                CHSI.UW.MuniUW_Details.Cache.PayrollObject.TotalEmployees = totalEEs;
                CHSI.UW.MuniUW_Details.Cache.PayrollObject.TotalPayroll = totalPayroll;
                CHSI.UW.MuniUW_Details.Cache.PayrollObject.TotalGrossPremium = totalGrossPrem;
                CHSI.UW.MuniQuote.SaveQuoteDetail(CHSI.UW.MuniUW_Details.Cache.QuoteID, 1, totalPayroll);
                CHSI.UW.MuniQuote.SaveQuoteDetail(CHSI.UW.MuniUW_Details.Cache.QuoteID, 6, totalGrossPrem);
                CHSI.UW.MuniUW_Details.Binds.BindPayroll();
            };
            CHSI.Common.Ajax.Get(Options);
        },
        DeleteCoverageLine: function (QuoteID, ProductLineID) {

            var Success = function (e) {
                CHSI.UW.MuniUW_Details.Calls.GetQuoteDetails(QuoteID);
            }

            CHSI.UW.MuniQuote.DeleteQuoteCoverageLine(QuoteID, ProductLineID, Success);
        },
        DeleteCommission: function (ID, successFunction) {
            var Options = {};
            Options.URL = '/api/UW/ClientCommissions/DeleteClientCommissionByCommissionID?CommissionID=' + ID;
            Options.Success = function (data) {
                if (successFunction) {
                    successFunction(data);
                }
            };
            CHSI.Common.Ajax.Delete(Options);
        },
        DeletePayroll: function (ID, successFunction) {
            var Options = {};
            Options.URL = '/api/UW/MonthlyActualPayrollQuote/Delete?DetailID=' + ID;
            Options.Success = function (data) {
                if (successFunction) {
                    successFunction(data);
                }
            };
            CHSI.Common.Ajax.Delete(Options);
        },
        SaveQuoteProperty: function (PropertyName, NewValue, successFunction) {
            var Success = function (e) {
                CHSI.UW.MuniUW_Details.Calls.GetQuoteDetails(CHSI.UW.MuniUW_Details.Cache.QuoteID);
                if (successFunction) {
                    successFunction();
                }
            }

            CHSI.UW.MuniQuote.SaveQuoteProperty(PropertyName, NewValue, Success);
        },
        SaveCommission: function (successFunction) {
            var Options = {};
            Options.URL = '/api/UW/ClientCommissions/SaveClientCommission';
            Options.Data = CHSI.UW.MuniUW_Details.Cache.SavedCommissionObject;
            Options.Success = function (data) {
                if (successFunction) {
                    successFunction(data);
                }
                CHSI.UW.MuniUW_Details.Cache.NewestCommissionID = data;
            };
            CHSI.Common.Ajax.Post(Options);
        },
        SavePayroll: function (successFunction) {
            var Options = {};
            Options.URL = '/api/UW/MonthlyActualPayrollQuote/Save';
            Options.Data = CHSI.UW.MuniUW_Details.Cache.SavedPayrollObject;
            Options.Success = function (data) {
                if (successFunction) {
                    successFunction(data);
                }
                CHSI.UW.MuniUW_Details.Cache.NewestPayrollID = data;
            };
            CHSI.Common.Ajax.Post(Options);
        },
        GetUWRule: function (Code, Success) {
            var QuoteType = CHSI.UW.MuniQuote.Cache.QuoteObject.QuoteType;
            var GroupID = CHSI.UW.MuniQuote.Cache.QuoteObject.GroupID;
            var PolicyPeriod = CHSI.UW.MuniQuote.Cache.QuoteObject.PolicyPeriod;
            CHSI.UW.MuniQuote.GetUWRule(QuoteType, GroupID, PolicyPeriod, Code, Success);
        }
    }

}
