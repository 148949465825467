
class ExceptionDetail {

    eObject: GUI.Smart.SmartObject;
    exceptions: Exceptions;
    cdt1: GUI.Tables.DTables = new GUI.Tables.DTables; 
    
    constructor(eID: number) {
        this.exceptions = new Exceptions();

        var _self = this;

        this.eObject = this.exceptions.eObject;        
        this.exceptions.getExceptionsByID(eID, function (data) {
            _self.buildExceptionDetailColumnDefinition(data);            
        });                
    }

    events(): void {
        var _self = this;    

        _self.cdt1.$container.on('click', (e) => {            
            var tr = $(e.target).closest('tr');
            var row = _self.cdt1.$grid.row(tr);

            if (row.child.isShown()) {
                row.child.hide();
                tr.removeClass('ui-icon-triangle-1-n');
            } else {                
                row.child(_self.masterDetailExceptionView(row)).show();
                tr.addClass('ui-icon-triangle-1-s');
            }            
        });

        _self.prepareFields();
    }

    prepareFields(): void {
        var _self = this;
        console.log(_self.cdt1.data.Values);
        $('#jxCaptureCount').html(_self.cdt1.data.Values.length.toString());
        $('#jxExceptionID').html(_self.cdt1.data.Values[0][0].toString());
    }

    masterDetailExceptionView(rowObject: any): string {        
        console.log(rowObject);
        var data = rowObject.row().data();
        var index = rowObject.row().index();
        var HTML = [];

        HTML.push('<table>');
        HTML.push('<tr><td valign="top"><span class="mdBoldTitle">Message</span></td><td><div style="border:1px solid gray; width: 500px; height: 100px; overflow: hidden;">' + data[3] + '</div></td></tr>');
        HTML.push('<tr><td valign="top"><span class="mdBoldTitle">Internal Message</span></td><td><div style="border: 1px solid gray; width: 500px; height: 100px; overflow: hidden;">' + data[4] + '</div></td></tr>');        
        HTML.push('</table>');               

        return HTML.join('');
    }

    buildExceptionDetailColumnDefinition(exceptionData: GUI.Smart.SmartObject) {
        var _self = this;
        var Column = null;
        var ClientColumnDefinition = null;                

        for (var i = 0; i < exceptionData.Columns.length; i++) {

            if (exceptionData.Columns[i].title == 'Message' || exceptionData.Columns[i].title == 'InternalMessage' ) {
                exceptionData.Columns[i].visible = false;
            }

        }
        
        Column = new GUI.Smart.SmartColumnObject;
        Column.title = "";
        Column.data = null;
        Column.class = 'details-control';
        Column.orderable = false;
        exceptionData.Columns.push(Column);               
             
        ClientColumnDefinition = new GUI.Tables.ColumnDefinitions;
        ClientColumnDefinition.targets = 8;
        ClientColumnDefinition.render = function (data, type, row) {
            return '<span class="ui-icon ui-icon-triangle-1-s" style="float: right;">Edit</span>';
        }
        _self.cdt1.dtObject.colDefCollection.push(ClientColumnDefinition);                        
        
        _self.buildExceptionDetailTable(exceptionData);
    }

    buildExceptionDetailTable(exceptionData: GUI.Smart.SmartObject) {          
        var _self = this;        

        _self.cdt1.containerName = 'jxExceptionDetailContainer';
        _self.cdt1.tableName = 'jxExceptionDetailGrid'
        _self.cdt1.data = exceptionData;
        _self.cdt1.dtObject.globalFilter = false;        
        _self.cdt1.dtObject.createdRow = function (row, data, index) {
            $('td', row).eq(0).addClass(_self.exceptions.sourceTypeColorMarker(data[1]));
        }
        _self.cdt1.buildTable();

        _self.events();
    }
 }