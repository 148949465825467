var Template_PendingSubsidies = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<table class=\"dtable sortable\">\r\n    <tr>\r\n        <td style=\"width: 225px\">Member</td>\r\n        <td style=\"width: 150px\">Subsidy</td>\r\n        <td style=\"width: 100px\">Type</td>\r\n        <td style=\"width: 100px\">Request Date</td>\r\n        <td style=\"text-align: right; width:100px\">Amount</td>\r\n        <td style=\"text-align: right; width: 75px\"># of Files</td>\r\n        <td style=\"width: 50px\" class=\"sorttable_nosort\"></td>\r\n    </tr>\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.PendingRequest : depth0), {"name":"each","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</table>\r\n";
},"2":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;
  return "    <tr>\r\n        <td style=\"width: 225px\">"
    + escapeExpression(lambda((depth0 != null ? depth0.ClientName : depth0), depth0))
    + "</td>\r\n        <td style=\"width: 150px\">"
    + escapeExpression(lambda((depth0 != null ? depth0.SubsidyName : depth0), depth0))
    + "</td>\r\n        <td style=\"width: 100px\">"
    + escapeExpression(lambda((depth0 != null ? depth0.TransactionTypeName : depth0), depth0))
    + "</td>\r\n        <td style=\"width: 100px\">"
    + escapeExpression(((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || helperMissing).call(depth0, (depth0 != null ? depth0.RequestDate : depth0), {"name":"CHSIDate","hash":{},"data":data})))
    + "</td>\r\n        <td style=\"text-align: right; width:100px\">"
    + escapeExpression(((helpers.FormatCurrency || (depth0 && depth0.FormatCurrency) || helperMissing).call(depth0, (depth0 != null ? depth0.AmountRequested : depth0), true, {"name":"FormatCurrency","hash":{},"data":data})))
    + "</td>\r\n        <td style=\"text-align: right; width: 75px\">"
    + escapeExpression(lambda((depth0 != null ? depth0.AttachmentCount : depth0), depth0))
    + "</td>\r\n        <td style=\"width: 50px;text-align:center;\">[<a href=\"javascript: ShowPopup('SubsidyTransaction', '/Billing/Subsidy/TransactionModal.aspx?SubsidyID="
    + escapeExpression(lambda((depth0 != null ? depth0.SubsidyID : depth0), depth0))
    + "&ClientID="
    + escapeExpression(lambda((depth0 != null ? depth0.ClientID : depth0), depth0))
    + "', 'Subsidy Transaction', 800, 800, BuildPendingRequestGrid, true);\" class=\"MiniLink\">View</a>]</td>\r\n    </tr>\r\n";
},"4":function(depth0,helpers,partials,data) {
  return "<p>No pending requests.</p>\r\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "﻿";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.PendingRequest : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});