
module PL.Que.APIs {
    
    export class FormAPI {          
        
        FormID: number;       
        private static _routePrefix: string = '/API/QUE/Form/';
        
        public static getFormQuestionsByIndex(designID: number, sectionIndex: number, elementsOnly: boolean): JQueryDeferred<PL.Que.Models.Design.Form> {
            var $result = $.Deferred();
            $.get(this._routePrefix + 'GetFormQuestionByIndex').done((data) => {
                $result.resolve(data);
            });
            return $result;
        }    

        private static saveForm(formObject: PL.Que.Models.QuestionnaireBase, onSuccess?: (data: any) => any): PL.Que.Models.QuestionnaireBase {
            var formObject: PL.Que.Models.QuestionnaireBase;
            var ajax = new IO.Ajax;
            
            var settings = {
                URL: '/API/QUE/Form/SaveForm',                
                Success: function (data) {                                        
                    if (onSuccess){
                        onSuccess(data);
                    }
                },
                Data: formObject,
                Error: function () {
                    console.log('No exceptions were found.');
                }
            };
            ajax.Post(settings);
                                    
            return formObject;
        }

       static getFormDetails(successFunction?: (data: any) => any): void {
            var formCollection: GUI.Smart.SmartObject;
            var ajax = new IO.Ajax;

            var settings = {
                URL: '/API/QUE/Form/GetForms',
                Success: function (data) {                    
                    successFunction(data);
                },                
                Error: function () {
                    console.log('No forms were found.');
                }
            };
            ajax.Get(settings);

            //return formCollection;
        }

        getCompleteFormByFormID(successFunction?: any): void {
            var formCollection: GUI.Smart.SmartObject;
            var ajax = new IO.Ajax;

            var settings = {
                URL: '/API/QUE/Form/GetCompleteFormByFormID?FormID=' + this.FormID,
                Success: function (data) {
                    formCollection = data;
                    successFunction(data);
                },
                Error: function () {
                    console.log('No forms were found.');
                }
            };
            ajax.Get(settings);

            //return formCollection;
        }

        getFormSections(successFunction?: any): void {
            var formCollection: GUI.Smart.SmartObject;
            var ajax = new IO.Ajax;

            var settings = {
                URL: '/API/QUE/Form/GetForms',
                Success: function (data) {
                    formCollection = data;
                    successFunction(data);
                },
                Error: function () {
                    console.log('No forms were found.');
                }
            };
            ajax.Get(settings);

            //return formCollection;
        }

        getFormElements(successFunction?: any): void {
            var formCollection: GUI.Smart.SmartObject;
            var ajax = new IO.Ajax;

            var settings = {
                URL: '/API/QUE/Form/GetForms',
                Success: function (data) {
                    formCollection = data;
                    successFunction(data);
                },
                Error: function () {
                    console.log('No forms were found.');
                }
            };
            ajax.Get(settings);

            //return formCollection;
        }

        getFormActions(successFunction?: any): void {
            var formCollection: GUI.Smart.SmartObject;
            var ajax = new IO.Ajax;

            var settings = {
                URL: '/API/QUE/Form/GetForms',
                Success: function (data) {
                    formCollection = data;
                    successFunction(data);
                },
                Error: function () {
                    console.log('No forms were found.');
                }
            };
            ajax.Get(settings);

            //return formCollection;
        }

        static getDataStructures(onSuccess?: (data: any) => any): void {            
            var ajax = new IO.Ajax;

            var settings = {
                URL: '/API/QUE/DataStructure/GetDataStructures',
                Success: function (data) {                    
                    if (onSuccess) {
                        onSuccess(data);
                    }
                },
                Error: function () {
                    console.log('No forms were found.');
                }
            };
            ajax.Get(settings);            
        }        

        //getGridFormsObject(onSuccess?: (data: any) => any): void {            
        //    PL.Que.API.FormAPI.getFormDetails(function (data) {
        //        if (onSuccess) {
        //            onSuccess(data);
        //        }
        //    });                            
        //}

        static getCompleteFormByFormID(FormID: number, onSuccess?: (data: any) => any): void {
            
            $.get('/API/QUE/Form/GetCompleteFormByFormID?FormID=' + FormID).done(function () { }).then(function (formObject) {
                if (onSuccess)
                {
                    onSuccess(formObject);
                }
            });
        }

        static saveNewForm(newFormObject: PL.Que.Models.QuestionnaireBase, onSuccess?: (data: any) => any): void {
            FormAPI.saveForm(newFormObject, function (data) {
                if (onSuccess) {
                    onSuccess(data);
                }
            });
        }

        static saveCompleteFormObject(formObject: any, optionalFormObject?: PL.Que.Models.QuestionnaireBase, onSuccess?: (data) => any): void {            

            if (!optionalFormObject) {
                FormAPI.saveForm(formObject, function (data) {
                    if (onSuccess) {
                        onSuccess(data);
                    }
                });
            } else {
                FormAPI.saveForm(optionalFormObject, function (data) {
                    if (onSuccess) {
                        onSuccess(data);
                    }
                });
            }            
        }

        getFormInformation(): void {
            //var _self = this;
            //var formObject: PL.Que.Model.QuestionnaireBase = Que.API.FormAPI.getFormDetails();

            //_self.formData = formObject;
            //_self.buildFormColumnDefinitions(formObject);
        }

    }

} 