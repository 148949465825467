module PL.UW.LossHistory.Views{
    export class xMod_LossHistoryEdit_PriorCoverage {

        $lossRunDate: JQuery;
        $policyPeriod: JQuery;
        $fromDate: JQuery;
        $toDate: JQuery;
        $carrier: JQuery;
        $premium: JQuery;
        $xMod: JQuery;
        $overallComments: JQuery;

        constructor(isJQueryObject?: boolean) {
            this.$lossRunDate = $('#jxLossRunDate');
            this.$policyPeriod = $('#jxPolicyPeriod');
            this.$fromDate = $('#jxFromDate');
            this.$toDate = $('#jxToDate');
            this.$carrier = $('#jxCarrier');
            this.$premium = $('#jxPremium');
            this.$xMod = $('#jxMod');
            this.$overallComments = $('#jxOverallComments');
        }
    }
} 