module PL.Claims.Models {

    export class FormCategory {

        FormCategoryID: number;
        FormTypeID: number|string;
        CategoryName: string;
        Status: string;
        SortOrder: number;
        FieldCount: number;
        FormFields: PL.Claims.Models.FormField[];
        CreateUser: string;
        CreateTsmp: Date;
        UpdateUser: string;
        UpdateTmsp: Date;

        // internal property to helps us identify the forms category phase - claim intake form
        _Phase: Enums.ClaimIntakeForm_Phase;
        _Status: Enums.ClaimIntakeCategory_Status;

        constructor(category?: FormCategory) {
            this.FormFields = new Array<PL.Claims.Models.FormField>();
            if (category != undefined && category.FormFields) {
                category.FormFields.forEach((ffO, ffI) => {
                    this.FormFields.push(new FormField(ffO));
                });
            }
            this.FormCategoryID = category == undefined ? null : category.FormCategoryID;
            this.FormTypeID = category == undefined ? null : category.FormTypeID;
            this.CategoryName = category == undefined ? null : category.CategoryName;
            this.Status = category == undefined ? null : category.Status;
            this.SortOrder = category == undefined ? null : category.SortOrder;
            this.FieldCount = category == undefined ? null : category.FieldCount;
            this.CreateUser = category == undefined ? null : category.CreateUser;
            this.CreateTsmp = category == undefined ? null : category.CreateTsmp;
            this.UpdateUser = category == undefined ? null : category.UpdateUser;
            this.UpdateTmsp = category == undefined ? null : category.UpdateTmsp;
        }

        getFormFields(formFieldID?: number, formCategoryID?: number): JQueryDeferred<FormField[]> {
            var $result = $.Deferred();
            PL.Claims.APIs.FormTypeRepo.getFormFields(this.FormTypeID, formFieldID, formCategoryID).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        saveFormFields(): JQueryDeferred<FormField[]> {
            var $result = $.Deferred();
            PL.Claims.APIs.FormTypeRepo.saveFormTypeFields(this.FormFields).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        saveCategories(categories: FormCategory[]): JQueryDeferred<FormCategory[]> {
            var $result = $.Deferred();                        
            // since we don't want to keep internal (considering it's fake) we remove it before we save
            var filteredCategories: PL.Claims.Models.FormCategory[] = new Array<PL.Claims.Models.FormCategory>();

            categories.forEach((fcO, fcI) => {
                if (fcO.CategoryName != 'Internal') {
                    filteredCategories.push(fcO);
                }
            });

            PL.Claims.APIs.FormTypeRepo.saveFormTypeCategories(filteredCategories).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        saveCategory(): JQueryDeferred<FormCategory> {
            var $result = $.Deferred();
            PL.Claims.APIs.FormTypeRepo.saveFormCategory(this).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        deleteCategory(): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            PL.Claims.APIs.FormTypeRepo.deleteFormCategory(this.FormCategoryID).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }
       
    }

} 