module PL.Claims.Enums {

    export enum ClaimIntakeForm_Type {
    // we can delete this at some point - type has now become "phase" 
        START,
        POST_START_DYNAMIC,
        DYNAMIC,
        PRE_FINISH_DYNAMIC,
        FINISH,
        LIMBO_DYNAMIC,
        NO_CATEGORIES,
        SUBMITTED,
        CLASSIC_FORM

    }

    export enum ClaimIntakeForm_Phase {

        START,
        START_DYNAMIC,
        DYNAMIC,
        DYNAMIC_FINISH,
        FINISH,
        UNKNOWN

    }

    export enum ClaimIntakeForm_Status {

        NEW,
        OPEN,
        SUBMITTED,
        FIRST_AID,
        NO_CATEGORIES,
        PENDING

    }

    export enum ClaimIntakeCategory_Status {

        COMPLETE,
        INCOMPLETE,
        REVIEW,
        ERROR

    }

    export enum ClaimIntakeNotification_Request {

        GENERAL_INFORMATION_ONE,
        GENERAL_INFORMATION_TWO,
        REVIEW,
        CATEGORY_PROGRESS_ERROR,
        FIELD_VALIDATION_ERROR,
        INCOMPLETE_CATEGORIES_ERROR,
        FINISH_INFORMATION_NOT_COMPLETED_ERROR,
        FINISH_CATEGORIES_INCOMPLETE_ERROR,
        START_INFORMATION_NOT_COMPLETED_ERROR,
        FORM_CATEGORIES_COMPLETE,
        FIELD_ATTACHMENT_PREVIEW_AND_EDIT
    }

}