
// DO NOT ADD OR MAINTAIN THIS CLASS. See "GUI.Windows.Popups" for newer ShowPopup methods. 

module GUI.Utilities {
    export class Helpers {



        static ShowPopup(WindowID?: any, WinURL?: any, WinTitle?: any, width?: any, height?: any, CloseFunction?: any, ismodal?: any, isWindowLocked?: any, ShowHelp?: any, SuccessFunction?: any, panelContent?: any) {
            ShowPopup(WindowID, WinURL, WinTitle, width, height, CloseFunction, ismodal, isWindowLocked, ShowHelp, SuccessFunction, panelContent);
        }

        static closeThis(closeFunction) {
            closeThis(closeFunction);
        }

        static ShowInlinePopup(WindowID?: any, panelcontent?: any, WinTitle?: any, width?: any, height?: any, CloseFunction?: any, ismodal?: any) {
            ShowInlinePopup(WindowID, panelcontent, WinTitle, width, height, CloseFunction, ismodal);
        }

        static orderParentChildHierarchy(Object: any[], ParentChildPattern?: any): any[] {
            var orderedArray = [];
            var currentParentID = null;
            var iIndex = 0;
            var eIndex = 0;

            if (ParentChildPattern) {
                // update this to support any parent child structure
            }

            while (iIndex < Object.length) {

                // check if this is a parent item
                if (Object[iIndex].ParentID == 0 || Object[iIndex].ParentID == null) {
                    // add to ordered array if it is
                    orderedArray.push(Object[iIndex]);
                    // set the current parentid 
                    currentParentID = Object[iIndex].Id;
                    // remove from object array
                    Object.splice(iIndex, 1);                    
                    // check for children
                    checkForChildElement(currentParentID, true);
                }
                iIndex++;
            }

            function checkForChildElement(ParentID: number, resetIndex?: boolean) {

                if (resetIndex) {
                    eIndex = 0;
                }

                while (eIndex < Object.length) {

                    if (ParentID == Object[eIndex].ParentID) {
                        // add the child element right after it's parent
                        orderedArray.push(Object[eIndex]);
                        // set the current parentid as the child element, as we are going to check to see if the child has any children of it's own
                        currentParentID = Object[eIndex].Id;
                        // remove child element from the array
                        Object.splice(eIndex, 1);
                        // check currently select child element as a parent to see if it has children of it's own
                        checkForChildElement(currentParentID, true);
                        // reset iIndex back to 0
                        iIndex = 0;
                    }
                    eIndex++;
                }
                iIndex = 0;
            }
            return orderedArray;
        }

        static generateHTMLList(obj: any[]): string {
            var currentParentID = null;
            var HTML: string[] = new Array<string>();
            HTML.push('<nav class="genericNavWrap">');
            HTML.push('<ul>');
			for (var i = 0; i < obj.length; i++) {

				if (obj[i].ParentID == 0 || obj[i].ParentID == null) {
					HTML.push('<li><a href="#" data-attachmentcategoryid="' + obj[i].Id + '">' + obj[i].Title);
					this.checkForChildElements(obj[i].Id, 1, obj, i);
                    HTML.push('</a></li>');
                }

                

            }
            HTML.push('</ul>');
            HTML.push('</nav');
            return HTML.join('');
		}

		static checkForChildElements(ParentID: number, depth: number, obj: any, index: number): void {
		var hasParentBlock = false;
		var e = 0;
		var HTML = [];

		while (e < obj.length) {
			if (Object[e].ParentID == ParentID) {



				HTML.push('<li>');
				var tabdepth = 0;
				while (tabdepth <= depth) {
					HTML.push('&nbsp;&nbsp;&nbsp;&nbsp;');
					tabdepth += 1;
				}

				HTML.push('<a href="#" data-attachmentcategoryid="' + obj[index].Id + '">' + obj[e].Title + '</a></li>');

			}
			e++;
		}


	}
    }
}

