module PL.Common.Factories {

    var Repositories: any = {};
    export class RepositoryFactory {
        public static getChoreRepository():
            APIs.ChoreRepository{
            var Result = Repositories["ChoreRepository"];
            if (Result == null) {
                Result = new APIs.ChoreRepository();
                Repositories["ChoreRepository"] = Result;
            }
            return Result;
        }

    }

} 