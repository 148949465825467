
module IO {
    //This object cleans out functions from an object
    export class cleanedJSONObject{

        constructor(fullObject: any){
            for (var prop in fullObject) {
                if (typeof fullObject[prop] == 'function') continue;
                this[prop] = fullObject[prop];
            }
        }

    }

    export class AjaxBase {
        URL: string;
        Data: any;
        Async: boolean;
        StringData: string;
        Type: string;
        Success: any;
        Error: any;        
    }

    export interface IAjaxConfig {
        URL: string;
        Data?: any;
        Async?: boolean;
        StringData?: string;
        Type?: string;
        Success?: any;
        Error?: any;        
    }

    export class Ajax implements IAjaxConfig {         

        URL: string;         

        //constructor(URL?: string, successFunction?: any, restType?: string) {
        //    var a: IAjaxConfig;

        //    a.URL = URL;
        //    a.Success = successFunction;            

        //    switch(restType) {            
        //        case 'GET':                    
        //            this.Get(a);
        //            break;
        //        case 'POST':
        //            this.Post(a);
        //            break;
        //        case 'PUT':
        //            this.Put(a);
        //            break;
        //        case 'DELETE':
        //            this.Delete(a);
        //            break;
        //        default:
        //            this.Get(a);                    
        //    }
        //}

         private _base(defaultSettings: IAjaxConfig): any {

            if (defaultSettings.Async == null) {
                defaultSettings.Async = true;
            }

            if (defaultSettings.Data) {
                defaultSettings.Data = JSON.stringify(defaultSettings.Data);
            }

             if (defaultSettings.Success == null) {
                 defaultSettings.Success = function (data) {
                     console.log(data);
                 }
             }

             if (defaultSettings.Error == null) {
                 defaultSettings.Error = function (data) {
                     console.log(data);
                 }
             }

            var settings: JQueryAjaxSettings = {
                url: defaultSettings.URL,
                async: defaultSettings.Async,
                type: defaultSettings.Type,
                data: defaultSettings.Data,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: defaultSettings.Success,
                error: defaultSettings.Error
            }

        $.ajax(settings);
        }

        public Get(a: IAjaxConfig): any {
            a.Type = "GET";
            this._base(a);
        }

        public Post(a: IAjaxConfig): any {
            a.Type = "POST";            
            this._base(a);
        }

        public Put(a: IAjaxConfig): any {
            a.Type = "PUT";
            this._base(a);
        }

        public Delete(a: IAjaxConfig): any {
            a.Type = "DELETE";
            this._base(a);
        }
    }
}



