module PL.UW.Quotes.APIs {
    export class QuoteAPIs {

        static getQuoteRuleByGroupPeriod(QTName: string, RuleCode: string, GroupID: string, PolicyPeriod: number, DefaultValue: string, SuccessFunction?: (data) => any): any {
            var URL = '/API/UW/QuoteRules/GetQuoteRuleByGroupPeriod?QTName=' + QTName + '&RuleCode=' + RuleCode + '&GroupID=' + GroupID + '&PolicyPeriod=' + PolicyPeriod + '&DefaultValue=' + DefaultValue;
            $.get(URL).done(function () { }).then(function (data) {
                if (SuccessFunction) {
                    SuccessFunction(data);
                }
            });
        }

        static getQuoteRulesByGroupPeriod(GroupID: string, PolicyPeriod: number, SuccessFunction?: (data) => any): any {
            var URL = '/API/UW/QuoteRules/GetQuoteRulesByGroupPeriod?GroupID=' + GroupID + '&PolicyPeriod=' + PolicyPeriod;
            $.get(URL).done(function () { }).then(function (data) {
                if (SuccessFunction) {
                    SuccessFunction(data);
                }
            });
        }
    }
} 