CHSI.Page.CurrentSubsidyInformation = new Object();

CHSI.Page.CurrentSubsidyInformation.RequestText = 'Add Transaction';
// Page Variables
CHSI.Page.CurrentSubsidyInformation.SubsidyTemplate = '';

// Get the template
CHSI.Page.CurrentSubsidyInformation.GetTemplate = function (returnFunction) {    
    

    if (returnFunction) {
        returnFunction();
    }
};

// Get the template and compile it
CHSI.Page.CurrentSubsidyInformation.GetGroupSubsidyByClient = function(ClientID)
{
    jQuery.ajax({
        type: 'GET',
        url: '/api/AR/GroupSubsidy/GetGroupSubsidiesByClient?clientID=' + ClientID,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        success: function (e) {
            var GroupSubsidyClient = {};
            GroupSubsidyClient.GroupSubsidyByClientRecord = e;            
            GroupSubsidyClient.RequestText = CHSI.Page.CurrentSubsidyInformation.RequestText;
            
            
            jQuery('#jxSubsidyInformation').html(CHSITemplates.Template_Subsidy_CurrentSubsidyInformation(GroupSubsidyClient));
            restart = true;
            sorttable.init();
        },
        error: function (e) {
            jQuery('#jxSubsidyInformation').html("Error loading Subsidy Information grid. Please contact your system administrator.");
            CHSI.DOM.ProcessAjaxError;
        }
    });
};
