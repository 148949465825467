CHSI.Claims.FormTypes.Information = {
    Init: function (QSObject, successFunction) {
        var Template_Information = $('#Template_Information').html();
        this.Templates.Information = Handlebars.compile(Template_Information);        
        var Template_Notifications = $('#Template_Notifications').html();
        this.Templates.Notifications = Handlebars.compile(Template_Notifications);

        this.Cache.FormTypeID = QSObject.FormTypeID;        

        if (this.Cache.FormTypeID) {
            this.Calls.GetFormInformation(function () {
                CHSI.Claims.FormTypes.Information.Calls.GetGroups(function () {
                    CHSI.Claims.FormTypes.Information.Binds.Information();
                });                
            });
        } else {
            this.Calls.GetGroups(function () {
                CHSI.Claims.FormTypes.Information.Binds.Information();
            });            
        }

        if (successFunction) {
            successFunction();
        }

        this.Events.Global();
    },
    Templates: {

    },
    Cache: {        
        InformationObject: {
            ActiveGroups: {},
            AssociatedGroups: [],
            Instructions: null
        },        
        FormTypeID: null,
        Instructions: null
    },
    Binds:{
        Information: function () {            
            $('#InformationContainer').html(CHSI.Claims.FormTypes.Information.Templates.Information({ FormInformation: CHSI.Claims.FormTypes.Information.Cache.InformationObject }));
            $('#jxNotificationsContainer').html(CHSI.Claims.FormTypes.Information.Templates.Notifications({ FormInformation: CHSI.Claims.FormTypes.Information.Cache.InformationObject }));
            restart = true;
            sorttable.init();

            if (CHSI.Claims.FormTypes.Information.Cache.InformationObject.Instructions != null) {
                $('#jxInstructions').text('Click to update Start Instructions');
            }

            if (CHSI.Claims.FormTypes.Information.Cache.InformationObject.FinishInstructions != null) {
                $('#jxFinishInstructions').text('Click to update Finish Instructions');
            }
        },        
        Notifications: function () {
            $('#jxNotificationsContainer').html(CHSI.Claims.FormTypes.Information.Templates.Notifications({ FormInformation: CHSI.Claims.FormTypes.Information.Cache.InformationObject }));
            restart = true;
            sorttable.init();
        }
    },
    Events: {              
        Global: function () {
            $('#InformationContainer').on('change', '.AssociatedGroup', function () {
                var GroupName = $(this).data('groupname');

                if ($(this).is(':checked')) {
                    CHSI.Claims.FormTypes.Information.Events.GetAssociatedGroups(GroupName, true);
                } else {
                    CHSI.Claims.FormTypes.Information.Events.GetAssociatedGroups(GroupName, false);
                }                
            });

            $('#InformationContainer').on('click', '#jxAddNotification', function () {
                var NotificationID = $('#jxNotificationList').val();
                Data = {};
                Data.FormTypeID = CHSI.Claims.FormTypes.Information.Cache.FormTypeID;
                Data.NotificationID = NotificationID;
                Data.Status = 'active';
                CHSI.Claims.FormTypes.Information.Calls.SaveFormTypeNotification(Data, CHSI.Claims.FormTypes.Information.Events.RefreshNotifications)
            });

            $('#InformationContainer').on('click', '#jxSubmitIncident', function () {               
                CHSI.Claims.FormTypes.Information.Calls.SubmitIncident(5);
            });

            $('#InformationContainer').on('click', '.jxDeleteNotification', function () {
                var NotificationID = $(this).data('notificationid');
                Data = {};
                Data.FormTypeID = CHSI.Claims.FormTypes.Information.Cache.FormTypeID;
                Data.NotificationID = NotificationID;
                Data.Status = 'deleted';
                CHSI.Claims.FormTypes.Information.Calls.SaveFormTypeNotification(Data, CHSI.Claims.FormTypes.Information.Events.RefreshNotifications)
            });

            $('#InformationContainer').on('click', '.jxEditNotification', function () {
                var NotificationID = $(this).data('notificationid');
                CHSI.Claims.FormTypes.Information.Events.ShowNotificationEditor(NotificationID);               
            });

            $('#InformationContainer').on('click', '#jxAddNewNotification', function () {
                if (CHSI.Claims.FormTypes.Information.Cache.FormTypeID == null) {
                    CHSI.SystemNotification.SetResponse('Save to continue', 4);
                    return false;
                }
                CHSI.Claims.FormTypes.Information.Events.ShowNotificationEditor();
            });

            $('#InformationContainer').on('click', '#jxInstructions', function () {
                if (CHSI.Claims.FormTypes.Information.Cache.FormTypeID == null) {                    
                    alert('Save to continue');
                    return false;
                }                
                CHSI.Claims.FormTypes.Information.Events.ShowInstructionsEditor('start');
            });

            $('#InformationContainer').on('click', '#jxFinishInstructions', function () {
                if (CHSI.Claims.FormTypes.Information.Cache.FormTypeID == null) {                    
                    alert('Save to continue');
                    return false;
                }
                CHSI.Claims.FormTypes.Information.Events.ShowInstructionsEditor('finish');
            });
        },              
        RefreshNotifications: function ()
        {
            CHSI.Claims.FormTypes.Information.Calls.GetNotificationsNotInFormType(function () { CHSI.Claims.FormTypes.Information.Calls.GetNotificationsByFormTypeID(CHSI.Claims.FormTypes.Information.Binds.Notifications) })
        },
        RefreshInformation: function () {
            CHSI.Claims.FormTypes.Information.Calls.GetFormInformation(function () {
                CHSI.Claims.FormTypes.Information.Calls.GetGroups(function () {
                    CHSI.Claims.FormTypes.Information.Binds.Information();
                });
            });
        },
        ShowNotificationEditor: function (NotificationID) {
            var URL = null;
            if (NotificationID) {
                URL = '/Claims/Modals/NotificationEditor.aspx?NotificationID=' + NotificationID;
            }else {
                URL = '/Claims/Modals/NotificationEditor.aspx';
            }

            parent.ShowPopup('NotificationEditor', URL, 'Notification Editor', 800, 650, CHSI.Claims.FormTypes.Information.Events.RefreshNotifications, true);
        },
        SaveInformation: function (successFunction) {
            if (!CHSI.Claims.FormTypes.Information.Events.ValidateSummaryFields()) {
                alert('Please select a value in Summary Field.');
                return false;
            }
            CHSI.Claims.FormTypes.Information.Calls.SaveInformation(successFunction);
        },
        ValidateSummaryFields: function () {
            var InformationObject = CHSI.Claims.FormTypes.Information.Cache.InformationObject,
                isValid = false,
                SummaryFieldHasValue = false;

            if (InformationObject.SummaryFields) {
                SummaryFieldHasValue = (InformationObject.SummaryFields.length == 0) ? false : true;
            }

            if (SummaryFieldHasValue) {
                if ($('#jxSummaryField').val()) {
                    isValid = true;
                } else {
                    isValid = false;
                }
            } else {
                isValid = true;
            }
            return isValid;
        },
        PersistFormToCache: function () {
            var InformationObject = {}

            InformationObject.FormTypeID = CHSI.Claims.FormTypes.Information.Cache.FormTypeID;
            InformationObject.FormTypeName = $('#jxFormType').val();
            InformationObject.Name = $('#jxName').val();
            InformationObject.Description = $('#jxDescription').val();
            InformationObject.Instructions = CHSI.Claims.FormTypes.Information.Cache.InformationObject.Instructions
            InformationObject.Status = $('#jxStatus').val();
            InformationObject.ReportForm = $('#jxReportForm').val();
            InformationObject.DaysToStale = $('#jxDaysToStale').val();
            InformationObject.AssociatedGroups = CHSI.Claims.FormTypes.Information.Cache.InformationObject.AssociatedGroups;

            CHSI.Claims.FormTypes.Information.Cache.InformationObject = InformationObject;
            return InformationObject;
        },
        GetAssociatedGroups: function (groupName, isActive) {
            var AssociatedGroups = CHSI.Claims.FormTypes.Information.Cache.InformationObject.AssociatedGroups;

            if (isActive) {
                AssociatedGroups.push(groupName);
            } else {
                for (Index = 0; Index < AssociatedGroups.length; Index++) {
                    if (AssociatedGroups[Index] == groupName) {
                        AssociatedGroups.splice(Index, 1);
                    }
                }
            }

            CHSI.Claims.FormTypes.Information.Cache.InformationObject.AssociatedGroups = AssociatedGroups;
        },
        ShowInstructionsEditor: function (instructionType) {
            var URL;
            if (instructionType == 'start') {
                URL = '/Claims/Modals/InstructionsEditor.aspx?FormTypeID=' + CHSI.Claims.FormTypes.Information.Cache.FormTypeID + '&instructiontype=start';
            } else {
                URL = '/Claims/Modals/InstructionsEditor.aspx?FormTypeID=' + CHSI.Claims.FormTypes.Information.Cache.FormTypeID + '&instructiontype=finish';
            }


            parent.ShowPopup('InstructionsEditor', URL, 'Instructions Editor', 850, 490, CHSI.Claims.FormTypes.Information.Events.RefreshInformation, true);
        },
    },
    Calls: {
        GetFormInformation: function (successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/Form/GetFormType?FormTypeID=' + CHSI.Claims.FormTypes.Information.Cache.FormTypeID;
            Options.Success = function (data) {
                CHSI.Claims.FormTypes.Information.Cache.InformationObject = data
                CHSI.Claims.FormTypes.Information.Cache.InformationObject.AssociatedGroups = data.AssociatedGroups;
                if (successFunction) {
                    successFunction(data);
                }
            };            
            CHSI.Common.Ajax.Get(Options);
        },
        GetGroups: function (successFunction) {
            var Options = {};
            Options.URL = '/api/CRM/Group/GetActiveGroups';
            Options.Data = null;
            Options.Success = function (data) {
                CHSI.Claims.FormTypes.Information.Cache.InformationObject.ActiveGroups = data;

                if (successFunction) {
                    successFunction(data);
                }
            }
            CHSI.Common.Ajax.Get(Options);
        },
        SaveInformation: function (successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/Form/SaveFormType';
            Options.Data = CHSI.Claims.FormTypes.Information.Events.PersistFormToCache();
            Options.Success = function (data) {                
                CHSI.Claims.FormTypes.Information.Cache.FormTypeID = data;
                if (successFunction) {
                    successFunction(data);
                }
            };
            Options.Error = function (data) {
                CHSI.SystemNotification.SetResponse('An error occurred and has been logged', 2);
                CHSI.Common.LogObject(data);
            }

            CHSI.Common.Ajax.Post(Options);
        },
        GetNotificationsNotInFormType: function (successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/Form/GetNotificationsNotInFormType?FormTypeID='+CHSI.Claims.FormTypes.Information.Cache.FormTypeID;
            Options.Data = null;
            Options.Success = function (data) {
                CHSI.Claims.FormTypes.Information.Cache.InformationObject.AllNotifications = data;

                if (successFunction) {
                    successFunction(data);
                }
            }
            CHSI.Common.Ajax.Get(Options);
        },
        GetNotificationsByFormTypeID: function (successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/Form/GetNotificationsByFormTypeID?FormTypeID=' + CHSI.Claims.FormTypes.Information.Cache.FormTypeID;;
            Options.Data = null;
            Options.Success = function (data) {
                CHSI.Claims.FormTypes.Information.Cache.InformationObject.Notifications = data;

                if (successFunction) {
                    successFunction(data);
                }
            }
            CHSI.Common.Ajax.Get(Options);
                },
        SaveFormTypeNotification: function (Data, successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/Form/SaveFormTypeNotification?FormTypeID='+Data.FormTypeID+'&NotificationID='+Data.NotificationID+'&Status='+Data.Status;
            Options.Data = Data;
            Options.Success = function (data) {                
                if (successFunction) {
                    successFunction();
                }
            };

            CHSI.Common.Ajax.Post(Options);
        },
        SubmitIncident: function (IncidentID) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/ClaimIncidentReport/SubmitIncident?IncidentID=' + IncidentID;
            Options.Data = IncidentID;
            Options.Success = function (data) {
                if (successFunction) {
                    successFunction();
                }
            };

            CHSI.Common.Ajax.Post(Options);
        }        
    }
}