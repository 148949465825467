module GUI {
    export class Mustache {

        static SetupCommonHelpers(): void {
            ///This works equally well for selected, ratio and checkbox values.
            Handlebars.registerHelper('matchSelected', function (option, value, matchText) {
                if (option === value) {
                    return ' ' + matchText;
                } else {
                    return ''
                }
            });

            Handlebars.registerHelper('getAttachmentCategoryOptions', function (AttachmentCategories, selectedAttachmentCategoryID) {
                var Results = '';
                Results += CL.Common.Views.AttachmentCategoryDropDown.GetOptionHTML(AttachmentCategories, selectedAttachmentCategoryID, 0);

                return new Handlebars.SafeString(Results);
            });

        }

        static QuickBind(HTMLTemplate: string, Object: any, $container: JQuery, SuccessFunction?: () => any): void {
            var _self = this;
            var GenericTemplate: HandlebarsTemplateDelegate;

            GenericTemplate = Handlebars.compile(HTMLTemplate);

            $container.html(GenericTemplate(Object));

            if (SuccessFunction) {
                SuccessFunction();
            }
        }

        static quickBindPrecompiled(PrecompiledTemplate: any, Object: any, $container: JQuery, SuccessFunction?: () => any): void {

            $container.html(PrecompiledTemplate(Object));

            if (SuccessFunction) {
                SuccessFunction();
            }
        }

        public static success(successFunction?: () => any) {
            if (successFunction) {
                successFunction();
            }            
        }
    }
} 