CHSI.Claims.ClaimIntake.Finish = {
    Init: function (incidentID, formTypeID) {
        
        this.Templates.Finish = CHSITemplates.ClaimIntakeForm_Finish;

        this.Cache.IncidentID = incidentID;
        this.Cache.FormTypeID = formTypeID;

        this.Calls.GetLateReasons(function () {
            CHSI.Claims.ClaimIntake.Finish.Binds.Finish();
        });
        
        this.Events.Global();
    },
    Templates: {},
    Cache: {
        ClaimObject: {}
    },
    Binds: {
        Finish: function () {
            $('#DynamicFormContainer').html(CHSI.Claims.ClaimIntake.Finish.Templates.Finish({ ClaimObject: CHSI.Claims.ClaimIntake.Finish.Cache.ClaimObject }));

            $('#jxNext').hide();
            $('#jxFinish').show();
            $('#jxPreparing').hide();
        }
    },
    Events: {
        Global: function () {
            $('#ClaimIntakeFormContainer').on('click', '#jxFinish', function () {
                CHSI.Claims.ClaimIntake.Finish.Calls.SaveIncident(function () {
                    CHSI.Claims.ClaimIntake.Finish.Calls.SubmitIncident(function () {
                        top.location = top.location;
                    });
                });
            });
        },
        PersistIncident: function () {
            var NewIncidentObject = CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.NewIncidentObject,
                d = new Date(),
                month = d.getMonth() + 1,
                day = d.getDate(),
                fullYear = d.getFullYear(),
                shortDate = month + '/' + day + '/' + fullYear;


           
            NewIncidentObject.Status = 'Submitted';
            NewIncidentObject.FormCompletedDate = shortDate;
            NewIncidentObject.FormCompletedBy = $('#jxCompletedBy').val();
            NewIncidentObject.FormCompletedTitle = $('#jxTitle').val();
            NewIncidentObject.LateReasonCode = $('#jxLate').val();
            CHSI.Claims.ClaimIntake.ClaimIntakeForm.Cache.ClaimIntakeObject.NewIncidentObject = NewIncidentObject;

            return NewIncidentObject;
        }
    },
    Calls: {
        GetLateReasons: function (successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/Claim/GetLateReasons';            
            Options.Success = function (data) {
                CHSI.Claims.ClaimIntake.Finish.Cache.ClaimObject.LateReasons = data;
                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        SaveIncident: function (successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/ClaimIncidentReport/SaveIncident';
            Options.Data = CHSI.Claims.ClaimIntake.Finish.Events.PersistIncident();
            Options.Success = function (data) {
                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Post(Options);
        },
        SubmitIncident: function (successFunction) {
            var IncidentID = CHSI.Claims.ClaimIntake.Finish.Cache.IncidentID * 1;
            var Options = {};
            Options.URL = '/api/ClaimSystem/ClaimIncidentReport/SubmitIncident?IncidentID=' + IncidentID;
            Options.Success = function (data) {
                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Post(Options);
        }
    }
}