module PL.Common.Models {

    export class SmartField {

        Title: string;
        FieldName: string;
        Format: string;
        DataType: number;
        Summarized: boolean;
        Visible: boolean;
        Type: string;
        Editable: boolean;
        isCurrency: boolean;
        OrderDataType: string;

    }

}