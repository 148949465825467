CHSI.CRM.ClientTypes = {

    Models: {},
    Controllers: {},

    Init: function () {

        CHSI.CRM.ClientTypes.Calls.GetClientTypes();
        CHSI.CRM.ClientTypes.Events.Global();

    },

    Cache: {
        GridObject: {}
    , ActiveOnly: false

    },

    Events: {

        Global: function () {
            //$('#jxClientTypeFilter').on('click', '.jxEdit', function () {
            $('#jxClientTypes').on('click', '.jxEdit', function () {
                var Button = $(this);
                var ClientTypeName = Button.data('clienttype');
                var ShowEditClientTypeURL = '/utilities/clienttypeedit.aspx?ClientTypeName=' + ClientTypeName;
                ShowPopup("EditClientType", ShowEditClientTypeURL, "Edit Company Type", 340, 185, CHSI.CRM.ClientTypes.Calls.GetClientTypes, true);

            });
            //Not sure where this should point
            $('#jxClientTypes').on('click', '.jxDelete', function () {
                var Button = $(this);
                var ClientTypeName = Button.data('clienttype');
                if (confirm("Are you sure you want to delete this Company Type?") == true) {
                    CHSI.CRM.ClientTypes.Calls.DeleteClientType(ClientTypeName);

                }
                
            });

            $('#jxAddNew').on('click', function () {
                var ShowEditClientTypeURL = '/utilities/clienttypeedit.aspx';
                ShowPopup("EditClientTypeURL", ShowEditClientTypeURL, "Add Company Type", 340, 185, CHSI.CRM.ClientTypes.Calls.GetClientTypes, true);
            });


        }
    },

    BindPresentationLayer: function () {

        $('#jxClientTypes').html('<table width="100%" class="dtable" id="jxClientTypesGrid"><tfoot></tfoot></table>');

        var DataSet = null;

        if (CHSI.CRM.ClientTypes.Cache.GridObject) {
            DataSet = CHSI.CRM.ClientTypes.Cache.GridObject.Values;
            var Columns = CHSI.CRM.ClientTypes.Cache.GridObject.Columns;
        }

        var columnDefs = [{
            bSortable: false,
            width:'10%',
            aTargets: [5]  
        }];
        var ClientColumnDefinition = {};
        ClientColumnDefinition.targets = 5;
        ClientColumnDefinition.render = function (data, type, row) {
            return "<a class='jxEdit' data-clienttype='" + row[0] + "' href='#'>[Edit]</a> <a class='jxDelete' data-clienttype='" + row[0] + "' href='#'>[Delete]</a>";
        }
        columnDefs.push(ClientColumnDefinition);

        if (DataSet != null) {
            CHSI.CRM.ClientTypes.Cache.GridTable = $('#jxClientTypesGrid').DataTable({
                data: DataSet,
                columns: Columns,
                lengthMenu: [[25], [25]],
                columnDefs: columnDefs,
                stateSave: true
            })
        } else {
            var HTML = [];
            $('#jxClientTypesGrid').html('<tr><td>No results found.</td></tr>')
        }

        $('#jxLoading').hide();
    },

    Calls: {

        GetClientTypes: function () {
            var Options = {};
            Options.URL = "/api/CRM/ClientType/GetClientTypes";
            Options.Success = function (e) { CHSI.CRM.ClientTypes.Cache.GridObject = e; CHSI.CRM.ClientTypes.BindPresentationLayer(); };
            CHSI.Common.Ajax.Get(Options);
        },

        DeleteClientType: function (clienttype) {
            var Options = {};
            Options.URL = "/api/CRM/ClientType/DeleteClientType?clienttype=" + clienttype;
            Options.Success = function (e) {
                CHSI.CRM.ClientTypes.Calls.GetClientTypes();
            };
            CHSI.Common.Ajax.Delete(Options);

        }
    }

}
