var currentEnvironment = "";
function getCurrentEnvironment() {
    switch (targetEnvironment) {
        case 'hotfix':
            currentEnvironment = "hf";
            break;
        case 'staging':
            currentEnvironment = "staging";
            break;
        case 'dev':
            currentEnvironment = "dev";
            break;
        case 'release':
            currentEnvironment = "";
    }

}