CHSI.Claims.FormTypes.Categories = {
    Init: function (successFunction) {        
        var Template_Categories = $('#Template_Categories').html();
        this.Templates.Categories = Handlebars.compile(Template_Categories);

        if (CHSI.Claims.FormTypes.Information.Cache.FormTypeID) {
            CHSI.Claims.FormTypes.Categories.Calls.GetCategories(function () {
                successFunction();
                CHSI.Claims.FormTypes.Categories.Binds.Categories();
            });
        }
        else {
            CHSI.Claims.FormTypes.Categories.CategoriesObject = [];
            CHSI.Claims.FormTypes.Categories.Binds.Categories();
            CHSI.Claims.FormTypes.Categories.SaveFlag = false;

            if (successFunction) {
                successFunction();
            }
        }

        this.Events.Global();
    },
    Templates: {

    },
    Cache: {
        FormCategoryID: null,
        CategoriesObject: {},
        SaveFlag: false,
        CategoriesList: []
    },
    Binds: {
        Categories: function () {            
            $('#CategoriesContainer').html(CHSI.Claims.FormTypes.Categories.Templates.Categories({ CategoriesObject: CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories }));
            restart = true;
            sorttable.init();            

            $('#categoryBody > ul').sortable({
                connectWith: "#CategoriesContainer"
            }).disableSelection();
        }        
    },    
    Events: {
        Global: function () {
            $('#CategoriesContainer').on('sortstart', '#categoryBody > ul', function (event, ui) {
                ui.item.css('box-shadow', '0 0 10px #000000');
                ui.item.css('background-color', 'white');                                
            });

            $('#CategoriesContainer').on('sortstop', '#categoryBody > ul', function (event, ui) {
                ui.item.css('box-shadow', '');
                CHSI.Claims.FormTypes.Categories.Events.updateCategorySortOrder();
                CHSI.Claims.FormTypes.Categories.Events.updateRowColor();
            });

            $('.ButtonsLeft').on('click', '#jxCategoryAddField', function () {
                if (CHSI.Claims.FormTypes.Information.Cache.FormTypeID == null) {
                    alert('Please save the form if you would like to create a new category.');
                } else {
                    CHSI.Claims.FormTypes.Categories.Events.AddField();
                }                
            });

            $('#CategoriesContainer').on('blur', '.CategoryNameInput', function () {
                var name = $(this).data('name');
                CHSI.Claims.FormTypes.Categories.Events.updateFieldsCategoriesList($(this), name);
                CHSI.Claims.FormTypes.Fields.Binds.Fields();
            });

            $('#CategoriesContainer').on('click', '.jxDelete', function () {
                var FormCategoryID = $(this).data('formcategoryid'),
                    Index = $(this).data('index');
                var hasFields = CHSI.Claims.FormTypes.Categories.Events.checkAssociatedFields(FormCategoryID);
                var isDeleted = false;

                if (hasFields) {
                    isDeleted = confirm('The category you are about to delete has fields associated with it. If you delete this category, those fields will be deleted in addition to having this category removed. Please confirm to contiune.');
                } else {
                    isDeleted = confirm('Please confirm that you want to delete this category.');
                }
                if (isDeleted) {
                    CHSI.Claims.FormTypes.Categories.Events.deleteCategory(FormCategoryID);
                                   
                    CHSI.SystemNotification.SetResponse('Category deleted');

                    $(this).closest('li').remove();
                    $(this).prop('disabled', true);
                }
            });
        },
        SaveCategories: function (successFunction) {
            if (CHSI.Claims.FormTypes.Information.Cache.FormTypeID != null) {                
                CHSI.Claims.FormTypes.Categories.Calls.SaveCategories(successFunction);
            }
        },
        AddField: function () {

            if (CHSI.Claims.FormTypes.Information.Cache.FormTypeID) {

                if (CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories == null) {
                    CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories = [];
                }

                CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories.push({
                    FormCategoryID: null,
                    FormTypeID: CHSI.Claims.FormTypes.Information.Cache.FormTypeID * 1,
                    CategoryName: null,
                    FieldCount: 0,
                    SortOrder: CHSI.Claims.FormTypes.Categories.Events.getLastSortOrder(),
                    Status: 'Active',
                    CreateUser: null,
                    CreateTmsp: null,
                    UpdateUser: null,
                    UpdateTmsp: null
                });

                CHSI.Claims.FormTypes.Categories.Binds.Categories();

            } else {                
                CHSI.SystemNotification.SetResponse('Save to continue', 4);
            }            
        },
        validateCategoryName: function (categoryName, savedName) {
            var categories = CHSI.Claims.FormTypes.Categories.Cache.CategoriesList;
            var isDupilcate = false;
            for (i = 0; i < categories.length; i++) {
                if (savedName == categoryName) {
                    break;
                }
                if (categories[i].CategoryName == categoryName) {
                    isDupilcate = true;                    
                    break;
                }
            }            
            return isDupilcate;
        },
        deleteCategory: function (formCategoryID) {
            var categories = CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories;            CHSI.Claims.FormTypes.Categories.Calls.DeleteCategory(formCategoryID, function () {                
                for (i = 0; i < categories.length; i++) {
                    if (categories[i].FormCategoryID == formCategoryID) {
                        categories.splice(i, 1);
                    }
                }
            });
        },
        updateFieldsCategoriesList: function (categoryNameContext, savedName) {
            var categories = CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories;
            if (categoryNameContext != undefined) {            
                var isDuplicate = CHSI.Claims.FormTypes.Categories.Events.validateCategoryName(categoryNameContext.val(), savedName);
                if (!isDuplicate) {
                    var index = categoryNameContext.data('index');
                    var categoryName = categoryNameContext.val();
                    categories[index].CategoryName = categoryName;
                    CHSI.Claims.FormTypes.Fields.Binds.Fields();
                } else {
                    categoryNameContext.val('');
                    alert('There can only be one category with the same name.');
                }
            }
        },
        checkAssociatedFields: function (categoryID) {
            var fields = CHSI.Claims.FormTypes.Fields.Cache.FieldsArray;
            var hasFields = false;
            for (i = 0; i < fields.length; i++) {
                if (fields[i].FormCategoryID == categoryID) {
                    hasFields = true;
                    break;
                }
            }
            return hasFields;
        },
        updateCategorySortOrder: function () {
            var categories = CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories;
            $('#categoryBody > ul > li').each(function (i, v) {
                for (e = 0; e < categories.length; e++) {
                    if ($(this).find('.CategoryNameInput').val() == categories[e].CategoryName) {
                        categories[e].SortOrder = i;                        
                    }
                }
            });                        
        },
        updateRowColor: function () {
            $('#categoryBody > ul > li').each(function (i, v) {
                var backgroundcolor = 'arow';
                if (i % 2 == 0) {
                    backgroundcolor = 'prow';
                }
                $(this).removeClass('arow prow').addClass(backgroundcolor);
                return backgroundcolor;
            });
        },
        getCategoryObjectByID: function (formCategoryID) {
            var categories = CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories;
            var category;
            for (i = 0; i < categories.length; i++) {
                if (categories[i].FormCategoryID == formCategoryID) {
                    category = categories[i];
                    break;
                }
            }
            return category;
        },
        getLastSortOrder: function () {
            var categories = CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories;
            var previousSortOrder = 0;            
            for (i = 0; i < categories.length; i++) {
                if (categories[i].SortOrder > previousSortOrder) {
                    previousSortOrder = categories[i].SortOrder;
                }                
            }
            return previousSortOrder + 1;
        }
    },
    Calls: {
        GetCategories: function (successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/Form/GetCategoriesByType?FormTypeID=' + CHSI.Claims.FormTypes.Information.Cache.FormTypeID;
            Options.Success = function (data) {
                CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories = data;
                CHSI.Claims.FormTypes.Categories.Cache.CategoriesList = data;
                if (successFunction) {
                    successFunction(data);
                }
            }
            Options.Error = function (data) {
                CHSI.Common.LogObject(data);                
            }

            CHSI.Common.Ajax.Get(Options);                        
        },
        SaveCategories: function (successFunction) {

            var Options = {};
            Options.URL = '/api/ClaimSystem/Form/SaveFormCategories';
            Options.Data = CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories == undefined ? [] : CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories;
            Options.Success = function (data) {                                
                if (successFunction) {
                    successFunction(data);
                }
            }
            Options.Error = function (data) {
                CHSI.SystemNotification.SetResponse('An error occurred and has been logged', 2);
                CHSI.Common.LogObject(data);                                
            }

            CHSI.Common.Ajax.Post(Options);                            
        },
        DeleteCategory: function (FormCategoryID, successFunction) {
            var Options = {};            
            Options.URL = '/api/ClaimSystem/Form/DeleteFormCategory?FormCategoryID=' + FormCategoryID;
            Options.Data = null;
            Options.Success = function (data) {
                $('.jxDelete').prop('disabled', false);
                if (successFunction) {
                    successFunction(data);
                }
            }
            Options.Error = function (data) {
                CHSI.Common.LogObject(data);                
            }
            CHSI.Common.Ajax.Delete(Options);
        },        
    }
}