// Initialize the ValuePicker
CHSI.UI.ValuePicker = new Object();
CHSI.UI.ValuePicker.AjaxObjects = {};
CHSI.UI.ValuePicker.ReturnFunctions = {};
CHSI.UI.ValuePicker.UIInfo = {};

// Called by external code to display the picker
CHSI.UI.ValuePicker.ShowPicker = function (searchID, width, height, responseFunction, isModal, defaultSearch, pageIndex, numRecords, hideSearch, searchType) {
    var result = [];
    if (isModal == null) {
        isModal = true;
    }
    if (defaultSearch == null) {
        defaultSearch = '';
    }
    if (pageIndex == null) {
        pageIndex = 0;
    }
    if (numRecords == null) {
        numRecords = 25;
    }
    if (hideSearch == null) {
        hideSearch = false;
    }
    if (searchType == null) {
        searchType = 1;
    }
    var windowWidth = width;
    var windowHeight = height;
    CHSI.UI.ValuePicker.ReturnFunctions['R' + searchID] = responseFunction;
    var SearchObject = new Object();
    SearchObject.PageIndex = pageIndex;
    SearchObject.NumRecords = numRecords;
    SearchObject.SearchType = searchType;
    CHSI.UI.ValuePicker.UIInfo['SO' + searchID] = SearchObject;
    var frameWidth = (hideSearch == true) ? windowWidth - 30 : windowWidth - 30;
    var frameHeight = (hideSearch == true) ? windowHeight - 30 : windowHeight - 80;
    result.push('<div id="AdvancedSearchContents_' + searchID + '" style="height: ' + frameHeight + 'px; width: ' + frameWidth + 'px;"><div id="UISearchbar_' + searchID + '"');
    if (hideSearch) {
        result.push(' style="display:none;" ');
    }
    result.push('>Search Text: <input id="UISearchText_' + searchID + '" onkeyup="CHSI.UI.ValuePicker.DoSearch(' + searchID + ',' + numRecords + ');" type="text" value="' + defaultSearch + '"/><input type="button" value="Go" onclick="CHSI.UI.ValuePicker.DoSearch(' + searchID + ',' + numRecords + ');"/></div><div id="UISearchResults_' + searchID + '" style="height: ' + (windowHeight - 70) + 'px; width: ' + (windowWidth - 30) + 'px;"><div id="UISearchGrid_' + searchID + '"></div></div><div class="buttonPanel" style=" margin-right: 2px; padding-top:3px;"><div align="right"><input type="button" class="button" onclick="javascript: MUI.closeWindow(parent.$(aSearchForm_' + searchID + '));" value="Cancel"/></div></div>');
    ShowPagePopup(result.join(""), 'Advanced Search', windowWidth, windowHeight, 'aSearchForm_' + searchID, isModal);

    if (defaultSearch != '') {
        CHSI.UI.ValuePicker.DoSearch(searchID, numRecords);
    }

}

// Called when a "Select" button is clicked within the grid
CHSI.UI.ValuePicker.DoAction = function (searchID, index) {
    if (CHSI.UI.ValuePicker.UIInfo['SO' + searchID].CachedData == null)
    { alert('error, cache empty, please alert support') }
    else {
        var Row = CHSI.UI.ValuePicker.UIInfo['SO' + searchID].CachedData.Data[index];
        CHSI.Common.Log('calling function - ' + CHSI.UI.ValuePicker.ReturnFunctions['R' + searchID]);
        CHSI.UI.ValuePicker.ReturnFunctions['R' + searchID](Row);
        MUI.closeWindow(parent.$('aSearchForm_' + searchID));
    }
}

// Called when the "Go" button is clicked
CHSI.UI.ValuePicker.DoSearch = function (searchID, numRecords) {

    CHSI.Common.Log('Calling DoSearch');

    var ajaxData = new Object();
    ajaxData.searchid = searchID;
    ajaxData.searchterm = jQuery('#UISearchText_' + searchID).val();
    ajaxData.searchtype = CHSI.UI.ValuePicker.UIInfo['SO' + searchID].SearchType;
    ajaxData.pageindex = 0;
    ajaxData.numrecords = numRecords;
    ajaxData.OrderBy = CHSI.AdvancedSearch.OrderText;
    var ajaxString = JSON.stringify(ajaxData);
    CHSI.Common.Log('would search - ' + ajaxString);

    if (CHSI.UI.ValuePicker.AjaxObjects['UI' + searchID]) {
        CHSI.Common.Log('Aborting Call' - CHSI.UI.ValuePicker.AjaxObjects['UI' + searchID]);
        CHSI.UI.ValuePicker.AjaxObjects['UI' + searchID].abort();
    }

    CHSI.UI.ValuePicker.AjaxObjects['UI' + searchID] = jQuery.ajax({
        type: "POST", url: "/webservice/ajaxfunctions.asmx/GetSearchResults",
        data: ajaxString,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (e) {
            var html = [];
            var Cols = [];
            var MorePages = false;
            var MinX = 500;
            var MinY = 400;
            var result = e.d;
            CHSI.UI.ValuePicker.UIInfo['SO' + searchID].CachedData = result;
            var UISearchGridTable = jQuery('#UISearchGrid_' + searchID);
            var UISearchContainer = jQuery('#AdvancedSearchContents_' + searchID);
            var windowWidth = UISearchContainer.width();
            var windowHeight = UISearchContainer.height();

            html.push('<div class="uxMobileReturnPanel" id="AIContent" style="overflow: auto;  height:' + windowHeight + 'px; width:' + windowWidth + 'px;"><table cellspacing="0" cellpadding="2"  class="dtable sortable" width="98%" align="center"><thead><tr>');
            jQuery.each(result.Headers, function (index, value) {
                if (value.length > 0) {
                    if (value.substr(0, 1) != "_" && value != "rowid") {
                        Cols.push(index);
                        html.push('<th  ><a >');
                        html.push(value);
                        html.push('</a></th>');
                    }
                }

            });
            html.push('<th></th>');
            html.push('</tr></thead><tbody>');

            jQuery.each(result.Data, function (objindex, objdata) {
                if (objindex < CHSI.UI.ValuePicker.UIInfo['SO' + searchID].NumRecords) {

                    html.push('<tr');
                    html.push('>');
                    jQuery.each(objdata.Data, function (index, columnvalue) {
                        if (jQuery.inArray(index, Cols) != -1) {
                            html.push('<td>');
                            html.push(columnvalue);
                            html.push('</td>');
                        }

                    });
                    html.push('<td><input type="button" value="Select" onclick="CHSI.UI.ValuePicker.DoAction(' + searchID + ',' + objindex + ');"/></td>');
                    html.push('</tr>');

                }
                else {
                    MorePages = true;
                }

            });

            html.push('</tbody></table></div>');
            html.push('<div>');

            if (CHSI.UI.ValuePicker.UIInfo['SO' + searchID].PageIndex > 0) {
                html.push('<a class="button" href=\'javascript: GetPage(-1);\' class=\'minilink\'><< Previous Records</a>&nbsp;');
            }
            else { html.push('<a href=\'#\' style="color:gray; text-decoration:none;"  class=\'minilink\'><< Previous Records</a>&nbsp;'); }
            if (MorePages) {
                html.push('&nbsp;<a href=\'javascript: GetPage(1);\' class=\'minilink\'>More Records >></a>');
            }
            html.push('</div>');

            UISearchGridTable.html(html.join(''))
            restart = true;
            sorttable.init();

        },
        error: function (e) {
            if (e.statusText != 'abort') {
                alert('An error from the webservice - ' + e.statusText);
            }
        }
    });
}
