var Template_IncidentSearch_Parameters = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                    <select style=\"width: 60px\" id=\"jxSearchOperator"
    + escapeExpression(((helper = (helper = helpers.FilterIndex || (depth0 != null ? depth0.FilterIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"FilterIndex","hash":{},"data":data}) : helper)))
    + "\" onchange=\"CHSI.Page.IncidentSearch.BuildNewSearchOption(this)\" class=\"FilterOperator\">\r\n                        <option value=\"none\"></option>\r\n                        <option value=\"and\">AND</option>\r\n                        <option value=\"or\">OR</option>\r\n                    </select>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "﻿        \r\n            <tr class=\"SearchOptionRow\">\r\n                <td style=\"width: 155px;\"> \r\n                    <select style=\"width: 150px\" class='searchoption' id=\"jxSelectedOption"
    + escapeExpression(((helper = (helper = helpers.FilterIndex || (depth0 != null ? depth0.FilterIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"FilterIndex","hash":{},"data":data}) : helper)))
    + "\" onchange=\"CHSI.Page.IncidentSearch.BuildSearchOptionInputs("
    + escapeExpression(((helper = (helper = helpers.FilterIndex || (depth0 != null ? depth0.FilterIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"FilterIndex","hash":{},"data":data}) : helper)))
    + ")\">\r\n                        "
    + escapeExpression(((helper = (helper = helpers.PopulateOptionDropdown || (depth0 != null ? depth0.PopulateOptionDropdown : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"PopulateOptionDropdown","hash":{},"data":data}) : helper)))
    + "\r\n                    </select> \r\n                </td>\r\n                <td style=\"width: 350px\" class=\"SearchFields\"><div id=\"SearchFields"
    + escapeExpression(((helper = (helper = helpers.FilterIndex || (depth0 != null ? depth0.FilterIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"FilterIndex","hash":{},"data":data}) : helper)))
    + "\"></div></td>\r\n                <td style=\"width: 60px\">\r\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ShowAdditionalFilter : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                </td>\r\n            </tr>\r\n        ";
},"useData":true});