module PL.ClaimSystem.ClaimManagement {
    export class ReserveModel {
        constructor() { }
        public ReserveID: number;
        public ClaimID: number;
        public ClaimantID: number;
        public Source: string;
        public Status: string;
        public ExtKey: number;
        public ReserveType: string;
        public ItemDate: string;
        public Amount: number;
        public Comments: string;
        public CreateUser: string;
        public CreateTmsp: string;
        public UpdateUser: string;
        public UpdateTmsp: string;
    }
}