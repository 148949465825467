module PL.CRM.Models {

    export class Location {

        LocationID: number;
        ClientID: number;
        ClientNumber: string;
        Status: string;
        ContactID: number;
        DBA: string;
        Description: string;
        LocationDBA: string;
        Address1: string;
        Address2: string;
        City: string;
        State: string;
        Zip: string;
        Zip4: string;
       // PhoneAC: string;
        Phone: string;
        PhoneExt: string;
        //FaxAC: string;
        Fax: string;
        LocationNumber: number;
        StoreNumber: string;
        FEIN: string;
        StartDate: Date;
        TerminationDate: Date;
        Directions: string;
        Comments: string;
        RegionCode: string;
        Latitude: number;
        Longitude: number;
        ClaimEmail: string;
        ScheduleID: number;
        LocationString: string;
        CreateTsmp: Date;
        CreateUser: string;
        UpdateTmsp: Date;
        UpdateUser: string;

        constructor() {
            this.LocationID = null;
            this.ClientID = null;
            this.ClientNumber = null;
            this.Status = null;
            this.ContactID = null;
            this.DBA = null;
            this.Description = null;
            this.LocationDBA = null;
            this.Address1 = null;
            this.Address2 = null;
            this.City = null;
            this.State = null;
            this.Zip = null;
            this.Zip4 = null;
            //this.PhoneAC = null;
            this.Phone = null;
            this.PhoneExt = null;
            //this.FaxAC = null;
            this.Fax = null;
            this.LocationNumber = null;
            this.StoreNumber = null;
            this.FEIN = null;
            this.StartDate = null;
            this.TerminationDate = null;
            this.Directions = null;
            this.Comments = null;
            this.RegionCode = null;
            this.Latitude = null;
            this.Longitude = null;
            this.ClaimEmail = null;
            this.ScheduleID = null;
            this.LocationString = null;
            this.CreateTsmp = null;
            this.CreateUser = null;
            this.UpdateTmsp = null;
            this.UpdateUser = null;
        }

    }

}