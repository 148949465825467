CHSI.Page.ScheduleItemSummary = {
    Settings: {
        GroupFilter: null
        , TabID: null
        , TabName: null
        , ClientID: null
        , DisplayType: 1
        , ScheduleTypeID: null
        , isPortal: false
    }
        , Cache: {
            Groups: null
            , ScheduleItemSummaries: null
            , Details: null
        },
        Init: function (isPortal, ClientID, GroupID) {
            this.SetupScheduleItemHelpers();
            this.Settings.isPortal = isPortal;
            var Template_GroupTabs = $('#Template_GroupTabs').html();
            this.Templates.GroupTabs = Handlebars.compile(Template_GroupTabs);
            var Template_ScheduleItemSummaries = $('#Template_ScheduleItemSummaries').html();
            this.Templates.ScheduleItemSummaries = Handlebars.compile(Template_ScheduleItemSummaries);
            var Template_ScheduleItemLinks = $('#Template_ScheduleItemLinks').html();
            this.Templates.Links = Handlebars.compile(Template_ScheduleItemLinks);
            if (isPortal) {
                var Template_ScheduleItemExportLinks = $('#Template_ScheduleItemExportLinks').html();
                this.Templates.ExportLinks = Handlebars.compile(Template_ScheduleItemExportLinks);
            }

            if (!isPortal) {
                this.GetFiltersFromURL();
            } else {
                this.Settings.ClientID = ClientID;
                this.Settings.GroupFilter = GroupID;
                CHSI.Page.ScheduleItemSummary.GetSummaries();
            }            
            this.BindEvents();
        },

    Ajax: {},
    Templates: {
        GroupTabs: null
        , ScheduleItemSummaries: null
       , ScheduleItemSummaryDetails: null
        , Links: null
        , ExportLinks: null
    },

    DeterminePageMode: function () {
        if (this.Settings.TabID == null) {
            CHSI.Page.ScheduleItemSummary.GetSummaries();
        }
        else {            
            CHSI.Page.ScheduleGrid.Calls.GetSummary();
        }
        //this.BindEvents();
    },
    GetFiltersFromURL: function (hashParameters) {
        if (hashParameters) {
            location.hash = '';
            location.hash = hashParameters;
        }

        var HashObject = CHSI.DOM.CreateHashObject(location.hash);
        if (HashObject.GroupName) {
            this.Settings.GroupFilter = HashObject.GroupName;
        } else {
            if (CHSI.Page.ScheduleItemSummary.Cache.Groups) {
                this.Settings.GroupFilter = CHSI.Page.ScheduleItemSummary.Cache.Groups[0].GroupName;
            }
        }
        this.Settings.TabID = HashObject.TabID;
        this.Settings.TabName = unescape(HashObject.TabName);
        if (HashObject.ClientID) {
            this.Settings.ClientID = HashObject.ClientID; 
        }
        if (HashObject.DisplayType) {
            this.Settings.DisplayType = HashObject.DisplayType;
        }
        this.Settings.ScheduleTypeID = HashObject.ScheduleTypeID;
    }
    ,
    BuildReportPath: function (ExportAll) {
        if (CHSI.Page.ScheduleItemSummary.Settings.ExportReportPath === undefined) {
            alert('invalid report path configured, check groupspec "Schedule Report Path"');
        }

        return '/CHSIFileHandler.axd?ReportName=' + CHSI.Page.ScheduleItemSummary.Settings.ExportReportPath + '&ReportType=5&ReportOptions=ScheduleTypeID%3D' + CHSI.Page.ScheduleItemSummary.Settings.ScheduleTypeID + '%26GroupID%3D' + CHSI.Page.ScheduleItemSummary.Settings.GroupFilter + '%26ClientID%3D' + ((CHSI.Page.ScheduleItemSummary.Settings.ClientID == "null" || CHSI.Page.ScheduleItemSummary.Settings.ClientID == null) ? 0 : CHSI.Page.ScheduleItemSummary.Settings.ClientID) + '%26ExportAll%3D' + (ExportAll ? 1 : 0);
    }
    ,

    BindEvents: function () {
        $('#jxPageMainContent').on('hashchange', window, function () {
            CHSI.Page.ScheduleItemSummary.GetFiltersFromURL();
            CHSI.Page.ScheduleItemSummary.BindTabs();
            CHSI.Page.ScheduleItemSummary.DeterminePageMode();
        });

        $('#jxPageMainContent').on('click', '#jxExport', function () {
            window.open('/CHSIFileHandler.axd?ReportName=reports/uw/ScheduleSummaryExport.rpt&ReportOptions&ReportType=1&ReportOptions=groupname%3D' + CHSI.Page.ScheduleItemSummary.Settings.GroupFilter);
        });

        $('#jxPageMainContent').on('click', '#jxExportAllFields', function () {
            window.open('/CHSIFileHandler.axd?ReportName=reports/uw/ScheduleSummaryDetails.rpt&ReportOptions&ReportType=1&ReportOptions=groupname%3D' + CHSI.Page.ScheduleItemSummary.Settings.GroupFilter + '%26ScheduleTypeID%3D' + CHSI.Page.ScheduleItemSummary.Settings.TabID + '%26ClientID=' + CHSI.Page.ScheduleItemSummary.Settings.ClientID);
        });

        $('#SideTabContainer').on('click', '.GroupLinks', function () {            
            var GroupName = $(this).attr('href')
            $('.GroupLinks').removeClass('activesubmit');
            $(this).addClass('activesubmit');            
            CHSI.Page.ScheduleItemSummary.GetFiltersFromURL(GroupName);
            CHSI.Page.ScheduleItemSummary.DeterminePageMode();            
        });

        $('#jxPageMainContent').on('click', '.QuoteSummary', function () {

            $('#jxScheduleItemSummariesTable').hide();

            var Div = $(this);
            var TabID = Div.data('tabid');
            var TabName = Div.data('tabname');
            var Category = Div.data('category');
            var DefaultImageID = Div.data('defaultimageid');
            location.hash = '';
            location.hash = '#GroupName=' + CHSI.Page.ScheduleItemSummary.Settings.GroupFilter + '&TabID=' + TabID + '&TabName=' + escape(TabName) + '&ClientID=' + CHSI.Page.ScheduleItemSummary.Settings.ClientID + '&ScheduleTypeID=' + TabID + '&DisplayType=' + CHSI.Page.ScheduleItemSummary.Settings.DisplayType + '&ScheduleCategory=' + escape(Category) + '&DefaultImageID=' + DefaultImageID;

            CHSI.Page.ScheduleItemSummary.GetFiltersFromURL();
            CHSI.Page.ScheduleItemSummary.DeterminePageMode();
        });
    },
    GetGroupURL: function () {
        return '/API/CRM/Group/GetGroup';
    },
    GetSummaryURL: function () {
        return '/api/UW/ScheduleItemSummary/Get?ClientID=' + this.Settings.ClientID + '&GroupName=' + this.Settings.GroupFilter;
    },
    GetGroups: function () {
        $('#jxLoading').show();
        if (CHSI.Page.ScheduleItemSummary.Ajax.filterapplication) {
            CHSI.Page.ScheduleItemSummary.Ajax.filterapplication.abort();
        }
        CHSI.Page.ScheduleItemSummary.Ajax.filterapplication = $.ajax({
            type: "GET",
            url: this.GetGroupURL(),
            cache: false,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (e) {
                CHSI.Page.ScheduleItemSummary.Cache.Groups = e;
                CHSI.Page.ScheduleItemSummary.BindGroups();
            },
            error: function (e) {
                CHSI.Common.LogObject(e);
            }
        });
    }
            , BindGroups: function () {
                if ((this.Settings.GroupFilter == '' || this.Settings.GroupFilter == null) && this.Cache.Groups.length > 0) {
                    this.Settings.GroupFilter = this.Cache.Groups[0].GroupName;
                }
                $('#jxTabMenu').html(this.Templates.GroupTabs({ Groups: this.Cache.Groups }));
                this.BindTabs();
                CHSI.Page.ScheduleItemSummary.DeterminePageMode();
            }
            , BindTabs: function () {
                var Links = $('#jxTabMenu > a');
                Links.removeClass('activesubmit');
                var ActiveLink = $('#jxTabMenu > a[href$="#GroupName=' + this.Settings.GroupFilter + '"]');
                ActiveLink.addClass('activesubmit');
            },

            GetSummaries: function () {
                $('#jxLoading').show();
             CHSI.Page.ScheduleItemSummary.Ajax.filterapplication = $.ajax({
            type: "GET",
            url: this.GetSummaryURL(),
            cache: false,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (e) {
                if (e) {
                    var obj = {};
                    for (i = 0; i < e.length; i++) {
                        if (!e[i].TypeCategory) {
                            e[i].TypeCategory = 'Other';
                        }
                        if (typeof obj[e[i].TypeCategory] == 'undefined') {
                            obj[e[i].TypeCategory] = [];
                        }
                        obj[e[i].TypeCategory].push(e[i]);
                    }
                }
                CHSI.Page.ScheduleItemSummary.Cache.ScheduleItemSummaries = obj;
                CHSI.Page.ScheduleItemSummary.BindSummaries();
            },
            error: function (e) {
                CHSI.Common.LogObject(e);
            }
        });
    }
            , BindSummaries: function () {                
                $('#jxPageMainContent').html(this.Templates.ScheduleItemSummaries({ Summaries: this.Cache.ScheduleItemSummaries }));
                $('#jxLinks').html(this.Templates.Links({ Details: false }));
                if (CHSI.Page.ScheduleItemSummary.Settings.isPortal) {
                    $('#jxExportLinks').html(this.Templates.ExportLinks({ Details: false }));
                }
                CHSI.Page.ScheduleGrid.Cache.CurrentURLParameters = null;                
                $('#jxScheduleItemSummariesTable').show();
                $('#jxLoading').hide();
            }
            
    , SetupScheduleItemHelpers: function () {
        /*if these helpers are used outside of this function, they don't belong here. If they are, they have to be prepared before they're called or the page errors.*/

        Handlebars.registerHelper('GetStyle', function (ScheduleItem) {
            var Style = '';
            if (ScheduleItem.TextColor) {
                Style += 'Color: ' + ScheduleItem.TextColor + '; ';
            }
            if (ScheduleItem.BGColor) {
                Style += 'Background-color: ' + ScheduleItem.BGColor + '; ';
            }

            return new Handlebars.SafeString(Style);
        });
        Handlebars.registerHelper('GetStatusLink', function (QuoteDetail) {
            var StatusString;
            if (QuoteDetail.Editable == 'Y') {
                StatusString = '<a href="javascript: ShowPopup(\'QuoteStatus\', \'/utilities/quotestatusedit.aspx?PolicyYear=' + QuoteDetail.PolicyPeriod + '&QuoteID=' + QuoteDetail.QuoteID + '\', \'Quote Status\', 750, 500, null, true);">' + Handlebars.Utils.escapeExpression(QuoteDetail.Status) + '</a>';
            }
            else {
                StatusString = Handlebars.Utils.escapeExpression(QuoteDetail.Status);
            }
            return new Handlebars.SafeString(StatusString);
        });

        Handlebars.registerHelper('DecodeValue', function (Value) {
            var DecodedValue = decodeURI(Value);
            return new Handlebars.SafeString(DecodedValue);
        });

        Handlebars.registerHelper('eachArray', function (context, options) {
            var ret = "";
            var index = -1;
            var size = 0;
            var isLast = false;
            if (Object.prototype.toString.apply(context) === '[object Array]') {
                size = context.length;
                for (var i = 0; i < context.length; i++) {
                    index++;
                    isLast = false;
                    if (index >= size - 1) {
                        isLast = true;
                    }
                    ret = ret + options.fn({ property: i, value: context[i], index: index, isLast: isLast, size: size });
                    }
            } else {
                for (var prop in context) {
                    size++;
                }
                for (var prop in context) {
                    index++;
                    isLast = false;
                    if (index >= size - 1) {
                        isLast = true;
                    }
                    ret = ret + options.fn({ property: prop, value: context[prop], index: index, isLast: isLast, size: size });
                }
            }

            return ret;
        });

         
}


};
