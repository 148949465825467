
interface JQuery {
    bPopup(options?: any): JQuery;
    close(options?: any): JQuery;
}
declare var bPopup: JQueryStatic;

module GUI.Windows {
    export class Popup {
        WindowID: string
        URL: string
        Title: string
        Width: number
        Height: number
        CloseFunction: any
        SuccessFunction: any
        HTMLContent: string
        isWindowLocked: boolean
        isModal: boolean
        showHelp: boolean
        isParent: boolean = false;
        
        showIFrame(clickEvent: any)
        {
            if (this.URL == '' || this.URL == null) {
                throw new Error('You tried creating an iframe popup without providing a url on the URL property');
            }
            if (clickEvent)
            {
                if (clickEvent.shiftKey || clickEvent.ctrlKey)
                {
                    window.open(this.URL);
                    clickEvent.preventDefault();
                    return;
                }
            }

            if (this.isParent) {
                (<any>parent).ShowPopup(this.WindowID, this.URL, this.Title, this.Width, this.Height, this.CloseFunction, this.isModal, this.isWindowLocked, this.showHelp, this.SuccessFunction, this.HTMLContent);
            } else {
                ShowPopup(this.WindowID, this.URL, this.Title, this.Width, this.Height, this.CloseFunction, this.isModal, this.isWindowLocked, this.showHelp, this.SuccessFunction, this.HTMLContent);
            }
        }
        showInline()
        {
            if (this.HTMLContent == '' || this.HTMLContent == null)
            {
                throw new Error('You tried creating an inline popup without populating the HTMLContent property');
            }
            ShowInlinePopup(this.WindowID, this.HTMLContent, this.Title, this.Width, this.Height, this.CloseFunction, this.isModal);
        }
        static closeThis(closeFunction) {

                closeThis(closeFunction);
            
            
        }             
        static closeThisInline(targetID): void {
            $(targetID).bPopup().close()
        }   
    }    

} 
