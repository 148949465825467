CHSI.CRM.ClientTypes.Controllers.ClientTypeAdminController = {
    ClientTypeName: null,
    TargetOutputElement: null,
    TargetTemplateElement: null,

    Cache: {

        currentObject: null,
        currentTemplate: null,
        ClientIcons: null

    },



    Init: function () {
        this.Cache.currentTemplate = Handlebars.compile(this.TargetTemplateElement.html())
        this.GetClientType();
        this.SetupGlobalEvents();


    },

    GetClientType: function () {
        if (this.ClientTypeName) {
            CHSI.CRM.ClientTypes.Models.ClientType.GetClientType(this.ClientTypeName, function (result) {
                CHSI.CRM.ClientTypes.Controllers.ClientTypeAdminController.Cache.currentObject = result;
                CHSI.CRM.ClientTypes.Controllers.ClientTypeAdminController.BindPresentationLayer();

            })
        }
        else {

            CHSI.CRM.ClientTypes.Controllers.ClientTypeAdminController.Cache.currentObject = {IconImage: ''};
            CHSI.CRM.ClientTypes.Controllers.ClientTypeAdminController.BindPresentationLayer();

        }
    },
    GetClientIconsDDL: function () {

        CHSI.CRM.Images.Models.ClientIcon.Get(function (result) {
            CHSI.CRM.ClientTypes.Controllers.ClientTypeAdminController.Cache.ClientIcons = result;
            CHSI.CRM.ClientTypes.Controllers.ClientTypeAdminController.BindClientIconsDDL();
            
        });
    }
    ,
    BindClientIconsDDL: function () {
        CHSI.Common.Log('binding');
        var HTML = [];
        HTML.push('<option value=""></option>');
        for (var i = 0; i < this.Cache.ClientIcons.length ; i++) {
            HTML.push('<option value="' + this.Cache.ClientIcons[i].toLowerCase() + '">' + this.Cache.ClientIcons[i] + '</option>');
        }
        $('#jxImageIcon').html(HTML.join(''));
        if (CHSI.CRM.ClientTypes.Controllers.ClientTypeAdminController.Cache.currentObject) {
            this.SetClientIconsDDL(this.Cache.currentObject.IconImage);
        }
        
    }
    ,
    SetClientIconsDDL: function (SelectedValue)
    {
        if ($("#jxImageIcon option[value='" + SelectedValue.toLowerCase() + "']").length > 0) {
            $('#jxImageIcon').val(SelectedValue);
        }
        else {
            $('#jxImageIcon').append('<option selected="selected" value = "' + SelectedValue + '">' + SelectedValue + '-{Icon Missing}</option>');
        }
        
    }
    ,
    BindPresentationLayer: function () {
        this.TargetOutputElement.html(this.Cache.currentTemplate({ model: this.Cache.currentObject }));
        this.GetClientIconsDDL();
        CHSI.CRM.ClientTypes.Controllers.ClientTypeAdminController.BindIconPreview((this.Cache.currentObject.IconImage))


    },
    SaveClientType: function () {
        var clienttype = this.GetClientTypeFromForm();
        if (clienttype) {

            CHSI.CRM.ClientTypes.Models.ClientType.GetClientType(clienttype.ClientTypeName, function (check) {
                
                if (check && check.ClientTypeName != clienttype.OriginalClientTypeName) {
                    alert('Company Type already exists.');
                    return false;
                }
                else {
                    CHSI.CRM.ClientTypes.Models.ClientType.SaveClientType(clienttype, function () {
                        closeThis(true);
                    });
                }
                
            })

            


        }
        
       

    },
    CancelClientType: function() {

        closeThis(false);
    },

    GetClientTypeFromForm: function () {
        var clienttypename = $('#jxClientTypeName').val();
        if (clienttypename.length > 15 || clienttypename.trim() == "") {
            alert('Invalid Company Type Name');
            return false;
        }
        var clienttype = this.Cache.currentObject ? this.Cache.currentObject : {};
        clienttype.ClientTypeName = clienttypename;
        clienttype.MemberAccess = ($('#jxMemberAccess').is(':checked') == true) ? 'Y' : 'N';
        clienttype.BrokerAccess = ($('#jxBrokerAccess').is(':checked') == true) ? 'Y' : 'N';
        clienttype.PartnerAccess = ($('#jxPartnerAccess').is(':checked') == true) ? 'Y' : 'N';
        clienttype.IconImage = $('#jxImageIcon').val();
        return clienttype;

    },
    SetupGlobalEvents: function() {
        this.TargetOutputElement.on('click','#jxSave', function () { CHSI.CRM.ClientTypes.Controllers.ClientTypeAdminController.SaveClientType(); });
        this.TargetOutputElement.on('click', '#jxCancel', function () { CHSI.CRM.ClientTypes.Controllers.ClientTypeAdminController.CancelClientType(); });

        this.TargetOutputElement.on('change',"#jxImageIcon", function () {

            CHSI.CRM.ClientTypes.Controllers.ClientTypeAdminController.BindIconPreview(($(this).val()))
            

        });

    },

    BindIconPreview: function(Image){
        var ImageHtml = this.GetIconPreview(Image);
        $('#jxIconPreview').html(this.GetIconPreview(Image));
    
    },

    GetIconPreview: function (ImageIcon) {

        if (ImageIcon == '' || ImageIcon == null) {

            return '';

        }
        else {

            return ('<img src = "/images/ClientIcons/' + ImageIcon + '" />');

        }

    }



}