var Template_IncidentSearch_ClientResults = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "    <div style=\"color: Red; font-size: 16px;\">\r\n        More than 100 records found, please refine your search.\r\n    </div>\r\n";
  },"3":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "            <table style=\"width: 100%;\" cellspacing=0 cellpadding=0 class=\"dtable sortable\">\r\n                <thead>\r\n                    <tr>\r\n                        <td style=\"width: 80px\">Incident #</td>\r\n                        <td style=\"width: 130px\">Incident Date</td>\r\n                        <td style=\"width: 150px\">Employee</td>\r\n                        <td style=\"width: 100px\">Status</td>\r\n                        <td style=\"width: 70px\">Language</td>\r\n                        <td style=\"width: 130px\">Create Date</td>\r\n                        <td style=\"width: 90px\">Created By</td>\r\n                        <td>&nbsp;</td>\r\n                    </tr>\r\n                </thead>\r\n                <tbody>\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.IncidentTableRecord : depth0), {"name":"each","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                </tbody>\r\n            </table>\r\n";
},"4":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, functionType="function";
  return "                    <tr>\r\n                        <td style=\"width: 80px\">"
    + escapeExpression(lambda((depth0 != null ? depth0.IncidentID : depth0), depth0))
    + "</td>\r\n                        <td style=\"width: 130px\">"
    + escapeExpression(((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || helperMissing).call(depth0, (depth0 != null ? depth0.IncidentDate : depth0), {"name":"CHSIDate","hash":{},"data":data})))
    + "</td>\r\n                        <td style=\"width: 150px\">"
    + escapeExpression(lambda((depth0 != null ? depth0.EmployeeName : depth0), depth0))
    + "</td>\r\n                        <td style=\"width: 100px\">"
    + escapeExpression(lambda((depth0 != null ? depth0.Status : depth0), depth0))
    + "</td>\r\n                        <td style=\"width: 70px\">"
    + escapeExpression(lambda((depth0 != null ? depth0.Language : depth0), depth0))
    + "</td>\r\n                        <td style=\"width: 130px\">"
    + escapeExpression(((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || helperMissing).call(depth0, (depth0 != null ? depth0.CreatedDate : depth0), {"name":"CHSIDate","hash":{},"data":data})))
    + "</td>\r\n                        <td style=\"width: 100px\">"
    + escapeExpression(lambda((depth0 != null ? depth0.CreatedBy : depth0), depth0))
    + "</td>\r\n\r\n                        <td style=\"width: 80px\">[<a href=\"#\" class=\"minilink\" onclick=\"showIntakeForm("
    + escapeExpression(((helper = (helper = helpers.IncidentID || (depth0 != null ? depth0.IncidentID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"IncidentID","hash":{},"data":data}) : helper)))
    + ", "
    + escapeExpression(lambda((depths[1] != null ? depths[1].ClientID : depths[1]), depth0))
    + ");\">View/Edit</a>][<a href=\"/sig/Claims/details.aspx?id="
    + escapeExpression(lambda((depth0 != null ? depth0.IncidentID : depth0), depth0))
    + "\" class=\"minilink\">Details</a>][<a href=\""
    + escapeExpression(((helpers.CreatePrintURL || (depth0 && depth0.CreatePrintURL) || helperMissing).call(depth0, (depth0 != null ? depth0.FormType : depth0), ((stack1 = (depth0 != null ? depth0.FormTypeObject : depth0)) != null ? stack1.ReportForm : stack1), (depth0 != null ? depth0.IncidentID : depth0), {"name":"CreatePrintURL","hash":{},"data":data})))
    + "\" target=\"_blank\" class=\"minilink\">Print</a>]</td>\r\n                    </tr>\r\n";
},"6":function(depth0,helpers,partials,data) {
  return "            <p>No incidents found using the supplied search filters.  Please refine your search and try again...</p>\r\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "﻿";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ShowTooManyRecords : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<br />\r\n<span style=\"font-size: 20px;\">Reported Incidents</span><hr style=\"color: #7ba2d6;\" />\r\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.IncidentTableRecord : depth0), {"name":"if","hash":{},"fn":this.program(3, data, depths),"inverse":this.program(6, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true,"useDepths":true});