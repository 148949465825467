module PL.Claims.APIs {

    export class ClaimRepo {

        private static _routePrefix: string = '/api/ClaimSystem/Claim/';
        
        public static getLateReasons(): JQueryDeferred<Models.LateReasonCodes[]> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'GetLateReasons').done(function (data) {
                results.resolve(data);
            });
            return results;
        }

    }

}