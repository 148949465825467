 
CHSI.Common.Numbers = new Object();

CHSI.Common.Numbers.FormatCurrency = function (strValue, ShowDecimals) {
    if (ShowDecimals == null) {
        ShowDecimals = false;
    }
    if(strValue != null){
    strValue = strValue.toString().replace(/\$|\,/g, '');
    dblValue = parseFloat(strValue);

    blnSign = (dblValue == (dblValue = Math.abs(dblValue)));
    dblValue = Math.floor(dblValue * 100 + 0.50000000001);
    intCents = dblValue % 100;
    strCents = intCents.toString();
    dblValue = Math.floor(dblValue / 100).toString();
    if (intCents < 10)
        strCents = "0" + strCents;
    for (var i = 0; i < Math.floor((dblValue.length - (1 + i)) / 3); i++)
        dblValue = dblValue.substring(0, dblValue.length - (4 * i + 3)) + ',' +
		dblValue.substring(dblValue.length - (4 * i + 3));
    if (ShowDecimals == true)
    { return (((blnSign) ? '' : '-') + dblValue + '.' + strCents); }
    else
    { return (((blnSign) ? '' : '-') + dblValue ); }
    }
};

CHSI.Common.Numbers.IsCurrencyNumber = function (inputValue) {
    /*Using RegEx this function will validate a number based on being numeric and a number with acceptable comma formatting and two decimals*/
    var regex = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
    if (regex.test(inputValue)) {
        return true;
    } else {
        return false;
    }
};

CHSI.Common.Numbers.IsNumber = function (inputValue) {
    /*Using RegEx this function will validate a number*/
    var regex = /^\d+$/;
    if (regex.test(inputValue)) {
        return true;
    } else {
        return false;
    }
};

CHSI.Common.Numbers.IsDecimalNumber = function (inputValue) {
    /*Using RegEx this function will validate a number*/
    var regex = /^-?([0-9]|[0-9]*\.[0-9])+(?:\.[0-9]+)?$/;
    if (regex.test(inputValue)) {
        return true;
    } else {
        return false;
    }
};