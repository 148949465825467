module PL.UW.Quotes.Model {

    export class QuoteRules {

        RuleID: number;
        QTName: string;
        RuleCode: string;
        RuleDescription: string;
        QuoteType: string;
        PolicyPeriod: number;
        GroupName: string;
        RuleValue: string;

        constructor() {

        }
    }

}