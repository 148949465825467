module PL.CRM.Models {

    export class ExcessGroupPolicy {

        constructor() {}

        GroupName: string
        GroupPolicy: string;
        EachOccurance: number;
        Aggregate: number;
        DiseasePol: number;
        DiseaseEmp: number;
        Accident: number;
        CoverageType: string;
        ExcessID: number;
        ExcessProviderLetter: string;
        GroupPolicyEff: string;
        MulitpleReinsCos: string;
        Status: string;
    }

} 