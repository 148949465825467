module PL.Claims.Modules {

    export class _$progress {

        $: JQuery;
        $progress: JQuery;
        $remaning: JQuery;

        progress: number;
        progress_chunk: number;
        status: string;
        message: string;
        remaning_count: number;

        constructor(index?: number) {

            this.$ = $('.innerCategoryProgress');
            this.$progress = this.$.eq(index);
            this.$remaning = this.$progress.find('.remaningCount');
            this.remaning_count = this.$progress.data('remaningcount');
            this.status = this.$progress.data('status');

        }

        setProgress(status: string, remaining?: number): _$progress {

            var progressClass, progressStatus, progressMessage, progressRemaining;

            this._removeClasses();

            switch (status) {

                case 'incompleted':
                    progressClass = 'progressSuccess', progressStatus = 'incompleted', progressRemaining = remaining.toString();
                    break;
                case 'completed':
                    progressClass = 'progressSuccess', progressStatus = 'completed', progressMessage = 'COMPLETED';
                    break;
                case 'review':
                    progressClass = 'progressWarning', progressStatus = 'review', progressMessage = 'REVIEW';
                    break;

            }

            this.$progress.addClass(progressClass);
            this.$progress.data('status', progressStatus).data('remaningcount', remaining);
            this.$progress.find('.categoryMessage').html(progressMessage);
            this.$remaning.html(progressRemaining);

            this.status = this.$progress.parent().data('status');

            return this;
        }

        private _removeClasses(): void {
            this.$progress.removeClass('progressSuccess progressWarning progressError');
        }

    }

}