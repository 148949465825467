CHSI.UW.QuoteStatus.Models.QuoteStatus = {

    QuoteStatus: null,
    GetAllQuoteStatus: function (resultfunction) {

        var Options = {};
        Options.URL = "/api/UW/QuoteStatus/GetAllQuoteStatus";
        Options.Success = resultfunction;
        CHSI.Common.Ajax.Get(Options);

    }

}