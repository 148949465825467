module PL.UW.LossHistory.Model {

    export class PriorCoverageLoss {
        PriorLossID: number = +$('#master').data('priorlossid');
        PriorCoverageID: number = +$('#master').data('priorcoverageid');
        GroupID: string = $('#master').data('groupid');
        xMod: number;
        NumClaims: number;
        IndClaims: number;
        Individual: boolean;
        ClaimNumber: string;
        Recovery: number;
        PaidActual: number;
        PolicyPeriod: number = +$('#master').data('masterpolicyperiod');
        PaidAdjusted: number;
        IncurredActual: number;
        IncurredAdjusted: number;
        IncurredAdjustedERA: number;
        PrimaryLosses: number;
        PrimaryERA: number;
        LossType: string|number;
        Open: string|boolean;
        Comments: string;

        constructor() {

        }

        processPriorCoverageLossValues(successFunction?: (data) => any): void {
            var PCL = new PriorCoverageLoss;
            var $losses = new PL.UW.LossHistory.Views.xMod_LossHistoryEdit_PriorCoverageLoss();

            PL.UW.Quotes.APIs.QuoteAPIs.getQuoteRulesByGroupPeriod(this.GroupID, this.PolicyPeriod, function (rData) {
                var quoteRules: PL.UW.Quotes.Model.QuoteRules[] = rData;
                var LPRIM: number = 0;
                var xSAL: number = 0;
                var IncurredAdjustedERA: number = 0;
                var bCLBR: boolean = false;

                for (var i = 0; i < quoteRules.length; i++) {
                    if (quoteRules[i].QTName == 'CHSI1') {
                        if (quoteRules[i].RuleCode.toUpperCase() == 'LPRIM') {
                            LPRIM = +quoteRules[i].RuleValue;
                        }
                    }
                }


                for (var i = 0; i < quoteRules.length; i++) {
                    if (quoteRules[i].QTName == 'CHSI1') {
                        if (quoteRules[i].RuleCode.toUpperCase() == 'XSAL') {
                            xSAL = +quoteRules[i].RuleValue;
                        }
                    }
                }

                for (var i = 0; i < quoteRules.length; i++) {
                    if (quoteRules[i].RuleCode.toUpperCase() == "CLBR") {
                        if (quoteRules[i].RuleValue != null) {
                            if (quoteRules[i].RuleValue.toUpperCase() == 'Y') {
                                bCLBR = true;
                            }
                        }
                    }
                }

                if ($losses.$individual.is(':checked')) {
                    if ($losses.$open.is(':checked')) {
                        PCL.Open = 'O';
                    } else {
                        PCL.Open = 'F';
                    }
                    PCL.ClaimNumber = $losses.$claimNumber.val();
                    PCL.NumClaims = 1;
                    PCL.IndClaims = 0;
                } else {
                    PCL.Open = '*';
                    if (bCLBR) {
                        PCL.IndClaims = $losses.$indemnity.val();
                        PCL.NumClaims = $losses.$medical.val();
                        if ($.isNumeric($losses.$claimNumber.val())) {
                            PCL.NumClaims = +$losses.$claimNumber.val();
                        }
                    } else {
                        if ($.isNumeric($losses.$medical.val())) {
                            PCL.NumClaims = +$losses.$medical.val();
                        }
                        if ($.isNumeric($losses.$indemnity.val())) {
                            PCL.IndClaims = +$losses.$indemnity.val();
                        }
                    }
                }

                PCL.IncurredActual = $losses.$incurredActual.val();
                PCL.IncurredAdjusted = $losses.$inccuredAdjusted.val();
                if (PCL.IncurredAdjusted == null || PCL.IncurredAdjusted == 0) {
                    PCL.IncurredAdjusted = PCL.IncurredActual;
                }

                var primaryLosses: number = PCL.IncurredAdjusted;
                IncurredAdjustedERA = PCL.IncurredAdjusted;

                if (PCL.NumClaims > 1) {
                    //We do not apply the LPRIM rule to multi-claim records
                } else {
                    // LPRIM                    
                    if (LPRIM != 0) {
                        if (primaryLosses > LPRIM) {
                            primaryLosses = LPRIM;
                        }
                    }

                    if (xSAL != 0) {
                        if (IncurredAdjustedERA > xSAL) {
                            IncurredAdjustedERA = xSAL;
                        }
                    }

                }
                var primaryERA: number = primaryLosses;
                var LERARuleApplied: boolean = false;
                if ($losses.$type.val() == 6) {
                    for (var i = 0; i < quoteRules.length; i++) {
                        if (quoteRules[i].RuleCode.toUpperCase() == 'LERA') {
                            if (quoteRules[i].RuleValue.toUpperCase() == 'TRUE') {
                                LERARuleApplied = true;
                            }
                        }
                    }
                }

                PCL.IncurredAdjustedERA = LERARuleApplied == true ? (IncurredAdjustedERA * 0.3) : IncurredAdjustedERA;
                PCL.PaidActual = $losses.$paidActual.val();
                PCL.PaidAdjusted = $losses.$paidAdjusted.val();
                PCL.Recovery = $losses.$recovery.val();
                PCL.LossType = $losses.$type.val();
                PCL.Comments = $losses.$lossComments.val();
                PCL.ClaimNumber = ($losses.$claimNumber.val() == '' ? null : $losses.$claimNumber.val());
                PCL.PrimaryERA = LERARuleApplied == true ? (primaryLosses * 0.3) : primaryLosses;
                PCL.PrimaryLosses = primaryLosses;

                if (successFunction) {
                    successFunction(PCL);
                }
            });
        }

        static clear(): void {
            $('#master').data('priorlossid', null);
            var $losses = new PL.UW.LossHistory.Views.xMod_LossHistoryEdit_PriorCoverageLoss();
            $losses.$individual.prop('checked', false);
            $losses.$claimNumber.val('');
            $losses.$medical.val('');
            $losses.$indemnity.val('');
            $losses.$paidActual.val('');
            $losses.$paidAdjusted.val('');
            $losses.$incurredActual.val('');
            $losses.$inccuredAdjusted.val('');
            $losses.$recovery.val('');
            $losses.$type.val('');
            $losses.$open.prop('checked', false);
            $losses.$lossComments.val('');
        }
    }

} 