

module CL.Common.Views
{
    export class AttachmentCategoryDropDown
    {
        static GetOptionHTML(AttachmentCategories: CL.Common.Models.AttachmentCategory[], selectedID: number, depth: number): string
        {
            var Results = '';
            for (var i = 0; i < AttachmentCategories.length; i++) {
                var selected = '';
                var padding = '';
                if (AttachmentCategories[i].Id == selectedID) {
                    selected = 'selected';
                }
                for (var x = 0; x < depth * 5; x++) {
                    padding += '&nbsp;';
                }
                Results += '<option value="' + AttachmentCategories[i].Id + '" ' + selected + '>' + padding + AttachmentCategories[i].Title + '</option>';

                Results += AttachmentCategoryDropDown.GetOptionHTML(AttachmentCategories[i].Children, selectedID, depth + 1);
            }
            return Results;
        }

    }

} 