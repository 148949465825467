module PL.UW.LossHistory.Model {

    export class PriorCoverageDetail {

        PriorDetailID: number = +$('#master').data('priordetailid');
        ClientID: number = +$('#master').data('clientid');
        xMod: string;
        State: string;
        Premium: string;
        PriorCoverageID: number = +$('#master').data('priorcoverageid');
        Source: string = $('#master').data('groupid');
        ClassCode: string;
        Description: string;
        PayrollAmount: number;
        ExpectedLosses: number;
        DRatio: number;
        ExpectedPrimary: number;
        ExpectedExcess: number;
        ELR: number;

        constructor() {

        }

        getValues(): PriorCoverageDetail {
            var PCD: PriorCoverageDetail = new PriorCoverageDetail();
            var $payroll = new PL.UW.LossHistory.Views.xMod_LossHistoryEdit_PriorCoverageDetail();

            PCD.State = $payroll.$state.val();
            PCD.ClassCode = $payroll.$classCode.val();
            PCD.PayrollAmount = $payroll.$payroll.val();                        

            return PCD;
        }

        static clear(): void {
            $('#master').data('priordetailid', null);
            var $details = new PL.UW.LossHistory.Views.xMod_LossHistoryEdit_PriorCoverageDetail();
            $details.$classCode.val('');
            $details.$state.val('');
            $details.$payroll.val('');
        }
    }

} 