CHSI.CRM.Departments = {
    Cache: {
        Departments: null
    }
    ,Get: function (successFunction)
    {
        var Options = {};
        Options.URL = '/api/CRM/Department/Get' ;
        Options.Success = function (e) {
            CHSI.CRM.Departments.Cache.Departments = e;
            if (successFunction)
            {
                successFunction(e);
            }
        };
        CHSI.Common.Ajax.Get(Options);

    }

}