

var MinY = 400;
var MinX = 500;
var HistoryIndex = -1;
var HistoryPages = new Array();
var showpreview;
var ShowMore;
var NewestURL;
var ResizedWindows = [];
var PageModals = {};
CHSI.DOM.ExtractPageID = function (WinURL) {
    var pageid;
    if (WinURL) {
        var URL = WinURL.split("?");

        var queryobject = new PageQuery('?' + URL[1]);
        pageid = unescape(queryobject.getValue("PageID"));
    }
    return pageid;
};
CHSI.DOM.componentToHex = function (c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
};
CHSI.DOM.RGBtoHex = function (r, g, b) {
    return '#' + CHSI.DOM.componentToHex(r) + CHSI.DOM.componentToHex(g) + CHSI.DOM.componentToHex(b);
};
CHSI.DOM.aRGBtoalphaHex = function (a, r, g, b) {
    return CHSI.DOM.componentToHex(a) + CHSI.DOM.componentToHex(r) + CHSI.DOM.componentToHex(g) + CHSI.DOM.componentToHex(b);
};
/*Modal functions*/
CHSI.DOM.Modal = {};
CHSI.DOM.Modal.UpdateTitle = function (WindowID, newTitle) {
    var ModalWindow = jQuery('#modal_' + CHSI.DOM.Modal.GenerateID(WindowID));
    var Header = ModalWindow.find('.ModalTitle');
    Header.html(newTitle);
};
CHSI.DOM.Modal.CloseModal = function (WindowID) {
    var ModalWindow = jQuery('#modal_' + CHSI.DOM.Modal.GenerateID(WindowID));
    if (ModalWindow.length > 0) {
        ModalWindow.bPopup().close();
    }
};

CHSI.DOM.Modal.ResizeModal = function (ModalID, newWidth, newHeight) {
    var ModalWindow = jQuery(ModalID);
    var ModalFrame = ModalWindow.find('iframe');

    if (newWidth) {
        ModalWindow.width(newWidth);
        ModalFrame.width(newWidth - 10);
    }
    if (newHeight) {
        ModalWindow.height(newHeight);
        ModalFrame.height(newHeight - 50);
    }
    ModalFrame.attr('scrolling', 'yes');
};

CHSI.DOM.Modal.GetTemplate = function (WindowID, SiteStartColor, SiteEndColor, PageID, ShowHelp, showclose) {
    var HeaderStyle = 'background-color: #4b6480;';

    var StartColor = null;
    var StartHex = null;
    var EndColor = null;
    var EndHex = null;
    if (SiteStartColor) {
        StartColor = SiteStartColor
        StartHex = CHSI.DOM.RGBtoHex(SiteStartColor[0], SiteStartColor[1], SiteStartColor[2]);
    }
    if (SiteEndColor) {
        EndColor = SiteEndColor

        EndHex = CHSI.DOM.RGBtoHex(SiteEndColor[0], SiteEndColor[1], SiteEndColor[2]);
    }

    if (SiteStartColor) {
        HeaderStyle = 'background: ' + StartHex + '; ' +
                      'background: -moz-linear-gradient(top,  rgb(' + SiteStartColor + ') 0%, rgb(' + SiteEndColor + ') 100%); ' +
                      'background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(' + SiteStartColor + ')), color-stop(100%,rgb(' + SiteEndColor + '))); ' + 'background: -webkit-linear-gradient(top,  rgb(' + SiteStartColor + ') 0%,rgb(' + SiteEndColor + ') 100%); ' +
                      'background: -o-linear-gradient(top,  rgb(' + SiteStartColor + ') 0%, rgb(' + SiteEndColor + ') 100%); ' +
                      'background: -ms-linear-gradient(top,  rgb(' + SiteStartColor + ') 0%,rgb(' + SiteEndColor + ') 100%); ' +
                      'background: linear-gradient(to bottom,  rgb(' + SiteStartColor + ') 0%, rgb(' + SiteEndColor + ') 100%); ' +
                      'filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="' + StartHex + '", endColorstr="' + EndHex + '",GradientType=0 ); overflow: auto; ';
    }
    
    var closebuttonhtml = "";
    if (showclose) {
        closebuttonhtml = "<div class='CHSI-ModalClose' ><img src='/images/icons/modalclose.png' width='14' height='14' border='0' /></div>";
    }
    return "<div id='modal_" + CHSI.DOM.Modal.GenerateID(WindowID) + "' class='CHSIModal'><div class='Modalheader' style='cursor: move;" + HeaderStyle + "'>" +  closebuttonhtml + "<span class='ModalTitle'>Modal</span></div><div class='ModalBody'></div><div class='ModalStatusBar'><div id='MiniSystemNotificationIcons'></div><div id='MiniSystemNotificationResponse'></div><div id='MiniSystemNotificationProgress'></div></div></div>";
};

CHSI.DOM.Modal.GenerateID = function (newID) {
    return newID.replace(/[\/ ]/g, '_');
};

 
 
CHSI.DOM.Modal.ShowPopup = function (WindowID, WinURL, WinTitle, width, height, CloseFunction, ismodal, isWindowLocked, ShowHelp, SuccessFunction, panelContent) {
    var ModalID = '#modal_' + CHSI.DOM.Modal.GenerateID(WindowID);
    var ModalWindow = jQuery(ModalID);
    var PageID = CHSI.DOM.ExtractPageID(WinURL);
    var closablevalue = true;
    if (isWindowLocked) {
        closablevalue = false;
    }
    if (ModalWindow.length == 0) {
        ModalWindow = jQuery(CHSI.DOM.Modal.GetTemplate(WindowID, SiteStartColor, SiteEndColor, PageID, ShowHelp, closablevalue));
        jQuery('body').append(ModalWindow);
    
         
        

    }

    var autoresizeWidth = false;
    var autoresizeHeight = false;
    if (height == 0) {
        var windowHeight = Math.max(jQuery(window).height(), MinY);
        height = windowHeight - 100; /*default padding */
        autoresizeHeight = true;
    }
    else {
        height = height + 80;/*add extra for trim*/
    }

    if (width == 0) {
        var windowWidth = Math.max(jQuery(window).width(), MinX);
        width = windowWidth - 100; /*default padding */
        autoresizeWidth = true;
    }
    else { width = width + 50; }
    var WindowType = 'window';
    var theCloseFunction = null;
    var theSuccessFunction = null;
    
    var ContentType = 'iframe';
    if (panelContent != null) {
        ContentType = null;
        WinURL = null;
    }
    if (ModalWindow.is(":visible")) {
        if (ContentType == 'iframe') {
            var iFrame = ModalWindow.find('iframe');

            iFrame.attr('src', WinURL);
        }
        else {
            var ContentWindow = ModalWindow.find('.ModalBody');
            ContentWindow.html(panelContent);
        }
    }
    else {
        CHSI.DOM.Modal.ResizeModal(ModalID, width, height);
        if (panelContent) {
            var ContentWindow = ModalWindow.find('.ModalBody');
            ContentWindow.html(panelContent);
        }
        ModalWindow.bPopup({ loadUrl: WinURL, content: ContentType, contentContainer: ModalID + ' > .ModalBody', closeClass: 'CHSI-ModalClose', modalClose: closablevalue, modal: ismodal, onClose: CloseFunction, position: ['auto', 'auto'], scrollBar: true, ModalName: WindowID });

        CHSI.DOM.Modal.ResizeModal(ModalID, width, height);
        ModalWindow.draggable({ handle: ".Modalheader" });
    }
    if (WinTitle != '' && WinTitle != null) {
        CHSI.DOM.Modal.UpdateTitle(WindowID, WinTitle);
    }
    PageModals[WindowID] = {};
    if (SuccessFunction) {
        PageModals[WindowID].SuccessfulClose = SuccessFunction;
    }

    var resizedwindow = [];
    resizedwindow.WindowID = ModalID;
    resizedwindow.autoresizeWidth = autoresizeWidth;
    resizedwindow.autoresizeHeight = autoresizeHeight;
    ResizedWindows.push(resizedwindow);

    return void (0);
};


function ClearHistory() {
    HistoryIndex = -1;
    HistoryPages = new Array();
};

function AddHistory(PageUrl) {
    if (HistoryIndex == HistoryPages.length - 1 || HistoryIndex == -1) {
        HistoryIndex = HistoryIndex + 1;
        HistoryPages[HistoryIndex] = PageUrl;
    }
    else { }
};

function hidePopupWin(ModalWin) {
    CHSI.DOM.Modal.CloseModal(ModalWin);
};

 

function ShowInlinePopup(WindowID, panelcontent, WinTitle, width, height, CloseFunction, ismodal) {
    return ShowPopup(WindowID, null, WinTitle, width, height, CloseFunction, ismodal, false, false, null, panelcontent);
};

function ShowPopup(WindowID, WinURL, WinTitle, width, height, CloseFunction, ismodal, isWindowLocked, ShowHelp, SuccessFunction, panelContent) {
    return CHSI.DOM.Modal.ShowPopup(WindowID, WinURL, WinTitle, width, height, CloseFunction, ismodal, isWindowLocked, ShowHelp, SuccessFunction, panelContent);
};

jQuery(document).ready(function () {
    jQuery(window).resize(function () {
        var windowHeight = Math.max(jQuery(window).height(), MinY);
        windowHeight = windowHeight - 100; /*default padding */
        var windowWidth = Math.max(jQuery(window).width(), MinX);
        windowWidth = windowWidth - 100; /*default padding */
        var FreshResizedWindows = [];

        jQuery(ResizedWindows).each(function (index, resizedwindow) {
            var Modal = jQuery(resizedwindow.WindowID)
            if (Modal.is(':visible')) {
                var options = [];
                if (resizedwindow.autoresizeWidth == true) {
                    options.width = windowWidth;
                }
                if (resizedwindow.autoresizeHeight == true) {
                    options.height = windowHeight;
                }
                CHSI.DOM.Modal.ResizeModal(resizedwindow.WindowID, options.width, options.height);
                FreshResizedWindows.push(resizedwindow);
            }
        });
        ResizedWindows = FreshResizedWindows; /* this will remove any hidden windows*/
    });
});

function ShowPagePopup(htmlcontent, title, overridex, overridey, windowname, ismodal) {
    if (typeof (windowname) == "undefined") {
        windowname = "GenericFormWindow"
    }

    if (typeof (ismodal) == "undefined") {
        ismodal = true
    }

    if (overridex == null) {
        overridex = 600;
    }
    if (overridey == null) {
        overridey = 480;
    }

    return ShowPopup(windowname, null, title, overridex, overridey, null, ismodal, false, false, null, htmlcontent);
}

function hidePopWin() {
    var Modals = jQuery('div[id^=modal_]');
    jQuery(Modals).each(function () {
        if (jQuery(this).is(':visible')) {
            var ModalName = jQuery(this).attr('id').replace(/modal_/, '');
            CHSI.DOM.Modal.CloseModal(ModalName);
        }
    });
}

function showPopWin(navigateurl, width, height, callbackfunction, ReplacementTitle) {
    windowname = "GenericFormWindow"

    var title = 'Window';
    if (ReplacementTitle) { title = ReplacementTitle; }
    return ShowPopup(windowname, navigateurl, title, width, height, null, false, false, false, callbackfunction);
}