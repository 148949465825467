module PL.Que.Models {

    export class QuoteForm {

        ResultSetID: number;
        FormID: number;
        ClientID: number;
        QuoteID: number;
        ClientName: string;
        FormStatus: string;
        DueDate: string|Date;
        Period: number;
        PeriodString: string;
        FormName: string;
        UpdateTmsp: string|Date;
        PublishedDate: string|Date;
        PolicyPeriod: number;
        PolicyPeriodString: string;
        OpenedDate: string|Date;
        SubmittedDate: string|Date;
        Group: string;
        DisplayLabel: string;
        ReportFormName: string;
        DescriptionColumn: string;
        DescriptionColumnID: number;
        DataStructureID: number;

    }

}