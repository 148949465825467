module CL.UW.APIs {

    export class ProductRepository
    {
        getGroupProductLines(): JQueryDeferred<Model.GroupProductLine[]> {
            var Results = jQuery.Deferred();
            $.get('/API/UW/GroupProductLine/Get').done(
                function (data) {
                    Results.resolve(data);
                })

            return Results;
        }
    }

}