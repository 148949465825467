var Template_DistributionListEmailEditor = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.DistributionList : depth0), {"name":"each","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"2":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.Email : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"3":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return escapeExpression(((helper = (helper = helpers.Email || (depth0 != null ? depth0.Email : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Email","hash":{},"data":data}) : helper)))
    + ";";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "﻿<table width=\"100%\">\r\n    <tr>\r\n        <td class=\"uxLabel\">\r\n            From\r\n        </td>\r\n        <td>\r\n            <input id=\"uxEmailAddressesFrom\" type=\"text\" disabled=\"disabled\" />\r\n        </td>\r\n        <td></td>\r\n        <td></td>\r\n    </tr>\r\n\r\n    <tr>\r\n        <td class=\"uxLabel\">\r\n            To\r\n        </td>\r\n        <td colspan=\"3\">\r\n            <textarea id=\"uxEmailAddressesTo\" disabled=\"disabled\" rows=\"3\" cols=\"60\">";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.DistributionList : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</textarea>\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td class=\"uxLabel\">\r\n            Subject\r\n        </td>\r\n        <td>\r\n            <input id=\"uxMessageHeader\" class=\"required-field\" type=\"text\" />\r\n        </td>\r\n        <td colspan=\"2\"><input id=\"uxSaveToActionItem\" type=\"checkbox\" />Save to actionItem</td>\r\n\r\n    </tr>\r\n    <tr>\r\n        <td class=\"uxLabel\" valign=\"top\">\r\n            Message\r\n        </td>\r\n        <td colspan=\"3\">\r\n            <textarea id=\"uxMessageBody\" rows=\"10\" cols=\"60\"></textarea>\r\n\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td class=\"uxLabel\">Attachments</td>\r\n        <td class=\"Values\">\r\n            <div id=\"jxAttachmentGrid\" style=\"height: 60px; overflow-y: auto; overflow-x: hidden;\">\r\n            </div>\r\n        </td>\r\n    </tr>\r\n</table>\r\n";
},"useData":true});