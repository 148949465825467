declare function ShowPopup(WindowID?, WinURL?, WinTitle?, width?, height?, CloseFunction?, ismodal?, isWindowLocked?, ShowHelp?, SuccessFunction?, panelContent?);
declare function ShowInlinePopup(WindowID, panelcontent, WinTitle, width, height, CloseFunction, ismodal);
declare function closeThis(closeFunction);

module PL.Que.Controllers {

    interface IFormController {        
        FormObject: PL.Que.Models.QuestionnaireBase;
        StructureObject: GUI.Smart.SmartObject;    
        FormController?: PL.Que.Controllers.FormController;    
    }

    export class FormController implements IFormController {       

        FormID: number;

        $formGrid: GUI.Tables.DTables = new GUI.Tables.DTables;

        Detail: DetailController;
        Section: ContentController;        
        Action: ActionController;
        Structure: DataStructureController;
        hasPendingDeletedContent: boolean = false;
        hasPendingNewContent: boolean = false;

        FormObject: PL.Que.Models.QuestionnaireBase = new PL.Que.Models.QuestionnaireBase;
        StructureObject: GUI.Smart.SmartObject = new GUI.Smart.SmartObject;

        constructor() {                                                            
            this.Detail = new DetailController(this);
            this.Section = new ContentController(this);            
            //this.Action = new ActionController(_self);                                                                         
        }              
        
        initFormGrid(onSuccess?: () => any): void {      
            var _self = this;
            $.get('/API/QUE/DataStructure/GetDataStructures').done(function () {}).then(function (sData) {
                _self.StructureObject = sData;
                $.get('/API/QUE/Form/GetForms').done(function () {}).then(function (fData) {
                    _self.FormObject.Form.GridFormObject = fData;                                        

                    _self.displayFormGrid();
                    _self.setupFormEvents();

                    if (onSuccess) {
                        onSuccess();

                    }
                });
            });                                                            
        }        

        setupFormEvents(): void {
            var _self = this;

            $('#jxQuestionnaireFormContainer table').on('click', 'tr',(e) => {

                var gridData = _self.$formGrid.$grid.row(e.currentTarget).data();
                var formID = gridData[0];

                _self.FormID = formID;
                _self.displayFormEditor(formID);

            });

            $('#jxNewForm').on('click', function () {                
                var url = '/que/modals/formpicker.aspx';
                var picker: PL.Que.FormPicker = new PL.Que.FormPicker;
                
                picker.ParentContext = _self;

                GUI.Utilities.Helpers.ShowPopup('questionnaireformedit', url, 'Form Data Structure Selector', 400, 120, displayEditor);
            });

        }        

        buildFormColumnDefinitions(): void {            
            var Column: GUI.Smart.SmartColumnObject = new GUI.Smart.SmartColumnObject;
            var ClientColumnDefinition = null;

            Column.title = "";
            Column.data = null;
            Column.orderable = false;
            Column.class = 'details-control';
            Column.defaultContent = '[<a href="#" class="minilink jxEditForm">Edit</a>]';

            this.FormObject.Form.GridFormObject.Columns.push(Column);

            this.bindForms();
        }

        bindForms(): void {
            this.$formGrid = new GUI.Tables.DTables;
                                    
            this.$formGrid.containerName = 'jxQuestionnaireFormContainer';
            this.$formGrid.tableName = 'jxFormGrid';
            this.$formGrid.data = this.FormObject.Form.GridFormObject;

            this.$formGrid.buildTable();
        }                                                   

        displayFormEditor(formID?: number): void {
            var _self = this;    
            
            $('#jxFormGrid').remove();
            $('#jxQuestionnaireFormContainer').hide();

            $('#FormEditor').show();

            $('#FormEditTabs').tabs();

            if (formID == null) {                
                var qs = IO.JQueryHelper.parseQuerystring();
                formID = +qs.formid;    
            }

            PL.Que.APIs.FormAPI.getDataStructures(function (sData) {

                _self.StructureObject = sData;

                PL.Que.APIs.FormAPI.getCompleteFormByFormID(formID, function (completeFormObject) {

                    if (_self.FormObject == null || _self.FormObject == undefined) {
                        _self.FormObject = new Que.Models.QuestionnaireBase;
                    }

                    _self.FormObject.Form = completeFormObject;
                    _self.FormObject.Sections = completeFormObject.Sections;
                    _self.FormObject.Elements = completeFormObject.ElementValues;

                    _self.reconstructFormObject(completeFormObject);

                    _self.Detail.initDetails(_self);                
                    _self.Section.initSections(_self);
                });

            });

        }        

        displayFormGrid(): void {
        
            $('#FormEditor').hide();
            $('#jxQuestionnaireFormContainer').show();          
              
            this.buildFormColumnDefinitions();
        }   

        getFieldValues(): PL.Que.Models.QuestionnaireBase {
                        
            this.FormObject.Form = this.Detail.getDetailFieldValues();
            // sections
            this.FormObject.Sections = this.Section.getSectionFieldValues(this.FormObject.Form.FormID, this.FormObject);
            // elements            
            // actions
            return this.FormObject;
        }

        setFieldValues(): void {
            var _self = this;
            // details
            this.Detail.initDetails(_self);
            // sections
            this.Section.initSections(_self);
            // elements            
            //this.Element.initElements();
            // actions
            //this.Action.initAction();
        }

        getFormObjectIndexByFormID(formID: number, onSuccess?: () => any): GUI.Smart.SmartObject {
            
            var currentFormItem = null;
            for (var index = 0; index < this.FormObject.Form.GridFormObject.Values.length; index++) {
                currentFormItem = this.FormObject.Form.GridFormObject.Values[index];
                if (currentFormItem[0] == formID) {
                    return currentFormItem;
                    //break;
                }
            }
            console.log('could not locate object by formid');

            if (onSuccess) {
                onSuccess();
            }
        } 
        
        reconstructFormObject(dataObject?: any): void {
            var _self = this;
            var newObject = null;

            newObject = $.extend(true, {}, dataObject);
            newObject.Elements = [];

            for (var i = 0; i < dataObject.ElementValues.length; i++) {
                var newElement: PL.Que.Models.Element = new PL.Que.Models.Element;

                for (var x = 0; x < dataObject.ElementColumns.length; x++) {
                    newElement[dataObject.ElementColumns[x].FieldName] = dataObject.ElementValues[i][x];
                }

                newObject.Elements.push(newElement);

                for (var z = 0; z < newObject.Sections.length; z++) {

                    if (newObject.Sections[z].SectionID == newElement.FormSectionID) {
                        if (newObject.Sections[z].Elements == null) {
                            newObject.Sections[z].Elements = []
                        }

                        newObject.Sections[z].Elements.push(newElement);
                        break;
                    }
                }
            }
            _self.FormObject.Form = newObject;
        }                       
    }

    class DetailController implements IFormController {
        
        FormObject: PL.Que.Models.QuestionnaireBase;
        StructureObject: GUI.Smart.SmartObject;
        FormController: PL.Que.Controllers.FormController;

        constructor(formController?: PL.Que.Controllers.FormController) {

            this.FormObject = formController.FormObject;
            this.StructureObject = formController.StructureObject;
            this.FormController = formController;
            
        }

        initDetails(formController?: PL.Que.Controllers.FormController): void {

            this.FormObject = formController.FormObject;

            this.setDetailFieldValues();

            this.setupDetailEvents();

        }

        setDetailFieldValues(): void {            
            var formObject = this.FormObject.Form;
            $('#FormDetails').data('formid', formObject.FormID);
            $('#jxFormDetailTitle').val(formObject.Title);
            $('#jxFormDetailStatus').val(formObject.Status);
            $('#jxFormDetailDescription').val(formObject.FormDescription);
            formObject.isPortalEditable == true ? $('#jxDetailIsPortal').prop('checked', true) : $('#jxDetailIsPortal').prop('checked', false);
            $('#jxFormDetailActiveDate').val(GUI.DateTime.formatDate(formObject.ActiveDate, 'MM/DD/YYYY'));
            $('#jxFormDetailInactiveDate').val(formObject.InactiveDate == null ? "" : GUI.DateTime.formatDate(formObject.InactiveDate, 'MM/DD/YYYY'));
            $('#jxFormDetailDataStructure').text(PL.Que.Controllers.DataStructureController.getDataStructureNameByDataStructureID(formObject.DataStructureID, this.FormController.StructureObject)).data('datastructureid', formObject.DataStructureID);
            $('#jxFormDetailFormType').val(formObject.DesignTemplateID.toString());
        }

        getDetailFieldValues(): PL.Que.Models.Form {
            this.FormObject.FormID = $('#FormDetails').data('formid') != null ? $('#FormDetails').data('formid') : 0;
            this.FormObject.Title = $('#jxFormDetailTitle').val();
            this.FormObject.DataStructureID = $('#jxFormDetailDataStructure').data('datastructureid');
            this.FormObject.FormDescription = $('#jxFormDetailDescription').val();
            this.FormObject.DesignTemplateID = $('#jxFormDetailFormType').val();
            this.FormObject.DisplayTitle = $('#jxFormDetailTitle').val();
            this.FormObject.Status = $('#jxFormDetailStatus').val();
            this.FormObject.isPortalEditable = $('#jxDetailIsPortal').is(':checked');
            this.FormObject.InactiveDate = $('#jxFormDetailInactiveDate').val() == "" ? null : $('#jxFormDetailInactiveDate').val();
            this.FormObject.ActiveDate = $('#jxFormDetailActiveDate').val();
            this.FormObject.ReportID = null;
            return this.FormObject.Form;
        }

        setupDetailEvents(): void {
            var _self = this;

            //$('#FormEditor').hide();                        

            $('#jxQueFormBack').on('click', function () {                
                _self.FormController.initFormGrid();
            });

            $('#jxQueFormCancel').on('click', function () {                                
                _self.FormController.initFormGrid();
            });

            $('#jxQueFormSave').off().on('click', function () {                
                PL.Que.APIs.FormAPI.saveCompleteFormObject(_self.FormController.getFieldValues(), null, function () {
                    
                    _self.FormController.initFormGrid();

                });
            });

            $(".jxDateClass").datepicker({
                showOn: "both",
                buttonImage: "/images/icons/cal.gif",
                buttonImageOnly: true
            });

            $('#FormEditTabs').tabs({

                activate: function (event, ui) {
                    var currentTab = $("#FormEditTabs").tabs("option", "active");
                    alert(currentTab);
                }

            });                       
            
        }

        getFormDetailHTML(): void {
            var Template_QuestionnaireForm = $('#QuestionnaireForm_Template').html();
            $('#jxQuestionnaireFormContainer').html(Template_QuestionnaireForm);           
        }
          

    }

    class ContentController implements IFormController  {

        FormObject: PL.Que.Models.QuestionnaireBase;
        StructureObject: GUI.Smart.SmartObject;
        FormController: PL.Que.Controllers.FormController;

        SectionsTemplate: HandlebarsTemplateDelegate;
        ElementsTemplate: HandlebarsTemplateDelegate;

        SectionContainerName: string;
        SectionListItemName: string;
        ElementContainerName: string;
        ElementListItemName: string;
        SectionsObject: PL.Que.Models.Section[];
        ElementsObject: PL.Que.Models.Element[];

        _$sectionContainer: JQuery;
        get $sectionContainer(): JQuery {
            return $('#' + this.SectionContainerName);
        }
        set $sectionContainer(containerName: JQuery) {
            this._$sectionContainer = $('#' + this.SectionContainerName);
        }

        _$sectionListItem: JQuery;
        get $sectionListItem(): JQuery {
            return $('#' + this.SectionListItemName);
        }
        set $sectionListItem(containerName: JQuery) {
            this._$sectionContainer = $('#' + this.SectionListItemName);
        }

        _$elementListItem: JQuery;
        get $elementListItem(): JQuery {
            return $('.' + this.ElementListItemName);
        }
        set $elementListItem(containerName: JQuery) {
            this._$elementContainer = $('.' + this.ElementListItemName);
        }

        _$elementContainer: JQuery;
        get $elementContainer(): JQuery {
            return $('.' + this.ElementContainerName);
        }
        set $elementContainer(containerName: JQuery) {
            this._$elementContainer = $('.' + this.ElementContainerName);
        }        

        constructor(formController?: PL.Que.Controllers.FormController) {

            this.FormObject = formController.FormObject;
            this.StructureObject = formController.StructureObject;
            this.FormController = formController;         
            
        }

        initSections(formController?: PL.Que.Controllers.FormController) {            

            this.FormObject = formController.FormObject;

            this.SectionContainerName = 'jxQuestionnaireFormSectionsContainer';
            this.SectionListItemName = 'jxFormSectionList';
            this.ElementContainerName = 'jxFormElementList';
            this.ElementListItemName = 'jxFormElementList';
            this.SectionsObject = this.FormObject.Sections;
            this.ElementsObject = this.FormObject.Elements;
            this.bindSectionTemplate();
            this.setupContentEvents();

        }        

        getSectionTemplate(): void {

            var Template_Sections = $('#Template_QuestionnaireFormSections').html();
            this.SectionsTemplate = Handlebars.compile(Template_Sections);

        }

        bindSectionTemplate(): void {

            this.getSectionTemplate();

            this.$sectionContainer.html(this.SectionsTemplate({ Section: this.FormObject.Form.Sections }));

        }

        getElementTemplate(): void {
            var _self = this;
            var Template_Elements = $('#Template_QuestionnaireFormElements').html();
            this.ElementsTemplate = Handlebars.compile(Template_Elements);

            _self.$elementListItem.sortable({
                placeholder: "hightLightDropShadowElements"
            });
        }

        bindElementTemplate(index?: number): void {
            var _self = this;
            this.getElementTemplate();

            $('.' + _self.ElementContainerName + ':eq(' + index + ')').html(this.ElementsTemplate({ Element: this.FormObject.Form.Sections[index].Elements }));
            
        }

        setupContentEvents(): void {
            var _self = this;

            $('.ElementMasterContainer').hide();

            _self.$sectionListItem.sortable({
                placeholder: "hightLightDropShadowSections"
            });            

            _self.$elementContainer.sortable({
                placeholder: "hightLightDropShadowElements"
            });
                                    
            this.$sectionContainer.on("sortupdate", function (event, ui) {
                _self.SectionsObject = GUI.Sortable.recalculateOrder(_self.SectionsObject, $('#' + _self.SectionContainerName + ' li'), "SortOrder", "SectionID");
            });

            $('.jxFormElementList').off().on('click', '.jxFormElementDelete', function () {
                
                var sectionID = $(this).parent().data('sectionid'),
                    elementID = $(this).parent().data('elementid'),
                    sectionIndex = $(this).parent().parent().parent().data('sectionindex'),
                    elementIndex = $(this).parent().data('elementindex');

                $(this).parent().parent().parent().prop('disabled', true);

                var r = confirm('Please confirm that you would like to delete this element before continuing.');

                if (r) {
                    _self.deleteElement(sectionID, elementID, sectionIndex, elementIndex);
                    _self.bindElementTemplate(elementIndex);
                    _self.setupContentEvents();
                }
            });

            $('.jxFormSectionDelete').on('click', function () {                
                var sectionid = $(this).parent().parent().data('sectionid'),
                    index = $(this).parent().parent().parent().data('sectionindex');

                var r = confirm('Please confirm that you would like to delete this section before continuing.');

                if (r) {
                    _self.deleteSection(sectionid, index);                    
                    _self.bindSectionTemplate();
                    _self.setupContentEvents();
                }                
            });

            $('#jxQueFormNewSection').off().on('click', function () {
                
                var index = $(this).parent().parent().data('sectionindex');                
                
                _self.addNewSection(_self.FormObject);
                _self.setupContentEvents();

            });

            $('.jxQueFormNewElement').off().on('click', function () {
                var parentContainer = $(this).parent().parent().parent();
                var index = $(this).data('sectionindex');
                var elementTypeID = $('.jxElementTypeSelect').val();
                parentContainer.find('.ElementMasterContainer').show();       
                parentContainer.data('iscollapsed', true);
                
                _self.addNewElement(_self.FormObject, index, elementTypeID);
                _self.setupContentEvents();                
            });

            this.$sectionContainer.off().on('click', '.EditEditor', function () {
                ShowInlinePopup('richtexteditor', $('#InLineEditorModal').html(), 'Rich Text Editor', 555, 405, null, true);
            });

            $('.expanded').on('click', function () {                
                _self.toggleCollapse(true, this);
            });

            $('.collapsed').off().on('click', function () {
                _self.toggleCollapse(false, this);                
            });


            //$('#jxSaveText').on('click', function () {

            //});

            $('.jxSectionListItem').each(function () {
                if ($(this).data('iscollapsed')) {
                    $(this).find('.ElementMasterContainer').show();
                } else {
                    $(this).find('.ElementMasterContainer').hide();
                }
            });
                        
        }
        toggleCollapse(isCollapsed: boolean, ThisContext: any): void {
            var _self = this;
            var parentContainer = $(ThisContext).parent().parent().parent();
            var Index = parentContainer.data('sectionindex');
            var HTMLRendered = parentContainer.data('htmlrendered');

            if (isCollapsed) {                                
                if (HTMLRendered) {
                    parentContainer.find('.ElementMasterContainer').show();
                } else {                    
                    _self.bindElementTemplate(Index);
                    parentContainer.data('htmlrendered', true);
                    parentContainer.find('.ElementMasterContainer').show();
                }
            } else {                                                   
                parentContainer.find('.ElementMasterContainer').hide();                                
            }

            parentContainer.data('iscollapsed', isCollapsed);
            _self.FormObject.Form.Sections[Index].isCollapsed = isCollapsed
        }

        getSectionFieldValues(formID: number, formObject: PL.Que.Models.QuestionnaireBase): PL.Que.Models.Section[]{
            var _self = this;
            formObject.Sections = [];
            $('.jxSectionListItem').each(function (index, element) {

                formObject.Sections.push({

                    FormID: formID,
                    SectionID: $(this).data('sectionid'),
                    Title: $(this).find('.FormSectionTitle').val(),
                    Tooltip: '',
                    CurrentVisibility: 0,
                    SectionIndex: index,
                    isRequired: false,
                    Status: 'Active',
                    SortOrder: index,
                    Elements: _self.getElementFieldValues(index),
                    isCollapsed: false,                    
                });

            });

            return formObject.Sections;
        }

        getElementFieldValues(SectionIndex: number): PL.Que.Models.Element[]{
            var elementList: PL.Que.Models.Element[] = new Array<PL.Que.Models.Element>();
            var element: PL.Que.Models.Element;            

            $('.jxElementListItem').each(function () {
                element = new PL.Que.Models.Element;

                element.ElementID = 0;
                element.ParentID = 0;
                element.Label = '';
                element.BlockType = 0;
                element.ElementType = 0;
                element.FormSectionID = 0;
                element.IsRequired = false;
                element.AllowNA = false;
                element.AllowHTML = false;
                element.Status = '';                              

                elementList.push(element);
            });            
            
            return elementList;            
        }

        addNewSection(currentFormObject: PL.Que.Models.QuestionnaireBase): void {           

            currentFormObject.Form.Sections.push({

                Title: '',
                FormID: currentFormObject.FormID,
                SectionID: null,
                SectionIndex: 0,
                SortOrder: this.FormObject.Sections.length,
                Status: 'Active',
                isRequired: false,
                CurrentVisibility: 0,
                Tooltip: '',
                Elements: [],
                isCollapsed: false
            });
                        
            this.bindSectionTemplate();
        }

        addNewElement(currentFormObject: PL.Que.Models.QuestionnaireBase, sectionIndex?: number, elementTypeID?: number): void {            
            var newElement: Que.Models.Element = new Que.Models.Element;

                newElement.ElementID= null,
                newElement.ParentID= null,
                newElement.Label= '',
                newElement.BlockType= 0,
                newElement.ElementType = elementTypeID,
                newElement.FormSectionID= 0,
                newElement.IsRequired= true,
                newElement.AllowNA= true,
                newElement.AllowHTML= true,
                newElement.TemplateRegionIndex = ''                
            
                if (currentFormObject.Form.Sections[sectionIndex].Elements == undefined || currentFormObject.Form.Sections[sectionIndex].Elements == null) {
                    currentFormObject.Form.Sections[sectionIndex].Elements = new Array<PL.Que.Models.Element>();
                }

            currentFormObject.Form.Sections[sectionIndex].Elements.push(newElement);

            this.bindElementTemplate(sectionIndex);
        } 

        deleteSection(SectionID: number, Index: number): void {
            var _self = this;
            if (SectionID) {
                for (var i = 0; i < _self.FormObject.Form.Sections.length; i++) {

                    if (_self.FormObject.Form.Sections[i].SectionID == SectionID) {
                        _self.FormObject.Form.Sections[i].Status = 'Deleted';
                    }

                }
            } else {
                _self.FormObject.Form.Sections[Index].Status = 'Deleted';
            }

        }             
        
        deleteElement(SectionID: number, ElementID: number, SectionIndex?: number, ElementIndex?: number): void { // refactor element delete
            var _self = this;

            if (SectionIndex) {
                for (var i = 0; i < _self.FormObject.Form.Sections.length; i++) {

                    if (_self.FormObject.Form.Sections[i].SectionID == SectionID) {

                        for (var x = 0; x < _self.FormObject.Form.Sections[i].Elements.length; x++) {

                            if (_self.FormObject.Form.Sections[i].Elements[x].ElementID == ElementID) {
                                _self.FormObject.Form.Sections[i].Elements[x].Status = 'Deleted';
                            }

                        }

                    }

                }
            } else {
                _self.FormObject.Form.Sections[SectionIndex].Elements[ElementIndex].Status = 'Deleted';
            }
        }
        
        pendingDeletedWaterMark(): void {
            // TODO
        }  
    }

    class ActionController implements IFormController  {

        FormObject: PL.Que.Models.QuestionnaireBase;
        StructureObject: GUI.Smart.SmartObject;
        FormController: PL.Que.Controllers.FormController;

        constructor(formController: PL.Que.Controllers.FormController) {

            this.FormObject = formController.FormObject;
            this.StructureObject = formController.StructureObject;
            this.FormController = formController;
        }

        initAction(): void {

        }

        getActionFieldValues(): void {

        }

        setActionFieldValues(): void {

        }

        setupActionEvents(): void {

        }
    }

    export class DataStructureController   {

        static DataStructures: any;        

        static getDataStructureNameByDataStructureID(dataStructureID: number, dataStructureObject: any): string {            
            var _self = this;
            var dataStructureName: string;
            for (var i = 0; i < dataStructureObject.Values.length; i++) {
                if (+dataStructureObject.Values[i][0] == dataStructureID) {
                    dataStructureName = dataStructureObject.Values[i][2];
                }
            }
            return dataStructureName;
        }

        static bindDataStructuresList(containerID: string): void {                        
            var _self = this;
            var dataStructureID: number = null;
            var HTML = [];

            PL.Que.APIs.FormAPI.getDataStructures(function (data) {

            _self.DataStructures = data;

            HTML.push('<select id="jxDataStructureSelect">');

            if (_self.DataStructures.Values.length > 1) {
                HTML.push('<option></option>');
            }

            for (var structure = 0; structure < _self.DataStructures.Values.length; structure++) {

                if (dataStructureID != null) {
                    if (dataStructureID == _self.DataStructures.Values[structure][0]) {
                        HTML.push('<option value="' + _self.DataStructures.Values[structure][0] + '" selected>' + _self.DataStructures.Values[structure][1] + '</option>');
                    } else {
                        HTML.push('<option value="' + _self.DataStructures.Values[structure][0] + '">' + _self.DataStructures.Values[structure][1] + '</option>');
                    }
                } else {
                    if (_self.DataStructures.Values.length <= 1) {
                        HTML.push('<option value="' + _self.DataStructures.Values[structure][0] + '" selected>' + _self.DataStructures.Values[structure][1] + '</option>');
                    } else {
                        HTML.push('<option value="' + _self.DataStructures.Values[structure][0] + '">' + _self.DataStructures.Values[structure][1] + '</option>');
                    }
                }
            }
            HTML.push('</select>');

            $('#' + containerID).html(HTML.join(''));
            });

        }       
    }
}

var displayEditor = function () { new PL.Que.Controllers.FormController().displayFormEditor(); };
