var Template_DistributionListAdmin = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return " checked=\"checked\" ";
  },"3":function(depth0,helpers,partials,data) {
  return " ";
  },"5":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return escapeExpression(((helper = (helper = helpers.ListName || (depth0 != null ? depth0.ListName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ListName","hash":{},"data":data}) : helper)));
  },"7":function(depth0,helpers,partials,data) {
  return "";
},"9":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return escapeExpression(((helper = (helper = helpers.Description || (depth0 != null ? depth0.Description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"Description","hash":{},"data":data}) : helper)));
  },"11":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return escapeExpression(((helper = (helper = helpers.ListSource || (depth0 != null ? depth0.ListSource : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ListSource","hash":{},"data":data}) : helper)));
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "﻿<table width=\"100%\">\r\n    <tr>\r\n        <td>\r\n            Distribution List Type\r\n        </td>\r\n        <td>\r\n            <select id=\"jxDistributionListType\">\r\n                <option value=\"1\" "
    + escapeExpression(((helpers.Selected || (depth0 && depth0.Selected) || helperMissing).call(depth0, (depth0 != null ? depth0.ListTypeID : depth0), 1, {"name":"Selected","hash":{},"data":data})))
    + ">Custom</option>\r\n                <option value=\"5\" "
    + escapeExpression(((helpers.Selected || (depth0 && depth0.Selected) || helperMissing).call(depth0, (depth0 != null ? depth0.ListTypeID : depth0), 5, {"name":"Selected","hash":{},"data":data})))
    + ">Constant Contact</option>\r\n            </select>\r\n        </td>\r\n        <td>\r\n            <input id=\"jxFax\" type=\"checkbox\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.AllowFax : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " />Fax\r\n        </td>\r\n        <td>\r\n            <input id=\"jxPhone\" type=\"checkbox\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.AllowPhone : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " />Phone\r\n        </td>\r\n        <td>\r\n            <input id=\"jxPost\" type=\"checkbox\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.AllowPost : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " />Post\r\n        </td>\r\n        <td>\r\n            <input id=\"jxEmail\" type=\"checkbox\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.AllowEmail : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " />Email\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>\r\n            Display Name\r\n        </td>\r\n        <td colspan=\"2\">\r\n            <input id=\"jxDisplayName\" type=\"text\" value=\"";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ListName : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.program(7, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" />\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>\r\n            Private\r\n        </td>\r\n        <td>\r\n            <input id=\"jxPrivate\" type=\"checkbox\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isPrivate : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " />\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>\r\n            Active\r\n        </td>\r\n        <td>\r\n            <input id=\"jxActive\" type=\"checkbox\" ";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.Status : depth0), "active", {"name":"compare","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += " />\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>\r\n            Description\r\n        </td>\r\n        <td colspan=\"3\">\r\n            <input id=\"jxDescription\" type=\"text\" value=\"";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.Description : depth0), {"name":"if","hash":{},"fn":this.program(9, data),"inverse":this.program(7, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" />\r\n        </td>\r\n    </tr>\r\n</table>\r\n<table width=\"100%\" id=\"jxListSourceContainer\">\r\n    <tr>\r\n        <td valign=\"top\" width=\"138\">\r\n            List Source\r\n        </td>\r\n        <td>\r\n            <textarea id=\"jxListSource\" rows=\"10\" cols=\"50\">";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ListSource : depth0), {"name":"if","hash":{},"fn":this.program(11, data),"inverse":this.program(7, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</textarea>\r\n            <select id=\"jxConstantContactList\" style=\"display:none;\"></select>\r\n        </td>\r\n    </tr>\r\n</table>\r\n<div>\r\n    <input id=\"jxDelete\" type=\"button\" class=\"buttonPadding\" value=\"Delete This List\" />\r\n    <input id=\"jxSave\" class=\"buttonFloat buttonPadding\" type=\"button\" value=\"Save\" />\r\n    <input id=\"jxCancel\" class=\"buttonFloat buttonPadding\" type=\"button\" value=\"Cancel\" onclick=\"closeThis(true);\" />\r\n</div>    ";
},"useData":true});