namespace PL.Common.APIs {

    export class ContentType_Repo {

        private static _routePrefix: string = '/api/Common/ContentType/';

        public static get(): JQueryDeferred<PL.Common.Models.ContentType[]> {
            var $result = $.Deferred<any>();
            $.get(this._routePrefix + 'Get').done((data) => { $result.resolve(data); });
            return $result;
        }
    }

}