namespace PL.Claims.Controllers {

    export class ClaimIntakeNotificationV2Controller extends Abstract.ClaimIntakeFormBase {

        private _$notification_container: JQuery;
        private _$notification_content: JQuery;
        private additionalInformation: any;
        CategoryIndex: number;
        ClaimIntakeForm: Models.IntakeForm;                

        init(): void {  
            this._$notification_container = $('#formInformationContainer');
            this._$notification_content = $('#formInformation');
            this.CategoryIndex = null;         
            this.handlebarHelpers(); 
            this.setupEvents();
            this.events();                        
        }

        setupEvents(): void {
            this._$notification_container.hide();
        }

        events(): void {

            // review actions
            this._$notification_content.on('click', '.jxReviewedInformation', () => {
                this.setCategoryStatusAsEnum(Enums.ClaimIntakeCategory_Status.COMPLETE, this.CategoryIndex);
                this.updateCategoryProgress(this.CategoryIndex);
                //hackish - review
                if (!$('#jxNext').is(':visible')) {
                    $('#jxFinalize').trigger('click');
                } else {
                    $('#jxNext').trigger('click');
                }
                
                //this.close();
            });

            // attachment actions
            this._$notification_content.on('keyup', '.attachmentNotificationDescription', (e) => {
                $(e.target).parent().find('.attachmentNotificationSave').prop('disabled', false).val(' Save ');
                if ($(e.target).val() == '') {
                    $(e.target).parent().find('.attachmentNotificationSave').prop('disabled', true);
                }

                $(e.target).parent().find('.attachmentNotificationCharacterCount').text(500 - $(e.target).val().length);
                
            });

            this._$notification_content.on('click', '.attachmentNotificationDone', (e) => {            
                $(e.target).closest('.attachmentNotificationInformation').slideUp('fast', () => {
                    $(e.target).closest('.attachmentNotificationInformation').remove();
                    if ($('.attachmentNotificationInformation').length == 0) {
                        this.close();
                    }
                });                
            });

            this._$notification_content.on('click', '.attachmentNotificationSave', (e) => {
                var fileID = $(e.target).closest('.attachmentNotificationInformation').data('fileid'), formfieldID = 0, attachment = new PL.Claims.Models.ClaimIncidentAttachments();
                attachment.FileID = fileID;
                attachment.IncidentID = this.ClaimIntakeForm.IncidentReport.IncidentID;
                attachment.FormFieldID = formfieldID;
                attachment.Description = $(e.target).parent().find('.attachmentNotificationDescription').val();
                attachment.save().done(() => {
                    $(e.target).prop('disabled', true);
                    $(e.target).val(' Saved ');
                });
            });

            // notification container actions
            this._$notification_content.on('click', '.jxOkInformation, .jxDoneInformation', () => {
                this.close();
            });

        }

        private bind(): JQueryDeferred<any> {
            var $result = $.Deferred();
            mW.handlebars.bind(CHSITemplates.ClaimIntakeForm_Notifications, { ClaimIntake: this.ClaimIntakeForm, NotificationInformation: this.additionalInformation }, this._$notification_content);
            return $result.resolve();
        }

        private close(): void {
            this._$notification_container.slideUp('fast');
        }

        getNotification(notificationRequest: Enums.ClaimIntakeNotification_Request, additionalInformation?: any): void {
            var $notification: JQuery;
            this.additionalInformation = additionalInformation;
            this.bind().done(() => {

                $('.notifcation').hide();

                switch (notificationRequest) {

                    case Enums.ClaimIntakeNotification_Request.GENERAL_INFORMATION_ONE:
                        $notification = $('#notification_general_information_one');
                        break;
                    case Enums.ClaimIntakeNotification_Request.GENERAL_INFORMATION_TWO:
                        $notification = $('#notification_general_information_two');
                        break;
                    case Enums.ClaimIntakeNotification_Request.REVIEW:
                        $notification = $('#notification_review');
                        break;
                    case Enums.ClaimIntakeNotification_Request.CATEGORY_PROGRESS_ERROR:
                        $notification = $('#');
                        break;
                    case Enums.ClaimIntakeNotification_Request.FIELD_VALIDATION_ERROR:
                        $notification = $('#notification_field_validation_error');
                        break;
                    case Enums.ClaimIntakeNotification_Request.FINISH_CATEGORIES_INCOMPLETE_ERROR:
                        $notification = $('#notification_finish_categories_incomplete_error');
                        break;
                    case Enums.ClaimIntakeNotification_Request.FINISH_INFORMATION_NOT_COMPLETED_ERROR:
                        $notification = $('#notification_finish_information_not_complete_error');
                        break;
                    case Enums.ClaimIntakeNotification_Request.FORM_CATEGORIES_COMPLETE:
                        $notification = $('#notification_form_categories_complete');
                        break;
                    case Enums.ClaimIntakeNotification_Request.FIELD_ATTACHMENT_PREVIEW_AND_EDIT:
                        $notification = $('#notification_field_attachment_preview_and_edit');
                        break;
                }

                $notification.show();

                this._$notification_container.slideDown('fast');
            });
            
        }

        handlebarHelpers(): void {

            Handlebars.registerHelper('FieldValidiationError', function () {
                var html = [];

                $('.FieldType').each(function () {
                    if ($(this).hasClass('error')) {
                        html.push('<div class="fieldError">' + $(this).parent().data('longname') + '</div>'); 
                    }
                });                

                return html.join('');
            });

        }

    }

}