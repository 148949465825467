
CHSI.CM.AttachmentTree = {
    Init: function () {

    },
    Cache: {
        AttachmentTreeObject: {}
    },
    Events: {
        GenerateAttachmentTreeview: function (AttachmentTreeObject, isCollapsed, IncludeCheckboxes, SingleObjectArray, successFunction) {

            if (SingleObjectArray) {
                AttachmentTreeObject = AttachmentTreeObject;
            } else {
                AttachmentTreeObject = AttachmentTreeObject[0];
            }

            var HTML = [];
            var Tree = AttachmentTreeObject;         
            var Root = Tree[0];

            HTML.push('<ul class="AttachmentTree treeview-modern">');
            for (var TreeIndex = 0; TreeIndex < Tree.length; TreeIndex++) {
                var TreeTopNode = AttachmentTreeObject[TreeIndex];                
                
                if (TreeTopNode.TierLevel == 0) {                    
                    HTML.push('<li>');
                    HTML.push(this.HTMLLink(TreeTopNode));
                    this.GetChildren(HTML, Tree, TreeTopNode.ProductCode, IncludeCheckboxes);
                    HTML.push('</li>');                    
                }
            }


            HTML.push('</ul>');

            jQuery('#jxFilterResultsContainer').html(HTML.join(''));
            jQuery('ul.AttachmentTree').treeview({
                animated: "normal",
                collapsed: isCollapsed,
                control: "#treecontrol"
            });
           
            if (successFunction) {
                successFunction();
            }

        },
        GetChildren: function (HTML, Collection, ParentID, IncludeCheckboxes) {
            var haschildren = false;                        
            for (var i = 0; i < Collection.length; i++) {
                if (Collection[i].ParentID == ParentID || (Collection[i].ProductCode == ParentID && Collection[i].TierLevel == 1) ) {
                        
                    Attachment = Collection[i];
                    HTML.push('<ul>');
                    HTML.push('<li>');
                    HTML.push(this.HTMLLink(Attachment, IncludeCheckboxes));
                    this.GetChildren(HTML, Collection, Attachment.SectionID, IncludeCheckboxes);
                    HTML.push('</li>');
                    HTML.push('</ul>');
                        
                }
            }
        },
        HTMLLink: function (AttachmentObject, IncludeCheckboxes) {
            var HTML = null;
            if (AttachmentObject != null) {
                if (IncludeCheckboxes) {
                    HTML = "<input type='checkbox' class='jxSectionChk' data-sectionid='" + AttachmentObject.SectionID + "' /><a class='HierarchyNode' data-sectionid='" + AttachmentObject.SectionID + "' data-allowdocuments='" + AttachmentObject.AllowDocuments + "'>" + AttachmentObject.ProductName + "</a>"
                    } else {
                    HTML = "<a class='HierarchyNode' data-sectionid='" + AttachmentObject.SectionID + "' data-allowdocuments='" + AttachmentObject.AllowDocuments + "'>" + AttachmentObject.ProductName + "</a>"
                }                                
            }                                     
            return HTML;
        },
        GetParentSection: function (SectionsObject, Value, RootParent, NewSectionsArray) {
            for (ParentIndex = 0; ParentIndex < SectionsObject.length; ParentIndex++) {
                if (SectionsObject[ParentIndex].SectionID == Value.ParentID) {
                    if (Value.ParentID != RootParent.SectionID) {
                        NewSectionsArray.push(SectionsObject[ParentIndex]);
                    }
                    return false;
                };
            }
        },
        FilterAttachmentResults: function (SearchTerm, Object) {
            SearchTerm = SearchTerm.toLowerCase();
            SearchTerm = SearchTerm.replace(/^\s*/gm, '');

            var FilteredResult = [],
                SectionIDsInUse = [{ SectionID: -1 }],
                NewSectionsArray = [],
                NewAttachmentsArray = [],
                SectionsObject = Object[0],
                AttachmentsObject = Object[1],
                RootParent = null;

            for (Index = 0; Index < SectionsObject.length; Index++) {
                var SectionItem = SectionsObject[Index];
                if (SectionsObject[Index].TierLevel == 0) {
                    RootParent = SectionsObject[Index];
                    NewSectionsArray.push(SectionItem);
                }

                if (CHSI.Common.String.FilterString(SectionItem.ProductName, SearchTerm)) {

                    CHSI.CM.AttachmentTree.Events.GetParentSection(SectionsObject, SectionItem, RootParent, NewSectionsArray);

                    NewSectionsArray.push(SectionItem);
                }
            }                       
            
            for (Index = 0; Index < AttachmentsObject.length; Index++) {

                if (CHSI.Common.String.FilterString(AttachmentsObject[Index].Description, SearchTerm) || CHSI.Common.String.FilterString(AttachmentsObject[Index].FileName, SearchTerm)) {

                    NewAttachmentsArray.push(AttachmentsObject[Index]);

                    for (i = 0; i < SectionsObject.length; i++) {

                        if (AttachmentsObject[Index].DefaultSectionID == SectionsObject[i].SectionID) {

                            var FoundSectionID = false;

                            for (e = 0; e < SectionIDsInUse.length; e++) {

                                if (SectionIDsInUse[e].SectionID == SectionsObject[i].SectionID) {
                                    FoundSectionID = true;
                                }

                            }

                            if (!FoundSectionID) {

                                NewSectionsArray.push(SectionsObject[i]);
                                SectionIDsInUse.push(SectionsObject[i]);

                            }
                        }
                    }
                }
            }

            FilteredResult.push(NewSectionsArray);
            FilteredResult.push(NewAttachmentsArray);
            return FilteredResult;
        }
    }
}

