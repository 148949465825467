
class Pagination {

    ViewPortID: string;
    $viewPort: JQuery;
    PageRenderLevel: number = 30;
    RowCount: number;
    AjaxBaseParameters: IO.AjaxBase;
    DataSet: any;

    constructor(viewPortID: string, setRowCount: number) {
        this.ViewPortID = viewPortID;
        this.$viewPort = $('#' + viewPortID);
        this.RowCount = setRowCount;
        if (this.RowCount == null) {
            this.RowCount = 10;
        }
        // make some call
    }

    getViewPortPosition(topPosition: number): number {
        var top: number = this.$viewPort.position().top; // make percentage based
        var _self = this;

        if (top > this.PageRenderLevel) {
            if (this.AjaxBaseParameters.Success == null) {
                this.AjaxBaseParameters.Success = function (data) {
                    _self.DataSet = data;
                    console.log('Retrieve set successful.');
                };
            }

            if (this.AjaxBaseParameters.Error == null) {
                this.AjaxBaseParameters.Error = function (data) {
                    console.log('Failed to retrieve data.');
                };
            }

            if (this.AjaxBaseParameters.URL == null) {
                alert('No API to query');
            } else {
                this.getPagedDataSet(function (data) {
                    _self.updateViewPort(data);
                });
            }
                        
        }
        return top;
    }

    getPagedDataSet(success: any): any {        
        var a = new IO.Ajax;
        var requestInfo = this.AjaxBaseParameters;

        var settings = {
            URL: requestInfo.URL,
            Success: requestInfo.Success(),
            Error: requestInfo.Error()
        };

        a.Get(settings);
    }

    updateViewPort(rederedData: any): void {        
        this.$viewPort.html(rederedData);
    }


} 