CHSI.Common.ErrorLogging = {

    RecordError: function (Message, InternalMessage, StackTrace, Priority)
    {
        var ErrorObject = {};
        ErrorObject.Message = Message;
        ErrorObject.InternalMessage = InternalMessage;
        ErrorObject.StackTrace = StackTrace;
        ErrorObject.SourceType = 1;/*Javascript*/
        ErrorObject.Host = window.location.hostname;
        ErrorObject.Priority = Priority;
        ErrorObject.Resolution = $(window).width() + 'x' + $(window).height();
        
        var Options = {};
        Options.URL = '/api/logging/Exception/RecordError';
        Options.Data = ErrorObject;
        Options.Success = function (data) {
            CHSI.Common.Log('Error recorded successfully - ExceptionID ' + data.ExceptionID + ', Instance ID ' + data.InstanceId);
        };
        CHSI.Common.Ajax.Post(Options);

    }


}