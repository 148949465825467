module PL.AdminTools.APIs {
    export class ClaimServiceRepository {
        getClaimImports(): JQueryDeferred<GUI.Smart.SmartObject> {
            var Results = jQuery.Deferred();
            $.get('/api/ClaimSystem/ClaimAdmin/ClaimImport').done(function (data) {
                var Result = new GUI.Smart.SmartObject();
                Result.Columns = data.Columns;
                Result.Values = data.Values;

                Results.resolve(Result);
            })
            return Results;
        }
    }
}