module CL.UW.Views
{
    export class GroupLineDocumentDropDown
    {

        public static getDropDownValues(GroupLineDocuments: Model.GroupLineDocument[], DocumentID: number)
        {
            var HTML = '';
            var existingGroupDocumentID = (DocumentID) ? DocumentID : 0;
            var Selected = (existingGroupDocumentID == -1) ? 'selected' : '';
            HTML += '<option value="-1" ' + Selected + '>Endorsement Documents</options>';
            $.each(GroupLineDocuments, function () {
                var GroupDocument = this;
                var Selected = (existingGroupDocumentID == GroupDocument.DocumentID) ? 'selected' : '';
                HTML += '<option value = "' + GroupDocument.DocumentID + '" ' + Selected + '>' + GroupDocument.Description + '</option>';
            });

            return HTML;

        }

    }
}