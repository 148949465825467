var Template_QuotePremium_Billing = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return " selected ";
},"3":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "                    <option value=\""
    + alias3(((helper = (helper = helpers.BillingRuleName || (depth0 != null ? depth0.BillingRuleName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"BillingRuleName","hash":{},"data":data}) : helper)))
    + "\" data-billingtype=\""
    + alias3(((helper = (helper = helpers.BillingType || (depth0 != null ? depth0.BillingType : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"BillingType","hash":{},"data":data}) : helper)))
    + "\" data-billingincrement=\""
    + alias3(((helper = (helper = helpers.BillingIncrement || (depth0 != null ? depth0.BillingIncrement : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"BillingIncrement","hash":{},"data":data}) : helper)))
    + "\" data-depositpercentage=\""
    + alias3(((helper = (helper = helpers.DepositPercentage || (depth0 != null ? depth0.DepositPercentage : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"DepositPercentage","hash":{},"data":data}) : helper)))
    + "\" data-numberofpayments=\""
    + alias3(((helper = (helper = helpers.NumberOfPayments || (depth0 != null ? depth0.NumberOfPayments : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"NumberOfPayments","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.BillingRuleName : depth0),(depths[1] != null ? depths[1].BillingPeriod : depths[1]),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3(((helper = (helper = helpers.BillingRuleName || (depth0 != null ? depth0.BillingRuleName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"BillingRuleName","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"4":function(depth0,helpers,partials,data) {
    return " selected";
},"6":function(depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"8":function(depth0,helpers,partials,data) {
    return "%";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "﻿<h3 style=\"clear:both; border-bottom: solid 1px gray;\">Billing</h3>\r\n<table width=\"850px\">\r\n    <tbody>\r\n        <tr>\r\n            <td style=\"width:150px;\">Billing Type</td>\r\n            <td style=\"width:130px;\">\r\n                <select id=\"jxBillingType\">\r\n                    <option value=\"Installment\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.BillingType : depth0),"Installment",{"name":"compare","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Installment</option>\r\n                    <option value=\"Reporting\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.BillingType : depth0),"Reporting",{"name":"compare","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Reporting</option>\r\n                </select>\r\n            </td>\r\n            <td style=\"width:210px\">&nbsp;</td>\r\n            <td style=\"width:330px\" rowspan=\"7\" valign=\"top\">\r\n                <label id=\"lblBillingScheduleTitle\" style=\"display:none;\"><b>Proposed Billing Schedule</b></label>\r\n                <div id=\"jxBillingSchedule\" style=\"height:180px;overflow-y:auto;\" class=\"Installment\"></div>\r\n            </td>\r\n        </tr>\r\n        <tr>\r\n            <td style=\"width:150px;\">Billing Period</td>\r\n            <td style=\"width:130px;\">\r\n                <select id=\"jxBillingPeriod\">\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.BillingRules : depth0),{"name":"each","hash":{},"fn":this.program(3, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                </select>\r\n            </td>\r\n            <td style=\"width:210px\">&nbsp;</td>\r\n        </tr>\r\n        <tr class=\"Installment\">\r\n            <td style=\"width:150px;\">First Payment Date</td>\r\n            <td style=\"width:130px;\">\r\n                <input type=\"text\" id=\"jxPaymentDate\" class=\"DatePicker AutoRefresh\" value=\""
    + alias3(((helper = (helper = helpers.FirstPaymentDate || (depth0 != null ? depth0.FirstPaymentDate : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FirstPaymentDate","hash":{},"data":data}) : helper)))
    + "\" style=\"width:70px;\" />\r\n            </td>\r\n            <td style=\"width:210px\">&nbsp;</td>\r\n        </tr>\r\n        <tr class=\"Installment\">\r\n            <td style=\"width:150px;\">Billing Increment</td>\r\n            <td style=\"width:130px;\">\r\n                <input type=\"text\" id=\"jxBillingIncrement\" class=\"AutoRefresh\" value=\""
    + alias3(((helper = (helper = helpers.BillingIncrement || (depth0 != null ? depth0.BillingIncrement : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"BillingIncrement","hash":{},"data":data}) : helper)))
    + "\" style=\"width:70px;\" />/>\r\n            </td>\r\n            <td style=\"width:210px\">&nbsp;</td>\r\n        </tr>\r\n        <tr>\r\n            <td style=\"width:150px;\">Deposit <input type=\"checkbox\" id=\"jxDepositPercent\" "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.Percentage : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " /> Percent</td>\r\n            <td style=\"width:130px;\">\r\n                <input type=\"text\" id=\"jxDepositPercentage\" class=\"AutoRefresh\" value=\""
    + alias3(((helper = (helper = helpers.DepositPercentage || (depth0 != null ? depth0.DepositPercentage : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"DepositPercentage","hash":{},"data":data}) : helper)))
    + "\" style=\"width:70px;\" /> /><label id=\"lblPercentageSign\">"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.Percentage : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</label>\r\n            </td>\r\n            <td style=\"width:210px\"><input type=\"text\" id=\"jxDepositAmount\" value=\""
    + alias3(((helper = (helper = helpers.DepositAmount || (depth0 != null ? depth0.DepositAmount : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"DepositAmount","hash":{},"data":data}) : helper)))
    + "\" disabled=\"disabled\" /><label id=\"lblDepositPlusFees\"></label></td>\r\n        </tr>\r\n        <tr class=\"Installment\">\r\n            <td style=\"width:150px;\">Number of Payments</td>\r\n            <td style=\"width:130px;\">\r\n                <input type=\"text\" id=\"jxNumberOfPayments\" class=\"AutoRefresh\" value=\""
    + alias3(((helper = (helper = helpers.NumberOfPayments || (depth0 != null ? depth0.NumberOfPayments : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"NumberOfPayments","hash":{},"data":data}) : helper)))
    + "\" style=\"width:70px;\" /> />\r\n            </td>\r\n            <td style=\"width:210px\"><input type=\"text\" id=\"jxPaymentAmount\" value=\""
    + alias3(((helper = (helper = helpers.PaymentAmount || (depth0 != null ? depth0.PaymentAmount : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"PaymentAmount","hash":{},"data":data}) : helper)))
    + "\" disabled=\"disabled\" /></td>\r\n        </tr>\r\n        <tr>\r\n            <td>\r\n                <%--<input type=\"button\" id=\"jxSave\" value=\" Save \" />--%>\r\n            </td>\r\n        </tr>\r\n    </tbody>\r\n</table> ";
},"useData":true,"useDepths":true});