CHSI.Page.SItemFormItem = {
    Init: function (ScheduleItemID, scheduleTypeID, DefaultImageID, ClientID) {
 
        var Template_ScheduleItemFormItem = $('#Template_ScheduleItemFormItem').html();
        this.Templates.ScheduleItemFormItem = Handlebars.compile(Template_ScheduleItemFormItem);

        if (ScheduleItemID) {            
            this.Calls.GetItem(ScheduleItemID);
        } else {            
            this.Cache.SItemObject = this.GetNewItem(scheduleTypeID, ClientID);
            var SuccessFunction;
            if (ClientID) {
                SuccessFunction = function () { CHSI.Page.SItemFormItem.Calls.GetClientInfo(ClientID) };
            } else {
                SuccessFunction = CHSI.Page.SItemFormItem.BindPresentationLayer();
            }
            this.Calls.GetScheduleTypeByID(scheduleTypeID, SuccessFunction);
        }
        this.Events.Global();
    },
    Templates: {

    },
    Cache: {
        SItemObject: null       
       , ClientID: null
        ,Active:true
    },
    GetNewItem: function (scheduleTypeID, ClientID) {
        return {
            Locations: []
          , ClientInfo: []
          , FileID: null
          , Comments: ''
          , ScheduleTypeID: scheduleTypeID          
          , DefaultFileID: null
          , ClientID: ClientID
        }
    },
    BindPresentationLayer: function () {
        $('#jxScheduleItemFormItemContainer').html(this.Templates.ScheduleItemFormItem(this.Cache.SItemObject));
        $('.uxScheduleItemFormUploadImage').attr('disabled', false);
    },
    Events: {
        Global: function () {
            $('#uxScheduleItemFormItemOK').on('click', function () {                
                CHSI.Page.SItemFormItem.Calls.SaveItem();
                closeThis(true);
            });

            $('#jxScheduleItemFormItemContainer').on('click', '#jxClientSelect', function () {
                var thisWindow = window.name;
                var ReturnFunctionString = ((thisWindow == '') ? 'ChangeClient' : 'parent.window.frames.' + thisWindow + '.ChangeClient');
                parent.ShowAdvancedSearchForm('ChooseClient', null, ReturnFunctionString);
            });

            $('#jxScheduleItemFormItemContainer').on('change', '#jxStatus', function () {

                CHSI.Page.SItemFormItem.Cache.SItemObject.Status = $(this).val();

            });
            
        },
        PersistFromCache: function () {
            var SItemObject = CHSI.Page.SItemFormItem.Cache.SItemObject;
            SItemObject.ClientID = $('#jxClientID').val();
            SItemObject.LocationID = $('#jxLocation option:selected').val();
            SItemObject.Name = $('#jxName').val();
            //SItemObject.Status = $('#jxStatus option:selected').val();
            SItemObject.Comments = $('#jxComments').val();
            CHSI.Page.SItemFormItem.Cache.SItemObject = SItemObject;
        }
    },
    Calls: {
        GetItem: function (ScheduleItemID) {
            var AjaxURL = '/api/UW/ScheduleItem/Get?ScheduleItemID=' + ScheduleItemID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.SItemFormItem.Cache.SItemObject = e;
                    if (e.ClientID == null) {
                        CHSI.Page.SItemFormItem.BindPresentationLayer();
                    } else {
                        
                        CHSI.Page.SItemFormItem.Cache.ClientID = e.ClientID;                        
                        CHSI.Page.SItemFormItem.Calls.GetClientInfo(CHSI.Page.SItemFormItem.Cache.ClientID);                        

                    }                    
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });        
        },        
        GetClientInfo: function (ClientID) {
            CHSI.Page.SItemFormItem.Cache.SItemObject.ClientID = ClientID;
            var AjaxURL = '/api/CRM/Clients/GetClient?ClientID=' + ClientID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.SItemFormItem.Cache.SItemObject.ClientInfo = e;
                    
                    CHSI.Page.SItemFormItem.Calls.GetLocationByClientID(CHSI.Page.SItemFormItem.Cache.SItemObject.ClientID);
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },
        GetLocationByClientID: function (ClientID) {
            var AjaxURL = '/api/CRM/Location/GetLocationsByClientID?ClientID=' + ClientID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.SItemFormItem.Cache.SItemObject.Locations = e;
                    CHSI.Page.SItemFormItem.BindPresentationLayer();
                    $("#ScheduleItemFormTabs").tabs('enable', 1);
                    $("#ScheduleItemFormTabs").tabs('enable', 2);
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },
        GetScheduleTypeByID: function (ScheduleTypeID, SuccessFunction) {
            var AjaxURL = '/api/UW/Schedule/GetScheduleTypeByScheduleTypeID?ScheduleTypeID=' + ScheduleTypeID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: true,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {                    
                    if (e.DefaultFileID) {
                        CHSI.Page.SInformation.Cache.SItemObject.DefaultFileID = e.DefaultFileID;                        
                    }
                    if (SuccessFunction) {
                        SuccessFunction(e);
                    }
                    $('.uxScheduleItemFormUploadImage').attr('disabled', false);
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },
        SaveItem: function (successFunction) {            
            CHSI.Page.SItemFormItem.Events.PersistFromCache();
            if (CHSI.Page.SItemFormItem.Cache.SItemObject.ClientID) {
                var ajaxData = [];
                ajaxData = CHSI.Page.SItemFormItem.Cache.SItemObject;
                var ajaxString = JSON.stringify(ajaxData);
                var AjaxURL = '/api/UW/ScheduleItem/SaveScheduleItem';
                $.ajax({
                    type: "POST",
                    url: AjaxURL,
                    data: ajaxString,
                    cache: true,
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    success: function (e) {
                        CHSI.Page.SItemFormItem.Cache.SItemObject.ScheduleItemID = e.ScheduleItemID;                        
                        if (successFunction) {
                            successFunction();
                        }
                    },
                    error: function (e) {
                        CHSI.Common.Log(e);
                    }
                });
            } else {
                alert('Please add a valid client');
            }
        }
    }
}