module PL.Common.Models {

    export class SmartGrid {

        Columns: Models.SmartField[];
        Values: any[][];
        ExportName: string;

    }

}