module PL.Que.APIs {

    export class QuoteForm_Repo {

        private static _routePrefix: string = '/api/Que/WYSIWYG/';

        public static getQuoteSummaries(formTypeID: number, groupID: string, status: string, policyPeriod: number, dueWithin: number, schemaTypeID: number, resultSetID: number): JQueryDeferred<Models.QuoteForm[]> {
            var results = $.Deferred();
            $.get(this._routePrefix + 'Get?FormTypeID=' + formTypeID + '&GroupID=' + groupID + '&Status=' + status + '&PolicyPeriod=' + policyPeriod + '&DueWithin=' + dueWithin + '&SchemaTypeID=' + schemaTypeID + '&ResultSetID=' + resultSetID).done((data) => {
                results.resolve(data);
            });
            return results;
        }

    }

}