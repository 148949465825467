CHSI.Que.DataStructure = {
    
    Init: function () {       
        CHSI.Que.DataStructure.Cache.ActiveOnly = $('#jxStatus').is(':checked');       
        CHSI.Que.DataStructure.Calls.GetDataStructures();
        CHSI.Que.DataStructure.Events.Global();
    },
    Templates: {
    },
    Cache: {
        GridObject: {}
        ,ActiveOnly:false

    },
    Events: {
        Global: function () {

            $('#jxFilter').on('click', '#jxStatus', function () {
                CHSI.Que.DataStructure.Cache.ActiveOnly = $(this).is(':checked');
                CHSI.Que.DataStructure.Calls.GetDataStructures();
              
            });

            $('#jxDataStructureFilter').on('click', '.jxEdit', function () {
                var Button = $(this);
                var StructureID = Button.data('structureid');               
                var ShowEditStructureURL = '/que/modals/datastructureeditor.aspx?StructureID=' + StructureID;
                ShowPopup("EditDataStructure", ShowEditStructureURL, "Edit Data Structure", 800, 580, CHSI.Que.DataStructure.Calls.GetDataStructures, true);
            });

            $('#jxAddNew').on('click', function () {
                var ShowEditStructureURL = '/que/modals/datastructureeditor.aspx';
                ShowPopup("EditDataStructure", ShowEditStructureURL, "Edit Data Structure", 800, 580, CHSI.Que.DataStructure.Calls.GetDataStructures, true);
            });
        }
    },
    BindPresentationLayer: function () {

        $('#jxDataStructureFilter').html('<table width="100%" class="dtable" id="jxDataStructureGrid"><tfoot></tfoot></table>');

        var DataSet = null;

        if (CHSI.Que.DataStructure.Cache.GridObject) {
            DataSet = CHSI.Que.DataStructure.Cache.GridObject.Values;
            var Columns = CHSI.Que.DataStructure.Cache.GridObject.Columns;
        }
        var Column = {}
        Column.title = "";
        Column.data = null;
        Column.orderable = false;
        Column.class = 'details-control';
        Column.defaultContent = '[<a href="#" class="minilink">view</a>]';
        Columns.push(Column);
        var columnDefs = [];
        var ClientColumnDefinition = {};
        ClientColumnDefinition.targets = 6;
        ClientColumnDefinition.render = function (data, type, row) {
            return "<a class='jxEdit' data-structureid='" + row[0] + "' href='#'>[Edit]</a>";
        }
        columnDefs.push(ClientColumnDefinition);

        if (DataSet != null) {
            CHSI.Que.DataStructure.Cache.GridTable = $('#jxDataStructureGrid').DataTable({
                data: DataSet,
                columns: Columns,
                lengthMenu: [[25], [25]],
                columnDefs: columnDefs,               
                stateSave: true
            })
        } else {
            var HTML = [];
            $('#jxDataStructureGrid').html('<tr><td>No results found.</td></tr>')
        }

        $('#jxLoading').hide();
    }
    , Calls: {
        GetDataStructures: function () {
            var Options = {};
            Options.URL = "/api/Que/DataStructure/GetDataStructures?ActiveOnly=" + CHSI.Que.DataStructure.Cache.ActiveOnly;
            Options.Success = function (e) { CHSI.Que.DataStructure.Cache.GridObject = e; CHSI.Que.DataStructure.BindPresentationLayer(); };                      
            CHSI.Common.Ajax.Get(Options);
        },

    }
}
