CHSI.Page.BrokerQuoteEditor = {
    Init: function (qs) {
        this.Cache.GroupID = qs.groupid;

        var Template_ClassCodes = $('#Template_ClassCodes').html();
        this.Templates.ClassCodes = Handlebars.compile(Template_ClassCodes);


        var Template_AssessmentMods= $('#Template_AssessmentMods').html();
        this.Templates.AssessmentMods = Handlebars.compile(Template_AssessmentMods);

        this.Calls.GetStates(function () {

            var d = new Date();
            var n = d.getFullYear();
            var years = [];
            years.push(n);
            years.push(n+1);
            CHSI.Page.BrokerQuoteEditor.Cache.PolicyYears = years;
            CHSI.Page.BrokerQuoteEditor.Events.ConstructStateList();
            CHSI.Page.BrokerQuoteEditor.Binds.ClassCodeLayer();
            
           
        });

        
      
        
        this.Events.Global();

        var d = new Date();
        var m = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        CHSI.Page.BrokerQuoteEditor.Cache.Date = m[d.getMonth()] + '/' + d.getDate() + '/' + d.getFullYear();        
        CHSI.Page.BrokerQuoteEditor.Cache.Year = d.getFullYear();
        /*
        CHSI.UW.MuniQuote.GetUWRule('CHSI1', CHSI.Page.BrokerQuoteEditor.Cache.GroupID, CHSI.Page.BrokerQuoteEditor.Cache.Year, 'LCM', function (data) {
            CHSI.Page.BrokerQuoteEditor.Cache.LCM = (data.RuleValue * 1);            
        });        */
    },
    Templates: {},
    Cache: {
        ClassCodesObject: {},        
        ClassCodes: [],
        States: [],
        GroupID: '',
        ConstructedStateList: [],
        Date: '',
        Year: null,
        LCM: 1,
        isClassCodeValid: false,
        AssessmentMods: {},
        AssessmentModData: {},
        PremiumAmount: 0,
        TotalGrossPrem: 0,
        TotalPayroll:0,
        EstimatedPayroll: 0,
        PolicyYears: [],
        PolicyTotal: 0,
        PolicyPeriod:2015,
        QTName:'CHSI1',
        State:''
    },
    Binds: {
        ClassCodeLayer: function () {
            $('#ClassCodesContainer').html(CHSI.Page.BrokerQuoteEditor.Templates.ClassCodes({ PolicyYears: CHSI.Page.BrokerQuoteEditor.Cache.PolicyYears, States: CHSI.Page.BrokerQuoteEditor.Cache.States, ClassCodes: CHSI.Page.BrokerQuoteEditor.Cache.ClassCodes }));

            $('#jxModParameters .jxStateSelect').show();

            $('#jxState .jxStateSelect').show();

            $('#jxDate').datepicker({
                showOn: "both",
                minDate: (-31),
                maxDate: (366),
                buttonImage: "/images/icons/cal.gif",
                buttonImageOnly: true
            });

            $('#jxDate').val(CHSI.Page.BrokerQuoteEditor.Cache.Date);

            CHSI.Page.BrokerQuoteEditor.Events.CalculateTotal('jxPayrollEdit', 'jxTotalPayroll');
            CHSI.Page.BrokerQuoteEditor.Events.CalculateTotal('jxGrossPremEdit', 'jxTotalGrossPrem');
            CHSI.Page.BrokerQuoteEditor.Cache.TotalPayroll = $('#jxTotalPayroll').val();
            CHSI.Page.BrokerQuoteEditor.Cache.TotalGrossPrem = $('#jxTotalGrossPrem').val();            
           
            if(jQuery.isEmptyObject(CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods)==false){
                CHSI.Page.BrokerQuoteEditor.Events.CalculateAssessments();
                CHSI.Page.BrokerQuoteEditor.Binds.AssessmentModLayer();
            } else {
               
                CHSI.Page.BrokerQuoteEditor.Calls.GetAssessmentMods(
                   function () {                      
                       CHSI.Page.BrokerQuoteEditor.Binds.AssessmentModLayer();
                   }
                   );
            }
           

            restart = true;
            sorttable.init();

        },
        AssessmentModLayer: function (){
            $('#AssessmentModsContainer').html(CHSI.Page.BrokerQuoteEditor.Templates.AssessmentMods({ AssessmentMods: CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods, PolicyTotal:CHSI.Page.BrokerQuoteEditor.Cache.PolicyTotal }));
            
        }
    },
    Events: {
        Global: function () {

            

            $('#jxModParameters').on('change', '#jxDate', function () {
                CHSI.Page.BrokerQuoteEditor.Cache.Date = $(this).val();

                CHSI.Page.BrokerQuoteEditor.Calls.GetAssessmentMods(function () {
                    CHSI.Page.BrokerQuoteEditor.Binds.AssessmentModLayer();
                });
            });

            $('#jxModParameters').on('change', '#jxPolicyPeriod', function () {
                CHSI.Page.BrokerQuoteEditor.Cache.PolicyPeriod = $(this).val();
                CHSI.Page.BrokerQuoteEditor.Calls.GetAssessmentMods(function () {
                    CHSI.Page.BrokerQuoteEditor.Binds.AssessmentModLayer();
                });
            });

            $('#jxModParameters').on('change', '.jxStateSelect', function () {
                CHSI.Page.BrokerQuoteEditor.Cache.State = $(this).val();
                CHSI.Page.BrokerQuoteEditor.Calls.GetAssessmentMods(function () {
                    CHSI.Page.BrokerQuoteEditor.Binds.AssessmentModLayer();
                });
            });
            $('#ClassCodesContainer').on('change', '#jxDate', function () {                
                CHSI.Page.BrokerQuoteEditor.Cache.Date = $(this).val();                
            });

            $('#ClassCodesContainer').on('click', '#jxAddClassCode', function () {
                var NewClassCodeObject = {};                

                NewClassCodeObject.GroupID = CHSI.Page.BrokerQuoteEditor.Cache.GroupID;
                NewClassCodeObject.State = $('.jxStateSelect').val();
                NewClassCodeObject.ClassCode = $('.jxClassCode').val();
                NewClassCodeObject.Location = $('.jxLocation').val();
                NewClassCodeObject.Payroll = $('.jxPayroll').val();
                NewClassCodeObject.EffectiveDate = CHSI.Page.BrokerQuoteEditor.Cache.Date;
                NewClassCodeObject.DescriptionIndex = 0;

                var Payroll = $('.jxPayroll').val();

                if (isNaN(Payroll)) {
                    alert('Please enter a valid dollar amount into the Payroll.');
                    return false;
                }

                CHSI.Page.BrokerQuoteEditor.Events.ClassCodeValidationResponse(NewClassCodeObject, function (ClassCodeResult) {
                                        
                    NewClassCodeObject.Description = ClassCodeResult.Description;

                    NewClassCodeObject.Rate = (CHSI.Math.SafeMultiply(ClassCodeResult.Rate, CHSI.Page.BrokerQuoteEditor.Cache.LCM)).toFixed(2);                    

                    NewClassCodeObject.GrossPrem = (CHSI.Math.SafeMultiply(NewClassCodeObject.Payroll / 100, NewClassCodeObject.Rate)).toFixed(2);                    

                    CHSI.Page.BrokerQuoteEditor.Cache.ClassCodes.filter(function () { return true; }); // resets the indexes of the array.
                    
                    CHSI.Page.BrokerQuoteEditor.Cache.ClassCodes.push(NewClassCodeObject);

                    CHSI.Page.BrokerQuoteEditor.Binds.ClassCodeLayer();
                });                
            });

            $('#ClassCodesContainer').on('click', '.jxEdit', function () {
                var button = $(this);
                var row = button.parent().parent();
                var isReadOnly = button.data('readonly');

                $('#jxErrorMessage').val('');

                if (isReadOnly == true) {                                        
                    button.val('Save');                    
                    button.parent().parent().find('span').hide();
                    button.parent().parent().find('input').show();
                    button.parent().parent().find('select').show();
                    button.data('readonly', false);
                } else {
                    button.val(' Edit ');

                    CHSI.Page.BrokerQuoteEditor.Events.RevalidateClassCode(row, button.data('index'));

                    if (CHSI.Page.BrokerQuoteEditor.Cache.isClassCodeValid) {
                        var indexRow = row.data('index');
                        var ClassCode = CHSI.Page.BrokerQuoteEditor.Cache.ClassCodes[indexRow];
                        ClassCode.State = row.find('.jxStateEdit select').val();
                        ClassCode.ClassCode = row.find('.jxClassCodeEdit input').val();
                        ClassCode.Location = row.find('.jxLocationEdit input').val();
                        ClassCode.Payroll = row.find('.jxPayrollEdit input').val();
                        CHSI.Page.BrokerQuoteEditor.Binds.ClassCodeLayer();
                    } else {
                        button.parent().parent().find('span').show();
                        button.parent().parent().find('input[type="text"]').hide();
                        button.parent().parent().find('input[type="button"]').show();
                        button.parent().parent().find('select').hide();
                        button.data('readonly', true);
                    }
                }

            });

            $('#ClassCodesContainer').on('click', '.jxDelete', function () {

                var ClassCodeIndex = $(this).data('index');
                var ClassCodes = CHSI.Page.BrokerQuoteEditor.Cache.ClassCodes;

                for (i = 0; i < ClassCodes.length; i++) {
                    if (ClassCodeIndex == i) {
                        ClassCodes.splice(i, 1);
                    }
                }

                CHSI.Page.BrokerQuoteEditor.Cache.ClassCodes.filter(function () { return true; }); // resets the indexes of the array.
                CHSI.Page.BrokerQuoteEditor.Binds.ClassCodeLayer();

            });


            $('#AssessmentModsContainer').on('change', '.jxAssessmentMod', function () {
                var ModID = $(this).data('modid');
                var ModValue = $(this).val();

                if ($(this).attr('type') == 'checkbox') {
                    if ($(this).is(':checked')) {
                        ModValue = $(this).data('checkedvalue');
                    } else {                       
                        ModValue = $(this).data('uncheckedvalue');
                    }
                }
                   
                CHSI.Page.BrokerQuoteEditor.Events.UpdateAssessmet(ModID, ModValue);
                CHSI.Page.BrokerQuoteEditor.Events.CalculateAssessments();
                CHSI.Page.BrokerQuoteEditor.Binds.AssessmentModLayer();

            });
        },
        ClassCodeValidationResponse: function (ClassCodeFilters, SuccessFunction) {
            CHSI.Page.BrokerQuoteEditor.Calls.ValidateClassCode(ClassCodeFilters, function (data) {
                var ClassCodeResult = data;

                var ErrorMsg = $('#jxErrorMessage');

                if (ClassCodeResult.Description == 'That is not a valid Class Code') {
                    ErrorMsg.css('color', 'red');
                    ErrorMsg.text(ClassCodeResult.Description);
                    ErrorMsg.show();
                    CHSI.Page.BrokerQuoteEditor.Cache.isClassCodeValid = false;
                } else {
                    ErrorMsg.css('color', 'green');
                    ErrorMsg.text(ClassCodeResult.Description);
                    ErrorMsg.show();
                    CHSI.Page.BrokerQuoteEditor.Cache.isClassCodeValid = true;
                    SuccessFunction(ClassCodeResult);
                }
            });
        },
        ConstructStateList: function (isHidden) {
            var HTML = [];            
           // var strstyle = isHidden == true ? 'style="display:none;"' : 'style="display:block;"';

            HTML.push('<select class="jxStateSelect" style="display:none;" >');

            if (CHSI.Page.BrokerQuoteEditor.Cache.States.length > 1) {
                HTML.push('<option value=""></option>');
            }

            for (i = 0; i < CHSI.Page.BrokerQuoteEditor.Cache.States.length; i++) {
                                
                HTML.push('<option value="' + CHSI.Page.BrokerQuoteEditor.Cache.States[i] + '">' + CHSI.Page.BrokerQuoteEditor.Cache.States[i] + '</option>');                
                
            }

            HTML.push('</select>');
            
            CHSI.Page.BrokerQuoteEditor.Cache.ConstructedStateList = HTML.join('');
        },


        ConstructDropDown: function (AssessmentMod) {
            var HTML = [];
            var Selected = '';
            HTML.push('<select class="jxAssessmentMod"  id="jxAssessmentMod_' + AssessmentMod.ModID + '" style="" data-modid="' + AssessmentMod.ModID + '">');
          
            for (i = 0; i < AssessmentMod.DefaultCollectionValues.length; i++) {
                Selected = '';
                if (AssessmentMod.DefaultCollectionValues[i].Value == AssessmentMod.DefaultValue) {
                    Selected = 'selected="selected"';
                }

                HTML.push('<option value="' + AssessmentMod.DefaultCollectionValues[i].Value + '" '+ Selected+ '>' + AssessmentMod.DefaultCollectionValues[i].Key + '</option>');

            }
            HTML.push('</select>');

            return HTML.join('');
        },

        ConstructTextBox: function (AssessmentMod) {
            var HTML = [];
            var Selected = '';
            HTML.push('<input class="jxAssessmentMod" id="jxAssessmentMod_' + AssessmentMod.ModID + '" type="text" style="" value="' + AssessmentMod.DefaultValue + '" data-modid="' + AssessmentMod.ModID + '" maxlength="10">');
            return HTML.join('');
        },

        ConstructCheckBox: function (AssessmentMod) {
            var HTML = [];
            var Selected = '';
            if (AssessmentMod.DefaultValue != "1,0") {
                Selected = 'checked="checked"';
            }
            HTML.push('<input class="jxAssessmentMod" id="jxAssessmentMod_' + AssessmentMod.ModID + '" type="checkbox" style="" value="1,0" data-uncheckedvalue="1,0" data-checkedvalue="' + AssessmentMod.DefaultCollectionValues[1].Key + '" data-modid="' + AssessmentMod.ModID + '" ' + Selected + '>');
            return HTML.join('');
        },
        GetAssessmentModControl: function (AssessmentMod) {
            var control='';
            switch(AssessmentMod.SelectionType)
            {
                case 'lookup':
                   control=CHSI.Page.BrokerQuoteEditor.Events.ConstructDropDown(AssessmentMod);
                    break;
                case 'list':
                    control = CHSI.Page.BrokerQuoteEditor.Events.ConstructDropDown(AssessmentMod);
                    break;
                case 'ddsimple':
                    control = CHSI.Page.BrokerQuoteEditor.Events.ConstructDropDown(AssessmentMod);
                    break;
                case 'ruleopt':
                    control = CHSI.Page.BrokerQuoteEditor.Events.ConstructCheckBox(AssessmentMod);
                    break;
                case 'ruleopttax':
                    control = CHSI.Page.BrokerQuoteEditor.Events.ConstructCheckBox(AssessmentMod);
                    break;
                default:
                    control = CHSI.Page.BrokerQuoteEditor.Events.ConstructTextBox(AssessmentMod);
                    break;
                
            }

            return control;
        },
        CalculateTotal: function (Class, OutputFieldID) {
            var Total = 0;
            Class = $('.' + Class + '');

            Class.each(function () {
                Total = Total += $(this).data('value');
            });

            Total = Total.toFixed(2);

            $('#' + OutputFieldID + '').val(Total);
        },
        RevalidateClassCode: function (CurrentRow, RowIndex) {
            var ClassCodes = CHSI.Page.BrokerQuoteEditor.Cache.ClassCodes;
            var UpdatedClassCodeObject = {};

            UpdatedClassCodeObject.State = CurrentRow.find('.jxStateEdit select').val();
            UpdatedClassCodeObject.GroupID = CHSI.Page.BrokerQuoteEditor.Cache.GroupID;
            UpdatedClassCodeObject.EffectiveDate = CHSI.Page.BrokerQuoteEditor.Cache.Date;
            UpdatedClassCodeObject.ClassCode = CurrentRow.find('.jxClassCodeEdit input').val();
            UpdatedClassCodeObject.Location = CurrentRow.find('.jxLocationEdit input').val();
            UpdatedClassCodeObject.Payroll = CurrentRow.find('.jxPayrollEdit input').val();
            UpdatedClassCodeObject.DescriptionIndex = 0;
                                  
            CHSI.Page.BrokerQuoteEditor.Events.ClassCodeValidationResponse(UpdatedClassCodeObject, function (ClassCodeResult) {

                UpdatedClassCodeObject.Description = ClassCodeResult.Description;

                UpdatedClassCodeObject.Rate = (CHSI.Math.SafeMultiply(ClassCodeResult.Rate, CHSI.Page.BrokerQuoteEditor.Cache.LCM)).toFixed(2);

                UpdatedClassCodeObject.GrossPrem = (CHSI.Math.SafeMultiply(UpdatedClassCodeObject.Payroll / 100, UpdatedClassCodeObject.Rate)).toFixed(2);

                for (ClassIndex = 0; ClassIndex < ClassCodes.length; ClassIndex++) {
                    if (RowIndex == ClassIndex) {
                        ClassCodes[ClassIndex].State = UpdatedClassCodeObject.State;
                        ClassCodes[ClassIndex].Description = ClassCodeResult.Description;
                        ClassCodes[ClassIndex].Location = UpdatedClassCodeObject.Location;
                        ClassCodes[ClassIndex].Payroll = UpdatedClassCodeObject.Payroll;
                        ClassCodes[ClassIndex].GrossPrem = UpdatedClassCodeObject.GrossPrem;
                        ClassCodes[ClassIndex].Rate = ClassCodeResult.Rate;
                    }
                }

                CHSI.Page.BrokerQuoteEditor.Binds.ClassCodeLayer();
            });

            if (!CHSI.Page.BrokerQuoteEditor.Cache.isClassCodeValid) {
                CurrentRow.find('.jxClassCodeEdit input').val(CurrentRow.find('.jxClassCodeEdit').data('value'));
                CurrentRow.find('.jxStateEdit').data('value', CurrentRow.find('.jxStateEdit select'));
                CurrentRow.find('.jxLocationEdit').data('value', CurrentRow.find('.jxLocationEdit input').val());
                CurrentRow.find('.jxPayrollEdit').data('value', CurrentRow.find('.jxPayrollEdit input').val());
            }

        },

        CalculateAssessments: function () {
            var PremiumAmount = 0;
            CHSI.Page.BrokerQuoteEditor.Cache.EstimatedPayroll = CHSI.Page.BrokerQuoteEditor.Cache.TotalPayroll;
            CHSI.Page.BrokerQuoteEditor.Cache.PremiumAmount = CHSI.Page.BrokerQuoteEditor.Cache.TotalGrossPrem;
            var PreviousAmount = CHSI.Page.BrokerQuoteEditor.Cache.PremiumAmount*1;
            for (var i = 0; i < CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods.length;i++)
            {                
                CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods[i].Amount = (CHSI.Page.BrokerQuoteEditor.Events.CalculateAssessment(CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods[i], PreviousAmount)).toFixed(0);
                CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods[i].Difference = (CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods[i].Amount - PreviousAmount).toFixed(0);
                PreviousAmount = CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods[i].Amount;
            }

            CHSI.Page.BrokerQuoteEditor.Cache.PolicyTotal = PreviousAmount;

        },

        UpdateAssessmet: function (ModID, ModValue) {
           
            for (var i = 0; i < CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods.length; i++) {
                if (CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods[i].ModID == ModID) {
                    CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods[i].DefaultValue = ModValue;
                }
                
            }

        },
        CalculateAssessment: function (AssessmentMod, PreviousAmount) {
            var CalcAmount = PreviousAmount;

            switch (AssessmentMod.SelectionType) {
                case 'lookup':
                    var CalcedDiscount = 0;
                    var TotalContributionConsidered = 0;
                    var RAFModReduction = 0;
                    var FinalDiscount = 0;
                    for (j = 0; j < AssessmentMod.DefaultCollectionValues.length; j++) {
                        if (CHSI.Page.BrokerQuoteEditor.Cache.PremiumAmount > AssessmentMod.DefaultCollectionValues[j].Key) {
                            CalcedDiscount = (AssessmentMod.DefaultCollectionValues[j].Key - TotalContributionConsidered) * AssessmentMod.DefaultCollectionValues[j].Value;
                            RAFModReduction -= CalcedDiscount;
                            TotalContributionConsidered = AssessmentMod.DefaultCollectionValues[j].Key;
                        } else {
                            CalcedDiscount = (AssessmentMod.DefaultCollectionValues[j].Key - TotalContributionConsidered) * AssessmentMod.DefaultCollectionValues[j].Value;
                            RAFModReduction -= CalcedDiscount;
                            break;
                        }
                    }

                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.AddContribution(AssessmentMod, PreviousAmount, RAFModReduction);                  
                 
                    break;
                case 'formamount':
                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.AddContribution(AssessmentMod, PreviousAmount, AssessmentMod.DefaultValue);
                    break;             
                case 'amount':
                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.AddContribution(AssessmentMod, PreviousAmount, AssessmentMod.DefaultValue);
                    break;
                case 'minprem':
                    var Minimum = 0;
                    if (AssessmentMod.DefaultValue > PreviousAmount) {
                        Minimum = AssessmentMod.DefaultValue - PreviousAmount;
                    }
                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.AddContribution(AssessmentMod, PreviousAmount, Minimum);
                    break;
                case 'ruleflat':
                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.AddContribution(AssessmentMod, PreviousAmount, AssessmentMod.DefaultValue);
                    break;
                case 'premflat':
                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.AddContribution(AssessmentMod, PreviousAmount, AssessmentMod.DefaultValue);
                    break;
                case 'text':
                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.MultiplyContribution(AssessmentMod, PreviousAmount, AssessmentMod.DefaultValue);
                    break;
                case 'xmod':
                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.MultiplyContribution(AssessmentMod, PreviousAmount, AssessmentMod.DefaultValue);
                    break;
                case 'formrate':
                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.MultiplyContribution(AssessmentMod, PreviousAmount, AssessmentMod.DefaultValue);
                    break;
                case 'list':
                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.MultiplyContribution(AssessmentMod, PreviousAmount, AssessmentMod.DefaultValue);
                    break;
                case 'premsimple':
                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.MultiplyContribution(AssessmentMod, PreviousAmount, AssessmentMod.DefaultValue);
                    break;                    
                case 'presimedit':
                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.MultiplyContribution(AssessmentMod, PreviousAmount, AssessmentMod.DefaultValue);
                    break;
                case 'ruleperc':
                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.MultiplyContribution(AssessmentMod, PreviousAmount, AssessmentMod.DefaultValue);
                    break;
                case 'ddsimple':
                    var aryRule = AssessmentMod.DefaultValue.split(",");
                    var idValue = aryRule[0];
                    var PercValue = aryRule[1];
                    var FlatValue = aryRule[2];
                 
                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.MultiplyContribution(AssessmentMod, PreviousAmount, PercValue);

                    if (FlatValue != 0 && FlatValue > (CalcAmount - PreviousAmount)) {
                        CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.AddContribution(AssessmentMod, PreviousAmount, FlatValue);
                    }
                    break;
                case 'ruleopt':
                    var aryRule = AssessmentMod.DefaultValue.split(",");
                    var PercValue = aryRule[0];
                    var FlatValue = aryRule[1];
                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.MultiplyContribution(AssessmentMod, PreviousAmount, PercValue);

                    if (FlatValue != 0 && FlatValue > (CalcAmount - PreviousAmount)) {
                        CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.AddContribution(AssessmentMod, PreviousAmount, FlatValue);
                    }
                    break;
                case 'ruleopttax':
                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.MultiplyContribution(AssessmentMod, PreviousAmount, AssessmentMod.DefaultValue);
                    break;
                case 'premflatmd':
                    var ModID = AssessmentMod.SelectionValues;
                    var premID = 0;
                    var ModAmount = 0;
                    var CalcedDiscount = 0;
                    for (f = 0; f < CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods.length; f++) {
                        if (CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods[f].ModID == ModID) {
                            premID = CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods[f].DefaultValue.split(",")[0];
                            ModAmount= CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods[f].Amount;
                            break;
                        }
                    }
                    for (j = 0; j < AssessmentMod.DefaultCollectionValues.length; j++) {
                        if (premID == AssessmentMod.DefaultCollectionValues[j].Key) {

                            var Discount= AssessmentMod.DefaultCollectionValues[j].Value;
                            if (ModAmount > Discount) {
                                CalcedDiscount = 0;
                            } else {
                                CalcedDiscount=Discount - ModAmount;
                            }                              
                            break;
                        }
                    }                   

                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.AddContribution(AssessmentMod, PreviousAmount, CalcedDiscount);
                    
                    break;
                case 'rangedisc':
                    var ModID = AssessmentMod.SelectionValues;
                    var premID = 0;
                    var ModAmount = 0;
                    var CalcedDiscount = 0;
                    for (f = 0; f < CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods.length; f++) {
                        if (CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods[f].ModID == ModID) {                            
                            ModAmount = CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods[f].Amount*1;
                            break;
                        }
                    }

                    for (j = 0; j < AssessmentMod.DefaultCollectionValues.length; j++) {
                        var MinMax=AssessmentMod.DefaultCollectionValues[j].Key.split(",");
                        var MinValue=MinMax[0]*1;
                        var MaxValue=MinMax[1]*1;
                        if (ModAmount >=MinValue && ModAmount<=MaxValue) {
                            CalcedDiscount = AssessmentMod.DefaultCollectionValues[j].Value;
                            CHSI.Page.BrokerQuoteEditor.Events.UpdateAssessmet(AssessmentMod.ModID, CalcedDiscount);
                            break;
                        }
                    }

                    

                    CalcAmount = CHSI.Page.BrokerQuoteEditor.Events.MultiplyContribution(AssessmentMod, PreviousAmount, CalcedDiscount);

                    break;
                    
                default:
                    CalcAmount = PreviousAmount;
                    break;

            }

            return CalcAmount;

        },

        MultiplyContribution: function (AssessmentMod, PreviousAmount, Value) {
            var Product = 0;
            switch (AssessmentMod.ModType) {
                case 'N':
                    Product = (1 * CHSI.Page.BrokerQuoteEditor.Cache.PremiumAmount) * (Value * 1);
                    break;
                default:
                    Product = (1*PreviousAmount) * (Value*1);
                    break;
            }
            return Product;
        },

        AddContribution: function (AssessmentMod, PreviousAmount, Value) {
            var Sum = 0;
            switch (AssessmentMod.ModType) {
                case 'n':
                    Sum = (1 * CHSI.Page.BrokerQuoteEditor.Cache.PremiumAmount) + (Value * 1);
                    break;
                default:
                    Sum = (1*PreviousAmount) + (Value*1);
                    break;
            }
            return Sum;
        }
    },
    Calls: {
        GetStates: function (successFunction) {
            var Options = {};
            Options.URL = '/api/UW/ClassCode/GetClassCodeStates?GroupName=' + CHSI.Page.BrokerQuoteEditor.Cache.GroupID;
            Options.Success = function (data) {
                if (successFunction) {
                    CHSI.Page.BrokerQuoteEditor.Cache.States = data;
                    CHSI.Page.BrokerQuoteEditor.Cache.State = CHSI.Page.BrokerQuoteEditor.Cache.States[0];
                    successFunction(data);
                } else {
                    CHSI.Page.BrokerQuoteEditor.Cache.States = data;
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        ValidateClassCode: function (ClassCodeFilters, successFunction) {
            var Options = {};
            Options.Async = false;
            Options.URL = '/api/UW/ClassCode/AdvancedGetDescriptionAndRateByClassCode?ClassCode=' + ClassCodeFilters.ClassCode + '&GroupID=' + ClassCodeFilters.GroupID + '&EffectiveDate=' + ClassCodeFilters.EffectiveDate + '&State=' + ClassCodeFilters.State + '&DescriptionIndex=' + ClassCodeFilters.DescriptionIndex + '&isPortal=' + true;
            Options.Success = function (data) {
                if (successFunction) {
                    CHSI.Page.BrokerQuoteEditor.Cache.ValidationObject = data;
                    successFunction(data);
                } else {
                    CHSI.Page.BrokerQuoteEditor.Cache.ValidationObject = data;
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },

        GetPolicyYears: function (GroupID, successFunction) {
            CHSI.CRM.GetAvailablePolicyYearByGroupID(GroupID, successFunction);
        },

        GetAssessmentMods: function (successFunction) {
            var Options = {};
            Options.URL = '/api/UW/Quote/GetAssessmentCalculationData?GroupID=' + CHSI.Page.BrokerQuoteEditor.Cache.GroupID + '&PolicyPeriod=' + CHSI.Page.BrokerQuoteEditor.Cache.PolicyPeriod + '&QTName=' + CHSI.Page.BrokerQuoteEditor.Cache.QTName + '&State=' + CHSI.Page.BrokerQuoteEditor.Cache.State;
            Options.Success = function (data) {
                if (successFunction) {
                    CHSI.Page.BrokerQuoteEditor.Cache.AssessmentModData = data;
                    CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods = data.AssessmentMods;
                    CHSI.Page.BrokerQuoteEditor.Events.CalculateAssessments();
                    successFunction(data);
                } else {
                    CHSI.Page.BrokerQuoteEditor.Cache.AssessmentMods = data;
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
    }
}

Handlebars.registerHelper('RenderStatesList', function () {
    return CHSI.Page.BrokerQuoteEditor.Cache.ConstructedStateList;
});

Handlebars.registerHelper('GetAssessmentModControl', function (AssessmentMod) {
    return new Handlebars.SafeString(CHSI.Page.BrokerQuoteEditor.Events.GetAssessmentModControl(AssessmentMod));
});