CHSI.Page.SItemFormFields = {
    Init: function (scheduleItemID, scheduleTypeID, isPortal) {
        var Template_ScheduleItemFormFields = $('#Template_ScheduleItemFormFields').html();
        this.Templates.ScheduleItemFormFields = Handlebars.compile(Template_ScheduleItemFormFields);
        var Template_ScheduleItemFieldHistory = $('#Template_ScheduleItemFieldHistory').html();
        this.Templates.ScheduleItemFieldHistory = Handlebars.compile(Template_ScheduleItemFieldHistory);
        this.Cache.ScheduleItemID = scheduleItemID;
        this.Cache.ScheduleTypeID = scheduleTypeID;
        this.Cache.isPortal = isPortal;
        var controller = this;
        var result = this.Calls.GetFields();
        result.then(function () {
            CHSI.Page.SItemFormFields.BindPresentationLayer();
            CHSI.Page.SItemFormFields.SetLabel();
            controller.Events.Global();
        })
        return result;
 
    },
    SaveAll: function (Status) {
        this.PersistFieldsFromHTML();
        this.Events.SaveFields();
    },
    SaveAllFields: function (Status) { 
        this.PersistFieldsFromHTML();
        this.Calls.SaveFields(Status);       
    }
    , PersistFieldsFromHTML: function () {       
        for(var index=0;index< CHSI.Page.SItemFormFields.Cache.FieldsObject.length;index++){
            var Element = $('#jxField_' + index);
            var DataType = Element.data('datatype');
            var ElementVal = Element.val();            
            ElementVal = CHSI.Page.SItemFormFields.GetValue(ElementVal, DataType);
            if (DataType == 3 || DataType==5) {
                ElementVal = isNaN(ElementVal) ? '' : ElementVal;
            }
            CHSI.Page.SItemFormFields.Cache.FieldsObject[index].ColumnValue = ElementVal;
            CHSI.Page.SItemFormFields.Cache.FieldsObject[index].Status = $('#jxField_' + index).data('newstatus');
        }
    }
    , Templates: {
    },
    Cache: {
        Fields: null
       , SHistoryObject: null
        , FieldsObject: null
        , HistoryName: null
        , Active: false
        , validator: null
        , isPortal: false
        , NewItem: false
        , PendingChanges: false
    },
    BindPresentationLayer: function () {
        $('#jxScheduleItemFormFieldsContainer').html(this.Templates.ScheduleItemFormFields({ Fields: this.Cache.Fields }));

        restart = true;
        //sorttable.init();
        var isReadonly = $('input.Date').attr('readonly');
        if (!isReadonly) {
            $('.Date').datepicker({
                showOn: "both",
                buttonImage: "/images/icons/cal.gif",
                buttonImageOnly: true
                , /* fix buggy IE focus functionality */
                fixFocusIE: false,

                /* blur needed to correctly handle placeholder text */
                onSelect: function (dateText, inst) {
                    this.fixFocusIE = true;
                    $(this).blur().change().focus();
                },
                onClose: function (dateText, inst) {
                    this.fixFocusIE = true;
                    this.focus();
                },
                beforeShow: function (input, inst) {
                    var result = true;
                    var ua = window.navigator.userAgent;
                    if (!!navigator.userAgent.match(/Trident\/7\./)) {
                        result = !this.fixFocusIE;
                    }
                    this.fixFocusIE = false;
                    return result;
                }

            }).click(function () { $(this).focus() });
        }

        $('#jxScheduleItemFormFieldsContainer').on('blur', 'input,select,textarea', function () {

            var ChangedField = $(this);
            if (ChangedField.data('originalvalue') != ChangedField.val()) {

                ChangedField.data('newstatus', 'changed');

                var index = ChangedField.data('index'),
                    dataSize = ChangedField.data('datasize');

                $('#jxUndoField_' + index).show()

                $('#jxScheduleItemFormFieldsContainer').on('click', '#jxUndoField_' + index, function () {
                    ChangedField.val(ChangedField.data('originalvalue'));
                    ChangedField.data('newstatus', ChangedField.data('status'));
                    $('#jxUndoField_' + index).hide();
                });
            }

        });

    },
    BindHistory: function () {
        var HTML = '';
        HTML = CHSI.Page.SItemFormFields.Templates.ScheduleItemFieldHistory(CHSI.Page.SItemFormFields.Cache.SHistoryObject);
        ShowInlinePopup('FieldHistoryModal', HTML, 'History:' + CHSI.Page.SItemFormFields.Cache.HistoryName, 400, 400, null, true);
        restart = true;
        //sorttable.init();
    },
    Events: {
        ClearStatusChangedFields:function(){
            $('.jxUndo').hide();
            CHSI.Page.SItemFormFields.Cache.Fields = CHSI.Page.SItemFormFields.Events.ProcessFields(CHSI.Page.SItemFormFields.Cache.FieldsObject);
            CHSI.Page.SItemFormFields.BindPresentationLayer();
        },

        ProcessFields: function (e) {
            var obj = {};
            if (e) {                
                for (i = 0; i < e.length; i++) {
                    if (e[i]) {
                        if (!e[i].CategoryName) {
                            e[i].CategoryName = 'Other';
                        }
                        e[i].index = i;
                        if (typeof obj[e[i].CategoryName] == 'undefined') {
                            obj[e[i].CategoryName] = [];
                        }
                        e[i].ColumnValue = CHSI.Page.SItemFormFields.FormatString(e[i].ColumnValue, e[i].DisplayFormat, e[i].DataType);
                        if (e[i].Status) {
                            if (e[i].Status.substring(0, 7).toLowerCase() == 'pending') {
                                CHSI.Page.SItemFormFields.Cache.PendingChanges = true;
                            }
                        }
                        if (CHSI.Page.SItemFormFields.Cache.ScheduleItemID == undefined && CHSI.Page.SItemFormFields.Cache.isPortal) {
                            CHSI.Page.SItemFormFields.Cache.NewItem = true;
                        }

                        if (e[i].NumOfUpdates < 1 && CHSI.Page.SItemFormFields.Cache.ScheduleItemID && CHSI.Page.SItemFormItem.Cache.Status == 'Pending' && CHSI.Page.SItemFormItem.Cache.AddNew == false) {
                            CHSI.Page.SItemFormFields.Cache.NewItem = true;
                        }

                        obj[e[i].CategoryName].push(e[i]);
                    }
                }
            }

            return obj;
        },
        Global: function () {


            $('#jxScheduleItemFormFieldsContainer').on('keyup', 'input', function () {

                var ChangedField = $(this),
                    index = ChangedField.data('index'),
                    dataSize = ChangedField.data('datasize'),
                     datatype = ChangedField.data('datatype'),
                    value = ChangedField.val();

                if (datatype == 3 || datatype == 5) {
                    if (datatype == 5) {
                        datasize = 0;
                    }
                    ChangedField.val(CHSI.Page.SItemFormFields.Events.DataSizeValidator(value, dataSize));
                }
            });

            $('#jxScheduleItemFormFieldsContainer').on('blur', '.Regular', function () {
                var Element = $(this);
                var ElementVal = Element.val();
                var Format = Element.data('displayformat');
                var DataType = Element.data('datatype');
                Element.val(CHSI.Page.SItemFormFields.FormatString(ElementVal, Format, DataType));

                var validator = jQuery('form').validate({ debug: true, onkeyup: false, onfocusout: false });
                validator.element(this);

                if (!validator.errorList.length) {
                    Element.qtip('destroy');
                }
            });

            $('#jxScheduleItemFormFieldsContainer').on('blur', '.Calculated', function () {
            });

            $('#jxScheduleItemFormFieldsContainer').on('blur', '.Date', function () {
                var Element = $(this);
                var FormatedDate = CHSI.Dates.GetShortDate(Element.val());
                Element.val(FormatedDate);
                var validator = jQuery('form').validate({ debug: true, onkeyup: false, onfocusout: false });
                validator.element(this);
                if (!validator.errorList.length) {
                    Element.qtip('destroy');
                }

            });

            $('#jxScheduleItemFormFieldsContainer').on('blur', '.DropDown', function () {
                var validator = jQuery('form').validate({ debug: true, onkeyup: false, onfocusout: false });
                validator.element(this);
                var Element = $(this);
                if (!validator.errorList.length) {
                    Element.qtip('destroy');
                }
            });

            $('#jxScheduleItemFormFieldsContainer').on('blur', '.TextBlock', function () {
                var validator = jQuery('form').validate({ debug: true, onkeyup: false, onfocusout: false });
                validator.element(this);
                var Element = $(this);
                if (!validator.errorList.length) {
                    Element.qtip('destroy');
                }
            });


        },
        SaveFields: function (successFunction) {
            CHSI.Page.SItemFormItem.Cache.SaveButton = true;
            if (CHSI.Page.SItemFormItem.Cache.Deleted && CHSI.Page.SItemFormItem.Cache.SItemObject.Status == 'Pending-Delete') {
                var r = true;
                if (CHSI.Page.SItemFormItem.Cache.AutoApprove || !CHSI.Page.SItemFormFields.Cache.isPortal) {
                    r = confirm("The item status will be updated to Deleted by confirming this message.");
                } else {
                    return false;
                }
                if (r == true) {
                    CHSI.Page.SItemFormItem.Calls.SaveItem(false);
                    
                } else {
                    return false;
                }
            } else {
                if (CHSI.Page.SItemFormFields.Cache.isPortal && CHSI.Page.SItemFormItem.Cache.AutoApprove == false) {
                    CHSI.Page.SItemFormFields.SaveAllFields('Pending');
                } else {
                    var r = true
                    if (CHSI.Page.SItemFormFields.Cache.PendingChanges || CHSI.Page.SItemFormFields.Cache.NewItem) {
                        r = confirm("Pending values and the item status will be updated to active by confirming this message.");
                    }
                    if (r == true) {
                        CHSI.Page.SItemFormFields.SaveAllFields('Active');
                        if (CHSI.Page.SItemFormItem.Cache.SItemObject.Status == 'Pending') {
                            CHSI.Page.SItemFormItem.Cache.SItemObject.Status = 'Active';
                            CHSI.Page.SItemFormItem.Calls.SaveItem(false);
                        }
                    } else {
                        return false;
                    }
                }
                    
                if (successFunction) {
                    successFunction();
                }
            }
            CHSI.Page.SItemFormItem.Cache.SaveButton = false;
        }
        , DataSizeValidator: function (CurrentValue, DataSize) {
            var CharLimit = 0;

            if (CurrentValue.indexOf('.') == -1) {
                CharLimit = CurrentValue;
            } else {
                var DecimalValue = CurrentValue.split('.'),
                    Characteristic = DecimalValue[0],
                    Mantissa = DecimalValue[1] * 1;

                if (Mantissa == 0 || '') {
                    CharLimit = CurrentValue;
                } else {
                    if (Mantissa.toString().length > DataSize) {
                        Mantissa = Mantissa.toString().substring(0, DataSize);
                    }
                    DecimalValue = Characteristic + '.' + Mantissa;
                    CharLimit = DecimalValue;
                }
            }

            return CharLimit;
        }
    },
    ApplyPrevious: function (index, priorvalue, actualvalue) {
        $('#jxField_' + index).val(priorvalue);
        $('#jxRevertField_' + index).hide();
        $('#jxField_' + index).removeClass('HighlightGreen');
    },

    SetLabel: function () {
        if (this.Cache.PendingChanges) {
            if (!CHSI.Page.SItemFormFields.Cache.isPortal) {
                if (this.Cache.NewItem) {
                    $('#jxItemLabel').html("Approval Required:  New Item Added");
                } else {
                    $('#jxFieldLabel').html("Values have been changed and are waiting for administrator action.<br />The scheduled item status will remain pending until changes are saved.");
                }                
            }
            if (CHSI.Page.SItemFormItem.Cache.Deleted == false) {
                if (!CHSI.Page.SItemFormFields.Cache.isPortal) {
                    $('#jxItemLabel').html("Approval Required:  Field Value(s) Changed");
                    if (this.Cache.NewItem) {
                        $('#jxItemLabel').html("Approval Required:  New Item Added");
                    }
                }
            }
        } else {
            if (this.Cache.NewItem) {
                if (!CHSI.Page.SItemFormFields.Cache.isPortal) {
                    $('#jxFieldLabel').html("A new item has been added and waiting for administrator action.<br />The scheduled item status will remain pending until changes are saved.");
                    if (CHSI.Page.SItemFormItem.Cache.Deleted == false) {
                        $('#jxItemLabel').html("Approval Required:  New Item Added");
                    }
                }
            } else {
                $('#jxFieldLabel').html("");
                if (CHSI.Page.SItemFormItem.Cache.Deleted == false) {
                    $('#jxItemLabel').html("");
                }
            }
        }
    },
    FormatString: function (SValue, Format, DataType) {
        var FormatedText = SValue;
        if (SValue && Format && DataType) {
            var ElementVal = CHSI.Page.SItemFormFields.GetValue(SValue, DataType);
            FormatedText = String.__Format(Format, ElementVal);
        }
        return FormatedText;
    },
    GetValue: function (SValue, DataType) {
        var ElementVal = SValue.toString(); // toString incase server doesn't return a string
        switch (DataType) {
            case 3: //Decimal
                ElementVal = parseFloat(ElementVal.replace(/[^0-9\.]/g, ''));
                break;
            case 5: //Integer
                //ElementVal = parseInt(ElementVal.replace(/[^0-9]/g, ''));

                ElementVal = parseInt(ElementVal.replace(/,/g, ''), 10);
                break;
        }

        return ElementVal;
    },
    Calls: {
        GetFields: function () {
            var AjaxURL = '/api/UW/ScheduleField/GetScheduleField?ScheduleTypeID=' + CHSI.Page.SItemFormFields.Cache.ScheduleTypeID;

            if (CHSI.Page.SItemFormFields.Cache.ScheduleItemID) {
                AjaxURL = '/api/UW/ScheduleItemField/Get?ScheduleItemID=' + CHSI.Page.SItemFormFields.Cache.ScheduleItemID;
            } else {
                if (CHSI.Page.SItemFormItem.Cache.SItemObject.ScheduleItemID) {
                    AjaxURL = '/api/UW/ScheduleItemField/Get?ScheduleItemID=' + CHSI.Page.SItemFormItem.Cache.SItemObject.ScheduleItemID;
                }
            }

            CHSI.Page.SItemFormFields.Cache.PendingChanges = false;
            CHSI.Page.SItemFormFields.Cache.NewItem = false;
          return  $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    var obj = CHSI.Page.SItemFormFields.Events.ProcessFields(e);
                    CHSI.Page.SItemFormFields.Cache.FieldsObject = e;
                    CHSI.Page.SItemFormFields.Cache.Fields = obj;
       
                },
                error: function (e) {
                    CHSI.Common.Log('no data to return');
                }
            });
        },
        GetHistory: function (ScheduleItemID, ScheduleFieldID, Name) {
            var AjaxURL = '/api/UW/ScheduleItemFieldValue/GetHistory?ScheduleItemID=' + ScheduleItemID + '&ScheduleFieldID=' + ScheduleFieldID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.SItemFormFields.Cache.HistoryName = Name;
                    CHSI.Page.SItemFormFields.Cache.SHistoryObject = { History: e };
                    CHSI.Page.SItemFormFields.BindHistory();
                },
                error: function (e) {
                    CHSI.Common.Log('no data to return');
                }
            });
        },
        SaveFields: function (Status) {
            var AjaxURL = '/api/UW/ScheduleItemField/SaveScheduleFields';
            var PendingFields = [];
            if (!CHSI.Page.SItemFormFields.Cache.FieldsObject) {
                return false;
            }
            if (CHSI.Page.SItemFormFields.Cache.FieldsObject.length) {
                for (i = 0; i < CHSI.Page.SItemFormFields.Cache.FieldsObject.length; i++) {
                    var FieldStatus = "";
                    if (CHSI.Page.SItemFormFields.Cache.FieldsObject[i].Status) {
                        FieldStatus = CHSI.Page.SItemFormFields.Cache.FieldsObject[i].Status.toLowerCase();
                    }
                    if (FieldStatus == 'pending' || FieldStatus == 'changed') {
                        if (Status != '') {
                            PendingFields.push(CHSI.Page.SItemFormFields.Cache.FieldsObject[i]);
                            CHSI.Page.SItemFormFields.Cache.FieldsObject[i].Status = Status;
                        } else {
                            if (FieldStatus == 'changed') {
                                if (CHSI.Page.SItemFormFields.Cache.isPortal) {
                                    CHSI.Page.SItemFormFields.Cache.FieldsObject[i].Status = 'Pending';
                                } else {
                                    CHSI.Page.SItemFormFields.Cache.FieldsObject[i].Status = 'Active';
                                }
                                PendingFields.push(CHSI.Page.SItemFormFields.Cache.FieldsObject[i]);
                            }
                        }
                    }
                }
            }

            for (var PendingFieldIndex = 0; PendingFieldIndex < PendingFields.length; PendingFieldIndex++) {
                PendingFields[PendingFieldIndex].ScheduleItemID = CHSI.Page.SItemFormFields.Cache.ScheduleItemID;
            }


            var FieldsContainer = {};
            FieldsContainer.AutoApprove = CHSI.Page.SItemFormItem.Cache.AutoApprove;
            FieldsContainer.ItemFields = PendingFields;
            FieldsContainer.Status = Status;
            var ajaxString = JSON.stringify(FieldsContainer);
            $.ajax({
                type: "POST",
                url: AjaxURL,
                data: ajaxString,
                cache: true,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    //CHSI.Page.SItemFormFields.Calls.GetFields();
                    CHSI.Page.SItemFormFields.Events.ClearStatusChangedFields();
                },
                error: function (e) {
                    CHSI.Common.Log(e);
                }
            });
        }
    }
}

Handlebars.registerHelper('eachArray', function (context, options) {
    var ret = "";
    var index = -1;
    var size = 0;
    var isLast = false;
    if (Object.prototype.toString.apply(context) === '[object Array]') {
        size = context.length;
        for (var i = 0; i < context.length; i++) {
            index++;
            isLast = false;
            if (index >= size - 1) {
                isLast = true;
            }
            ret = ret + options.fn({ property: i, value: context[i], index: index, isLast: isLast, size: size });
        }
    } else {
        for (var prop in context) {
            size++;
        }
        for (var prop in context) {
            index++;
            isLast = false;
            if (index >= size - 1) {
                isLast = true;
            }
            ret = ret + options.fn({ property: prop, value: context[prop], index: index, isLast: isLast, size: size });
        }
    }

    return ret;
});

Handlebars.registerHelper('setMaxLength', function (DataSize, DataType) {
    var maxLength = 0;

    if (DataType == 3) { // Decimal
        maxLength = 30;
    } else {
        maxLength = DataSize;
    }
    return maxLength;
});
