CHSI.UW.ClassCode.Controllers.ClassCodeAdminController = {

    TargetElement: null,
    TargetAddElement: null,
    TargetClientCodesElement:null,
    TargetInactiveFilterElement:null,

    Cache: {

        GridObject: null,
        GridTable: null
    },
    Init: function () {

        this.GetAllClassCode();
        this.SetupGlobalEvents();

    },

    GetAllClassCode: function () {
        var includeinactive = this.TargetInactiveFilterElement.is(':checked');
        CHSI.UW.ClassCode.Models.ClassCode.GetAllClassCode(includeinactive,function (result) {
            CHSI.UW.ClassCode.Controllers.ClassCodeAdminController.Cache.GridObject = result;
            CHSI.UW.ClassCode.Controllers.ClassCodeAdminController.BindPresentationLayer();


        })


    },

    BindPresentationLayer: function () {

        this.TargetElement.html('<table width="100%" class="dtable" id="jxClassCodeGrid"><tfoot></tfoot></table>')
        var DataSet = null;
        var Columns;
        if (CHSI.UW.ClassCode.Controllers.ClassCodeAdminController.Cache.GridObject) {
            DataSet = CHSI.UW.ClassCode.Controllers.ClassCodeAdminController.Cache.GridObject.Values;
            Columns = CHSI.UW.ClassCode.Controllers.ClassCodeAdminController.Cache.GridObject.Columns;
        }

        Columns.push({

            title: '',
            orderable: false,
            width: '10%',
            render: function (data, type, row) {
                var html = "<a class='jxViewEdit' data-classcode='" + row[0] + "' href='#'>view/edit</a>";
                
                return html;
            }

        })


        if (DataSet != null) {
            CHSI.UW.ClassCode.Controllers.ClassCodeAdminController.Cache.GridTable = $('#jxClassCodeGrid').DataTable({
                data: DataSet,
                columns: Columns,
                lengthMenu: [20, 50, 100],

                stateSave: true


            })
        } else {
            var HTML = [];
            $('#jxClassCodeGrid').html('<tr><td>No results found.</td></tr>')
        }

        $('#jxLoading').hide();


    },

    SetupGlobalEvents: function () {

        this.TargetElement.on('click', '.jxViewEdit', function () {
            var Button = $(this);
            var ClassCodeID = Button.data('classcode');
            var ShowViewEditClassCodeURL = '/SIG/Underwriting/admin/editclasscode.aspx?ClassCodeID=' + ClassCodeID;
            ShowPopup("ViewEditClassCodeURL", ShowViewEditClassCodeURL, "Edit Class Code", 385, 300, CHSI.UW.ClassCode.Controllers.ClassCodeAdminController.GetAllClassCode, true);
        });

        this.TargetAddElement.on('click', function () {

            var ShowAddClassCodeURL = '/SIG/Underwriting/admin/editclasscode.aspx?ClassCodeID=0';
            ShowPopup("AddClassCodeURL", ShowAddClassCodeURL, "New Class Code", 400, 270, CHSI.UW.ClassCode.Controllers.ClassCodeAdminController.GetAllClassCodes, true);
        });

        this.TargetClientCodesElement.on('click', function () {

            var ShowClientCodeURL = '/SIG/Underwriting/admin/clientclasscodes.aspx';
            ShowPopup("AddClientCodeURL", ShowClientCodeURL, "Client Class Codes", 800, 470, CHSI.UW.ClassCode.Controllers.ClassCodeAdminController.GetAllClassCode, true);
        });

        this.TargetInactiveFilterElement.on('click', function () { CHSI.UW.ClassCode.Controllers.ClassCodeAdminController.GetAllClassCode();});
    }
}