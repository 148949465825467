CHSI.Page.SItemFormImageEdit = {

    Init: function (ScheduleItemID) {
        var Template_ScheduleItemFormImageEdit = $('#Template_ScheduleItemFormImageEdit').html();
        this.Templates.ScheduleItemFormImageEdit = Handlebars.compile(Template_ScheduleItemFormImageEdit);
        this.Cache.ImageObject.ScheduleItemID = ScheduleItemID;
        if (ScheduleItemID) {
            return this.Calls.GetScheduleItemImages(ScheduleItemID);
        }
        else {
            var deferred = $.Deferred();
            deferred.resolve();
            return deferred;
        }

    },
    Templates: {

    },
    Cache: {
        ImageObject: {
            ScheduleItemID: null
        }
    },
    BindPresentationLayer: function () {
        $('#jxScheduleItemFormImageEditContainer').html(this.Templates.ScheduleItemFormImageEdit(this.Cache.ImageObject));
    },
    Events: {
        Global: function () {

        }
    },
    Calls: {
        GetScheduleItemImages: function (ScheduleItemID) {
            var AjaxURL = '/api/UW/ScheduleItemImage/GetScheduleItemImages?ScheduleItemID=' + ScheduleItemID;
            return $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.SItemFormImageEdit.Cache.ImageObject = { ImageObject: e };
                    CHSI.Page.SItemFormImageEdit.BindPresentationLayer();
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },
        DeleteScheduleItemImage: function (ScheduleImageID, ScheduleItemID, successFunction) {

            var AjaxURL = '/api/UW/ScheduleItemImage/DeleteScheduleItemImage?ScheduleImageID=' + ScheduleImageID;
            $.ajax({
                type: "Delete",
                url: AjaxURL,
                cache: true,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.SItemFormImageEdit.Calls.GetScheduleItemImages(ScheduleItemID);
                    if (successFunction) {
                        successFunction();
                    }
                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        }
    }
}