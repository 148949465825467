CHSI.Common.Page = {
    ReadOnly: function (Input, Select, Button) {

        if (Input == null) {
            Input = false;
        }

        if (Select == null) {
            Select = false;
        }

        if (Button == null) {
            Button = false;
        }

        if (Input) {
            $('input[type="text"]').attr('readonly', true);
            $('input[type="text"]').attr('disabled', true);
            $('input:not([type="text"],[type="submit"], [type="button"])').attr('disabled', true);
            $('.DatePicker').datepicker('disable');
        } else {
            $('input[type="text"]').attr('readonly', false);
            $('input[type="text"]').attr('disabled', false);
            $('input:not([type="text"],[type="submit"], [type="button"])').attr('disabled', false);            
        }

        if (Select) {
            $('select').attr('disabled', true);
        } else {
            $('select').attr('disabled', false);
        }

        if (Button) {
            $('input[type="button"]').attr('disabled', true);
            $('input[type="submit"]').attr('disabled', true);
            $('input[type="file"]').attr('disabled', true);
        } else {
            $('input[type="button"]').attr('disabled', false);
            $('input[type="submit"]').attr('disabled', false);
            $('input[type="file"]').attr('disabled', false);
        }
    }
}
