module PL.ClaimSystem.ClaimManagement {
    export class TreatmentModel {
        constructor() {
            this.Provider = new ProviderModel();
            // temp until we get a binder
            this.TreatmentID = 0;
            this.ProviderID = 0;
            this.IncidentID = 0;
            this.TreatmentType = "";
            this.TreatmentStatus = "";
            this.Restrictions = "";
            this.Comments = "";
        }

        public Provider: ProviderModel
        public TreatmentID: number;
        public ProviderID: number;
        public IncidentID: number;
        public TreatmentType: string;
        public TreatmentStatus: string;
        public Restrictions: string;
        public Comments: string;
        public CreateUser: string;
        public CreateTmsp: string;
        public UpdateUser: string;
        public UpdateTmsp: string;
    }
}