module PL {

    export class ClientSearch {

        constructor(gridID: string) {
            this.GridID = gridID;
        }

        public GridID: string;
        public Grid: DataTables.DataTable;
        public DefaultSearch: string;
        public DefaultSearchType: number;
        public Preferences: any;

        public Init(searchVal: string) {
            this.DefaultSearch = searchVal;

            // need to add one default dynamic search filter here cause flaw in api design
            var filters = [{ 
                FilterCategory: 1,
                FilterName: 0,
                FilterNameText: 'Name',
                FilterType: 0,
                FilterValue: '',
                FilterAdd: 0
            }]; // type this l8r

            // build filters from user preferences
            $.each(this.Preferences, (key, val) => {
                switch (key) {
                    case 'Default Group':
                        $('#customFilter_0 option').filter(function (i, e) {
                            return $(e).val() == val
                        }).prop('selected', 'selected');

                        if (/^-.*-$/.test(val) == false && val != "") {
                            filters.push({
                                FilterCategory: 0,
                                FilterName: 0,
                                FilterNameText: '',
                                FilterType: -1,
                                FilterValue: val,
                                FilterAdd: -1
                            });
                        }
                        break;
                    case 'Default Company Type':
                        $('#customFilter_1 option').filter(function (i, e) {
                            return $(e).val() == val
                        }).prop('selected', 'selected');

                        if (/^-.*-$/.test(val) == false && val != "") {
                            filters.push({
                                FilterCategory: 0,
                                FilterName: 1,
                                FilterNameText: '',
                                FilterType: -1,
                                FilterValue: val,
                                FilterAdd: -1
                            });
                        }
                        break;
                    case 'Default Record Type':
                        $('#customFilter_3 option').filter(function (i, e) {
                            return $(e).val() == val
                        }).prop('selected', 'selected');

                        if (/^-.*-$/.test(val) == false && val != "") {
                            filters.push({
                                FilterCategory: 0,
                                FilterName: 3,
                                FilterNameText: '',
                                FilterType: -1,
                                FilterValue: val,
                                FilterAdd: -1
                            });
                        }
                        break;
                    case 'Default Entity Relationship':
                        if (val == "Parents") {
                            $('#customFilter_2').val('1');
                            filters.push({
                                FilterCategory: 0,
                                FilterName: 2,
                                FilterNameText: '',
                                FilterType: -1,
                                FilterValue: '1',
                                FilterAdd: -1
                            })
                        }
                        else if (val == "Children") {
                            $('#customFilter_2').val('2');
                            filters.push({
                                FilterCategory: 0,
                                FilterName: 2,
                                FilterNameText: '',
                                FilterType: -1,
                                FilterValue: '2',
                                FilterAdd: -1
                            })
                        }
                        break;
                    case 'Default Search Type':
                        this.DefaultSearchType = val;
                        switch (val) {
                            case 'CONTAINS':
                                this.DefaultSearchType = 1;
                                $('.filterType').val('1');
                                break;
                            case 'EQUALS':
                                this.DefaultSearchType = 2;
                                $('.filterType').val('2');
                                break;
                            case 'NOT':
                                this.DefaultSearchType = 3;
                                $('.filterType').val('3');
                                break;
                            case 'BEGINS WITH':
                                this.DefaultSearchType = 4;
                                $('.filterType').val('4');
                                break;
                            case 'ENDS WITH':
                                this.DefaultSearchType = 5;
                                $('.filterType').val('5');
                                break;
                            case 'GREATHER THAN':
                                this.DefaultSearchType = 6;
                                $('.filterType').val('6');
                                break;
                            case 'LESS THAN':
                                this.DefaultSearchType = 7;
                                $('.filterType').val('7');
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
            });

            // get default results using preferences and search string
            this._getFilteredClients(filters).done((data: any) => {
                this.DefaultSearch ? this._andBuildTableWithSearch(data) : this._andBuildTable(data);
            });
        }

        public GetFilteredClients = function (filters) {
            this._getFilteredClients(filters).done(this._andBuildTable);
        };

        private _getFilteredClients = (filters): JQueryDeferred<any> => {
            var df = $.Deferred();
            $.ajax({
                type: "POST",
                url: "/api/CRM/Search/GetFilteredClients",
                data: JSON.stringify(filters),
                contentType: "application/json",
                beforeSend: function () {
                    $('#searchLoading').show();
                    $('#jxClientGrid').css('display', 'none'); // faster than .hide
                },
                success: function (smartgrid) {
                    $('#searchLoading').hide();
                    $('#jxClientGrid').css('display', 'block'); // faster than .show
                    df.resolve(smartgrid);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $('#searchLoading').hide();
                    if (jqXHR.readyState == 4) // http error
                        alert("(╯°□°）╯︵ ┻━┻  Error: " + errorThrown + "\r ...  looks like our servers didn't like that query! Try being more specific with your search values and try again.\r\r If this problem persists, please contact our support team at: support@chsitech.com");
                    else if (jqXHR.readyState == 0) // network error
                        alert("(っ˘̩╭╮˘̩)っ  Error: Network error\rCheck your internet connection!");
                    else // other error
                        alert("(╯°□°）╯︵ ┻━┻  Error: Application error\r ...  looks like our servers didn't like that query! Try being more specific with your search values and try again.\r\r If this problem persists, please contact our support team at: support@chsitech.com");
                    df.reject();
                }
            });
            return df;
        };
        
        private _andBuildTable = (data: any) => {
            this.Grid = mW.DataTableHelpers.BuildDataTableWithHeaders(this.GridID, data);
        };

        private _andBuildTableWithSearch = (data: any) => {
            this.Grid = mW.DataTableHelpers.BuildDataTableWithHeaders(this.GridID, data, this.DefaultSearch);
        };
    }
}