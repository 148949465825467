CHSI.Claims.FormTypes.Fields = {
    Init: function (successFunction) {

        var Template_Fields = $('#Template_Fields').html();
        this.Templates.Fields = Handlebars.compile(Template_Fields);        

        CHSI.Claims.FormTypes.Fields.Calls.GetValidatorTypes(function () {
            if (CHSI.Claims.FormTypes.Information.Cache.FormTypeID) {
                CHSI.Claims.FormTypes.Fields.Calls.GetFields(function () {
                    CHSI.Claims.FormTypes.Fields.Calls.GetCategories(function () {
                        //CHSI.Claims.FormTypes.Fields.Binds.Fields();                        
                        CHSI.Claims.FormTypes.Fields.Events.ProcessFieldCall();
                    });                    
                });
            }
            else {
                CHSI.Claims.FormTypes.Fields.Events.ProcessFieldCall([]);
            }
        });

        this.Events.Global();
    },
    Templates: {

    },
    Cache: {
        ValidatorTypes: [],
        FieldsObject: {
            Fields:[],
            Categories: [],
            SelectedCategoryIndex: null
        },        
        SavingFlag: false,
        FieldsArray: [], // new as of 6/23/2015
        SortType: 'form',
        currentMasterDetail: null,
        isNewFieldActive: false,
        SelectedCategoryID: null
    },

    Binds: {
        Fields: function () {            

            if (CHSI.Claims.FormTypes.FormEditor.Cache.FieldObject) {
                CHSI.Claims.FormTypes.Fields.Events.GetFieldsFromPresentationLayer();
                CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Fields = CHSI.Claims.FormTypes.FormEditor.Cache.FieldObject;
            }

            if ($('#jxFieldCategories').length > 0)
            {
                CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.SelectedCategoryIndex = $('#jxFieldCategories')[0].selectedIndex - 1;
            }
            
            if (CHSI.Claims.FormTypes.Categories.Cache.CategoriesList.length <= 0) {                
                
                CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Categories = CHSI.Claims.FormTypes.Categories.Cache.CategoriesList;
                $('#FieldsContainer').html(CHSI.Claims.FormTypes.Fields.Templates.Fields(CHSI.Claims.FormTypes.Fields.Cache.FieldsObject));
            } else {
                CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Categories = CHSI.Claims.FormTypes.Categories.Cache.CategoriesList;
                $('#FieldsContainer').html(CHSI.Claims.FormTypes.Fields.Templates.Fields({ Fields: CHSI.Claims.FormTypes.Fields.Cache.FieldsObject }));
            }

            if (CHSI.Claims.FormTypes.Fields.Cache.SelectedCategoryName == null) {
                CHSI.Claims.FormTypes.Fields.Cache.SelectedCategoryName = $('#jxFieldCategories :selected').val();
                CHSI.Claims.FormTypes.Fields.Cache.SelectedCategoryID = $('#jxFieldCategories :selected').data('formcategoryid');
            }


            $('#jxFieldCategories').val(CHSI.Claims.FormTypes.Fields.Cache.SelectedCategoryName);            

            $('#fieldContainer > ul').sortable({
                connectWith: "#FieldsContainer"
            }).disableSelection();

            $('.jxDatePicker').datepicker({
                showOn: "both",
                buttonImage: "/images/icons/cal.gif",
                buttonImageOnly: true
            });

            if (CHSI.Claims.FormTypes.Fields.Cache.isNewFieldActive) {
                $('#jxFieldAddField').prop('disabled', true);
            } else {
                $('#jxFieldAddField').prop('disabled', false);
            }

            $("#jxFormRadio").prop("checked", true);

            $('.formSortOrderContainer').show();
            $('.gridSortOrderContainer').hide();
        }        
    },
    GetDefaultField: function () {
        return {
            FormTypeID: CHSI.Claims.FormTypes.Information.Cache.FormTypeID * 1,
            FormFieldID: -1,
            CategoryName: $('#jxFieldCategories :selected').val(),
            FormEditable: true,
            Required: false,
            Status: 'Active',
            FieldType: '1',
            Summarize: false,
            GridShow: false,
            FormShow: false,
            PortalShow: false,
            FieldLogic: '',
            FormSortOrder: CHSI.Claims.FormTypes.Fields.Events.getLastSortOrderIndex(),
            ShowForm: false,
            GridSortOrder: CHSI.Claims.FormTypes.Fields.Events.getLastSortOrderIndex(),
            GridShow: false
        }
    },
    BindFieldtoPresentation: function (index, Field) {
        $('#jxFieldName_' + index).val(Field.FieldName);
        $('#jxFieldShowInGrid_' + index).prop('checked', Field.GridShow);
        if (Field.GridShow) {
            $('#jxFieldSortOrderDisplay_' + index).show();
        } else {
            $('#jxFieldSortOrderDisplay_' + index).hide();
        }
        //$('#jxFieldSortOrderGrid_' + index).val(Field.GridSortOrder);
        $('#jxFieldShowInForm_' + index).prop('checked', Field.FormShow);
        if (Field.FormShow) {
            $('#jxFieldShowFormDisplay_' + index).show();
        } else {
            $('#jxFieldShowFormDisplay_' + index).hide();
        }
        //$('#jxFieldSortOrderForm_' + index).val(Field.FormSortOrder);
        $('#jxFieldVisibleOnPortal_' + index).prop('checked', Field.PortalShow);
        if (Field.PortalShow) {
            $('#jxFieldReadonlyDisplay_' + index).show();
        } else {
            $('#jxFieldReadonlyDisplay_' + index).hide();
        }
        $('#jxFieldReadOnly_' + index).prop('checked', Field.PortalReadOnly);
        $('#jxFieldStatus_' + index).val(Field.Status);
        $('#jxFieldCategory_' + index).val(Field.CategoryName);
        $('#jxFieldLongName_' + index).val(Field.LongName);
        $('#jxFieldRequired_' + index).val(Field.Required.toString());

        $('#jxFieldToolTip_' + index).val(Field.ToolTipText);
        $('#jxFieldSummarized_' + index).val(Field.Summarize.toString());
        $('#jxFieldFieldType_' + index).val(Field.FieldType);
        $('#jxFieldDataType_' + index).val(Field.DataType.toString());
        $('#jxFieldSize_' + index).val(Field.DataSize);
        switch (Field.FieldType * 1) {
            case 1/*regular*/:
                $('#jxFieldValidator_' + index).val(Field.FieldLogic);
                break;
            case 2/*calculated*/:
            case 4/*dropdown*/:
                $('#jxFieldFormula_' + index).val(Field.FieldLogic);
                break;
            case 3/*date*/:
                break;
            case 5/*textblock*/:
                break;
            default:
        }
        CHSI.Claims.FormTypes.Fields.ProcessFieldTypeChange(index, Field.FieldType * 1);

        $('#jxSFieldDisplayFormat_' + index).val(Field.DisplayFormat);
    },
    ProcessFieldTypeChange: function (index, newFieldType) {
        var jxFieldLogicLabel = $('#jxFieldLogicLabel_' + index);

        var jxFieldValidator = $('#jxFieldValidator_' + index);
        var jxFieldFormula = $('#jxFieldFormula_' + index);
        var jxFieldDataType = $('#jxFieldDataType_' + index);
        jxFieldValidator.hide();
        jxFieldFormula.hide();
        jxFieldLogicLabel.html('');
        switch (newFieldType) {
            case 1/*regular*/:
                jxFieldValidator.show();
                jxFieldLogicLabel.html('Validator');
                jxFieldDataType.val(1);
                break;
            case 2/*calculated*/:
                jxFieldFormula.show();
                jxFieldLogicLabel.html('Calculation Formula');
                jxFieldDataType.val(3);
                break;
            case 4/*dropdown*/:
                jxFieldFormula.show();
                jxFieldLogicLabel.html('Dropdown source');
                jxFieldDataType.val(1);
                break;
            case 3/*date*/:
                jxFieldDataType.val(2);
                break;
            case 5/*textblock*/:
                jxFieldDataType.val(1);
                break;
            case 6/*HTML Read-Only*/:
                jxFieldDataType.val(1);
                break;
            default:
        }

    },
    ShowHideDetailRow: function (index) {
        var FieldEditButton = $('#jxFieldEdit_' + index);
        var DetailRow = $('#ItemDetailRow_' + index);

        if (DetailRow.is(':visible')) {
            DetailRow.slideUp('fast');
            FieldEditButton.html('Edit<span class="ui-icon ui-icon-triangle-1-s editIcon"></span><span></span>');
        }
        else {
            DetailRow.slideDown('fast');
            FieldEditButton.html('Edit<span class="ui-icon ui-icon-triangle-1-n editIcon"></span><span></span>');
        }
        DetailRow.parent().find('.headerFieldName').focus();
    },
    Events: {
        Global: function () {
            $('#FieldsContainer').on('sortstart', '#fieldContainer > ul', function (event, ui) {
                ui.item.css('box-shadow', '0 0 10px #000000');
                ui.item.css('background-color', 'white');
            });

            $('#FieldsContainer').on('sortstop', '#fieldContainer > ul', function (event, ui) {
                ui.item.css('box-shadow', '');                
                CHSI.Claims.FormTypes.Fields.Events.updateFieldSortOrder();
                CHSI.Claims.FormTypes.Fields.Events.updateRowColor();
            });

            $('#jxFieldAddField').on('click', function () {                
                if (CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories == undefined) {
                    CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories = [];
                }
                if (CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories.length == 0) {
                    alert('Please add a category if you would like to create a new field.');
                } else {
                    CHSI.Claims.FormTypes.Fields.Events.addField();
                }                
            });

            $('#TabFields').on('change', '.FieldCategorySelect', function () {
                var CategoryList = CHSI.Claims.FormTypes.Categories.Cache.CategoriesList,
                    CategoryName = $(this).val();   

                for (Index = 0; Index < CategoryList.length; Index++) {
                    if (CategoryName == CategoryList[Index].CategoryName) {
                        $(this).data('index', CategoryList[Index].Index);
                    }
                }
            });

            $('#FieldsContainer').on('change', '#jxFieldCategories', function () {
                var FormCategoryID = $(this).find(':selected').data('formcategoryid'),
                    CurrentOptionIndex = $(this).find(':selected').data('index'),
                    currentOptionValue = $(this).find(':selected').val();
                
                CHSI.Claims.FormTypes.Fields.Cache.SelectedCategoryName = currentOptionValue;
                CHSI.Claims.FormTypes.Fields.Events.ProcessFieldCall();
            });


            $('#FieldsContainer').on('change', '.ShowGridCheckbox', function () {
                var checkbox = $(this);
                var Index = checkbox.data('fieldindex');
                var jxSFieldSortOrderDisplay = $('#jxFieldSortOrderDisplay_' + Index);
                if (checkbox.is(':checked')) {
                    jxSFieldSortOrderDisplay.show();
                } else {
                    jxSFieldSortOrderDisplay.hide();
                }
            });

            $('#FieldsContainer').on('change', '.ShowFormCheckbox', function () {
                var checkbox = $(this);
                var Index = checkbox.data('fieldindex');
                var jxSFieldShowInForm = $('#jxFieldShowFormDisplay_' + Index);
                if (checkbox.is(':checked')) {
                    jxSFieldShowInForm.show();
                } else {
                    jxSFieldShowInForm.hide();
                }
            });

            $('#FieldsContainer').on('change', '.ShowFieldVisibleonPortalCheckbox', function () {
                var checkbox = $(this);
                var Index = checkbox.data('fieldindex');
                var jxSFieldReadonlyDisplay = $('#jxFieldReadonlyDisplay_' + Index);
                if (checkbox.is(':checked')) {
                    jxSFieldReadonlyDisplay.show();
                } else {
                    jxSFieldReadonlyDisplay.hide();
                }
            });

            $('#FieldsContainer').on('click', '.FieldEdit', function () {

                var FieldEditButton = $(this);
                var Index = FieldEditButton.data('fieldindex');

                CHSI.Claims.FormTypes.Fields.ShowHideDetailRow(Index);

            });
            $('#FieldsContainer').on('click', '.revertbutton', function () {
                var RevertButton = $(this);
                var Index = RevertButton.data('fieldindex');
                RevertButton.attr('disabled', 'disabled');
                var isFound = false;
                $(CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Fields).each(function (IndexInArray, Field) {
                    if (IndexInArray == Index) {
                        if (Field.FormFieldID == null) {
                            CHSI.Claims.FormTypes.Fields.BindFieldtoPresentation(IndexInArray, CHSI.Claims.FormTypes.Fields.GetDefaultField());
                        }
                        else {
                            CHSI.Claims.FormTypes.Fields.BindFieldtoPresentation(IndexInArray, Field);
                        }

                        isFound = true;
                        return false;
                    }
                });
            });

            $('#FieldsContainer').on('change', '.FieldType', function () {
                var FieldTypeList = $(this);
                var index = FieldTypeList.data('fieldindex');
                var FieldType = FieldTypeList.val() * 1;

                CHSI.Claims.FormTypes.Fields.ProcessFieldTypeChange(index, FieldType);
            });

            $('#FieldsContainer').on('change', 'input,textarea,select', function () {
                var ChangeField = $(this);
                var Index = ChangeField.data('fieldindex');
                var RevertButton = $('#jxFieldRevert_' + Index);
                RevertButton.removeAttr('disabled');
            });

            $('#FieldsContainer').on('click', '.deletebutton', function () {
                var isDeleted = confirm('Please confirm that you would like to delete this field');                
                if (isDeleted) {                    
                    CHSI.Claims.FormTypes.Fields.Events.deleteField($(this));
                }
            });            
            $('#FieldsContainer').on('click', '.cancelButton', function () {                
                    CHSI.Claims.FormTypes.Fields.Events.deleteField($(this));                
            });

            $('#FieldsContainer').on('change', '.setSortOrder', function () {
                CHSI.Claims.FormTypes.Fields.Cache.SortType = $(this).val();
                var sortOrder = $(this).val();
                if (sortOrder == 'form') {
                    $('.formSortOrderContainer').show();
                    $('.gridSortOrderContainer').hide();
                } else {
                    (CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Fields);
                    $('.gridSortOrderContainer').show();
                    $('.formSortOrderContainer').hide();
                }
            });

            $('#FieldsContainer').on('change', '.showInForm, .showInGrid', function () {
                var isChecked = $(this).is(':checked');
                var index = $(this).find('masterDetail').data('index');
                if ($(this).attr('class') == 'showInForm') {
                    CHSI.Claims.FormTypes.Fields.Events.getFieldByIndex(index).FormShow = isChecked;
                } else {                    
                    CHSI.Claims.FormTypes.Fields.Events.getFieldByIndex(index).GridShow = isChecked;
                }
            });
        },
        GetFieldsFromPresentationLayer: function () {
            var fields = CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Fields,
                fieldObject = null,
                fieldID = null;
            $('.masterDetail').each(function (i, v) {

                var index = $(this).data('index');                
                var formFieldID = $(this).data('formfieldid');
                fieldObject = CHSI.Claims.FormTypes.Fields.Events.getFieldByFieldID(formFieldID, false);

                if (fieldObject == null) {
                    for (fieldIndex = 0; fieldIndex < fields.length; fieldIndex++) {
                        if (fields[i].FormFieldID == -1) {                            
                            fieldObject = fields[i];                            
                        }
                    }
                }

                //for (fieldIndexValue = 0; fieldIndexValue < fields.length; fieldIndexValue++) {

                //    fieldObject.FormFieldID = $('#jxFieldName_' + index).data('formfieldid') == -1 ? null : $('#jxFieldName_' + i).data('formfieldid');

                //if (fields[fieldIndexValue].FormFieldID == fieldObject.FormFieldID || fields[fieldIndexValue].FormFieldID == -1) {

                fieldObject.FormFieldID = fieldObject.FormFieldID == -1 ? null : $(this).data('formfieldid');
                fieldObject.CategoryName = $('#jxFieldCategory_' + index).val();
                fieldObject.FieldName = $('#jxFieldName_' + index).val();
                fieldObject.GridShow = $(this).find('.showInGrid').is(':checked');
                fieldObject.GridSortOrder = $(this).data('gridsortorder');
                fieldObject.FormShow = $(this).find('.showInForm').is(':checked');
                fieldObject.FormSortOrder = $(this).data('formsortorder');
                fieldObject.PortalShow = $('#jxFieldVisibleOnPortal_' + index).is(':checked');
                fieldObject.PortalReadOnly = $('#jxFieldReadOnly_' + index).is(':checked');
                fieldObject.Status = $('#jxFieldStatus_' + index).val();
                fieldObject.FormCategoryID = null;
                fieldObject.LongName = $('#jxFieldLongName_' + index).val();
                fieldObject.Required = $('#jxFieldRequired_' + index).val();
                fieldObject.ToolTipText = $('#jxFieldToolTip_' + index).val();
                fieldObject.Summarize = $('#jxFieldSummarized_' + index).val();
                fieldObject.FieldType = $('#jxFieldFieldType_' + index).val();
                fieldObject.DataType = $('#jxFieldDataType_' + index).val();
                fieldObject.DataSize = $('#jxFieldSize_' + index).val();
                fieldObject.FieldLogic = '';

                switch (fieldObject.FieldType * 1) {
                    case 1 /*regular*/:
                        fieldObject.FieldLogic = $('#jxFieldValidator_' + index).val();
                        break;
                    case 2 /*calculated*/:
                    case 4 /*dropdown*/:
                        fieldObject.FieldLogic = $('#jxFieldFormula_' + index).val();
                        break;
                    case 3 /*date*/:
                        break;
                    case 5 /*textblock*/:
                        break;
                    default:
                }
                fieldObject.DisplayFormat = $('#jxFieldDisplayFormat_' + index).val();
                        
                    //}                    
                //}
                                                                            
            });

            this.ValidateFieldInformation();
        },
        SaveFields: function (successFunction) {                            
            if (CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Fields) {
                CHSI.Claims.FormTypes.Fields.Events.GetFieldsFromPresentationLayer();
                CHSI.Claims.FormTypes.Fields.Calls.SaveFields(successFunction);
            }             

            CHSI.Claims.FormTypes.Fields.Cache.isNewFieldActive = false;
            $('#jxFieldAddField').prop('disabled', false);
        },
        ValidateFieldInformation: function () {
            var Fields = CHSI.Claims.FormTypes.Fields.Cache.FieldsObject;
            var isMissingValue = false;            
            
            for (i = 0; i < Fields.length; i++) {                
                $.each(Fields[i], function (k, v) {
                    var FieldName = $('#jxFieldName_' + i).val();
                    if (FieldName == "") {
                        isMissingValue = true;
                        return false;
                    } else {                    
                        switch (k) {
                            case 'FormCategoryID':
                                break;
                            case 'FieldOptions':
                                break;
                            case 'FieldLogic':
                                if (v == null) {
                                    v = '';
                                }
                                break;
                            default:                                
                                if (v == null) {                                    
                                    isMissingValue = true;
                                    return false;
                                }
                        }                    
                    }
                });
                if (isMissingValue) {
                    break;
                }
                
            }
            return isMissingValue;
        },
        DynamicFieldTypes: function (FieldTypeValue) {
            var Calls = CHSI.Claims.FormTypes.Fields.Calls;
            var Cache = CHSI.Page.Cache;
            var HTML = [];
            switch (FieldTypeValue) {
                case 1: // Regular
                    $('#jxSFieldDynamicFieldType_' + + '').html('Validator')
                    HTML.push('<select>');
                    HTML.push('<option value=""></option>');
                    $.each(Cache.ValidatorTypes, function () {
                        HTML.push('<option value="' + Cache.ValidatorTypes + '"></option>');
                    })
                    HTML.push('</select>');
                    break;
                case 2: // Calculated
                    HTML.push('');
                    break;
                case 3: // Date
                    HTML.push('<input type="text" class="jxDatePicker" />');
                    break;
                case 4: // DropDown
                    HTML.push('');
                    break;
                case 5: // TextBlock
                    HTML.push('');
                    break;
                default:
                    alert('Type not supported.');
                    break;
            }
            return HTML.join('');
        },
        ProcessFieldCall: function (Fields) {            
            if (CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories != undefined) {
                if (CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories.length != 0) {
                
            CHSI.Claims.FormTypes.Fields.Cache.FieldsObject = { Fields: CHSI.Claims.FormTypes.Fields.Events.getFieldsByCategory(), Categories: CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories, ContentTypes: CHSI.Claims.FormTypes.Fields.Cache.ContentTypes };
            CHSI.Claims.FormTypes.Fields.Binds.Fields();
                }
            }
        },
        addField: function () {
            if (CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Fields == null) {
                CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Fields = [];
            }

            var field = {
                FormTypeID: CHSI.Claims.FormTypes.Information.Cache.FormTypeID * 1,
                FormFieldID: -1,
                CategoryName: $('#jxFieldCategories :selected').val(),
                FormEditable: true,
                Required: false,
                Status: 'Active',
                FieldType: '1',
                Summarize: false,
                GridShow: false,
                FormShow: false,
                PortalShow: false,
                FieldLogic: '',
                FormSortOrder: CHSI.Claims.FormTypes.Fields.Events.getLastSortOrderIndex(),
                ShowForm: false,
                GridSortOrder: CHSI.Claims.FormTypes.Fields.Events.getLastSortOrderIndex(),
                GridShow: false
            }
            console.log(CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Fields);
            CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Fields.push(field);
            console.log(CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Fields);
            CHSI.Claims.FormTypes.Fields.Cache.isNewFieldActive = true;

            CHSI.Claims.FormTypes.Fields.Binds.Fields();

            CHSI.Claims.FormTypes.Fields.Events.updateFieldSortOrder();

            CHSI.Claims.FormTypes.Fields.ShowHideDetailRow(CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Fields.length - 1);
                        
        },
        deleteField: function ($this) {
            var fieldID = $this.data('formfieldid'),
                index = $this.data('fieldindex'),
                fields = CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Fields;

            $this.closest('li').fadeOut('fast');

            for (i = 0; i < fields.length; i++) {
                
                if (i == index) {
                    fields.splice(index, 1);
                }

            }

            CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Fields = fields;
            if (fieldID) {
                CHSI.Claims.FormTypes.Fields.Calls.deleteField(fieldID, function () {
                    CHSI.Claims.FormTypes.Fields.Events.updateRowColor();
                    CHSI.Claims.FormTypes.Fields.Events.updateFieldSortOrder();
                });
            } else {
                CHSI.Claims.FormTypes.Fields.Events.updateRowColor();
                CHSI.Claims.FormTypes.Fields.Events.updateFieldSortOrder();
            }
            CHSI.Claims.FormTypes.Fields.Cache.isNewFieldActive = false;
            $('#jxFieldAddField').prop('disabled', false);
        },
        getFieldsByCategory: function () {
           
            var categoryName = (CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories[0].CategoryName == null ? null : CHSI.Claims.FormTypes.Categories.Cache.CategoriesObject.Categories[0].CategoryName);
            var fields = CHSI.Claims.FormTypes.Fields.Cache.FieldsArray;
            var fieldsList = [];

            if (CHSI.Claims.FormTypes.Fields.Cache.SelectedCategoryName == null) {
                CHSI.Claims.FormTypes.Fields.Cache.SelectedCategoryName = categoryName;
            } 

      

            if ($('#jxFieldCategories :selected').text() == 'Internal') {
                for (i = 0; i < fields.length; i++) {
                    if (fields[i].CategoryName == '' || fields[i].CategoryName == null || fields[i].CategoryName == ' ' || fields[i].CategoryName == 'Internal') {
                        fieldsList.push(fields[i]);
                    }
                }
            } else {                
                for (i = 0; i < fields.length; i++) {
                    if (fields[i].CategoryName == CHSI.Claims.FormTypes.Fields.Cache.SelectedCategoryName) {
                        fieldsList.push(fields[i]);
                    }
                }
            }            

            return fieldsList;

        },
        StickyCategoryName: function () {
            var CategoryList = CHSI.Claims.FormTypes.Categories.Cache.CategoriesList,
                Category = $('#jxFieldCategories');
            
            for (Index = 0; Index < CategoryList.length; Index++) {
                if ($('#jxFieldCategory_' + Index).data('fieldindex') == CategoryList[Index].Index) {
                    $('#jxFieldCategory_' + Index).val(CategoryList[Index].CategoryName);
                    $('#jxFieldCategory_' + Index).data('fieldindex', CategoryList[Index].Index);
                }
            }
        },
        getFieldByIndex: function (index, isFieldArray) {
            if (isFieldArray == undefined) {
                isFieldArray = true;
            }
            var fieldObject = null;
            var fields = isFieldArray == true ? CHSI.Claims.FormTypes.Fields.Cache.FieldsArray : CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Fields;
            for (i = 0; i < fields.length; i++) {
                if (index == i) {
                    fieldObject = fields[i];
                    break;
                }
            }
            return fieldObject;
        },
        getFieldByFieldID: function (fieldID, isFieldArray) {
            if (isFieldArray == undefined) {
                isFieldArray = true;
            }
            var fieldObject = null;
            var fields = isFieldArray == true ? CHSI.Claims.FormTypes.Fields.Cache.FieldsArray : CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Fields;
            for (i = 0; i < fields.length; i++) {
                if (fields[i].FormFieldID == fieldID) {
                    fieldObject = fields[i];
                    break;
                }
            }
            return fieldObject;
        },
        getFormTypeParameters: function () {
            var obj = {};
            obj.FormTypeID = CHSI.Claims.FormTypes.Information.Cache.FormTypeID == undefined ? null : CHSI.Claims.FormTypes.Information.Cache.FormTypeID,
            obj.FormCategoryID = CHSI.Claims.FormTypes.Fields.Cache.FormCategoryID == undefined ? null : CHSI.Claims.FormTypes.Fields.Cache.FormCategoryID,
            obj.FormFieldID = CHSI.Claims.FormTypes.Fields.Cache.FormFieldID == undefined ? null : CHSI.Claims.FormTypes.Fields.Cache.FormFieldID;
            return obj;
        },
        updateFieldSortOrder: function () {
            var fields = CHSI.Claims.FormTypes.Fields.Events.getFieldsByCategory();


            $('#fieldContainer > ul > li').each(function (i, v) {
                if (CHSI.Claims.FormTypes.Fields.Cache.SortType == 'form') {
                    $(this).find('.masterDetail').data('formsortorder', i); 
                    //fields[i].FormSortOrder = i;
                } else {
                    $(this).find('.masterDetail').data('gridsortorder', i);
                }
            });
        },
        updateRowColor: function () {
            $('#fieldContainer > ul > li').each(function (i, v) {                
                var backgroundcolor = 'arow';
                if (i % 2 == 0) {
                    backgroundcolor = 'prow';
                }
                $(this).removeClass('arow prow').addClass(backgroundcolor);
                return backgroundcolor;
            });            
        },
        getLastSortOrderIndex: function () {
            var fields = CHSI.Claims.FormTypes.Fields.Events.getFieldsByCategory();
            var previousSortOrder = 0;
            for (i = 0; i < fields.length; i++) {
                if (fields[i].SortOrder > previousSortOrder) {
                    previousSortOrder = fields[i].SortOrder;
                }
            }
            return previousSortOrder + 1;
        },
        sortOrder: function (orderBy) {
            // TODO
        }
    },    
    Calls: {
        SaveField: function (Fields, successFunction) {
            var Options = {};
            Options.URL = '/api/ClaimSystem/Form/SaveFormFields';
            Options.Data = Fields;
            Options.Success = function (data) {                
                if (successFunction) {
                    successFunction();
                }
            }
            Options.Error = function (data) {
                CHSI.SystemNotification.SetResponse('An error occurred and has been logged', 2);
                CHSI.Common.LogObject(data);
            }

            CHSI.Common.Ajax.Post(Options);
        },
        SaveFields: function (successFunction) {                       
                var Options = {};
                Options.URL = '/api/ClaimSystem/Form/SaveFormFields';
                Options.Data = CHSI.Claims.FormTypes.Fields.Cache.FieldsObject.Fields;
                Options.Success = function (data) {
                    CHSI.Claims.FormTypes.Fields.Events.ProcessFieldCall(data);                    

                    if (successFunction) {
                        successFunction();
                    }
                }
                Options.Error = function (data) {
                    CHSI.SystemNotification.SetResponse('An error occurred and has been logged', 2);
                    CHSI.Common.LogObject(data);
                }

                CHSI.Common.Ajax.Post(Options);            
        },
        GetFields: function (successFunction) {
            var Options = {},
            FormTypeID = CHSI.Claims.FormTypes.Information.Cache.FormTypeID == undefined ? null : CHSI.Claims.FormTypes.Information.Cache.FormTypeID,
            FormCategoryID = CHSI.Claims.FormTypes.Fields.Cache.FormCategoryID == undefined ? null : CHSI.Claims.FormTypes.Fields.Cache.FormCategoryID,
            FormFieldID = CHSI.Claims.FormTypes.Fields.Cache.FormFieldID == undefined ? null : CHSI.Claims.FormTypes.Fields.Cache.FormFieldID;

            Options.URL = '/api/ClaimSystem/Form/GetFormFields?FormTypeID=' + FormTypeID + '&FormFieldID=' + FormFieldID + '&FormCategoryID=' + FormCategoryID;
            Options.Success = function (data) {
                CHSI.Claims.FormTypes.Fields.Cache.FieldsArray = data;
                CHSI.Claims.FormTypes.Fields.Events.ProcessFieldCall();

                if (successFunction) {
                    successFunction(data);
                }
            }
            Options.Error = function () {
                CHSI.Common.Log('no data to return');
            }

            CHSI.Common.Ajax.Get(Options);            
        },
        GetValidatorTypes: function (successFunction) {
            var Options = {};
            Options.URL = '/api/Common/ContentType/Get';
            Options.Data = null;
            Options.Success = function (data) {
                CHSI.Claims.FormTypes.Fields.Cache.ContentTypes = data;
                if (successFunction) {
                    successFunction(data);
                }
            }
            Options.Error = function (data) {
                if (successFunction) {
                    successFunction(data);
                }
                CHSI.Common.Log('no data to return');
            }

            CHSI.Common.Ajax.Get(Options);            
        },
        GetCategories: function (successFunction) {
            var Options = {},
               FormTypeID = CHSI.Claims.FormTypes.Information.Cache.FormTypeID;
            
            Options.URL = '/api/ClaimSystem/Form/GetCategoriesByType?FormTypeID=' + FormTypeID;
            Options.Success = function (data) {
                CHSI.Claims.FormTypes.Categories.Cache.CategoriesList = data;
                
                if (successFunction) {
                    successFunction(data);
                }
            }
            Options.Error = function () {
                CHSI.Common.LogObject(e);                
            }
            CHSI.Common.Ajax.Get(Options);
        },
        deleteField: function (formFieldID, successFunction) {
            var URL = '/api/ClaimSystem/Form/DeleteFieldByFieldID?formFieldID=' + formFieldID + '&formTypeID=' + CHSI.Claims.FormTypes.Information.Cache.FormTypeID;
            $.post(URL).done(function (data) {
                if (successFunction) {
                    successFunction(data);
                }
            });
        }
    }
}