module PL.ClaimSystem.ClaimManagement {
    export class AssignmentModel {
        constructor() { this.AssigneeInfo = new Assignee(); }

        public AssigneeInfo: Assignee;
        public ClaimAssignmentID: number;
        public ClaimID: number;
        public AssigneeID: number;
        public FromDate: string;
        public ToDate: string;
        public Primary: boolean;
        public Status: string;
        public AssigneeType: string;
        public CreateUser: string;
        public CreateTmsp: string;
        public UpdateUser: string;
        public UpdateTmsp: string;        
    }
}