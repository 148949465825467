module PL.Que.APIs {

    export class Section_Repo {

        private static _routePrefix: string = '';

        public static saveSection(section: any): JQueryDeferred<number> {
            var results = $.Deferred();
            $.post(this._routePrefix + 'SaveSection', section).done((data) => {
                results.resolve(data);
            });
            return results;
        }
    }

}