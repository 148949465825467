CHSI.CRM.ProducerUser = {

    Init: function (qs) {
       
        if (qs) {
            if (qs.ExtUsersId || qs.ExtUsersID) {
                this.Cache.ExtUsersID = (qs.ExtUsersId | qs.ExtUsersID);
            }

            if (qs.ProducerID || qs.id) {
                this.Cache.ProducerID = (qs.ProducerID | qs.id);
            }
        }
        CHSI.CRM.ProducerUser.Calls.GetGetExtRoles();
        CHSI.CRM.ProducerUser.Events.Global();
    },
    Templates: {
    },
    Cache: {
        PermissionsObject:null
        , ProducerID: null
        , ExtUsersID: null
        ,RoleType:2
        , UserObject: null
        , ExtUserObject: null
        , RoleIDs: []
        , UserFound:false

    },
    Events: {
        Global: function () {

            

            $('#uxUserID').on('blur', function () {
               // var UserName = $(this).val();
               // CHSI.CRM.ProducerUser.Calls.GetUser(UserName);

            });
           
            
            $('.iRequired').on('blur', function () {
                var validator = jQuery('form').validate({ debug: true, onkeyup: false, onfocusout: false });
                validator.element(this);
                var Element = $(this);
                if (!validator.errorList.length) {
                    Element.qtip('destroy');
                }
            });
           
            $('#jxAddNew').on('click', function () {
                var ShowEditStructureURL = '/que/modals/datastructureeditor.aspx';
                ShowPopup("EditDataStructure", ShowEditStructureURL, "Edit Data Structure", 800, 580, CHSI.CRM.ProducerUser.Calls.GetDataStructures, true);
            });

            $('#jxOK').on('click', function () {
                if (jQuery('form').valid()) {
                    var UserName = $('#uxUserID').val();
                    CHSI.CRM.ProducerUser.Calls.GetUser(UserName, CHSI.CRM.ProducerUser.Calls.SaveExternalUser);
                }                
                
            });

            $('#jxCancel').on('click', function () {
                closeThis(false);

            });
            
        },

        GetFieldsFromPresentationLayer: function () {
            CHSI.CRM.ProducerUser.Cache.RoleIDs = [];
            ExtUser = {};
            ExtUser.ExtUsersID = CHSI.CRM.ProducerUser.Cache.ExtUsersID;
            ExtUser.UserID = $('#uxUserID').val();
            ExtUser.Restrictions = $('#uxRestrictions').val();
            ExtUser.Username = $('#uxFirstName').val() + ' ' + $('#uxLastName').val();
            ExtUser.EmailAddress = $('#uxEmailAddress').val();
            ExtUser.Password = $('#uxPassword').val();
            if (ExtUser.Password != '')
            {
                ExtUser.PasswordFormat = 0;
                ExtUser.PasswordSalt = '';
            }
            
            ExtUser.ExpirationDate = null;
            ExtUser.Status = $('#uxStatus').val();
            ExtUser.ProducerID = CHSI.CRM.ProducerUser.Cache.ProducerID;
            
            var Role = {};
            Role.Authorized = "Y";
            Role.ExtUsersID = CHSI.CRM.ProducerUser.Cache.ExtUsersID;
            Role.RoleID = 5;
            Role.UserID = ExtUser.UserID;
            Role.UserRole = 0;
            CHSI.CRM.ProducerUser.Cache.RoleIDs.push(Role);

            $('.jxRole').each(function (Index, Value) {
                if ($(this).is(':checked')) {
                    var Role = {};
                    Role.Authorized = "Y";
                    Role.ExtUsersID = CHSI.CRM.ProducerUser.Cache.ExtUsersID;
                    Role.RoleID = $(this).val();
                    Role.UserID = ExtUser.UserID;
                    Role.UserRole = 0;
                    CHSI.CRM.ProducerUser.Cache.RoleIDs.push(Role);
                }
            });

            ExtUser.Roles = CHSI.CRM.ProducerUser.Cache.RoleIDs;
            CHSI.CRM.ProducerUser.Cache.ExtUser = ExtUser;
        }
    },
    BindPresentationLayer: function () {

       
        if (CHSI.CRM.ProducerUser.Cache.PermissionsObject && CHSI.CRM.ProducerUser.Cache.PermissionsObject.length) {
            var row = '';
            for (i = 0; i < CHSI.CRM.ProducerUser.Cache.PermissionsObject.length; i++) {
                var Permission=CHSI.CRM.ProducerUser.Cache.PermissionsObject[i];               
                if (i % 2 == 0) {
                    row += '<tr>';
                }

                row += '<td><input id="jxPermission_' + Permission.RoleID + '" type="checkbox" value="' + Permission.RoleID + '" class="jxRole"><label for="jxPermission_' + Permission.RoleID + '">' + Permission.RoleName + '</label></td>';

                if (i % 2 == 1 || i == CHSI.CRM.ProducerUser.Cache.PermissionsObject.length - 1) {
                    row += '</tr>';
                }
            }
            $('#uxPermissions').html(row);
        }
      
    }
    , BindUser: function () {
        $('#uxUserID').val(CHSI.CRM.ProducerUser.Cache.ExtUserObject.UserID);
        $('#uxRestrictions').val(CHSI.CRM.ProducerUser.Cache.ExtUserObject.Restrictions);
        $('#uxFirstName').val(CHSI.CRM.ProducerUser.Cache.ExtUserObject.UserName.split(' ').slice(0, -1).join(' '));
        $('#uxLastName').val(CHSI.CRM.ProducerUser.Cache.ExtUserObject.UserName.split(' ').slice(-1).join(' '));
        $('#uxEmailAddress').val(CHSI.CRM.ProducerUser.Cache.ExtUserObject.emailaddress);
        if (CHSI.CRM.ProducerUser.Cache.ExtUserObject.PasswordFormat != '1')
        {
            CHSI.Common.Log('binding the password password format ' + CHSI.CRM.ProducerUser.Cache.ExtUserObject.PasswordFormat);
            $('#uxPassword').val(CHSI.CRM.ProducerUser.Cache.ExtUserObject.Password);
        }
        
        $('#uxStatus').val(CHSI.CRM.ProducerUser.Cache.ExtUserObject.Status);

        for (i = 0; i < CHSI.CRM.ProducerUser.Cache.ExtUserObject.Roles.length; i++) {
            $('#jxPermission_' + CHSI.CRM.ProducerUser.Cache.ExtUserObject.Roles[i].RoleID).prop('checked', true);
        }
    }
    , Calls: {
        GetUser: function (UserID, SuccessFunction) {
            var Options = {};
            CHSI.CRM.ProducerUser.Cache.UserFound = false;
            Options.URL = "/api/Security/PortalUser/GetUser?UserID=" + UserID;
            Options.Success = function (e) {
                CHSI.CRM.ProducerUser.Cache.UserObject = e;
                if (e){ 
                    CHSI.CRM.ProducerUser.Cache.UserFound = true;
                    if (!CHSI.CRM.ProducerUser.Cache.ExtUsersID) {
                        alert('Username exists, please choose another');
                    }else{
                        CHSI.CRM.ProducerUser.Cache.UserFound = false;
                    }
                }
                if(!CHSI.CRM.ProducerUser.Cache.UserFound ){
                    SuccessFunction();
                }                   
                
            };
            CHSI.Common.Ajax.Get(Options);
        },
        GetUserByExtUserID: function () {
                if (CHSI.CRM.ProducerUser.Cache.ExtUsersID) {
                    var Options = {};
                    Options.URL = "/api/Security/PortalUser/GetPortalUserByExtUserID?ExtUserID=" + CHSI.CRM.ProducerUser.Cache.ExtUsersID;
                    Options.Success = function (e) {
                        CHSI.CRM.ProducerUser.Cache.ExtUserObject = e; CHSI.CRM.ProducerUser.BindUser();
                        $('#uxUserID').prop('readonly', true);
                    };
                    CHSI.Common.Ajax.Get(Options);
                }
            },
        GetGetExtRoles: function () {
            var Options = {};
            Options.URL = "/api/Security/PortalUser/GetExtRoles?RoleType=" + CHSI.CRM.ProducerUser.Cache.RoleType;
            Options.Success = function (e) {
                CHSI.CRM.ProducerUser.Cache.PermissionsObject = e;
                CHSI.CRM.ProducerUser.BindPresentationLayer();
                CHSI.CRM.ProducerUser.Calls.GetUserByExtUserID();
            };
            CHSI.Common.Ajax.Get(Options);
        },

        SaveExternalUser: function () {
            if (!CHSI.CRM.ProducerUser.Cache.UserFound || CHSI.CRM.ProducerUser.Cache.ExtUsersID!=null) {
                var Options = {};
                Options.URL = "/api/Security/PortalUser/SaveExternalUser";
                CHSI.CRM.ProducerUser.Events.GetFieldsFromPresentationLayer();
                Options.Data = CHSI.CRM.ProducerUser.Cache.ExtUser;
                Options.Success = function (e) {
                    closeThis(true);
                    
                };
                CHSI.Common.Ajax.Post(Options);
            }
        }

    }
}
