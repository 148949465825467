CHSI.UW.MuniQuote = {

    Init: function (QuoteID, QuoteObject) {
        this.Cache.QuoteID = QuoteID;
        this.Cache.QuoteObject = QuoteObject;
    },

    Cache: {
        QuoteID: null,
        QuoteObject: null,
        CurrentControl: null,
        CurrentControlValue: null,
        CurrentControlCachedValue: null,
        QuoteList:null
    },

    GetValueByDataType: function (DataObject) {
        var NewValue;

        switch (DataObject.DataType) {
            case 1:
                NewValue = DataObject.StringValue;
                break;
            case 2:
                NewValue = DataObject.DateValue;
                break;
            case 3:
                NewValue = DataObject.DecimalValue;
                break;
            case 4:
                NewValue = DataObject.BoolValue;
                break;
            case 5:
                NewValue = DataObject.IntegerValue;
                break;
        }

        return NewValue;
    },

    SetValue: function (DataObject, NewValue) {
        DataObject.GetValue = NewValue;
        switch (DataObject.DataType) {
            case 1:
                DataObject.StringValue = NewValue;
                break;
            case 2:
                DataObject.DateValue = NewValue;
                break;
            case 3:
                DataObject.DecimalValue = NewValue;
                break;
            case 4:
                DataObject.BoolValue = NewValue;
                break;
            case 5:
                DataObject.IntegerValue = NewValue;
                break;
        }
    },

    SetDefaultValue: function (DataObject, NewValue) {
        DataObject.GetValue = NewValue;
        switch (DataObject.DataType) {
            case 1:
                DataObject.DefaultStringValue = NewValue;
                break;
            case 2:
                DataObject.DefaultDateValue = NewValue;
                break;
            case 3:
                DataObject.DefaultDecimalValue = NewValue;
                break;
            case 4:
                DataObject.DefaultBoolValue = NewValue;
                break;
            case 5:
                DataObject.DefaultIntegerValue = NewValue;
                break;
        }
    },

    GetDefaultValue: function (DataObject) {
        var result;

        switch (DataObject.DataType) {
            case 1:
                result = DataObject.DefaultStringValue; break;
            case 2:
                result = DataObject.DefaultDateValue; break;
            case 3:
                result = DataObject.DefaultDecimalValue; break;
            case 4:
                result = DataObject.DefaultBoolValue; break;
            case 5:
                result = DataObject.DefaultIntegerValue; break;

                return result;
        }
    },

    SaveVariable: function (VarID, VariableObj, Success) {
        var Options = {};
        Options.Data = {};
        Options.Data = VariableObj;
        switch (VarID.substring(0, 3)) {
            case "RaV":
                Options.URL = '/api/UW/MuniQuote/SaveQuoteRateVariable';
                var IDs = VarID.replace('RaV_', '').split('_');
                Options.Data.ProgramRateVariableID = IDs[0];
                Options.Data.RateInfoID = IDs[1];
                break;
            case "VaR":
                Options.URL = '/api/UW/MuniQuote/SaveQuoteVariable';
                Options.Data.VariableID = VarID.replace('VaR_', '');
                break;

        }
        var ajaxString = JSON.stringify(VariableObj);
        if (CHSI.Common.DebugMode) {
            CHSI.Common.Log('value - ' + Options.URL + ', Data: ' + ajaxString);
        }
        Options.Success = Success;
        CHSI.Common.Ajax.Put(Options);
    },

    RecalculateVariable: function (VarID, QuoteID, Success) {

        var Options = {};
        Options.Data = {};
        ajaxData = {};
        ajaxData.DataObject = {};
        ajaxData.Comments = 'Forced Recalculation';
        ajaxData.DataObject.isLocked = 1;
        ajaxData.DataObject.QuoteID = QuoteID;
        ajaxData.ForceAutoCalculation = true;
        ajaxData.Status = 'active';

        switch (VarID.substring(0, 3)) {
            case "RaV":
                Options.URL = '/api/UW/MuniQuote/RecalculateQuoteRateVariable';
                var IDs = VarID.replace('RaV_', '').split('_');
                ajaxData.ProgramRateVariableID = IDs[0];
                ajaxData.RateInfoID = IDs[1];
                break;
            case "VaR":
                Options.URL = '/api/UW/MuniQuote/RecalculateQuoteVariable';
                ajaxData.VariableID = VarID.replace('VaR_', '');
                break;
        }
        Options.Data = ajaxData;
        var ajaxString = JSON.stringify(ajaxData);
        if (CHSI.Common.DebugMode) {
            CHSI.Common.Log('value - ' + Options.URL + ', Data: ' + ajaxString);
        }
        Options.Success = Success;
        CHSI.Common.Ajax.Put(Options);
    },

    RecalculateQuote: function (QuoteID, Success) {

        var Options = {};
        Options.Data = QuoteID;
        Options.URL = '/api/UW/MuniQuote/RecalculateQuote';
        Options.Success = Success;
        CHSI.Common.Ajax.Put(Options);
    },

    CopyQuote: function (QuoteObject, Success) {
        var Options = {};
        Options.URL = '/api/UW/MuniQuote/CopyQuote';
        Options.Data = QuoteObject;
        Options.Success = Success;       
        CHSI.Common.Ajax.Post(Options);
    },

    AddEndorsementAPICall: function (QuoteObject, Success) {
        var Options = {};
        Options.URL = '/api/UW/MuniQuote/AddEndorsement';
        Options.Data = QuoteObject;
        Options.Success = Success;
        CHSI.Common.Ajax.Post(Options);
    },

    AddEndorsement: function (QuoteID) {
        var Endorsement = null; 
        if (QuoteID) {
            Endorsement = {};
            CHSI.UW.MuniQuote.GetQuoteDetails(QuoteID, function (e) {
               
                if (e.length > 1) {
                    Endorsement = e[1];
                    Endorsement.ParentQuoteID = Endorsement.ParentQuoteID;
                    Endorsement.OriginalQuoteID = Endorsement.ParentQuoteID;
                } else {
                    Endorsement = e[0];
                    Endorsement.ParentQuoteID = Endorsement.QuoteID;
                    Endorsement.OriginalQuoteID = Endorsement.QuoteID;
                }              
               
                Endorsement.QuoteType = 'Endrse';
                CHSI.UW.MuniQuote.AddEndorsementAPICall(Endorsement, function (e) { window.location = '/uw/Quote/QuoteInfo.aspx?QuoteID=' + e + '&ClientID=' + Endorsement.ClientID + '&GroupName=' + Endorsement.GroupID; });
            });            
           
        } else
        {
            Endorsement = this.Cache.QuoteObject;
            Endorsement.ParentQuoteID = Endorsement.QuoteID;
            Endorsement.OriginalQuoteID = Endorsement.QuoteID;
            Endorsement.QuoteType = 'Endrse';
            CHSI.UW.MuniQuote.AddEndorsementAPICall(Endorsement, function (e) { window.location = '/uw/Quote/QuoteInfo.aspx?QuoteID=' + e + '&ClientID=' + Endorsement.ClientID + '&GroupName=' + Endorsement.GroupID; });
        }
               
        
    },
    GetUWRule: function (QuoteType, GroupID, PolicyPeriod, Code, Success) {
        var Options = {};
        Options.URL = '/api/UW/UW/GetRules?QuoteType=' + QuoteType + '&GroupID=' + GroupID + '&PolicyPeriod=' + PolicyPeriod + '&Code=' + Code;
        Options.Success = Success;
        CHSI.Common.Ajax.Get(Options);
    },

    GetGroupSpec: function (GroupID, SpecName, Success) {
        var Options = {};
        Options.URL = '/api/Common/GroupSpecification/GetGroupSpecification?GroupID=' + GroupID + '&SpecName=' + SpecName;
        Options.Success = Success;
        Options.Error = CHSI.Common.Log('Please check the groupid or specname.');
        CHSI.Common.Ajax.Get(Options);
    },

    AddQuoteCoverageLine: function (QuoteID, CoverageLineID, Success) {
        var Options = {};
        Options.Data = {};
        Options.URL = "/api/UW/MuniQuote/AddQuoteCoverageLine?QuoteId=" + QuoteID + "&CoverageLineId=" + CoverageLineID;
        Options.Error = CHSI.DOM.ProcessAjaxError;
        Options.Success = Success;
        CHSI.Common.Ajax.Post(Options);
    },

    CreateNewQuote: function (QuoteObject, Success) {
        var Options = {};
        Options.Data = QuoteObject;
        Options.URL = "/api/UW/MuniQuote/CreateNewQuote";
        Options.Error = CHSI.DOM.ProcessAjaxError;
        Options.Success = function (data) {
            if (Success) {
                Success(data);
            }
        };
        CHSI.Common.Ajax.Post(Options);
    },

    ConstructQuote: function (QuoteType) {
        var NewQuote = {},
            d = new Date();

        function FullDate(addExtraYear) {
            if (addExtraYear == null) {
                addExtraYear = 0;
            }
            var fullYear = d.getUTCFullYear() + addExtraYear,
                monthArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

            return monthArray[d.getUTCMonth()] + '/' + d.getUTCDate() + '/' + fullYear;
        };

        NewQuote.QuoteDate = FullDate();
        NewQuote.PolicyPeriod = d.getFullYear();
        NewQuote.EffectiveDate = FullDate();
        NewQuote.ExpirationDate = FullDate(1);

        if (QuoteType) {
            NewQuote.QuoteType = QuoteType.QTName
            NewQuote.ProgramID = QuoteType.ProgramID;
            NewQuote.QuoteName = QuoteType.Name
        }

        return NewQuote;
    },
    GetQuoteDetails: function (QuoteID, Success) {
        var Options = {};
        //Options.Data = { QuoteID: QuoteID };
        Options.URL = "/api/UW/MuniQuote/GetQuote?QuoteID=" + QuoteID;
        Options.Error = CHSI.DOM.ProcessAjaxError;
        Options.Success = Success;
        CHSI.Common.Ajax.Get(Options);
    },
    SaveQuoteDetail: function (QuoteID, FieldID, FieldValue, Success) {
        var Options = {};
        Options.Data = { QuoteID: QuoteID, FieldID: FieldID, FieldValue: FieldValue };
        Options.URL = "/api/UW/QuoteDetail/Save";
        Options.Error = CHSI.DOM.ProcessAjaxError;
        Options.Success = Success;
        CHSI.Common.Ajax.Post(Options);
    },

    GetQuoteDetail: function (DetailID, FieldID, QuoteID, Success) {
        var Options = {};
        Options.URL = "/api/UW/QuoteDetail/Get?DetailID=" + DetailID + "&FieldID=" + FieldID + "&QuoteID=" + QuoteID;
        Options.Error = CHSI.DOM.ProcessAjaxError;
        Options.Success = Success;
        CHSI.Common.Ajax.Get(Options);
    },

    LoadFromQuoteDetailsToCache: function (QuoteDetailArr, Cache) {
        for (var i = 0; i < QuoteDetailArr.length; i++) {
            switch (QuoteDetailArr[i].FieldID) {
                case 29:
                    Cache.BillingType = QuoteDetailArr[i].FieldValue;
                    break;
                case 30:
                    Cache.BillingPeriod = QuoteDetailArr[i].FieldValue;
                    break;
                case 31:
                    Cache.BillingIncrement = QuoteDetailArr[i].FieldValue;
                    break;
                case 32:
                    Cache.Percentage = (QuoteDetailArr[i].FieldValue == 'percent' ? true : false);
                    break;
                case 33:
                    Cache.DepositPercentage = QuoteDetailArr[i].FieldValue;
                    break;
                case 34:
                    Cache.DepositAmount = QuoteDetailArr[i].FieldValue;
                    break;
                case 35:
                    Cache.NumberOfPayments = QuoteDetailArr[i].FieldValue;
                    break;
                case 36:
                    Cache.OnePayment = QuoteDetailArr[i].FieldValue;
                    Cache.PaymentAmount = QuoteDetailArr[i].FieldValue;
                    break;
                case 40:
                    Cache.FirstPaymentDate = QuoteDetailArr[i].FieldValue;
                    break;
            }
        }

    },
    SaveQuoteProperty: function (PropertyName, NewValue, Success) {
        var ajaxData = {};
        ajaxData.PropertyName = PropertyName;
        ajaxData.QuoteID = this.Cache.QuoteObject.QuoteID;
        ajaxData.NewValue = NewValue;
        var DataCollection = [];
        DataCollection.push(ajaxData);
        var Options = {};
        Options.Data = {};
        Options.Data = DataCollection;
        Options.URL = '/api/UW/MuniQuote/SaveQuoteproperty';
        var ajaxString = JSON.stringify(ajaxData);
        if (CHSI.Common.DebugMode) {
            CHSI.Common.Log('Updating Property value with URL ' + Options.URL + ' and Data: ' + ajaxString);
        }
        Options.Success = Success;
        Options.Error = CHSI.DOM.ProcessAjaxError;
        CHSI.Common.Ajax.Put(Options);
    },

    SaveBatchQuoteProperties: function (QuotePropertyArray, successFunction) {
        // object parameters: PropertyName, QuoteID, NewValue
        var Options = {};
        Options.URL = '/api/UW/MuniQuote/SaveQuoteproperty';
        Options.Data = QuotePropertyArray;
        Options.Success = successFunction;
        Options.Error = CHSI.DOM.ProcessAjaxError;
        CHSI.Common.Ajax.Put(Options);

    },
    DeleteQuote: function (QuoteID, GroupID) {
        var Response = confirm('Are you sure you want to delete this quote?');
        if (Response) {
            var Options = {};
            Options.URL = '/api/UW/MuniQuote/DeleteQuote?QuoteID=' + QuoteID;
            Options.Success = function () {
                alert('Quote deleted.');
                window.location = '/sig/underwriting/uwmain.aspx#GroupName=' + GroupID + '&TabID=2&TabName=QUOTED';
            };
            Options.Error = function () { alert('You do not have access to that function.'); };
            CHSI.Common.Ajax.Delete(Options);
        }
    },


    DeleteQuoteCoverageLine: function (QuoteID, CoverageLineID, Success) {
        var Options = {};
        Options.URL = '/api/UW/MuniQuote/DeleteQuoteCoverageLine?QuoteId=' + QuoteID + "&CoverageLineId=" + CoverageLineID;
        Options.Success = Success;
        Options.Error = CHSI.DOM.ProcessAjaxError;
        CHSI.Common.Ajax.Delete(Options);
    },

    GetQuotePropertyDisplay: function (PropertyName, ContentType) {
        var ret = '<input type="text" id="jxQuoteProp' + PropertyName + '"  ';
        var retdisabled = '';
        var retvalue = '';
        var retclass = '';
        var retdata = '';
        if (PropertyName == '')
        { }
        switch (PropertyName) {
            case "ProgramName":
            case "Status":
                retdisabled += ' disabled=disabled ';
                break;
            //case "PolicyPeriod":
            //    retdisabled += ' disabled=disabled ';
            //    break;
            default:
                retclass += 'QuoteProperty';
                retdata += ' data-propertyname="' + PropertyName + '" ';
        }

        switch (ContentType) {
            case "Date":
                retclass += ' DatePicker';
                retvalue += CHSI.Dates.GetShortDate(new Date(this.Cache.QuoteObject[PropertyName]));
                break;
            default:
                retvalue += this.Cache.QuoteObject[PropertyName];
        }
        ret += ' class="' + retclass + '" ' + retdata + ' value="' + retvalue + '" ' + retdisabled + '/>';
        return ret;
    },

    GetAppearanceCollection: function (ControlType) {
        if (this.Cache.QuoteObject == null) {
            throw new Error('Quote Object is null');
        }
        if (this.Cache.QuoteObject.Appearance == null) {
            throw new Error('Quote Object Appearance is null');
        }
        switch (ControlType) {
            case "RateVariables":
                return this.Cache.QuoteObject.Appearance.RateVariables;
                break;
            case "Variables":
                return this.Cache.QuoteObject.Appearance.Variables;
                break;
        }
    },

    GetVarIDfromObject: function (ControlObject, ControlType) {
        switch (ControlType) {
            case "RateVariables":
                return 'RaV_' + ControlObject.ProgramRateVariableID + '_' + ControlObject.RateInfoID;
                break;
            case "Variables":
                return 'VaR_' + ControlObject.VariableID;
                break;

        }
    },

    GetVariable: function (VariableID) {
        if (CHSI.Common.DebugMode) {
            CHSI.Common.Log('Getting VariableID ' + VariableID);
        }


        for (var Variable in this.Cache.QuoteObject.Vars) {
            if (this.Cache.QuoteObject.Vars[Variable].VariableID == VariableID) {
                return this.Cache.QuoteObject.Vars[Variable];
            }
        }

        for (var CL in this.Cache.QuoteObject.CL) {
            if (CHSI.Common.DebugMode) {
                CHSI.Common.Log('CL ' + CL + ', ' + this.Cache.QuoteObject.CL[CL].Rates.length)
            }

            for (var RateIndex = 0 ; RateIndex < this.Cache.QuoteObject.CL[CL].Rates.length; RateIndex++) {
                if (CHSI.Common.DebugMode) {
                    CHSI.Common.Log('Rate Index - ' + RateIndex);
                }

                for (var Variable in this.Cache.QuoteObject.CL[CL].Vars) {
                    if (this.Cache.QuoteObject.CL[CL].Vars[Variable].VariableID == VariableID) {
                        return this.Cache.QuoteObject.CL[CL].Vars[Variable];
                    }
                }
            }
        }
    },


    GetRateInfoVariable: function (ProgramRateVariableID, RateInfoID) {
        for (var CL in this.Cache.QuoteObject.CL) {
            if (CHSI.Common.DebugMode) {
                CHSI.Common.Log('CL ' + CL + ', ' + this.Cache.QuoteObject.CL[CL].Rates.length)
            }

            for (var RateIndex = 0 ; RateIndex < this.Cache.QuoteObject.CL[CL].Rates.length; RateIndex++) {
                if (CHSI.Common.DebugMode) {
                    CHSI.Common.Log('Rate Index - ' + RateIndex);
                }

                for (var RateType in this.Cache.QuoteObject.CL[CL].Rates[RateIndex].RateTypes) {
                    var RateDetail = this.Cache.QuoteObject.CL[CL].Rates[RateIndex].RateTypes[RateType];
                    if (CHSI.Common.DebugMode) {
                        CHSI.Common.Log('RateInfo - ' + RateDetail.RateInfoID);
                    }

                    if (RateDetail.RateInfoID == RateInfoID) {
                        if (CHSI.Common.DebugMode) {
                            CHSI.Common.Log('Rate Found');

                        }

                        for (var Variable in this.Cache.QuoteObject.CL[CL].Rates[RateIndex].RateTypes[RateType].Vars) {
                            if (CHSI.Common.DebugMode) {
                                CHSI.Common.Log('Checking - ' + this.Cache.QuoteObject.CL[CL].Rates[RateIndex].RateTypes[RateType].Vars[Variable].ProgramRateVariableID);
                            }

                            if (this.Cache.QuoteObject.CL[CL].Rates[RateIndex].RateTypes[RateType].Vars[Variable].ProgramRateVariableID == ProgramRateVariableID) {

                                return this.Cache.QuoteObject.CL[CL].Rates[RateIndex].RateTypes[RateType].Vars[Variable];
                            }
                        }
                    }
                }
            }
        }
    },

    GetQuoteObjectfromVarID: function (VarID) {
        if (CHSI.Common.DebugMode) {
            CHSI.Common.Log('getting quote object for ' + VarID);
        }

        switch (VarID.substring(0, 3)) {
            case "RaV":
                var IDs = CHSI.Common.replaceString('RaV_', '', VarID).split('_');
                var ProgramRateVariableID = IDs[0];
                var RateInfoID = IDs[1];
                if (CHSI.Common.DebugMode) {
                    CHSI.Common.Log('Getting Rate Variable for ProgramRateVariableID - ' + ProgramRateVariableID + ' and RateInfoID - ' + RateInfoID);
                }

                return this.GetRateInfoVariable(ProgramRateVariableID, RateInfoID);


                break;
            case "VaR":
                var VariableID = CHSI.Common.replaceString('VaR_', '', VarID);
                return this.GetVariable(VariableID);
                break;


        }

    },


    GetControlTypeFromVarID: function (VarID) {
        switch (VarID.substring(0, 3)) {
            case "RaV":
                return "RateVariables";
                break;
            case "VaR":
                return "Variables";
                break;

        }
    },

    GetControlType: function (AppearanceTemplate, SourceObject, ObjectType) {
        var ControlType;
        var Format = '';
        var DataCleanValue = '';
       
        if (AppearanceTemplate) {

        } else {
            AppearanceTemplate = {
                inEditMode: false
                , EditControlType:3
                , ReadOnlyControlType: 3
                , Format: ''
                , Locked:''
            }
        }
        if (AppearanceTemplate.inEditMode) {
            ControlType = AppearanceTemplate.EditControlType;
        }
        else {
            ControlType = AppearanceTemplate.ReadOnlyControlType;
        }
        if (CHSI.Common.DebugMode) {
            CHSI.Common.Log('Creating Appearance Control - ' + ControlType + ' - Display Title: ' + AppearanceTemplate.DisplayTitle)
        }
        var CleanValue = SourceObject.GetValue;
        if (CleanValue == 'null' || CleanValue == null) {
            CleanValue = '';
        } else {
            DataCleanValue = ' data-value="' + CleanValue + '" ';
        }

        if (AppearanceTemplate.Format) {
            Format = ' data-format="' + AppearanceTemplate.Format + '" ';           
        }
        var ControlStart, ControlEnd;

        if (ControlType == 2) {
            ControlStart = '<span' + Format + DataCleanValue + '>'
            ControlEnd = '</span>'
        }
        if (ControlType == 3) {
            if (AppearanceTemplate.Format) {
                Format = ' data-format="' + AppearanceTemplate.Format + '" ';
                CleanValue = CHSI.Common.String.Format(AppearanceTemplate.Format, CleanValue);
            }

            ControlStart = '<input type="text" '
            
            if (AppearanceTemplate.inEditMode == false) {
                ControlStart += ' disabled ';
            }

            else {
                ControlStart += ' ' + this.GetSaveEventCode(SourceObject, ObjectType, AppearanceTemplate) + ' ';
            }
            /* Generate ClientID */
            ControlStart += 'id="' + this.GetVarIDfromObject(SourceObject, ObjectType) + '" ';
            ControlStart += 'Class="Variable ' + AppearanceTemplate.Locked + '" ';
            ControlStart += Format + DataCleanValue;
            
            ControlStart += ' value="';
            ControlEnd = '" />'
        }
        if (ControlType == 4) {
            ControlStart = 'radiobutton'
            ControlEnd = '" '
        }
        if (ControlType == 5) {
            ControlStart = 'checkbox'
            ControlEnd = '" '
        }
        if (ControlType == 8) {
            ControlStart = '<select '
            if (AppearanceTemplate.inEditMode == false) {
                ControlStart += ' disabled ';
            }

            else {
                ControlStart += ' ' + this.GetSaveEventCode(SourceObject, ObjectType, AppearanceTemplate) + ' ';
            }            
            ControlStart += 'id="' + this.GetVarIDfromObject(SourceObject, ObjectType) + '" ';
            ControlStart += Format;
            ControlStart += '>'
            CleanValue = this.GenerateDropDownListFromSource(SourceObject, CleanValue);
            ControlEnd = '</select>'
        }
        if (ControlType == 10) {
            ControlStart = 'vertical radio'
            ControlEnd = '" '
        }
        if (ControlType == 11) {
            ControlStart = ' vertical checkbox'
            ControlEnd = '" '
        }
       
       
        
        return ControlStart + CleanValue + ControlEnd

    },

    GetSaveEventCode: function (SourceObject, ObjectType, AppearanceTemplate) {
        var HTML = [];
        //HTML.push('    onChange="CHSI.MuniUW.ShowSaveWindow(\'QuoteVariable\',0,this);" ');
        HTML.push(' Class="Variable ' + AppearanceTemplate.Locked + '" ');
        return HTML.join('');
    },

    GenerateDropDownListFromSource: function (SourceObject,CleanValue) {
        var HTML = [];
        for (var i = 0; i<SourceObject.FieldOptions.length; i++)
        {
            if (SourceObject.FieldOptions[i].Value == CleanValue) {
                HTML.push('<option value="' + SourceObject.FieldOptions[i].Value + '" selected="selected">' + SourceObject.FieldOptions[i].Key + '</option>');
            } else {
                HTML.push('<option value="' + SourceObject.FieldOptions[i].Value + '">' + SourceObject.FieldOptions[i].Key + '</option>');
            }
        }       
        return HTML.join('');
    },


    MenuController: function (QuoteObj, xTarget) {        
        var HTML = [];
        HTML.push('<a class="actionButton" href="/sig/underwriting/main.aspx?clientid=' + QuoteObj.ClientID + '&pagemode=3">Go To Client</a>');
        HTML.push('<a class="actionButton" onclick="CHSI.UW.MuniQuote.ShowNewNote(' + QuoteObj.ClientID + ')" href="#">New Note</a>');
        HTML.push('<a class="actionButton" onclick="CHSI.UW.MuniQuote.ShowNewTask(' + QuoteObj.ClientID + ')" href="#">New Task</a>');
        HTML.push('<a class="actionButton" onclick="CHSI.UW.MuniQuote.ChangeStatus(' + QuoteObj.PolicyPeriod + ', ' + QuoteObj.QuoteID +')" href="#">Change Status</a>');
        if (location.href.indexOf("Finish.aspx") != -1 && QuoteObj.QuoteStatus && QuoteObj.QuoteStatus.Bind == 'Y' && QuoteObj.QuoteStatus.Editable == 'Y') {
            HTML.push('<a class="actionButton" onclick="CHSI.UW.MuniQuote.ShowBindQuote(' + QuoteObj.ClientID + ', ' + QuoteObj.QuoteID + ')" href="#">Bind Quote</a>');
        }
        if (QuoteObj.QuoteStatus && QuoteObj.QuoteStatus.Editable == 'Y') {
            HTML.push('<a class="actionButton" onclick="CHSI.UW.MuniQuote.ShowRecalculateQuote(' + QuoteObj.QuoteID + ')" href="#">Recalculate Quote</a>');
        }

        if (QuoteObj.QuoteStatus && QuoteObj.QuoteStatus.QStatus.toLowerCase() == 'final') {
            HTML.push('<a class="actionButton" style="display:none;" onclick="CHSI.UW.MuniQuote.AddEndorsement();" href="#">Add Endorsement</a>');
        }        
        
        if (CHSI.UW.MuniQuote.Cache.QuoteList && CHSI.UW.MuniQuote.Cache.QuoteList.length > 1 && CHSI.UW.MuniQuote.Cache.QuoteObject.ParentQuoteID) {
            HTML.push('<a class="actionButton" style="" onclick="" href="/uw/Quote/QuoteInfo.aspx?QuoteID=' + QuoteObj.ParentQuoteID + '&ClientID=' + QuoteObj.ClientID + '&GroupName=' + QuoteObj.GroupID + '">Go to Quote</a>');           
        }

        HTML.push('<a class="actionButton" onclick="CHSI.UW.MuniQuote.ShowCopyQuote(' + QuoteObj.ClientID + ', ' + QuoteObj.QuoteID + ')" href="#">Copy Quote</a>');
        HTML.push("<a class='actionButton' onclick='CHSI.UW.MuniQuote.DeleteQuote(" + QuoteObj.QuoteID + ", " + '"' + QuoteObj.GroupID + '"' + ")' href='#'>Delete Quote</a>");

        $(xTarget).html(HTML.join(''));
    },

    RefreshPage: function () {
        //window.location.reload();
    },

    ShowNewNote: function (ClientID) {
        var NoteURL = '/utilities/actionitemedit.aspx?ClientID=' + ClientID;
        ShowPopup("NewNote", NoteURL, "New Note", 750, 530, null, false/*, SaveQuoteDetail*/);
    },

    ShowRecalculateQuote: function (QuoteID) {
        var Response = confirm('Please confirm that you would like to update this Quote.');

        if (Response) {
            CHSI.UW.MuniQuote.RecalculateQuote(QuoteID, CHSI.UW.MuniQuote.RefreshPage);
        }
    },
    ShowNewTask: function (ClientID) {
        var TaskURL = '/utilities/taskedit.aspx?ClientID=' + ClientID;
        ShowPopup("NewTask", TaskURL, "New Task", 870, 600, null, false);
    },

    ChangeStatus: function (PolicyYear, QuoteID) {
        var ChangeStatusURL = '/utilities/quotestatusedit.aspx?PolicyYear=' + PolicyYear + '&QuoteID=' + QuoteID;
        ShowPopup("ChangeStatus", ChangeStatusURL, "Change Status", 700, 490, null, false);
    },

    ShowCopyQuote: function (ClientID, QuoteID) {
        var CopyQuoteURL = '/UW/Modals/CopyQuote.aspx?ClientID=' + ClientID + '&QuoteID=' + QuoteID;
        ShowPopup("CopyQuote", CopyQuoteURL, "Copy Quote", 260, 200, null, false);
    },

    ShowBindQuote: function (ClientID, QuoteID) {

        var parentRefresh = null
        if (parent.RefreshPage) {
            parentRefresh = parent.RefreshPage;
        }

        var BindQuoteURL = '/UW/Modals/BindQuote.aspx?ClientID=' + ClientID + '&QuoteID=' + QuoteID;
        ShowPopup("BindQuote", BindQuoteURL, "Bind Quote", 270, 100, parentRefresh, true);
    },

    SaveOriginalValue: function (e) {
        this.Cache.CurrentControlValue = jQuery(e).val();
    },

    CacheValue: function () {
        this.Cache.CurrentControlCachedValue = this.Cache.CurrentControlValue;
    },

    GetAddCoverageLineSelectList: function () {
        var html = [];
        var availableCL = 0;
        html.push('<select id="addNewCoverageLine" name="addNewCoverageLine">');

        for (var newCL in this.Cache.QuoteObject.CoverageLineInfo) {

            var showCoverageLine = true;

            for (var CL in this.Cache.QuoteObject.CL) {
                //showCoverageLine = (PL != newPL);
                if (CL == newCL) {
                    showCoverageLine = false;
                }
            }

            if (showCoverageLine) {
                html.push('<option value="'
                    + this.Cache.QuoteObject.CoverageLineInfo[newCL].CoverageLineID + '">'
                    + this.Cache.QuoteObject.CoverageLineInfo[newCL].CoverageLineName + '</option>');

                availableCL++;
            }

        }

        if (availableCL > 0) {
            html.push('</select>');
            html.push('<input type="button" id="addPL" value="Add Coverage Line"/>');
        } else {
            html[0] = '<select id="addNewCoverageLine" name="addNewCoverageLine" disabled="disabled">'
                + '<option>No available coverage lines </option>'
                + '</select>';
        }

        return html.join('');

    },

    SanitizeTitle: function (value) {
        if (value == null || value == '') {
            return 'no formula';
        }
        else {
            return value;
        }
    },

    CalculateGrossPrem: function (Payroll, BaseRate) {
        var GrossPrem = 0;
        GrossPrem = CHSI.Math.SafeMultiply(Payroll, (CHSI.Math.SafeDivide(BaseRate, 100)));
        return GrossPrem;
    },

    DisablePage: function(Editable){
        if (Editable == "N") {
            CHSI.Common.Page.ReadOnly(true, true, true);
            $('#jxSaveNext').attr('value', 'Next');
            $('#jxSaveNext').attr('disabled', false);
            $('#jxCancel').attr('disabled', false);

        }
    },


    GetAppearanceControlProperties: function(ObjectType, keyID, SourceObject, DisplayFilter, ReadOnlyOverride) {
        var AppearanceCollection = CHSI.UW.MuniQuote.GetAppearanceCollection(ObjectType);
        var AppearanceObj = {
            Collapsable: 'Collapsable',
            Prop: null,
            Locked: '',
            ObjectType:null

        };
        var key = 'k' + keyID;
        for (var prop in AppearanceCollection) {
            if (prop == key) {
                var Display = true;
                if (DisplayFilter && AppearanceCollection[prop].DisplayMode < DisplayFilter) {
                    Display = false;
                }
                if (Display) {
                    var Collapsable = "Collapsable";
                    if (AppearanceCollection[prop].DisplayMode && AppearanceCollection[prop].DisplayMode == 2) {
                        Collapsable = "NonCollapsable";
                    }
                    var locked = '';
                    if (SourceObject && SourceObject.isLocked == true) {
                        //locked = '<img src="/images/icons/warning_red_16.png" alt="Modified by user" style="vertical-align:top; margin-top:5px;">';
                        locked = 'locked';
                    }
                    if (ReadOnlyOverride == 1) {
                        AppearanceCollection[prop].inEditMode = false;
                        locked = '';
                    }
                    AppearanceObj.Collapsable = Collapsable;
                    AppearanceObj.Prop = AppearanceCollection[prop];
                    if (AppearanceObj.Prop) {
                        AppearanceObj.Prop.Locked = locked;
                    }                    
                    AppearanceObj.Locked = locked;
                    AppearanceObj.Display = Display;
                    AppearanceObj.ObjectType = ObjectType;
                    break;
                }
            }
        }

        return AppearanceObj;
    },

    GetVariableDifference: function(Variable){      
        var DiffObj = {
            Var1: Variable,
            Var2: null,
            Difference: Variable,
            Type:'Quote'
        };

        if (CHSI.UW.MuniQuote.Cache.QuoteList && CHSI.UW.MuniQuote.Cache.QuoteList.length > 1)
        {
            if (CHSI.UW.MuniQuote.Cache.QuoteObject.ParentQuoteID) {
                DiffObj.Type = 'Endorsement';
            }
            CHSI.UW.MuniQuote.Cache.QuoteObject= CHSI.UW.MuniQuote.Cache.QuoteList[1];
            DiffObj.Var2 = CHSI.UW.MuniQuote.GetVariable(DiffObj.Var1.VariableID);
            DiffObj.Difference = CHSI.UW.MuniQuote.GetValueByDataType(DiffObj.Var1) - CHSI.UW.MuniQuote.GetValueByDataType(DiffObj.Var2);
            CHSI.UW.MuniQuote.Cache.QuoteObject = CHSI.UW.MuniQuote.Cache.QuoteList[0];
        }

        return DiffObj;
    }




}

/////////////////////////////////////////////////////////////////////
//                  was handlebars.js  extensions to handlebars
////////////////////////////////////////////////////////////////////
CHSI.UW.MuniQuote.Preparehelpers = function () {

    Handlebars.registerHelper('GetValueByDataType', function (DataObject) {
        return CHSI.UW.MuniQuote.GetValueByDataType(DataObject);
    });

    Handlebars.registerHelper('GetAppearanceTitle', function (ObjectType, keyID, DisplayFilter) {
        var AppearanceObj = CHSI.UW.MuniQuote.GetAppearanceControlProperties(ObjectType, keyID, null, DisplayFilter, 0);     
        ret = AppearanceObj.Prop==null?'':'<td class="VariableLabel ' + AppearanceObj.Collapsable + '">' + AppearanceObj.Prop.DisplayTitle + '</td>';
        return new Handlebars.SafeString(ret);
    });


    Handlebars.registerHelper('GetAddCoverageLineControl', function () {
        return new Handlebars.SafeString(CHSI.UW.MuniQuote.GetAddCoverageLineControl());
    });

    Handlebars.registerHelper('GetAddCoverageLineSelectList', function () {
        return new Handlebars.SafeString(CHSI.UW.MuniQuote.GetAddCoverageLineSelectList());
    });

    Handlebars.registerHelper('GetAppearanceDisplayValue', function (ObjectType, keyID, DisplayFilter) {
        var AppearanceObj = CHSI.UW.MuniQuote.GetAppearanceControlProperties(ObjectType, keyID, null, DisplayFilter, 0);
        ret = AppearanceObj.Prop == null ? '' : '<td class="VariableLabel ' + AppearanceObj.Collapsable + '">' + AppearanceObj.Prop.DisplayTitle + '</td>';
        return new Handlebars.SafeString(ret);
    });


    Handlebars.registerHelper('GetAppearanceControl', function (ObjectType, keyID, SourceObject, DisplayFilter, ReadOnlyOverride) {
        var AppearanceObj = CHSI.UW.MuniQuote.GetAppearanceControlProperties(ObjectType, keyID, SourceObject, DisplayFilter, ReadOnlyOverride);
        ret = AppearanceObj.Prop == null ? '' : '<td class="VariableValue ' + AppearanceObj.Collapsable + '">' + CHSI.UW.MuniQuote.GetControlType(AppearanceObj.Prop, SourceObject, ObjectType) + '</td>';
        return new Handlebars.SafeString(ret);
    });

    Handlebars.registerHelper('GetVariableSummary', function (ObjectType, keyID, SourceObject, DisplayFilter, ReadOnlyOverride) {
        var AppearanceObj = CHSI.UW.MuniQuote.GetAppearanceControlProperties(ObjectType, keyID, SourceObject, DisplayFilter, ReadOnlyOverride);
        ret = AppearanceObj.Prop == null ? '' : '<td class="VariableLabel ' + AppearanceObj.Collapsable + '">' + AppearanceObj.Prop.DisplayTitle + '</td>';
        ret += AppearanceObj.Prop == null ? '' : '<td class="VariableValue ' + AppearanceObj.Collapsable +'">' + CHSI.UW.MuniQuote.GetControlType(AppearanceObj.Prop, SourceObject, ObjectType) + '</td>';

        var DifObj = CHSI.UW.MuniQuote.GetVariableDifference(SourceObject);
        if (DifObj.Var2 != null) {
            var EditMode = AppearanceObj.Prop.inEditMode;
            var Var2 = DifObj.Var2.GetValue;
            AppearanceObj.Prop.inEditMode = false;
            ret += AppearanceObj.Prop == null ? '' : '<td class="VariableValue ' + AppearanceObj.Collapsable + '">' + CHSI.UW.MuniQuote.GetControlType(AppearanceObj.Prop, DifObj.Var2, ObjectType) + '</td>';
            CHSI.UW.MuniQuote.SetValue(DifObj.Var2, DifObj.Difference);
            ret += AppearanceObj.Prop == null ? '' : '<td class="VariableValue ' + AppearanceObj.Collapsable + '">' + CHSI.UW.MuniQuote.GetControlType(AppearanceObj.Prop, DifObj.Var2, ObjectType) + '</td>';
            CHSI.UW.MuniQuote.SetValue(DifObj.Var2, Var2);
            AppearanceObj.Prop.inEditMode = EditMode;
        }
        return new Handlebars.SafeString(ret);
    });

    Handlebars.registerHelper('GetVariableSummaryTitles', function (ObjectType, keyID, SourceObject, DisplayFilter, ReadOnlyOverride) {
        var ret = '';

        var DiffObj = CHSI.UW.MuniQuote.GetVariableDifference(SourceObject);
        if (DiffObj.Var2 != null) {
            var AppearanceObj = CHSI.UW.MuniQuote.GetAppearanceControlProperties(ObjectType, keyID, SourceObject, DisplayFilter, ReadOnlyOverride);
            ret += '<td class="VariableLabel ' + AppearanceObj.Collapsable + '">Variable</td>';
            if (DiffObj.Type != 'Quote') {
                ret += '<td class="VariableValue ' + AppearanceObj.Collapsable + '">Endorsement Value</td>';
                ret += '<td class="VariableValue ' + AppearanceObj.Collapsable + '">Original Value</td>';
            } else {
                ret += '<td class="VariableValue ' + AppearanceObj.Collapsable + '">Original Value</td>';
                ret += '<td class="VariableValue ' + AppearanceObj.Collapsable + '">Endorsement Value</td>';
            }
            
            ret += '<td class="VariableLabel ' + AppearanceObj.Collapsable + '">Difference</td>';
        }
        return new Handlebars.SafeString(ret);
    });


    Handlebars.registerHelper('GetQuotePropertyTextbox', function (PropertyName, ContentType) {

        return new Handlebars.SafeString(CHSI.UW.MuniQuote.GetQuotePropertyDisplay(PropertyName, ContentType));
    });


    Handlebars.registerHelper('GetFormulaControl', function (ObjectType, keyID, SourceObject) {
        var AppearanceCollection = CHSI.UW.MuniQuote.GetAppearanceCollection(ObjectType);
        var ret = "";
        var key = 'k' + keyID;
        var ControlOutput = '';
        var locked = '';
        var EditableControl = false;
        var EditMode = ' data-editmode="true" ';

        var AppearanceObj = CHSI.UW.MuniQuote.GetAppearanceControlProperties(ObjectType, keyID, SourceObject, 1, 0);

        for (var prop in AppearanceCollection) {
            if (prop == key) {
                CHSI.Common.Log('hello this is the key - ' + key + ' this should be showing a control - ' + SourceObject.VariableName + ' - ' + AppearanceCollection[prop].DisplayTitle);
                ControlOutput = CHSI.UW.MuniQuote.GetControlType(AppearanceObj.Prop, SourceObject, ObjectType);
                EditableControl = AppearanceCollection[prop].inEditMode;
                break;
            }
        }

        if (SourceObject.isLocked == true) {
            locked = 'style="border:1px solid orange;"';//'<img src="/images/icons/warning_red_16.png" alt="Modified by user" style="vertical-align:top; margin-top:5px;">';
        }

        if (EditableControl == false) {
            EditMode = ' data-editmode="false" ';
            if (SourceObject.GetValue) {
                ControlOutput = SourceObject.GetValue.toString().replace('-214748364', '');
            } else {
                ControlOutput = '';
            }
            ControlOutput = '<span class="FakeTextBox" ' + locked + '>' + ControlOutput + '</span>';
        }
       

        

        var id = CHSI.UW.MuniQuote.GetVarIDfromObject(SourceObject, ObjectType);
        var infopanel = '<div class="InformationPanel" data-variablename="' + SourceObject.VariableName + '" id="InformationBar_' + id + '" data-title="' + CHSI.UW.MuniQuote.SanitizeTitle(SourceObject.Formula) + ' for ' + ObjectType + '" data-historyid="' + id + '" data-keyid="' + keyID + '" data-quoteid="' + SourceObject.QuoteID + '"' + EditMode + '></div>'

        ret = '<td class="VariableTitle" id="NotificationID_' + id + '" data-notificationid="' + id + '">' + ControlOutput + infopanel + '</td>';

        return new Handlebars.SafeString(ret);
    });

    Handlebars.registerHelper('CalculateGrossPrem', function (Payroll, BaseRate) {
        var GrossPrem = 0;
        GrossPrem = CHSI.UW.MuniQuote.CalculateGrossPrem(Payroll, BaseRate);
        return new Handlebars.SafeString(GrossPrem);
    });

    Handlebars.registerHelper('RoundTotalCommission', function (CommissionAmount) {
        CommissionAmount = CHSI.Math.SafeMultiply(CommissionAmount, 100.0);
        CommissionAmount = CHSI.Math.SafeDivide(CommissionAmount, 100.0);
        return new Handlebars.SafeString(CommissionAmount);
    });
}
