/* Basic Advanced Search */
CHSI.AdvancedSearch = new Object();
CHSI.AdvancedSearch.SearchAjax = null;
var as_SelectedSearchID = 0;
var CurrentSearchCategory = '';
var as_SearchType = 1;
var as_pageindex = 0;
var as_numrecords = 25;
var ResponseFunction = null;
CHSI.AdvancedSearch.OrderText = '';
CHSI.AdvancedSearch.ResizeSearchContents = function () {
    var AdvancedSearchContents = jQuery('#AdvancedSearchContents');
    var MinX = 500;
    var MinY = 400;
    var Padding = 100;
    var windowWidth = Math.max(jQuery(window).width() - Padding, MinX);
    var windowHeight = Math.max(jQuery(window).height() - Padding, MinY);
    AdvancedSearchContents.height(windowHeight - 50);
    AdvancedSearchContents.width(windowWidth - 20);
    var SubWindowWidth = AdvancedSearchContents.width() - 40;
    var SubWindowHeight = AdvancedSearchContents.height() - 90;
    var AIContent = jQuery('#AIContent');
    AIContent.width(SubWindowWidth);
    AIContent.height(SubWindowHeight);
}
CHSI.AdvancedSearch.ReSort = function (SortColumn) {
    var SortParam = '[' + SortColumn + ']';
    if (CHSI.AdvancedSearch.OrderText == SortParam) {
        CHSI.AdvancedSearch.OrderText = SortParam + ' desc';
    }
    else {
        CHSI.AdvancedSearch.OrderText = SortParam;
    }
    as_pageindex = 0;
    var SearchTerm = jQuery('#jxAdvancedCustomerSearch').val();
    CHSI.Common.Log('searching -- ' + SearchTerm + ', Type: ' + as_SearchType + ',Index: ' + as_pageindex + ', Records: ' + as_numrecords + ', SortBy: ' + CHSI.AdvancedSearch.OrderText);
    if (CHSI.AdvancedSearch.SearchAjax) {
        CHSI.Common.Log('Aborting Call' - CHSI.AdvancedSearch.SearchAjax);
        CHSI.AdvancedSearch.SearchAjax.abort();       
    }
    var ajaxData = new Object();
    ajaxData.searchid = as_SelectedSearchID;
    ajaxData.searchterm = SearchTerm;
    ajaxData.searchtype = as_SearchType;
    ajaxData.pageindex = as_pageindex;
    ajaxData.numrecords = as_numrecords;
    ajaxData.OrderBy = CHSI.AdvancedSearch.OrderText;
    var ajaxString = JSON.stringify(ajaxData);

    CHSI.AdvancedSearch.SearchAjax = jQuery.ajax({
            type: "POST", url: "/webservice/ajaxfunctions.asmx/GetSearchResults",
            data: ajaxString,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (e) {
                AdvancedSearch_Result(e.d);
            },  
            error: function (e) {
                if (e.statusText != 'abort') {
                    alert('An error from the webservice - ' + e.statusText);
                }
            }
            });

    /*CHSI.AdvancedSearch.SearchAjax = ajaxfunctions.GetSearchResults(as_SelectedSearchID, SearchTerm, as_SearchType, as_pageindex, as_numrecords, CHSI.AdvancedSearch.OrderText, AdvancedSearch_Result, CHSI_ErrorResult);*/

}



function ShowAdvancedSearchForm(categoryname, SearchID, responseFunction) {
    if (typeof SearchID == "undefined" || SearchID == null) { 
        SearchID = 0;
    }
    as_SelectedSearchID = SearchID;
    CurrentSearchCategory = categoryname;

    ResponseFunction = (typeof responseFunction == 'undefined' ? null : responseFunction);

    var result = [];
    
    var MinX = 500;
    var MinY = 400;
    var Padding = 100;
    var windowWidth = Math.max( jQuery(window).width() - Padding, MinX);
    var windowHeight = Math.max(jQuery(window).height() - Padding, MinY);
    result.push('<div id="AdvancedSearchContents" style="height: ' + (windowHeight - 80) + 'px; width: ' + (windowWidth - 20) + 'px;"></div><div id="AdvancedSearchToolbar"><div class="buttonPanel" style=" margin-right: 2px; padding-top:3px;"><div style="float: left;"><input type="button" class="btn btn-primary" onclick="AddNewCompany()" value="Add New Company" style="width:190px;"/></div><div align="right"><input type="button" class="button" onclick="javascript: parent.CHSI.DOM.Modal.CloseModal(\'aSearchForm\');" value="Cancel"/></div></div></div>');
    ShowPagePopup(result.join(""), 'Advanced Search', 0, 0, 'aSearchForm',true);

    ajaxfunctions.GetSearchQueries(categoryname, GetQueryOption_Result, CHSI_ErrorResult);

    jQuery(window).bind('resize', function () {
        if (jQuery('aSearchForm') != null) {
            /* resize the modal*/
            CHSI.AdvancedSearch.ResizeSearchContents();
            //alert($('aSearchForm').width);
        }

    });
}
 
 
 

function GetQueryOption_Result(result) {
    var AdvancedSearchContents = jQuery('#AdvancedSearchContents');
    var html = [];
    html.push('<div class="uxMobilePopup"><div class="topTabs" id="MainTabStrip"><ul class="obtabs" style="min-width:650px;">');
    
    jQuery.each(result, function (index, value) {
        var TabClass = '';
        if (value.SearchTypeID == as_SelectedSearchID || as_SelectedSearchID == 0) {
            TabClass = 'current';
            as_SelectedSearchID = value.SearchTypeID;
        }
        html.push('<li class="');
        html.push(TabClass);
        html.push('"><span >');
        html.push('<a  style="width:90px; display:block;" href="javascript: ShowAdvancedSearchTab(');
        html.push(value.SearchTypeID);
        html.push(');">');
        html.push(value.SearchTitle);
        html.push('</a>');
        html.push('</span></li>');
    });
    


    html.push('</ul></div><div  class="wrap4" id="MainTabContent" height="95%" >');
    html.push('<table width="100%" ><tr><td style="vertical-align: top;" class="left"><strong>Search Term:</strong>&nbsp;&nbsp;&nbsp;&nbsp; <input type="text" class="txtBox" style="width:80%" id="jxAdvancedCustomerSearch" onkeyup="javascript: AdvancedSearch();"/></td></tr>');
 

    html.push('<tr><td colspan="2"><div id="jxAdvancedSearchResults"></div></td></tr></table>');
 
    html.push('</div></div>');
    
    AdvancedSearchContents.html(html.join(''));
}
 

function AdvancedSearch() {
    var SearchTerm = jQuery('#jxAdvancedCustomerSearch').val();
    as_pageindex = 0;
    CHSI.Common.Log('Searching ' + CHSI.AdvancedSearch.OrderText);
    if (CHSI.AdvancedSearch.SearchAjax) {
        CHSI.Common.Log('Aborting Call' - CHSI.AdvancedSearch.SearchAjax);
        CHSI.AdvancedSearch.SearchAjax.abort();
    }
    if (SearchTerm.length < 3)
    {
        return false;
    }
       var ajaxData = new Object();
    ajaxData.searchid = as_SelectedSearchID;
    ajaxData.searchterm = SearchTerm;
    ajaxData.searchtype = as_SearchType;
    ajaxData.pageindex = as_pageindex;
    ajaxData.numrecords = as_numrecords;
    ajaxData.OrderBy = CHSI.AdvancedSearch.OrderText;
    var ajaxString = JSON.stringify(ajaxData);

    CHSI.AdvancedSearch.SearchAjax = jQuery.ajax({
        type: "POST", url: "/webservice/ajaxfunctions.asmx/GetSearchResults",
        data: ajaxString,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (e) {
            AdvancedSearch_Result(e.d);
        },
        error: function (e) {
            if (e.statusText != 'abort') {
                alert('An error from the webservice - ' + e.statusText);
            }
        }
    });
}

function GetPage(pageincrement) {
    var SearchTerm = jQuery('#jxAdvancedCustomerSearch').val();
    as_pageindex += pageincrement;
    CHSI.Common.Log('Searching ' + CHSI.AdvancedSearch.OrderText);
    if (CHSI.AdvancedSearch.SearchAjax) {
        CHSI.Common.Log('Aborting Call' - CHSI.AdvancedSearch.SearchAjax);
        CHSI.AdvancedSearch.SearchAjax.abort();
    }
       var ajaxData = new Object();
    ajaxData.searchid = as_SelectedSearchID;
    ajaxData.searchterm = SearchTerm;
    ajaxData.searchtype = as_SearchType;
    ajaxData.pageindex = as_pageindex;
    ajaxData.numrecords = as_numrecords;
    ajaxData.OrderBy = CHSI.AdvancedSearch.OrderText;
    var ajaxString = JSON.stringify(ajaxData);

    CHSI.AdvancedSearch.SearchAjax = jQuery.ajax({
            type: "POST", url: "/webservice/ajaxfunctions.asmx/GetSearchResults",
            data: ajaxString,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (e) {
                AdvancedSearch_Result(e.d);
            },  
            error: function (e) {
                if (e.statusText != 'abort') {
                    alert('An error from the webservice - ' + e.statusText);
                }
            }
            }); 
}

function AdvancedSearch_Result(result)
{
    var html = [];
    var jxAdvancedSearchResults = jQuery('#jxAdvancedSearchResults');
    var Cols = [];
    var MorePages = false;
    var MinX = 500;
    var MinY = 400;
    
    var AdvancedSearchContents = jQuery('#AdvancedSearchContents');
    var windowWidth = AdvancedSearchContents.width() -40 ;
    var windowHeight = AdvancedSearchContents.height() - 120;

    html.push('<div class="uxMobileReturnPanel" id="AIContent" style="overflow: auto;  height:' + windowHeight + 'px; width:' + windowWidth + 'px;"><table cellspacing="0" cellpadding="2"  class="dtable sortable" style="table-layout:fixed;" width="98%" align="center"><thead><tr>');
    jQuery.each(result.Headers, function (index, value) {
        if (value.length > 0) {
            if (value.substr(0, 1) != "_" && value != "rowid") {
                Cols.push(index);
                html.push('<th class="sorttable_nosort" style="white-space:nowrap;"><a href="javascript: CHSI.AdvancedSearch.ReSort(' + "'" + value + "'" + ')">');
                html.push(value);
                html.push('</a></th>');
            }
        }

    });
    html.push('</tr></thead><tbody>');

    jQuery.each(result.Data, function (objindex, objdata) {
        if (objindex < as_numrecords) {

            html.push('<tr');
            html.push(' style="cursor:pointer;" onclick="');
            if (ResponseFunction != null) {
                html.push('javascript: ');
                html.push(ResponseFunction);
                html.push('(' + objdata.Data[0] + ')');
            }
            else {
                
                if (objdata.NavigateURL.slice(0, 4) != "java") {
                    html.push('javascript: location.href=\'');
                    html.push(objdata.NavigateURL);
                    html.push('\'');
                }
                else { html.push(objdata.NavigateURL); }
           
            }


            html.push('">');
            jQuery.each(objdata.Data, function (index, columnvalue) {
                if (jQuery.inArray(index, Cols) != -1) {
                    html.push('<td style="white-space:nowrap; overflow: hidden; ">');
                    html.push(columnvalue);
                    html.push('</td>');
                }

            });
            html.push('</tr>');

        }
        else {
            MorePages = true;
        }

    });

    html.push('</tbody></table></div>');
    html.push('<div class="resultActions">');
    
    if (as_pageindex > 0) {
        html.push('<a class="btn btn-primary" href=\'javascript: GetPage(-1);\' class=\'minilink\'><< Previous Records</a>&nbsp;');
    } else {
    	html.push('<span style="color:gray; text-decoration:none;" class=\'btn btn-primary\'><< Previous Records</span>&nbsp;');
    	//html.push('<a href=\'#\' style="color:gray; text-decoration:none;"  class=\'btn btn-primary\'><< Previous Records</a>&nbsp;');
    }
    if (MorePages) { 
        html.push('&nbsp;<a href=\'javascript: GetPage(1);\' class=\'btn btn-primary\'>More Records >></a>');
    }
    html.push('</div>');
    
    jxAdvancedSearchResults.html(html.join(''));
    restart = true;
    sorttable.init();

}

function AddNewCompany() { 
if (confirm('Have you searched to see if this company already exists? Don\'t be too specific with your search, as abbreviation and punctuation can cause you to create a potentially duplicate record')) {
    location.href = '/cm/clientedit.aspx?clientid=0';
 }
}
