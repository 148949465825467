CHSI.Help = {
    Settings: {
        ParentElement: null
        ,ParentContainer: null
    }
    , Ajax: {
        HelpLookup: null
    }
    ,
    ShowHelp: function (HelpID, parentElement) {
        CHSI.Help.Settings.ParentElement = parentElement;
        var HelpObject = this.GetHelpInformation(HelpID);
    },
    GenerateContainer: function (HelpObject, Class) {

        var elem = CHSI.Help.Settings.ParentElement;


        corners = ['left center', 'right center'],
        flipIt = elem.parents('span.right').length > 0;
        var my = corners[flipIt ? 0 : 1];
        var at = corners[flipIt ? 1 : 0];
        my = 'top center';
        at = 'bottom center';
        var HelpMessage = HelpObject.HelpText;
        var TagClass = 'qtip-light';

      
        if (elem.qtip) {
            elem.qtip('destroy');
        }
       
        elem.qtip({
            overwrite: false,
            content: HelpMessage,
            position: {
                my: my,
                at: at,
                viewport: jQuery(window),
                container: jQuery('#FormRegion2')
            },

            show: {
                event: false,
                ready: true
            },
            title: {text: 'Help', button: 'Close'}
          , style: {
              classes: TagClass + ' elementHelp'
                , height: CHSI.Help.GetHeight(HelpObject)
                , width: CHSI.Help.GetWidth(HelpObject)
                 
          }

        });
 
      
              
        
    },
    GetHeight: function (HelpObject) {        
        var Height;
        if (HelpObject.OverrideHeight == null) {
            Height= 50;
        }
        Height = HelpObject.OverrideHeight;
        return Height;
    },
    GetWidth: function (HelpObject) {
        var Width = 100;
        if (HelpObject.OverrideWidth == null) {
            Width = 100;
        }
        Width = HelpObject.OverrideWidth;
        return Width;
    },
    GetHelpInformation: function (HelpID) {
        var AjaxURL = "/api/Que/ElementHelp/GetHelpInformation?HelpID=" + HelpID;
        if (this.Ajax.HelpLookup)
        {
            this.Ajax.HelpLookup.abort();
        }
        this.Ajax.HelpLookup =  $.ajax({
            type: "GET"
        , url: AjaxURL
        , cache: false
        , contentType: "application/json; charset=utf-8"
        , dataType: "json"
        , success: function (HelpObject) {
            CHSI.Help.GenerateContainer(HelpObject);
        },
        error: function (e) {
          
        }
        });
    }
        
};