// goal: image viewer library to work with lazy loaded imgs (at least support bLazy)
// xtra func: prev/next navigation, img meta-data & controls (edit meta, delete img, etc)
// adheres to the Declarative Module Pattern
var LazyViewer = (function () {

    // var privateProp = true;
    // var privateFunc = function(){}

    var _private = {
        vContainer: null,
        vToolbar: null,
        vCloseButton: null,
        vDeleteButton: null,
        vOverlay: null,
        vImgContainer: null,
        vImg: null,
        vPrev: null,
        vNext: null,
    };

    var _public = {
        CurrentImage: null,
        ViewImage: function (imgContainer) {
            _public.CurrentImage = imgContainer;
            // need to replace method of getting image source...
            var img = $(imgContainer).children('img').first();
            var src = $(img).hasClass('b-loaded') ? $(img).attr('src') : $(img).data('src');
            // create img view
            _private.vImgContainer = document.createElement('div');
            _private.vImgContainer.className = 'v-img-container';
            _private.vContainer.appendChild(_private.vImgContainer);

            _private.vImg = document.createElement('img');
            _private.vImg.className = 'v-img';
            _private.vImg.src = src;
            _private.vImg.onload = function () {
                if ($('.v-img').height() > $('.v-container').height()) {
                    $('.v-img').height($('.v-container').height() - 50);
                }
                _private.vImgContainer.style.marginLeft = '-' + (_private.vImgContainer.clientWidth / 2) + 'px';
                _private.vImgContainer.style.marginTop = '-' + (_private.vImgContainer.clientHeight / 2) + 'px';
                $('.v-img-container, .v-img').animate({ opacity: 1 }, 200);
            };

            _private.vPrev.onclick = (function (e) {
                var prev = $(imgContainer).prev();
                if (prev.length) {
                    e.stopPropagation();
                    $('.v-img-container').fadeOut(200, function () {
                        _private.vImgContainer.remove();
                        _public.ViewImage(prev);
                    });
                }
            });
            _private.vNext.onclick = (function (e) {
                var next = $(imgContainer).next();
                if (next.length) {
                    e.stopPropagation();
                    $('.v-img-container').fadeOut(200, function () {
                        _private.vImgContainer.remove();
                        _public.ViewImage(next);
                    });

                }
            });
            _private.vImgContainer.appendChild(_private.vImg);
            //$('.v-img-container, .v-img').fadeIn();
        },

        CloseViewer: function () {
            $('.v-container *').fadeOut(function () {
                _private.vContainer.remove();
            });
        },

        DeleteImage: function () {
            var src = _private.vImg.src;
            var fileID = WebUtilities.ParameterHelpers.getParameterByName(src, 'fileid')
            if (!fileID > 0)
                alert('Delete image error - could not get file ID.');

            var df = $.Deferred();
            $.ajax({
                type: "POST",
                url: "/api/ClaimSystem/ClaimManagement/ClaimImages/DeleteClaimImage?claimID=" + CHSI.Page.ClaimImages.ClaimID + "&fileID=" + fileID,
                success: function (success) { // config = SearchController.AdvancedSearchConfig obj
                    if (success) {
                        // close viewer
                        _public.CloseViewer();
                        // remove image from view
                        $(_public.CurrentImage).fadeOut(400, function () {
                            _public.CurrentImage.remove();
                        })
                    }


                    df.resolve();
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    if (jqXHR.readyState == 4) // http error
                        alert("(╯°□°）╯︵ ┻━┻  Error: " + textStatus + "\r ... looks like we had trouble deleting your image.\r\r If this problem persists, please contact our support team at: support@chsitech.com");
                    else if (jqXHR.readyState == 0) // network error
                        alert("(っ˘̩╭╮˘̩)っ  Error: Network error\rCheck your internet connection!");
                    else // other error
                        alert("(╯°□°）╯︵ ┻━┻  Error: Application error\r ... looks like we had trouble deleting your image.\r\r If this problem persists, please contact our support team at: support@chsitech.com");
                    df.reject();
                }
            });

            return df;
        },

        CreateToolbar: function () {
            _private.vToolbar = document.createElement('div');
            _private.vToolbar.className = 'v-toolbar';

            // create close button
            _private.vCloseButton = document.createElement('div');
            _private.vCloseButton.className = 'v-toolbar-btn v-close-btn';
            _private.vCloseButton.innerHTML = "&#x2573;";
            _private.vToolbar.appendChild(_private.vCloseButton);

            // create delete button
            _private.vDeleteButton = document.createElement('div');
            _private.vDeleteButton.className = 'v-toolbar-btn v-delete-btn';
            _private.vDeleteButton.innerHTML = "&#x1F5D1;";
            _private.vToolbar.appendChild(_private.vDeleteButton);

            _private.vContainer.appendChild(_private.vToolbar);
            _private.vCloseButton.onclick = function () {
                _public.CloseViewer();
            }
            _private.vDeleteButton.onclick = function () {
                _public.DeleteImage();
            }
        },

        CreateOverlay: function () {
            // create overlay
            _private.vOverlay = document.createElement('div');
            _private.vOverlay.className = 'v-overlay';
            _private.vContainer.appendChild(_private.vOverlay);
            _private.vOverlay.onclick = function () {
                $('.v-container *').fadeOut(function () {
                    _private.vContainer.remove();
                });
            };

            // create left and right nav bars
            _private.vPrev = document.createElement('div');
            _private.vPrev.className = 'v-nav v-prev';
            _private.vPrev.innerHTML = "&#8249;";
            _private.vPrev.style.lineHeight = document.body.clientHeight + 'px';


            _private.vContainer.appendChild(_private.vPrev);
            _private.vNext = document.createElement('div');
            _private.vNext.className = 'v-nav v-next';
            _private.vNext.innerHTML = "&#8250;";
            _private.vNext.style.lineHeight = document.body.clientHeight + 'px';

            _private.vContainer.appendChild(_private.vNext);
        },

        View: function (imgContainer) {
            // create container
            _private.vContainer = document.createElement('div');
            _private.vContainer.className = 'v-container';
            document.body.appendChild(_private.vContainer);

            // create overlay w/ back and next buttons
            _public.CreateOverlay();
            _public.CreateToolbar();

            $('.v-overlay').fadeIn(200, function () {
                $('.v-nav').animate({ opacity: .9 }, 200);
                // create img view
                _public.ViewImage(imgContainer);
            });
        },

        ResizeDetailViewer: function () {
            $('.v-container').height(window.innerHeight);

            _private.vImgContainer.style.marginLeft = '-' + (_private.vImgContainer.clientWidth / 2) + 'px';
            _private.vImgContainer.style.marginTop = '-' + (_private.vImgContainer.clientHeight / 2) + 'px';
        }
    };

    return _public;
});