CHSI.Common.Validation = new Object();
CHSI.Common.Validation.UseQTip = true;
CHSI.Common.Validation.ValidateCurrencyPress = function (e)
{
    var Code = (String.fromCharCode(e.keyCode));
    var isValid = false;
    if (CHSI.Common.Numbers.IsCurrencyNumber(String.fromCharCode(e.keyCode)) || String.fromCharCode(e.keyCode) == '.')
    {
        isValid = true;
    };
    return isValid;
};

CHSI.Common.Validation.ValidateNumberPress = function (e) {
    var Code = (String.fromCharCode(e.keyCode));
    var isValid = false;
    if (CHSI.Common.Numbers.IsNumber(String.fromCharCode(e.keyCode)) ) {
        isValid = true;
    };
    return isValid;
};

CHSI.Common.Validation.isValidDate = function (value) {
    return !isNaN(Date.parse(value));
};

jQuery.validator.setDefaults({
    ignoreTitle: true,
    success: jQuery.noop,
    errorClass: 'error',
    validClass: 'valid',
    errorPlacement: function (error, element) {
    	if (CHSI.Common.Validation.useQTip) {
    		var elem = jQuery(element),
				corners = ['left center', 'right center'],
				flipIt = elem.parents('span.right').length > 0;

    		/* check to see if we have a valid error message*/
    		if (!error.is(':empty')) {
    			/* apply the tooltip only if it isn't valid*/
    			elem.filter(':not(.valid)').qtip({
    				overwrite: false,
    				content: error,
    				position: {
    					my: corners[flipIt ? 0 : 1],
    					at: corners[flipIt ? 1 : 0],
    					viewport: jQuery(window)
    				},
    				show: {
    					event: false,
    					ready: true
    				},
    				hide: false,
    				style: {
    					classes: 'ui-tooltip-red'
    				}

    			});
    			elem.qtip('option', 'content.text', error);
    		}
    		else {
    			elem.qtip('destroy');
    		}
    	}
    }
});
CHSI.Common.Validation.PrepareValidation = function () {
    
    jQuery.validator.addMethod("phone", function (value, element) {
        return !jQuery.validator.methods.required.call(this, value, element) || /^(\+\d)*\s*(\(\d{3}\)\s*)*\d{3}(-{0,1}|\s{0,1})\d{2}(-{0,1}|\s{0,1})\d{2}$/.test(value);
    }, "Must be (XXX) XXX-XXXX");
    jQuery.validator.addMethod("isInteger", function (value, element) {
        if (value == '') {
            return true;
        }
        if ((parseFloat(value) == parseInt(value)) && !isNaN(value)) {
            return true;
        }
        else {
            return false;
        }

    }, "Must be a whole number");

    jQuery.validator.addMethod("fourdigit", function (value, element) {
        if (value == '') {
            return true;
        }
        if ((parseFloat(value) == parseInt(value)) && value.length == 4 && !isNaN(value)) {
            return true;
        }
        else {
            return false;
        }
    }, "Must be a 4 digit number");

    jQuery.validator.addMethod("currency", function (value, element) {
        return !jQuery.validator.methods.required.call(this, value, element) || /^\$?\-?([1-9]{1}[0-9]{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\-?\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\(\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))\)$/.test(value);
    }, "Must be a valid currency amount");
    jQuery.validator.addMethod('isdecimal', function (value, element) {
        return this.optional(element) || /^\d+(\.\d{0,5})?$/.test(value);
    }, "Please enter a correct number, format xxxx.xxx");

    jQuery.validator.addMethod("year", function (value, element) {
        if (value == '') {
            return true;
        }
        if ((parseFloat(value) == parseInt(value)) && value.length == 4 && !isNaN(value) && (value >= 1500 && value <= 2999)) {
            return true;
        }
        else {
            return false;
        }
    }, "Must be a valid 4 digit year");

    jQuery.validator.addMethod('isSSN', function (value, element) {
        //Checks if there are 9 numbers in field - allows for any display mask
        return this.optional(element) || (value.match(/\d/g) || []).length == 9;
    }, "Please enter a 9 digit SSN");

    jQuery.validator.addMethod('isDate', function (value, element) {
        return this.optional(element) || CHSI.Common.Validation.isValidDate(value);
    }, "Please enter valid date");

    jQuery.validator.addMethod('isPriorDate', function (value, element) {
        var isValid = false;
        if (CHSI.Common.Validation.isValidDate(value)) {
            isValid = Date.parse(value) < new Date();
        }
        return this.optional(element) || isValid;
    }, "Date must be today or earlier");

    jQuery.validator.addMethod('isTime', function (value, element) {
        var isValid = false;
        //Strip input to just numbers, in case of masking
        var time = value.replace(/\D/g, '');
        if (time.length == 4) {
            isValid = (time.substring(0, 2) <= 23 && time.substring(2) <= 59);
        }
        return this.optional(element) || isValid;
    }, "Please enter a valid time in the format HH:MM");

    jQuery.validator.addClassRules({
        iLongText: {

        },
        iShortText: {},
        iPostalCode: {
            minlength: 5,
            maxlength: 5,
            digits: true
        },
        iSSN: {
            isSSN: true
        },
        iInteger: {
            digits: true,
                isInteger: true
        },
        iDecimal: {
            isdecimal: true
        },
        iDate: {
            isDate: true
        },
        iPriorDate: {
            isPriorDate: true
        },
        iTime: {
            isTime: true
        },
        iPhoneNumber: { phone: true },
        iPostalCodeSuffix: { minlength: 4, maxlength: 4, digits: true },
        iEmailAddress: { email: true },
        iRequired: { required: true },
        iYear: { year: true },
        iCurrency: { currency: true },
        FourDigitNumber: { fourdigit: true }
    });
    jQuery.extend(jQuery.validator.messages, {
        required: "This field is required.",
        remote: "Please fix this field.",
        email: "Please enter a valid email address.",
        url: "Please enter a valid URL.",
        date: "Please enter a valid date.",
        dateISO: "Please enter a valid date (ISO).",
        number: "Please enter a valid number.",
        digits: "Please enter only digits.",
        creditcard: "Please enter a valid credit card number.",
        equalTo: "Please enter the same value again.",
        accept: "Please enter a value with a valid extension.",
        maxlength: jQuery.validator.format("Please enter no more than {0} characters."),
        minlength: jQuery.validator.format("Please enter at least {0} characters."),
        rangelength: jQuery.validator.format("Please enter a value between {0} and {1} characters long."),
        range: jQuery.validator.format("Please enter a value between {0} and {1}."),
        max: jQuery.validator.format("Please enter a value less than or equal to {0}."),
        min: jQuery.validator.format("Please enter a value greater than or equal to {0}."),
        isInteger: "Please enter a whole number.",
        phone: "Please enter a valid phone number in (XXX)XXX-XXXX format",
        iYear: "Please enter a valid 4-digit year",
        iCurrency: "Please enter a valid currency amount",
        FourDigitNumber: "Please enter a 4 digit number"
    });
};
