module PL.ClaimSystem.ClaimManagement {
    export class AttachmentModel {
        constructor() { };

        public ClaimID: number;
        public AttachmentID: number;
        public FileID: number;
        public FileName: string;
        public Description: string;
        public ItemDate: any;
        public CreateUser: string;

        public Update = () => {
            var df = $.Deferred();
            $.ajax({
                type: "POST",
                url: "/api/ClaimSystem/ClaimManagement/ClaimAttachments/UpdateClaimAttachment",
                data: JSON.stringify(this),
                contentType: "application/json",
                success: () => {
                    df.resolve();
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    WebUtilities.genericAJAXErrorHandler(jqXHR, textStatus, errorThrown);
                    df.reject();
                }
            });

            return df;
        };

        public Delete = () => {
            var df = $.Deferred();
            $.ajax({
                type: "POST",
                url: "/api/ClaimSystem/ClaimManagement/ClaimAttachments/DeleteClaimAttachment",
                data: JSON.stringify(this),
                contentType: "application/json",
                success: () => {
                    df.resolve();
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    WebUtilities.genericAJAXErrorHandler(jqXHR, textStatus, errorThrown);
                    df.reject();
                }
            });

            return df;
        };
    }

}