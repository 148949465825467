CHSI.UW.ScheduleTypes.Controllers.ScheduleTypesController = {
    TargetElement: null,
    TargetInactiveFilterElement: null,
    TargetGroupNameFilterElement: null,

    Templates: {},

    Cache: {
        GroupObject: null,
        GroupName: '',

        GridObject: null,
        GridTable: null
    },

    Init: function () {
        var Template_Groups = $('#Template_Groups').html();
        this.Templates.GroupObject = Handlebars.compile(Template_Groups);

        this.GetGroups();
        this.GetScheduleTypes();
        this.SetupGlobalEvents();
    },

    GetGroups: function(){
        CHSI.UW.ScheduleTypes.Models.ScheduleTypes.GetGroups(function (result) {
            CHSI.UW.ScheduleTypes.Controllers.ScheduleTypesController.Cache.GroupObject = result;
            CHSI.UW.ScheduleTypes.Controllers.ScheduleTypesController.BindGroupPresentationLayer();
        });
    },

    GetScheduleTypes: function(){
        var showInactive = this.TargetInactiveFilterElement.is(':checked');
        var groupName = this.Cache.GroupName;

        CHSI.UW.ScheduleTypes.Models.ScheduleTypes.GetScheduleTypes(showInactive, groupName, function (result) {
            CHSI.UW.ScheduleTypes.Controllers.ScheduleTypesController.Cache.GridObject = result;
            CHSI.UW.ScheduleTypes.Controllers.ScheduleTypesController.BindPresentationLayer();
        });
    },

    BindGroupPresentationLayer: function () {
        var GroupOptions = { Groups: this.Cache.GroupObject };
        $('#jxGroupsContainer').html(this.Templates.GroupObject(GroupOptions));
    },

    BindPresentationLayer: function(){
        this.TargetElement.html('<table id="jxScheduleTypesGrid" width="100%" class="dtable"><tfoot></tfoor></table>');
        
        var DataSet = null;
        var Columns;

        if (CHSI.UW.ScheduleTypes.Controllers.ScheduleTypesController.Cache.GridObject) {
            DataSet = CHSI.UW.ScheduleTypes.Controllers.ScheduleTypesController.Cache.GridObject.Values;
            Columns = CHSI.UW.ScheduleTypes.Controllers.ScheduleTypesController.Cache.GridObject.Columns;
        }

        Columns.push({
            title: '',
            orderable: false,
            width: '10%',
            render: function (data, type, row) {
                var html = '<a href="#" onClick="ShowScheduleTypeEdit(' + row[0] + ');" class="jxViewEdit">[view/edit]</a>';

                return html;
            }
        });

        if (DataSet != null) {
            CHSI.UW.ScheduleTypes.Controllers.ScheduleTypesController.Cache.GridTable = $('#jxScheduleTypesGrid').DataTable({
                data: DataSet,
                columns: Columns,
                lengthMenu: [ [10], [25], [50] ],
                stateSave: true
            });
        } else {
            var HTML = [];
            $('#jxScheduleTypesGrid').html('<tr><td>No results found.</td></tr>');
        }

        $('#jxLoading').hide();
    },

    SetupGlobalEvents: function () {
        $('#jxAddNew').on('click', function () {
            ShowScheduleTypeEdit(0);
        });

        this.TargetElement.on('click', '.jxViewEdit', function () {
            var Button = $(this);
        });

        this.TargetInactiveFilterElement.on('click', function () {
            CHSI.UW.ScheduleTypes.Controllers.ScheduleTypesController.GetScheduleTypes();
        });

        this.TargetGroupNameFilterElement.on('change', '#jxGroupFilter', function () {
            CHSI.UW.ScheduleTypes.Controllers.ScheduleTypesController.Cache.GroupName = $(this).val();
            CHSI.UW.ScheduleTypes.Controllers.ScheduleTypesController.GetScheduleTypes();
        });
    }
}