CHSI.Common.Ajax = {
    GetFile: function (url) {
        
        var toReturn;
        $.ajax({
            url: url,
            async: false
        }).done(function (data) {
            toReturn = data;
        });
        return toReturn;
    },
    GetHTML: function (HTMLPath) {
        return CHSI.Common.Ajax.GetFile('/Libs/' + HTMLPath);
    },
    Get: function (Options) {
        if (Options.Error == null) {
            Options.Error = this.GetDefaultErrorFunction();
        }
        if (Options.Async == null || Options.Async == false)
        {
            console.log('synchronous: ' + Options.URL);
        }
        $.ajax({
            type: "GET",
            async: Options.Async != null ? Options.Async : true,
            url: Options.URL,
            cache: false,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: Options.Success,
            error: Options.Error
        });
    }
    , Post: function (Options) {
        if (Options.Error == null) {
            Options.Error = this.GetDefaultErrorFunction();
        }
        if (Options.StringData == null) {
            Options.StringData = JSON.stringify(Options.Data);
        }

        $.ajax({
            type: "POST",
            async: Options.Async != null ? Options.Async : true,
            url: Options.URL,
            cache: false,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            data: Options.StringData,
            success: Options.Success,
            error: Options.Error
        });
    }

    , Put: function (Options) {
        if (Options.Error == null) {
            Options.Error = this.GetDefaultErrorFunction();
        }
        if (Options.StringData == null) {
            Options.StringData = JSON.stringify(Options.Data);
        }

        $.ajax({
            type: "PUT",
            async: Options.Async != null ? Options.Async : true,
            url: Options.URL,
            cache: false,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            data: Options.StringData,
            success: Options.Success,
            error: Options.Error
        });
    }

    , Delete: function (Options) {
        if (Options.Error == null) {
            Options.Error = this.GetDefaultErrorFunction();
        }
        if (Options.StringData == null) {
            Options.StringData = JSON.stringify(Options.Data);
        }

        $.ajax({
            type: "DELETE",
            async: Options.Async != null ? Options.Async : true,
            url: Options.URL,
            cache: false,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            data: Options.StringData,
            success: Options.Success,
            error: Options.Error
        });
    }
    , GetDefaultErrorFunction: function (e) {
        CHSI.Common.LogObject(e);
    }
}
