CHSI.CRM.ClientTypes.Models.ClientType = {

    ClientTypeName: null,
    MemberAccess: null,
    BrokerAccess: null,
    PartnerAccess: null,
    IconImage: null,

GetClientType: function (ClientTypeName,resultfunction){
    var Options = {};
    Options.URL = "/api/CRM/ClientType/GetClientType?clientType=" + ClientTypeName;
    Options.Success = function (e) { resultfunction(e)};
    CHSI.Common.Ajax.Get(Options);
},

SaveClientType: function (ClientType, resultfunction) {
    var Options = {};
    Options.URL = "/api/CRM/ClientType/SaveClientType";
    Options.Data = ClientType;
    Options.Success = function (e) { resultfunction(e) };
    CHSI.Common.Ajax.Post(Options);
}

}