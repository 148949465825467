CHSI.Page.Subsidy = new Object();

CHSI.Page.Subsidy.SubsidyID = 0;
CHSI.Page.Subsidy.UserID = '';

CHSI.Page.Subsidy.ShowTransactionModal = function (subsidyID, userID) {

    CHSI.Page.Subsidy.SubsidyID = subsidyID;
    CHSI.Page.Subsidy.UserID = userID;




};

