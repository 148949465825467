module PL.AdminTools.Controllers {

    export class QuoteDocsBatchController {

        SmartQuoteDocs: GUI.Smart.SmartObject = new GUI.Smart.SmartObject;
        BatchTable: GUI.Tables.DTables = new GUI.Tables.DTables;

        init(): void {
            var _self = this;
            //Repository methods that return jQuery deferred objects get to use all of the cool Promise functionality like .done or .then.
            Models.QuoteDocumentBatch.getBatchGrid().done(function (data) {
                _self.SmartQuoteDocs = data;
                _self.buildQuoteBatchTable();
                _self.setupEvents();
            });

        }

        setupEvents(): void {
            var _self = this;

            $('#jxQuoteBatchContainer tbody').on('click', 'tr',(e) => {
                var d = _self.BatchTable.$grid.row(e.currentTarget).data();
                var ID = d[0];
                _self.ShowWindow(ID, e);

            });

            $('#jxAddNewQuoteBatch').on('click', (e) =>{
                _self.ShowWindow(null,e);

            });
        }

        ShowWindow(QuoteBatchID: number, clickInformation: any): void {
            var _self = this;
            var QuoteDocumentBatchWindow = new GUI.Windows.Popup();
            QuoteDocumentBatchWindow.URL = '/admin/scheduling/modals/QuoteDocBatchEdit.aspx?id=' + QuoteBatchID;
            QuoteDocumentBatchWindow.WindowID = 'quotedocbatch';
            QuoteDocumentBatchWindow.Title = 'Quote Documents Batch';
            QuoteDocumentBatchWindow.Width = 1000;
            QuoteDocumentBatchWindow.Height = 500;
            QuoteDocumentBatchWindow.CloseFunction = function () { _self.init(); }
            //Pass in the clickinformation so that the popup opens into a new window or tab if the shift or the shift + Control key is pressed.
            QuoteDocumentBatchWindow.showIFrame(clickInformation);
            
        }

        addEditColumn() {
            var _self = this;
            var cdt: GUI.Tables.DTables = new GUI.Tables.DTables;

            if (_self.SmartQuoteDocs) {
                var Column = new GUI.Smart.SmartColumnObject();
                //title: '',
                Column.data = null;
                Column.orderable = false;
                Column.class = 'details-control';
                Column.defaultContent = '[<a href="#" class="minilink">view</a>]';
                Column.render = function (data, type, row) {
                    //We set the title with no # so that the user can see the ID by moving their mouse over
                    return "<a class='jxEdit minilink' title='Quote Batch ID " + data[0] + "'>[Edit]</a>";
                }

                _self.SmartQuoteDocs.Columns.push(Column);
            }
        }

        buildQuoteBatchTable() {
            var _self = this;

            _self.addEditColumn();
            _self.BatchTable.containerName = 'jxQuoteBatchContainer';
            _self.BatchTable.tableName = 'jxQuoteBatchGrid';
            _self.BatchTable.data = _self.SmartQuoteDocs;       
            _self.BatchTable.buildTable();

        }

    }

}
 