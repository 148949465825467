namespace PL.Claims.Models {

    export class ClaimIncidentAttachments {

        FormFieldID: number;
        IncidentID: number;
        FileID: number;
        FileName: string;
        Description: string;

        constructor(attachment?: ClaimIncidentAttachments) {

            this.FormFieldID = attachment == undefined ? null : attachment.FormFieldID;
            this.IncidentID = attachment == undefined ? null : attachment.IncidentID;
            this.FileID = attachment == undefined ? null : attachment.FileID;
            this.FileName = attachment == undefined ? null : attachment.FileName;
            this.Description = attachment == undefined ? null : attachment.Description;

        }

        get(): JQueryDeferred<ClaimIncidentAttachments[]> {
            var result = $.Deferred();
            PL.Claims.APIs.ClaimIncidentReportRepo.getAttachments(this.IncidentID, this.FormFieldID).done((data) => {
                result.resolve(data);
            });
            return result;
        }   

        save(): JQueryDeferred<any> {
            var result = $.Deferred();
            PL.Claims.APIs.ClaimIncidentReportRepo.saveAttachment(this).done((data) => {
                result.resolve(data);
            });
            return result;
        }   

        deleteAttachment(): JQueryDeferred<any> {
            var result = $.Deferred();
            PL.Claims.APIs.ClaimIncidentReportRepo.deleteAttachment(this.FileID).done((data) => {
                result.resolve(data);
            });
            return result;
        }   

    }

}