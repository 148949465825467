CHSI.UW.ClassCode.Models.ClassCode = {

    ClassCode: null,
    GetAllClassCode: function (includeinactive,resultfunction) {

        var Options = {};
        Options.URL = "/api/UW/ClassCode/GetAllClassCode?includeinactive=" + includeinactive;
        Options.Success = resultfunction;
        CHSI.Common.Ajax.Get(Options);

    }

}