module PL.ClaimSystem.ClaimManagement {
    export class IncidentInjuryModel {
        constructor() { }

        public InjuryID: number;
        public IncidentID: number;
        public PrimaryInjury: boolean;
        public NatureOfInjury: string;
        public BodyPart: string;
        public Comments: string;
        public CreateUser: string;
        public CreateTmsp: string;
        public UpdateUser: string;
        public UpdateTmsp: string;
    }
}