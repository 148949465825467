namespace PL.AdminTools.Controllers {

    export class QuesitonnaireGridFormGridController {

        DataStructures: PL.Que.Models.Data.DataStructure[] = new Array<PL.Que.Models.Data.DataStructure>();
        formEditGrid: GUI.Tables.DTables = new GUI.Tables.DTables;

        init(): void {

            new PL.Que.Models.Data.DataStructure().getDataStructures().done((dataStructures) => {

                this.DataStructures = dataStructures;
                
                this.events();

            });

        }

        eventSetup(): void {

        }

        events(): void {
            var _self = this;

            $('#jxNewQuestionnaireFormGrid').on('click', function (e) {
                _self.openForm(null, e);
            });

        }

        openForm(formID: number, clickEvent?: any): void {

            var showPopUP = new GUI.Windows.Popup();
            showPopUP.URL = '/Que/Administration/Modal/FormEditor_2.aspx?formid=' + formID;
            showPopUP.WindowID = 'questionnaireformeditor';
            showPopUP.Title = 'Questionnaire Form Editor';
            showPopUP.Width = 1000;
            showPopUP.Height = 720;
            showPopUP.CloseFunction = () => this.init()
            showPopUP.showIFrame(clickEvent);

        }

    }

}