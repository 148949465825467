
CHSI.Forms.Results = {
    ResultMap: null
    ,GetPriorResult: function (ColumnID, ValueIndex) {
        var PriorResult = '';
        var RelatedResultValue = CHSI.Forms.Results.GetResultSetValue(ColumnID, ValueIndex);
        if (RelatedResultValue) {
            if (RelatedResultValue.DataTypeID == 2) {
                return CHSI.Dates.GetFormattedJSONDate(RelatedResultValue.PriorResultAsString);
            }
            PriorResult = RelatedResultValue.PriorResultAsString;
        }
        return PriorResult;
    }
    , SetLastUserResult: function (columnID, elementID, valueIndex, formID) {
        var AjaxValue = { ResultSetID: CHSI.Forms.ResultID, ColumnID: columnID, ValueIndex: valueIndex };

        jQuery.ajax({
            type: "GET", url: "/api/Que/Result/GetPreviousUserResultValue",
            data: AjaxValue,
            contentType: "application/json; charset=utf-8"
            , cache: false
            , dataType: "json",
            success: function (e) {
                /* replace the cached value*/
                if (e)
                {
                    var PreviousValue = CHSI.Forms.Results.GetResultItemAsString(e);
                    CHSI.Forms.Elements.UpdateElementValue(elementID, valueIndex, formID, PreviousValue);
                }
                

            }, error: jQuery_ErrorResult
        });

    },
    /*API change
    /api/Que/Result/GetChangeResults

    /api/Que/FormInstance/GetChangeResults
    */

    GetChangedResults: function (resultid, formid) {
        CHSI.Forms.ajaxContainerHTML = jQuery.ajax({
            type: "GET", url: CHSI.Forms.APIs.ChangeResultsAPI() +  "?resultid=" + resultid + "&FormID=" + formid,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (e) {
                CHSI.Forms.ChangedResults = e;
                $('.elementvalidation').hide();
                if (CHSI.Forms.ChangedResults) {
                    CHSI.Forms.CalculatedResults = CHSI.Forms.ChangedResults.CalculatedResults;
                    if (CHSI.Forms.CalculatedResults) {
                        CHSI.Forms.Results.ProcessCalculatedResults(CHSI.Forms.CalculatedResults);
                    }
                    CHSI.Forms.ValidatedResults = CHSI.Forms.ChangedResults.ValidatedResults;
                    if (CHSI.Forms.ValidatedResults) {
                        CHSI.Forms.Results.ProcessValidatedResults(CHSI.Forms.ValidatedResults);
                    }
           
                }
                $('#jxLoading').hide();
            }, error: CHSI.DOM.ProcessAjaxError
        });

    }
      , ProcessValidatedResults: function (ValidatedResults) {
          for (var i = 0, ResultCount = CHSI.Forms.ValidatedResults.length; i < ResultCount; i++) {
              var ValidatedResult = ValidatedResults[i];
              this.ProcessValidationResult(ValidatedResult);
          }
          CHSI.Forms.BindEvents();

      }
    , ProcessValidationResult: function (ValidatedResult)
    {
        var Element = $('#jxElementContainer_' + ValidatedResult.ElementID + '_0');
        if (Element.length == 1)
        {
            switch (ValidatedResult.ValidationResult) {
                case 1://Warning
                    CHSI.Forms.Elements.ShowElementMessage(ValidatedResult.ElementID, ValidatedResult.Message, 'orange', 0)
                    break;
                case 2: //Warning with Explanation
                    //alert("Warning! Please Explain Element ID:" + ValidatedResult.ElementID);
                    var Explanation = '';
                    if (ValidatedResult.Explanation)
                    { Explanation = ValidatedResult.Explanation;}
                    CHSI.Forms.Elements.ShowElementMessage(ValidatedResult.ElementID, ValidatedResult.Message + '<br/><Textarea class="ExplanationField" data-elementid="' + ValidatedResult.ElementID + '">' + Explanation + '</Textarea>', 'orange', 0)
                    break;
                case 3: //error
                    CHSI.Forms.Elements.ShowElementMessage(ValidatedResult.ElementID, ValidatedResult.Message, 'red',0)
                    break;
            }
        }
        

    }
    , ProcessCalculatedResults: function (CalculatedResults) {
        for (var i = 0, ResultCount = CHSI.Forms.CalculatedResults.length; i < ResultCount; i++) {
            var CalculatedResult = CHSI.Forms.CalculatedResults[i];
            if (CalculatedResult.NewValue) {
                var ElementValue = CHSI.Forms.Results.GetValue(CalculatedResult.ColumnID, CalculatedResult.ValueIndex, CHSI.Forms.FormID);
                if (ElementValue == CalculatedResult.NewValue) {
                    CHSI.Common.Log('Calculated Value for ' + CalculatedResult.ColumnID + ' the same as original answer, not saving');
                } else {

                    var Element = CHSI.Forms.Elements.GetElementByColumn(CHSI.Forms.PrimaryFormID, CalculatedResult.ColumnID);
                    if (Element) {
                        CHSI.Forms.Elements.UpdateElementValue(Element.ElementID, CalculatedResult.ValueIndex, CHSI.Forms.PrimaryFormID, CalculatedResult.NewValue);
                        $("#" + Element.ElementID).trigger('blur', function (event) { event.stopPropagation() });
                    }
                    else {
                        var UpdatedResultItem = CHSI.Forms.Results.SetValue(CalculatedResult.ColumnID, CalculatedResult.ValueIndex, CHSI.Forms.FormID, CalculatedResult.NewValue, null);
                        this.SaveResultFieldValue(UpdatedResultItem, null);
                    }
                }
            }
        }
        
    }
    , MarkFormAsChanged: function () {

        CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].DataChanged = true;
        CHSI.Common.Log('Internal status: ' + CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].IsInternal);
        if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].IsInternal == false) {
            CHSI.Forms.Navigation.SetSectionStatus(CHSI.Forms.CurrentActiveSectionIndex, 'In Progress');
            jQuery('#jxStatusTitle').html('In Progress');
        }

        CHSI.Forms.Navigation.UpdateSectionHTML();
    }
    ,SaveExplanation: function(ElementID, ExplanationText)
    {
        var ajaxData = new Object();
        ajaxData.ElementID = ElementID;
        ajaxData.ResultSetID = CHSI.Forms.ResultID;
        ajaxData.ValidationResult = null;
        ajaxData.Message = null;
        ajaxData.Explanation = ExplanationText;
        var DataString = JSON.stringify(ajaxData);
        jQuery.ajax({
            type: "POST", url: "/api/Que/ElementValidation/post",
            data: DataString,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (e) {

            }, error: jQuery_ErrorResult
        });

        
    }
    , SaveResultFieldValue: function (ResultSetValue, ParentElementID, executeSuccess) {
        if (executeSuccess == null)
        {
            executeSuccess = true;
        }

        var ajaxData = new Object();
        ajaxData.resultid = CHSI.Forms.ResultID;
        ajaxData.columnid = ResultSetValue.StructureColumnID;
        ajaxData.formid = CHSI.Forms.PrimaryFormID;
        ajaxData.valueindex = ResultSetValue.ValueIndex;
        ajaxData.StringValue = ResultSetValue.StringValue;
        ajaxData.DateValue = ResultSetValue.DateValue;
        ajaxData.IntegerValue = ResultSetValue.IntegerValue;
        ajaxData.DecimalValue = ResultSetValue.DecimalValue;
        ajaxData.BitValue = ResultSetValue.BitValue;
        ajaxData.passedvalidation = ResultSetValue.PassedValidation;
        ajaxData.ChangeType = ResultSetValue.ChangeType;
        ajaxData.WarningLevel = ResultSetValue.ValidationLevel;
        ajaxData.ParentElementID = ParentElementID;
        ajaxData.ClearReviewed = true;
        var DataString = JSON.stringify(ajaxData);
        if (CHSI.Forms.DebugMode) {
            CHSI.Common.Log('Calling SaveResultField: ' + DataString + ' and setting new cache for tag: ' + ResultSetValue.Tag);
        }
        jQuery.ajax({
            type: "POST", url: "/webservice/formfunctions.asmx/SaveResultField",
            data: DataString,
            async: false,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (e) {
                /* replace the cached value*/
                if (executeSuccess)
                {
                    CHSI.Forms.Results.GetChangedResults(CHSI.Forms.ResultID, CHSI.Forms.PrimaryFormID);
                    CHSI.Forms.Actions.ExecuteFormActions();
                    CHSI.Forms.Navigation.SetSectionStatus(CHSI.Forms.CurrentActiveSectionIndex, 'In Progress');
                }
                

            }, error: jQuery_ErrorResult
        });

    }
    , CreateMap: function ()
    {
        var ResultMap = {};
        for (var i = 0, valuecount = CHSI.Forms.ResultSetValues.length; i < valuecount; i++) {           
                var ResultValue = CHSI.Forms.ResultSetValues[i];
                ResultMap[this.GetResultKey(ResultValue.StructureColumnID, ResultValue.ValueIndex)] = ResultValue;
        }; 
        CHSI.Forms.Results.ResultMap = ResultMap;
    }
    , GetResultKey: function (ColumnID, ValueIndex)
    {
        return "R" + ColumnID + '_' + ValueIndex;
    }
    , GetResultSetValue: function (StructureColumnID, ValueIndex) {
        var Value = null;
        if (StructureColumnID != 0 && StructureColumnID != null)
        {
            if (!CHSI.Forms.Results.ResultMap)
            {
                CHSI.Forms.Results.CreateMap();
            }
            Value = CHSI.Forms.Results.ResultMap[this.GetResultKey(StructureColumnID, ValueIndex)];

            return Value;

        }
        
    }
};

CHSI.Forms.Results.GetDisabledStatus = function (columnID, ValueIndex, FormID) {
    var isDisabled = false;
    var ResultValue = CHSI.Forms.Results.GetResultSetValue(ColumnID, ValueIndex);
    if (ResultValue) {
        isDisabled = ResultValue.isDisabled;
    }

    return isDisabled;
}

CHSI.Forms.Results.DeleteElement = function (ElementID, ColumnID, ValueIndex, FormID) {
    if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].isDisabled) {
        return false;
    }

    var ResultValue = CHSI.Forms.Results.GetResultSetValue(ColumnID, ValueIndex)
    if (ResultValue) {
        CHSI.Common.Log('deleting Result: ' + ColumnID + ', Value Index: ' + ValueIndex);
        ResultValue.Status = 'deleted';
    }

    CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].DataChanged = true;
    CHSI.Forms.Navigation.SetSectionStatus(CHSI.Forms.CurrentActiveSectionIndex, 'In Progress');


    var ajaxData = new Object();
    ajaxData.resultid = CHSI.Forms.ResultID;
    ajaxData.columnid = ColumnID;
    ajaxData.formid = CHSI.Forms.PrimaryFormID;
    ajaxData.valueindex = ValueIndex;
    ajaxData.ParentElementID = ElementID;
    var DataString = JSON.stringify(ajaxData);
    if (CHSI.Forms.DebugMode) {
        CHSI.Common.Log('Calling DeleteResultField: ' + DataString + ' and setting new cache for elementid: ' + ElementID);
    }

    jQuery.ajax({
        type: "POST", url: "/webservice/formfunctions.asmx/DeleteResultField",
        data: DataString,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (e) {
            /* replace the cached value*/
            CHSI.Forms.Actions.ExecuteFormActions();
            var UploadControl = CHSI.DOM.Get('#' + CHSI.Forms.Elements.GetElementID(ElementID, 'X'))
            CHSI.Common.Log('Updating Upload Control: ' + UploadControl.length + ' count');
            UploadControl.html(CHSI.Forms.Elements.GetFileUploadListing(ColumnID, FormID, ElementID));

        }, error: jQuery_ErrorResult
    });


};

CHSI.Forms.Results.SaveValue = function (sender, datastructurecolumnid, tag, ValueIndex, FormID, ParentElementID, TargetDataType) {    
    
    
    
    if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].isDisabled) {
        return false;
    }
    var cachedValue = CHSI.Forms.Results.GetValue(datastructurecolumnid, ValueIndex, FormID);
    var elementcontrol = jQuery(sender);
        console.log('SaveValue function being called for - Column: ' + datastructurecolumnid + ' for Tag: ' + tag + ' cached value' + cachedValue + ' compared to control value: ' + elementcontrol.val());
    
    var NewAnswer = '';
    

    if (elementcontrol.attr("type") == 'checkbox') {
        /* to get the values we have to do it a bit differently*/
        console.log('checkbox code called');
        var CheckboxAnswer = [];
        var CheckedResults = jQuery('input:checkbox[name=' + elementcontrol.attr('name') + ']:checked');
        jQuery(CheckedResults).each(function (index, value) {
            CheckboxAnswer.push(value.value);
        });
        NewAnswer = '||' + CheckboxAnswer.join('||') + '||';
    }
    else {
        NewAnswer = elementcontrol.val();
    }
    
    

    if (NewAnswer != cachedValue) {

        if (elementcontrol.attr("type") == 'text' && NewAnswer != '') {
            if (CHSI.Forms.DebugMode) {
                CHSI.Common.Log('Checking to remove validation');
            }
            NewAnswer = CHSI.Forms.Elements.RemoveFormatting(datastructurecolumnid, NewAnswer, FormID);
            CHSI.Common.Log('the new value is ' + NewAnswer);
        }
    
        var CurrentResult = null;

        if (elementcontrol.valid() == false) {
            return false;
        }
        var QueFormElement = CHSI.Forms.Elements.GetElementByID(ParentElementID, FormID);

        if (QueFormElement && QueFormElement.ElementType == 12)
        {
        
            if (elementcontrol.is(':checked'))
            {
                if (QueFormElement.DataStructure.DataTypeID == 1)
                { NewAnswer = 'Y';}
                else
                { NewAnswer = 'true'; }
            }
            else
            {
                if (QueFormElement.DataStructure.DataTypeID == 1)
                { NewAnswer = 'N'; }
                else
                { NewAnswer = 'false'; }
            }
        }

        CHSI.Forms.Results.MarkFormAsChanged();

        CHSI.Forms.Navigation.UpdateSectionHTML();
      
        CHSI.Forms.Results.SetValue(datastructurecolumnid, ValueIndex, FormID, NewAnswer, TargetDataType);

        var isvalidated = CHSI.Forms.Results.ValidateField(tag, elementcontrol.attr("id"), ValueIndex, NewAnswer, FormID, TargetDataType);
        if (CHSI.Forms.DebugMode) {
            CHSI.Common.Log('Validation Level ' + isvalidated + ', saving ' + datastructurecolumnid + ':' + ValueIndex + ' with new value ' + NewAnswer);
        }
        CHSI.Forms.CurrentValidationObjects[tag] = isvalidated;
        var objResultValue = CHSI.Forms.Results.GetResultSetValue(datastructurecolumnid, ValueIndex);

        if (TargetDataType != 2) {
            objResultValue.DateValue = '';
        }

        objResultValue.passedvalidation = true;
    
        var ChangeType = 0;
        var jxNoChange = $('#jxNoChange' + datastructurecolumnid + '_' + ValueIndex);
        if (jxNoChange.length == 1) {
            if (jxNoChange.is(':checked')) {
                ChangeType = 1;
            }
        }
        objResultValue.ChangeType = ChangeType;
        objResultValue.WarningLevel = isvalidated;
    
        CHSI.Forms.Results.SaveResultFieldValue(objResultValue, ParentElementID);

        elementcontrol.addClass('dbSaved');    

    }

    var Format = elementcontrol.data('format');

    if (Format) {
        var FormattedValue = String.__Format(Format, +elementcontrol.val());
        elementcontrol.data('rawvalue', +elementcontrol.val());
        elementcontrol.val(FormattedValue);
    }

    CHSI.Forms.Grid.RecalculateTotals();
    
}


/*This gets the calculated columns*/




CHSI.Forms.Results.GetValueByTag = function (tag, FormID) {

    var Result = [];


    var SenderTagValues = tag.replace("{", "").replace("}", "").split('.');
    if (SenderTagValues.length == 3) {
        SenderTagValues.push(0); /*Default to Value Index 0 */
    }
    
    /* Check for element on page */
    var ElementExists = false;
    var ElementName;
    jQuery(CHSI.Forms.Forms['f' + FormID].Elements).each(function (index, Element) {
        if (Element.Tag == tag && Element.DataStructure != null) {
            
            /* Element might be on page */
            ElementName = 'jxElement_' + Element.ElementID + '_' + SenderTagValues[3];
            
            var ElementLookup = null;
            if (Element.ElementType == 5 || Element.ELementType == 11) {
                ElementLookup = jQuery('input:checkbox[name=' + ElementName + ']');
                if (ElementLookup.length > 0) {
                    ElementExists = true;
                    ElementLookup = jQuery('input:checkbox[name=' + ElementName + ']:checked');
                    var CheckboxAnswer = [];
                    jQuery(ElementLookup).each(function (index, value) {
                        CheckboxAnswer.push(value.value);
                    });
                    Result.push('||' + CheckboxAnswer.join('||') + '||');
                }

            }
            else if (Element.ElementType == 4 || Element.ElementType == 10) {
                ElementLookup = jQuery('input:radio[name=' + ElementName + ']');
                if (ElementLookup.length > 0) {
                    ElementExists = true;
                    ElementLookup = jQuery('input:radio[name=' + ElementName + ']:checked');
                    var CheckboxAnswer = [];
                    jQuery(ElementLookup).each(function (index, value) {
                        CheckboxAnswer.push(value.value);
                    });
                    Result.push(CheckboxAnswer);
                }
            }
            else if (Element.ElementType == 8) {
                ElementLookup = jQuery('select[name=' + ElementName + ']');
                if (ElementLookup.length > 0) {
                    ElementExists = true;
                    Result.push(ElementLookup.val());
                }
            }
            else {
                ElementLookup = jQuery('input[name=' + ElementName + ']');
                if (ElementLookup.length > 0) {
                    ElementExists = true;
                    Result.push(ElementLookup.val());
                    return Result.join('');
                }
            }
            return false;
        }
    });
    if (ElementExists == true) {
        return Result.join('');
    }


    for (var ResultIndex = 0, ResultSetLength = CHSI.Forms.ResultSetValues.length; ResultIndex < ResultSetLength; ++ResultIndex) {
        var ResultValue = CHSI.Forms.ResultSetValues[ResultIndex];
        var ResultTagValues = ResultValue.Tag.replace("{", "").replace("}", "").split('.');
        if (ResultTagValues.length == 3) {
            ResultTagValues.push(0);
        }
        if (ResultTagValues[1] == SenderTagValues[1] && ResultTagValues[2] == SenderTagValues[2] && ResultTagValues[3] == SenderTagValues[3]) {
            if (ResultValue)
            {
                Result.push(CHSI.Forms.Results.GetResultItemAsString(ResultValue));
            }
            
            break;
        }
    };


    return Result.join('');
}

CHSI.Forms.Results.GetResultItemAsString = function (ResultValue) {
    var Value = null;
    if (ResultValue.DataTypeID)
    {
        if (ResultValue.DataTypeID == 1) {
            Value = ResultValue.StringValue;
        }
        if (ResultValue.DataTypeID == 2) {
            Value = CHSI.Dates.GetFormattedJSONDate(ResultValue.DateValue);
        }
        if (ResultValue.DataTypeID == 3) {
            Value = ResultValue.DecimalValue;
        }
        if (ResultValue.DataTypeID == 4) {
            Value = ResultValue.BitValue;
        }
        if (ResultValue.DataTypeID == 5) {
            Value = ResultValue.IntegerValue;
        }

    }
    
    return Value;
}

CHSI.Forms.Results.SaveMetaAttribute = function (ParentID, Parent2ID, Parent3ID, ObjectTypeID, MetaType, MetaIndex, MetaValue, IncrementAttribute, ElementID) {
    if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].isDisabled) {
        return false;
    }
    var ajaxData = new Object();
    ajaxData.ParentID = ParentID;
    ajaxData.Parent2ID = Parent2ID;
    ajaxData.Parent3ID = Parent3ID;
    ajaxData.ObjectTypeID = ObjectTypeID;
    ajaxData.MetaType = MetaType;
    ajaxData.MetaIndex = MetaIndex;
    ajaxData.MetaValue = MetaValue;
    ajaxData.IncrementAttribute = IncrementAttribute;
    ajaxData.ElementID = ElementID;
    ajaxData.ParentElementID = ElementID;
    var DataString = JSON.stringify(ajaxData);
 

    jQuery.ajax({
        type: "POST", url: "/webservice/formfunctions.asmx/SaveMetaAttribute",
        data: DataString,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (e) {
            /* replace the cached value*/
            var isFound = false;
            var ResultItem = null;
            var objResultValue;
            objResultValue = CHSI.Forms.Results.GetResultSetValue(ParentID, Parent3ID);
            if (objResultValue) {

                jQuery(objResultValue.MetaAttributes).each(function (index, Attribute) {
                    if (Attribute.MetaType == MetaType) {
                        Attribute.MetaValue = MetaValue;
                        isFound = true;
                        return false;
                    }
                });
            }

            if (isFound == false && ResultItem != null) {
                ResultItem.MetaAttributes.push(e.d);
            }


        }, error: jQuery_ErrorResult
    });

}

CHSI.Forms.Results.GetMetaAttributeValue = function (ColumnID, ValueIndex, MetaType) {
    var Result = null;
    var objResultValue;
    objResultValue = CHSI.Forms.Results.GetResultSetValue(ColumnID, ValueIndex);
    if (objResultValue) {
        jQuery(objResultValue.MetaAttributes).each(function (index, Attribute) {
            if (Attribute.MetaType == MetaType) {
                Result = Attribute.MetaValue;
                return false;
            }
        });

    }


    return Result;
}

CHSI.Forms.Results.GetValue = function (ColumnID, ValueIndex, FormID) {
    var Result = [];
    var objResultValue;
    objResultValue = CHSI.Forms.Results.GetResultSetValue(ColumnID, ValueIndex);
    if (objResultValue) {
        Result.push(CHSI.Forms.Results.GetResultItemAsString(objResultValue));
    }

    return Result.join('');
}

CHSI.Forms.Results.SetValue = function (ColumnID, ValueIndex, FormID, NewValue, TargetDataType) {
    var ResultValue;
    ResultValue = CHSI.Forms.Results.GetResultSetValue(ColumnID, ValueIndex);
    if (ResultValue) {
        if (TargetDataType == null) {
            TargetDataType = ResultValue.DataTypeID;
        }

        if (TargetDataType == 1) {
            ResultValue.StringValue = NewValue;
        }
        if (TargetDataType == 2) {
            ResultValue.DateValue = CHSI.Dates.GetFormattedJSONDate(NewValue);
        }
        if (TargetDataType == 3) {
            ResultValue.DecimalValue = NewValue;

        }
        if (TargetDataType == 4) {
            ResultValue.BitValue = NewValue;
        }
        if (TargetDataType == 5) {
            ResultValue.IntegerValue = NewValue;
        }
    }
    return ResultValue;
}
/* validation logic will be here soon */
CHSI.Forms.Results.ValidateField = function (tag, controlid, answerindex, newanswer, formID, TargetDataType) {
    if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].isDisabled) {
        return false;
    }
    var ValidationResult = 0; /*this would be a success if nothing else changes*/
    if (newanswer == '') {
        ValidationResult = 1; /* unanswered*/
    }

    else {
     

    }
    return ValidationResult;
}
/* The user wants to save whats in the fields*/
CHSI.Forms.Results.SaveForm = function (CompleteForm, IgnoreWarnings, FormID, successFunction) {
    if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].isDisabled) {
        return false;
    }
     
    var invalidForm = false;

    if (invalidForm == true) {
        alert('one or more errors exist on the page, please correct before continuing');
        return false;
    }
    var DataObject = new Object();
    DataObject.ResultID = CHSI.Forms.ResultID;
    DataObject.FormID = FormID;
    DataObject.IgnoreWarnings = IgnoreWarnings;
 
    var ajaxString = JSON.stringify(DataObject);
  
    jQuery.ajax({
        type: "POST", url: CHSI.Forms.APIs.SubmitQuestionnaireAPI(),
        data: ajaxString,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (e) {
            /* replace the cached value*/
            var SaveObject = e;
            console.log(e);
            if (SaveObject.StatusChanged == true) {
                CHSI.Forms.Results.isFormSaved = true;
                if (CompleteForm) {
                    alert('Your Form has been completed and successfully submitted.  Changes to this Form may no longer be made.');
                    
                    $('#jxLoadingText').hide();
                    $('#jxFormContainer').show();
                }
                else {
                    alert('Your changes have been saved');
                    $('#jxLoadingText').hide();
                    $('#jxFormContainer').show();
                }

            }
            else {
                if (CompleteForm) {
                    if (SaveObject.ValidationLevel <= 2) {
                        if (confirm('The following Warnings occurred: \n\t' + SaveObject.WarningMessages.join('\n\t') + '\nDo you want to submit the form anyway?')) {
                            CHSI.Forms.Results.SaveForm(true, true, FormID);
                            $('#jxLoadingText').hide();
                            $('#jxFormContainer').show();
                        }
                    }
                    else {
                        CHSI.Common.Page.ReadOnly(false, false, false);
                        $('.SubmitQuestionnaire').attr('readonly', false);
                        $('.SubmitQuestionnaire').css('background-color', 'orange');
                        $('#jxLoadingText').hide();
                        $('#jxFormContainer').show();

                        CHSI.Forms.Results.isFormSaved = false;
                        
                        var ResultMessage = '';
                        if (SaveObject.WarningMessages.length > 0) {
                            ResultMessage = ResultMessage + 'The following Warnings occurred:<ul>' + SaveObject.WarningMessages.join('<br/>') + '</ul>';
                        }
                        if (SaveObject.ErrorMessages.length > 0) {
                            ResultMessage = ResultMessage + '<br/>The questionnaire is unable to be submitted due to the issues below. Please address each and then re-submit. <br/><br/><ul><li>' + SaveObject.ErrorMessages.join('</li></li><li>') + '</ul>';
                        }
                        var DialogWindow = jQuery('#jxFormMessageWindow');
                        var DialogMessage = jQuery('#jxFormMessage', DialogWindow);
                        var jxFormDialog = jQuery('#jxFormDialog', DialogWindow);

                        var DialogButtons;
                        if (SaveObject.ValidationLevel <= 2) {
                            DialogButtons = '<input type="button" value="Ignore Warnings, Complete Questionnaire" onclick="CHSI.Forms.Results.SaveSection(' + FormID + ',' + SectionID + ',' + Visibility + ',' + ResultID + ',\'Complete\',true);" /><input type="button" value="Do not complete Questionnaire" onclick="jQuery(\'#jxFormMessageWindow\').hide()" />';
                        }
                        else {
                            DialogButtons = '<input type="button" value="Close" onclick="jQuery(\'#jxFormMessageWindow\').hide()" />';
                        }
                        jxFormDialog.html(DialogButtons);
                        DialogMessage.html(ResultMessage);

                        DialogWindow.css('left', -5000);
                        DialogWindow.css('top', -5000);
                        DialogWindow.show();
                        DialogWindow.position({ at: "center center", collision: "flip flip", of: jQuery('#' + CHSI.Forms.FormContainerID) });
                    }
                }
            }
            if (successFunction) {
                successFunction();
            }
        }, error: function () {
            jQuery_ErrorResult            
            $('#jxLoadingText').hide();
            $('#jxFormContainer').show();
        }        
        });

        };

CHSI.Forms.Results.SaveSection = function (FormID, SectionID, Visibility, ResultID, Status, IgnoreWarnings, newSectionIndex) {
    if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].isDisabled) {
        return false;
    }
    if (IgnoreWarnings == null) {
        IgnoreWarnings = false;
    }
    if (newSectionIndex == null) {
        newSectionIndex = -1;
    }


    var ajaxData = new Object();
    ajaxData.FormID = FormID;
    ajaxData.SectionID = SectionID;
    ajaxData.CurrentVisibility = Visibility;
    ajaxData.ResultID = ResultID * 1;
    ajaxData.Status = Status;
    ajaxData.IgnoreWarnings = IgnoreWarnings;
    var ajaxString = JSON.stringify(ajaxData);

    jQuery.ajax({
        /*
        Old API  /api/Que/Form/SaveSection
        New API  /api/Que/FormInstance/SaveSection
        */
        type: "POST", url: CHSI.Forms.APIs.SaveSectionAPI(),
        data: ajaxString,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (e) {
            /* replace the cached value*/

            var objCurrentSection = CHSI.Forms.Forms['f' + FormID].Sections[CHSI.Forms.Navigation.GetSectionIndexbyIndex(CHSI.Forms.CurrentActiveSectionIndex)];


            var SaveObject = e;

            if (SaveObject.StatusChanged == true) {
                if (Status != null) {
                    objCurrentSection.Status = Status;
                    jQuery('#jxFormMessageWindow').hide();
                    jQuery('#jxStatusTitle').html('In Progress');
                    CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].DataChanged = false;
                  
                }

                CHSI.Forms.Navigation.UpdateSectionHTML();

                if (newSectionIndex != -1) {
                    CHSI.Forms.Navigation.ChangeActiveSection(newSectionIndex, FormID);
                }
            }
            else {

                var ResultMessage = '';
                if (SaveObject.WarningMessages.length > 0) {
                    ResultMessage = ResultMessage + 'The following Warnings occurred:<ul>' + SaveObject.WarningMessages.join('<br/>') + '</ul>';
                }
                if (SaveObject.ErrorMessages.length > 0) {
                    ResultMessage = ResultMessage + '<br/>The questionnaire is unable to be completed due to the issues below.  Please address each and then re-submit. <br/><br/><ul><li>' + SaveObject.ErrorMessages.join('</li></li><li>') + '</ul>';
                }
                var DialogWindow = jQuery('#jxFormMessageWindow');
                var DialogMessage = jQuery('#jxFormMessage', DialogWindow);
                var jxFormDialog = jQuery('#jxFormDialog', DialogWindow);

                var DialogButtons;
                if (SaveObject.ValidationLevel <= 2) {
                    DialogButtons = '<input type="button" value="Ignore Warnings, Complete Section" onclick="CHSI.Forms.Results.SaveSection(' + FormID + ',' + SectionID + ',' + Visibility + ',' + ResultID + ',\'Complete\',true,' + newSectionIndex + ');" /><input type="button" value="Do not complete section" onclick="jQuery(\'#jxFormMessageWindow\').hide()" />';
                }
                else {
                    DialogButtons = '<input type="button" value="Close" onclick="jQuery(\'#jxFormMessageWindow\').hide()" />';
                }
                jxFormDialog.html(DialogButtons);
                DialogMessage.html(ResultMessage);

                DialogWindow.css('left', -5000);
                DialogWindow.css('top', -5000);
                DialogWindow.show();
                DialogWindow.position({ at: "center center", collision: "flip flip", of: jQuery('#' + CHSI.Forms.FormContainerID) });


            }

            /*jQuery('#FormNavRegion').html(CHSI.Forms.Navigation.GenerateNavigation(FormID));*/

        }, error: jQuery_ErrorResult
    });


};

CHSI.Forms.Results.returnResultSetIndex = function (StructureColumnID, ValueIndex) {
    if (ValueIndex == null)
    { ValueIndex = 0; }
    var beginIndex = 0, endIndex = CHSI.Forms.ResultSetValues.length;
    var ResultIndex = null;
    var SearchKey = (StructureColumnID * 10000) + ValueIndex;
    var CurrentSearchItemKey;
    var LoopCount = 0;

    while (beginIndex < endIndex) {
        if (LoopCount > 100) {
            ResultIndex == null;
            break;
        }
        ResultIndex = Math.floor((beginIndex + endIndex) / 2);
        var CurrentItem = CHSI.Forms.ResultSetValues[ResultIndex];
        CurrentSearchItemKey = (CurrentItem.StructureColumnID * 10000) + CurrentItem.ValueIndex;
        if (SearchKey === CurrentSearchItemKey)
        { break; }
        if (SearchKey < CurrentSearchItemKey) {
            endIndex = ResultIndex;
        }
        else {
            beginIndex = ResultIndex;
        }
        LoopCount++;
    }
    return ResultIndex;

};


CHSI.Forms.Results.GetNonVirtualValueIndexRows = function (StructureColumnID) {
    var RowIndices = []
    var ResultFound = false;
    var StartIndex = CHSI.Forms.Results.returnResultSetIndex(StructureColumnID, 0);
    for (var ResultIndex = StartIndex, ResultSetLength = CHSI.Forms.ResultSetValues.length; ResultIndex < ResultSetLength; ++ResultIndex) {
        var ResultValue = CHSI.Forms.ResultSetValues[ResultIndex];
        if (ResultValue.StructureColumnID == StructureColumnID && ResultValue.Status != 'deleted' && ResultValue.Status != 'virtual') {
            RowIndices.push(ResultValue.ValueIndex);
            ResultFound = true;
        }
        else {
            if (ResultFound == true && ResultValue.StructureColumnID != StructureColumnID) {
                break;/*Ordered list, if it did match and now doesn't, there must not be any more*/
            }
        }
    }
    return RowIndices;
};
CHSI.Forms.Results.GetResultSetValueByTag = function (Tag) {
    var Value = null;
    for (var ResultIndex = 0, ResultSetLength = CHSI.Forms.ResultSetValues.length; ResultIndex < ResultSetLength; ++ResultIndex) {
        var ResultValue = CHSI.Forms.ResultSetValues[ResultIndex];
        if (ResultValue.Tag == Tag) {
            Value = ResultValue;
            break;
        }
    }
    return Value;
}


CHSI.Forms.Results.GetResultSetValueByTagRegex = function (TagRegex) {
    var Value = null;
    for (var ResultIndex = 0, ResultSetLength = CHSI.Forms.ResultSetValues.length; ResultIndex < ResultSetLength; ++ResultIndex) {
        var ResultValue = CHSI.Forms.ResultSetValues[ResultIndex];
        if (TagRegex.test(ResultValue.Tag)) {
            Value = ResultValue;
            break;
        }
    };

    return Value;
}




CHSI.Forms.Results.GetResultSetValueArray = function (StructureColumnID) {
    var Values = [];
    var isFound = false;
    var StartIndex = CHSI.Forms.Results.returnResultSetIndex(StructureColumnID, 0);

    for (var ResultIndex = StartIndex, ResultSetLength = CHSI.Forms.ResultSetValues.length; ResultIndex < ResultSetLength; ++ResultIndex) {
        var ResultValue = CHSI.Forms.ResultSetValues[ResultIndex];
        if (ResultValue.StructureColumnID == StructureColumnID) {
            Values.push(ResultValue);
            isFound = true;
        }
        if (isFound == true && ResultValue.StructureColumnID != StructureColumnID) {
            break;
        }
    };

    return Values;
};

CHSI.Forms.Results.GetMaxValueIndex = function (StructureColumnID) {
    var MaxValue = -1;
    var isFound = false;
    var StartIndex = CHSI.Forms.Results.returnResultSetIndex(StructureColumnID, 0);
    for (var ResultIndex = StartIndex, ResultSetLength = CHSI.Forms.ResultSetValues.length; ResultIndex < ResultSetLength; ++ResultIndex) {
        var ResultValue = CHSI.Forms.ResultSetValues[ResultIndex];
        if (ResultValue.StructureColumnID == StructureColumnID) {
            MaxValue = ResultValue.ValueIndex;
            isFound = true;
        }
        if (isFound == true && ResultValue.StructureColumnID != StructureColumnID) {
            break;
        }
    };
    return MaxValue;
};



CHSI.Forms.Results.AppendResult = function (NewResultItem) {
    CHSI.Forms.ResultSetValues.push(NewResultItem);
    if (!CHSI.Forms.Results.ResultMap )
    {
        CHSI.Forms.Results.CreateMap();
    }
    CHSI.Forms.Results.ResultMap[this.GetResultKey(NewResultItem.StructureColumnID, NewResultItem.ValueIndex)] = NewResultItem;
};
CHSI.Forms.Results.SortResults = function () {

    CHSI.Forms.ResultSetValues.sort(function (a, b) {
        if (a.StructureColumnID < b.StructureColumnID)
        { return -1; }
        else if (a.StructureColumnID > b.StructureColumnID)
        { return 1; }
        else if (a.ValueIndex < b.ValueIndex) {
            return -1;
        }
        else if (a.ValueIndex > b.ValueIndex) {
            return 1;
        }
        else {
            return 0;
        }

    });

};