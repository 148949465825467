module PL.UW.LossHistory.Views {
    export class xMod_LossHistoryEdit_PriorCoverageDetail {
        $classCode: JQuery;
        $state: JQuery;
        $payroll: JQuery;        

        constructor() {
            this.$classCode = $('#jxClassCode');
            this.$state = $('#jxState');
            this.$payroll = $('#jxPayroll');            
        }
    }
}  