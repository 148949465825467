

CHSI.Claims.ClaimsSearch = {
    Init: function () {
        
        var Template_Results = $('#Results_Template').html();
        this.Templates.Results = Handlebars.compile(Template_Results);
        var Template_Filters = $('#Filters_Template').html();
        this.Templates.Filters = Handlebars.compile(Template_Filters);
        this.Events.Global();
        this.Events.ConstructFilters(this.Cache.Filters);
    },
    Templates: {

    },
    Cache: {
        Filters: [],
        FilterObject: {
            FilterIndex: 0,            
            LeftFilterValue: 'initial',
            RightFilterValue: {
                Value1: null,
                Value2: null,
                OperatorValue: null
            },
            RightFilterType: 'select',
            RightFilterName: 'initial',
            OptionFilterValue: null
        },
        Results: {
            Coverage: null,
            Client: 0,
            ClaimType: null,
            ClaimNo: 0,
            IncidentDate: null,
            Claimant: null,
            Status: '',
            Incurred: null
        }
    },
    BindPresentationLayer: {
        BindFilters: function () {
            $('#FiltersContainer').html(CHSI.Claims.ClaimsSearch.Templates.Filters({ Filters: CHSI.Claims.ClaimsSearch.Cache.Filters }));            
        },
        BindResults: function () {
            $('#ResultsContainer').html(CHSI.Claims.ClaimsSearch.Templates.Results({ Results: CHSI.Claims.ClaimsSearch.Cache.Results }));
        }
    },
    Events: {
        Global: function () {
            $('#FiltersContainer').on('change', '.SelectLeftFilter', function () {
                var FilterType = $(this).find('option:selected').data('filtertype'),
                    FilterName = $(this).val(),
                    NumberOfFilters = $('.Filters').length;

                for (i = 0; i < NumberOfFilters; i++) {
                    var FilterID = '#Filter_' + i;                        
                        var RightFilter = $(FilterID + ' > .RightFilter');

                        RightFilter.data('filtertype', FilterType);                        
                        RightFilter.data('filtername', FilterName);                        
                }

                var CurrentFilterValues = CHSI.Claims.ClaimsSearch.Events.SaveFilterValues();                
                CHSI.Claims.ClaimsSearch.Events.ConstructFilters(CurrentFilterValues);
            });
            $('.SelectOptionFilter').on('change', function () {
                // show new row.
                CHSI.Claims.ClaimsSearch.Events.ConstructFilters();
            });

            $('#jxSearch').on('click', function () {
                CHSI.Claims.ClaimsSearch.Calls.GetResults();
            });
        },
        AddFilter: function (FilterName) {
           return this.FilterTree(FilterName);
        },
        SaveFilterValues: function () {            
            var NumberOfFilters = $('.Filters').length;

            for (i = 0; i < NumberOfFilters; i++) {

                var FilterObject = CHSI.Claims.ClaimsSearch.Cache.FilterObject;
                var FilterID = '#Filter_' + i,
                    LeftFilter = FilterID + ' > .LeftFilter',
                    RightFilter = FilterID + ' > .RightFilter',
                    OptionFilter = FilterID + ' > .OptionFilter',
                    FilterType = $(RightFilter).data('filtertype'),
                    FilterName = $(RightFilter).data('filtername');

                FilterObject.FilterIndex = i;
                FilterObject.RightFilterType = FilterType;
                FilterObject.RightFilterName = FilterName;
                FilterObject.LeftFilterValue = $(LeftFilter + ' > .SelectLeftFilter').val();
                
                    switch (FilterObject.RightFilterType)
                    {                                              
                        case 'date':
                            FilterObject.RightFilterValue.Value1 = $(RightFilter + ' > .FilterValue1').val();
                            FilterObject.RightFilterValue.Value2 = $(RightFilter + ' > .FilterValue2').val();
                            FilterObject.RightFilterValue.OperatorValue = $(RightFilter + ' > .OperatorValue').val();
                            break;                    
                        case 'text':
                            FilterObject.RightFilterValue.Value1 = $(RightFilter + ' > .FilterValue1').val();
                            FilterObject.RightFilterValue.OperatorValue = $(RightFilter + ' > .OperatorValue').val();
                            break;
                        case 'select':
                            FilterObject.RightFilterValue.Value1 = $(RightFilter + ' > .FilterValue1').val();
                            break;
                        case 'checkbox':
                            // TBA
                            break;
                        case 'radio':
                            // TBA
                            break;
                    }
                
                FilterObject.OptionFilterValue = $(OptionFilter + ' > .SelectOptionFilter').val();
                CHSI.Claims.ClaimsSearch.Cache.Filters.push(FilterObject)                
            }
            return CHSI.Claims.ClaimsSearch.Cache.Filters;
        },
        ConstructFilters: function (FilterValues) {
            if (FilterValues) {
                CHSI.Claims.ClaimsSearch.Cache.Filters.push(FilterValues);
                CHSI.Claims.ClaimsSearch.BindPresentationLayer.BindFilters();
            } else {
                CHSI.Claims.ClaimsSearch.BindPresentationLayer.BindFilters();
            }                        
        },
        FilterBuilders: {
            DateFilter: function () {                
                var HTML = [];
                HTML.push('<select class="OperatorValue" style="width: 100px; vertical-align: middle;">');
                HTML.push('<option value="between">Between</option>');
                HTML.push('<option value="equals">Equals</option>');
                HTML.push('<option value="before">Before</option>');
                HTML.push('<option value="after">After</option>');
                HTML.push('</select>');
                HTML.push('<input class="FilterValue1" style="width: 70px; vertical-align: middle;" />');
                HTML.push('<b>AND</b>');
                HTML.push('<input class="FilterValue2" style="width: 70px; vertical-align: middle;" />');
                return HTML;                
            },
            TextFilter: function () {
                var HTML = [];
                HTML.push('<select class="OperatorValue" style="width: 100px; vertical-align: middle;">');
                HTML.push('<option value="begins with">Begins With</option>');
                HTML.push('<option value="ends with">Ends With</option>');
                HTML.push('<option value="contains">Contains</option>');
                HTML.push('<option value="equals">Equals</option>');
                HTML.push('</select>&nbsp;');
                HTML.push('<input type="text" class="FilterValue1" style="width: 210px; vertical-align: middle;" />');
                return HTML;
            },
            NumericTextFilter: function () {
                var HTML = [];
                HTML.push('<select class="OperatorValue" style="width: 100px; vertical-align: middle;">');
                HTML.push('<option value="equals">Equals</option>');
                HTML.push('<option value="less than">Less Than</option>');
                HTML.push('<option value="greater than">Greater Than</option>');
                HTML.push('</select>&nbsp;');
                HTML.push('<input type="text" class="FilterValue1" style="width: 210px; vertical-align: middle;" />');
                return HTML;
            }, // add currency validation
            GroupIDFilter: function () {
                var HTML = [];
                var GroupIDs = null;

                CHSI.Claims.ClaimsSearch.Calls.GetGroups(GetGroups);
                GroupIDs = function GetGroups(GroupIDs) {
                    return GroupIDs;
                }

                HTML.push('<select>');
                $.each(GroupIDs, function (Index, Value) {
                    HTML.push('<option value="' + Value + '">' + Value + '</option>');
                });
                HTML.push('</select>');

                return HTML;
            },
            ClientIDFilter: function () {
                var HTML = [];
                HTML.push('<span style="color:maroon; vertical-align: middle;">No Client Selected</span>');
                HTML.push('<a style="background:#eee;border-radius:4px;padding:3px;border:1px solid #aaa; vertical-align: middle;"   href="Javascript: ChooseClient()" >Select Client</a>');
                HTML.push('<input type="hidden" value="" class="FilterValue1">')
                return HTML;
            },
            PolicyPeriodFilter: function () {
                var HTML = [];
                var PolicyPeriods = null;

                CHSI.Claims.ClaimsSearch.Calls.GetPolicyPeriods(GetPolicyPeriods);
                PolicyPeriods = function GetPolicyPeriods(PolicyPeriods) {
                    return PolicyPeriods;
                }

                HTML.push('<select>');
                $.each(PolicyPeriods, function (Index, Value) {
                    HTML.push('<option value="' + Value + '">' + Value + '</option>');
                });                
                HTML.push('</select>');

                return HTML;
            },
            CoverageFilter: function () {
                var HTML = [];
                var Coverages = null;

                CHSI.Claims.ClaimsSearch.Calls.GetCoverageLines(GetCoverageLines);
                function GetCoverageLines(Coverages) {
                    return Coverages;
                }

                HTML.push('<select style="width: 150px; vertical-align: middle;" class="FilterValue1 SelectedCoverage">');
                $.each(Coverages, function (Index, Value) {
                    HTML.push('<option value="' + Value + '">' + Value + '</option>');
                });                                
                HTML.push('</select>')

                return HTML;
            },
            ClaimClassFilter: function () {
                var HTML = [];
                var ClaimTypes = null;

                CHSI.Claims.ClaimsSearch.Calls.GetClaimTypesByGroupLineID(GetClaimTypes);
                PolicyPeriods = function GetClaimTypes(ClaimTypes) {
                    return ClaimTypes;
                }

                HTML.push('<select>');
                $.each(ClaimTypes, function (Index, Value) {
                    HTML.push('<option value="' + Value + '">' + Value + '</option>');
                });
                HTML.push('</select>');

                return HTML;
            },
            ClaimTypeFilter: function () {
                var HTML = [];
                if (CHSI.Claims.ClaimSearch.GroupLineID <= 0) {
                    HTML.push('<span style="color: red;">Please select a coverage type.</span>');
                } else {
                    CHSI.Claims.ClaimSearch.GetClaimTypesByGroupLineID(CHSI.Claims.ClaimSearch.GroupLineID, BuildClaimTypeList);

                    function BuildClaimTypeList() {
                        HTML.push(CHSI.Claims.ClaimSearch.BuildClaimTypeList(index, selectedOption));
                    }
                }
                return HTML;
            }, // alot of work
            StatusFilter: function () {
                var HTML = [];
                HTML.push('<select style="width: 150px; vertical-align: middle;" class="FilterValue1">')
                HTML.push('<option value="open">Open</option>');
                HTML.push('<option value="closed">Closed</option>');
                HTML.push('</select>');
                return HTML;
            }
        },
        FilterTree: function (FilterName) {
            var HTML = [];            
            switch (FilterName) {
                case 'initial':
                    HTML = HTML.push('<select value=""> </select>');
                    break;
                case 'groupid':
                    HTML = this.FilterBuilders.GroupIDFilter();
                    break;
                case 'clientid':                    
                    HTML = this.FilterBuilders.ClientIDFilter();
                    break;
                case 'policyperiod':
                    HTML = this.FilterBuilders.PolicyPeriodFilter();
                    break;
                case 'coverage':
                    HTML = this.FilterBuilders.CoverageFilter();
                    break;
                case 'claim class':                                        
                    HTML = this.FilterBuilders.ClaimClassFilter();
                    break;
                case 'claim type':                    
                    HTML = this.FilterBuilders.ClaimTypeFilter();
                    break;
                case 'date of incident':
                    HTML = this.FilterBuilders.DateFilter();
                    break;
                case 'date opened':
                    HTML = this.FilterBuilders.DateFilter();
                    break;
                case 'date closed':
                    HTML = this.FilterBuilders.DateFilter();
                    break;
                case 'claim number':
                    HTML = this.FilterBuilders.TextFilter();
                    break;
                case 'claimant':
                    HTML = this.FilterBuilders.TextFilter();
                    break;
                case 'county':
                    HTML = this.FilterBuilders.TextFilter();
                    break;                
                case 'paid':
                    HTML = this.FilterBuilders.NumericTextFilter();
                    break;
                case 'reserves':
                    HTML = this.FilterBuilders.NumericTextFilter();
                    break;
                case 'incurred':
                    HTML = this.FilterBuilders.NumericTextFilter();
                    break;
                case 'status':
                    HTML = this.FilterBuilders.StatusFilter();
                    break;
            }                       
            return HTML;
        }    
    },
    Calls: {
        GetGroups: function (successFunction) {
            var AjaxURL = '/api/CRM/Group/GetActiveGroups';
            $.ajax({
                type: "GET",
                url: AjaxURL,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                cache: false,
                success: function (e) {                    
                    if (successFunction) {
                        successFunction(e);
                    }
                },
                error: function (e) {
                    CHSI.Common.Log(e);
                }
            });
        },
        GetCoverageLines: function (successFunction) {
            var AjaxURL = '/api/ClaimSystem/Claim/GetCoverageTypes';
            $.ajax({
                type: "GET",
                url: AjaxURL,                
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                cache: false,
                success: function (e) {                    
                    if (successFunction) {
                        successFunction(e);
                    }
                },
                error: function (e) {
                    CHSI.Common.Log(e);
                }
            });
        },
        GetPolicyPeriods: function (successFunction) {
            var AjaxURL = '/api/UW/UW/GetPolicyYears';
            $.ajax({
                type: "GET",
                url: AjaxURL,                
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                cache: false,
                success: function (e) {                    
                    if (successFunction) {
                        successFunction(e);
                    }
                },
                error: function (e) {
                    CHSI.Common.Log(e);
                }
            });
        },
        GetClaimTypes: function (successFunction) {
            var AjaxURL = '/api/ClaimSystem/Claim/GetClaimTypes';
            $.ajax({
                type: "GET",
                url: AjaxURL,                
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                cache: false,
                success: function (e) {                    
                    if (successFunction) {
                        successFunction(e);
                    }
                },
                error: function (e) {
                    CHSI.Common.Log(e);
                }
            });
        },
        GetClaimTypesByGroupLineID: function (successFunction) {
            var AjaxURL = '/api/ClaimSystem/Claim/GetClaimTypesByGroupLineID?GroupLineID=' + GroupLineID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                cache: false,
                success: function (e) {                                        
                    if (successFunction) {
                        successFunction(e);
                    }
                },
                error: function (e) {
                    CHSI.Common.Log(e);
                }
            });
        },
        GetResults: function (successFunction) {
            var AjaxURL = '/api/ClaimSystem/Claim/GetClaimSearchResults';
            jQuery.ajax({
                type: 'GET',
                url: AjaxURL,                
                contentType: 'application/json; charset=utf-8',
                dataType: "json",
                cache: false,
                success: function (e) {                    
                    if (successFunction) {
                        successFunction(e);
                    }
                },
                error: function (e) {
                    CHSI.Common.Log(e);
                }
            });
        }
    }
}