var Template_Attachments = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <tr>\r\n        <td><a href=\"/Chsifilehandler.axd?fileid="
    + escapeExpression(((helper = (helper = helpers.FileID || (depth0 != null ? depth0.FileID : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"FileID","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">"
    + escapeExpression(((helper = (helper = helpers.FileName || (depth0 != null ? depth0.FileName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"FileName","hash":{},"data":data}) : helper)))
    + "</a></td>\r\n    </tr>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "﻿\r\n<table cellpadding=\"2\" cellspacing=\"0\">\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.Attachments : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</table>";
},"useData":true});