var Template_ClientDetailsForm = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "                <option value=\""
    + escapeExpression(((helper = (helper = helpers.GroupName || (depth0 != null ? depth0.GroupName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"GroupName","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depths[1] != null ? depths[1].Client : depths[1])) != null ? stack1.GroupID : stack1), (depth0 != null ? depth0.GroupName : depth0), {"name":"compare","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">"
    + escapeExpression(((helper = (helper = helpers.GroupName || (depth0 != null ? depth0.GroupName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"GroupName","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"2":function(depth0,helpers,partials,data) {
  return " selected=\"selected\" ";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "﻿\r\n<table>\r\n    <tr>\r\n        <td style=\"width: 125px;\">&nbsp;</td>\r\n        <td>Click <a id=\"jxAdvancedSearchLink\" href=\"#\" style=\"color: blue; text-decoration: underline;\">here</a> to use the advanced search to look up the company.</td>\r\n    </tr>\r\n    <tr>\r\n        <td>Company Name</td>\r\n        <td>\r\n            <input id=\"uxClientName\" name=\"ClientName\" maxlength=\"200\" style=\"width: 400px;\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.ClientName : stack1), depth0))
    + "\" class=\"ClientData iRequired\" />\r\n            <span class=\"Required\">*</span>\r\n            <input id=\"uxClientID\" name=\"ClientID\" type=\"hidden\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.ClientID : stack1), depth0))
    + "\" class=\"ClientData\" />\r\n        </td>\r\n    </tr>\r\n\r\n    <tr>\r\n        <td>DBA</td>\r\n        <td><input id=\"uxDBA\" name=\"DBA\" maxlength=\"200\" style=\"width: 400px;\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.DBA : stack1), depth0))
    + "\" class=\"ClientData\" /></td>\r\n    </tr>\r\n    <tr>\r\n        <td>Legal Entity Name</td>\r\n        <td><input id=\"uxLegalname\" name=\"LegalName\" maxlength=\"300\" style=\"width: 400px\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.LegalName : stack1), depth0))
    + "\" class=\"ClientData\" /></td>\r\n    </tr>\r\n    <tr>\r\n        <td>Start Date</td>\r\n        <td>\r\n            <input type=\"text\" id=\"uxStart\" name=\"StartDate\" style=\"width: 100px;\" value=\""
    + escapeExpression(((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.StartDate : stack1), {"name":"CHSIDate","hash":{},"data":data})))
    + "\" class=\"ClientData iRequired\" /><span class=\"Required\">*</span>\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>Group</td>\r\n        <td>\r\n            <select id=\"uxGroupID\" name=\"GroupID\" style=\"width: 110px;\" class=\"ClientData\">\r\n                <option value=\"\"></option>\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.Groups : depth0), {"name":"each","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            </select>\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>Governing Class</td>\r\n        <td>\r\n            <input id=\"uxClassCode\" name=\"ClassCode_DetailValue\" style=\"width: 50px;\" maxlength=\"100\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ClassCode : depth0)) != null ? stack1.DetailValue : stack1), depth0))
    + "\" class=\"ClassCodeData\" />\r\n            &nbsp;&nbsp;\r\n            <span id=\"uxPrimaryClassTranslation\" style=\"color: red;\"></span>\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>Primary Contact</td>\r\n        <td>\r\n            <input id=\"uxContactFirst\" name=\"ContactFirst\" style=\"width: 120px;\" maxlength=\"20\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.PrimaryContact : depth0)) != null ? stack1.ContactFirst : stack1), depth0))
    + "\" class=\"PrimaryContactData\" />&nbsp;\r\n            <input id=\"uxContactLast\" name=\"ContactLast\" style=\"width: 120px;\" maxlength=\"30\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.PrimaryContact : depth0)) != null ? stack1.ContactLast : stack1), depth0))
    + "\" class=\"PrimaryContactData\" />\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>Contact Title</td>\r\n        <td><input id=\"uxContacTitle\" name=\"ContactTitle\" style=\"width: 120px;\" maxlength=\"20\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.PrimaryContact : depth0)) != null ? stack1.ContactTitle : stack1), depth0))
    + "\" class=\"PrimaryContactData\" /></td>\r\n    </tr>\r\n    <tr>\r\n        <td>Phone</td>\r\n        <td>\r\n            <input id=\"uxContactPhoneAC\" name=\"Phone1AC\" style=\"width: 30px;\" maxlength=\"3\" onkeypress=\"return isNumberKey(event);\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.PrimaryContact : depth0)) != null ? stack1.Phone1AC : stack1), depth0))
    + "\" class=\"PrimaryContactData\" />&nbsp;\r\n            <input id=\"uxContactPhone\" name=\"Phone1\" style=\"width: 100px;\" maxlength=\"15\" onkeypress=\"return isSSN(event);\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.PrimaryContact : depth0)) != null ? stack1.Phone1 : stack1), depth0))
    + "\" class=\"PrimaryContactData\" />&nbsp;&nbsp;\r\n            ext.&nbsp;&nbsp;<input id=\"uxPhoneExt\" name=\"Phone1Ext\" style=\"width: 50px;\" maxlength=\"10\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.PrimaryContact : depth0)) != null ? stack1.Phone1Ext : stack1), depth0))
    + "\" class=\"PrimaryContactData\" />\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n\r\n        <td>Fax</td>\r\n        <td>\r\n            <input id=\"uxContactFaxAC\" name=\"FaxAC\" style=\"width: 30px;\" maxlength=\"3\" onkeypress=\"return isNumberKey(event);\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.PrimaryContact : depth0)) != null ? stack1.FaxAC : stack1), depth0))
    + "\" class=\"PrimaryContactData\" />&nbsp;\r\n            <input id=\"uxContactFax\" name=\"Fax\" style=\"width: 100px;\" maxlength=\"15\" onkeypress=\"return isSSN(event);\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.PrimaryContact : depth0)) != null ? stack1.Fax : stack1), depth0))
    + "\" class=\"PrimaryContactData\" />\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>Email Address</td>\r\n        <td>\r\n            <input id=\"uxEmailAddress\" name=\"Email\" style=\"width: 400px;\" maxlength=\"100\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.PrimaryContact : depth0)) != null ? stack1.Email : stack1), depth0))
    + "\" class=\"PrimaryContactData\" />\r\n            <input id=\"uxPrimaryContactID\" name=\"ContactID\" type=\"hidden\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.PrimaryContact : depth0)) != null ? stack1.ContactID : stack1), depth0))
    + "\" class=\"PrimaryContactData\" />\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td valign=\"top\">\r\n            Billing Address\r\n            <input id=\"uxBillingAddressID\" name=\"BillingAddress_AddressID\" type=\"hidden\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.BillingAddress : stack1)) != null ? stack1.AddressID : stack1), depth0))
    + "\" class=\"BillingAddressData\" />\r\n        </td>\r\n        <td id=\"uxBillingAddressFields\">\r\n            <input id=\"uxBillingAddress\" name=\"BillingAddress_Address1\" style=\"width: 400px;\" maxlength=\"50\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.BillingAddress : stack1)) != null ? stack1.Address1 : stack1), depth0))
    + "\" class=\"BillingAddressData iRequired\" /><span class=\"Required\">*</span><br />\r\n            <input id=\"uxBillingAddress2\" name=\"BillingAddress_Address2\" style=\"width: 400px; margin-top: 4px;\" maxlength=\"50\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.BillingAddress : stack1)) != null ? stack1.Address2 : stack1), depth0))
    + "\" class=\"BillingAddressData\" /><br />\r\n            City &nbsp; <input id=\"uxBillingAddressCity\" name=\"BillingAddress_City\" style=\"width: 177px; margin-top: 4px;\" maxlength=\"30\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.BillingAddress : stack1)) != null ? stack1.City : stack1), depth0))
    + "\" class=\"BillingAddressData iRequired\" /><span class=\"Required\">*</span>&nbsp;&nbsp;\r\n            State &nbsp; <input id=\"uxBillingAddressState\" name=\"BillingAddress_State\" style=\"width: 20px; margin-top: 4px;\" maxlength=\"2\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.BillingAddress : stack1)) != null ? stack1.State : stack1), depth0))
    + "\" class=\"BillingAddressData iRequired\" /><span class=\"Required\">*</span>&nbsp;&nbsp;\r\n            Zip &nbsp; <input id=\"uxBillingAddressZip\" name=\"BillingAddress_Zip\" style=\"width: 50px; margin-top: 4px;\" maxlength=\"10\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.BillingAddress : stack1)) != null ? stack1.Zip : stack1), depth0))
    + "\" onkeypress=\"return isUSZip(event);\" class=\"BillingAddressData iRequired\" /><span class=\"Required\">*</span>\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td valign=\"top\">\r\n            Mailing Address\r\n            <input id=\"uxMailingAddressID\" name=\"MailingAddress_AddressID\" type=\"hidden\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.MaillingAddress : stack1)) != null ? stack1.AddressID : stack1), depth0))
    + "\" class=\"MailingAddressData\" />\r\n            <br />\r\n            <label id=\"uxSameAsBilling\"><input type=\"checkbox\" /> Same as Billing</label>\r\n        </td>\r\n        <td id=\"uxMailingAddressFields\">\r\n            <input id=\"uxMailingAddress\" name=\"MailingAddress_Address1\" style=\"width: 400px;\" maxlength=\"50\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.MailingAddress : stack1)) != null ? stack1.Address1 : stack1), depth0))
    + "\" class=\"MailingAddressData iRequired\" /><span class=\"Required\">*</span><br />\r\n            <input id=\"uxMailingAddress2\" name=\"MailingAddress_Address2\" style=\"width: 400px; margin-top: 4px;\" maxlength=\"50\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.MailingAddress : stack1)) != null ? stack1.Address2 : stack1), depth0))
    + "\" class=\"MailingAddressData\" /><br />\r\n            City &nbsp; <input id=\"uxMailingAddressCity\" name=\"MailingAddress_City\" style=\"width: 177px; margin-top: 4px;\" maxlength=\"30\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.MailingAddress : stack1)) != null ? stack1.City : stack1), depth0))
    + "\" class=\"MailingAddressData iRequired\" /><span class=\"Required\">*</span>&nbsp;&nbsp;\r\n            State &nbsp; <input id=\"uxMailingAddressState\" name=\"MailingAddress_State\" style=\"width: 20px; margin-top: 4px;\" maxlength=\"2\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.MailingAddress : stack1)) != null ? stack1.State : stack1), depth0))
    + "\" class=\"MailingAddressData iRequired\" /><span class=\"Required\">*</span>&nbsp;&nbsp;\r\n            Zip &nbsp; <input id=\"uxMailingAddressZip\" name=\"MailingAddress_Zip\" style=\"width: 50px; margin-top: 4px;\" maxlength=\"10\" onkeypress=\"return isUSZip(event);\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.MailingAddress : stack1)) != null ? stack1.Zip : stack1), depth0))
    + "\" class=\"MailingAddressData iRequired\" /><span class=\"Required\">*</span>\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td valign=\"top\">\r\n            Shipping Address<input id=\"uxShippingAddressID\" name=\"ShippingAddress_AddressID\" type=\"hidden\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.ShippingAddress : stack1)) != null ? stack1.AddressID : stack1), depth0))
    + "\" class=\"ShippingAddressData\" />\r\n            <br />\r\n            <label id=\"uxSameAsMailing\"><input type=\"checkbox\" /> Same as Mailing</label>\r\n        </td>\r\n        <td id=\"uxShippingAddressFields\">\r\n            <input id=\"uxShippingAddress\" name=\"ShippingAddress_Address1\" style=\"width: 400px;\" maxlength=\"50\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.ShippingAddress : stack1)) != null ? stack1.Address1 : stack1), depth0))
    + "\" class=\"ShippingAddressData\" /><br />\r\n            <input id=\"uxShippingAddress2\" name=\"ShippingAddress_Address2\" style=\"width: 400px; margin-top: 4px;\" maxlength=\"50\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.ShippingAddress : stack1)) != null ? stack1.Address2 : stack1), depth0))
    + "\" class=\"ShippingAddressData\" /><br />\r\n            City &nbsp; <input id=\"uxShippingAddressCity\" name=\"ShippingAddress_City\" style=\"width: 177px; margin-top: 4px;\" maxlength=\"30\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.ShippingAddress : stack1)) != null ? stack1.City : stack1), depth0))
    + "\" class=\"ShippingAddressData\" />&nbsp;&nbsp;\r\n            State &nbsp; <input id=\"uxShippingAddressState\" name=\"ShippingAddress_State\" style=\"width: 20px; margin-top: 4px;\" maxlength=\"2\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.ShippingAddress : stack1)) != null ? stack1.State : stack1), depth0))
    + "\" class=\"ShippingAddressData\" />&nbsp;&nbsp;\r\n            Zip &nbsp; <input id=\"uxShippingAddressZip\" name=\"ShippingAddress_Zip\" style=\"width: 50px; margin-top: 4px;\" maxlength=\"10\" onkeypress=\"return isUSZip(event);\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.Client : depth0)) != null ? stack1.ShippingAddress : stack1)) != null ? stack1.Zip : stack1), depth0))
    + "\" class=\"ShippingAddressData\" />\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>FEIN</td>\r\n        <td><input id=\"uxFEIN\" name=\"FEIN_DetailValue\" style=\"width: 100px;\" maxlength=\"10\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.FEIN : depth0)) != null ? stack1.DetailValue : stack1), depth0))
    + "\" class=\"FEINData iRequired\" /><span class=\"Required\">*</span></td>\r\n    </tr>\r\n    <tr>\r\n        <td>Business Type</td>\r\n        <td>\r\n            <select id=\"uxBusinessType\" style=\"width: 250px;\" name=\"BusinessType_DetailValue\" class=\"BusinessTypeData\">\r\n                <option value=\"\" />\r\n                <option value=\"Individual\" ";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.BusinessType : depth0)) != null ? stack1.DetailValue : stack1), "Individual", {"name":"compare","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">Individual</option>\r\n                <option value=\"Corporation\" ";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.BusinessType : depth0)) != null ? stack1.DetailValue : stack1), "Corporation", {"name":"compare","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">Corporation</option>\r\n                <option value=\"Limited Corp.\" ";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.BusinessType : depth0)) != null ? stack1.DetailValue : stack1), "Limited Corp.", {"name":"compare","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">Limited Corp.</option>\r\n                <option value=\"Partnership\" ";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.BusinessType : depth0)) != null ? stack1.DetailValue : stack1), "Partnership", {"name":"compare","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">Partnership</option>\r\n                <option value=\"Subchapter &quot;S&quot; Corp\" ";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.BusinessType : depth0)) != null ? stack1.DetailValue : stack1), "Subchapter &quot;S&quot; Corp", {"name":"compare","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">Subchapter &quot;S&quot; Corp.</option>\r\n                <option value=\"Other\" ";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.BusinessType : depth0)) != null ? stack1.DetailValue : stack1), "Other", {"name":"compare","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">Other</option>\r\n            </select>\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>Date Opened</td>\r\n        <td><input id=\"uxDateOpened\" name=\"DateOpened_DetailValue\" style=\"width: 100px;\" maxlength=\"100\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.DateOpened : depth0)) != null ? stack1.DetailValue : stack1), depth0))
    + "\" class=\"DateOpenedData\" /></td>\r\n    </tr>\r\n    <tr>\r\n        <td>Submission Date</td>\r\n        <td><input id=\"uxSubmissionDate\" name=\"SubmissionDate_DetailValue\" style=\"width: 100px;\" maxlength=\"10\" value=\""
    + escapeExpression(((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.SubmissionDate : depth0)) != null ? stack1.DetailValue : stack1), {"name":"CHSIDate","hash":{},"data":data})))
    + "\" class=\"SubmissionDateData\" /></td>\r\n    </tr>\r\n    <tr>\r\n        <td>Full Time EEs</td>\r\n        <td><input id=\"uxFullTimeEEs\" name=\"FullTimeEEs_DetailValue\" style=\"width: 100px;\" maxlength=\"100\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.FullTimeEEs : depth0)) != null ? stack1.DetailValue : stack1), depth0))
    + "\" class=\"FullTimeEEsData\" /></td>\r\n    </tr>\r\n    <tr>\r\n        <td>Part Time EEs</td>\r\n        <td><input id=\"uxPartTimeEEs\" name=\"PartTimeEEs_DetailValue\" style=\"width: 100px;\" maxlength=\"100\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.PartTimeEEs : depth0)) != null ? stack1.DetailValue : stack1), depth0))
    + "\" class=\"PartTimeEEsData\" /></td>\r\n    </tr>\r\n</table>";
},"useData":true,"useDepths":true});