
CHSI.Common.Dates = {
	addDays: function (myDate, days) {
		return new Date(myDate.getTime() + days * 24 * 60 * 60 * 1000);
	},
	addMonths: function (myDate, days) {
		return new Date(myDate.getTime() + days * 24 * 60 * 60 * 1000);
	},
	addHours: function (myDate, hours) {
	    return new Date(myDate.getTime() + hours * 60 * 60 * 1000);
	},
	addMinutes: function (myDate, minutes) {
		return new Date(myDate.getTime() + minutes * 60 * 1000);
	},
	addSeconds: function (myDate, seconds) {
		return new Date(myDate.getDate() + seconds * 1000);
	},
	addMilliseconds: function (myDate, milliseconds) {
		return new Date(myDate.getDate() + milliseconds);
	},
	setISO8601: function (string) {
		var regexp = "([0-9]{4})(-([0-9]{2})(-([0-9]{2})" +
			"(T([0-9]{2}):([0-9]{2})(:([0-9]{2})(\.([0-9]+))?)?" +
			"(Z|(([-+])([0-9]{2}):([0-9]{2})))?)?)?)?";
		var d = string.match(new RegExp(regexp));

		var offset = 0;
		var date = new Date(d[1], 0, 1);

		if (d[3]) { date.setMonth(d[3] - 1); }
		if (d[5]) { date.setDate(d[5]); }
		if (d[7]) { date.setHours(d[7]); }
		if (d[8]) { date.setMinutes(d[8]); }
		if (d[10]) { date.setSeconds(d[10]); }
		if (d[12]) { date.setMilliseconds(Number("0." + d[12]) * 1000); }
		if (d[14]) {
			offset = (Number(d[16]) * 60) + Number(d[17]);
			offset *= ((d[15] == '-') ? 1 : -1);
		}

		offset -= date.getTimezoneOffset();
		time = (Number(date) + (offset * 60 * 1000));
		return Number(time);
	},
	GetjQueryAJAXDate: function (jsonDate) {
		if (jsonDate == null) {
			return '';
		}
		var DateCheck = new Date(jsonDate);
		if (isNaN(DateCheck.getDate()) == false) {
			return jsonDate;
		}


		if (jsonDate.getMonth) {
			return jsonDate;
		}

		var thedate = new Date(parseInt(jsonDate.substr(6)));

		if (thedate.getFullYear() == 1) {
			return "";
		}
		return (thedate.getMonth() + 1) + '/' + thedate.getDate() + '/' + thedate.getFullYear();
	},
	ConvertDate: function (thedate) {
		if (thedate == '' || thedate == null) {
			return null;
		}
		if (thedate == '1/1/1' || thedate == '1/1/1901' || thedate == '0001-01-01T00:00:00') {
			return null;
		}
		var ConvertedDate = thedate;
		var dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}((T|t)[0-9][0-9]:[0-9][0-9]:[0-9][0-9])|\d{4}-\d{2}-\d{2}((T|t)[0-9][0-9]:[0-9][0-9]:[0-9][0-9])?$/;
		var dateformatwithMS = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}((T|t)[0-9][0-9]:[0-9][0-9]:[0-9][0-9])|\d{4}-\d{2}-\d{2}((T|t)[0-9][0-9]:[0-9][0-9]:[0-9][0-9].[0-9][0-9][0-9])?$/;
		var ProcessDateString = false;
		if (thedate.getMonth == null)
		{ ProcessDateString = true; }
		else {
			if (isNaN(thedate.getMonth()))
			{ ProcessDateString = true; }
		}
		if (ProcessDateString) {

			ConvertedDate = new Date(thedate);
			ProcessDateString = false;
			if (ConvertedDate.getMonth == null)
			{ ProcessDateString = true; }
			else {
				if (isNaN(ConvertedDate.getMonth()))
				{ ProcessDateString = true; }
			}



			if (ProcessDateString) {

				if ((thedate.match(dateformatwithMS))) {
					var splitdate = thedate.split('.')
					thedate = splitdate[0];
				}
				if (!(thedate.match(dateformat))) {
					return null;
				}
				ProcessDateString = false;
				if (ConvertedDate.getMonth == null)
				{ ProcessDateString = true; }
				else {
					if (isNaN(ConvertedDate.getMonth()))
					{ ProcessDateString = true; }
				}

				if (ProcessDateString) {
					ConvertedDate = new Date(CHSI.Dates.setISO8601(thedate));
					if (ConvertedDate.getFullYear() < 1902) {
						return null;
					}
				}
			}

		}

		return ConvertedDate;
	},
	GetShortDate: function (thedate) {
		var ConvertedDate = (thedate == null) ? null : CHSI.Dates.ConvertDate(thedate);
		if (ConvertedDate == null) {
			return '';
		}
		else {
			return ("0" + (ConvertedDate.getUTCMonth() + 1)).slice(-2) + '/' + ("0" + ConvertedDate.getUTCDate()).slice(-2) + '/' + ConvertedDate.getUTCFullYear();
		}
	},
	GetDateTime: function (thedate) {
		var ConvertedDate = CHSI.Dates.ConvertDate(thedate);
		if (ConvertedDate == null) {
			return '';
		}
		return CHSI.Dates.GetShortDate(ConvertedDate) + ' ' + CHSI.Dates.FormatTime(ConvertedDate);

	},
	FormatTime: function (date) {

		var Hour, Minute, Second, Period;
		Second = date.getUTCSeconds();
		Hour = date.getUTCHours();
		Minute = date.getUTCMinutes();
		Period = 'AM';
		if (Hour > 11) {
			Hour = Hour - 12;
			Period = 'PM';
		}
		if (Hour == 0) { Hour = 12 };
		return Hour + ':' + Minute + ':' + Second + ' ' + Period;
	}
}