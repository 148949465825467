module PL.Claims.Models {

    export class FormField {

        FormFieldID: number;
        FormTypeID: number;
        FormCategoryID: number;
        Status: string;
        FieldName: string;
        LongName: string;
        ToolTipText: string;
        Required: boolean;
        FieldType: number;
        FieldOptions: any[];
        FieldLogic: string;
        DataType: number;
        DataSize: number;
        GridShow: boolean;
        GridSortOrder: number;
        Summarize: boolean;
        PortalReadOnly: boolean;
        FormShow: boolean;
        FormEditable: boolean;
        FormSortOrder: number;
        DisplayFormat: string;
        CategoryName: string;
        CreateUser: string;
        CreateTmsp: Date;
        UpdateUser: string;
        UpdateTmsp: Date;
        isExpanded: boolean = false;
        Attachments: PL.Claims.Models.ClaimIncidentAttachments[];

        _hasError: boolean;

        constructor(field?: FormField) {

            this.FormFieldID = field == undefined ? null : field.FormFieldID;
            this.FormTypeID = field == undefined ? null : field.FormTypeID;
            this.FormCategoryID = field == undefined ? null : field.FormCategoryID;
            this.Status = field == undefined ? null : field.Status;
            this.FieldName = field == undefined ? null : field.FieldName;
            this.LongName = field == undefined ? null : field.LongName;
            this.ToolTipText = field == undefined ? null : field.ToolTipText;
            this.Required = field == undefined ? null : field.Required;
            this.FieldType = field == undefined ? null : field.FieldType;
            this.FieldOptions = field == undefined ? null : field.FieldOptions;
            this.FieldLogic = field == undefined ? null : field.FieldLogic;
            this.DataType = field == undefined ? null : field.DataType;
            this.DataSize = field == undefined ? null : field.DataSize;
            this.GridShow = field == undefined ? null : field.GridShow;
            this.GridSortOrder = field == undefined ? null : field.GridSortOrder;
            this.Summarize = field == undefined ? null : field.Summarize;
            this.PortalReadOnly = field == undefined ? null : field.PortalReadOnly;
            this.FormShow = field == undefined ? null : field.FormShow;
            this.FormEditable = field == undefined ? null : field.FormEditable;
            this.FormSortOrder = field == undefined ? null : field.FormSortOrder;
            this.DisplayFormat = field == undefined ? null : field.DisplayFormat;
            this.CategoryName = field == undefined ? null : field.CategoryName;
            this.CreateUser = field == undefined ? null : field.CreateUser;
            this.CreateTmsp = field == undefined ? null : field.CreateTmsp;
            this.UpdateUser = field == undefined ? null : field.UpdateUser;
            this.UpdateTmsp = field == undefined ? null : field.UpdateTmsp;
            this.isExpanded = field == undefined ? null : field.isExpanded;
            this.Attachments = new Array<PL.Claims.Models.ClaimIncidentAttachments>();
            
            if (field != undefined && field.Attachments) {
                field.Attachments.forEach((faO, faI) => {

                    this.Attachments.push(new ClaimIncidentAttachments(faO));

                });
            }

        }

        getFormFields(formFieldID?: number, formCategoryID?: number): JQueryDeferred<FormField[]> {
            var $result = $.Deferred();
            PL.Claims.APIs.FormTypeRepo.getFormFields(this.FormTypeID, formFieldID, formCategoryID).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        save(): JQueryDeferred<number> {
            var $result = $.Deferred();
            PL.Claims.APIs.FormTypeRepo.saveFormTypeField(this).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        saveFormFields(formFields: FormField[]): JQueryDeferred<FormField[]> {
            var $result = $.Deferred();
            PL.Claims.APIs.FormTypeRepo.saveFormTypeFields(formFields).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        deleteField(): JQueryDeferred<any> {
            var $result = $.Deferred();
            PL.Claims.APIs.FormTypeRepo.deleteFormField(this.FormFieldID, this.FormTypeID).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }   

        static filterFieldsByCategoryID(formFields: FormField[], formCategoryID: number): FormField[] {
            var fields = new Array<FormField>();

            formFields.forEach((ffO, ffI, ff) => {

                if (ffO.FormCategoryID == formCategoryID) {
                    fields.push(ffO);
                }

            });

            return fields;

        }

        static filterAllFieldsByCategory(formCategories: FormCategory[], formFields: FormField[]): FormCategory[] {            

            formCategories.forEach((fcO, fcI) => {

                fcO.FormFields = new Array<FormField>();

                formFields.forEach((ffO, ffI) => {

                    if (ffO.FormCategoryID == fcO.FormCategoryID) {

                        fcO.FormFields.push(ffO);

                    }

                });

            });

            return formCategories;
        }
    }

} 