
CHSI.UW.Narrative = {
    Init: function (QuoteID) {
        this.Templates.Document = CHSITemplates.Template_QuoteNarrative;
        this.Events.Global();
        this.Calls.GetQuoteInfo();

    },
    Templates: {

    },
    Cache: {
        NarrativeObject: null
        , QuoteID: 0
        , ClientID: 0
        , GroupID: ''
        , Editable: 'Y'
    },
    BindPresentationLayer: function () {
        $('#jxNarrativeContainer').html(this.Templates.Document({ Narrative: this.Cache.NarrativeObject }));
        CHSI.UW.MuniQuote.DisablePage(this.Cache.Editable);
    },
    Events: {
        Global: function () {
            $('#jxNarrativeContainer').on('click', '#jxDeleteNarrative', function () {
                var Button = $(this);
                var NarrativeID = Button.data('narrativeid');
                var r = confirm('Are you sure you want to delete this narrative?');
                if (r) {
                    CHSI.UW.Narrative.Calls.DeleteNarrative(NarrativeID);
                }
            });

            $('#jxNarrativeContainer').on('click', '#jxEditNarrative', function () {
                var Button = $(this);
                var NarrativeTypeID = Button.data('narrativetypeid');
                var NarrativeID = Button.data('narrativeid');
                var ShowEditNarrativeURL = '/UW/Modals//QuoteNarrativeEditor.aspx?QuoteID=' + CHSI.UW.Narrative.Cache.QuoteID + '&NarrativeTypeID=' + NarrativeTypeID + '&NarrativeID=' + NarrativeID;
                ShowPopup("EditNarrative", ShowEditNarrativeURL, "Edit Narrative", 700, 480, CHSI.UW.Narrative.Calls.GetNarrative, false);

            });

            $('#jxSaveNext').on('click', function () {
                CHSI.Common.Navigation.NavigateToNextTab();
            });

            $('#jxCancel').on('click', function () {
                CHSI.Common.Navigation.NavigateToReturnURL();
            });
        },
        GetNarrativeFromCollection: function (NarrativeID) {
            var Narrative = null;
            for (var i = 0; i < CHSI.UW.Narrative.Cache.NarrativeObject.length; i++) {
                if (CHSI.UW.Narrative.Cache.NarrativeObject[i].Narrative && CHSI.UW.Narrative.Cache.NarrativeObject[i].Narrative.NarrativeID == NarrativeID) {
                    Narrative = CHSI.UW.Narrative.Cache.NarrativeObject[i].Narrative;
                    break;
                }
            }

            return Narrative;

        }
    },
    Calls: {
        GetQuoteInfo: function () {
            var Options = {};              
            Options.URL = '/api/UW/MuniQuote/GetBasicQuote?QuoteID=' + CHSI.UW.Narrative.Cache.QuoteID;            
            Options.Success = function (e) {
                if (e.QuoteStatus && e.QuoteStatus.Editable == 'N') {
                    CHSI.UW.Narrative.Cache.Editable = 'N';
                }
                CHSI.UW.Narrative.Calls.GetNarrative();
                var SecOptions = {},
                    isVerified = false;
                SecOptions.PermissionID = 14;
                SecOptions.Success = function (v) {
                    isVerified = v;

                    if (isVerified) {
                        CHSI.MuniUW.MenuController(e, '#jxMenuContainer');
                    }
                }

                CHSI.Security.HasPermission(SecOptions);

            }
            CHSI.Common.Ajax.Get(Options);
        },

        GetNarrative: function () {
            var Options = {};
            Options.URL = '/api/UW/Narrative/GetQuoteNarrativeTypes?QuoteID=' + CHSI.UW.Narrative.Cache.QuoteID;          
            Options.Error = CHSI.DOM.ProcessAjaxError;
            Options.Success = function (e) {
                CHSI.UW.Narrative.Cache.NarrativeObject = e;
                CHSI.UW.Narrative.Cache.NarrativeObject.QuoteID = CHSI.UW.Narrative.Cache.QuoteID;
                CHSI.UW.Narrative.BindPresentationLayer();
            };
            CHSI.Common.Ajax.Get(Options);
        },
        DeleteNarrative: function (NarrativeID) {
            var Options = {};
            Options.URL = '/api/UW/Narrative/SaveQuoteNarrative';
            var Narrative = CHSI.UW.Narrative.Events.GetNarrativeFromCollection(NarrativeID);
            Narrative.Status = 'Deleted';
            Narrative.CurrentUser = '';
            Options.Data = Narrative;
            Options.Error = CHSI.DOM.ProcessAjaxError;
            Options.Success = function (e) {
                CHSI.UW.Narrative.Calls.GetNarrative();
            };
            CHSI.Common.Ajax.Post(Options);
        }


    }

}