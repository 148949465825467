module PL.CRM.APIs {

    export class Location {

        private static _routePrefix = '/api/CRM/Location/';

        public static getLocations(clientID: number): JQueryDeferred<CRM.Models.Location[]> {
            var result = $.Deferred();
            $.get(this._routePrefix + 'GetLocationsByClientID?ClientID=' + clientID).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

    }

} 