declare function ShowPopup(WindowID?, WinURL?, WinTitle?, width?, height?, CloseFunction?, ismodal?, isWindowLocked?, ShowHelp?, SuccessFunction?, panelContent?);
declare function closeThis(closeFunction);

module PL.Que {

    export class FormPicker {        

        ParentContext: Que.Controllers.FormController;

        constructor() {
            this.ParentContext = new Que.Controllers.FormController;
        }

        init(): void {

            this.bindDataStructuresList();

            this.events();

        }

        events(): void {
            var _self = this;
            
            $('#jxSave').prop('disabled', true);
            
            $('#jxSave').on('click', function () {
                var newFormObject: PL.Que.Models.QuestionnaireBase = new PL.Que.Models.QuestionnaireBase;                

                PL.Que.APIs.FormAPI.saveCompleteFormObject(_self.preperFields(), null, function (data) {                    
                    newFormObject.FormID = data.FormID;                
                    parent.window.location.href = parent.window.location.href + '&formid=' + newFormObject.FormID;
                    closeThis(true);                                                                
                });
                
            });

            $('#jxCancel').on('click', function () {
                closeThis(false);
            });

            $('#jxFormTitle').on('keyup', function () {

                if ($(this).val() != '') {
                    $('#jxSave').prop('disabled', false);
                } else {
                    $('#jxSave').prop('disabled', true);
                }

            });
        }

        preperFields(): PL.Que.Models.QuestionnaireBase {
                var formObject: PL.Que.Models.QuestionnaireBase = new PL.Que.Models.QuestionnaireBase;

                formObject.FormID = null;
                formObject.Title = $('#jxFormTitle').val();
                formObject.DataStructureID = $('#jxDataStructureSelect').val();
                formObject.ActiveDate = GUI.DateTime.getTodaysDate();
                formObject.InactiveDate = GUI.DateTime.getTodaysDate();
                formObject.FormDescription = $('#jxFormDescritption').val();
                formObject.Status = 'Active';
                formObject.ReportID = null;
                formObject.DesignTemplateID = $('#jxFormType').val();

            return formObject;
        }

        bindDataStructuresList(): void {
            PL.Que.Controllers.DataStructureController.bindDataStructuresList('jxDataStructureSelectContainer');
        }

    }

} 