module PL.Que.Models.Design {

    export class ActionBehavior {

        ParentTag: string;
        TargetTag: string;
        ParentObjectID: number;
        TargetObjectID: number;
        ParentObjectType: number;
        TargetObjectType: number;
        ComparisonType: number;
        ComparisonValue: string;
        TargetTrueValue: string;
        TargetFalseValue: string;
        PropertyName: string;
        ActionFormula: string;

    }

}