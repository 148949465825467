
CHSI.HandleBars = new Object();

CHSI.HandleBars.Preparehelpers = function () {
    Handlebars.registerHelper('eachProperty', function (context, options) {
        var ret = "";
        for (var prop in context) {
            ret = ret + options.fn({ property: prop, value: context[prop] });
        }
        return ret;
    });

    Handlebars.registerHelper('eachArray', function (context, options) {
        var ret = "";
        var index = -1;
        var size = 0;
        var isLast = false;
        if (Object.prototype.toString.apply(context) === '[object Array]') {
            size = context.length;
            for (var i = 0; i < context.length; i++) {
                index++;
                isLast = false;
                if (index >= size - 1) {
                    isLast = true;
                }
                ret = ret + options.fn({ property: i, value: context[i], index: index, isLast: isLast, size: size });
            }
        } else {
            for (var prop in context) {
                size++;
            }
            for (var prop in context) {
                index++;
                isLast = false;
                if (index >= size - 1) {
                    isLast = true;
                }
                ret = ret + options.fn({ property: prop, value: context[prop], index: index, isLast: isLast, size: size });
            }
        }

        return ret;
    });
    /** 
* Date formatting helper.
*
* Date helper takes a SC.DateTime and return a formatted string based on the format
* parameter. If no format is given, it uses %c as default.
*
* @param format a format string
*/
    Handlebars.registerHelper('CHSIDate', function (datevalue) {     
       
        return CHSI.Dates.GetShortDate(datevalue);

    });


    Handlebars.registerHelper('isEmailValid', function (email) {
        var ret = "";
        if (CHSI.Common.String.IsEmail(email)) {
            ret = email;
        }

        return ret;
    });

    Handlebars.registerHelper('CHSIDateTime', function (datevalue) {
        return CHSI.Dates.GetDateTime(datevalue);
    });

    /*We want the first of a collection sometimes for creating headers*/
    Handlebars.registerHelper('firstProperty', function (context, options) {
        var ret = "";

        for (var prop in context) {
            ret = ret + options.fn({ property: prop, value: context[prop] });
            return ret;
        }
    });
     
    /* Truncate a string to prevent wrapping in table results */
    Handlebars.registerHelper('TruncateString', function (inStr, length) {
        var returnVal = inStr + '';
        var substrlength = length * 1;
        return returnVal.substring(0, substrlength);
    });

    /* Format Numerical Results as currency with or without decmials */
    Handlebars.registerHelper('FormatCurrency', function (inNumber, showDecimals) {
        return CHSI.Common.Numbers.FormatCurrency(inNumber, showDecimals);
    });
    

    Handlebars.registerHelper("debug", function (optionalValue) {
        CHSI.Common.Log("Current Context");
        CHSI.Common.Log("===============");
        CHSI.Common.Log(this);

        if(optionalValue) {
            CHSI.Common.Log("Value");
            CHSI.Common.Log("===============");
            CHSI.Common.Log(optionalValue);
        }
    });
    
    Handlebars.registerHelper('compare', function (lvalue, rvalue, options) {

        if (arguments.length < 3)
            throw new Error("Handlerbars Helper 'compare' needs 2 parameters");

        operator = options.hash.operator || "==";

        var operators = {
            '==': function (l, r) { return l == r; },
            '===': function (l, r) { return l === r; },
            '!=': function (l, r) { return l != r; },
            '<': function (l, r) { return l < r; },
            '>': function (l, r) { return l > r; },
            '<=': function (l, r) { return l <= r; },
            '>=': function (l, r) { return l >= r; },
            'typeof': function (l, r) { return typeof l == r; }
        }

        if (!operators[operator])
            throw new Error("Handlerbars Helper 'compare' doesn't know the operator " + operator);

        var result = operators[operator](lvalue, rvalue);

        if (result) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }

    });

    Handlebars.registerHelper('select', function (value, options) {
        var $el = $('<select />').html(options.fn(this));
        $el.find('[value=' + value + ']').attr({ 'selected': 'selected' });
        return $el.html();
    });

    Handlebars.registerHelper('StyleRow', function (Index, Even, Odd) {
        ret = (Index % 2 == 0 ? Even : Odd);
        return new Handlebars.SafeString(ret);
    });
     
    Handlebars.registerHelper('decodeURI', function (value) {
        var decodedValue = decodeURI(value);
        return decodedValue;
    });

    Handlebars.registerHelper('encodeURI', function (value) {
        var endecodedValue = endecodeURI(value);
        return endecodedValue;
    });    
    
    Handlebars.registerHelper('AddNumber', function (value, options) {
        var addition = value + options;
        return addition;
    });


    Handlebars.registerHelper('Variation', function (value, options) {
        var Nvalue;
        var Noptions;
        var isnumericv = false;
        var isnumerico = false;
        var calc = 0;
        var result;
        if (value || value == 0) {
            if (value == 0) {
                isnumericv = true;
                Nvalue = 0;
            }
            if (Object.prototype.toString.call(value) == '[object String]') {
                value = value.replace(/[^0-9\.\/]+/g, "");
            }
            if (!isNaN(value) && value!="") {                
                Nvalue = value*1;          
                isnumericv = true;
            }
        }

        if (options || options == 0) {
            if (options == 0) {
                isnumerico = true;
                Noptions = 0;
            }
            if (Object.prototype.toString.call(options) == '[object String]') {
                options = options.replace(/[^0-9\.\/]+/g, "");
            }
            if (!isNaN(options) && options != "") {
                Noptions = options*1;                
                isnumerico = true;
            }
        }


        if (isnumericv && isnumerico) {
            if (Noptions == 0 && Nvalue==0) {
                Noptions = 1;
                Nvalue = 1;
            }
            if (Noptions == 0) {
                result = 'Infinite';
            } else {
                calc = ((Nvalue / Noptions) - 1) * 100;
                result = calc.toFixed(2);
                result = result + '%';
            }
                
        }else{
            result = '';
        }
       
        return result;
    });

    
    Handlebars.registerHelper('in', function (list, containsValue, options) {
        var hasValue = false;
        for (var i = 0; i < list.length; i++) {
         
            if (containsValue == list[i]) {
                hasValue = true;
                continue;
            }
            
        }

        if (hasValue) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    });

    Handlebars.registerHelper('ToUpperCase', function (string) {
        return string.toUpperCase();
    });

    Handlebars.registerHelper('ToLowerCase', function (string) {
        return string.toLowerCase();
    });

    Handlebars.registerHelper('setMaxLength', function (DataSize, DataType) {
        var maxLength = 0;

        if (DataType == 3) { // Decimal
            maxLength = 30;
        } else {
            maxLength = DataSize;
        }
        return maxLength;
    });

    Handlebars.registerHelper('EqualTo', function (argh1, argh2, options) {
        var isValid = false;
        if (argh1 === argh2) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }                
    });

    Handlebars.registerHelper('NotEqualTo', function (argh1, argh2, options) {
        var isValid = false;
        if (argh1 !== argh2) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    });
    Handlebars.registerHelper('GreaterThan', function (argh1, argh2, options) {
        var isValid = false;
        if (argh1 > argh2) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    });
    Handlebars.registerHelper('LessThan', function (argh1, argh2, options) {
        var isValid = false;
        if (argh1 < argh2) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    });
    Handlebars.registerHelper('GreaterThanOrEqualTo', function (argh1, argh2, options) {
        var isValid = false;
        if (argh1 >= argh2) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    });
    Handlebars.registerHelper('LessThanOrEqualTo', function (argh1, argh2, options) {
        var isValid = false;
        if (argh1 <= argh2) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    });
    Handlebars.registerHelper('escapeScriptHTML', function (value) {
        return value.replace(/'/g, "&quot;");
    })
};

