
CHSI.Forms.Grid = {
    ProcessGridElements:  function(formID)
    {
        jQuery(CHSI.Forms.Forms['f' + formID].Elements).each(function (index, element) {
            if (element.ElementType == 9) {
                //jQuery('#' + CHSI.Forms.Elements.GetElementID(element.ElementID)).html('<tr><td>retrieved Grid Values here</td></tr>');
                var GridID = 0;
                jQuery(element.MetaAttributes).each(function (index, metaattribute) {
                    if (metaattribute.MetaType == 6) {
                        GridID = metaattribute.MetaValue;
                        return false;
                    }
                });
                if (GridID != 0) {
                    CHSI.Forms.Grid.GetGridForm(GridID, element.ElementID);
                }
            }
            else if (element.ElementType == 6 && !element.isDisabled) {
                CHSI.Common.Log('File Upload: ElementID - ' + element.ElementID + ', ColumnID - ' + element.DataStructure.ColumnID + ', ResultSetID - ' + CHSI.Forms.ResultID);

                var Uploader = new qq.FineUploader({
                    element: jQuery('#' + CHSI.Forms.Elements.GetElementID(element.ElementID, 0))[0],
                    debug: true,
                    template: 'qq-template',
                    request: {
                        endpoint:'/CHSIUploadHandler.axd',
                        params: {
                            ElementID: element.ElementID,
                            ColumnID: element.DataStructure.ColumnID,
                            ResultSetID: CHSI.Forms.ResultID
                        }
                    },
                    callbacks: {
                        onComplete: function (id, fileName, response) {
                            this.clearStoredFiles();
                            if (response.success == true) {
                                /* This would be successful */

                                var CurrentValueIndex = 0;
                                CurrentValueIndex = CHSI.Forms.Results.GetMaxValueIndex(element.DataStructure.ColumnID) + 1;

                                var FileID = response.FileID;
                                var ColumnID = element.DataStructure.ColumnID;
                                var NewValue = {};
                                NewValue.StructureColumnID = element.DataStructure.ColumnID
                                NewValue.ResultSetID = 0;
                                NewValue.ValueIndex = CurrentValueIndex;
                                NewValue.HistoryIndex = 0;
                                NewValue.StringValue = fileName;
                                NewValue.Status = 'active';
                                NewValue.DataTypeID = 5;
                                NewValue.IntegerValue = FileID;
                                NewValue.DateValue = null;
                                NewValue.DecimalValue = 0;
                                NewValue.BitValue = false;
                                NewValue.PassedValidation = false;
                                NewValue.Explanation = null;
                                NewValue.ValidationLevel = 0;
                                var ElementTag = element.Tag.replace("{", "").replace("}", "").split('.');
                                if (ElementTag.length == 3) {
                                    ElementTag.push(0); /*Default to Value Index 0 */
                                }
                                ElementTag[3] = NewValue.ValueIndex;
                                var FormattedTag = '{' + ElementTag.join('.') + '}';

                                NewValue.Tag = FormattedTag;
                                NewValue.CreateTmsp = new Date();
                                var ContainerID = CHSI.Forms.Elements.GetElementID(element.ElementID, 'X');
                                CHSI.Common.Log('adding new file - ' + fileName + ', fileid - ' + FileID + ' and loading -' + ContainerID);

                                CHSI.Forms.Results.AppendResult(NewValue);
                                CHSI.Forms.Results.SortResults();
                                CHSI.Common.Log('file container count - ' + jQuery('#' + ContainerID).length);
                                jQuery('#' + ContainerID).html(CHSI.Forms.Elements.GetFileUploadListing(element.DataStructure.ColumnID, FormID, element.ElementID));
                                var isfound = false;
                                var ResultValue = CHSI.Forms.Results.GetResultSetValueByTag(NewValue.Tag);
                                if (ResultValue) {
                                    isfound = true;
                                    CHSI.Common.Log('Value found - ' + NewValue.Tag);
                                }
                                else {
                                    CHSI.Common.Log('Value not found - ' + NewValue.Tag);
                                }


                                var ajaxData = new Object();
                                ajaxData.resultid = CHSI.Forms.ResultID;
                                ajaxData.columnid = ColumnID;
                                ajaxData.formid = FormID;
                                ajaxData.valueindex = NewValue.ValueIndex;
                                ajaxData.StringValue = fileName;
                                ajaxData.DateValue = '1/1/1900';
                                ajaxData.IntegerValue = FileID;
                                ajaxData.DecimalValue = 0;
                                ajaxData.BitValue = false;
                                ajaxData.passedvalidation = false;
                                ajaxData.ParentElementID = null;
                                ajaxData.WarningLevel = 0;
                                ajaxData.ChangeType = 0;

                                ajaxData.ClearReviewed = true;
                                var DataString = JSON.stringify(ajaxData);
                                if (CHSI.Forms.DebugMode) {
                                    CHSI.Common.Log('Calling SaveResultField: ' + DataString + ' and setting new cache for tag: ' + NewValue.Tag);
                                }
                                jQuery.ajax({
                                    type: "POST", url: "/webservice/formfunctions.asmx/SaveResultField",
                                    data: DataString,
                                    contentType: "application/json; charset=utf-8",
                                    dataType: "json",
                                    success: function (e) {
                                        /* replace the cached value*/
                                        CHSI.Forms.Actions.ExecuteFormActions();
                                    }, error: CHSI.DOM.ProcessAjaxError
                                });
                            }
                        }
                    }
                });
            }

        });

    }


};
CHSI.Forms.Grid.GetGridForm = function (GridID, ElementID) {
    /*
old API
/api/Que/Result/QuoteDetails
new API /api/Que/FormInstance/GetFormInstanceByIndex
""*/
    
    jQuery.ajax({
        type: "GET", url: CHSI.Forms.APIs.QuoteDetailsAPI(),
        data: 'FormID=' + GridID + '&SectionIndex=' + 0 + '&ResultID=' + CHSI.Forms.ResultID + '&ElementsOnly=false',
        cache: false
        ,contentType: "application/json; charset=utf-8",
        success: function (e) {
            if (CHSI.Forms.DebugMode) {
                CHSI.Common.Log('Grid Success');
            }
            if (e.HelpIDs == null)
            {
                e.HelpIDs = [];
            }

            if (e.ElementCalculations == null) {
                e.ElementCalculations = [];
            }
            
            CHSI.Forms.Forms['f' + GridID] = e;
            CHSI.Forms.Grid.BuildGrid(GridID, ElementID);

        }, error: jQuery_ErrorResult
    });
};

CHSI.Forms.Grid.BuildGrid = function (GridID, ElementID) {
        var ColumnCount = 0;
    var AddDeleteRows = false;
    var RowIndices = [];
    var CurrentForm = CHSI.Forms.Forms['f' + GridID];

    if (CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].isDisabled == false) {
        AddDeleteRows = CHSI.Forms.GetFormMetaAttributeBoolean(CurrentForm, 23, false);
    }

    for (var ElementIndex = 0, ElementLength = CurrentForm.Elements.length; ElementIndex < ElementLength; ++ElementIndex)
    {
        var Element = CurrentForm.Elements[ElementIndex];
        if (Element.TemplateRegionIndex == 1) {
            if (Element.DataStructure != null) {
                if (ColumnCount == 0) {
                    /*The first column drives the rows*/
                    console.log('adding column - ' + Element.DataStructure.ColumnID);
                    RowIndices = RowIndices.concat(CHSI.Forms.Results.GetNonVirtualValueIndexRows(Element.DataStructure.ColumnID));

                }
                ColumnCount += 1;
            }
            else {
                console.log('skipping column Index- ' + ElementIndex);
            }
        }
    }
    
    console.log('building a grid with ' + RowIndices.length + ' rows and ' + ColumnCount + ' columns for gridID ' + GridID);

    var HeaderHTML = CHSI.Forms.Grid.getHeaderHTML(CurrentForm, AddDeleteRows);

    var ContentMatrix = CHSI.Forms.Grid.translateResultSettoGridMatrix(CurrentForm, CHSI.Forms.ResultSetValues, RowIndices, ColumnCount, ElementID);
    var BodyHTML = CHSI.Forms.Grid.getBodyHTML(GridID, ContentMatrix, RowIndices, ColumnCount, AddDeleteRows, ElementID,CurrentForm);

    var FooterHTML = CHSI.Forms.Grid.getFooterHTML(GridID, ElementID, ColumnCount, CurrentForm, AddDeleteRows);

    CHSI.Forms.Grid.OutputGridHTML(HeaderHTML + BodyHTML + FooterHTML, ElementID);

};

CHSI.Forms.Grid.OutputGridHTML = function (GridHTML, GridElementID) {
    var GridContainer = jQuery('#' + CHSI.Forms.Elements.GetElementID(GridElementID))
    GridContainer.html(GridHTML);
};

CHSI.Forms.Grid.getHeaderHTML = function (FormInstance, AddDeleteRows) {
    var HeaderHTML = [];
    HeaderHTML.push('<thead><tr>');
    if (AddDeleteRows) {
        HeaderHTML.push('<th>&nbsp;</th>');
    }
    for (var ElementIndex = 0, ElementLength = FormInstance.Elements.length; ElementIndex < ElementLength; ++ElementIndex) {
        var Element = FormInstance.Elements[ElementIndex];
        var TDHTML = [];
        var THeadHTML = [];

        if (Element.TemplateRegionIndex == 1) {
            if (Element.DataStructure != null) {

                var LabelColumnText = Element.Label;
                var LabelHelp = CHSI.Forms.Elements.GetHelpIconHTML(Element.ElementID, FormInstance.FormID);
                var OptionalLabels = '';
                if (Element.IsRequired) {
                    OptionalLabels = OptionalLabels + '<span class="RequiredSectionFlag">*</span>';
                }
                THeadHTML.push('<th class="GridLabel"><div id="jxElementContainer_' + Element.ElementID + '_0">' + LabelColumnText + OptionalLabels + LabelHelp + '</div></th>');
            }
        }
        HeaderHTML.push(THeadHTML);
    }

   
    HeaderHTML.push('</tr></thead>');
    return HeaderHTML.join('');
};

CHSI.Forms.Grid.getBodyHTML = function (gridID, ContentMatrix, RowIndices, ColumnCount, ShowDeleteRows, ElementID,FormInstance) {
    var BodyHTML = [];
    BodyHTML.push('<tbody>');
    if (TableContent.length > 0) {
        /* Create Body cell by cell*/
        for (var CurrentRow = 0; CurrentRow < ContentMatrix.length; CurrentRow++) {
            BodyHTML.push('<tr>');
            if (ShowDeleteRows) {
                BodyHTML.push('<td><img src="/images/icons/icon_close.png" title="Delete Row" style="vertical-align: middle; pointer: cursor;" onclick="CHSI.Forms.Grid.DeleteValueIndex(' + gridID + ',' + ElementID + ',' + RowIndices[CurrentRow] + ')"/></td>');
            }
            for (var CurrentColumn = 0; CurrentColumn < ColumnCount; CurrentColumn++) {
                BodyHTML.push('<td>' + ContentMatrix[CurrentRow][CurrentColumn] + '</td>');
            }

            BodyHTML.push('</tr>');
        }

    }
    else {
        BodyHTML.push('<tr><td colspan="' + ColumnCount + '" style="padding:25px;">There are currently no entries in this schedule. To add entries, press the "Add New Row" button below.</td></tr>');
    }
    var ContentMatrixValue = CHSI.Forms.Grid.translateResultSettoGridMatrixValue(FormInstance, RowIndices, ColumnCount);
    var Calculations = CHSI.Forms.Grid.getCalculationsHTML(ColumnCount, FormInstance, ContentMatrixValue, ShowDeleteRows);
    BodyHTML.push(Calculations);
    BodyHTML.push('</tbody>');
    return BodyHTML.join('');
};

CHSI.Forms.Grid.getCalculationsHTML = function (ColumnCount, FormInstance, ContentMatrix, ShowDeleteRows) {
    var CalculationsHTML = [];
    if (FormInstance.ElementCalculations.length) {
        CalculationsHTML.push('<tr>');
        if (ShowDeleteRows) {
            CalculationsHTML.push('<td></td>');
        }
        for (var CurrentColumn = 0; CurrentColumn < ColumnCount; CurrentColumn++) {
            var Element = FormInstance.Elements[CurrentColumn];
            var CalculatedElement = '';
            var Format = null; 
            var CalculationType = 0;

            for (var CalcIndex = 0; CalcIndex <FormInstance.ElementCalculations.length; CalcIndex++) {
                if (Element.ElementID == FormInstance.ElementCalculations[CalcIndex].ElementID) {
                    Format=FormInstance.ElementCalculations[CalcIndex].Format;
                    CalculationType=FormInstance.ElementCalculations[CalcIndex].CalculationType;                    
                }
            }
            CalculatedElement = CHSI.Forms.Grid.getCalculatedColumn(ContentMatrix, CurrentColumn, CalculationType);
            if (Format) {
                CalculatedElement = String.__Format(Format, CalculatedElement);
            } else {
                Format = '';
            }
            CalculationsHTML.push('<td><div id="jxElementGridTotal_' + Element.ElementID + '" class="ElementGridTotal" data-elementid="' + Element.ElementID + '" data-calculationtype="' + CalculationType + '" data-format="' + Format + '"  style="font-weight:bold;">' + CalculatedElement + '</div></td>');
        }
        CalculationsHTML.push('</tr>');
    }

    return CalculationsHTML.join('');
};


CHSI.Forms.Grid.getCalculatedColumn = function (ContentMatrix, CurrentColumn, CalculationType) {
    var CalculatedValue = 0;
    for (var CurrentRow = 0; CurrentRow < ContentMatrix.length; CurrentRow++) {
        switch (CalculationType) {
            case 1:
                if (CHSI.Common.Numbers.IsDecimalNumber(ContentMatrix[CurrentRow][CurrentColumn])) {
                    CalculatedValue += ContentMatrix[CurrentRow][CurrentColumn] * 1;
                }                
                break;
            case 2:
                if(ContentMatrix[CurrentRow][CurrentColumn]){
                    CalculatedValue++;
                }               
                break;
            default:
                CalculatedValue = '';
        }
    }

    return CalculatedValue;
};


CHSI.Forms.Grid.RecalculateTotals = function () {
    $('.ElementGridTotal').each(function (index, value) {
        var Total = $(this);
        var ElementID = Total.data('elementid');
        var CalculationType = Total.data('calculationtype');
        var Format = Total.data('format');
        var CalculatedValue = 0;
        var existingTotal = null;
        
        $('input[id^="jxElement_' + ElementID + '"]').each(function (index, value) {            
            var Element = $(this);            
            var ElementVal = Element.val();                
            
            if (Element.data('rawvalue') != undefined && Element.data('rawvalue') != null && Element.data('rawvalue') !== '') {
                ElementVal = Element.data('rawvalue');                
            } else {                
                ElementVal = Number(ElementVal.replace(/[^0-9\.\/]+/g, ""));
            }                        

            switch (CalculationType) {
             
                case 1:
                    if (CHSI.Common.Numbers.IsDecimalNumber(ElementVal)) {
                        CalculatedValue += ElementVal * 1;
                    }
                    break;
                case 2:
                    if (ElementVal) {
                        CalculatedValue++;
                    }
                    break;
                default:
                    CalculatedValue = '';
            }

        });
        
        if (Format) {
            CalculatedValue = String.__Format(Format, CalculatedValue);
        }

        Total.text(CalculatedValue);

    });
    
};

CHSI.Forms.Grid.getFooterHTML = function (GridID, ElementID, ColumnCount, FormInstance, AllowDeleteRows) {
    var FooterHTML = [];    
    FooterHTML.push('<tfoot>');
    FooterHTML.push('<tr><td colspan="' + ColumnCount + '">');
    if (AllowDeleteRows) {
        FooterHTML.push('<input type="button" value="Add New Row" onclick="CHSI.Forms.Grid.AddNewRow(' + GridID + ',' + ElementID + ');" />');
    }

    FooterHTML.push('<input type="button" value="Export Data to Excel" onclick="CHSI.Forms.Grid.ExportTableasExcel(\'' + FormInstance.FormID + '\');" />');
    FooterHTML.push('</td></tr>');

    FooterHTML.push('</tfoot>');
    return FooterHTML.join('');

};

CHSI.Forms.Grid.translateResultSettoGridMatrix = function (FormInstance, ResultSetCollection, rowIndices, ColumnCount, TargetElementID) {

    TableContent = CHSI.Arrays.ReturnEmpty2DimensionalMatrix(rowIndices.length, ColumnCount);

    var ColumnIndex = 0;

    var isElementDisabled = FormInstance.isDisabled;

    CHSI.Common.Log('all grid elements are disabled - ' + isElementDisabled);
    var CurrentRowValueIndex;
    for (var ElementIndex = 0, ElementLength = FormInstance.Elements.length; ElementIndex < ElementLength; ++ElementIndex) {
        var Element = FormInstance.Elements[ElementIndex];
        if (Element.TemplateRegionIndex == 1) {
            if (Element.DataStructure != null) {
                for (var RowIndex = 0; RowIndex < rowIndices.length; RowIndex++) {
                    CurrentRowValueIndex = rowIndices[RowIndex]
             
                            if (TableContent[RowIndex]) {
                                TableContent[RowIndex][ColumnIndex] = CHSI.Forms.Elements.GetElementHTML(Element, CurrentRowValueIndex, FormInstance.FormID, TargetElementID, false,false);
                            }
                        
                    }
 
                }

                ColumnIndex += 1;
            }

        } 

    return TableContent;
};

CHSI.Forms.Grid.translateResultSettoGridMatrixValue = function (FormInstance,  rowIndices, ColumnCount) {

    TableContent = CHSI.Arrays.ReturnEmpty2DimensionalMatrix(rowIndices.length, ColumnCount);

    var ColumnIndex = 0;

    var isElementDisabled = FormInstance.isDisabled;

    var CurrentRowValueIndex;
    for (var ElementIndex = 0, ElementLength = FormInstance.Elements.length; ElementIndex < ElementLength; ++ElementIndex) {
        var Element = FormInstance.Elements[ElementIndex];
        if (Element.TemplateRegionIndex == 1) {
            if (Element.DataStructure != null) {
                for (var RowIndex = 0; RowIndex < rowIndices.length; RowIndex++) {
                    CurrentRowValueIndex = rowIndices[RowIndex]

                    if (TableContent[RowIndex]) {
                        TableContent[RowIndex][ColumnIndex] = CHSI.Forms.Results.GetValue(Element.DataStructure.ColumnID, CurrentRowValueIndex, FormInstance.FormID);                        
                    }

                }

            }

            ColumnIndex += 1;
        }

    }    
    return TableContent;
};


CHSI.Forms.Grid.AddNewRow = function (GridID, ElementID, ParentElementID) {

    var ColumnCount = 0;
    var RowCount = 0;
    var ResultMax = {};
    var FormInstance = CHSI.Forms.Forms['f' + GridID];
    CHSI.Forms.Forms['f' + CHSI.Forms.PrimaryFormID].DataChanged = true;
    CHSI.Forms.Navigation.SetSectionStatus(CHSI.Forms.CurrentActiveSectionIndex, 'In Progress');

    for (var ElementIndex = 0, ElementLength = FormInstance.Elements.length; ElementIndex < ElementLength; ++ElementIndex) {
        var Element = FormInstance.Elements[ElementIndex];
        if (Element.TemplateRegionIndex == 1) {
            if (Element.DataStructure != null) {
                ColumnCount += 1;
                ResultMax['R' + Element.DataStructure.ColumnID] = CHSI.Forms.Results.GetMaxValueIndex(Element.DataStructure.ColumnID);
            }
        }
    }

    for (var ElementIndex = 0, ElementLength = FormInstance.Elements.length; ElementIndex < ElementLength; ++ElementIndex) {
        var Element = FormInstance.Elements[ElementIndex];
        if (Element.TemplateRegionIndex == 1) {
            if (Element.DataStructure != null) {
                var MaxResultValue = CHSI.Forms.Results.GetResultSetValue(Element.DataStructure.ColumnID, ResultMax['R' + Element.DataStructure.ColumnID]);
                var NewIndex;

                if (MaxResultValue.Status == 'virtual') {
                    NewIndex = 0;
                } else {
                    NewIndex = MaxResultValue.ValueIndex + 1;
                }

                var NewValue = {};
                NewValue.StructureColumnID = Element.DataStructure.ColumnID
                NewValue.ResultSetID = 0;
                NewValue.ValueIndex = NewIndex;
                NewValue.HistoryIndex = 0;
                NewValue.StringValue = null;
                NewValue.Status = 'active';
                NewValue.DataTypeID = MaxResultValue.DataTypeID;
                NewValue.IntegerValue = null;
                NewValue.DateValue = null;
                NewValue.DecimalValue = null;
                NewValue.BitValue = false;
                NewValue.PassedValidation = false;
                NewValue.Explanation = null;
                NewValue.ValidationLevel = 0;
                NewValue.ChangeType = 0;
                var ElementTag = MaxResultValue.Tag.replace("{", "").replace("}", "").split('.');
                if (ElementTag.length == 3) {
                    ElementTag.push(0); /*Default to Value Index 0 */
                }
                ElementTag[3] = NewValue.ValueIndex;
                var FormattedTag = '{' + ElementTag.join('.') + '}';

                NewValue.Tag = FormattedTag;
                NewValue.CreateTmsp = new Date();

                if (MaxResultValue.Status == 'virtual') {
                    CHSI.Forms.Results.ResultMap[CHSI.Forms.Results.GetResultKey(Element.DataStructure.ColumnID, NewIndex)] = NewValue;
                    CHSI.Forms.ResultSetValues[CHSI.Forms.Results.returnResultSetIndex(Element.DataStructure.ColumnID, NewIndex)] = NewValue;
                } else {
                    CHSI.Forms.Results.AppendResult(NewValue);
                }
                
                CHSI.Forms.Results.SaveResultFieldValue(NewValue, Element.ElementID,false);
                     

                
            }
        }
    };
    CHSI.Forms.Results.GetChangedResults(CHSI.Forms.ResultID, CHSI.Forms.PrimaryFormID);
    CHSI.Forms.Results.SortResults();
    CHSI.Forms.Actions.ExecuteFormActions();

    CHSI.Forms.Grid.BuildGrid(GridID, ElementID);
};

CHSI.Forms.Grid.DeleteValueIndex = function (GridID, ElementID, ValueIndex) {
    CHSI.Common.Log('would have deleted value index ' + ValueIndex);
    var ConfirmDelete = window.confirm("Please confirm you wanted to delete this row");
    if (ConfirmDelete) {
        jQuery(CHSI.Forms.Forms['f' + GridID].Elements).each(function (index, element) {
            if (element.TemplateRegionIndex == 1) {
                if (element.DataStructure != null) {
                    CHSI.Forms.Results.DeleteElement(ElementID, element.DataStructure.ColumnID, ValueIndex, GridID);
                }
            }
        });
        CHSI.Forms.Grid.BuildGrid(GridID, ElementID);
    }
};

CHSI.Forms.Grid.ExportTableasExcel = function (FormID) {
    window.open('/utilities/FormGridExcelExport.aspx?FormID=' + FormID + '&resultsetID=' + CHSI.Forms.ResultID, 'Export');

};


CHSI.Forms.Grid.GetGridRow = function (GridID, RowContents) {
    if (CHSI.Forms.DebugMode) {
        CHSI.Common.Log('creating Grid Row');
    }
    var RowHTML = [];
    jQuery(RowContents).each(function (index, td) {
        RowHTML.push('<td>' + td + '</td>');
    });

    return RowHTML.join('');
};
