var Template_QuoteDocuments = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=helpers.helperMissing;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.DocumentType : depth0),"text",{"name":"compare","hash":{"operator":"=="},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.DocumentType : depth0),"report",{"name":"compare","hash":{"operator":"=="},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.DocumentType : depth0),"file",{"name":"compare","hash":{"operator":"=="},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "                <tr>\r\n                    <td>\r\n                        <input id=\"jxDocument_"
    + alias3(((helper = (helper = helpers.DocumentID || (depth0 != null ? depth0.DocumentID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"DocumentID","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + alias3(((helper = (helper = helpers.DocumentID || (depth0 != null ? depth0.DocumentID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"DocumentID","hash":{},"data":data}) : helper)))
    + "\" class=\"jxIncludeReportGroup "
    + alias3(((helper = (helper = helpers.GroupLineID || (depth0 != null ? depth0.GroupLineID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"GroupLineID","hash":{},"data":data}) : helper)))
    + "\" type=\"checkbox\" data-GroupLineID=\""
    + alias3(((helper = (helper = helpers.GroupLineID || (depth0 != null ? depth0.GroupLineID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"GroupLineID","hash":{},"data":data}) : helper)))
    + "\" />\r\n                    </td>\r\n                    <td colspan=\"2\">\r\n                        <span>"
    + ((stack1 = ((helper = (helper = helpers.Description || (depth0 != null ? depth0.Description : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\r\n                    </td>\r\n                </tr>            \r\n";
},"4":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "                <tr>                \r\n                    <td>\r\n                        <input id=\"jxDocument_"
    + alias3(((helper = (helper = helpers.DocumentID || (depth0 != null ? depth0.DocumentID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"DocumentID","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + alias3(((helper = (helper = helpers.DocumentID || (depth0 != null ? depth0.DocumentID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"DocumentID","hash":{},"data":data}) : helper)))
    + "\" class=\"jxIncludeReport "
    + alias3(((helper = (helper = helpers.GroupLineID || (depth0 != null ? depth0.GroupLineID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"GroupLineID","hash":{},"data":data}) : helper)))
    + "\" type=\"checkbox\" data-GroupLineID=\""
    + alias3(((helper = (helper = helpers.GroupLineID || (depth0 != null ? depth0.GroupLineID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"GroupLineID","hash":{},"data":data}) : helper)))
    + "\" data-reportid=\""
    + alias3(((helper = (helper = helpers.ReportID || (depth0 != null ? depth0.ReportID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ReportID","hash":{},"data":data}) : helper)))
    + "\" />\r\n                    </td>     \r\n                    <td>\r\n                        <img src=\"/images/treelineimages/document.gif\" alt=\"Document\" />\r\n                    </td>           \r\n                    <td>\r\n                        <a style=\"color: blue; text-decoration: underline;\" href=\"/chsifilehandler.axd?ReportID="
    + alias3(((helper = (helper = helpers.ReportID || (depth0 != null ? depth0.ReportID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ReportID","hash":{},"data":data}) : helper)))
    + "&ReportType=1&outputtype=attachment&outputname="
    + alias3(this.lambda(((stack1 = (depths[2] != null ? depths[2].Documents : depths[2])) != null ? stack1.ClientName : stack1), depth0))
    + " "
    + alias3(((helper = (helper = helpers.Description || (depth0 != null ? depth0.Description : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Description","hash":{},"data":data}) : helper)))
    + "&ReportOptions=QuoteID%3d"
    + alias3(((helper = (helper = helpers.QuoteID || (depth0 != null ? depth0.QuoteID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"QuoteID","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3(((helper = (helper = helpers.Description || (depth0 != null ? depth0.Description : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Description","hash":{},"data":data}) : helper)))
    + "</a>\r\n                    </td>\r\n                </tr>            \r\n";
},"6":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "                <tr>                \r\n                    <td>\r\n                        <input id=\"jxDocument_"
    + alias3(((helper = (helper = helpers.DocumentID || (depth0 != null ? depth0.DocumentID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"DocumentID","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + alias3(((helper = (helper = helpers.DocumentID || (depth0 != null ? depth0.DocumentID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"DocumentID","hash":{},"data":data}) : helper)))
    + "\"class=\"jxIncludeReport "
    + alias3(((helper = (helper = helpers.GroupLineID || (depth0 != null ? depth0.GroupLineID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"GroupLineID","hash":{},"data":data}) : helper)))
    + "\" type=\"checkbox\" data-GroupLineID=\""
    + alias3(((helper = (helper = helpers.GroupLineID || (depth0 != null ? depth0.GroupLineID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"GroupLineID","hash":{},"data":data}) : helper)))
    + "\" />\r\n                    </td>     \r\n                    <td>\r\n                        <img src=\"/images/treelineimages/document.gif\" alt=\"Document\" />\r\n                    </td>           \r\n                    <td>\r\n                        <a style=\"color: blue; text-decoration: underline;\" href=\"/chsifilehandler.axd?fileid="
    + alias3(((helper = (helper = helpers.ReportID || (depth0 != null ? depth0.ReportID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ReportID","hash":{},"data":data}) : helper)))
    + "&outputname="
    + alias3(this.lambda(((stack1 = (depths[2] != null ? depths[2].Documents : depths[2])) != null ? stack1.ClientName : stack1), depth0))
    + " "
    + alias3(((helper = (helper = helpers.Description || (depth0 != null ? depth0.Description : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Description","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3(((helper = (helper = helpers.Description || (depth0 != null ? depth0.Description : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Description","hash":{},"data":data}) : helper)))
    + "</a>\r\n                    </td>\r\n                </tr>            \r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "﻿The following documents are available for this policy. Clicking on the document will open it as a PDF in another window.\r\n        <br />\r\n        <br />\r\n\r\n        <table>\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Documents : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </table>\r\n\r\n        \r\n        <div id=\"ButtonButtonContainer\">                        \r\n            <input type=\"checkbox\" id=\"jxIncludeAll\" /> Select All             \r\n            <div style=\"float: right;\">\r\n                <input type=\"checkbox\" id=\"jxAttachment\" value=\"Y\"/> Create Attachment &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp\r\n                Named &nbsp <input style=\"width: 250px\" type=\"text\" id=\"jxAttachmentName\" value=\" Some Value \" /> &nbsp&nbsp&nbsp&nbsp\r\n                <input type=\"button\" id=\"jxEmail\" value=\" Email Selected\"  Style=\"display:none;\" />\r\n                <input type=\"button\" id=\"jxPrint\" value=\" Print Selected\" /> &nbsp&nbsp&nbsp&nbsp\r\n                <input type=\"button\" id=\"jxCancel\" value=\" Cancel \" />        \r\n            </div>        \r\n        </div>    ";
},"useData":true,"useDepths":true});