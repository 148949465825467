module PL.Claims.Controllers {

    export class ClaimIntakeFinishV2Controller extends Abstract.ClaimIntakeFormBase {

        private _lateReasons: any;
        private _incompleteCategories: any[];
        private _infomationStatus: string;

        Notification: Claims.Controllers.ClaimIntakeNotificationV2Controller;

        constructor(intakeForm: PL.Claims.Models.IntakeForm) {

            super(intakeForm);
            this.ClaimIntakeForm = intakeForm;

        }

        init(): void {
            this.bind();
        }

        bind(): JQueryDeferred<any> {
            var $result = $.Deferred();
            mW.handlebars.bind(CHSITemplates.ClaimIntakeForm_Finish, { Status: this.ClaimIntakeForm.Status, LateReasons: this.ClaimIntakeForm.LateReasons, FinishInstructions: this.ClaimIntakeForm.FinishInstructions, Attachments: this.ClaimIntakeForm.Attachments }, this.$category_container).done(() => {

            });
            return $result.resolve(true);
        }

        setupEvents(): void {


        }

        events(): void {

            var _self = this;

            $('#renderedIntakeFormContainer').on('click', '#jxFinish', (e) => {
                if ($('#jxCompletedBy').val() != '') {
                    this.validateCategories().done(() => {
                        this.submit();
                    }).fail((incompleteCategories) => {
                        this.markFailedCategoires(incompleteCategories);
                        this.Notification.getNotification(Enums.ClaimIntakeNotification_Request.FINISH_CATEGORIES_INCOMPLETE_ERROR, incompleteCategories);
                    });

                } else {
                    this.Notification.getNotification(Enums.ClaimIntakeNotification_Request.FINISH_INFORMATION_NOT_COMPLETED_ERROR);
                }
            });


        }

        private markFailedCategoires(incompleteCategories: any[]): void {
            incompleteCategories.forEach((catO) => {
                $('.categoryContainer').eq(catO.index).addClass('progressError').find('.innerCategoryProgress').addClass('progressError');
            });

            setTimeout(function () {
                $('.innerCategoryProgress, .categoryContainer').removeClass('progressError');
            }, 5000);
        }

        private processMissingValues(): void {
            this.ClaimIntakeForm.Categories.forEach((catO) => {
                catO.Fields.forEach((fO) => {
                    if (<Object>this.ClaimIntakeForm.IncidentReport.hasOwnProperty(fO.FieldName)) {
                        this.ClaimIntakeForm.IncidentReport[fO.FieldName] = fO.FieldValue;
                    }
                });
            });
        }

        private submit(): void {
            var $finish: JQuery = $('#jxFinish');
            this.processMissingValues();
            console.log(this.ClaimIntakeForm);
            this.ClaimIntakeForm.IncidentReport.Status = Enums.ClaimIntakeForm_Status.SUBMITTED;
            this.ClaimIntakeForm.IncidentReport.FormCompletedDate = moment().format('L');
            this.ClaimIntakeForm.IncidentReport.FormCompletedBy = $('#jxCompletedBy').val();
            this.ClaimIntakeForm.IncidentReport.FormCompletedTitle = $('#jxTitle').val();
            this.ClaimIntakeForm.IncidentReport.LateReasonCode = $('#jxLate').val();
            $finish.val(' Saving... ').attr('disabled', 'disabled');
            this.ClaimIntakeForm.IncidentReport.save().done((incidentID) => {
                $finish.val(' Preparing to Submit, this may take a moment... ').attr('disabled', 'disabled');
                var executionStage = 'Staging';
                PL.Claims.APIs.ClaimIncidentReportRepo.executeClaimIncidentSteps(incidentID, executionStage).done(() => {
                    this.ClaimIntakeForm.IncidentReport.submit().done((data) => {
                        this.ClaimIntakeForm.IncidentReport.EsignatureURL = data.EsignatureURL;
                        this.ClaimIntakeForm.IncidentReport.EsignatureDocID = data.EsignatureDocID;
                            $finish.val(' Submitted ').attr('disabled', 'disabled');
                            //if using signnow then redirect else close this
                            if (data.ReturnURL) {
                                location.href = data.ReturnURL;
                            } else {
                                closeThis(true);
                            }
                    }).fail((err) => {
                        alert(err["responseJSON"]["ExceptionMessage"]);
                        $finish.val(' Submit ').removeAttr('disabled');
                    }); 
                }).fail((err) => {
                    console.log(err);
                    if (err.responseJSON && err.responseJSON.ExceptionMessage) {
                        alert(err.responseJSON.ExceptionMessage);
                    } else {
                        alert(err);
                    }
                    $finish.val(' Submit ').removeAttr('disabled');
                });
            }).fail((err) => {
                console.log(err);
                alert(err["responseJSON"]["ExceptionMessage"]);
                $finish.val(' Submit ').removeAttr('disabled');
            });
        }

    }
}