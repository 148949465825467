module PL.Que.Models {

    export class DynFormObject {

        InstanceID: number;
        isDisabled: boolean;
        MetaAttributes: any;

    }

}