module PL.CRM.APIs {

    export class ClientAddress {

        static _routePrefix: string = '/api/CRM/Clients/';

        public static getClientAddressByClientID(clientID: number): JQueryDeferred<CRM.Models.ClientAddress> {
            var result = $.Deferred();
            $.get(this._routePrefix + 'GetClientAddressByClientID?ClientID=' + clientID).done(function (data) {
                result.resolve(data);
            });
            return result;
        }

    }

}