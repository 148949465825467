module PL.Common.Controllers {

    var Repositories: any = {};
    export class ChoreCoordinator {
        pageOutput: string = "#jxOutput"
 
        choreData: GUI.Smart.SmartObject
        DataTableObject: GUI.Tables.DTables
        init(): void {
            var self = this;
            this.bindEvents();
            Factories.RepositoryFactory.getChoreRepository().Get().done(function (data) {
                self.choreData= data;
                self.bindUI();
            });
        }

        bindEvents(): void {
//need to update for variable recurrance.
            var self = this;
            $(this.pageOutput).on('click', '.dataEnable', function () {
                var choreID = $(this).data().choreid;
                Factories.RepositoryFactory.getChoreRepository().EnableChore(choreID, '*/5 * * * *').done(function () {
                    console.log('enabled');
                    self.init();
                });
                
            });
            $(this.pageOutput).on('click', '.dataDisable', function () {
                var choreID = $(this).data().choreid;
                Factories.RepositoryFactory.getChoreRepository().DisableChore(choreID).done(function () {
                    console.log('disabled');
                    self.init();
                });
            });
        }

        buildOptions(): void {
            var Column: GUI.Smart.SmartColumnObject = new GUI.Smart.SmartColumnObject; 
            Column.title = "";
            Column.data = null;
            Column.orderable = false;
            Column.class = "details-control";
            Column.defaultContent = '[<a href="#" class="minilink jxEditForm">Enable</a>]';
            Column.render = function (row, data, type) {
                
                return (row[3] == 'True') ? '<a class="dataDisable" data-choreid="' + row[0] + '">[Disable Chore]</a>': '<a class="dataEnable" data-choreid="' + row[0] + '">[Enable Chore]</a>';
            }
            this.choreData.Columns.push(Column);
        }

        bindUI(): void {
            
            this.buildOptions();
            this.DataTableObject = new GUI.Tables.DTables;
            this.DataTableObject.containerName= "jxOutput"
            this.DataTableObject.tableName = "choreGrid"
            this.DataTableObject.columnSearch = false;
            this.DataTableObject.stateSave = false;
            this.DataTableObject.data = this.choreData;
            this.DataTableObject.buildTable();
            
        }
    }
}