CHSI.Page.LossSummary = new Object();

CHSI.Page.LossSummary.GridOutputID = '';
CHSI.Page.LossSummary.Template_Claims_LossSumary = '';

CHSI.Page.LossSummary.Init = function (gridID, SuccessFunction) {
 
    CHSI.HandleBars.Preparehelpers();

    Handlebars.registerHelper('eachArray', function (context, options) {
        var ret = "";
        var index = -1;
        var size = 0;
        var isLast = false;
        if (Object.prototype.toString.apply(context) === '[object Array]') {
            size = context.length;
            for (var i = 0; i < context.length; i++) {
                index++;
                isLast = false;
                if (index >= size - 1) {
                    isLast = true;
                }
                ret = ret + options.fn({ property: i, value: context[i], index: index, isLast: isLast, size: size });
            }
        } else {
            for (var prop in context) {
                size++;
            }
            for (var prop in context) {
                index++;
                isLast = false;
                if (index >= size - 1) {
                    isLast = true;
                }
                ret = ret + options.fn({ property: prop, value: context[prop], index: index, isLast: isLast, size: size });
            }
        }

        return ret;
    });

    Handlebars.registerHelper('CommafyNumber', function (inputNumber) {
        var inputString = inputNumber.toString();
        return inputString.replace(/(^|[^\w.])(\d{4,})/g, function ($0, $1, $2) {
            return $1 + $2.replace(/\d(?=(?:\d\d\d)+(?!\d))/g, "$&,");
        });
    });

    Handlebars.registerHelper('DecimalToPercentage', function (inputNumber) {
        var convertedNum = Math.round(inputNumber * 100) / 100;
        return convertedNum.toFixed(2) + "%";
    });

    Handlebars.registerHelper('FixedToPercentage', function (inputNumber) {
        var convertedNum = Math.round(inputNumber * 100) / 100;
        return convertedNum.toFixed(0) + "%";
    });
    CHSI.Page.LossSummary.GridOutputID = gridID;

    CHSI.Page.LossSummary.GetTemplates(SuccessFunction);

};

CHSI.Page.LossSummary.GetTemplates = function (SuccessFunction) {
    CHSI.Forms.ajaxFormDetails = CHSITemplates.Template_Claims_LossSummary;
    CHSI.Page.LossSummary.Template_Claims_LossSumary = CHSI.Forms.ajaxFormDetails;
    SuccessFunction();    
};

CHSI.Page.LossSummary.GetLossSummary = function (clientID, policyPeriod, asOfDate, groupLineID, IncludeChildren) {
    jQuery('#btnRefresh').attr('disabled', 'disabled');
    jQuery('#' + CHSI.Page.LossSummary.GridOutputID).html("<img src=\"/images/Dialog/spinner.gif\" alt=\"loading...\" /><b>Loading...</b>");

    CHSI.Forms.ajaxFormDetails = jQuery.ajax({
        type: "GET",
        url: "/api/ClaimSystem/LossSummary/GetLossSummary?clientID=" + clientID + "&policyPeriod=" + policyPeriod + "&asOfDate=" + asOfDate + "&groupLineID=" + groupLineID + "&IncludeChildren=" + IncludeChildren,
        contentType: "application/json; charset=utf-8",
        cache: false,
        dataType: "json",
        success: function (e) {
            jQuery('#btnRefresh').removeAttr('disabled');
            CHSI.Common.LogObject(e);

            var lossSummaryContainer = {};
            lossSummaryContainer.LossSummary = e;

            var lossSummaryTemplate = CHSITemplates.Template_Claims_LossSummary;
            jQuery('#' + CHSI.Page.LossSummary.GridOutputID).html(lossSummaryTemplate(lossSummaryContainer));

            restart = true; sorttable.init();
        },
        error: function (e) {
            jQuery('#btnRefresh').removeAttr('disabled');
            jQuery('#' + CHSI.Page.LossSummary.GridOutputID).html("Error loading Loss Summary grid. Please contact your system administrator.");
            CHSI.DOM.ProcessAjaxError;
        }
    });

};
