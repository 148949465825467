module PL.CRM.Models {

    export class Client {

        ClientID: number;
        ClientNumber: string;
        GroupID: string;
        ClientName: string;
        DBA: string;
        LegalName: string;
        Industry: string;
        ClientType: string;
        RelatedClientNumber: string;
        PrimaryContact: string;
        AccountNumber: string;
        Phone: string;
        Phone2: string;
        Phone3: string;
        P1Ext: string;
        P2Ext: string;
        P3Ext: string;
        //P1AC: number;
        //P2AC: number;
        //P3AC: number;
        //FaxAC: number;
        Fax: string;
        ShippingAddress1: string;
        ShippingAddress2: string;
        ShippingCity: string;
        ShippingState: string;
        ShippingZip: string;
        BillingAddress1: string;
        BillingAddress2: string;
        BillingCity: string;
        BillingState: string;
        BillingZip: string;
        StartDate: Date;
        TerminationDate: Date;
        Products: string;
        RecordType: string;
        HasBilling: boolean;
        ShippingAddress: any;
        MailingAddress: any;
        BillingAddress: any;
        CreateTmsp: Date;
        CreateUser: string;
        UpdateTmsp: Date;
        UpdateUser: string;

        constructor() {
            this.ClientID = null;
            this.ClientNumber = null;
            this.GroupID = null;
            this.ClientName = null;
            this.DBA = null;
            this.LegalName = null;
            this.Industry = null;
            this.ClientType = null;
            this.RelatedClientNumber = null;
            this.PrimaryContact = null;
            this.AccountNumber = null;
            this.Phone = null;
            this.Phone2 = null;
            this.Phone3 = null;
            this.P1Ext = null;
            this.P2Ext = null;
            this.P3Ext = null;
            //this.P1AC = null;
            //this.P2AC = null;
            //this.P3AC = null;
            //this.FaxAC = null;
            this.Fax = null;
            this.ShippingAddress1 = null;
            this.ShippingAddress2 = null;
            this.ShippingCity = null;
            this.ShippingState = null;
            this.ShippingZip = null;
            this.BillingAddress1 = null;
            this.BillingAddress2 = null;
            this.BillingCity = null;
            this.BillingState = null;
            this.BillingZip = null;
            this.StartDate = null;
            this.TerminationDate = null;
            this.Products = null;
            this.RecordType = null;
            this.HasBilling = null;
            this.ShippingAddress = null;
            this.MailingAddress = null;
            this.BillingAddress = null;
            this.CreateTmsp = null;
            this.CreateUser = null;
            this.UpdateTmsp = null;
            this.UpdateUser = null;
        }

        getClientInformation(): JQueryDeferred<Client> {
            var result = $.Deferred();

            PL.CRM.APIs.Clients.getClientInfo(this.ClientID).done((clientInformation) => {

                result.resolve(clientInformation);

            });

            return result;

        }
    }    

}