CHSI.UW = {
    Quote: {},
    MuniQuote: {},
    PrintStatus: {},
    QuoteStatus: {
        Models: {},
        Controllers: {}
    },
    ClassCode: {
        Models: {},
        Controllers: {}
    },
    ScheduleTypes: {
        Models: {},
        Controllers: {}
    }
};