module PL.Que.Models.Design {

    export class ValueListOptions {

        ValueListID: number;
        Description: string;
        Status: string;
        Value: string;
        SortOverride: number;

    }

}