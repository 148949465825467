module PL.Claims.APIs {

    export class IntakeFormFieldRepo {

        private static _routePrefix: string = "/api/ClaimSystem/Form/";

        public static GetIntakeFormFields(ClaimIncidentReportID: number): JQueryDeferred<Models.IntakeFormField> {
            var results = $.Deferred();            
            $.get(this._routePrefix + 'GetIntakeFormFields?ClaimIncidentReportID=' + ClaimIncidentReportID).done(function (data) {
                results.resolve(data);
            });
            return results;
        }
                
        public static SaveIntakeFormField(formFields: any[]): JQueryDeferred<Models.IntakeFormField> {
            var results = $.Deferred();                       
            var data = JSON.stringify(formFields);            
            $.post(this._routePrefix + 'SaveIntakeFormField', data).done(function (data) {
                    results.resolve(data);
            });

            return results;
        }

    }
}