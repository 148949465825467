CHSI.Que.DataStructureCategories = {

    Init: function (setup) {

        this.Cache.DataStructureID = setup.StructureID;
       
        this.Calls.GetCategories(function () {
            CHSI.Que.DataStructureCategories.BindPresentationLayer();
            CHSI.Que.DataStructureCategories.Cache.GridTable.search('').draw();
        });
        this.Events.Global();
    },
    Utilities: {
        GetCategories: function ()
        {
            var Categories = [];
            for (var CategoryIndex = 0; CategoryIndex < CHSI.Que.DataStructureCategories.Cache.CategoriesGrid.Values.length; CategoryIndex++) {
                Categories.push({ CategoryID: CHSI.Que.DataStructureCategories.Cache.CategoriesGrid.Values[CategoryIndex][0], CategoryTitle: CHSI.Que.DataStructureCategories.Cache.CategoriesGrid.Values[CategoryIndex][2] });
            }
            Categories = sortByKey(Categories, 'CategoryTitle');
            return Categories;
        }
    }
    ,Cache: {
        CategoriesGrid:{
            Columns:null,
            Values:null
        },
        GridTable: null,
        CategoryObject:{}
    },
    BindPresentationLayer: function () {

        $('#jxStructureCategoriesContainer').html('<table width="100%" class="dtable" id="jxDataStructureCategoriesGrid"><tfoot></tfoot></table>');

        var DataSet = null;        
        var ClientColumnDefinition = {};
        var columnDefs = [];

        if (CHSI.Que.DataStructureCategories.Cache.CategoriesGrid) {
            DataSet = CHSI.Que.DataStructureCategories.Cache.CategoriesGrid;
        }
        var PreparedColumnDefinitions = CHSI_DataTables.PrepareColumnDefinitions(DataSet.Columns);
        for (ColumnIndex = 0; ColumnIndex < DataSet.Columns.length; ColumnIndex++) {

                ClientColumnDefinition = {};
                ClientColumnDefinition.targets = ColumnIndex;
              

                if (DataSet.Columns[ColumnIndex].title == 'Status') {
                    ClientColumnDefinition.render = function (data, type, row, meta) {

                        if (data == 'active') {
                            return '<select class="CategoryCellValue"><option value="active" selected>Active</option><option value="inactive">Inactive</option></select>';
                        } else {
                            return '<select class="CategoryCellValue"><option value="active">Active</option><option value="inactive" selected>Inactive</option></select>';
                        }
                    }
                }
                else if (DataSet.Columns[ColumnIndex].title == 'Category Type') {
 
                    ClientColumnDefinition.render = function (data, type, row, meta) {
                        var HTML = [];
                        var Options = [['Questionnaire', 1, ''], ['Grid', 2, '']];
                        HTML.push('<select class="CategoryCellValue">');
                        for (var i = 0; i < Options.length; i++) {
                            if (Options[i][1] == Number(data))
                            { Options[i][2] = 'selected'; }

                            HTML.push('<option value=' + Options[i][1].toString() + ' ' + Options[i][2] + '>' + Options[i][0] + '</option>');
                        }
                        HTML.push('</select>');

                        return HTML.join('');
                    }
                }
                else {
                    DataSet.Columns[ColumnIndex].orderDataType = 'dom-text';
                    ClientColumnDefinition.render = function (data, type, row, meta) {
                        return '<input type="text" class="CategoryCellValue" value="' + data + '"/>';
                    }
                }

            columnDefs.push(ClientColumnDefinition);
        }

        if (DataSet.Values != null) {
            CHSI.Que.DataStructureCategories.Cache.GridTable = $('#jxDataStructureCategoriesGrid').DataTable({
                aaData: DataSet.Values,
                columns:DataSet.Columns,
                aoColumnDefs: columnDefs,
                stateSave: true
            })
        } else {
            var HTML = [];
            $('#jxDataStructureCategoriesGrid').html('<tr><td>No results found.</td></tr>')
        }

        $('#jxLoading').hide();
    },
    Events: {
        Global: function () {
            $('#jxCreateCategory').off().on('click', function () {

                CHSI.Que.DataStructureCategories.Cache.CategoryObject.CategoryTitle = $('#jxCategoryTitle').val();
                if (CHSI.Que.DataStructureCategories.Cache.CategoryObject.CategoryTitle == '')
                {
                    alert('please enter a category title');
                    return false;
                }

                if (isNaN(CHSI.Que.DataStructureCategories.Cache.CategoryObject.SortOrder) || CHSI.Que.DataStructureCategories.Cache.CategoryObject.SortOrder == "") {
                    CHSI.Que.DataStructureCategories.Cache.CategoryObject.SortOrder = 0;
                }

                CHSI.Que.DataStructureCategories.Cache.CategoryObject.SortOrder = $('#jxCategorySortOrder').val();
                CHSI.Que.DataStructureCategories.Cache.CategoryObject.Status = 'active';
                CHSI.Que.DataStructureCategories.Cache.CategoryObject.StructureType = $('#jxCategoryStructureType').val();
                CHSI.Que.DataStructureCategories.Cache.CategoryObject.DataStructureID = CHSI.Que.DataStructureCategories.Cache.DataStructureID;
                CHSI.Que.DataStructureCategories.Cache.CategoryObject.CategoryID = null;
                CHSI.Que.DataStructureCategories.Calls.SaveCategory(function () {
                    CHSI.Que.DataStructureCategories.Calls.GetCategories(function () {
                        CHSI.Que.DataStructureCategories.BindPresentationLayer();
                    });                    
                });
            });

            $("#jxStructureCategoriesContainer").on('input', 'td', function () {
                var Cell = CHSI.Que.DataStructureCategories.Cache.GridTable.cell(this).index();
                var Row = CHSI.Que.DataStructureCategories.Cache.GridTable.row(Cell.row).data();
                var Column = CHSI.Que.DataStructureCategories.Cache.GridTable.settings()[0].aoColumns[Cell.column];
                var ValueType = $(this).find('.CategoryCellValue').val();
                
                CHSI.Que.DataStructureCategories.Cache.CategoryObject.CategoryID = Row[0];
                CHSI.Que.DataStructureCategories.Cache.CategoryObject.DataStructureID = Row[1];
                CHSI.Que.DataStructureCategories.Cache.CategoryObject.CategoryTitle = Column.title == 'Category Title' ? ValueType : Row[2];
                CHSI.Que.DataStructureCategories.Cache.CategoryObject.StructureType = Column.title == 'Category Type' ? ValueType : Row[3];
                CHSI.Que.DataStructureCategories.Cache.CategoryObject.SortOrder = Column.title == 'SortOrder' ? ValueType : Row[4];
                CHSI.Que.DataStructureCategories.Cache.CategoryObject.Status = Column.title == 'Status' ? ValueType : Row[5];
              
                CHSI.Que.DataStructureCategories.Cache.GridTable.cell({ row: CHSI.Que.DataStructureCategories.Cache.GridTable.row(Cell.row).index(), column: Cell.column }).data(ValueType);

                CHSI.Que.DataStructureCategories.Calls.SaveCategory(function () {
                    CHSI.Que.DataStructureCategories.Calls.GetCategories(function () {
                        CHSI.Que.DataStructureCategories.BindPresentationLayer();
                    });
                });
            });
        }
    },
    Calls: {
        GetCategories: function (successFunction) {
            var Options = {};
            Options.URL = '/api/Que/DataStructureCategory/Get?DataStructureID=' + CHSI.Que.DataStructureCategories.Cache.DataStructureID;
            Options.Success = function (data) {
                CHSI.Que.DataStructureCategories.Cache.CategoriesGrid = data;
                if (successFunction) {
                    successFunction();
                }
            };
            CHSI.Common.Ajax.Get(Options);
        },
        SaveCategory: function (successFunction) {
            var Options = {};
            Options.URL = '/api/Que/DataStructureCategory/Save';
            Options.Data = CHSI.Que.DataStructureCategories.Cache.CategoryObject;
            Options.Success = function (data) {
                if (data && data.CategoryID>0) {
                    if (successFunction) {
                        successFunction();
                    }
                } else {
                    alert('Can not save duplicated titles');
                }
               
            };
            CHSI.Common.Ajax.Post(Options);
        }
    }

}