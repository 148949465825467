namespace PL.Claims.APIs {

    export class Notification_Repo {

        private static _routePrefix: string = '/api/ClaimSystem/Notification/';

        public static getNotificationsByFormTypeID(formTypeID: number): JQueryDeferred<PL.Claims.Models.Notifcation[]> {
            var $result = $.Deferred<PL.Claims.Models.Notifcation[]>();
            $.get(this._routePrefix + 'GetNotificationsByFormTypeID?FormTypeID=' + formTypeID).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        public static getNotificationsNotInFormType(formTypeID: number): JQueryDeferred<PL.Claims.Models.Notifcation[]> {
            var $result = $.Deferred<PL.Claims.Models.Notifcation[]>();
            $.get(this._routePrefix + 'GetNotificationsNotInFormType?FormTypeID=' + formTypeID).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        public static removeFormTypeNotification(formTypeID: number, notificationID: number): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            mW.io.post(this._routePrefix + 'RemoveFormTypeNotification?FormTypeID=' + formTypeID + '&NotificationID=' + notificationID);
            return $result.resolve();
        }

        public static saveFormTypeNotification(formTypeID: number, notificationID: number): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            mW.io.post(this._routePrefix + 'SaveFormTypeNotification?FormTypeID=' + formTypeID + '&NotificationID=' + notificationID);
            return $result.resolve();
        }

        public static deleteNotification(notificationID: number): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            mW.io.post(this._routePrefix + 'DeleteNotification?NotificationID=' + notificationID);        
            return $result.resolve();
        }
    }

}