
declare function ShowPopup(WindowID, WinURL, WinTitle, width, height, CloseFunction, ismodal, isWindowLocked, ShowHelp, SuccessFunction, panelContent);

class Exceptions {    

    $grid: any;
    eObject: GUI.Smart.SmartObject;    

    constructor() {

    }

    init(exceptionID?: number): any {
        if (exceptionID) {
            this.getExceptionsByID(exceptionID, null);
        } else {
            this.getLatestExceptions();
        }
        this.bindEvents();
    }

    bindEvents(): void {        
        $('.jxEdit').on('click', function () {
            var eID = $(this).data('exceptionid');
            var URL = '/admin/diagnostics/modal/exceptiondetails.aspx?exceptionid=' + eID;
            ShowPopup('excetpiondetail', URL, 'Exception Detail', 800, 400, null, null, null, null, null, null);
        });
    }

    sourceTypeColorMarker(sourceTypeName: string): string {
        var classColor: string = '';
        switch (sourceTypeName) {
            case 'NET':
                classColor = 'net';
                break;
            case 'Javascript':
                classColor = 'js';
                break;
            case 'SQL':
                classColor ='sql';
                break;
            case 'RemoteHost':
                classColor = 'rh';
                break;
            default:
                classColor = '';
        }
        return classColor;
    }

    getLatestExceptions() {   
        var e = this.eObject;     
        e = new GUI.Smart.SmartObject();
        var ajax = new IO.Ajax;
        var d = new Date();
        var todaysDate: string = (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();


        var settings = {
            URL: '/API/logging/Exception/GetExceptionsByDateMinified?FilterDate=' + todaysDate,       
            Success: function (data) {
                e = data;
            },
            Error: function () {
                console.log('No exceptions were found.');
            }
        };
        ajax.Get(settings);
        
        this.constructExceptionData(e);
    }

    getExceptionsByID(ExceptionID: number, successFunction: any) {   
        var e = this.eObject;
        var ajax = new IO.Ajax;

        var settings = {
            URL: '/API/logging/Exception/GetMinified?ExceptionID=' + ExceptionID,
            Success: function (data) {
                e = data;
                successFunction(e);
            },
            Error: function () {
                console.log('No exceptions were found.');
            }
        };
        ajax.Get(settings);
                        
    }

    constructExceptionData(exceptionData: GUI.Smart.SmartObject) {
        var cdt: GUI.Tables.DTables = new GUI.Tables.DTables;
        
            if (exceptionData) {
                var Column = new GUI.Smart.SmartColumnObject();
                //title: '',
                Column.data = null;
                Column.orderable = false;
                Column.class = 'details-control';
                Column.defaultContent = '[<a href="#" class="minilink">view</a>]';
                Column.render = function (data, type, row) { return "<a class='jxEdit minilink' data-exceptionid='" + row[0] + "' href='#'>[Edit]</a>";
                }

                exceptionData.Columns.push(Column);
            }

            this.buildExceptionTable(exceptionData);
    }

    buildExceptionTable(exceptionData: GUI.Smart.SmartObject) {
        var cdt: GUI.Tables.DTables = new GUI.Tables.DTables;        
        var self = this;

        cdt.containerName = 'ExceptionsContainer';
        cdt.tableName = 'jxExceptionGrid';
        cdt.data = exceptionData;
        cdt.dtObject.createdRow = function (row, data, index) {
            $('td', row).eq(0).addClass(self.sourceTypeColorMarker(data[1]));
        };        
        
        cdt.buildTable();        

        self.$grid = cdt.$grid;        
    }
}