CHSI.Page.UWQuoteAssignment = {
    Init: function (QuoteID) {

        this.Cache.QuoteID = QuoteID;
        
        var Template_AssigneeTypes = $('#Template_AssigneeTypes').html();
        this.Templates.AssigneeTypes = Handlebars.compile(Template_AssigneeTypes);
        var Template_Assignments = $('#Template_Assignments').html();
        this.Templates.Assignments = Handlebars.compile(Template_Assignments);
        var Template_AssigneeNames = $('#Template_AssigneeNames').html();
        this.Templates.AssigneeNames = Handlebars.compile(Template_AssigneeNames);
       
        this.Calls.GetAssigneeTypes();
        this.Calls.GetQuoteAssignments();
        this.Events.Global();
    },
    Templates: {

    },
    Cache: {
        Assignments: null,
        AssigneeTypes: null,
        AssigneeNames: null,
        Status: 'active'
        ,QuoteID:null
        , AssigneeTypeID: null
        , CurrentAssignment: {                      
            Status: 'active'
        , QuoteID: null
        , AssigneeTypeID: null
        }
    },
    BindPresentationLayer: function () {
        $('#jxAssigmentsContainer').html(this.Templates.Assignments(this.Cache.Assignments));
    },
    BindPresentationLayerAssigneeTypes: function () {
        $('#jxAssigneeTypesContainer').html(this.Templates.AssigneeTypes(this.Cache.AssigneeTypes));
    },
    BindPresentationLayerAssigneeNames: function () {
        $('#jxAssigneeNamesContainer').html(this.Templates.AssigneeNames(this.Cache.AssigneeNames));
        if (this.Cache.CurrentAssignment) {
            $('#jxAssigneeID').val(this.Cache.CurrentAssignment.AssigneeID);
        }        
    },
    PersistFromCache: function (Object) {
        if (Object == null) {
            AssignmentObject = CHSI.Page.UWQuoteAssignment.Cache.CurrentAssignment;
        } else {
            AssignmentObject = Object;
        }
        AssignmentObject.AssigneeTypeName = $('#jxAssigneeType').val();
        AssignmentObject.Primary = $('#jxPrimary').is(':checked') ? true : false;
        AssignmentObject.AssigneeTypeID = $('#jxAssigneeType').val();
        AssignmentObject.Status = $('#jxStatus').val();
        AssignmentObject.QuoteID = this.Cache.QuoteID;
        AssignmentObject.AssigneeID = $('#jxAssigneeID').val();
        AssignmentObject.FromDate = $('#jxDateFrom').val();
        AssignmentObject.ToDate = $('#jxDateTo').val();
        return AssignmentObject;
    },
    Events: {
        Global: function () {

            $('#jxAssigneeTypesContainer').on('change', '#jxAssigneeType', function () {
                CHSI.Page.UWQuoteAssignment.Cache.AssigneeTypeID = $(this).val();
                CHSI.Page.UWQuoteAssignment.Calls.GetAssigneeNames();
            });

            $('#jxAdd').on('click', function () {
                var isNew = $(this).data('isnew');
                if (isNew) {
                    CHSI.Page.UWQuoteAssignment.Events.ResetNewAssignment();
                    CHSI.Page.UWQuoteAssignment.PersistFromCache();
                    CHSI.Page.UWQuoteAssignment.Calls.SaveNewAssignment();
                } else {
                    CHSI.Page.UWQuoteAssignment.Calls.SaveExistingAssignment();
                }                
            });            

            $('#jxReferenceTypeContainer').on('change', '#jxStatus', function () {
                if ($(this).is(':checked')) {
                    CHSI.Common.Reference.Cache.Status = 'active';
                    CHSI.Common.Reference.Calls.GetReferences();
                } else {
                    CHSI.Common.Reference.Cache.Status = '';
                    CHSI.Common.Reference.Calls.GetReferences();
                }
            });

            $('.DatePicker').val();
        },
        EditAssignment: function (AssignID) {
            $('#jxAdd').prop('value', ' Save ');
            $('#jxAdd').data('isnew', false);
            this.GetAssigneeInformation(AssignID);
        },
        ResetNewAssignment: function () {
            $('#jxAdd').prop('value', ' Add ');
            $('#jxAdd').data('isnew', true);
            var CurrentAssignment = CHSI.Page.UWQuoteAssignment.Cache.CurrentAssignment;            
            CurrentAssignment.AssignID = null
            CurrentAssignment.AssigneeID = ''
            CurrentAssignment.AssigneeTypeID = ''
            CurrentAssignment.Email = ''
            CurrentAssignment.QuoteID = 0
            CurrentAssignment.UserID = ''
            CurrentAssignment.AssigneeTypeName = ''
            CurrentAssignment.AssigneeName = ''
            CurrentAssignment.FromDate = ''
            CurrentAssignment.ToDate = ''
            CurrentAssignment.Status = ''
            CurrentAssignment.Primary = ''            
        },
        GetAssigneeInformation: function (AssignID) {
            var Assignments = CHSI.Page.UWQuoteAssignment.Cache.Assignments.Assignments;
            $.each(Assignments, function (Index) {
                if (Assignments[Index].AssignID == AssignID) {

                    var CurrentAssignment = CHSI.Page.UWQuoteAssignment.Cache.CurrentAssignment;
                    CurrentAssignment.AssignID = Assignments[Index].AssignID;
                    CurrentAssignment.AssigneeID = Assignments[Index].AssigneeID;
                    CurrentAssignment.AssigneeTypeID = Assignments[Index].AssigneeTypeID;
                    CurrentAssignment.Email = Assignments[Index].Email;
                    CurrentAssignment.QuoteID = Assignments[Index].QuoteID;
                    CurrentAssignment.UserID = Assignments[Index].UserID;
                    CurrentAssignment.AssigneeTypeName = Assignments[Index].AssigneeTypeName;
                    CurrentAssignment.AssigneeName = Assignments[Index].AssigneeName;
                    CurrentAssignment.FromDate = Assignments[Index].FromDate;
                    CurrentAssignment.ToDate = Assignments[Index].ToDate;
                    CurrentAssignment.Status = Assignments[Index].Status;
                    CurrentAssignment.Primary = Assignments[Index].Primary;

                    $('#jxAssigneeType').val(CurrentAssignment.AssigneeTypeID);
                    CHSI.Page.UWQuoteAssignment.Cache.AssigneeTypeID = CurrentAssignment.AssigneeTypeID;
                    CHSI.Page.UWQuoteAssignment.Calls.GetAssigneeNames();
                    $('#jxAssigneeID').val(CurrentAssignment.AssigneeID);
                    $('#jxDateFrom').val(CHSI.Dates.GetShortDate(CurrentAssignment.FromDate));
                    $('#jxDateTo').val(CHSI.Dates.GetShortDate(CurrentAssignment.ToDate));
                    $('#jxStatus').val(CurrentAssignment.Status);

                    if (CurrentAssignment.Primary) {
                        $('#jxPrimary').prop('checked', true);
                    } else {
                        $('#jxPrimary').prop('checked', false);
                    }
                }
            });
        }
    },    
    Calls: {
        GetQuoteAssignments: function () {            
           // var AjaxURL = '/api/UW/QuoteAssignment/get?AssignID=' + AssignID + '&QuoteID=' + QuoteID + '&AssigneeID=' + AssigneeID + '&AssigneeTypeID=' + AssigneeTypeID;
            var AjaxURL = '/api/UW/QuoteAssignment/get?QuoteID=' + CHSI.Page.UWQuoteAssignment.Cache.QuoteID;

            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: true,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.UWQuoteAssignment.Cache.Assignments = { Assignments: e };
                    CHSI.Page.UWQuoteAssignment.BindPresentationLayer();
                    restart = true;
                    sorttable.init();

                },
                error: function (e) {
                    CHSI.Common.LogObject(e);
                }
            });
        },
        GetAssigneeTypes: function () {
            var AjaxURL = '/api/UW/ReferenceValue/GetReferenceSpecValues?tablename=uw.assigneetype&fieldname=';
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    if (e.length) {
                        CHSI.Page.UWQuoteAssignment.Cache.AssigneeTypeID = e[0].ReferenceValueID;
                        CHSI.Page.UWQuoteAssignment.Calls.GetAssigneeNames();
                    }

                    CHSI.Page.UWQuoteAssignment.Cache.AssigneeTypes = { AssigneeTypes: e };
                    CHSI.Page.UWQuoteAssignment.BindPresentationLayerAssigneeTypes();
                    restart = true;
                    sorttable.init();

                },
                error: function (e) {
                    CHSI.Common.Log('no data to return');
                }
            });
        },        
        GetAssigneeNames: function () {
            var AjaxURL = '/api/UW/Assignee/GetByType?AssigneeTypeID=' + CHSI.Page.UWQuoteAssignment.Cache.AssigneeTypeID;
            $.ajax({
                type: "GET",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (e) {
                    CHSI.Page.UWQuoteAssignment.Cache.AssigneeNames = { AssigneeNames: e };
                    CHSI.Page.UWQuoteAssignment.BindPresentationLayerAssigneeNames();
                    restart = true;
                    sorttable.init();

                },
                error: function (e) {
                    CHSI.Common.Log('no data to return');
                }
            });
        },
        SaveNewAssignment: function () {
            var AjaxURL = '/api/UW/QuoteAssignment/Save';
            var ajaxString = JSON.stringify(CHSI.Page.UWQuoteAssignment.PersistFromCache());
            $.ajax({
                type: "POST",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                data: ajaxString,
                success: function (e) {
                    CHSI.Page.UWQuoteAssignment.Calls.GetQuoteAssignments();
                },
                error: function (e) {
                    CHSI.Common.Log(e);
                }
            });
        },
        SaveExistingAssignment: function () {
            CHSI.Page.UWQuoteAssignment.PersistFromCache(CHSI.Page.UWQuoteAssignment.Cache.CurrentAssignment);            
            var AjaxURL = '/api/UW/QuoteAssignment/Save';
            var ajaxString = JSON.stringify(CHSI.Page.UWQuoteAssignment.Cache.CurrentAssignment);
            $.ajax({
                type: "POST",
                url: AjaxURL,
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                data: ajaxString,
                success: function (e) {
                    CHSI.Page.UWQuoteAssignment.Calls.GetQuoteAssignments();
                    CHSI.Page.UWQuoteAssignment.Events.ResetNewAssignment();
                },
                error: function (e) {
                    CHSI.Common.Log(e);
                }
            });
        }
    },
    UpdateURL: function (TableName, ReferenceTable) {
        this.Cache.TableName = TableName;
        this.Cache.ReferenceTable = ReferenceTable;
        this.Cache.GetReferenceTypesURL = '/api/' + this.Cache.NameSpace + '/ReferenceSpec/GetTypes?TableName=' + this.Cache.TableName;
        this.Cache.GetReferencesURL = '/api/' + this.Cache.NameSpace + '/ReferenceSpec/Get?TableName=' + this.Cache.TableName + '&ReferenceTable=' + this.Cache.ReferenceTable + '&IncludeValues=true&FieldName=';
    },
    Refresh: function () {
        CHSI.Common.Reference.Calls.GetReferenceTypes();
        CHSI.Common.Reference.Calls.GetReferences();
    },

    ShowReferenceEdit: function (ReferenceValueID) {
        var QueryString = '';
        if (ReferenceValueID != 0)
        { QueryString = '?ReferenceValueID=' + ReferenceValueID }
        var URL = CHSI.Common.Reference.Cache.ReferenceEditorURL + QueryString;
        ShowPopup('ReferenceEditor', URL, 'Reference Editor', 900, 550, CHSI.Common.Reference.RefreshPage, true);
    }

}