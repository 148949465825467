module CL.Common.Models {

    export class AttachmentCategory {
        Id: number;
        AttachmentID: number;
        ParentID: number;
        Title: string;
        AllowDocuments: boolean;
        Children: AttachmentCategory[] = new Array<AttachmentCategory>();
        static getAttachmentCategories(): JQueryDeferred<Models.AttachmentCategory[]> {
            var Results = jQuery.Deferred();
            Factories.RepositoryFactory.getAttachmentRepository().getAttachmentCategories().done(function (data) {
                Results.resolve(data);
            });
            return Results;
        }
        findChildren(AttachmentCategories: AttachmentCategory[]): void {
            var Self = this;
            AttachmentCategories.forEach(function (obj) {
                if (obj.ParentID == Self.Id) {
                    var attachmentCategory: AttachmentCategory = new AttachmentCategory(obj);
                    attachmentCategory.findChildren(AttachmentCategories);
                    Self.Children.push(attachmentCategory);
                }
            });
        }

        constructor(jsonObject?: any) {
            console.log('creating a new AttachmentCategory object');
            if (jsonObject) {
                console.log('deserializing the AttachmentCategory from JSON');
                for (var prop in jsonObject) this[prop] = jsonObject[prop];
            }
        }

        static parseJSONArray(JSONObjects: any[]): AttachmentCategory[] {
            var Results = new Array<AttachmentCategory>();
            JSONObjects.forEach(function (obj) {

                if (obj.ParentID == 0 || obj.ParentID == null) {
                    var attachmentCategory: AttachmentCategory = new AttachmentCategory(obj);
                    attachmentCategory.findChildren(JSONObjects);
                    Results.push(attachmentCategory);
                }

            });

            return Results;
        }



    }

}