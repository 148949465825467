namespace PL.Claims.Models {

    export class Notifcation {

        NotificationID: number;
        FormTypeID: number;
        NotificationName: string;
        NotificationType: number;
        Status: string;
        From: string;
        Subject: string;
        IncludeReport: boolean;
        BodyTemplate: string;
        DestinationType: number;
        Destination: string;
        CCDestinationType: number;
        CCDestination: string;
        BCCDestinationType: number;
        BCCDestination: string;
        GroupName: string;
        CreateUser: string;
        CreateTmsp: string | Date;
        UpdateUser: string;
        UpdateTmsp: string | Date;

        constructor(notificationID?: number, formTypeID?: number) {
            this.NotificationID = notificationID;
            this.FormTypeID = formTypeID;
        }

        getNotificationsByFormTypeID(): JQueryDeferred<Notifcation[]> {
            var $result = $.Deferred<Notifcation[]>();
            PL.Claims.APIs.Notification_Repo.getNotificationsByFormTypeID(this.FormTypeID).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        getNotificationsNotInFormType(): JQueryDeferred<Notifcation[]> {
            var $result = $.Deferred<Notifcation[]>();
            PL.Claims.APIs.Notification_Repo.getNotificationsNotInFormType(this.FormTypeID).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        removeFormTypeNotification(): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            PL.Claims.APIs.Notification_Repo.removeFormTypeNotification(this.FormTypeID, this.NotificationID).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        saveFormTypeNotification(): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            PL.Claims.APIs.Notification_Repo.saveFormTypeNotification(this.FormTypeID, this.NotificationID).done((data) => {
                $result.resolve(data);
            });
            return $result;
        }

        deleteNotification(): JQueryDeferred<any> {
            var $result = $.Deferred<any>();
            PL.Claims.APIs.Notification_Repo.deleteNotification(this.NotificationID);
            return $result.resolve();
        }
    }

}