module PL.Que.Models {

    export class MetaAttributes {

        ParentID: number = null;
        ParentSecondID: number = null;
        ParentThirdID: number = null;
        ObjectTypeID: number = null;
        Description: string = null;
        MetaType: number = null;
        MetaIndex: number = null;
        MetaValue: string = null;
        Status: string = null;
        CreateUser: string;
        CreateTmsp: string|Date;
        UpdateUser: string;
        UpdateTmsp: string|Date;

    }

}