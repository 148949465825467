module PL.Que.Enums {

    export enum Elements {

        HTML = 1,
        TEXTBOX = 3,
        RADIO = 4,
        CHECKBOX_LIST = 5,
        UPLOAD_CONTROL = 6,
        SELECT_LIST = 8,
        GRID = 9,
        CHECKBOX = 12

    }

}